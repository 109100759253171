<lib-auto-layout [grow]="1" direction="vertical" horizontal="stretch">
  @if (pastRequiredActions.length) {
    <lib-auto-layout [padding]="[10, 20]" vertical="center" [gap]="[10]" class="divider color-warn">
      <fa-icon [icon]="ICONS.warn"></fa-icon>
      <p class="no-margin"><strong>{{ 'shared.tasks-list.overdue' | translate }}</strong></p>
    </lib-auto-layout>
    @for (requiredAction of pastRequiredActions; track requiredAction.id; let isOdd = $odd) {
      <lib-task-row
        [requiredAction]="requiredAction"
        [isOdd]="isOdd"
        [class.element-loading-overlay]="requiredAction.processing"></lib-task-row>
    }
  }

  @if (futureRequiredActions.length) {
    <div class="line"></div>
    <lib-auto-layout [padding]="[10, 20]" class="divider color-grey">
      <p class="no-margin"><strong>{{ 'shared.tasks-list.due-soon' | translate }}</strong></p>
    </lib-auto-layout>
    @for (requiredAction of futureRequiredActions; track requiredAction.id; let isOdd = $odd) {
      <lib-task-row
        [requiredAction]="requiredAction"
        [isOdd]="isOdd"
        [class.element-loading-overlay]="requiredAction.processing"></lib-task-row>
    }
  }
</lib-auto-layout>
