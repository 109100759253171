<div class="swipe">
  <div class="img">
    <ion-img [src]="slide.img"></ion-img>
  </div>
  <div class="information">
    <div>
      <h2>{{ slide.headline }}</h2>
    </div>
    <div>
      <ion-text>{{ slide.description }}</ion-text>
    </div>
    <div *ngIf="slide.form" class="form">
      <ng-container *ngFor="let group of slide.form.body">
        <ng-container *ngFor="let field of group.fieldGroup">
          <ion-select
            *ngIf="dataType.select && isLanguageSelectionField(field)"
            [(ngModel)]="field.value.value"
            [name]="field.identifier.originalValue"
            [id]="'id-' + field.identifier"
            interface="popover"
            placeholder="{{ 'shared.swiper.please-choose' | translate }}…"
            class="color-medium"
            lang="de">
            <div slot="label"><fa-icon [icon]="icons.language"></fa-icon></div>

            <ion-select-option
              *ngFor="let option of field.value.options"
              [value]="option.identifier">
              {{ option.value }}
            </ion-select-option>
          </ion-select>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
