import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { OverlayEventDetail } from '@ionic/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ExpensesWidgetRow } from 'projects/core/src/lib/models/expense.model';
import { InvokerBody } from 'projects/core/src/lib/models/invoker-body.model';
import {
  ExpenseTranslationKeys,
  ModalEvent,
  ModalResult,
} from 'projects/core/src/lib/models/modal-action.model';
import { SDAPIResponseObject } from 'projects/core/src/lib/models/sdapi-object.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { ExpensesService } from 'projects/core/src/lib/services/expenses.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { ModalActionService } from 'projects/core/src/lib/services/modal-action.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-expense-row',
  templateUrl: './expense-row.component.html',
  styleUrls: ['./expense-row.component.scss'],
})
@UntilDestroy()
export class ExpenseRowComponent implements OnInit {
  @Output() listReload = new EventEmitter<void>();
  @Output() documentUploaded = new EventEmitter<SDAPIResponseObject>();

  @Input({ required: true }) expense: ExpensesWidgetRow;
  @Input({ required: false }) hasExtendedView = true;
  @Input({ required: false }) hasNavigationIcon = true;

  icons = {
    handHoldingDollar: faHandHoldingDollar,
  };

  translations: any;

  constructor(
    private breakPointService: BreakpointService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private modalActionService: ModalActionService,
    private expensesService: ExpensesService,
  ) {}
  async ngOnInit(): Promise<void> {
    this.translations = await firstValueFrom(this.translateService.get('shared.expenses'));
  }

  async viewExpense(expense: ExpensesWidgetRow): Promise<void> {
    if (!expense.behaviourInvokers?.length) {
      return;
    }
    await this.loadAndShowExpenseDetails(expense.id, expense.behaviourInvokers);
  }

  private async loadAndShowExpenseDetails(
    expenseId: string,
    behaviorInvokers?: InvokerBody[],
  ): Promise<void> {
    await this.loadingService.load(this.translations['fetching-expense-details']);
    try {
      await this.showExpenseDetails(expenseId, behaviorInvokers);
    } catch (error) {
      console.error('Failed to show expense details', error);
      await this.loadingService.stop();
    }
  }

  private async showExpenseDetails(
    expenseId: string,
    behaviorInvokers?: InvokerBody[],
  ): Promise<void> {
    const result: OverlayEventDetail = await firstValueFrom(
      this.modalActionService.showDynamicViewWithActions(
        expenseId,
        behaviorInvokers,
        this.expensesService.previewFallbackMethods,
      ),
    );
    if (this.modalActionService.isActionTriggeringEvent(result)) {
      await this.handleModalActionEvent(result, expenseId, behaviorInvokers);
    }
  }

  private async handleModalActionEvent(
    event: OverlayEventDetail,
    expenseId: string,
    behaviorInvokers?: InvokerBody[],
  ): Promise<void> {
    const modalResult: ModalResult =
      await this.modalActionService.handleInvokerMethodOfActionButton(
        event,
        ExpenseTranslationKeys,
      );
    if (this.modalActionService.shouldReloadDataSet(modalResult)) {
      this.reloadExpenseList();
    }
    if (modalResult.event !== ModalEvent.stateChange) {
      await this.loadAndShowExpenseDetails(expenseId, behaviorInvokers);
    }
  }

  private reloadExpenseList(): void {
    this.listReload.emit();
  }

  get isExtendedView(): boolean {
    return this.hasExtendedView && this.breakPointService.isAbove('sm');
  }
}
