import { Component, Input } from '@angular/core';
import {
  DataType,
  DynamicDataField,
  DynamicForm,
  SelectionObject,
} from 'projects/core/src/lib/models/form.model';
import { DynamicFormService } from 'projects/core/src/lib/services/dynamic-form.service';

@Component({
  selector: 'lib-preview-form',
  templateUrl: './preview-form.component.html',
  styleUrls: ['./preview-form.component.scss'],
})
export class PreviewFormComponent {
  @Input() form: DynamicForm;

  dataType: typeof DataType = DataType;

  isType(item: DynamicDataField, type: string): boolean {
    return DynamicFormService.isFieldType(item, type);
  }

  isBasicInputType(field: DynamicDataField): boolean {
    return (
      field.type === this.dataType.text ||
      field.type === this.dataType.textArea ||
      field.type === this.dataType.number
    );
  }

  getSelectionFieldValue(item: DynamicDataField): string {
    if (item.value.value) {
      return item.value.options.find((selection: SelectionObject) => selection.identifier === item.value.value).value;
    }
    return '';
  }
}
