<ion-content>
  <svg
    class="full-background-svg"
    preserveAspectRatio="xMidYMid slice"
    id="bg-session-timeout-page"
    width="1536"
    height="722"
    viewBox="0 0 1536 722"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="768" height="722" />
    <circle cx="167" cy="140" r="400" fill="url(#paint0_linear_2519_31478)" />
    <circle cx="1266" cy="894" r="400" fill="url(#paint1_linear_2519_31478)" />
    <defs>
      <linearGradient
        id="paint0_linear_2519_31478"
        x1="-303.5"
        y1="-465.5"
        x2="439.5"
        y2="589"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0" />
        <stop id="upper-circle" offset="0.826408" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2519_31478"
        x1="866"
        y1="314"
        x2="1545"
        y2="1229"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="0.883594" stop-color="#EAEDF0" />
      </linearGradient>
    </defs>
  </svg>

  <ion-grid class="content session-timeout" [fixed]="true">
    <ion-row>
      <ion-col sizeXl="6" sizeMd="8" size="12">
        <div id="i-card-design">
          <ion-card color="light">
            <ion-card-header class="ion-text-center session-timeout-card-header">
              <lib-logo></lib-logo>
            </ion-card-header>
            <ion-card-content class="ion-no-padding">
              <ion-grid>
                <ion-row>
                  <ion-col sizeMd="10" size="12">
                    <ion-row>
                      <ion-col>
                        <div>
                          <p class="ion-text-center">
                            {{ 'shared.session-timeout.message' | translate }}
                          </p>
                        </div>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col>
                        <ion-button
                          color="primary"
                          class="button-glow"
                          type="button"
                          expand="block"
                          [routerLink]="['/portal']">
                          <p class="p3">{{ 'shared.session-timeout.re-login' | translate }}</p>
                        </ion-button>
                        <ion-button
                          *ngIf="landingpageActive"
                          color="secondary"
                          class="button-glow"
                          type="button"
                          expand="block"
                          [routerLink]="['/']">
                          <p class="p3">
                            {{ 'shared.session-timeout.to-landingpage' | translate }}
                          </p>
                        </ion-button>
                        <div>
                          <p class="label-secondary label-i-engineers p2">
                            Powered by the i-engineers
                          </p>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
