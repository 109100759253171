<pinch-zoom
  *ngIf="pdfSrc"
  [properties]="zoomProperties"
  [wheel]="false"
  [autoHeight]="true"
  [ngClass]="{ 'stretch-self': !isLoading }">
  <div id="pinch-zoom-wrapper" class="stretch-self">
    <pdf-viewer
      *ngIf="pdfSrc"
      [src]="pdfSrc"
      [render-text]="false"
      [render-text-mode]="renderTextMode.DISABLED"
      [fit-to-page]="true"
      [original-size]="false"
      [autoresize]="true"
      (after-load-complete)="afterLoadComplete()"
      zoom-scale="page-width"
      style="height: 100%"></pdf-viewer>
  </div>
</pinch-zoom>

<div *ngIf="isLoading" class="ion-margin-horizontal">
  <ion-skeleton-text
    *ngFor="let i of getRowAmount()"
    class="ion-margin-vertical"
    animated="true"></ion-skeleton-text>
</div>
