import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DynamicDataField } from 'projects/core/src/lib/models/form.model';

@Component({
  selector: 'lib-contact-information',
  templateUrl: './contact-information.component.html',
})
@UntilDestroy()
export class ContactInfoCardComponent {
  @Input() fields: DynamicDataField[];
}
