import { Injectable } from '@angular/core';
import { ActionSheetButton } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import {
  ButtonActionType,
  DynamicButton,
  DynamicButtonSetConfiguration,
} from '../models/dynamic-button.model';
import { LabelService } from './label.service';

@Injectable()
export class DynamicButtonsService {
  constructor(
    private translateService: TranslateService,
    private labelService: LabelService,
  ) {}

  public constructActionSheetButtons(
    configuration: DynamicButtonSetConfiguration,
  ): ActionSheetButton[] {
    return configuration.actionButtons
      .map((button: DynamicButton) => {
        if (button.hasPrintActivity) {
          return this.labelService.constructLabelPrintActionSheetButton(button);
        }
        if (button.hasDownloadActivity) {
          return this.constructDownloadActionSheetButton(button);
        }
        if (button.invoker) {
          return this.constructInvokerBasedActionSheetButton(button);
        }

        return null;
      })
      .filter((button: ActionSheetButton) => button);
  }

  private constructInvokerBasedActionSheetButton(button: DynamicButton): ActionSheetButton {
    return button.hasDeleteActivity
      ? this.constructDeleteActionSheetButton(button)
      : this.constructDefaultActionSheetButton(button);
  }

  private constructDownloadActionSheetButton(button: DynamicButton): ActionSheetButton {
    return {
      text: button.label,
      data: button.downloadDetails,
      role: ButtonActionType.download,
      htmlAttributes: {
        color: 'tertiary',
        'aria-label': button.label,
      },
      icon: 'cloud-download',
    };
  }

  private constructDeleteActionSheetButton(button: DynamicButton): ActionSheetButton {
    return {
      text: button.label,
      data: button.invoker,
      role: ButtonActionType.invokerAction,
      cssClass: 'color-warn',
      htmlAttributes: {
        color: 'warn',
        'aria-label': button.label,
      },
      icon: 'trash',
    };
  }

  private constructDefaultActionSheetButton(button: DynamicButton): ActionSheetButton {
    return {
      text: button.label,
      data: button.invoker,
      role: ButtonActionType.invokerAction,
      htmlAttributes: {
        color: 'primary',
        'aria-label': button.label,
      },
    };
  }

  public async constructBackButton(): Promise<ActionSheetButton> {
    const backButtonTranslation: string = await firstValueFrom(
      this.translateService.get('shared.forms.back'),
    );
    return {
      text: backButtonTranslation,
      role: ButtonActionType.cancel,
      cssClass: 'back-button',
      htmlAttributes: { color: 'tertiary', 'aria-label': backButtonTranslation },
      icon: 'chevron-back-outline',
    };
  }
}
