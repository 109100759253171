import { Injectable } from '@angular/core';
import { APIError } from 'projects/core/src/lib/data/errors.data';
import {
  TableHeaderItem,
  TableList,
  TableListItem,
  TableType,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { TieTableObjectList } from 'projects/core/src/lib/models/sdapi-table-object.model';
import { ExpensesWidgetRow, ExpenseTypeIdentifier } from '../models/expense.model';
import { TableMapper } from './table.mapper';

@Injectable({ providedIn: 'root' })
export class ExpensesMapper {
  private static readonly storageKeyColumnPreferences: string = `expensesColumnPreference`;

  public static mapExpensesTable(resource: TieTableObjectList): TableList {
    let tableHeader: TableHeaderItem[] = [];
    let tableRows: TableListItem[] = [];

    //TODO: Improve mapping error handling
    try {
      tableHeader = TableMapper.mapTableHeaderResource(resource);
      tableRows = TableMapper.mapTableRowsObjectListResource(resource);
    } catch (error) {
      throw new APIError('Mapping of expenses failed in the ExpensesMapper.', error);
    }

    return new TableList(tableHeader, tableRows, {
      title: resource.objName,
      storageKey: this.storageKeyColumnPreferences,
      tableType: TableType.expenses,
    });
  }

  public static mapExpensesTableListToExpensesWidgetRows(
    tableList: TableList,
  ): ExpensesWidgetRow[] {
    const indexOfNameAmountDate = {
      name: tableList.initialHeader.findIndex(
        (header) => header.identifier.normalizedValue === ExpenseTypeIdentifier.name,
      ),
      amount: tableList.initialHeader.findIndex(
        (header) => header.identifier.normalizedValue === ExpenseTypeIdentifier.amount,
      ),
      date: tableList.initialHeader.findIndex(
        (header) => header.identifier.normalizedValue === ExpenseTypeIdentifier.date,
      ),
      currency: tableList.initialHeader.findIndex(
        (header) => header.identifier.normalizedValue === ExpenseTypeIdentifier.currency,
      ),
      status: tableList.initialHeader.findIndex(
        (header) => header.identifier.normalizedValue === ExpenseTypeIdentifier.status,
      ),
    };
    return tableList.initialRows.map((row) => {
      const name = row.columns[indexOfNameAmountDate.name];
      const amount = row.columns[indexOfNameAmountDate.amount];
      const date = row.columns[indexOfNameAmountDate.date];
      const currency = row.columns[indexOfNameAmountDate.currency];
      const status = row.columns[indexOfNameAmountDate.status];
      const id = row.id;
      return {
        name: name ? name : '',
        amount: amount && !Number.isNaN(amount) ? amount : '',
        date: date ? date : '',
        currency: currency ? currency : '',
        status: status ? status : '',
        behaviourInvokers: row.behaviorInvokers,
        id,
      };
    });
  }
}
