import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/angular-fontawesome/types';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { TableRowState } from 'projects/core/src/lib/models/dynamic-table.model';

@Component({
  selector: 'lib-generic-table-row-skeleton',
  templateUrl: './generic-table-row-skeleton.component.html',
  styleUrls: ['./generic-table-row-skeleton.component.scss'],
})
export class GenericTableRowSkeletonComponent {
  @Input() isHeaderItem = false;
  @Input() rowState: TableRowState;
  @Input() rowIcon: IconDefinition = faChevronRight;

  readonly icons = { chevronRight: faChevronRight };

  get skeletonColumnItemsList(): Array<number> {
    const columnNumber: number = this.resolveSkeletonColumnNumber();
    return Array(columnNumber).fill(0);
  }

  private resolveSkeletonColumnNumber(): number {
    return this.rowState.isDesktopView
      ? this.rowState.columnNumber.current
      : this.skeletonColumnNumberInMobileView;
  }

  get skeletonColumnNumberInMobileView(): number {
    return this.rowState.columnNumber.maximum + 1;
  }
}
