import { Component, Input } from '@angular/core';
import { DocumentDetails, DocumentFilterName } from 'projects/core/src/lib/models/documents.model';

@Component({
  selector: 'lib-document-thumbnail',
  templateUrl: './document-thumbnail.component.html',
  styleUrls: ['./document-thumbnail.component.scss'],
})
export class DocumentThumbnailComponent {
  @Input() document: DocumentDetails;
  @Input() url: string;

  hasError = false;

  resolveColor(type: string) {
    switch (type) {
      case DocumentFilterName.FINDINGS_REPORTS:
        return 'orangered';
      case DocumentFilterName.DOCTORS_LETTERS:
        return 'blue';
      case DocumentFilterName.LAB_RESULTS:
        return 'green';
      case DocumentFilterName.INVOICE:
        return 'purple';
      case DocumentFilterName.DICOM:
        return 'orange';
      default:
        return 'greyish';
    }
  }
}
