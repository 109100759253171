import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from 'projects/core/src/lib/services/language.service';
import dayjs from 'dayjs';

@Pipe({
  name: 'time',
  pure: false,
})
export class TimePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  private format(locale, value, pattern, modificated, extended) {
    const datePipe: DatePipe = new DatePipe(locale);
    if (modificated) {
      switch (locale) {
        case 'de':
          return `${datePipe.transform(value, 'HH:mm')}${extended ? ' Uhr' : ''}`;
        case 'fr':
          return datePipe.transform(value, `H'h'mm`);
        default:
          return datePipe.transform(value, 'shortTime');
      }
    } else {
      return datePipe.transform(value, pattern);
    }
  }

  transform(
    value: any,
    pattern: string = 'shortTime',
    modificated: boolean = false,
    extended: boolean = false,
  ): any {
    if (!value) {return '--:--';}

    const tzValue = dayjs(value).tz().format('YYYY-MM-DDTHH:mm:ss');

    const locale = this.languageService.getCurrentLanguageCode();

    const timezoneFormatted = this.format(locale, tzValue, pattern, modificated, extended);

    return timezoneFormatted;
  }
}
