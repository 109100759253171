import {
  LaboratoryResultGroup,
  LaboratoryResults,
  LaboratoryResultTable,
  LaboratoryResultTableCell,
  LaboratoryResultTableColumn,
  LaboratoryResultTableRow,
  ObservationResultColumnType,
  ObservationResultGroupsResource,
  PatientObservationResultViewResource,
} from 'projects/core/src/lib/models/laboratory-results.model';
import { arrayMove } from 'projects/core/src/lib/utils/array.utils';

export class LaboratoryResultsMapper {
  public static mapResponse(resource: PatientObservationResultViewResource): LaboratoryResults {
    const laboratoryResults: LaboratoryResults = new LaboratoryResults();
    laboratoryResults.chartVisible = resource.observationResultView.chartVisible;
    laboratoryResults.groups = resource.observationResultView.groups.map(
      (group: ObservationResultGroupsResource) => {
        const mappedGroup = new LaboratoryResultGroup();
        mappedGroup.name = group.groupName;
        mappedGroup.tableData = new LaboratoryResultTable();

        this.addTableEmptyRows(group, mappedGroup);
        this.fillFixedColumns(group, mappedGroup);
        this.fillValueColumns(group, mappedGroup);
        this.sortTableColumns(resource.observationResultView.sortAscending, mappedGroup);

        return mappedGroup;
      },
    );

    return laboratoryResults;
  }

  private static fillFixedColumns(
    group: ObservationResultGroupsResource,
    mappedGroup: LaboratoryResultGroup,
  ) {
    group.descriptionColumns.forEach((column, columnIndex) => {
      mappedGroup.tableData.fixedColumns.push(
        new LaboratoryResultTableColumn(
          column.description,
          column.type,
          column.tooltip,
          true,
          columnIndex,
        ),
      );
      column.values.forEach((value, valueIndex) => {
        mappedGroup.tableData.rows[valueIndex].fixedCells.push(
          new LaboratoryResultTableCell(value, columnIndex),
        );
      });
    });
  }

  private static fillValueColumns(
    group: ObservationResultGroupsResource,
    mappedGroup: LaboratoryResultGroup,
  ) {
    group.valueColumns.forEach((column, columnIndex) => {
      mappedGroup.tableData.columns.push(
        new LaboratoryResultTableColumn(
          column.description,
          ObservationResultColumnType.TEXT,
          column.tooltip,
          false,
          columnIndex,
        ),
      );
      column.values.forEach((value, valueIndex) => {
        mappedGroup.tableData.rows[valueIndex].cells.push(
          new LaboratoryResultTableCell(value.value, columnIndex),
        );
      });
    });
  }

  private static addTableEmptyRows(
    group: ObservationResultGroupsResource,
    mappedGroup: LaboratoryResultGroup,
  ) {
    if (group.descriptionColumns?.length > 0) {
      group.descriptionColumns[0].values.forEach(() =>
        mappedGroup.tableData.rows.push(new LaboratoryResultTableRow()),
      );
    } else if (group.valueColumns?.length > 0) {
      group.descriptionColumns[0].values.forEach(() =>
        mappedGroup.tableData.rows.push(new LaboratoryResultTableRow()),
      );
    }
  }

  private static sortTableColumns(sortAscending: boolean, mappedGroup: LaboratoryResultGroup) {
    if (sortAscending) {
      mappedGroup.tableData.columns.sort((a, b) => (a.name > b.name ? 1 : -1));
      mappedGroup.tableData.rows.forEach((row) => {
        for (const cell of row.cells) {
          arrayMove(
            row.cells,
            row.cells.indexOf(cell),
            mappedGroup.tableData.columns.findIndex(
              (column) => column.originalSortIndex === cell.originalSortIndex,
            ),
          );
        }
      });
    } else {
      mappedGroup.tableData.columns.reverse();
      mappedGroup.tableData.rows.forEach((row) => row.cells.reverse());
    }
  }
}
