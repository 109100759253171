export class SkeletonService {
  static getMaxRowAmount(offsetHeader: number, rowHeight: number): number {
    const rowAmount = Math.ceil((window.innerHeight - offsetHeader) / rowHeight);
    return rowAmount >= 0 ? rowAmount : 0;
  }

  static getRowAmountAsArray(offsetHeader: number, rowHeight: number): Array<number> {
    const rowAmount = this.getMaxRowAmount(offsetHeader, rowHeight);
    return Array(rowAmount).fill(0);
  }
}
