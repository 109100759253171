import { AlertBehaviour } from '../models/alert.model';

export class AlertBehaviourPresets {
  static persistent: AlertBehaviour = {
    backdropDismiss: false,
  };

  static persistentTimed(seconds: number): AlertBehaviour {
    return {
      backdropDismiss: false,
      timeout: seconds,
    };
  }

  static persistentAndModalDestructive: AlertBehaviour = {
    backdropDismiss: false,
    dismissOtherOnDismiss: true,
  };

  static userInfo: AlertBehaviour = {
    backdropDismiss: true,
  };
}
