import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { AlertBehaviourPresets } from 'projects/core/src/lib/data/alert-behavior.data';
import { AlertMessagesData } from 'projects/core/src/lib/data/alert-messages.data';
import { FormMapper } from 'projects/core/src/lib/mappers/form.mapper';
import { SelectionObject } from 'projects/core/src/lib/models/form.model';
import { AlertHandlerActions } from 'projects/core/src/lib/services/alert-handler.service';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { SDAPIService } from 'projects/core/src/lib/services/sdapi.service';
import { BaseFieldComponent } from 'projects/shared/src/lib/components/form/base-field/base-field.component';
import { BehaviorSubject, debounceTime, firstValueFrom, throttleTime } from 'rxjs';
import { filterOptions } from '../../../functions/selection-options-filter.functions';
import { SelectionFieldPopoverComponent } from '../selection-field-popover/selection-field-popover.component';

@Component({
  selector: 'lib-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  styleUrls: ['./autocomplete-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AutocompleteFieldComponent extends BaseFieldComponent implements AfterContentInit {
  @ViewChild('input') formElement: NgModel;
  @ViewChildren('searchListItem', { read: ElementRef }) searchListItems: QueryList<ElementRef>;
  @ViewChild('selectionElement') selectionElement: ElementRef<HTMLInputElement>;

  searchValue = '';
  searchValueBehaviourSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  containerWidth: number;
  hasFocus = false;

  constructor(
    private popoverController: PopoverController,
    private cdr: ChangeDetectorRef,
    private sdapiService: SDAPIService,
    private destroyRef: DestroyRef,
    private alertService: AlertService,
  ) {
    super();
  }

  get options(): SelectionObject[] {
    const currentValue = this.searchValue ?? '';
    const currentOptions = this.item.value?.options ?? [];
    return filterOptions(currentOptions, currentValue, 10);
  }

  get isPopupMandatory(): boolean {
    return !!this.item.value.autocompleteOptions?.popupMandatory;
  }

  get currentSelectedOptionText(): string {
    return this.searchValue;
  }

  get showAutocompleteOptions(): boolean {
    const hasOptions = this.options?.length > 0;
    const selectionIsSameAsOptions =
      hasOptions && this.item.value.options.find((option) => option.value === this.searchValue);
    return (
      !this.isPopupMandatory &&
      this.isEditableField &&
      this.options?.length &&
      this.hasFocus &&
      !selectionIsSameAsOptions
    );
  }

  override ngAfterContentInit(): void {
    super.ngAfterContentInit();
    this.subscribeAutocompleteOptionsUpdate();
    this.subscribeExternalValueUpdate();
    this.resetSearchValue();
  }

  private subscribeExternalValueUpdate() {
    this.item.value
      .getValueObserver()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((identifier) => {
        const option = this.item.value.options?.find((opt) => opt.identifier === identifier);
        if (option) {
          this.searchValue = option.value;
        }
        if (identifier === '') {
          this.searchValue = '';
        }
      });
  }

  setInternalValueFromOptions() {
    const selectedOption = this.item.value.options.find(
      (option) => option.value === this.searchValue,
    );
    if (selectedOption) {
      this.item.value.value = selectedOption.identifier;
    } else if (!this.isPopupMandatory) {
      this.item.value.value = this.searchValue;
    }
    this.formElement.control.updateValueAndValidity();
  }

  triggerChanges() {
    this.searchValueBehaviourSubject.next(this.searchValue);
  }

  setHasFocusTrue() {
    this.hasFocus = true;
  }

  setSelection(selectedOption: SelectionObject) {
    if (selectedOption) {
      this.searchValue = selectedOption.value;
      this.item.value.value = selectedOption.identifier;
      this.searchValueBehaviourSubject.next(this.searchValue);
    }
    this.unsetFocus();
    this.setFocusToSelectionElement();
  }

  setFocusToSelectionElement() {
    this.selectionElement.nativeElement?.focus();
  }

  resetSearchValue() {
    this.searchValue = this.item.value.autocompleteOptions?.originalDisplayValue ?? '';
    this.item.value.value = this.item.value.originalValue ?? '';
    this.searchValueBehaviourSubject.next(this.searchValue);
  }

  unsetFocus(event?: any) {
    const targetAfterBlurIsAutoselectOptionAfterPressingTab =
      event?.relatedTarget?.classList?.contains('js-autocomplete-option');
    if (!targetAfterBlurIsAutoselectOptionAfterPressingTab) {
      this.hasFocus = false;
    }
  }

  async openSelect() {
    if (this.isEditableField) {
      this.searchValueBehaviourSubject.next('');
      const popover = await this.popoverController.create({
        component: SelectionFieldPopoverComponent,
        translucent: true,
        trigger: 'select-' + this.customHtmlId,
        reference: 'trigger',
        alignment: 'center',
        side: 'bottom',
        animated: true,
        arrow: true,
        id: 'options-popover',
        componentProps: {
          item: this.item,
          options: this.item.value.options,
          selectedOption: this.getCurrentSelection(),
          triggerOnSearchSubject: this.searchValueBehaviourSubject,
        },
      });
      await popover.present();
      await popover.onDidDismiss<SelectionObject>().then(async (data) => {
        this.setSelection(data.data);
      });
    }
  }

  getCurrentSelection(): SelectionObject {
    return this.item.value.options.find(({ identifier }) => identifier === this.item.value.value);
  }

  focusFirstListElement() {
    const firstItem = this.searchListItems.first;
    if (firstItem) {
      firstItem.nativeElement.focus();
    }
  }

  focusLastListElement() {
    const lastItem = this.searchListItems.last;
    if (lastItem) {
      lastItem.nativeElement.focus();
    }
  }

  private subscribeAutocompleteOptionsUpdate() {
    this.searchValueBehaviourSubject
      .pipe(debounceTime(150), throttleTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe(async (value) => {
        try {
          if (!this.item.value.autocompleteOptions.isLoadingOptions) {
            this.item.value.autocompleteOptions.isLoadingOptions = true;
            const updates = await firstValueFrom(
              this.sdapiService.getOptionsForAutoCompleteField(
                this.item.value.autocompleteOptions,
                value,
              ),
            );
            const combinedOptions = [
              ...(this.item.value.options ?? []),
              ...FormMapper.mapOptionsUpdate(updates),
            ];
            const uniqueOptions = combinedOptions.filter(
              (option, index, self) =>
                index === self.findIndex((t) => t.identifier === option.identifier),
            );
            this.item.value.options = uniqueOptions;
            this.setInternalValueFromOptions();
            this.cdr.detectChanges();
          }
        } catch (error) {
          this.alertService.presentAlert(
            AlertMessagesData.optionsLoadingError,
            AlertHandlerActions.confirmable,
            AlertBehaviourPresets.userInfo,
          );
          throw error;
        } finally {
          this.item.value.autocompleteOptions.isLoadingOptions = false;
        }
      });
  }
}
