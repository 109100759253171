import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class VirtualScrollService {
  constructor(private platform: Platform) {}

  public getBufferPx(valueFor: 'min' | 'max'): number {
    let height = this.platform.height();
    if (valueFor === 'max') {height *= 2;}
    return height;
  }
}
