<lib-modal-header [title]="title"></lib-modal-header>

<ion-content [scrollY]="false">
  <lib-generic-table-container
    class="stretch-container fill-height"
    [data]="data"
    [isRowDataLoading]="isRowDataLoading"
    [isCard]="false"
    (eventMessage)="resolveEventMessage($event)">
    @if (dynamicTableConfiguration.actionButtons) {
      <lib-auto-layout actionButtonCollection class="toolbar-item card-layout" [gap]="[1]">
        @for (button of dynamicTableConfiguration.actionButtons; track $index) {
          <ion-button
            color="primary"
            class="ion-no-margin"
            (click)="dismissModalWithActionEvent(button)">
            {{ button.invoker.presentation.label }}
          </ion-button>
        }
      </lib-auto-layout>
    }
  </lib-generic-table-container>
</ion-content>
