import { Component, Input } from '@angular/core';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import {
  AppointmentDetails,
  ViewAppointmentsGroup,
} from 'projects/core/src/lib/models/appointments.model';

@Component({
  selector: 'lib-appointment-date-divider',
  templateUrl: './appointment-date-divider.component.html',
  styleUrl: './appointment-date-divider.component.scss',
})
export class AppointmentDateDividerComponent {
  @Input() selectedDate?: Date;
  @Input() selectedAppointment?: AppointmentDetails;
  @Input() mappedAppointmentGroups: ViewAppointmentsGroup;
  @Input() date: Date;

  readonly icons = {
    day: faCalendarDays,
  };

  isFutureAppointment(date: Date): boolean {
    return date.getTime() >= new Date(new Date().toDateString()).getTime();
  }

  isToday(date?: Date): boolean {
    if (date) {return date.toDateString() === new Date().toDateString();}
    else {return this.selectedDate.toDateString() === new Date().toDateString();}
  }
}
