<ion-header>
  <ion-toolbar style="--padding-start: 10px; --padding-end: 10px">
    <h2 slot="start" class="ion-no-margin">{{ 'general.edit' | translate }}</h2>
    <ion-button slot="end" (click)="cancel()" class="p3" size="small" color="tertiary">
      <p slot="start">{{ 'general.cancel' | translate }}</p>
      <fa-icon [icon]="ICONS.CLOSE" slot="end"></fa-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form #form="ngForm" (ngSubmit)="send()" class="safe-area">
    <ion-list class="card-list top">
      <ng-container *ngFor="let item of list; let i = index">
        <ion-item
          *ngIf="
            item.editable &&
            item.type.toLowerCase() !== 'date' &&
            item.type.toLowerCase() !== 'gender'
          "
          lines="none">
          <ion-label color="medium" position="stacked" id="{{ 'data-exchange-form-input-' + i }}">
            <span class="name">{{ item.name }}</span>
            <span class="required" *ngIf="item.required && name.valid">
              ({{ 'general.mandatory' | translate }})</span
            >
            <strong class="required" *ngIf="item.required && !name.valid">
              ({{ 'general.mandatory' | translate }})</strong
            >
          </ion-label>
          <ion-input
            [attr.aria-labelledby]="'data-exchange-form-input-' + i"
            class="color-medium"
            [ngClass]="{ edited: !equalsOrigin(item) }"
            [required]="item.required"
            [(ngModel)]="item.value"
            [name]="item.name"
            [placeholder]="item.name + '…'"
            [type]="item.type"
            [email]="item.type === 'email' ? 'true' : 'false'"
            [pattern]="item.type === 'tel' ? telNumberPattern : ''"
            maxlength="100"
            #name="ngModel">
          </ion-input>
          <span slot="error" *ngIf="name.dirty" class="invalid label-input">
            <span *ngIf="name.invalid && !name.errors?.['required']" class="invalid">
              {{ item.inputErrorMessage }}
            </span>
          </span>
          <ion-button
            color="tertiary"
            *ngIf="!equalsOrigin(item)"
            class="p"
            style="align-self: end; margin-bottom: 0; margin-right: 0"
            slot="end"
            (click)="reset(item)">
            <fa-icon [icon]="ICONS.RESET" slot="icon-only"></fa-icon>
          </ion-button>
        </ion-item>

        <ion-item *ngIf="item.editable && item.type.toLowerCase() === 'gender'" lines="none">
          <ion-label color="medium" position="stacked" id="{{ 'data-exchange-form-select-' + i }}">
            <span class="name">{{ item.name }}</span>
            <span class="required" *ngIf="item.required && gender.valid">
              ({{ 'general.mandatory' | translate }})</span
            >
            <strong class="required" *ngIf="item.required && !gender.valid">
              ({{ 'general.mandatory' | translate }})</strong
            >
          </ion-label>
          <ion-select
            [attr.aria-labelledby]="'data-exchange-form-select-' + i"
            [(ngModel)]="item.value"
            #gender="ngModel"
            [name]="item.name"
            class="color-medium p"
            required
            interface="popover"
            placeholder="{{ 'general.reason' | translate }}">
            <ion-select-option value="female">{{
              'general.female' | translate | lowercase
            }}</ion-select-option>
            <ion-select-option value="male">{{
              'general.male' | translate | lowercase
            }}</ion-select-option>
            <ion-select-option value="other">{{
              'general.other' | translate | lowercase
            }}</ion-select-option>
            <ion-select-option value="unknown">{{
              'general.unknown-gender' | translate
            }}</ion-select-option>
          </ion-select>
          <span slot="error" *ngIf="gender.dirty" class="invalid label-input">
            <span *ngIf="gender.invalid && !gender.errors?.['required']" class="invalid">
              {{ item.inputErrorMessage }}
            </span>
          </span>
        </ion-item>

        <ion-accordion-group *ngIf="item.editable && item.type.toLowerCase() === 'date'">
          <ion-accordion toggleIcon="calendar">
            <ion-item slot="header" class="ion-no-padding" color="light" lines="none">
              <div class="ion-accordion-toggle-icon" style="display: none"></div>
              <ion-label
                color="medium"
                position="stacked"
                class="ion-text-wrap visible"
                id="{{ 'data-exchange-form-datetime-' + i }}">
                <span class="name">{{ item.name }}</span>
                <span class="required" *ngIf="item.required">
                  ({{ 'general.mandatory' | translate }})</span
                >
                <strong class="required" *ngIf="item.required">
                  ({{ 'general.mandatory' | translate }})
                </strong>
              </ion-label>
              <p class="input ion-input" [ngClass]="{ edited: !equalsOrigin(item) }">
                {{ item.value | localeDate }}
              </p>
              <ion-button
                color="tertiary"
                *ngIf="!equalsOrigin(item)"
                class="p"
                style="align-self: end; margin-bottom: 0; margin-right: 0"
                slot="end"
                (click)="reset(item)">
                <fa-icon [icon]="ICONS.RESET" slot="icon-only"></fa-icon>
              </ion-button>
            </ion-item>
            <ion-datetime
              [attr.aria-labelledby]="'data-exchange-form-datetime-' + i"
              [(ngModel)]="item.value"
              name="date"
              slot="content"
              color="medium"
              firstDayOfWeek="1"
              presentation="date"></ion-datetime>
          </ion-accordion>
        </ion-accordion-group>
      </ng-container>
    </ion-list>
    <div class="ion-margin button-section">
      <ion-button expand="block" color="primary" class="p3" type="submit" [disabled]="!form.valid">
        {{ 'general.save' | translate }}
      </ion-button>
    </div>
  </form>
</ion-content>
