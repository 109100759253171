import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IonDatetime, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { DynamicDataField } from 'projects/core/src/lib/models/form.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { LanguageService } from 'projects/core/src/lib/services/language.service';
import { LocaleDatePipe } from 'projects/theme/src/lib/pipes/locale-date.pipe';

@Component({
  selector: 'lib-date-field-date-popover',
  templateUrl: './date-field-date-popover.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrls: ['./date-field-date-popover.component.scss'],
})
export class DateFieldDatePopoverComponent implements OnInit {
  @Input() value: string;
  @Input() name: string;
  @Input() dates: string[];
  @Input() item?: DynamicDataField;

  @ViewChild('datePicker') datePicker: IonDatetime;

  minDate: string | undefined;

  maxDate: string | undefined;

  constructor(
    private popoverController: PopoverController,
    public languageService: LanguageService,
    public breakPointService: BreakpointService,
    public translateService: TranslateService,
    private localeDatePipe: LocaleDatePipe,
    private cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.setMinMaxDate();
    this.goToFirstDate();
    this.setInitialDate();
  }

  setInitialDate() {
    if (!this.value) {return;}
    if (this.checkDates(this.value)) {
      this.value = this.value;
    }
  }

  get yearDateSelectionDisabled(): boolean {
    return this.dates.length > 0;
  }

  async goToFirstDate() {
    this.cdr.detectChanges();
    if (!this.datePicker || (!this.dates?.length && !this.minDate)) {
      if (!this.value) {
        this.datePicker?.reset();
      }
      return;
    }
    let minDate = dayjs().toISOString();
    if (new Date(this.minDate) > new Date(minDate)) {
      minDate = this.minDate;
    }

    if (this.dates.length > 0) {
      this.dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

      const firstDate = this.dates.find((date) => new Date(date) >= new Date(this.minDate));
      if (firstDate && !(this.maxDate && new Date(firstDate) > new Date(this.maxDate))) {
        minDate = firstDate;
      }
    }
    if (minDate) {
      this.datePicker?.reset(minDate);
    }
  }

  setMinMaxDate() {
    let minDate: dayjs.Dayjs | null = null;
    let maxDate: dayjs.Dayjs | null = null;

    if (this.item?.value?.limiters?.minrel) {
      minDate = dayjs(this.item.value.limiters.minrel);
      minDate = minDate.isValid() ? minDate : null;
    }

    if (this.item?.value?.limiters?.maxrel) {
      maxDate = dayjs(this.item.value.limiters.maxrel);
      maxDate = maxDate.isValid() ? maxDate : null;
    }

    if (this.item?.value?.limiters?.min) {
      const min = dayjs(this.item.value.limiters.min);
      if (min.isValid() && (!minDate || dayjs(min).isAfter(minDate))) {
        minDate = min;
      }
    }

    if (this.item?.value?.limiters?.max) {
      const max = dayjs(this.item.value.limiters.max);
      if (max.isValid() && (!maxDate || dayjs(max).isBefore(maxDate))) {
        maxDate = max;
      }
    }

    if (this.dates?.length) {
      const minDateFromDates = dayjs(
        Math.min(...this.dates.map((date) => new Date(date).getTime())),
      );
      if (!minDate || dayjs(minDateFromDates).isAfter(minDate)) {
        minDate = minDateFromDates;
      }
      const maxDateFromDates = dayjs(
        Math.max(...this.dates.map((date) => new Date(date).getTime())),
      );
      if (!maxDate || dayjs(maxDateFromDates).isBefore(maxDate)) {
        maxDate = maxDateFromDates;
      }
    }

    if (minDate) {
      this.minDate = this.getLocaledDateString(minDate.tz().startOf('day').toDate());
    }
    if (maxDate) {
      this.maxDate = this.getLocaledDateString(maxDate.tz().endOf('day').toDate());
    }
  }

  showDesktopAnimation(): boolean {
    return this.breakPointService.isAbove('md');
  }

  async onDateChange(changeEvent: any): Promise<void> {
    this.value = changeEvent.detail.value;
  }

  async confirmBtn() {
    if (await this.popoverController.getTop()) {
      await this.popoverController.dismiss(this.value, 'dateChanged', 'date-field-date-popover');
    }
  }
  async cancelBtn() {
    if (await this.popoverController.getTop()) {
      await this.popoverController.dismiss(null, 'dateChanged', 'date-field-date-popover');
    }
  }

  checkDates = (dateString: string): boolean => {
    if (!this.dates?.length) {return true;}
    return this.dates.map((date) => this.getLocaledDateString(date)).includes(dateString);
  };

  private getLocaledDateString(date: any): string {
    return this.localeDatePipe.transform(date, 'yyyy-MM-dd');
  }
}
