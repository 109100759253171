export const loadPdfViewer = async (): Promise<void> => {
  try {
    if (window['pdfWorkerSrc']) {
      return;
    }
    const url = 'assets/js/pdf.worker.min.mjs';
    const response = await fetch(url);
    const scriptText = await response.text();
    const blob = new Blob([scriptText], { type: 'text/javascript' });
    const blobUrl = URL.createObjectURL(blob);
    (window as any).pdfWorkerSrc = blobUrl;
  } catch (error) {
    console.error('Failed to load external script:', error);
  }
};
