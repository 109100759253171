import { Injectable } from '@angular/core';
import { DynamicDataField, DynamicForm } from 'projects/core/src/lib//models/form.model';
import { ProfileFieldKey } from 'projects/core/src/lib//models/profile.model';
import { AttributeNameIdentifier } from '../models/shared.model';

@Injectable()
export class ProfileFormService {
  public filterFormFields(
    form: DynamicForm,
    fieldKeys?: ProfileFieldKey[],
    groupIndex?: number,
  ): DynamicDataField[] {
    if (fieldKeys) {
      return this.filterFormFieldsByKeyNames(form, fieldKeys);
    }
    if (groupIndex !== undefined) {
      return this.filterFormFieldsByGroupIndex(form, groupIndex);
    }

    console.warn(
      'No filter criteria for profile form fields have been provided in ProfileFormService.',
    );
    return form.body;
  }

  private filterFormFieldsByKeyNames(
    form: DynamicForm,
    fieldKeys: ProfileFieldKey[],
  ): DynamicDataField[] {
    const relevantGroups: DynamicDataField[] = this.extractGroupsWithMatchingFieldKeys(
      form,
      fieldKeys,
    );
    const filteredGroups: DynamicDataField[] = this.filterItemsInGroupsByFieldKeys(
      relevantGroups,
      fieldKeys,
    );

    return filteredGroups;
  }

  private extractGroupsWithMatchingFieldKeys(
    form: DynamicForm,
    fieldKeys: ProfileFieldKey[],
  ): DynamicDataField[] {
    const filtered = form.body.filter((group: DynamicDataField) => (group.fieldGroup || [group])?.some((field: DynamicDataField) =>
        this.isIdentifierMatchingFieldKey(field, fieldKeys),
      ));
    return filtered;
  }

  private filterItemsInGroupsByFieldKeys(
    relevantGroups: DynamicDataField[],
    fieldKeys: ProfileFieldKey[],
  ): DynamicDataField[] {
    relevantGroups.forEach((group: DynamicDataField) => {
      group.fieldGroup = (group.fieldGroup || [group]).filter((field: DynamicDataField) =>
        this.isIdentifierMatchingFieldKey(field, fieldKeys),
      );
    });

    return relevantGroups;
  }

  private isIdentifierMatchingFieldKey(
    field: DynamicDataField,
    fieldKeys: ProfileFieldKey[],
  ): boolean {
    return fieldKeys.some((key: ProfileFieldKey) =>
      field.identifier.isEqualTo(new AttributeNameIdentifier(key)),
    );
  }

  private filterFormFieldsByGroupIndex(form: DynamicForm, groupIndex: number): DynamicDataField[] {
    return form.body.filter((_group: DynamicDataField, index: number) => index === groupIndex);
  }
}
