import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { faArrowRight, faFingerprint } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { WINDOW } from 'projects/core/src/lib/injection-tokens';
import {
  IframePostMessageAction,
  iframePostMessageEventPrefix,
} from 'projects/core/src/lib/models/auto-ident.model';
import { SkeletonHeaderOffset } from 'projects/core/src/lib/models/dynamic-table.model';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';
import { AutoIdentService } from 'projects/core/src/lib/services/auto-ident.service';
import { DomSanitizerService } from 'projects/core/src/lib/services/dom-sanitizer.service';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';
import { ModalHeaderStyle } from '../../modal-helper/header/header.component';

@Component({
  selector: 'lib-auto-ident-information',
  templateUrl: './auto-ident-iframe.component.html',
  styleUrls: ['./auto-ident-iframe.component.scss'],
})
export class AutoIdentIframeComponent implements OnInit {
  @HostBinding('class') class = 'medium-modal';

  @ViewChild('iframe') iframe: ElementRef<HTMLIFrameElement>;

  @Input()
  url: string;

  @Input() title: string;

  readonly headerOffset: SkeletonHeaderOffset = { mobile: 210, desktop: 520 };
  readonly expandedViewThreshold: number = 600;
  readonly icons = { arrowRight: faArrowRight, fingerprint: faFingerprint };
  readonly modalHeaderStyle: string = ModalHeaderStyle.edit;

  isLoading = true;
  sanitizedURL: SafeResourceUrl;

  searchParams: SearchParams = new SearchParams();
  isRowDataLoading: boolean;

  constructor(
    private modalController: ModalController,
    private domSanitizerService: DomSanitizerService,
    private autoIdentService: AutoIdentService,
    @Inject(WINDOW) private window: Window,
  ) {}

  async cancel(): Promise<void> {
    await this.modalController.dismiss(null, OverlayEventRole.cancel);
  }

  ngOnInit(): void {
    this.prepareUrlForIframe();
    this.startPostMessageListener();
  }

  hideLoader() {
    setTimeout(() => {
      this.isLoading = false;
    });
  }

  private prepareUrlForIframe(): void {
    this.sanitizedURL = this.domSanitizerService.sanitizeResourceUrl(this.url);
  }

  private startPostMessageListener() {
    this.window.addEventListener('message', this.handlePostMessage.bind(this), false);
  }

  private async handlePostMessage(event: MessageEvent) {
    if (event.origin !== window.location.origin) {
      return;
    }
    if (event.data === `${iframePostMessageEventPrefix}:${IframePostMessageAction.success}`) {
      this.autoIdentService.blockShowingAutoIdentForTenMinutes();
      await this.modalController.dismiss(null, OverlayEventRole.cancel);
    }
    if (event.data === `${iframePostMessageEventPrefix}:${IframePostMessageAction.aborted}`) {
      await this.modalController.dismiss(null, OverlayEventRole.cancel);
    }
  }
}
