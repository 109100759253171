<lib-auto-layout
  direction="horizontal"
  vertical="stretch"
  class="stretch-self card"
  [gap]="[20]"
  [padding]="[10, 15]">
  <lib-auto-layout class="trim-text-inline" direction="vertical" [grow]="1" [gap]="[10]">
    <span class="h3 action-title trim-text-inline">{{ requiredAction.title }}</span>

    <div class="action-status">
      <span>{{ requiredAction.task.value }}</span>
    </div>

    <lib-auto-layout
      class="action-date"
      [ngClass]="{
        urgent: requiredAction.isUrgent,
        past: requiredAction.isOverdue
      }"
      direction="horizontal"
      [gap]="[10]"
      vertical="center">
      <fa-icon [icon]="icons.calendar"></fa-icon>
      <span class="trim-text-inline strong">
        {{ 'required-actions.due-to' | translate }} {{ requiredAction.endDate.value | localeDate }}
      </span>
    </lib-auto-layout>
  </lib-auto-layout>

  <lib-auto-layout direction="vertical" vertical="space-between">
    <ion-button color="primary" class="icon-only" (click)="openRequiredAction.emit()">
      <fa-icon [icon]="icons.arrow"></fa-icon>
    </ion-button>
    <ion-button color="tertiary" class="icon-only" (click)="viewTaskInformation.emit()">
      <fa-icon [icon]="icons.info"></fa-icon>
    </ion-button>
  </lib-auto-layout>
</lib-auto-layout>
