import { Injectable } from '@angular/core';

@Injectable()
export class BreakpointService {
  getCurrentBreakpointIdentifier(): BreakpointIdentifier {
    const index = breakpoints.findIndex((breakpoint) => breakpoint <= window.innerWidth);
    return breakpointIdentifier[index];
  }

  getCurrentBreakpointSpot(): BreakpointSpots {
    return breakpointIndex.get(this.getCurrentBreakpointIdentifier());
  }

  isBelow(breakpoint: BreakpointIdentifier): boolean {
    return breakpointIndex.get(breakpoint) > this.getCurrentBreakpointSpot();
  }

  isAbove(breakpoint: BreakpointIdentifier): boolean {
    return breakpointIndex.get(breakpoint) < this.getCurrentBreakpointSpot();
  }
}

export type BreakpointIdentifier = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type BreakpointSpots = 0 | 576 | 768 | 992 | 1200 | 1400;

export const breakpointIndex: Map<BreakpointIdentifier, BreakpointSpots> = new Map([
  ['xs', 0],
  ['sm', 576],
  ['md', 768],
  ['lg', 992],
  ['xl', 1200],
  ['xxl', 1400],
]);

export const breakpoints: BreakpointSpots[] = [1400, 1200, 992, 768, 576, 0];

export const breakpointIdentifier: BreakpointIdentifier[] = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];

export const enum ElementBreakpoint {
  MOBILE = 368,
  MOBILE_LANDSCAPE = 576,
}
