import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import {
  EventMessage,
  EventMessageType,
  TableHeaderItem,
  TableList,
  TableListItem,
  TableRowState,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { TableDataService } from 'projects/core/src/lib/services/table-data.service';
import { TableValueByTypePipe } from 'projects/theme/src/lib/pipes/table-value-by-type.pipe';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-generic-expandable-row-mobile',
  templateUrl: './generic-expandable-row-mobile.component.html',
  styleUrls: ['./generic-expandable-row-mobile.component.scss', '../shared-generic-table-row.scss'],
})
export class GenericExpandableRowMobileComponent implements OnInit {
  @Output() eventMessage: EventEmitter<EventMessage> = new EventEmitter();

  @Input() table: TableList;
  @Input() row: TableListItem;
  @Input() state: TableRowState;

  isCompact = true;
  primaryRowItemCount: number;
  infoTranslation: string;

  readonly icons = {
    caretDown: faCaretDown,
    caretUp: faCaretUp,
    square: faSquare,
    squareCheck: faSquareCheck,
    check: faCheck,
  };

  constructor(
    private valueByTypePipe: TableValueByTypePipe,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.primaryRowItemCount = this.state.columnNumber.current;
    this.infoTranslation = await firstValueFrom(
      this.translateService.get('shared.tables.additional'),
    );
  }

  get visibleHeaderItems(): TableHeaderItem[] {
    return TableDataService.getVisibleTableItemList<TableHeaderItem>(
      this.table.sortedHeader,
      this.primaryRowItemCount,
    );
  }

  get visibleMobileRowValues(): string[] {
    return TableDataService.getVisibleTableItemList<string>(
      this.row.columns,
      this.primaryRowItemCount,
    );
  }

  get secondaryVisibleHeaderItem(): TableHeaderItem {
    return this.table.sortedHeader[this.primaryRowItemCount];
  }

  get secondaryVisibleRowValue(): string {
    return this.row.columns[this.primaryRowItemCount];
  }

  get hiddenItemInformation(): string {
    const value: string = this.valueByTypePipe.transform(
      this.secondaryVisibleRowValue,
      this.primaryRowItemCount,
      this.table,
    );

    return `${this.secondaryVisibleHeaderItem?.value} : ${value}, ${this.hiddenRowValues.length - 1} ${this.infoTranslation}`;
  }

  get hiddenHeaderItems(): TableHeaderItem[] {
    return TableDataService.getHiddenTableItemList<TableHeaderItem>(
      this.table.sortedHeader,
      this.primaryRowItemCount,
    );
  }

  get hiddenRowValues(): string[] {
    return TableDataService.getHiddenTableItemList<string>(
      this.row.columns,
      this.primaryRowItemCount,
    );
  }

  expandRowDetails(): void {
    this.isCompact = !this.isCompact;
  }

  emitClickEventMessage(): void {
    this.eventMessage.emit({
      type: EventMessageType.CLICK,
      data: this.row.id,
      behaviorInvokers: this.row.behaviorInvokers,
    });
  }

  emitSelectEventMessageAndToggleRowSelection(): void {
    this.row.selected = !this.row.selected;
    this.eventMessage.emit({
      type: EventMessageType.SELECT,
      data: this.row.id,
      behaviorInvokers: this.row.behaviorInvokers,
    });
  }
}
