import { Component } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { BaseFieldComponent } from 'projects/shared/src/lib/components/form/base-field/base-field.component';

@Component({
  selector: 'lib-file-input-field',
  templateUrl: './file-input-field.component.html',
  styleUrls: ['./file-input-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FileInputFieldComponent extends BaseFieldComponent {
  file: File;
  fileName = '';

  fileChanged(fileChangeEvent): void {
    const fileFromTarget = fileChangeEvent.target.files?.[0];
    if (fileFromTarget) {
      this.item.file = fileFromTarget;
      this.item.value.value = fileFromTarget.name || '';
    }
  }

  get fileRestrictions(): string {
    return this.item.fileTypeData?.map((fileType) => fileType.extension).join(',');
  }
}
