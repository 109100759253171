import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { AlertHandlerActions } from './alert-handler.service';
import { AlertMessagesData } from '../data/alert-messages.data';
import { filter, map } from 'rxjs/operators';
import { from, mergeMap } from 'rxjs';

@Injectable()
export class AppUpdateService {
  constructor(
    private readonly updates: SwUpdate,
    private alertService: AlertService,
    public alertController: AlertController,
  ) {}

  callUpdate(): void {
    if (this.updates.isEnabled) {
      this.updates.versionUpdates
        .pipe(
          filter(
            (event: VersionEvent): event is VersionReadyEvent => event.type === 'VERSION_READY',
          ),
          map((versionReadyEvent: VersionReadyEvent) => ({
            type: 'UPDATE_AVAILABLE',
            current: versionReadyEvent.currentVersion,
            available: versionReadyEvent.latestVersion,
          })),
          mergeMap(() =>
            from(
              this.alertService.presentAlert(
                AlertMessagesData.appUpdateAlert,
                AlertHandlerActions.appUpdateAction,
              ),
            ),
          ),
          filter(({ role }) => role === 'confirm'),
        )
        .subscribe(() => {
          this.activateUpdate();
        });
    }
  }

  private activateUpdate(): void {
    this.updates
      .activateUpdate()
      .then(() => window.location.reload())
      .catch(() => this.alertService.presentAlert(AlertMessagesData.appUpdateError));
  }
}
