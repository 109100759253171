import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import {
  ColumnCountInMobile,
  EventMessage,
  EventMessageType,
  TableDataType,
  TableList,
  TableListItem,
  TableRowState,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { TableDataService } from 'projects/core/src/lib/services/table-data.service';

@Component({
  selector: 'lib-generic-table-row',
  templateUrl: './generic-table-row.component.html',
  styleUrls: ['./generic-table-row.component.scss'],
})
export class GenericTableRowComponent {
  @Output() eventMessage: EventEmitter<EventMessage> = new EventEmitter();

  @Input() table: TableList;
  @Input() row: TableListItem;
  @Input() rowState: TableRowState;

  tableDataType = TableDataType;

  readonly icons = {
    squareCheck: faSquareCheck,
    square: faSquare,
    check: faCheck,
  };

  get visibleDesktopRowValues(): string[] {
    return TableDataService.getVisibleTableItemList<string>(
      this.row.columns,
      this.rowState.columnNumber.current,
    );
  }

  get shouldShowCondensedView(): boolean {
    return this.row.columns.length <= ColumnCountInMobile.condensed;
  }

  get hasHiddenHeaderItems(): boolean {
    return TableDataService.getHiddenHeaderItemCount(this.table, this.rowState) > 0;
  }

  emitClickEventMessage(): void {
    this.eventMessage.emit({
      type: EventMessageType.CLICK,
      data: this.row.id,
      behaviorInvokers: this.row.behaviorInvokers,
    });
  }

  emitSelectEventMessageAndToggleRowSelection($event: MouseEvent): void {
    $event.stopPropagation();
    this.row.selected = !this.row.selected;
    this.eventMessage.emit({
      type: EventMessageType.SELECT,
      data: this.row.id,
      behaviorInvokers: this.row.behaviorInvokers,
    });
  }
}
