<ion-list class="popover-item-list">
  @if (document.fileType) {
    <ion-item id="file-type" class="p information" lines="none" button="false">
      <div class="icon-container">
        <fa-icon [icon]="document.fileType | documentFormatToIconPipe" class="greyish"></fa-icon>
      </div>
      <span class="label">
        {{ 'shared.documents.file-type' | translate }}: {{ document.fileType }}
      </span>
    </ion-item>
  }
  @if (document.size) {
    <ion-item id="file-size" class="p information" lines="none" button="false">
      <div class="icon-container">
        <fa-icon [icon]="icons.size" class="greyish"></fa-icon>
      </div>
      <span class="label">
        {{ 'shared.documents.file-size' | translate }}: {{ document.size | filesize }}
      </span>
    </ion-item>
  }
</ion-list>
