<input
  class="invisible-input hidden-form-element"
  label-placement="stacked"
  [id]="customHtmlId"
  [(ngModel)]="item.value.value"
  [name]="item.identifier.originalValue"
  #input="ngModel"
  hidden />

<pp-base-field
  [item]="item"
  [customHtmlId]="customHtmlId"
  [input]="input"
  [preview]="preview"
  (resetValueEvent)="resetSearchValue()">
  @if (isPopupMandatory) {
    <ng-container inputPart>
      <div
        [id]="'select-' + customHtmlId"
        class="select"
        (click)="openSelect()"
        (keyup.enter)="openSelect()"
        [class.no-value-selected]="!item.value.value"
        [class.edited]="item.isModified && item.value.value"
        [class.disabled]="!isEditableField"
        [tabindex]="tabIndex"
        (blur)="ensureFieldMarkedAsTouched(input)"
        [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
        [attr.aria-required]="item.required"
        [class.no-value-selected]="!item.value.value"
        #selectionElement>
        <span class="select-value">{{
          currentSelectedOptionText || placeholderKey | translate
        }}</span>
      </div>
    </ng-container>
  } @else {
    <ng-container inputPart>
      <input
        [disabled]="!isEditableField"
        [placeholder]="placeholderKey | translate"
        (focus)="setHasFocusTrue()"
        (click)="setHasFocusTrue()"
        (keyup)="triggerChanges()"
        (change)="triggerChanges()"
        (keyup.arrowdown)="focusFirstListElement()"
        (keyup.arrowup)="focusLastListElement()"
        type="text"
        [id]="'autocomplete-' + customHtmlId"
        class="autocomplete"
        [class.edited]="item.isModified && item.value.value"
        [class.disabled]="!isEditableField"
        [tabindex]="tabIndex"
        (blur)="ensureFieldMarkedAsTouched(input); unsetFocus($event)"
        [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
        [attr.aria-required]="item.required"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="searchValue"
        #selectionElement />
    </ng-container>
  }
</pp-base-field>

@if (showAutocompleteOptions) {
  <div class="autocomplete-list-container">
    <ion-list class="autocomplete-list" tabindex="-1">
      @for (option of options; track option.identifier; let isLastElement = $last) {
        <ion-item
          class="autocomplete-field-popover-item js-autocomplete-option"
          (click)="setSelection(option)"
          (keyup.enter)="setSelection(option)"
          button="true"
          tabindex="0"
          detail="false"
          [lines]="isLastElement ? 'none' : 'full'"
          #searchListItem>
          {{ option.value }}
          <ion-icon
            *ngIf="option.identifier === item.value.value"
            slot="end"
            name="checkmark"
            color="primary"></ion-icon>
        </ion-item>
      }
    </ion-list>
  </div>
}
