<div class="preview-form">
  <ion-list *ngFor="let group of form.body; index as i" class="card-list">
    <ng-container *ngIf="group.visible">
      <ng-container *ngFor="let item of group.fieldGroup; index as i">
        <ng-container *ngIf="item.visible">
          <ion-item
            class="item-line"
            lines="none"
            [id]="'id-' + item.identifier.originalValue"
            *ngIf="isType(item, dataType.title)">
            <h5>{{ item.name }}</h5>
          </ion-item>
          <ion-item
            class="item-line"
            lines="none"
            [id]="'id-' + item.identifier.originalValue"
            *ngIf="isBasicInputType(item)">
            <p class="field-label ion-text-wrap">{{ item.name }}</p>
            <p slot="end">
              {{ item.value.value }}
            </p>
            <p *ngIf="!item.value.value" slot="end" class="no-entry">
              {{ 'general.not-specified' | translate }}
            </p>
          </ion-item>
          <ion-item
            class="item-line"
            lines="none"
            [id]="'id-' + item.identifier.originalValue"
            *ngIf="isType(item, dataType.date)">
            <p class="field-label ion-text-wrap">{{ item.name }}</p>
            <p slot="end">
              {{ item.value.value | localeDate: item.formatOptions?.formattedString }}
            </p>
            <p *ngIf="!item.value.value" slot="end" class="no-entry">
              {{ 'general.not-specified' | translate }}
            </p>
          </ion-item>
          <ion-item
            class="item-line"
            lines="none"
            [id]="'id-' + item.identifier.originalValue"
            *ngIf="isType(item, dataType.checkbox)">
            <p class="field-label ion-text-wrap">{{ item.name }}</p>
            <ion-checkbox
              color="primary"
              [(ngModel)]="item.value.value"
              [disabled]="true"
              [name]="item.identifier.originalValue"
              slot="end"></ion-checkbox>
          </ion-item>
          <ion-item
            class="item-line"
            lines="none"
            [id]="'id-' + item.identifier.originalValue"
            *ngIf="isType(item, dataType.radio)">
            <p class="field-label ion-text-wrap">{{ item.name }}</p>
            <p slot="end">{{ getSelectionFieldValue(item) }}</p>
            <p *ngIf="!item.value.value" slot="end" class="no-entry">
              {{ 'general.not-specified' | translate }}
            </p>
          </ion-item>
          <ion-item
            class="item-line"
            lines="none"
            [id]="'id-' + item.identifier.originalValue"
            *ngIf="isType(item, dataType.select)">
            <p class="field-label ion-text-wrap">{{ item.name }}</p>
            <p *ngIf="item.value.value" slot="end">{{ getSelectionFieldValue(item) }}</p>
            <p *ngIf="!item.value.value" slot="end" class="no-entry">
              {{ 'general.not-specified' | translate }}
            </p>
          </ion-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </ion-list>
</div>
