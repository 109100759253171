<ion-item
  [class]="breakpoint.isAbove('sm') ? 'white' : 'grey'"
  button="true"
  lines="none"
  (click)="viewDocument(document)">
  <lib-auto-layout
    direction="horizontal"
    vertical="center"
    [grow]="1"
    [padding]="[5, 0, 5, 10]"
    class="list-row">
    <fa-icon [icon]="sdapiDocumentFormat | documentFormatToIconPipe"></fa-icon>
    <lib-auto-layout
      [direction]="hasOnlySizeInfo ? 'horizontal' : 'vertical'"
      horizontal="start"
      vertical="center"
      [padding]="[5, 0, 5, 10]"
      [grow]="1"
      [gap]="hasOnlySizeInfo ? [5] : [0]"
      class="details trim-text-inline">
      <h3 class="ion-no-margin trim-text-inline">{{ document.name }}</h3>
      @if (hasOnlySizeInfo) {
        <span class="detail ion-text-end">{{ documentSize }}</span>
      }
      @if (document.date) {
        <lib-auto-layout
          direction="horizontal"
          horizontal="space-between"
          [gap]="[10]"
          class="stretch-self trim-text-inline">
          <span class="detail trim-text-inline">{{ document.date | localeDate: 'longDate' }}</span>
          @if (hasDateAndSizeInfo) {
            <span class="detail">{{ documentSize }}</span>
          }
        </lib-auto-layout>
      }
    </lib-auto-layout>
  </lib-auto-layout>
</ion-item>
