import { Component, Input } from '@angular/core';
import { GenericListItem } from 'projects/core/src/lib/models/list.model';

@Component({
  selector: 'lib-information-item',
  templateUrl: './information-item.component.html',
  styleUrl: './information-item.component.scss',
})
export class InformationItemComponent {
  @Input() item: GenericListItem;
}
