import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicButtonSetConfiguration } from 'projects/core/src/lib/models/dynamic-button.model';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';

@Component({
  selector: 'lib-action-buttons-toolbar',
  templateUrl: './action-buttons-toolbar.component.html',
})
export class ActionButtonsToolbarComponent {
  @Output() actionTrigger: EventEmitter<Invoker> = new EventEmitter<Invoker>();

  @Input({ required: true }) configuration: DynamicButtonSetConfiguration;
}
