import { Component, DestroyRef, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppName } from 'projects/core/src/lib/models/app.model';
import { ClientConfig, LandingPageData } from 'projects/core/src/lib/models/client.model';
import { DynamicForm } from 'projects/core/src/lib/models/form.model';
import { AppointmentBookingService } from 'projects/core/src/lib/services/appointment-booking.service';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { LanguageService } from 'projects/core/src/lib/services/language.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import {
  STORAGE_KEY_SELECTED_LANGUAGE_CODE,
  StorageService,
} from 'projects/core/src/lib/services/storage.service';
import { firstValueFrom, take } from 'rxjs';
import SwiperCore, { Navigation, Pagination, SwiperOptions, Virtual } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Navigation, Pagination, Virtual]);

@Component({
  selector: 'lib-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
})
export class LandingpageComponent implements OnInit {
  @ViewChild('swiper', { static: true }) swiper: SwiperComponent;
  config: SwiperOptions;
  clientConfig: ClientConfig;

  loginPath = '/portal';
  backToClinicPageLink = '/some-clinic';
  data: LandingPageData;

  private appointmentOverviewTranslations: string[];
  private appointmentBookingTranslations: string[];

  readonly icons = {
    bulletPoint: faCheckCircle,
    language: faGlobe,
  };

  constructor(
    private clientConfigService: ClientConfigService,
    private translateService: TranslateService,
    private popupService: PopupService,
    private storageService: StorageService,
    private languageService: LanguageService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private bookingService: AppointmentBookingService,
    private alertController: AlertController,
    private destroyRef: DestroyRef,
    private router: Router,
    private route: ActivatedRoute,
    @Inject('AppName') public appName: AppName,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initializeLandingpageData();
    await Promise.all([this.handleInitialLanguageSelection(), this.setTranslationData()]);
    await this.openPublicAppointmentBookingIfGetParamIsSet();
  }

  async openPublicAppointmentBookingIfGetParamIsSet(): Promise<void> {
    const shouldShowPublicAppointmentBookingInstantly =
      this.route.snapshot.queryParamMap.get('showPublicAppointmentBooking') === 'true';
    if (this.shouldShowBookingButton && shouldShowPublicAppointmentBookingInstantly) {
      await this.openBookingModal();
    }
  }

  get shouldShowBookingButton(): boolean {
    return this.clientConfig.activeModules.publicAppointmentBooking && this.appName === AppName.PP;
  }

  private async initializeLandingpageData(): Promise<void> {
    this.clientConfig = this.clientConfigService.get();
    this.data = this.getSuitableClientConfigData(this.clientConfig);
    if (typeof this.data.testimonials === 'undefined') {
      const translationData: object = await firstValueFrom(
        this.translateService.get('shared.landingpage'),
      );
      this.data.testimonials = [
        {
          name: translationData['testimonial-1'].name,
          role: translationData['testimonial-1'].role,
          quote: translationData['testimonial-1'].quote,
          image: translationData['testimonial-1'].image,
        },
        {
          name: translationData['testimonial-2'].name,
          role: translationData['testimonial-2'].role,
          quote: translationData['testimonial-2'].quote,
          image: translationData['testimonial-2'].image,
        },
      ];
    }
    this.generateConfig();
  }

  private generateConfig(): void {
    this.config = {
      grabCursor: true,
      pagination: {
        el: '.paginator',
        clickable: true,
      },
      spaceBetween: 0,
      breakpoints: {
        1200: {
          slidesPerView: this.data.testimonials.length < 3 ? this.data.testimonials.length : 3,
        },
        768: {
          slidesPerView: this.data.testimonials.length < 2 ? 1 : 2,
        },
        576: {
          slidesPerView: 1,
        },
      },
    };
  }

  private async handleInitialLanguageSelection(): Promise<void> {
    const storedLanguageCode = await this.storageService.get(STORAGE_KEY_SELECTED_LANGUAGE_CODE);
    if (!storedLanguageCode) {
      this.presentLanguageSwitch();
    }
  }

  async presentLanguageSwitch(): Promise<void> {
    const languageCode: string = await this.popupService.showLanguageSwitchPopover();
    if (!languageCode) {return;}

    const isLoggedIn: boolean = await firstValueFrom(this.authService.isLoggedIn());
    const reloadAfterLanguageChange = !!isLoggedIn;
    if (isLoggedIn) {
      await this.languageService.syncProfileFormWithSelectedLanguage(languageCode);
    }
    this.languageService.handleChangedLanguagePreference(languageCode, reloadAfterLanguageChange);
  }

  private getSuitableClientConfigData(clientConfig: ClientConfig): LandingPageData {
    switch (this.appName) {
      case AppName.ZP:
        return clientConfig.landingpageDataZP;
      case AppName.KP:
        return clientConfig.landingpageDataKP;
      default:
        return clientConfig.landingpageDataPP;
    }
  }

  async openBookingModal(): Promise<void> {
    await this.loadingService.load(
      this.appointmentOverviewTranslations['loading-appointment-bookings'],
    );
    this.bookingService
      .getBookingForm(true)
      .pipe(takeUntilDestroyed(this.destroyRef), take(1))
      .subscribe({
        next: (form: DynamicForm) => {
          this.showAppointmentCreationModal(form);
        },
        error: () => {
          this.loadingService.stop().then();
        },
      });
  }

  private showAppointmentCreationModal(form: DynamicForm): void {
    this.popupService.showPublicAppointmentBookingModal(form, false);
  }

  private async setTranslationData(): Promise<void> {
    this.appointmentOverviewTranslations = await firstValueFrom(
      this.translateService.get('shared.appointment-overview'),
    );
    this.appointmentBookingTranslations = await firstValueFrom(
      this.translateService.get('shared.appointment-booking'),
    );
  }
}
