import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { ItemSearcherService } from 'projects/core/src/lib/services/item-searcher.service';
import {
  TableConfiguration,
  TableHeaderItem,
  TableList,
  TableListItem,
  TableRowView,
} from '../models/dynamic-table.model';
import { SearchParams } from '../models/shared.model';

@Injectable()
export class TableOrganizerService {
  constructor(private itemSearcherService: ItemSearcherService) {}

  public static sortHorizontally(
    initialHeader: TableHeaderItem[],
    sortedHeader: TableHeaderItem[],
    initialRows: TableListItem[],
    viewRows: TableListItem[],
  ): TableListItem[] {
    const arrayOfIndices: number[] = this.getArrayOfIndicesOfInitialHeaderValues(
      initialHeader,
      sortedHeader,
    );
    return this.sortRowValuesAccordingToIndices(arrayOfIndices, initialRows, viewRows);
  }

  private static getArrayOfIndicesOfInitialHeaderValues(
    initialHeader: TableHeaderItem[],
    sortedHeader: TableHeaderItem[],
  ): number[] {
    return sortedHeader.map((item: TableHeaderItem) =>
      initialHeader.findIndex(
        (headerItem) => headerItem.value === item.value && headerItem.type === item.type,
      ),
    );
  }

  private static sortRowValuesAccordingToIndices(
    arrayOfIndices: number[],
    initialRows: TableListItem[],
    viewRows: TableListItem[],
  ): TableListItem[] {
    viewRows.forEach((sortedRow: TableListItem) => {
      sortedRow.columns = arrayOfIndices.map(
        (i: number) =>
          initialRows.find((initialRow: TableListItem) => initialRow.id === sortedRow.id).columns[
            i
          ],
      );
    });
    return viewRows;
  }

  public searchTableItems(searchParams: SearchParams, table: TableList): TableListItem[] {
    const viewRows = this.itemSearcherService.search(
      structuredClone(table.initialRows),
      searchParams,
    );
    if (table.sortedHeader) {
      TableOrganizerService.sortHorizontally(
        table.initialHeader,
        table.sortedHeader,
        table.initialRows,
        viewRows,
      );
    }
    return viewRows;
  }

  public getInverseViewportTopPosition(viewPort: CdkVirtualScrollViewport): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (!viewPort?._renderedContentTransform) {
      return '0px';
    }
    return `-${viewPort.getOffsetToRenderedContentStart()}px`;
  }

  public sortRowsBasedOnSearchParams(table: TableList, searchParams: SearchParams) {
    const searchedItems: TableListItem[] = this.searchTableItems(searchParams, table);
    table.updateRowsAfterSearch(searchedItems);
  }

  public static getColumnNumberByTableWidth(
    data: TableConfiguration,
    widthInPixels: number,
  ): number {
    return Math.floor(widthInPixels / data.columnWidth);
  }

  public static resolveRowViewByTableWidth(
    data: TableConfiguration,
    widthInPixels: number,
  ): number {
    return widthInPixels < data.expandedViewThreshold ? TableRowView.mobile : TableRowView.desktop;
  }
}
