import { Component, Input } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { PatientDataField } from 'projects/core/src/lib/models/patient.model';
import { AttributeFieldType } from 'projects/core/src/lib/models/sdapi-form-object.model';

@Component({
  selector: 'lib-patient-table-details-viewer',
  templateUrl: './patient-table-details-viewer.component.html',
  styleUrls: ['./patient-table-details-viewer.component.scss'],
})
export class PatientTableDetailsViewerComponent {
  @Input() tableList: PatientDataField[];
  dataType: typeof AttributeFieldType = AttributeFieldType;

  public readonly icons = {
    close: faXmark,
  };

  constructor(private modalController: ModalController) {}

  async close(): Promise<boolean> {
    return this.modalController.dismiss('data', 'close');
  }
}
