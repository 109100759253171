import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ModalController } from '@ionic/angular';
import { DocumentFormat } from 'projects/core/src/lib/models/documents.model';
import {
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
} from 'projects/core/src/lib/models/form.model';
import { RequiredActionsDetails } from 'projects/core/src/lib/models/required-actions.model';
import { DynamicFormService } from 'projects/core/src/lib/services/dynamic-form.service';
import { RequiredActionsService } from 'projects/core/src/lib/services/required-actions.service';
import { throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Component({
  selector: 'lib-required-action-switch',
  templateUrl: './required-action-switch.component.html',
  host: { class: 'handle-ion-layout loading-modal' },
})
export class RequiredActionSwitchComponent implements OnInit {
  @Input({ required: true }) requiredAction: RequiredActionsDetails;

  isLoading: boolean = true;

  requiredActionType: typeof RequiredActionType = RequiredActionType;

  private form: DynamicForm;

  constructor(
    private requiredActionsService: RequiredActionsService,
    private dynamicFormService: DynamicFormService,
    private modalController: ModalController,
    private destroyRef: DestroyRef,
  ) {}
  get currentRequiredActionType(): RequiredActionType {
    switch (this.requiredAction.fileType) {
      case DocumentFormat.EDIT_FORM:
      case DocumentFormat.UPLOAD_FORM:
      case DocumentFormat.VIEW_FORM:
        return RequiredActionType.form;
      case DocumentFormat.CHAT:
        return RequiredActionType.chat;
      case DocumentFormat.TABLE:
        return RequiredActionType.table;
      default:
        return RequiredActionType.unknown;
    }
  }

  get dynamicFormConfiguration(): DynamicFormConfiguration {
    return new DynamicFormConfiguration({
      type: this.resolveFormType(),
      activityURL: this.requiredAction.viewLink,
      requiredActionsDetails: this.requiredAction,
      dynamicForm: this.form,
      isInteractive: this.resolveFormInteractivity(),
    });
  }

  get dynamicChatFormConfiguration(): DynamicFormConfiguration {
    return new DynamicFormConfiguration({
      type: DynamicFormType.CHAT,
      activityURL: this.requiredAction.viewLink,
      requiredActionsDetails: this.requiredAction,
      isInteractive: this.resolveFormInteractivity(),
    });
  }

  ngOnInit(): void {
    this.requiredActionsService
      .getRequiredActionDetails(this.requiredAction)
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef),
        catchError((error) => throwError(() => error)),
      )
      .subscribe(() => {
        if (this.requiredAction.fileType === DocumentFormat.UPLOAD_FORM) {
          this.fetchDynamicForm();
        } else {
          this.isLoading = false;
        }
      });
  }

  isForm(requiredAction: RequiredActionsDetails): boolean {
    return (
      requiredAction.fileType === DocumentFormat.EDIT_FORM ||
      requiredAction.fileType === DocumentFormat.VIEW_FORM ||
      requiredAction.fileType === DocumentFormat.UPLOAD_FORM
    );
  }

  isChat(requiredAction: RequiredActionsDetails): boolean {
    return requiredAction.fileType === DocumentFormat.CHAT;
  }

  isTable(requiredAction: RequiredActionsDetails): boolean {
    return requiredAction.fileType === DocumentFormat.TABLE;
  }

  cancel() {
    return this.modalController.dismiss('data', 'cancel');
  }

  private fetchDynamicForm() {
    this.isLoading = true;
    this.dynamicFormService
      .getForm(this.dynamicFormConfiguration)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((form: DynamicForm) => {
        this.form = form;
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  private resolveFormType(): DynamicFormType {
    switch (this.requiredAction.fileType) {
      case DocumentFormat.EDIT_FORM:
        return DynamicFormType.TASK;
      case DocumentFormat.UPLOAD_FORM:
        return DynamicFormType.DOCUMENT_UPLOAD;
      default:
        return DynamicFormType.TASK_WITH_ACTIONS;
    }
  }

  private resolveFormInteractivity(): boolean {
    return this.requiredAction.fileType !== DocumentFormat.VIEW_FORM;
  }
}

enum RequiredActionType {
  chat,
  form,
  table,
  unknown,
}
