import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MedicalCertificatesMapper } from 'projects/core/src/lib/mappers/medical-certificates.mapper';
import { TableList } from 'projects/core/src/lib/models/dynamic-table.model';
import {
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
} from 'projects/core/src/lib/models/form.model';
import { Invoker, InvokerMethods } from 'projects/core/src/lib/models/invoker-body.model';
import { TieTableObjectList } from 'projects/core/src/lib/models/sdapi-table-object.model';
import { SDAPIService } from 'projects/core/src/lib/services/sdapi.service';
import { TableDataService } from 'projects/core/src/lib/services/table-data.service';
import { Observable, firstValueFrom, mergeMap } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import {
  InvokerMethodCollection,
  MedicalCertificateTranslationKeys,
  ModalResult,
  TranslationOptions,
} from '../models/modal-action.model';
import { ModalActionService } from './modal-action.service';
import { UploadService } from './upload.service';

@Injectable()
export class MedicalCertificatesService {
  constructor(
    private http: HttpClient,
    private sdapiService: SDAPIService,
    private tableDataService: TableDataService,
    private modalActionService: ModalActionService,
    private uploadService: UploadService,
  ) {}

  getAllCertificates(): Observable<TableList> {
    return this.getMedicalCertificatesObjectId().pipe(
      mergeMap((medicalCertificatesObjectId: number) =>
        this.sdapiService.getInvokerByMethodName(
          `${medicalCertificatesObjectId}`,
          InvokerMethods.objectObjectList,
        ),
      ),
      concatMap((invoker: Invoker) =>
        this.http.put<TieTableObjectList>(invoker.activityURL, invoker.invoker),
      ),
      switchMap((response: TieTableObjectList) =>
        this.mapAndUpdateTableAccordingToUserSettings(response),
      ),
    );
  }

  private mapAndUpdateTableAccordingToUserSettings(
    response: TieTableObjectList,
  ): Observable<TableList> {
    const tableList: TableList = MedicalCertificatesMapper.mapMedicalCertificatesTable(response);
    return this.tableDataService.fetchHeaderPreferencesAndUpdateTable(tableList);
  }

  private getMedicalCertificatesObjectId(): Observable<number> {
    return this.sdapiService
      .getInvokerByMethodName(
        'MP_ARZTZEUGNIS_FOLDER_OF_LOGGEDIN_USER',
        InvokerMethods.constraintObjectList,
      )
      .pipe(
        mergeMap((invoker: Invoker) =>
          this.http
            .put<TieTableObjectList>(invoker.activityURL, invoker.invoker)
            .pipe(map((response: TieTableObjectList) => response.items[0].objId)),
        ),
      );
  }

  public getMedicalCertificateCreationForm(): Observable<DynamicForm> {
    return this.getMedicalCertificatesObjectId().pipe(
      concatMap((objectId: number) => this.uploadService.getUploadFormInvoker(objectId)),
      mergeMap((uploadInvoker: Invoker) => this.uploadService.getDocumentUploadForm(uploadInvoker)),
    );
  }

  public async showMedicalCreationForm(): Promise<ModalResult> {
    const form: DynamicForm = await firstValueFrom(this.getMedicalCertificateCreationForm());
    const translationOptions: TranslationOptions = this.translationOptionsForCertificateCreation();
    return this.modalActionService.openDynamicFormModal(
      new DynamicFormConfiguration({
        dynamicForm: form,
        type: DynamicFormType.DOCUMENT_UPLOAD,
        translationOptions,
      }),
    );
  }

  private translationOptionsForCertificateCreation(): TranslationOptions {
    return {
      keys: MedicalCertificateTranslationKeys,
      successMessageKey: 'create-completion',
      actionInProgressKey: 'saving-in-progress',
    };
  }

  public get previewFallbackMethods(): InvokerMethodCollection {
    return { preferred: InvokerMethods.objectObjectList };
  }
}
