import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CookieService } from 'ngx-cookie-service';
import { catchError, concatMap, firstValueFrom, from, map, Observable, throwError } from 'rxjs';
import { DataFormMapper } from '../mappers/data-form.mapper';
import { TableHeaderItem, TableList } from '../models/dynamic-table.model';
import { DynamicDataField, DynamicForm } from '../models/form.model';
import { Invoker, InvokerMethods } from '../models/invoker-body.model';
import {
  ProfileSettings,
  ProfileSettingsValueField,
  TableDataSettings,
  TableSettings,
  UserSettingsItem,
} from '../models/profile.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import { TieTableItemObject, TieTableObjectList } from '../models/sdapi-table-object.model';
import { AttributeNameIdentifier } from '../models/shared.model';
import { CustomHttpHeaders } from './custom-http-headers';
import { SDAPIService } from './sdapi.service';

@Injectable()
@UntilDestroy()
export class ProfileSettingsService {
  constructor(
    private cookieService: CookieService,
    private sdapiService: SDAPIService,
    private http: HttpClient,
    private dataFormMapper: DataFormMapper,
  ) {}

  private getCookieExpiryDate(): Date {
    const expiry = new Date();
    expiry.setDate(expiry.getDate() + 1);
    return expiry;
  }

  public switchPatient(pid: string): void {
    this.cookieService.set('currentPatientId', pid, this.getCookieExpiryDate(), '/');
  }

  public deleteCurrentPatientIdFromCookies(): void {
    this.cookieService.delete('currentPatientId', '/');
  }

  public getCurrentPatientIdFromCookies(): string {
    if (this.cookieService.check('currentPatientId')) {
      return this.cookieService.get('currentPatientId');
    } else {
      return undefined;
    }
  }

  public async getUserProfileSettingsValue(): Promise<ProfileSettings> {
    try {
      return await this.processUserProfileSettings(false);
    } catch (error) {
      return this.processUserProfileSettings(true);
    }
  }

  private async processUserProfileSettings(
    settingsFolderIdIsChanged: boolean,
  ): Promise<ProfileSettings> {
    const userProfileSettings = await this.getUserSettingsForm(settingsFolderIdIsChanged);

    const settingsValue = userProfileSettings.body.filter((field: DynamicDataField) => {
      const informationFieldIdentifier = new AttributeNameIdentifier(
        ProfileSettingsValueField.information,
      );
      return field.identifier.isEqualTo(informationFieldIdentifier);
    })[0];

    if (settingsValue) {
      return JSON.parse(settingsValue.value.value) as ProfileSettings;
    } else {
      throw new Error('Profile settings value not found.');
    }
  }

  private async getSettingsFolder(): Promise<TieTableItemObject> {
    let settingsFolder: TieTableItemObject = await firstValueFrom(
      this.getUserProfileSettingsFolder(),
    );
    if (!settingsFolder) {
      await firstValueFrom(this.createProfileSettings());
      settingsFolder = await firstValueFrom(this.getUserProfileSettingsFolder());
    }
    return settingsFolder;
  }

  private async setSettingsFolderIDInCookies(settingsFolder: TieTableItemObject): Promise<void> {
    this.cookieService.set(
      'settingsFolderID',
      settingsFolder.objId.toString(),
      this.getCookieExpiryDate(),
      '/',
    );
  }

  private async getUserSettingsForm(settingsFolderIdIsChanged: boolean): Promise<DynamicForm> {
    if (!this.settingsFolderIDFromCookies || settingsFolderIdIsChanged) {
      const settingsFolder = await this.getSettingsFolder();
      await this.setSettingsFolderIDInCookies(settingsFolder);
    }

    return firstValueFrom(this.getUserSettingsViewForm(Number(this.settingsFolderIDFromCookies)));
  }

  private get settingsFolderIDFromCookies(): string {
    if (this.cookieService.get('settingsFolderID')) {
      return JSON.parse(this.cookieService.get('settingsFolderID'));
    } else {
      return undefined;
    }
  }

  private getUserProfileSettingsFolder(): Observable<TieTableItemObject> {
    return this.sdapiService
      .getInvokerByMethodName('SDAPI_CLIENT_SETTINGS', InvokerMethods.constraintObjectList)
      .pipe(
        concatMap((invoker: Invoker) => this.getSettingsFolderID(invoker)),
        concatMap((objectId: number) =>
          this.sdapiService.getInvokerByMethodName(`${objectId}`, InvokerMethods.objectObjectList),
        ),
        concatMap((invoker: Invoker) =>
          this.getUserSettingsItem(invoker, CustomHttpHeaders.XNoCacheHeaders),
        ),
        catchError((error) => throwError(() => error)),
      );
  }

  private getSettingsFolderID(invoker: Invoker): Observable<number> {
    return this.http.put<TieTableObjectList>('/invoke-method', invoker.invoker).pipe(
      map((result) => result.items[0].objId),
      catchError((error) => throwError(() => error)),
    );
  }

  private getUserSettingsItem(
    invoker: Invoker,
    headers?: HttpHeaders,
  ): Observable<TieTableItemObject> {
    return this.http.put<TieTableObjectList>('/invoke-method', invoker.invoker, { headers }).pipe(
      map((response: TieTableObjectList) =>
        response.items.find(
          (item: TieTableItemObject) => item.objName === UserSettingsItem.userSettings,
        ),
      ),
      catchError((error) => throwError(() => error)),
    );
  }

  private getUserSettingsViewForm(objectId: number): Observable<DynamicForm> {
    return this.sdapiService
      .getInvokerByMethodName(`${objectId}`, InvokerMethods.objectAttributesView)
      .pipe(concatMap((invoker: Invoker) => this.fetchAndMapUserSettingsFormObject(invoker)));
  }

  private fetchAndMapUserSettingsFormObject(invoker: Invoker): Observable<DynamicForm> {
    return this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker).pipe(
      map((response: TieFormObject) =>
        this.dataFormMapper.mapDataFormResource(response, invoker.activityURL, false, true),
      ),
      catchError((error) => throwError(() => error)),
    );
  }

  private createProfileSettings(): Observable<void> {
    const dataMap: Map<string, string> = new Map([
      ['SYS_ATTRIBUTE_CLOB.0[BODY,10]', `${JSON.stringify(new ProfileSettings())}`],
      ['SYS_OBJECT.OBJ_NAME[BODY,1]', UserSettingsItem.userSettings],
      ['SYS_OBJECT.DESCR[BODY,5]', ''],
    ]);
    return this.sdapiService
      .getInvokerByMethodName('SDAPI_CLIENT_SETTINGS', InvokerMethods.constraintObjectList)
      .pipe(
        concatMap((invoker: Invoker) =>
          this.getSettingsFolderID(invoker).pipe(
            concatMap((objectId: number) => this.createSettingsWithSDAPI(objectId, dataMap)),
          ),
        ),
        catchError((error) => throwError(() => error)),
      );
  }

  private createSettingsWithSDAPI(id: number, dataMap: Map<string, string>): Observable<void> {
    return this.sdapiService.getInvokerByMethodName(`${id}`, InvokerMethods.objectCreate).pipe(
      concatMap((invoker: Invoker) => this.sdapiService.getMetaFinderForm(invoker)),
      concatMap((dynamicForm: DynamicForm) =>
        this.sdapiService.fillDynamicFormAndGetResponseObjectList(dynamicForm, dataMap),
      ),
      map(() => undefined),
    );
  }

  public updateProfileSettings(userSettings: ProfileSettings): Observable<void> {
    const dataMap: Map<string, string> = new Map([
      ['SYS_ATTRIBUTE_CLOB.0[BODY,10]', `${JSON.stringify(userSettings)}`],
      ['SYS_OBJECT.OBJ_NAME[BODY,1]', UserSettingsItem.userSettings],
    ]);
    return this.getUserProfileSettingsFolder().pipe(
      concatMap((settingsFolder) => this.updateSettingsWithSDAPI(settingsFolder.objId, dataMap)),
    );
  }

  private updateSettingsWithSDAPI(id: number, dataMap: Map<string, string>): Observable<void> {
    return this.sdapiService
      .getInvokerByMethodName(`${id}`, InvokerMethods.objectAttributesEdit)
      .pipe(
        concatMap((invoker: Invoker) => this.sdapiService.getMetaFinderForm(invoker)),
        concatMap((dynamicForm: DynamicForm) =>
          this.sdapiService.fillDynamicFormAndGetResponseObjectList(dynamicForm, dataMap),
        ),
        map(() => undefined),
      );
  }

  public setTableColumnPreferenceValue(
    header: TableHeaderItem[],
    table: TableList,
  ): Observable<void> {
    const identifiers: string[] = header?.map(
      (headerItem: TableHeaderItem) => headerItem.identifier.originalValue,
    );
    return from(this.getUserProfileSettingsValue()).pipe(
      map((userSettings: ProfileSettings) =>
        this.setColumnPreferenceSettings(userSettings, table, identifiers),
      ),
      concatMap((userSettings: ProfileSettings) => this.updateProfileSettings(userSettings)),
      catchError((error) => throwError(() => error)),
    );
  }

  private setColumnPreferenceSettings(
    userSettings: ProfileSettings,
    table: TableList,
    identifiers: string[],
  ): ProfileSettings {
    const headerData = JSON.stringify(identifiers);
    if (!userSettings.tableData) {
      userSettings.tableData = new TableDataSettings();
    }
    if (!userSettings.tableData[table.type]) {
      userSettings.tableData[table.type] = new TableSettings();
    }
    userSettings.tableData[table.type][table.storageKey] = headerData;
    return userSettings;
  }
}
