import { Component, Input } from '@angular/core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import {
  TableHeaderItem,
  TableList,
  TableRowState,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-data-organizer',
  templateUrl: './data-organizer.component.html',
})
export class DataOrganizerComponent {
  @Input() headerReordering: BehaviorSubject<TableHeaderItem[]>;
  @Input() table: TableList;
  @Input() rowState: TableRowState;

  readonly icons = { filter: faFilter };
}
