<ion-content scrollX="false" scrollY="false">
  <pinch-zoom
    [properties]="zoomProperties"
    [wheel]="false"
    [autoHeight]="true"
    class="stretch-self">
    <div id="image-container" class="ion-justify-content-center ion-align-items-center">
      <img [src]="image.viewLink" alt="" />
    </div>
  </pinch-zoom>
</ion-content>
