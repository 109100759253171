export class LastActivitiesAttributesResource {
  public static readonly date = 'activityDateAndTime';
  public static readonly type = 'type';
  public static readonly title = 'title';
}

export class LastActivity {
  date: Date;
  title: string;
  type: string;
}

export class TemplateLastActivityGroup {
  date: Date;
  activities: LastActivity[];
}

export enum LastActivityType {
  DOCUMENT = 'DOCUMENT',
  PATIENT = 'PATIENT',
  VISIT = 'VISIT',
  OBJECT = 'OBJECT',
}
