import { APIError } from 'projects/core/src/lib/data/errors.data';
import { Profile, ProfileFieldKey } from 'projects/core/src/lib/models/profile.model';
import { DynamicDataField, DynamicForm } from '../models/form.model';
import { AttributeNameIdentifier } from '../models/shared.model';

export class ProfileResourceMapper {
  public static mapResource(form: DynamicForm, id: number): Profile {
    let profile: Profile;
    try {
      const findFieldByFilterKey = (fieldKey: ProfileFieldKey): DynamicDataField => {
        if (!form.body) {
          console.error(
            'profile.mapper.ts->mapResource() Profile Form Body is empty, returning undefined',
          );
          return undefined;
        }

        return form.body
          .flatMap((group: DynamicDataField) => (group.fieldGroup ? group.fieldGroup : group))
          .find((field: DynamicDataField) =>
            field.identifier.isEqualTo(new AttributeNameIdentifier(fieldKey)),
          );
      };

      const fullName = findFieldByFilterKey(ProfileFieldKey.fullName)?.value?.value || '';
      const profileID = id.toString();
      const userName = findFieldByFilterKey(ProfileFieldKey.username)?.value?.value || '';
      const detailsForm: DynamicForm = form;

      profile = new Profile({ profileID, fullName, detailsForm, userName });
    } catch (error) {
      throw new APIError('Profile Mapping Failed', error);
    }

    return profile;
  }
}
