<ion-content class="no-safe-area">
  <div id="language-switch-container">
    <ion-label>{{ 'shared.landingpage.footer.language-switch.label' | translate }}</ion-label>
    <div class="action-pane form" *ngIf="availableLanguageCodes.length">
      <ion-select [(ngModel)]="selectedLanguageCode" class="color-medium" interface="popover">
        <div slot="label"><fa-icon [icon]="icons.language"></fa-icon></div>
        <ng-container *ngFor="let code of availableLanguageCodes">
          <ion-select-option *ngIf="isDisplayableLanguageCode(code)" [value]="code">
            {{ getLanguageName(code) | translate }}
          </ion-select-option>
        </ng-container>
      </ion-select>
      <ion-button color="primary" (click)="saveSelectedLanguage()">
        {{ 'shared.landingpage.footer.language-switch.save-button' | translate }}
      </ion-button>
    </div>
  </div>
</ion-content>
