import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreatmentDetails } from 'projects/core/src/lib/models/treatment.model';

@Component({
  selector: 'lib-treatment-row',
  templateUrl: './treatment-row.component.html',
  styleUrls: ['./treatment-row.component.scss'],
})
export class TreatmentRowComponent {
  @Input() treatment: TreatmentDetails;
  @Output() treatmentClicked = new EventEmitter<number>();
}
