import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, map, Observable } from 'rxjs';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { FormMapper } from '../mappers/form.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { TableMapper } from '../mappers/table.mapper';
import { TableList } from '../models/dynamic-table.model';
import { DynamicForm } from '../models/form.model';
import { Invoker, InvokerMethods } from '../models/invoker-body.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import { TieTableReport } from '../models/sdapi-table-object.model';
import { SDAPIService } from './sdapi.service';

@Injectable()
export class AftercareService {
  constructor(
    private sdapiService: SDAPIService,
    private http: HttpClient,
    private dynamicFormMapper: DynamicFormMapper,
  ) {}

  getClinicReport(): Observable<TableList> {
    return this.sdapiService
      .getInvokerByMethodName('PP_HOSPITAL_BED_CAPACITY_REPORT', InvokerMethods.finderReport)
      .pipe(
        concatMap((invoker: Invoker) =>
          this.sdapiService.invokeMethod<TieTableReport>(invoker.invoker),
        ),
        map((response: TieTableReport) => TableMapper.mapTable(response)),
      );
  }

  public createAftercareOrder(): Observable<DynamicForm> {
    return this.sdapiService
      .getInvokerByMethodName('PP_REHAB_REFERRAL', InvokerMethods.objectCreate)
      .pipe(
        concatMap((invoker: Invoker) => this.getAftercareOrderForm(invoker)),
        map((dynamicForm: DynamicForm) => dynamicForm),
      );
  }

  public getAftercareOrderForm(aftercareOrderCreateInvoker: Invoker): Observable<DynamicForm> {
    return this.http
      .put<TieFormObject>(
        aftercareOrderCreateInvoker.activityURL,
        aftercareOrderCreateInvoker.invoker,
      )
      .pipe(
        map((response: TieFormObject) => {
          const activityURL: string = SDAPIObjectMapper.mapActivityPath(
            aftercareOrderCreateInvoker.invoker,
          );
          const dynamicForm: DynamicForm = this.dynamicFormMapper.mapDynamicFormResource(
            response,
            activityURL,
          );
          const invoker: Invoker[] = FormMapper.transferAttributesToInvokers(
            aftercareOrderCreateInvoker,
            dynamicForm,
          );
          dynamicForm.invoker = invoker;
          return dynamicForm;
        }),
      );
  }
}
