import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessagesData } from 'projects/core/src/lib/data/alert-messages.data';
import { ProfileSettings } from 'projects/core/src/lib/models/profile.model';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { ProfileSettingsService } from 'projects/core/src/lib/services/profile-settings.service';
import { UserAgreementService } from 'projects/core/src/lib/services/user-agreement.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-terms-of-service-browser',
  templateUrl: './terms-of-service-browser.component.html',
  styleUrls: ['./terms-of-service-browser.component.scss'],
})
export class TermsOfServiceBrowserComponent implements OnInit {
  isLoading: boolean = true;
  buttonsVisibility: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private profileSettings: ProfileSettingsService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private userAgreementService: UserAgreementService,
    private alertService: AlertService,
  ) {}

  async ngOnInit() {
    const userSettings: ProfileSettings = await this.profileSettings.getUserProfileSettingsValue();
    if (userSettings) {
      this.buttonsVisibility = !userSettings.userProfileData.tosRead;
    }
  }

  setLoadingValue(value: boolean): void {
    this.isLoading = value;
  }

  async setTosToRead(): Promise<void> {
    await this.loadingService.load(
      await firstValueFrom(
        this.translateService.get('shared.terms-of-service.selection-is-processed'),
      ),
    );
    await this.handleTosConfirmation();
    await this.loadingService.stop();
  }

  private async handleTosConfirmation(): Promise<void> {
    try {
      await this.updateTosStatusAndRedirect();
    } catch {
      await this.alertService.presentAlert(AlertMessagesData.tosConfirmationError);
    }
  }

  private async updateTosStatusAndRedirect(): Promise<void> {
    await this.userAgreementService.updateTosReadStatus(true);
    const tosConfirmed: boolean =
      await this.userAgreementService.fetchUpdatedTosReadStatusWithRetry();
    if (tosConfirmed) {
      await this.router.navigateByUrl('/portal');
    }
  }

  async logOutPatient(): Promise<void> {
    await this.authService.logout();
    await this.router.navigateByUrl('/');
  }
}
