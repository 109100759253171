import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import {
  faFileArchive,
  faFileAudio,
  faFileCircleExclamation,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWaveform,
  faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import { DocumentFormat } from 'projects/core/src/lib/models/documents.model';

@Pipe({
  name: 'documentFormatToIconPipe',
})
export class DocumentFormatToIconPipe implements PipeTransform {
  static readonly icons = {
    pdf: faFilePdf,
    dicom: faFileWaveform,
    audio: faFileAudio,
    video: faFileVideo,
    image: faFileImage,
    archive: faFileArchive,
    word: faFileWord,
    excel: faFileExcel,
    powerpoint: faFilePowerpoint,
    unknown: faFileCircleExclamation,
  };

  transform(mimeType: string): IconDefinition {
    return DocumentFormatToIconPipe.transformStatic(mimeType);
  }

  static transformStatic(documentFormat: string): IconDefinition {
    switch (documentFormat) {
      case DocumentFormat.PDF:
        return this.icons.pdf;
      case DocumentFormat.DICOM:
        return this.icons.dicom;
      case DocumentFormat.AUDIO:
        return this.icons.audio;
      case DocumentFormat.VIDEO:
        return this.icons.video;
      case DocumentFormat.IMAGE:
        return this.icons.image;
      case DocumentFormat.ARCHIVE:
        return this.icons.archive;
      case DocumentFormat.WORD:
        return this.icons.word;
      case DocumentFormat.EXCEL:
        return this.icons.excel;
      case DocumentFormat.POWERPOINT:
        return this.icons.powerpoint;
      default:
        return this.icons.unknown;
    }
  }
}
