import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { PageHeaderService } from 'projects/core/src/lib/services/page-header.service';
import { HeaderConfiguration } from './header.model';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() set headerConfiguration(headerConfig: HeaderConfiguration) {
    if (headerConfig) {this.headerService.updateHeader(headerConfig);}
  }
  header: HeaderConfiguration;

  public icons = {
    back: faChevronLeft,
  };

  constructor(
    public breakpoint: BreakpointService,
    private router: Router,
    private headerService: PageHeaderService,
    private destroyRef: DestroyRef,
  ) {
    this.headerService.headerObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((header: HeaderConfiguration) => {
        this.header = header;
      });
  }

  async navigate(): Promise<void> {
    if (this.header.backNavigation.path) {
      await this.router.navigate([this.header.backNavigation.path]);
    } else {
      const path = this.getPathForBackNavigation();
      await this.router.navigateByUrl(path);
    }
  }

  getPathForBackNavigation(): string {
    const pathArray = this.router.url.split('/');
    pathArray.pop();
    return pathArray.join('/');
  }

  get titleIconSize(): SizeProp {
    return this.breakpoint.isAbove('sm') ? 'sm' : '2xs';
  }

  get buttonSize(): string {
    return this.breakpoint.isAbove('sm') ? 'undefined' : 'small';
  }

  get pageTitle(): string {
    if (this.header.title.short) {
      return this.breakpoint.isAbove('md') ? this.header.title.extended : this.header.title.short;
    }
    return this.header.title.extended;
  }

  get buttonText(): string {
    if (this.header.backNavigation.text.short) {
      return this.breakpoint.isAbove('md')
        ? this.header.backNavigation.text.extended
        : this.header.backNavigation.text.short;
    }
    return this.header.backNavigation.text.extended;
  }
}
