import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Colord, colord, extend } from 'colord';
import mixPlugin from 'colord/plugins/mix';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { firstValueFrom } from 'rxjs';
import { FONT_SELECTOR_MAPPING, FONTFACE_NAME } from '../data/theme.data';
import { PlatformFix } from '../models/platform-fixes.model';
import {
  Color,
  ColorSelector,
  FontFace,
  Logo,
  Theme,
  ThemeProperty,
  ThemePropertySelector,
} from '../models/theme.model';
import { PlatformFixes } from './platform-fixes.service';

extend([mixPlugin]);

@Injectable()
export class ThemeService {
  constructor(
    private http: HttpClient,
    private platformFixes: PlatformFixes,
    private clientService: ClientConfigService,
    @Inject(APP_BASE_HREF) private baseHref: string,
  ) {}

  private cssLog = '';

  async loadTheme(): Promise<boolean> {
    this.resolvePlatformFixes(this.platformFixes.getFixes());

    const clientConfig: ClientConfig = this.clientService.get();
    const theme: Theme = await firstValueFrom(
      this.http.get<Theme>(
        `${this.baseHref}shared/assets/themes/${this.generateThemeFilename(clientConfig)}`,
        {
          headers: CustomHttpHeaders.build(
            CustomHttpHeaders.XNoAuthorization,
            CustomHttpHeaders.XNoApiBaseUrlInterception,
          ),
        },
      ),
    ).catch((error) => {
      console.warn(
        'THEMELOADER: Theme could not be loaded. Revert to Fallback Theme. Error Message: ' +
          error.message,
      );
      return {} as Theme;
    });

    const themeReadable = !!Object.keys(theme);

    if (themeReadable) {
      this.resolveTheme(theme);
    }

    return Promise.resolve(themeReadable);
  }

  async loadLocalTheme(themeJSON: string): Promise<boolean> {
    const theme: Theme = JSON.parse(themeJSON) as Theme;

    const themeReadable = !!Object.keys(theme);

    if (themeReadable) {
      this.resolveTheme(theme);
    }

    return Promise.resolve(themeReadable);
  }

  private generateThemeFilename(clientConfig: ClientConfig): string {
    const key = clientConfig?.key || 'default';
    return `${key}/${key}.theme.json`;
  }

  private resolvePlatformFixes(platformFixes: Array<PlatformFix>) {
    for (const platformFix of platformFixes) {
      this.injectCustomPlatformFix(platformFix);
    }
  }

  private injectCustomPlatformFix({ isTarget, css }: PlatformFix) {
    if (isTarget()) {this.injectCSS(css);}
  }

  private resolveTheme(theme: Theme) {
    this.cssLog = '';
    if (theme.logo?.url) {
      this.resolveLogo(theme.logo);
    }

    if (theme.color) {
      for (const color of theme.color) {this.resolveColor(color);}
    }

    if (theme.font) {
      for (const font of theme.font) {this.resolveFont(font);}
    }

    if (theme.properties) {
      for (const propertie of theme.properties) {this.resolvePropertie(propertie);}
    }

    if (theme.css) {
      this.injectCSS(theme.css);
    }
  }

  private resolveLogo(logo: Logo) {
    this.setProperty('--logo', `url('${logo.url}')`);
    if (logo.padding) {this.setProperty(`--logo-padding`, logo.padding);}
    if (logo.paddingMobile) {this.setProperty(`--logo-padding-mobile`, logo.paddingMobile);}
  }

  private resolveColor(color: Color) {
    if (ColorSelector.primary === color.selector) {
      const colorBase: Colord = colord(color.value);
      this.generateTints(colorBase, color.selector);
      this.generateShades(colorBase.mix('#333333', 0.7), 'greyish');
    } else if (ColorSelector.secondary === color.selector) {
      const colorBase: Colord = colord(color.value);
      this.generateTints(colorBase, color.selector);
    } else if (ColorSelector.greyish === color.selector) {
      const colorBase: Colord = colord(color.value);
      this.generateShades(colorBase.mix('#333333', 0.7), color.selector);
    } else if (Object.values(ColorSelector).includes(color.selector)) {
      const { h, s, l } = colord(color.value).toHsl();
      this.setProperty(`--color-${color.selector}`, `hsl(${h}, ${s}%, ${l}%)`);
    } else {console.warn(`THEMELOADER: Color Selector "${color.selector}" does not exist.`);}
  }

  private tints(base: Colord, _tint: Colord, tints: number): Array<Colord> {
    const colors: Array<Colord> = [];
    for (let i = 100; i > 0; i -= 100 / tints) {
      colors.push(base.lighten((100 - i) / 200));
    }
    return colors;
  }

  private generateTints(
    colorBase: Colord,
    selector: string,
    filter: Array<string> = [],
    tints: number = 100,
  ) {
    {
      const { h, s, l } = colorBase.toHsl();
      this.setProperty(`--color-${selector}-hsl`, `${h}, ${s}%, ${l}%`);
    }

    for (const [i, { h, s, l }] of this.tints(colorBase, colord('#ffffff'), tints)
      .map((color) => color.toHsl())
      .entries()) {
      if (filter.length === 0 || filter.includes((tints - i).toString())) {
        this.setProperty(`--color-${selector}-${tints - i}`, `hsl(${h}, ${s}%, ${l}%)`);
      }
    }
  }

  private generateShades(
    colorBase: Colord,
    selector: string,
    filter: Array<string> = [],
    tints: number = 100,
  ) {
    {
      const { h, s, l } = colorBase.toHsl();
      this.setProperty(`--color-${selector}-hsl`, `${h}, ${s}%, ${l}%`);
    }
    for (const [i, { h, s, l }] of colorBase
      .tints(tints + 1)
      .map((color) => color.toHsl())
      .entries()) {
      if (filter.length === 0 || filter.includes((tints - i).toString())) {
        this.setProperty(`--color-${selector}-${tints - i}`, `hsl(${h}, ${s}%, ${l}%)`);
      }
    }
  }

  private resolveFont(fontProperties: FontFace) {
    if (
      !FONTFACE_NAME.has(fontProperties.selector) &&
      !FONT_SELECTOR_MAPPING.has(fontProperties.selector)
    ) {
      console.warn(`THEMELOADER: Font Selector "${fontProperties.selector}" does not exist.`);
      return;
    }

    if (fontProperties.url) {
      for (const selector of FONT_SELECTOR_MAPPING.has(fontProperties.selector)
        ? FONT_SELECTOR_MAPPING.get(fontProperties.selector)
        : [fontProperties.selector]) {
        this.addFont(fontProperties.url, FONTFACE_NAME.get(selector), !!fontProperties.fontWeight);
      }
    }

    if (fontProperties.fontSize) {
      this.setProperty(`--font-${fontProperties.selector}-font-size`, fontProperties.fontSize);
    }
    if (fontProperties.lineHeight) {
      this.setProperty(`--font-${fontProperties.selector}-line-height`, fontProperties.lineHeight);
    }
    if (fontProperties.fontWeight) {
      this.setProperty(`--font-${fontProperties.selector}-weight`, fontProperties.fontWeight);
    }
    if (fontProperties.textAlign) {
      this.setProperty(`--font-${fontProperties.selector}-text-align`, fontProperties.textAlign);
    }
  }

  private resolvePropertie(propertie: ThemeProperty) {
    if (Object.values(ThemePropertySelector).includes(propertie.selector)) {
      this.setProperty(`--${propertie.selector}`, propertie.value);
    } else {
      console.warn(`THEMELOADER: Theme Selector "${propertie.selector}" does not exist.`);
    }
  }

  private setProperty(property: string, value: string): void {
    if (value) {
      document.documentElement.style.setProperty(property, value);
      if (!['--logo'].includes(property)) {this.cssLog += `  ${property}: ${value};\n`;}
    }
  }

  public getCSSPropertyList() {
    return this.cssLog;
  }

  private injectCSS(cssText: string) {
    const styleSheets = document.styleSheets;
    const lastStyle = styleSheets[styleSheets.length - 1];
    lastStyle.insertRule(`@media all { ${cssText} }`, lastStyle.cssRules.length);
  }

  private addFont(url: string, name: string, variable = false) {
    const font = new FontFace(name, `url(${url})`, {
      weight: variable ? '1 1000' : undefined,
    });
    font
      .load()
      .then((fontFace) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        document.fonts.add(fontFace);
      })
      .catch((error) => {
        console.warn(
          `THEMELOADER: Font at "${url.substring(
            0,
            2000,
          )}" could not be loaded. Error Message: ${error}`,
        );
      });
  }
}
