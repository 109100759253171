import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  EventMessage,
  EventMessageType,
  SearchLayoutType,
  TableConfiguration,
  TableRowState,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { TableOrganizerService } from 'projects/core/src/lib/services/table-organizer.service';

@Component({
  selector: 'lib-generic-table-toolbar',
  templateUrl: './generic-table-toolbar.component.html',
  styleUrl: './generic-table-toolbar.component.scss',
})
export class GenericTableToolbarComponent {
  @Output() eventMessage: EventEmitter<EventMessage> = new EventEmitter();

  @Input({ required: true }) data: TableConfiguration;
  @Input({ required: true }) rowState: TableRowState;
  @Input() isLoading: boolean;
  @Input() isCard = true;

  constructor(private tableOrganizerService: TableOrganizerService) {}

  updateTableListAfterSearch(): void {
    if (this.data.table?.sortedRows && this.hasDefinedSearchCriterion) {
      this.tableOrganizerService.sortRowsBasedOnSearchParams(
        this.data.table,
        this.data.searchParams,
      );
    }
    this.eventMessage.emit({ type: EventMessageType.RESORTING, data: false });
  }

  private get hasDefinedSearchCriterion(): boolean {
    return this.data.searchParams.criterions !== undefined;
  }

  showLoading(): void {
    this.eventMessage.emit({ type: EventMessageType.RESORTING, data: true });
  }

  get searchLayoutType(): SearchLayoutType {
    return this.isCard ? 'card' : 'list';
  }
}
