<ion-card color="light" class="important-information ion-no-margin">
  <ion-card-header>
    <lib-auto-layout direction="horizontal" [padding]="[10]" [gap]="[10]" vertical="center">
      <fa-icon [icon]="icons.info" size="lg"></fa-icon>
      <h3 class="ion-no-margin">
        {{ 'shared.treatments.important-information' | translate }}
      </h3>
    </lib-auto-layout>
  </ion-card-header>

  <div *cdkVirtualFor="let document of documentList">
    <lib-information-document-row
      [document]="document"
      (documentListReload)="documentListReload.emit($event)">
    </lib-information-document-row>
  </div>
</ion-card>
