import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faArrowLeft, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { ViewDocumentDetails } from 'projects/core/src/lib/models/documents.model';

@Component({
  selector: 'lib-link-viewer',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkViewerComponent implements OnInit {
  @Input() document: ViewDocumentDetails;
  @Output() commitButtonsVisibility = new EventEmitter<boolean>();
  cancelButtonVisibility = true;
  url: string;
  linkClicked = false;

  ICONS = {
    link: faArrowUpRightFromSquare,
    arrowLeft: faArrowLeft,
  };

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    this.url = this.document.externalOpenLink;
  }

  async close(): Promise<boolean> {
    return this.modalController.dismiss('data', 'close');
  }

  openExternalLink() {
    this.linkClicked = true;
    window.open(this.document.externalOpenLink, '_blank');
  }
}
