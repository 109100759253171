import { Component, HostBinding } from '@angular/core';
import { faArrowRight, faFingerprint } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { SkeletonHeaderOffset } from 'projects/core/src/lib/models/dynamic-table.model';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';
import { ModalHeaderStyle } from '../../modal-helper/header/header.component';

@Component({
  selector: 'lib-auto-ident-information',
  templateUrl: './auto-ident-information.component.html',
  styleUrls: ['./auto-ident-information.component.scss'],
})
export class AutoIdentInformationComponent {
  @HostBinding('class') class = 'medium-modal';
  readonly headerOffset: SkeletonHeaderOffset = { mobile: 210, desktop: 520 };
  readonly expandedViewThreshold: number = 600;
  readonly icons = { arrowRight: faArrowRight, fingerprint: faFingerprint };
  readonly modalHeaderStyle: string = ModalHeaderStyle.view;

  searchParams: SearchParams = new SearchParams();
  isRowDataLoading: boolean;

  constructor(private modalController: ModalController) {}

  async cancel() {
    await this.modalController.dismiss(null, OverlayEventRole.cancel);
  }

  async proceedWithIdentification() {
    await this.modalController.dismiss(null, OverlayEventRole.activityChange);
  }
}
