<ion-card color="light" class="treatment ion-no-margin stretch-container stretch-flex">
  <ion-card-header>
    <div class="card-header-container stretch-self">
      @if (treatment) {
        <span class="strong">{{ treatment.title }}</span>
      } @else {
        <div class="stretch-self skeleton-title">
          <ion-skeleton-text animated class="rounded-small"></ion-skeleton-text>
        </div>
      }
    </div>
  </ion-card-header>

  @if (breakpoint.isAbove('sm')) {
    <lib-auto-layout direction="horizontal" class="stretch-self">
      @if (shouldShowInformationSection) {
        <div class="info-section stretch-self stretch-container">
          <lib-information-section
            class="stretch-flex stretch-container"
            [treatment]="treatment"
            [isLoading]="isLoading"
            (documentSectionActivation)="activateDocumentSection()"
            (openTreatmentNotes)="openTreatmentNotes.emit()"
            (refreshTreatment)="refreshTreatment.emit()">
          </lib-information-section>
        </div>
      }

      <div class="document-section stretch-self stretch-container">
        <lib-document-section
          class="stretch-flex stretch-container"
          [treatment]="treatment"
          [isLoading]="isLoading"
          (documentUploaded)="documentUploaded.emit($event)">
        </lib-document-section>
      </div>
    </lib-auto-layout>
  }

  @if (breakpoint.isBelow('md')) {
    <div class="stretch-self stretch-container">
      <div class="segment-container">
        @if (shouldShowInformationSection) {
          <ion-segment [(ngModel)]="activeSegment" [disabled]="isLoading" swipe-gesture="false">
            <ion-segment-button [value]="segment.INFORMATION" id="information-segment">
              <ion-label class="p3">{{ 'shared.treatments.information' | translate }}</ion-label>
            </ion-segment-button>
            <ion-segment-button [value]="segment.DOCUMENTS" id="documents-segment">
              <ion-label class="p3">{{ 'shared.treatments.documents' | translate }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        }
      </div>

      <ng-container [ngSwitch]="activeSegment">
        @if (shouldShowInformationSection) {
          <ng-container *ngSwitchCase="segment.INFORMATION">
            <lib-information-section
              class="stretch-flex stretch-container"
              [treatment]="treatment"
              [isLoading]="isLoading"
              (documentSectionActivation)="activateDocumentSection()"
              (openTreatmentNotes)="openTreatmentNotes.emit()">
            </lib-information-section>
          </ng-container>
        }

        <ng-container *ngSwitchCase="segment.DOCUMENTS">
          <lib-document-section
            class="stretch-flex stretch-container"
            [treatment]="treatment"
            [isLoading]="isLoading"
            (documentUploaded)="documentUploaded.emit($event)">
          </lib-document-section>
        </ng-container>
      </ng-container>
    </div>
  }

  @if (treatment?.hasShareWithDoctorActivity) {
    <lib-share-treatment-with-doctor
      [id]="treatment.id"
      (treatmentShared)="refreshTreatment.emit()">
    </lib-share-treatment-with-doctor>
  }
</ion-card>
