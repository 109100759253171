@if (!isLoading && viewForm) {
  <div class="stretch-container fill-height">
    <div data-api-id="patient-data"></div>

    @if (hasMultipleTabs) {
      <div class="menu-button ion-hide-md-up">
        <ion-button
          id="popover-trigger-button"
          class="ion-no-margin"
          color="primary"
          (click)="toggleButtonState()">
          <fa-icon slot="end" icon="bars"></fa-icon>
          <span>{{ viewForm.body[tabIndex].name }}</span>
        </ion-button>

        <ion-popover
          [isOpen]="isMenuPopupOpen"
          (ionPopoverWillDismiss)="toggleButtonState()"
          [dismissOnSelect]="true"
          trigger="popover-trigger-button"
          alignment="end"
          animated="true"
          arrow="false"
          side="bottom"
          cssClass="popover">
          <ng-template>
            <ion-list class="popover-item-list">
              @for (tab of viewForm.body; track i; let i = $index) {
                <ion-item (click)="setMenuValue(i)" class="p" lines="none">
                  <ion-label>{{ tab.name }}</ion-label>
                </ion-item>
              }
            </ion-list>
          </ng-template>
        </ion-popover>
      </div>

      <ion-segment value="0" [(ngModel)]="tabIndex" class="ion-hide-md-down">
        @for (tab of viewForm.body; track i; let i = $index) {
          <ion-segment-button value="{{ i }}">
            <ion-label>{{ tab.name }}</ion-label>
          </ion-segment-button>
        }
      </ion-segment>
    }

    <ng-container [ngSwitch]="tabIndex">
      @for (tab of viewForm.body; track i; let i = $index) {
        <ng-container *ngSwitchCase="tabIndex">
          @if (tabIndex === i.toString()) {
            @if (hasTableView(tabIndex)) {
              @for (item of tab.fieldGroup; track $index) {
                <lib-patient-data-table class="stretch-flex" [table]="item.value.table">
                </lib-patient-data-table>
              }
            } @else {
              <ion-card color="light" class="ion-no-margin stretch-container stretch-flex">
                <lib-patient-list-data
                  class="stretch-flex"
                  [list]="viewForm.body[i].fieldGroup"
                  [editForm]="editForm"
                  (patientDataUpdate)="patientDataUpdate.emit($event)">
                </lib-patient-list-data>
              </ion-card>
            }
          }
        </ng-container>
      }
    </ng-container>
  </div>
}

@if (isLoading) {
  <ion-segment>
    @for (item of [0, 0]; track $index) {
      <ion-segment-button [disabled]="true">
        <ion-skeleton-text animated="true" class="like-p"> </ion-skeleton-text>
      </ion-segment-button>
    }
  </ion-segment>
  <ion-card color="light" class="ion-no-margin">
    @for (item of skeletonRowAmountAsArray; track $index) {
      <div class="card-list skeleton-animation">
        <p>
          <ion-skeleton-text animated="true" class="like-p"> </ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated="true" class="like-p"> </ion-skeleton-text>
        </p>
      </div>
    }
  </ion-card>
}
