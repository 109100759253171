import { Component, Input, OnInit } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DynamicButtonSetConfiguration } from 'projects/core/src/lib/models/dynamic-button.model';
import {
  EventMessage,
  EventMessageType,
  ModalTableDismissData,
  SkeletonHeaderOffset,
  SkeletonRowSet,
  TableConfiguration,
  TableList,
  TableSection,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { DynamicDataField } from 'projects/core/src/lib/models/form.model';
import { Invoker, InvokerBody } from 'projects/core/src/lib/models/invoker-body.model';
import { RequiredActionsDetails } from 'projects/core/src/lib/models/required-actions.model';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { DynamicFormService } from 'projects/core/src/lib/services/dynamic-form.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';
import { TableModalService } from 'projects/core/src/lib/services/table-modal.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
  host: { class: 'large-modal handle-ion-layout' },
})
export class GenericTableComponent implements OnInit {
  @Input({ required: true }) table: TableList;
  @Input() requiredAction: RequiredActionsDetails;

  readonly headerOffset: SkeletonHeaderOffset = { mobile: 370, desktop: 490 };
  readonly expandedViewThreshold: number;
  readonly icons = { close: faXmark };
  readonly segment: typeof TableSection = TableSection;

  isRowDataLoading = false;
  skeletonRowSet: SkeletonRowSet;
  searchParams: SearchParams = new SearchParams();
  buttonSetConfiguration: DynamicButtonSetConfiguration;
  translations: Object;
  activeSegment: TableSection = TableSection.list;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private tableModalService: TableModalService,
    private breakpointService: BreakpointService,
  ) {
    this.searchParams.fields = ['columns'];
    this.skeletonRowSet = new SkeletonRowSet(this.headerOffset);
  }

  async ngOnInit(): Promise<void> {
    this.translations = await firstValueFrom(this.translateService.get('shared'));
    if (this.table.actionButtons?.length) {
      this.buttonSetConfiguration = this.tableModalService.constructActionButtonConfiguration(
        this.table,
        this.translations,
      );
    }
  }

  get data(): TableConfiguration {
    return {
      table: this.table,
      searchParams: this.searchParams,
      skeletonRowSet: this.skeletonRowSet,
      expandedViewThreshold: this.viewThreshold,
      columnWidth: 200,
    };
  }

  get viewThreshold(): number {
    return this.table.header?.length ? 725 : 640;
  }

  cancel(): void {
    this.modalController.dismiss(null, OverlayEventRole.cancel);
  }

  resolveEventMessage(eventMessage: EventMessage): void {
    switch (eventMessage.type) {
      case EventMessageType.CLICK:
        this.viewListItem(eventMessage.data, eventMessage.behaviorInvokers);
        break;
      case EventMessageType.RESORTING:
        this.setLoadingValue(eventMessage.data);
        break;
    }
  }

  setLoadingValue(value: boolean): void {
    this.isRowDataLoading = value;
  }

  private async viewListItem(id: number, behaviorInvokers: InvokerBody[]): Promise<void> {
    const loadingMessage: string = await firstValueFrom(
      this.translateService.get('shared.documents.requesting-document'),
    );
    await this.loadingService.load(loadingMessage);
    const dismissData: ModalTableDismissData =
      await this.tableModalService.constructModalDismissData(id, behaviorInvokers);
    this.modalController.dismiss(dismissData, OverlayEventRole.activityChange);
  }

  async dismissModalWithActionEvent(invoker: Invoker): Promise<void> {
    await this.modalController.dismiss(
      { invoker, requiredAction: this.requiredAction },
      OverlayEventRole.actionButtonEvent,
    );
  }

  get isDesktopView(): boolean {
    return this.breakpointService.isAbove('lg');
  }

  get isTabletView(): boolean {
    return this.breakpointService.isAbove('sm') && this.breakpointService.isBelow('xl');
  }

  get isExpandedView(): boolean {
    return this.isDesktopView || this.isTabletView;
  }

  get informationSideBarClass(): string {
    return this.isDesktopView ? 'desktop' : 'tablet';
  }

  isDisplayableField(item: DynamicDataField): boolean {
    return DynamicFormService.isDisplayableFieldType(item);
  }
}
