import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-landingpage-header',
  templateUrl: './landingpage-header.component.html',
  styleUrls: ['./landingpage-header.component.scss'],
})
export class LandingpageHeaderComponent {
  @Input()
  showBackButton: boolean;

  @Input()
  useWindowHistoryBack: boolean = false;

  @Input()
  showLoginButton: boolean = true;

  @Input()
  noLogoPaddingLeft: boolean = false;

  loginPath = '/portal';

  get historyHasEntries(): boolean {
    return window.history.length > 1;
  }

  navigateBack(): void {
    if (this.useWindowHistoryBack) {
      window.history.back();
    }
  }
}
