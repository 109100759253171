<booking-modal-timeline-indicator
  class="ion-hide-md-up"
  [icon]="bookingQueue.currentStep.icon"
  [current]="true"
  [first]="bookingQueue.isFirst"
  [last]="bookingQueue.isLast"></booking-modal-timeline-indicator>

<lib-auto-layout direction="vertical" horizontal="space-around" class="stretch-self">
  <div class="form-item" [ngSwitch]="bookingQueue.currentStep.type">
    <h3>{{ bookingQueue.currentStep.title }}</h3>

    <ng-container *ngSwitchCase="StepTypes.form">
      @if (bookingQueue.currentFormGroup) {
        <lib-dynamic-form-compact-layout
          [group]="bookingQueue.currentFormGroup"></lib-dynamic-form-compact-layout>
      }
    </ng-container>

    <ng-container *ngSwitchCase="StepTypes.dynamicDate">
      <lib-dynamic-date-selection
        [bookingQueue]="bookingQueue"
        (onAppointmentSelect)="chooseAppointment($event)"></lib-dynamic-date-selection>
    </ng-container>

    <ng-container *ngSwitchCase="StepTypes.information">
      <div class="information">
        <fa-icon [icon]="bookingQueue.currentStep.icon || ICONS.info" size="lg"></fa-icon>
        <div class="details">
          <div class="p4">
            {{ bookingQueue.currentStep.info }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-content></ng-content>
</lib-auto-layout>
