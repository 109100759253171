import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
})
export class CardListComponent {
  @Input() type: 'list' | 'grid' = 'list';
}
