<ion-content scrollY="true">
  <div class="filter-container stretch-container">
    <div class="segment-buttons-container stretch-container">
      <lib-auto-layout [padding]="[10]">
        <ion-segment [(ngModel)]="activeFilterSection">
          <ion-segment-button [value]="filterSectionType.horizontalSorting">
            <ion-label>{{ 'shared.data-organizer.columns' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button [value]="filterSectionType.verticalSorting">
            <ion-label>{{ 'shared.data-organizer.sort' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </lib-auto-layout>
    </div>

    <ng-container [ngSwitch]="activeFilterSection">
      <ng-container *ngSwitchCase="filterSectionType.horizontalSorting">
        <lib-horizontal-sorting-section
          [rowState]="rowState"
          [header]="table.sortedHeader"
          [headerReordering]="headerReordering"></lib-horizontal-sorting-section>
      </ng-container>

      <ng-container *ngSwitchCase="filterSectionType.verticalSorting">
        <lib-vertical-sorting-section [table]="table"></lib-vertical-sorting-section>
      </ng-container>
    </ng-container>
  </div>
</ion-content>
