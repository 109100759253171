import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleInfo,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { SearchParams, SortDirection, SortParams } from 'projects/core/src/lib/models/shared.model';
import { TreatmentDetails } from 'projects/core/src/lib/models/treatment.model';
import { ItemSearcherService } from 'projects/core/src/lib/services/item-searcher.service';
import { ItemSorterService } from 'projects/core/src/lib/services/item-sorter.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { SkeletonService } from 'projects/core/src/lib/services/skeleton.service';
import { TableOrganizerService } from 'projects/core/src/lib/services/table-organizer.service';
import { VirtualScrollService } from 'projects/core/src/lib/services/virtual-scroll.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-treatments-browser',
  templateUrl: './treatments-browser.component.html',
  styleUrls: ['./treatments-browser.component.scss'],
})
export class TreatmentsBrowserComponent {
  @ViewChild('scrollViewPort') viewPort: CdkVirtualScrollViewport;

  @Input() set treatments(treatments: TreatmentDetails[]) {
    this.sortTreatmentList(treatments);
  }
  @Input() isCreateTreatmentVisible: boolean;
  @Input() isLoading = true;

  @Output() treatmentClicked = new EventEmitter<number>();
  @Output() createTreatmentClicked = new EventEmitter<void>();

  readonly MAX_ROW_AMOUNT: number = SkeletonService.getMaxRowAmount(190, 64);
  readonly icons = {
    asc: faCircleArrowDown,
    desc: faCircleArrowUp,
    info: faCircleInfo,
    plus: faPlus,
  };

  treatmentsList: TreatmentDetails[];
  viewList: TreatmentDetails[];
  searchParams = new SearchParams();
  sortParams = new SortParams();

  constructor(
    public virtualScrollService: VirtualScrollService,
    public tableOrganizerService: TableOrganizerService,
    private itemSearcherService: ItemSearcherService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
  ) {
    this.searchParams.fields = ['title', 'date', 'doctor', 'location'];
    this.sortParams.field = 'date';
    this.sortParams.direction = SortDirection.DESC;
  }

  sortTreatmentList(treatments: TreatmentDetails[]): void {
    if (treatments) {
      this.treatmentsList = treatments;
      this.viewList = ItemSorterService.sort(this.treatmentsList, this.sortParams);
      this.isLoading = false;
    }
  }

  getMaxSkeletonAmountAsArray(): Array<number> {
    return Array(this.MAX_ROW_AMOUNT).fill(0);
  }

  getTreatments(): void {
    if (this.viewList !== undefined) {
      this.isLoading = true;
      const searchedItems = this.itemSearcherService.search(this.treatmentsList, this.searchParams);
      this.viewList = ItemSorterService.sort(searchedItems, this.sortParams);
      this.isLoading = false;
    }
  }

  checkSortParams(field: string, isAsc: boolean): boolean {
    const direction = isAsc ? SortDirection.ASC : SortDirection.DESC;
    return this.sortParams.field === field && this.sortParams.direction === direction;
  }

  setSort(field: string): void {
    if (this.sortParams.field === field) {
      this.sortParams.direction =
        this.sortParams.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    } else {
      this.sortParams.direction = SortDirection.ASC;
    }
    this.sortParams.field = field;
    this.getTreatments();
  }

  async newTreatment(): Promise<void> {
    await this.loadingService.load(
      await firstValueFrom(
        this.translateService.get('shared.treatments.opening-treatment-creation-form'),
      ),
    );
    this.createTreatmentClicked.emit();
  }

  setLoadingValue(value: boolean): void {
    this.isLoading = value;
  }
}
