@if (!isLoading) {
  @switch (currentRequiredActionType) {
    @case (requiredActionType.form) {
      <lib-dynamic-form-flexible-layout [configuration]="dynamicFormConfiguration">
      </lib-dynamic-form-flexible-layout>
    }
    @case (requiredActionType.table) {
      <lib-generic-table
        [table]="requiredAction.table"
        [requiredAction]="requiredAction"></lib-generic-table>
    }
    @case (requiredActionType.chat) {
      <lib-chat [configuration]="dynamicChatFormConfiguration"></lib-chat>
    }
    @default {
      <lib-required-action [requiredAction]="requiredAction"></lib-required-action>
    }
  }
} @else {
  <ion-header>
    <ion-toolbar style="--padding-start: 10px; --padding-end: 10px">
      <h2 slot="start" class="ion-no-margin">
        <ion-skeleton-text
          class="h2 rounded-small"
          animated="true"
          style="width: 300px"></ion-skeleton-text>
      </h2>
      <ion-button slot="end" fill="clear" (click)="cancel()">
        <fa-icon icon="xmark" slot="end"></fa-icon>
      </ion-button>
    </ion-toolbar>
  </ion-header>

  <ion-content [scrollY]="false">
    <div class="stretch-self align-center">
      <ion-spinner></ion-spinner>
    </div>
  </ion-content>
}
