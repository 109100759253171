import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class LoadingService {
  constructor(
    private loadingController: LoadingController,
    private toastController: ToastController,
    private translateService: TranslateService,
  ) {}

  public async load(message?: string): Promise<void> {
    const loadingMessage: string = await this.resolveMessageValue(
      message,
      'core.toast.message.loading',
    );
    try {
      await this.loadingController.dismiss();
    } catch {}
    const loader: HTMLIonLoadingElement = await this.loadingController.create({
      message: loadingMessage,
      cssClass: 'tie-spinner p',
    });
    return loader.present();
  }

  public async stop(): Promise<void> {
    if (await this.loadingController.getTop()) {
      await this.loadingController.dismiss();
    }
    return Promise.resolve();
  }

  public async toast(message?: string): Promise<void> {
    const toastMessage: string = await this.resolveMessageValue(message, 'core.toast.message.done');
    try {
      await this.toastController.dismiss();
    } catch {}
    const toast: HTMLIonToastElement = await this.toastController.create({
      message: toastMessage,
      duration: 5000,
      cssClass: 'p',
      swipeGesture: 'vertical',
      buttons: [
        {
          text: '×',
          role: 'cancel',
        },
      ],
    });
    return toast.present();
  }

  private async resolveMessageValue(message: string, translationKey: string): Promise<string> {
    return message ? message : await firstValueFrom(this.translateService.get(translationKey));
  }
}
