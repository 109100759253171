import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DataOrganizerToolboxComponent } from 'projects/shared/src/lib/components/data-organizer-toolbox/data-organizer-toolbox.component';
import { DataOrganizerComponent } from 'projects/shared/src/lib/components/modals/data-organizer/data-organizer.component';
import { BehaviorSubject, Observable, firstValueFrom, from, map, mergeMap } from 'rxjs';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { TableMapper } from '../mappers/table.mapper';
import {
  TableHeaderItem,
  TableList,
  TableRowState,
  TableRowView,
  TableType,
} from '../models/dynamic-table.model';
import { DynamicDataField, DynamicForm } from '../models/form.model';
import { Invoker } from '../models/invoker-body.model';
import { ProfileSettings } from '../models/profile.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { LoadingService } from './loading.service';
import { ProfileSettingsService } from './profile-settings.service';

@Injectable()
export class TableDataService {
  constructor(
    private profileSettings: ProfileSettingsService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private profileSettingsService: ProfileSettingsService,
    private http: HttpClient,
    private dynamicFormMapper: DynamicFormMapper,
  ) {}

  async getColumnPreferenceFromUserSettings(
    keyName: string,
    tableName: TableType,
  ): Promise<string[]> {
    const userSettings: ProfileSettings = await this.profileSettings.getUserProfileSettingsValue();
    const columnPreference = userSettings.tableData?.[tableName]?.[keyName];
    if (userSettings.tableData && columnPreference) {
      return JSON.parse(columnPreference) as string[];
    }
    return undefined;
  }

  public static retrieveHeaderItemsByIdentifiers(
    initialHeader: TableHeaderItem[],
    identifiers: string[],
  ): TableHeaderItem[] {
    const initialHeaderMap: Map<string, TableHeaderItem> =
      this.buildInitialHeaderMap(initialHeader);
    return this.mapIdentifiersToHeaderItems(initialHeaderMap, identifiers);
  }

  private static buildInitialHeaderMap(
    initialHeader: TableHeaderItem[],
  ): Map<string, TableHeaderItem> {
    return new Map(
      initialHeader.map((item: TableHeaderItem) => [item.identifier.originalValue, item]),
    );
  }

  private static mapIdentifiersToHeaderItems(
    initialHeaderMap: Map<string, TableHeaderItem>,
    identifiers: string[],
  ): TableHeaderItem[] {
    return identifiers.map((identifier: string) => {
      const headerItem = initialHeaderMap.get(identifier);
      if (!headerItem) {
        throw new Error(`Header item not found for identifier: ${identifier}`);
      }
      return headerItem;
    });
  }

  public fetchHeaderPreferencesAndUpdateTable(table: TableList): Observable<TableList> {
    return from(this.fetchTableColumnPreferences(table)).pipe(
      mergeMap(async (storedHeaderIdentifiers: string[]) => {
        if (storedHeaderIdentifiers) {
          await this.handleTableHeaderUpdateAndSorting(table, storedHeaderIdentifiers);
        }
        return table;
      }),
    );
  }

  public async handleTableHeaderUpdateAndSorting(
    table: TableList,
    identifiers: string[],
  ): Promise<void> {
    try {
      table.updateHeaderAccordingToSavedIdentifiers(identifiers);
      table.sortColumnsHorizontally(table.sortedHeader);
      table.sortParams.identifier = null;
      table.sortColumnsVertically();
    } catch (error) {
      console.error(error);
      table.sortedHeader = table.initialHeader;
      await this.resetStoredHeaderPreference(table);
    }
  }

  private async resetStoredHeaderPreference(table: TableList): Promise<void> {
    const tableTranslations = await firstValueFrom(this.translateService.get('shared.tables'));
    await firstValueFrom(this.profileSettings.setTableColumnPreferenceValue(null, table));
    await this.loadingService.toast(tableTranslations['column-preference-reset']);
  }

  public async fetchTableColumnPreferences(table: TableList): Promise<string[]> {
    return await this.getColumnPreferenceFromUserSettings(table.storageKey, table.type);
  }

  public static getVisibleTableItemList<T>(itemList: T[], itemCount: number): T[] {
    return itemList.slice(0, itemCount);
  }

  public static getHiddenTableItemList<T>(itemList: T[], itemCount: number): T[] {
    return itemList.slice(itemCount);
  }

  public static getHiddenHeaderItemCount(table: TableList, rowState: TableRowState): number {
    return table.sortedHeader.length - rowState.columnNumber.current;
  }

  public async presentDataOrganizerModalSheet(
    table: TableList,
    rowState: TableRowState,
    headerReordering: BehaviorSubject<TableHeaderItem[]>,
  ): Promise<HTMLIonModalElement> {
    return await this.modalController.create({
      component: DataOrganizerComponent,
      componentProps: {
        headerReordering,
        table,
        rowState,
      },
      cssClass: 'filtering-and-sorting-modal',
      breakpoints: [0, 0.4, 1],
      initialBreakpoint: 0.4,
    });
  }

  public async presentDataOrganizerPopover(
    table: TableList,
    rowState: TableRowState,
    headerReordering: BehaviorSubject<TableHeaderItem[]>,
  ): Promise<HTMLIonPopoverElement> {
    return await this.popoverController.create({
      component: DataOrganizerToolboxComponent,
      componentProps: {
        headerReordering,
        table,
        rowState,
      },
      trigger: table.title + '-filter-button',
      reference: 'trigger',
      alignment: 'end',
      animated: true,
      cssClass: 'filtering-and-sorting-popover',
      side: 'bottom',
      arrow: false,
    });
  }

  public dismissActiveModalSheet(state: TableRowState, modal: HTMLIonModalElement): void {
    if (state?.view === TableRowView.mobile && modal) {
      this.modalController?.dismiss(null, null, modal.id);
    }
  }

  public dismissActivePopover(state: TableRowState, popover: HTMLIonPopoverElement): void {
    if (state?.view === TableRowView.desktop && popover) {
      this.popoverController?.dismiss(null, null, popover.id);
    }
  }

  public saveColumnPreferences(header: TableHeaderItem[], table: TableList): void {
    if (table.storageKey) {
      firstValueFrom(this.profileSettingsService.setTableColumnPreferenceValue(header, table));
    }
  }

  public getTableListWithHeaderAndActionButtons(
    primaryInvoker: Invoker,
    actionInvokers: Invoker[],
  ): Observable<TableList> {
    return this.fetchTableListWithHeader(primaryInvoker).pipe(
      map((table: TableList) => {
        table.actionButtons = SDAPIObjectMapper.mapInvokersToDynamicButtons(actionInvokers);
        return table;
      }),
    );
  }

  public fetchTableListWithHeader(primaryInvoker: Invoker): Observable<TableList> {
    return this.http
      .put<TieTableObjectList>(primaryInvoker.activityURL, primaryInvoker.invoker)
      .pipe(
        map((response: TieTableObjectList) => {
          const table: TableList = TableMapper.mapTable(response);
          table.header = this.retrieveTableHeader(response);
          return table;
        }),
      );
  }

  private retrieveTableHeader(response: TieTableObjectList): DynamicDataField[] {
    const form: DynamicForm = this.dynamicFormMapper.mapDynamicFormResource(
      response.header,
      null,
      false,
      true,
    );
    return form.header;
  }
}
