import { Component, Input } from '@angular/core';
import { faArrowRight, faXmark, IconDefinition, IconName } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-modal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title: string;
  @Input() titleIcon: IconDefinition | IconName;
  @Input() cancelText: string;
  @Input() style: string = ModalHeaderStyle.edit;
  @Input() hasVerticalPadding = true;
  @Input() showCancelButton = true;

  modalHeaderStyle: typeof ModalHeaderStyle = ModalHeaderStyle;

  cancelPopupOpen = false;

  closeIcon = faXmark;
  arrowRightIcon = faArrowRight;

  @Input()
  cancelButtonPopupConfiguration?: {
    cancelDescriptionTextKey: string;
    continueButtonTextKey: string;
    cancelButtonTextKey: string;
  };

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
  ) {
    firstValueFrom(this.translateService.get('general')).then((translations) => {
      if (!this.title) {
        this.title = translations.untitled;
      }
      if (!this.cancelText) {
        this.cancelText = translations.cancel;
      }
    });
  }

  get buttonColor(): string {
    if (this.cancelButtonPopupConfiguration) {
      return undefined;
    }
    return this.style === ModalHeaderStyle.view ? 'light' : 'tertiary';
  }

  get showCancelText(): boolean {
    return this.style !== ModalHeaderStyle.view;
  }

  async handleCancelButton() {
    if (!this.cancelButtonPopupConfiguration) {
      await this.modalController.dismiss('data', 'cancel');
    } else {
      this.cancelPopupOpen = true;
    }
  }

  handleCancelButtonPopup(): Promise<boolean> {
    this.cancelPopupOpen = false;
    return this.modalController.dismiss('data', 'cancel');
  }

  handleContinueButtonPopup(): void {
    this.cancelPopupOpen = false;
  }
}

export enum ModalHeaderStyle {
  edit = 'edit',
  view = 'view',
}
