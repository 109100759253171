import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeySerializer } from '@ngneat/cashew';
import md5Hex from 'md5-hex';

@Injectable()
export class HttpCacheKeySerializer extends KeySerializer {
  override serialize(request: HttpRequest<any>): string {
    switch (request.method) {
      case 'POST':
      case 'PUT':
      case 'DELETE':
      case 'PATCH':
        return `${request.method}@${request.urlWithParams}@${md5Hex(JSON.stringify(request.body))}`;
      default:
        return `${request.method}@${request.urlWithParams}`;
    }
  }
}
