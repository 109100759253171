import { APIError } from 'projects/core/src/lib/data/errors.data';
import { Patient, PatientAttributeNames } from 'projects/core/src/lib/models/patient.model';
import {
  TieTableObjectList,
  TieTableProcessor,
  TieTableRow,
} from '../models/sdapi-table-object.model';

export class PatientResourceMapper {
  public static mapResource(resource: TieTableObjectList): Patient[] {
    const patientsList: Patient[] = [];
    const table: TieTableProcessor = new TieTableProcessor(resource);
    const rows: TieTableRow[] = table.getRows();

    try {
      for (const row of rows) {
        const date: Date = new Date(row.getOptionalAttributeValue(PatientAttributeNames.birthDate));
        const isValidDate = !isNaN(date.getTime());

        const patient = new Patient({
          pid: row.getOptionalAttributeValue(PatientAttributeNames.pid),
          firstName: row.getOptionalAttributeValue(PatientAttributeNames.firstName),
          lastName: row.getOptionalAttributeValue(PatientAttributeNames.lastName),
          birthDate: isValidDate ? date : undefined,
          gender: row.getOptionalAttributeValue(PatientAttributeNames.gender),
          currentCase: row.getOptionalAttributeValue(PatientAttributeNames.currentCase),
          masterPatientRelation: row.getOptionalAttributeValue(
            PatientAttributeNames.masterPatientRelation,
          ),
          masterPatientRelationOid: row.getOptionalAttributeValue(
            PatientAttributeNames.masterPatientRelationOid,
          ),
          sourceSystem: row.getOptionalAttributeValue(PatientAttributeNames.sourceSystem),
          sourceSystemOid: row.getOptionalAttributeValue(PatientAttributeNames.sourceSystemOid),
          street: row.getOptionalAttributeValue(PatientAttributeNames.street),
          zipCode: row.getOptionalAttributeValue(PatientAttributeNames.zipCode),
          location: row.getOptionalAttributeValue(PatientAttributeNames.location),
          phoneNumber: row.getOptionalAttributeValue(PatientAttributeNames.phoneNumber),
          email: row.getOptionalAttributeValue(PatientAttributeNames.email),
          patientID: row.getOptionalAttributeValue(PatientAttributeNames.patientID),
          avatarUrl: row.getOptionalAttributeValue(PatientAttributeNames.avatarUrl),
        });

        patientsList.push(patient);
      }
    } catch (error) {
      throw new APIError('Mapping of patient list failed in the PatientResourceMapper.', error);
    }

    return patientsList;
  }
}
