import { Component, Input, OnInit } from '@angular/core';
import { TableHeaderItem, TableRowState } from 'projects/core/src/lib/models/dynamic-table.model';
import { TableDataService } from 'projects/core/src/lib/services/table-data.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-horizontal-sorting-section',
  templateUrl: './horizontal-sorting-section.component.html',
  styleUrls: ['../data-organizer-toolbox.scss'],
})
export class HorizontalSortingSectionComponent implements OnInit {
  @Input() header: TableHeaderItem[];
  @Input() rowState: TableRowState;
  @Input() headerReordering: BehaviorSubject<TableHeaderItem[]>;

  visibleHeaderItems: TableHeaderItem[];
  hiddenHeaderItems: TableHeaderItem[];
  isEditMode = false;

  ngOnInit(): void {
    this.setHiddenHeaderItems();
    this.setVisibleHeaderItems();
  }

  toggleEditMode(value: boolean): void {
    this.isEditMode = value;
  }

  private setVisibleHeaderItems(): void {
    this.visibleHeaderItems = TableDataService.getVisibleTableItemList<TableHeaderItem>(
      this.header,
      this.rowState.columnNumber.current,
    );
  }

  private setHiddenHeaderItems(): void {
    this.hiddenHeaderItems = TableDataService.getHiddenTableItemList<TableHeaderItem>(
      this.header,
      this.rowState.columnNumber.current,
    );
  }
}
