import { HttpHeaders } from '@angular/common/http';

export class CustomHttpHeaders {
  static readonly XBackgroundRequest: string = 'X-Background-Request';
  static readonly XPublicUserRequest: string = 'X-Public-User-Request';
  static readonly XNoAuthorization: string = 'X-No-Authorization';
  static readonly XNoUnauthorizedInterceptor: string = 'X-No-Unauthorized-Interceptor';
  static readonly XNoAlertInterceptor: string = 'X-No-Alert-Interceptor';
  static readonly XNoApiBaseUrlInterception: string = 'X-No-Api-Base-Url-Interception';
  static readonly XNoCache: string = 'X-No-Cache';

  static get XBackgroundRequestHeaders(): HttpHeaders {
    return new HttpHeaders().append(CustomHttpHeaders.XBackgroundRequest, 'true');
  }

  static get XPublicUserRequestHeaders(): HttpHeaders {
    return new HttpHeaders().append(CustomHttpHeaders.XPublicUserRequest, 'true');
  }

  static get XNoAuthorizationHeaders(): HttpHeaders {
    return new HttpHeaders().append(CustomHttpHeaders.XNoAuthorization, 'true');
  }

  static get XNoApiBaseUrlInterceptionHeaders(): HttpHeaders {
    return new HttpHeaders().append(CustomHttpHeaders.XNoApiBaseUrlInterception, 'true');
  }

  static get XNoAlertInterceptorHeaders(): HttpHeaders {
    return new HttpHeaders().append(CustomHttpHeaders.XNoAlertInterceptor, 'true');
  }

  static get XNoCacheHeaders(): HttpHeaders {
    return new HttpHeaders().append(CustomHttpHeaders.XNoCache, 'true');
  }

  static build(...headers: string[]): HttpHeaders {
    let httpHeaders: HttpHeaders = new HttpHeaders();

    for (const headersKey of headers) {
      httpHeaders = httpHeaders.set(headersKey, 'true');
    }

    return httpHeaders;
  }
}
