import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { concatMap, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { FormMapper } from '../mappers/form.mapper';
import { OrdersResourceMapper } from '../mappers/orders.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { TableList } from '../models/dynamic-table.model';
import { DynamicDataField, DynamicForm, FormIdentifier } from '../models/form.model';
import { Invoker, InvokerMethods } from '../models/invoker-body.model';
import {
  InvokerMethodCollection,
  OrdersSharingTranslationKeys,
  TranslationOptions,
} from '../models/modal-action.model';
import { Patient } from '../models/patient.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { SystemAttributeName } from '../models/shared.model';
import { PatientService } from './patient.service';
import { SDAPIService } from './sdapi.service';
import { TableDataService } from './table-data.service';

@Injectable()
@UntilDestroy()
export class OrdersService {
  constructor(
    private http: HttpClient,
    private patientService: PatientService,
    private sdapiService: SDAPIService,
    private dynamicFormMapper: DynamicFormMapper,
    private tableDataService: TableDataService,
  ) {}

  public getAllOrders(): Observable<TableList> {
    return this.sdapiService
      .getInvokerByMethodName('OE_ALL_ORDERS', InvokerMethods.constraintObjectList)
      .pipe(
        concatMap((invoker: Invoker) =>
          this.http.put<TieTableObjectList>(invoker.activityURL, invoker.invoker),
        ),
        switchMap((response: TieTableObjectList) =>
          this.mapAndUpdateTableAccordingToUserSettings(response),
        ),
      );
  }

  public getOrdersOfSpecificPatient(): Observable<TableList> {
    return this.patientService.getCurrentPatient().pipe(
      concatMap((patient: Patient) => this.findOrdersOfPatient(patient.patientID)),
      switchMap((response: TieTableObjectList) =>
        this.mapAndUpdateTableAccordingToUserSettings(response),
      ),
    );
  }

  async getOrderCountOfSpecificPatient(): Promise<number> {
    return (await firstValueFrom(this.getOrdersOfSpecificPatient())).initialRows.length;
  }

  private mapAndUpdateTableAccordingToUserSettings(
    response: TieTableObjectList,
  ): Observable<TableList> {
    const tableList: TableList = OrdersResourceMapper.mapOrderTable(response);
    return this.tableDataService.fetchHeaderPreferencesAndUpdateTable(tableList);
  }

  public findOrdersOfPatient(patientID: string): Observable<TieTableObjectList> {
    const dataMap: Map<string, string> = new Map([['TEMP.PATIENT_ID[BODY,1]', patientID]]);

    return this.sdapiService.findDataObjectWithMetaFinder<TieTableObjectList>(
      'OE_PAT_ORDERS',
      InvokerMethods.objectFindInObjectList,
      dataMap,
    );
  }

  public getOrderTypes(): Observable<TableList> {
    return this.findOrderTypes().pipe(
      map((response: TieTableObjectList) => OrdersResourceMapper.mapOrderTypeTable(response)),
    );
  }

  public findOrderTypes(): Observable<TieTableObjectList> {
    return this.sdapiService.findDataObjectWithMetaFinder<TieTableObjectList>(
      'META_FINDER',
      InvokerMethods.objectFindInObjectList,
      this.orderTypesDataMap,
    );
  }

  private get orderTypesDataMap(): Map<string, string> {
    return new Map([
      ['TEMP.TABLE[BODY,1]', 'OBJCLASS'],
      ['TEMP.FIELD[BODY,2]', 'TYPE'],
      ['TEMP.QUERY[BODY,3]', 'ORDER'],
    ]);
  }

  public createOrder(orderId: string): Observable<DynamicForm> {
    return this.sdapiService.getInvokerByMethodName(orderId, InvokerMethods.objectCreate).pipe(
      concatMap((invoker: Invoker) => this.getOrderForm(invoker)),
      map((dynamicForm: DynamicForm) => dynamicForm),
    );
  }

  public getOrderForm(orderCreateInvoker: Invoker): Observable<DynamicForm> {
    return this.http
      .put<TieFormObject>(orderCreateInvoker.activityURL, orderCreateInvoker.invoker)
      .pipe(
        map((response: TieFormObject) => {
          const activityURL: string = SDAPIObjectMapper.mapActivityPath(orderCreateInvoker.invoker);
          const dynamicForm: DynamicForm = this.dynamicFormMapper.mapDynamicFormResource(
            response,
            activityURL,
          );
          const invoker: Invoker[] = FormMapper.transferAttributesToInvokers(
            orderCreateInvoker,
            dynamicForm,
          );
          dynamicForm.invoker = invoker;
          return dynamicForm;
        }),
      );
  }

  public resolveOrderFormType(dynamicForm: DynamicForm): FormIdentifier[] {
    return dynamicForm.body
      .flatMap((field: DynamicDataField) => field.fieldGroup.flat())
      .map(({ prefillIdentifier }) => prefillIdentifier)
      .filter((prefillIdentifier: SystemAttributeName) =>
        Object.values(FormIdentifier).some((formIdentifier: FormIdentifier) =>
          prefillIdentifier.isEqualTo(new SystemAttributeName(formIdentifier)),
        ),
      )
      .map((prefillIdentifier: SystemAttributeName) => prefillIdentifier.originalValue);
  }

  public getOrderDetails(orderId: string): Observable<DynamicForm> {
    return this.sdapiService
      .getInvokerByMethodName(orderId, InvokerMethods.objectAttributesView)
      .pipe(concatMap((invoker: Invoker) => this.getOrderDetailsForm(invoker)));
  }

  private getOrderDetailsForm(orderViewInvoker: Invoker): Observable<DynamicForm> {
    return this.http
      .put<TieFormObject>(orderViewInvoker.activityURL, orderViewInvoker.invoker)
      .pipe(
        map((tieFormObject: TieFormObject) =>
          this.dynamicFormMapper.mapDynamicFormResource(tieFormObject, null, false, true),
        ),
      );
  }

  public translationOptionsForOrderCreation(): TranslationOptions {
    return {
      keys: OrdersSharingTranslationKeys,
      successMessageKey: 'save-completion',
      actionInProgressKey: 'saving-in-progress',
    };
  }

  public get previewFallbackMethods(): InvokerMethodCollection {
    return { preferred: InvokerMethods.objectAttributesView };
  }
}
