import { inject } from '@angular/core';
import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { API_CONFIG, ApiConfig } from 'projects/core/src/lib/models/api-config.model';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';

export const ApiBaseUrlInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const apiConfig: ApiConfig = inject(API_CONFIG);
  if (checkIfUrlNeedsPrefixedWithBase(req)) {
    const reqWithPrependApiUrl = req.clone({
      url: `${apiConfig.apiUrl}${apiConfig.apiSuffix}${req.url}`,
    });
    return next(reqWithPrependApiUrl);
  } else {
    return next(req);
  }
};

const checkIfUrlNeedsPrefixedWithBase = (req: HttpRequest<any>) => (
    req.url.startsWith('/') &&
    !req.headers.has(CustomHttpHeaders.XNoApiBaseUrlInterception) &&
    !req.url.startsWith('/auth-service')
  );
