import { Component, Input } from '@angular/core';
import { DynamicDataField } from 'projects/core/src/lib/models/form.model';

@Component({
  selector: 'lib-subscription-information',
  templateUrl: './subscription-information.component.html',
})
export class SubscriptionInformationComponent {
  @Input() fields: DynamicDataField[];
}
