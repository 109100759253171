import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { MarkdownService } from 'projects/core/src/lib/services/markdown.service';
import { APIError } from '../data/errors.data';
import { Imprint, ImprintFormatType, ImprintResourceAttributeNames } from '../models/imprint.model';
import {
  TieTableObjectList,
  TieTableProcessor,
  TieTableRow,
} from '../models/sdapi-table-object.model';
import { SharedMapper } from './shared.mapper';

@Injectable({ providedIn: 'root' })
export class ImprintMapper {
  constructor(private markdownService: MarkdownService) {}

  public mapResource(resource: TieTableObjectList): Imprint {
    const imprint = new Imprint();
    const table: TieTableProcessor = new TieTableProcessor(resource);
    const firstRow: TieTableRow = table.getRows()[0];

    try {
      if (firstRow) {
        const type: string = firstRow.getOptionalAttributeValue(ImprintResourceAttributeNames.type);
        const content: string = firstRow.getOptionalAttributeValue(
          ImprintResourceAttributeNames.content,
        );

        imprint.formatType = SharedMapper.parseEnum(ImprintFormatType, type);
        imprint.content = this.mapContent(content, imprint.formatType);
      }
    } catch (error) {
      throw new APIError('Mapping of imprint details failed in the ImprintMapper.', error);
    }

    return imprint;
  }

  public mapContent(content: string, type: ImprintFormatType): string | SafeHtml {
    if (!content || !type) {return null;}

    switch (type) {
      case ImprintFormatType.TEXT:
        return content.replace(/(\r\n|\n|\r|;\s)/gm, '<br>');
      case ImprintFormatType.HTML:
        return content;
      case ImprintFormatType.MD:
        return this.markdownService.parse(content);
      default:
        return '';
    }
  }
}
