<div>
  <p class="label-input ion-no-margin" *ngIf="selectedId">
    {{ 'shared.appointment-booking.appointment-selected' | translate }}: {{ getSelectedDate() }}
  </p>
  <div class="information" *ngIf="filteredDates?.length === 0">
    <fa-icon [icon]="ICONS.info" size="lg"></fa-icon>
    <div class="details">
      <div class="p4">
        {{ 'shared.appointment-booking.no-appointments-for-your-criteria' | translate }}
      </div>
    </div>
  </div>
  <div class="selection-date-section" *ngIf="filteredDates?.length > 0">
    <p class="label-input ion-no-margin">
      {{ 'shared.appointment-booking.date' | translate }}
    </p>
    <ion-datetime
      presentation="date"
      size="cover"
      [locale]="languageService.getCurrentLanguageCode()"
      [min]="firstAvailableDate"
      [max]="lastAvailableDate"
      color="medium"
      [isDateEnabled]="dateHasAvailableSlot"
      (ionChange)="dateSelected($event)"></ion-datetime>
  </div>
  <div class="selection-time-section" *ngIf="filteredDates?.length > 0 && selectedDate">
    <p class="label-input ion-no-margin">
      {{ 'shared.appointment-booking.time' | translate }}
    </p>
    <div class="available-timeslots">
      @for (timeslot of timeslotsForDate; track timeslot.id; let i = $index) {
        <ion-button
          *ngIf="i < 6 || showAllTimeslots"
          (click)="selectAppointment(timeslot.id)"
          color="primary"
          class="ion-no-margin"
          >{{ timeslot.time | time }}
        </ion-button>
      }
    </div>
    <ion-button
      *ngIf="timeslotsForDate.length > 6 && !this.showAllTimeslots"
      class="show-more-timeslots-button"
      color="primary"
      expand="block"
      (click)="showAllTimeslots = true">
      {{
        'shared.appointment-booking.show-more-slots' | translate: { count: timeslotsForDate.length }
      }}
      <fa-icon slot="end" [icon]="ICONS.showMoreTimeslots"></fa-icon>
    </ion-button>
  </div>
</div>
