@switch (item.type) {
  @case (dataType.text) {
    <lib-text-input-field [item]="item" [preview]="preview"></lib-text-input-field>
  }
  @case (dataType.number) {
    @if (item.value.options) {
      <lib-selection-input-field [item]="item" [preview]="preview"></lib-selection-input-field>
    } @else {
      <lib-number-input-field [item]="item" [preview]="preview"></lib-number-input-field>
    }
  }
  @case (dataType.textArea) {
    <lib-text-area-input-field [item]="item" [preview]="preview"></lib-text-area-input-field>
  }
  @case (dataType.select) {
    <lib-selection-input-field [item]="item" [preview]="preview"></lib-selection-input-field>
  }
  @case (dataType.radio) {
    <lib-selection-input-field [item]="item" [preview]="preview"></lib-selection-input-field>
  }
  @case (dataType.autocomplete) {
    <lib-autocomplete-field [item]="item" [preview]="preview"></lib-autocomplete-field>
  }
  @case (dataType.date) {
    <lib-date-input-field [item]="item" [preview]="preview"></lib-date-input-field>
  }
  @case (dataType.checkbox) {
    <lib-checkbox-input-field [item]="item" [preview]="preview"></lib-checkbox-input-field>
  }
  @case (dataType.file) {
    <lib-file-input-field [item]="item" [preview]="preview"></lib-file-input-field>
  }
  @case (dataType.title) {
    <lib-title-field [item]="item"></lib-title-field>
  }
  @case (dataType.richtext) {
    <lib-richtext [item]="item"></lib-richtext>
  }
}
