import { IconDefinition } from '@fortawesome/angular-fontawesome/types';

export class HeaderConfiguration {
  title?: DynamicTitle = undefined;
  icon?: IconDefinition = undefined;
  backNavigation?: BackNavigationDetails = undefined;
}

export interface BackNavigationDetails {
  path?: string;
  text: DynamicTitle;
}

export interface DynamicTitle {
  extended: any;
  short?: string;
}
