<ion-card color="light" class="no-margin">
  <ion-card-header>
    <ion-card-title>
      <h2 data-api-id="appointment-list">
        {{ 'appointments.your-upcoming-appointments' | translate }}
      </h2>
    </ion-card-title>
  </ion-card-header>

  <ion-card-content>
    <ng-container *ngIf="mappedAppointmentGroups">
      <ng-container *ngFor="let appointmentGroup of mappedAppointmentGroups.future">
        <lib-appointment-date-divider
          [date]="appointmentGroup.date"
          [mappedAppointmentGroups]="mappedAppointmentGroups">
        </lib-appointment-date-divider>
        <lib-appointment-item
          [widgetView]="true"
          [appointmentGroup]="appointmentGroup"
          [mappedAppointmentGroups]="mappedAppointmentGroups"
          (onSelectAppointment)="navigateToAppointment($event)">
        </lib-appointment-item>
      </ng-container>
    </ng-container>

    <div *ngIf="!mappedAppointmentGroups">
      <div class="skeleton-item" *ngFor="let item of [0]">
        <div class="appointments-date">
          <ion-skeleton-text animated="true" class="rounded-small"></ion-skeleton-text>
        </div>
        <div class="appointments-info">
          <ion-skeleton-text animated="true" class="rounded-small"></ion-skeleton-text>
        </div>
      </div>
    </div>

    @if (showNoAppointmentsMessage) {
      <lib-notification-box-component class="no-appointments" [message]="noAppointmentsMessage">
      </lib-notification-box-component>
    }

    <div class="button-section">
      <ion-button
        *ngIf="showRequestNewAppointment"
        id="newAppointment"
        class="p3"
        color="primary"
        expand="block"
        (click)="newAppointment()"
        data-api-id="appointment-creation">
        <fa-icon size="sm" slot="start" [icon]="icons.appointment"></fa-icon>
        {{ 'appointments.new-appointment-request' | translate }}
      </ion-button>
      <ion-button
        class="appointments-page-link p3"
        color="medium"
        fill="clear"
        expand="block"
        (click)="navigateToAllAppointments()">
        {{ 'appointments.show-all-appointments' | translate }}
      </ion-button>
    </div>
  </ion-card-content>
</ion-card>
