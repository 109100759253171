import { Component, Input } from '@angular/core';
import { DynamicDataField } from 'projects/core/src/lib/models/form.model';

@Component({
  selector: 'lib-richtext',
  templateUrl: './richtext.component.html',
})
export class RichtextComponent {
  @Input() item: DynamicDataField;
}
