<ion-list slot="content" style="overflow-y: scroll">
  <ion-item-divider sticky>
    <ion-label>
      <fa-icon [icon]="ICONS.DESIGN" size="lg"></fa-icon>
      Theme Editor
    </ion-label>
  </ion-item-divider>
  <ion-item-divider sticky>
    <ion-label>
      <fa-icon [icon]="ICONS.LOGO" size="lg"></fa-icon>
      Logo
    </ion-label>
  </ion-item-divider>

  <ion-item-group>
    <ion-item lines="none">
      <ion-input label="Grafik" slot="end" type="file" (change)="changeLogo($event)"></ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-input
        label="Seitenabstand"
        slot="end"
        type="number"
        step="0.1"
        placeholder="Grösse"
        pattern="number"
        (change)="setLogoPadding($event, 'padding')"
        (keyup)="setLogoPadding($event, 'padding')"></ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-input
        label="Seitenabstand Mobilgerät"
        slot="end"
        type="number"
        step="0.1"
        placeholder="Grösse"
        (change)="setLogoPadding($event, 'paddingMobile')"
        (keyup)="setLogoPadding($event, 'paddingMobile')"></ion-input>
    </ion-item>
  </ion-item-group>

  <ion-item-divider sticky>
    <ion-label>
      <fa-icon [icon]="ICONS.COLORS" size="lg"></fa-icon>
      Farben
    </ion-label>
  </ion-item-divider>

  <ion-item lines="none" class="description">
    <p>
      primary – Die Primärfarbe ist dominierend im Design und wird für Akzente und Aktionen
      verwendet.
      <br />
      <br />secondary – Die Sekundärfarbe wird für die Hintergrundgrafiken verwendet.
      <br />
      <br />greyish – Der Grauton wird für Text und Abstufungen verwendet und automatisch anhand der
      Primärfarbe generiert. Der Wert kann optional überschrieben werden.
      <br />
      <br />warn – Die Warnfarbe wir in Formularen verwendet um falsche Angaben zu kennzeichnen.
      <br />
      <br />info – Aktuell noch nicht in Gebrauch.
    </p>
  </ion-item>

  <ion-item-group *ngFor="let type of getColorSelectors()">
    <ion-item-divider sticky>
      <ion-label>Farbtyp: {{ type }}</ion-label>
    </ion-item-divider>
    <ion-item lines="none">
      <ion-input
        label="Farbe"
        type="text"
        slot="end"
        placeholder="Farbe als HEX Wert #FFFFFF"
        (change)="setColor($event, type)"
        (keyup)="setColor($event, type)"></ion-input>
    </ion-item>
  </ion-item-group>

  <ion-item-divider sticky>
    <ion-label>
      <fa-icon [icon]="ICONS.PROPERTIES" size="lg"></fa-icon>
      Eigenschaften
    </ion-label>
  </ion-item-divider>

  <ion-item lines="none" class="description">
    <p>Verschiedene Eigenschaften, welche das globale Design beeinflussen.</p>
  </ion-item>

  <ion-item-group *ngFor="let type of getPropertySelectors()">
    <ion-item-divider sticky>
      <ion-label>Eigenschaft: {{ type }}</ion-label>
    </ion-item-divider>
    <ion-item lines="none">
      <ion-input
        label="Radius"
        type="number"
        slot="end"
        step="1"
        placeholder="Radius in px"
        (change)="setProperty($event, type)"
        (keyup)="setProperty($event, type)"></ion-input>
    </ion-item>
  </ion-item-group>

  <ion-item-divider sticky>
    <ion-label>
      <fa-icon [icon]="ICONS.FONT" size="lg"></fa-icon>
      Typografie
    </ion-label>
  </ion-item-divider>

  <ion-item-group *ngFor="let type of getFontSelectors()">
    <ion-item-divider sticky>
      <ion-label>Absatzformat: {{ type }}</ion-label>
    </ion-item-divider>
    <ion-item *ngIf="fontDescription[type]" lines="none">
      <p>{{ fontDescription[type] }}</p>
    </ion-item>
    <ion-item lines="none">
      <ion-input
        label="Schriftgrösse"
        type="number"
        step="0.1"
        placeholder="Grösse"
        slot="end"
        (change)="setFontSize($event, type)"
        (keyup)="setFontSize($event, type)"></ion-input>
    </ion-item>
    <ion-item lines="none">
      <ion-input
        label="Zeilenabstand"
        type="number"
        step="0.1"
        placeholder="Abstand"
        slot="end"
        (change)="setFontLineHeight($event, type)"
        (keyup)="setFontLineHeight($event, type)"></ion-input>
    </ion-item>
    <ion-item lines="none">
      <ion-segment value="left">
        <ion-segment-button
          (click)="setFontAlign($event, type)"
          color="secondary"
          *ngFor="let item of ['left', 'center', 'right', 'justify']"
          [value]="item">
          <fa-icon [icon]="ICONS[item.toUpperCase()]"></fa-icon>
        </ion-segment-button>
      </ion-segment>
    </ion-item>
    <ion-item lines="none">
      <ion-input
        label="Schriftdatei (TTF/OTF)"
        slot="end"
        type="file"
        accept=".ttf, .otf"
        (change)="insertFont($event, type)"></ion-input>
    </ion-item>
    <ion-item lines="none">
      <ion-input
        label="Schnitt (nur V-Fonts)"
        type="number"
        step="1"
        min="1"
        max="1000"
        placeholder="Schnitt"
        slot="end"
        (change)="setFontWeight($event, type)"
        (keyup)="setFontWeight($event, type)"></ion-input>
    </ion-item>
  </ion-item-group>

  <ion-item-divider sticky>
    <ion-label>
      <fa-icon [icon]="ICONS.JSON" size="lg"></fa-icon>
      Theme-Datei
    </ion-label>
  </ion-item-divider>

  <ion-item-group>
    <ion-item lines="full">
      <ion-input
        label="Theme-Datei anzeigen"
        slot="end"
        type="file"
        (change)="setTheme($event)"></ion-input>
    </ion-item>
    <ion-item lines="full">
      <ion-input
        label="Theme Domain"
        slot="end"
        type="text"
        placeholder="default"
        [(ngModel)]="domain"
        pattern="(\w|-)*"></ion-input>
      <p slot="error" style="color: red">
        Bitte nur Buchstaben und Zahlen verwenden. (A-z 0-9 _ -)
      </p>
    </ion-item>
    <ion-item lines="full">
      <ion-label>Theme Datei</ion-label>
      <ion-button class="ion-no-margin" size="small" color="primary" slot="end" style="width: auto">
        <a (click)="downloadThemeZIP()" style="color: white; font-weight: bold">
          {{ getThemeDomain() }}.theme&nbsp;
          <fa-icon style="color: white" [icon]="ICONS.DOWNLOAD"></fa-icon>
        </a>
      </ion-button>
    </ion-item>
    <ion-item lines="full">
      <ion-label>Theme JSON</ion-label>
      <ion-button class="ion-no-margin" size="small" color="light" slot="end" style="width: auto">
        <a [href]="getThemeBlob()" download="theme.json" style="color: var(--color-primary)">
          {{ getThemeDomain() }}.theme.json&nbsp;
          <fa-icon style="color: var(--color-primary)" [icon]="ICONS.DOWNLOAD"></fa-icon>
        </a>
      </ion-button>
    </ion-item>
  </ion-item-group>
  <ion-item lines="none">
    Theme Editor
    <ion-label slot="end">Version 2.0</ion-label>
  </ion-item>
</ion-list>
