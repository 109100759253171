import { Injectable } from '@angular/core';

@Injectable()
export class VideoCallService {
  getVideoCallUrl(isDoctor: boolean): string {
    if (isDoctor) {
      return 'https://video-iengineers-test.arztkonsultation.de/video/5469?tan=jKWn-34ha-z5uU';
    } else {
      return 'https://video-iengineers-test.arztkonsultation.de/video/5469?tan=UnTL-naHb-yqqx';
    }
  }
}
