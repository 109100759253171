import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { StorageService } from '../services/storage.service';

export const AppointmentGuard: CanActivateFn = async (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): Promise<boolean | UrlTree> => {
  const storageService: StorageService = inject(StorageService);
  const router: Router = inject(Router);

  if (_state.url === '/portal/appointments') {
    return true;
  } else if (await storageService.getObject('bookingData')) {
    await router.navigateByUrl('/portal/appointments');
    return true;
  } else {
    return true;
  }
};
