import { Component, Input, OnInit } from '@angular/core';
import {
  faCalendar,
  faCalendarPlus,
  faChevronRight,
  faClipboard,
  faClock,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  OPTIONAL_REQUIRED_ACTIONS_ATTRIBUTE_ICONS,
  RequiredActionsDetails,
} from 'projects/core/src/lib/models/required-actions.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';
import { LocaleDatePipe } from 'projects/theme/src/lib/pipes/locale-date.pipe';
import { firstValueFrom } from 'rxjs';
import { ModalHeaderStyle } from '../../modal-helper/header/header.component';

@Component({
  selector: 'pp-required-action-info-overview',
  templateUrl: './required-action-info-overview.component.html',
  styleUrl: './required-action-info-overview.component.scss',
})
export class RequiredActionInfoOverviewComponent implements OnInit {
  @Input() requiredAction: RequiredActionsDetails;

  modalHeaderStyle: typeof ModalHeaderStyle = ModalHeaderStyle;
  remainingDaysNotification: string;
  dueDateNotification: string;
  translations: Object;

  iconSet = OPTIONAL_REQUIRED_ACTIONS_ATTRIBUTE_ICONS;
  readonly icons = {
    clipboard: faClipboard,
    clock: faClock,
    info: faInfoCircle,
    calendarPlus: faCalendarPlus,
    calendar: faCalendar,
    arrow: faChevronRight,
  };

  constructor(
    public breakpoint: BreakpointService,
    private localeDatePipe: LocaleDatePipe,
    private modalController: ModalController,
    private translateService: TranslateService,
  ) {}

  async ngOnInit() {
    this.translations = await firstValueFrom(this.translateService.get('shared.required-action'));
    await this.loadNotificationMessages();
  }

  private async loadNotificationMessages(): Promise<void> {
    this.remainingDaysNotification = await this.getRemainingDaysNotification();
    this.dueDateNotification = await this.getDueDateNotification();
  }

  dismissModalWithEventMessage() {
    this.modalController.dismiss(this.requiredAction, OverlayEventRole.actionButtonEvent);
  }

  private async getRemainingDaysNotification(): Promise<string | null> {
    if (
      this.requiredAction.remainingDaysForProcessing <= 0 ||
      this.requiredAction.remainingDaysForProcessing > 7
    ) {
      return null;
    }

    return this.getRemainingDaysText();
  }

  private async getRemainingDaysText(): Promise<string> {
    const daysNumber: number = this.requiredAction.remainingDaysForProcessing;
    const daysLabel: string = await this.resolveDaysLabel(daysNumber);
    return await firstValueFrom(
      this.translateService.get('shared.required-action.days-remaining-for-processing', {
        daysLabel,
        daysNumber,
      }),
    );
  }

  private async getDueDateNotification(): Promise<string | null> {
    const translationKey: string = this.getTranslationKeyBasedOnDate();
    if (translationKey === null) {
      return null;
    }
    const dueDateText = await firstValueFrom(this.translateService.get(translationKey));
    return `${dueDateText} ${this.weekDayName}, ${this.longEndDateFormat}.`;
  }

  private get weekDayName(): string {
    return this.localeDatePipe.transform(this.requiredAction.endDate.value, 'EEEE');
  }

  private get longEndDateFormat(): string {
    return this.localeDatePipe.transform(this.requiredAction.endDate.value, 'longDate');
  }

  private getTranslationKeyBasedOnDate(): string | null {
    const endDate = new Date(this.requiredAction.endDate.value);
    const isToday = new Date().toDateString() === endDate.toDateString();
    return this.resolveTranslationKeyBasedOnDate(isToday, this.requiredAction.isOverdue);
  }

  private resolveTranslationKeyBasedOnDate(isToday: boolean, isOverdue: boolean): string | null {
    if (isToday && !isOverdue) {
      return 'shared.required-action.due-today';
    }
    if (isOverdue) {
      return 'shared.required-action.overdue-on';
    }
    return 'shared.required-action.due-on';
  }

  private async resolveDaysLabel(daysNumber: number): Promise<string> {
    const key = daysNumber === 1 ? 'general.day' : 'general.days';
    return firstValueFrom(this.translateService.get(key));
  }
}
