<lib-auto-layout
  direction="horizontal"
  [gap]="[10]"
  horizontal="space-between"
  vertical="start"
  [padding]="[5, 20]">
  <lib-auto-layout direction="horizontal" [gap]="[10]">
    <fa-icon slot="start" size="lg" [icon]="item.icon"></fa-icon>
    <h3 class="ion-no-margin">{{ item.label | translate }}</h3>
  </lib-auto-layout>
  <div class="info-item-value ion-text-end" [grow]="1">
    @if (item.value) {
      <span class="p ion-no-margin">{{ item.value }}</span>
    } @else {
      <span class="p ion-no-margin">{{ 'general.unknown' | translate }}</span>
    }
  </div>
</lib-auto-layout>
