import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faCircleXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { IonInput } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchLayoutType } from 'projects/core/src/lib/models/dynamic-table.model';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'lib-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
@UntilDestroy()
export class SearchInputComponent implements OnInit {
  @ViewChild('searchInput') searchInput: IonInput;

  @Input() disabled: boolean;
  @Input() searchParams: SearchParams;
  @Input() placeholder: string;
  @Input() type: SearchLayoutType = 'card';
  @Output() searchParamsChanged = new EventEmitter<void>();
  @Output() inputChanged = new EventEmitter<void>();

  criterions$ = new BehaviorSubject<string>('');

  readonly icons = {
    search: faMagnifyingGlass,
    delete: faCircleXmark,
  };

  searchInputDirty = false;

  ngOnInit(): void {
    this.criterions$
      .pipe(debounceTime(333), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => this.searchParamsChanged.emit());
  }

  focusOnSearchInput(): void {
    this.searchInput.setFocus();
  }

  deleteSearchInput(): void {
    this.searchInputDirty = false;
    this.inputChanged.emit();
    this.searchParams.criterions = '';
    this.searchParamsChanged.emit();
  }

  onCriterionChange(criterions: string): void {
    const value: string = criterions;
    this.searchInputDirty = true;
    this.inputChanged.emit();
    if (typeof value === 'string') {
      this.criterions$.next(value);
    }
  }

  get isInputDirty(): boolean {
    return this.searchInputDirty;
  }

  get hasCardLayout(): boolean {
    return this.type === 'card';
  }
}
