import { Component, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { PopoverController } from '@ionic/angular';
import { MaskitoOptions } from '@maskito/core';
import { maskitoDateOptionsGenerator, maskitoTimeOptionsGenerator } from '@maskito/kit';
import dayjs from 'dayjs';
import { BaseFieldComponent } from 'projects/shared/src/lib/components/form/base-field/base-field.component';
import { DateFieldDatePopoverComponent } from 'projects/shared/src/lib/components/form/date-field/date-field-date-popover/date-field-date-popover.component';
import { DateFieldTimePopoverComponent } from 'projects/shared/src/lib/components/form/date-field/date-field-time-popover/date-field-time-popover.component';
import { LocaleDatePipe } from 'projects/theme/src/lib/pipes/locale-date.pipe';
import { DateFieldSelectTimeOrDatePopoverComponent } from './date-field-select-time-or-date-popover/date-field-select-time-or-date-popover.component';

@Component({
  selector: 'lib-date-input-field',
  templateUrl: './date-field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrls: ['./date-field.component.scss'],
})
export class DateFieldComponent extends BaseFieldComponent implements OnInit {
  readonly icons = {
    calendar: faCalendar,
  };

  readonly dateMask: MaskitoOptions = maskitoDateOptionsGenerator({ mode: 'dd/mm/yyyy' });
  readonly timeMask: MaskitoOptions = maskitoTimeOptionsGenerator({
    mode: 'HH:MM',
  });

  @ViewChild('dateInput') dateInput: NgModel;
  @ViewChild('timeInput') timeInput: NgModel;

  currentMaskedDateValue = '';
  currentMaskedTimeValue = '';

  constructor(
    private popoverController: PopoverController,
    private localeDatePipe: LocaleDatePipe,
  ) {
    super();
  }

  async ngOnInit() {
    this.setCurrentMaskedInputsFromAcutalValue();
  }

  async openSelectionPopover(field: NgModel) {
    if (!this.item.hasTime) {
      this.openDatePopover(field);
    } else {
      const popover: HTMLIonPopoverElement = await this.popoverController.create({
        component: DateFieldSelectTimeOrDatePopoverComponent,
        trigger: 'date-time-' + this.customHtmlId,
        reference: 'trigger',
        cssClass: 'date-or-time-picker',
        alignment: 'end',
        side: 'bottom',
        animated: true,
        arrow: false,
        id: 'date-or-time-select-popover',
      });
      popover.keepContentsMounted = true;
      popover.onDidDismiss().then((dismissEvent) => {
        if (dismissEvent.data === 'date') {
          this.openDatePopover(field);
        } else if (dismissEvent.data === 'time') {
          this.openTimePopover(field);
        }
      });
      await popover.present();
    }
  }

  async openDatePopover(field: NgModel): Promise<void> {
    const popover: HTMLIonPopoverElement = await this.popoverController.create({
      component: DateFieldDatePopoverComponent,
      componentProps: {
        name: this.item.name,
        value: this.item.value.value
          ? this.localeDatePipe.transform(this.item.value.value, 'yyyy-MM-dd')
          : '',
        item: this.item,
        dates: (this.item.value.options || []).map((date) =>
          this.localeDatePipe.transform(date.value, 'yyyy-MM-dd'),
        ),
      },
      reference: 'trigger',
      cssClass: 'date-picker',
      alignment: 'start',
      side: 'bottom',
      animated: true,
      arrow: false,
      id: 'date-field-date-popover',
      trigger: 'date-' + this.customHtmlId,
    });
    popover.keepContentsMounted = true;
    popover.onDidDismiss().then((dismissEvent) => {
      if (dismissEvent.data) {
        const dateStringFromDatePopover = dismissEvent.data;
        const dateStringSplittedIntoYearMonthDayArray = dateStringFromDatePopover.split('-');
        const year = parseInt(dateStringSplittedIntoYearMonthDayArray[0], 10);
        const month = parseInt(dateStringSplittedIntoYearMonthDayArray[1], 10) - 1;
        const day = parseInt(dateStringSplittedIntoYearMonthDayArray[2], 10);
        const existingDate = this.getAlreadyExistingDate();
        const newDate = existingDate.tz().set('year', year).set('month', month).set('date', day);
        this.item.value.value = newDate.toISOString();
        this.setCurrentMaskedInputsFromAcutalValue();
      }
      this.ensureFieldMarkedAsTouched(field);
    });
    await popover.present();
  }

  async openTimePopover(field: NgModel): Promise<void> {
    const popover: HTMLIonPopoverElement = await this.popoverController.create({
      component: DateFieldTimePopoverComponent,
      componentProps: {
        name: this.item.name,
        value: this.item.value.value ? this.currentMaskedTimeValue : '12:00',
      },
      reference: 'trigger',
      cssClass: 'time-picker',
      alignment: 'end',
      side: 'bottom',
      animated: true,
      arrow: false,
      id: 'date-field-time-popover',
      trigger: 'time-' + this.customHtmlId,
    });
    popover.keepContentsMounted = true;
    popover.onDidDismiss().then((dismissEvent) => {
      if (dismissEvent.data) {
        const timeStringFromTimePopover = dismissEvent.data;
        const timeStringSplittedIntoHoursAndMinutesArray = timeStringFromTimePopover.split(':');
        const hours = parseInt(timeStringSplittedIntoHoursAndMinutesArray[0], 10);
        const minutes = parseInt(timeStringSplittedIntoHoursAndMinutesArray[1], 10);
        const newDate = this.getAlreadyExistingDate()
          .tz()
          .set('hour', hours)
          .set('minute', minutes);
        this.item.value.value = newDate.toISOString();
        this.setCurrentMaskedInputsFromAcutalValue();
      }
      this.ensureFieldMarkedAsTouched(field);
    });
    await popover.present();
  }

  onDateChange(event: Event, field: NgModel): void {
    this.ensureFieldMarkedAsTouched(field);
    if (event.target instanceof HTMLInputElement) {
      this.processValidDateInput(event.target.value);
    }
  }

  private getAlreadyExistingDate(): dayjs.Dayjs {
    const existingDate = this.item.value.value ? dayjs(this.item.value.value) : dayjs();
    return existingDate;
  }

  private processValidDateInput(currentInput: string): void {
    if (!currentInput) {
      this.item.value.value = '';
    }
    if (!currentInput || currentInput.length < 10) {
      this.item.value.value = '';
      return;
    }
    const parts = currentInput.split('.');
    if (parts.length !== 3) {
      this.item.value.value = '';
      return;
    }
    const newDate = this.getAlreadyExistingDate()
      .tz()
      .set('year', Number.parseInt(parts[2], 10))
      .set('month', Number.parseInt(parts[1], 10) - 1)
      .set('date', Number.parseInt(parts[0], 10));

    if (!newDate.isValid()) {
      this.item.value.value = '';
      return;
    }
    this.item.value.value = newDate.toISOString();
  }

  onTimeChange(event: Event, field: NgModel): void {
    this.ensureFieldMarkedAsTouched(field);
    if (event.target instanceof HTMLInputElement) {
      this.processValidTimeInput(event.target.value);
    }
  }

  processValidTimeInput(currentInput: string) {
    if (!this.item.hasTime) {
      return;
    }
    if (!currentInput) {
      this.item.value.value = '';
      return;
    }
    if (!currentInput || currentInput.length < 5) {
      this.item.value.value = '';
      return;
    }
    const parts: string[] = currentInput.split(':');
    if (parts.length !== 2) {
      this.item.value.value = '';
      return;
    }
    const hours: string = parts[0];
    const minutes: string = parts[1];
    const newDate = this.getAlreadyExistingDate()
      .tz()
      .set('hour', Number.parseInt(hours, 10))
      .set('minute', Number.parseInt(minutes, 10));
    if (newDate.toString() === 'Invalid Date') {
      this.item.value.value = '';
      return;
    }
    this.item.value.value = newDate.toISOString();
  }

  setCurrentMaskedInputsFromAcutalValue() {
    if (!this.item.value.value) {
      this.currentMaskedDateValue = '';
      this.currentMaskedTimeValue = '';
      return;
    }
    this.dateInput?.control?.setValue(
      this.localeDatePipe.transform(this.item.value.value, 'dd.MM.yyyy'),
    );
    this.timeInput?.control?.setValue(
      this.localeDatePipe.transform(this.item.value.value, 'HH:mm'),
    );
    this.currentMaskedDateValue = this.localeDatePipe.transform(
      this.item.value.value,
      'dd.MM.yyyy',
    );
    this.currentMaskedTimeValue = this.localeDatePipe.transform(this.item.value.value, 'HH:mm');
  }
}
