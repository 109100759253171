<ion-card class="testimonial">
  <ion-card-content>
    <ion-grid class="person">
      <ion-row>
        <ion-col class="avatar" size="2">
          <ion-img class="image" [src]="image" [alt]="name"></ion-img>
        </ion-col>
        <ion-col class="info" size="10">
          <ion-text class="name p3">{{ name }}</ion-text>
          <ion-text class="role p">{{ role }}</ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
    <p class="quote align">„{{ quote }}“</p>
  </ion-card-content>
</ion-card>
