<ion-card
  *ngIf="!isMobile || isAppointmentListInMobileView"
  [ngSwitch]="selectedView"
  class="fill-height stretch-container ion-no-margin overflow-visible"
  color="light">
  @if (!isLoading) {
    <div class="button-section calendar-navigation-buttons">
      <div class="stretch">
        <ion-segment class="ion-no-margin" value="OVERVIEW" [(ngModel)]="selectedView">
          <ion-segment-button value="OVERVIEW">
            <ion-label class="p4">{{
              'shared.appointment-overview.overview' | translate
            }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="MONTH">
            <ion-label class="p4">
              {{
                selectedView === 'MONTH'
                  ? currentViewTitle
                  : ('shared.appointment-overview.month' | translate)
              }}
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="DAY">
            <ion-label class="p4">{{
              selectedView === 'DAY'
                ? currentViewTitle
                : ('shared.appointment-overview.day' | translate)
            }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>

      @if (!isMobile) {
        <div class="ion-button-group">
          <ion-button
            class="p3"
            color="primary"
            [disabled]="selectedView === 'OVERVIEW'"
            (click)="onPrevClick()">
            <fa-icon [icon]="icons.arrowLeft"></fa-icon>
          </ion-button>
          <ion-button
            class="p3"
            color="primary"
            [disabled]="selectedView === 'OVERVIEW'"
            (click)="onNextClick()">
            <fa-icon [icon]="icons.arrowRight"> </fa-icon>
          </ion-button>
          <ion-button
            class="p3"
            color="primary"
            [disabled]="selectedView === 'OVERVIEW' || isToday()"
            (click)="onTodayClick()">
            {{ 'shared.appointment-overview.today' | translate }}
          </ion-button>
          @if (showRequestNewAppointment && (!isDoctor || hasPatientView)) {
            <ion-button
              class="p3"
              color="primary"
              data-api-id="appointment-creation"
              (click)="newAppointment()">
              {{ 'shared.appointment-overview.new-appointment' | translate }}
              <fa-icon size="xs" slot="end" [icon]="icons.appointment"></fa-icon>
            </ion-button>
          }
        </div>
      }
    </div>
  }
  @if (!isReadyToShowAppointments) {
    <div class="stretch-self align-center">
      <lib-inline-loader
        [message]="
          'shared.appointment-overview.loading-appointment-calendar' | translate
        "></lib-inline-loader>
    </div>
  } @else {
    <div class="stretch-flex">
      <div *ngSwitchCase="'OVERVIEW'" class="overview-container">
        <div class="stretch-frame sidebar">
          <lib-appointments-scroll-list
            *ngIf="mappedAppointmentGroups"
            (onSelectAppointment)="onAppointmentClick($event)"
            [mappedAppointmentGroups]="mappedAppointmentGroups"
            [selectedAppointment]="selectedAppointment"
            [selectedDate]="selectedDate"
            [isLoading]="isLoading">
          </lib-appointments-scroll-list>
        </div>
        <div class="stretch-frame details" *ngIf="!isMobile">
          <lib-appointment-details
            *ngIf="selectedAppointment"
            [selectedAppointment]="selectedAppointment"
            [isDoctor]="isDoctor"
            (redirectToMonthOverview)="activateMonthOverview($event)"
            (appointmentListUpdate)="appointmentsReload.emit()">
          </lib-appointment-details>
        </div>
      </div>

      <div *ngSwitchCase="'MONTH'" class="month-container">
        <full-calendar #monthCalendar [options]="calendarOptions">
          <ng-template #eventContent let-item>
            <div class="event-item p" [title]="item.event.title">
              <div
                class="marker"
                [style.background-color]="item.event.extendedProps.statusColor"></div>
              <div class="icon"><fa-icon [icon]="item.event.extendedProps.icon"></fa-icon></div>
              <div class="time">
                <strong>
                  {{ item.event.start | time: 'shortTime' : true }}
                </strong>
              </div>
            </div>
          </ng-template>

          <ng-template #dayHeaderContent let-item>
            <div class="p ion-hide-md-down">
              {{ item.date | localeDate: 'EEEE' }}
            </div>
            <div class="p ion-hide-md-up">
              {{ item.date | localeDate: 'EE' }}
            </div>
          </ng-template>

          <ng-template #dayCellContent let-item>
            <div class="p3">
              {{ item.dayNumberText }}
            </div>
          </ng-template>
        </full-calendar>
      </div>

      <div *ngSwitchCase="'DAY'" class="overview-container">
        <div class="stretch-frame sidebar">
          <div
            class="no-details"
            *ngIf="!selectedDateAppointments || !selectedDateAppointments.appointments">
            <p class="p3">
              <fa-icon [icon]="icons.info" size="xl"></fa-icon>
              {{
                'shared.appointment-overview.no-appointments-for-this-day'
                  | translate: { day: currentViewTitle }
              }}.
            </p>
          </div>
          <ion-list
            lines="none"
            class="appointment-list"
            *ngIf="selectedDateAppointments && selectedDateAppointments.appointments">
            <lib-appointment-item
              [appointmentGroup]="selectedDateAppointments"
              [selectedDate]="selectedDate"
              [selectedAppointment]="selectedAppointment"
              [mappedAppointmentGroups]="mappedAppointmentGroups"
              (onSelectAppointment)="onAppointmentClick($event)">
            </lib-appointment-item>
          </ion-list>
        </div>
        <div class="stretch-frame details" *ngIf="!isMobile">
          <lib-appointment-details
            *ngIf="selectedAppointment"
            [selectedAppointment]="selectedAppointment"
            [isDoctor]="isDoctor"
            (redirectToMonthOverview)="activateMonthOverview($event)"
            (appointmentListUpdate)="appointmentsReload.emit()">
          </lib-appointment-details>
        </div>
      </div>
    </div>
  }
  <div
    class="ion-button-group footer-button-group"
    *ngIf="!isLoading && isAppointmentListInMobileView">
    <ion-button
      class="p4"
      color="primary"
      [disabled]="selectedView === 'OVERVIEW'"
      (click)="onPrevClick()">
      <fa-icon [icon]="icons.arrowLeft"></fa-icon>
    </ion-button>
    <ion-button
      class="p4"
      color="primary"
      [disabled]="selectedView === 'OVERVIEW'"
      (click)="onNextClick()">
      <fa-icon [icon]="icons.arrowRight"> </fa-icon>
    </ion-button>
    <ion-button
      class="p4"
      color="primary"
      [disabled]="selectedView === 'OVERVIEW' || isToday()"
      (click)="onTodayClick()">
      {{ 'shared.appointment-overview.today' | translate }}
    </ion-button>
    <ion-button
      *ngIf="showRequestNewAppointment && (!isDoctor || hasPatientView)"
      class="p4"
      color="primary"
      data-api-id="appointment-creation"
      (click)="newAppointment()">
      {{ 'shared.appointment-overview.new-appointment' | translate }}
      <fa-icon size="xs" slot="end" [icon]="icons.appointment"></fa-icon>
    </ion-button>
  </div>
</ion-card>

<ion-card *ngIf="isMobile && selectedAppointment" class="fill-height ion-no-margin" color="light">
  <lib-appointment-details
    [selectedAppointment]="selectedAppointment"
    [isDoctor]="isDoctor"
    (redirectToMonthOverview)="activateMonthOverview($event)"
    (appointmentListUpdate)="appointmentsReload.emit()">
  </lib-appointment-details>
</ion-card>
