import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { ViewDocumentDetails } from 'projects/core/src/lib/models/documents.model';

@Component({
  selector: 'lib-document-details-popover-content',
  templateUrl: './document-details-popover-content.component.html',
  styleUrls: ['./document-details-popover-content.component.scss'],
})
export class DocumentDetailsPopoverContentComponent {
  @Input() document: ViewDocumentDetails;
  @Output() dismiss: EventEmitter<void> = new EventEmitter<void>();

  readonly icons = { size: faCircleInfo };
}
