import { Injectable } from '@angular/core';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

@Injectable()
export class MarkdownService {
  parse(markdown: string): string {
    return DOMPurify.sanitize(marked.parse(markdown) as string);
  }
}
