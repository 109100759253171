<lib-auto-layout
  class="header"
  vertical="center"
  [class.desktop]="rowState.isDesktopView"
  (mouseenter)="updateColumnIndicatorState(true)"
  (mouseleave)="updateColumnIndicatorState(false)">
  <div class="row-container" [class.selectable-rows]="table?.selectableRows">
    @for (item of displayHeader; track item.identifier; let lastItem = $last) {
      <div class="stretch-self row-cell" [class.stretch-cell]="hasHiddenHeaderItems && lastItem">
        <div class="trim-text-inline sorting-button-container">
          <ion-button
            class="no-margin"
            size="small"
            color="light"
            [title]="item.value"
            (click)="updateSortParamsAndSortVertically(item)"
            [class.shrink]="isColumnIndicatorExpanded">
            <h3 class="uppercase">
              {{ item.value }}
            </h3>
            @if (checkSortParams(item, true)) {
              <fa-icon slot="end" [icon]="getSortIconByType(item.type, true)"></fa-icon>
            }
            @if (checkSortParams(item, false)) {
              <fa-icon slot="end" [icon]="getSortIconByType(item.type, false)"></fa-icon>
            }
          </ion-button>
        </div>

        @if (lastItem && hasHiddenHeaderItems) {
          <lib-auto-layout class="toolbar-item" [gap]="[1]">
            @if (isColumnIndicatorExpanded) {
              <button class="primary" (click)="shiftColumnsToRight()">
                <fa-icon [icon]="icons.chevronLeft"></fa-icon>
              </button>
              <button class="primary" (click)="shiftColumnsToLeft()">
                <fa-icon [icon]="icons.chevronRight"></fa-icon>
              </button>
            }
            <button class="tertiary">
              <span>+ {{ hiddenHeaderItemCount }}</span>
              <fa-icon [icon]="icons.columns"></fa-icon>
            </button>
          </lib-auto-layout>
        }
      </div>
    }
  </div>
</lib-auto-layout>
