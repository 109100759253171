import { DestroyRef, EventEmitter, inject, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ValidatorFn } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { APIError } from '../data/errors.data';
import { FormMapper } from '../mappers/form.mapper';
import { generateValidators } from '../mappers/validator.generator';
import { SDAPIService } from '../services/sdapi.service';
import { DynamicButton } from './dynamic-button.model';
import { TableList } from './dynamic-table.model';
import { MultiChosenData } from './grid.model';
import { Invoker } from './invoker-body.model';
import { TranslationOptions } from './modal-action.model';
import { RequiredActionsDetails } from './required-actions.model';
import {
  AttributeFieldType,
  AttributeItem,
  AttributeValue,
  CheckerType,
  FormatOptionIdentifier,
  FormatOptionsMap,
  RequestParameters,
} from './sdapi-form-object.model';
import { AttributeNameIdentifier, SystemAttributeName } from './shared.model';

export interface DynamicFormConfigurationData {
  type: DynamicFormType;
  isSavable?: boolean;
  isInteractive?: boolean;
  dynamicForm?: DynamicForm;
  invoker?: Invoker;
  requiredActionsDetails?: RequiredActionsDetails;
  activityURL?: string;
  translationOptions?: TranslationOptions;
}

export class DynamicFormConfiguration implements DynamicFormConfigurationData {
  isSavable: boolean = true;
  isInteractive: boolean = true;
  type: DynamicFormType;
  dynamicForm?: DynamicForm;
  invoker?: Invoker;
  requiredActionsDetails?: RequiredActionsDetails;
  activityURL?: string;
  translationOptions?: TranslationOptions;

  constructor(dynamicFormConfiguration: DynamicFormConfigurationData) {
    Object.assign(this, dynamicFormConfiguration);
  }
}

export enum DynamicFormType {
  ACTIVITY_CHANGE,
  CHAT,
  DIRECT_SAVE,
  DOCUMENT_UPLOAD,
  MULTICHOSEN,
  STATIC_PATIENT_CREATE,
  TASK_UPLOAD,
  TASK_WITH_ACTIONS,
  TASK,
  TREATMENT_CREATE,
  USER_DEACTIVATION,
  VIEW,
}

export enum DynamicFormLayoutType {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
}

export interface FormData {
  activityURL?: string;
  title?: string;
  body?: DynamicDataField[];
  invoker?: Invoker[];
  fileTypeData?: FileTypeData[];
  labelData?: LabelData;
  isPublicAccessed?: boolean;
  multiChosen?: any;
}

@UntilDestroy()
export class DynamicForm implements FormData {
  @Output() fieldVisibilityChanged: EventEmitter<any> = new EventEmitter();
  @Output() formProcessed: EventEmitter<any> = new EventEmitter();

  private _body: DynamicDataField[] = [];
  private conditionalDependencyMap: Map<string, FieldTrigger[]>;
  private destroyReference: DestroyRef = inject(DestroyRef);
  private sdapiService: SDAPIService = inject(SDAPIService);

  private itemLookupMap?: Map<string, DynamicDataField>;
  private _prefillIdentifiers?: FormIdentifier[];

  actionButtons: DynamicButton[];
  isInteractive: boolean;

  activityURL?: string;
  fileTypeData?: FileTypeData[];
  header?: DynamicDataField[];
  invoker?: Invoker[];
  isPublicAccessed?: boolean = false;
  labelData?: LabelData;
  multiChosen?: MultiChosenData;
  title?: string;

  constructor(dynamicFormData?: DynamicFormData) {
    if (dynamicFormData) {
      this.activityURL = dynamicFormData.activityURL;
      this.title = dynamicFormData.title;
      this.invoker = dynamicFormData.invoker;
      this.fileTypeData = dynamicFormData.fileTypeData;
      this.labelData = dynamicFormData.labelData;
      this.isPublicAccessed = dynamicFormData.isPublicAccessed;
      this.multiChosen = dynamicFormData.multiChosen;
      this.isInteractive = dynamicFormData.isInteractive;
      this._body = dynamicFormData.body;
      this.header = dynamicFormData.header;
    }
    this.initializeForm();
  }

  public get body(): DynamicDataField[] {
    return this._body;
  }

  public set body(body: DynamicDataField[]) {
    this._body = body;
  }

  private initializeForm(): void {
    this.setInitialValueState(this.getAllFieldItems());
    this.resolveUploadLimitersForFileFields();
    this.addValidatorsToFields();
    this.resolveInitialFormItemVisibility(this.body);
    if (this.isInteractive === true) {
      this.setPrefillIdentifiers();
      this.initializeBodyValueSubscription(this._body);
    }
  }

  private addValidatorsToFields() {
    this.getAllFieldItems().forEach((field) => {
      if (field) {
        field.validators = generateValidators(field);
      }
    });
  }

  private setInitialValueState(dataFieldArray: DynamicDataField[]) {
    dataFieldArray.forEach((dataField) => {
      if (dataField) {
        this.setSingleOption(dataField);
      }
    });
  }

  private initializeBodyValueSubscription(dataFieldArray: DynamicDataField[]): void {
    const interactiveFields: DynamicDataField[] = this.getInteractiveFields(dataFieldArray);

    this.subscribeToChangesOfFields(interactiveFields);
    this.triggerProcessingOfFields(interactiveFields);
  }

  private setPrefillIdentifiers() {
    this._prefillIdentifiers = this.resolvePrefillIdentifiers();
  }

  private subscribeToChangesOfFields(dataFields: DynamicDataField[]) {
    dataFields.map((field: DynamicDataField) =>
      field
        .getProcessDataObserver()
        .pipe(takeUntilDestroyed(this.destroyReference))
        .subscribe((processData: ProcessData) => this.processForm(processData)),
    );
  }

  private triggerProcessingOfFields(dataFields: DynamicDataField[]): void {
    dataFields.map((field: DynamicDataField) => field.triggerProcessing());
  }

  private getInteractiveFields(dataFieldArray: DynamicDataField[]): DynamicDataField[] {
    return dataFieldArray
      .map((dataField) => {
        if (dataField.fieldGroup) {
          return this.getInteractiveFields(dataField.fieldGroup);
        } else if (this.isInteractiveField(dataField)) {
          return dataField;
        } else {
          return [];
        }
      })
      .flat();
  }

  private getFieldsByTabAndFieldKey(fieldKeys: string[], tab?: string): DynamicDataField[] {
    const tabNormalized = tab?.toLocaleLowerCase();
    const fieldKeysNormalized = fieldKeys.map((fk) => fk.toLocaleLowerCase());
    return this.getAllFieldItems()
      .filter(
        (field) =>
          fieldKeysNormalized.find((fk) =>
            field.identifier.originalValue.toLocaleLowerCase().includes(fk),
          ) &&
          (!tabNormalized ||
            field.identifier.originalValue.toLocaleLowerCase().includes(tabNormalized)),
      )
      .filter((field) => !Number.isNaN(Number.parseFloat(field.value.value)));
  }

  private calculateFormula(
    foundFields: DynamicDataField[],
    formulaField: DynamicDataField,
  ): number | null {
    let result = null;
    for (const currentDataField of foundFields) {
      if (result === null) {
        result = parseFloat(currentDataField.value.value);
      } else {
        switch (formulaField.value.formula.type) {
          case FormulaType.SUM:
            result = result + parseFloat(currentDataField.value.value);
            break;
          case FormulaType.PRODUCT:
            result = result * parseFloat(currentDataField.value.value);
            break;
          case FormulaType.DIFFERENCE:
            result = result - parseFloat(currentDataField.value.value);
            break;
        }
      }
    }
    return result;
  }

  private calculateFormulaAndUpdateFields(processData: ProcessData) {
    if (processData.dependentFormulaFields.length > 0) {
      for (const formulaField of processData.dependentFormulaFields) {
        const foundFields = this.getFieldsByTabAndFieldKey(
          formulaField.value.formula.fields,
          formulaField.value.formula.tab,
        );
        if (foundFields.length == formulaField.value.formula.fields?.length) {
          const result = this.calculateFormula(foundFields, formulaField);
          if (result !== null) {
            formulaField.value.value = `${result}`;
          }
        }
      }
    }
  }

  private updateObjNameFromFileName() {
    const allFieldItems = this.getAllFieldItems();
    const fileField: DynamicDataField = allFieldItems.find((item) => item.type === DataType.file);
    const objNameField: DynamicDataField = allFieldItems.find(
      (item) =>
        item.type === DataType.text &&
        item.identifier.normalizedValue === 'sys_object.obj_name' &&
        item.value.originalValue === '',
    );
    if (fileField && objNameField) {
      const file = fileField.file;
      const fileName = file?.name;
      if (fileName) {
        const normalizedFileName = fileName.substring(0, fileName.lastIndexOf('.')).normalize();
        objNameField.value.value = normalizedFileName;
      }
    }
  }

  public async processForm(processData: ProcessData): Promise<void> {
    if (processData.isFileUpload) {
      this.updateObjNameFromFileName();
    }
    if (processData.dependentFormulaFields) {
      this.calculateFormulaAndUpdateFields(processData);
    }
    if (processData.dependence?.isPrimary) {
      await this.triggerSecondaryFormFields(processData.dependence);
    }
    if (processData.conditionalDependencies) {
      this.updateFieldVisibilityOnFieldValueChange(processData.conditionalDependencies);
      this.fieldVisibilityChanged.emit();
    }
    if (processData.fileTypeData) {
      this.setObjectClassFieldWithFileObjectId();
    }
    this.formProcessed.emit();
  }

  private setFirstFittingObjectClassForExtension(objectClassField: DynamicDataField, file: File) {
    objectClassField.type = DataType.hidden;
    if (file) {
      const extension = '.' + file.name.split('.').pop();
      const filetypeIdentifier = this.fileTypeData.find(
        (data) => data.extension === extension.toLowerCase(),
      )?.identifier;
      const checkerField: DynamicDataField = this.getAllFieldItems().find(
        (item) => item.checker === CheckerType.objectClass,
      );
      checkerField.value.value = filetypeIdentifier;
    }
  }

  private setObjectClassSelectOptions(objectClassField: DynamicDataField, file: File) {
    objectClassField.type = DataType.select;
    objectClassField.value.options = this.fileTypeData
      .filter((fileTypeData) =>
        fileTypeData.extension.includes(file.name.split('.').pop().toLowerCase()),
      )
      .map((fileTypeData) => ({
        identifier: fileTypeData.identifier,
        value: fileTypeData.name,
      }));
  }

  private hasMoreThanOneOverlappingExtensionsWithFile(fileName: string): boolean {
    if (!this.fileTypeData) {
      return false;
    }
    const overlappingFileTypes = this.fileTypeData.filter((fileTypeData) =>
      fileTypeData.extension.includes(fileName.split('.').pop().toLowerCase()),
    );
    return overlappingFileTypes.length > 1;
  }

  private setObjectClassFieldWithFileObjectId() {
    const fileField: DynamicDataField = this.getAllFieldItems().find(
      (item) => item.type === DataType.file,
    );

    const objectClassField: DynamicDataField = this.getAllFieldItems().find(
      (item) => item.checker === CheckerType.objectClass,
    );

    if (fileField?.file && objectClassField) {
      if (
        fileField.file?.name &&
        this.hasMoreThanOneOverlappingExtensionsWithFile(fileField.file.name)
      ) {
        this.setObjectClassSelectOptions(objectClassField, fileField.file);
      } else {
        this.setFirstFittingObjectClassForExtension(objectClassField, fileField.file);
      }
    }
  }

  private isInteractiveField(dataField: DynamicDataField): boolean {
    const isPrimary: boolean = dataField.dependence?.isPrimary;
    const isConditional = !!dataField.conditionalDependencies;
    const isFileField: boolean = dataField.type === DataType.file;
    const isFormulaField: boolean = !!dataField.dependentFormulaFields?.length;
    return isPrimary || isConditional || isFileField || isFormulaField;
  }

  private async triggerSecondaryFormFields(dependence: DependenceDefinition): Promise<void> {
    try {
      if (this.checkPrimariesFilled(dependence)) {
        await this.injectOptionUpdatesToSecondaryFields(dependence);
      } else {
        await this.resetOptionUpdatesSecondaryFields(dependence);
      }
    } catch (error) {
      throw new APIError(
        `Primary Secondary check and process failed. Invalid Data for: ${dependence.primaryIdentifier}, ${dependence.dependencyGroupIndices.primary}`,
        error,
      );
    }
  }

  private async injectOptionUpdatesToSecondaryFields(dependence: DependenceDefinition) {
    const dependentSecondaries: DynamicDataField[] = this.getAllDependentSecondaries(dependence);
    for (const secondary of dependentSecondaries) {
      secondary.isProcessed = true;
      if (secondary.type === DataType.autocomplete) {
        secondary.value.autocompleteOptions.optionsUpdateParameter =
          this.getOptionsUpdateParameter(secondary);
        const updates = await firstValueFrom(
          this.sdapiService.getOptionsForAutoCompleteField(secondary.value.autocompleteOptions),
        );
        secondary.value.options = FormMapper.mapOptionsUpdate(updates);
        secondary.isProcessed = false;
      } else {
        const updates = await firstValueFrom(
          this.sdapiService.getOptionsUpdate(
            secondary.dependence.optionsUpdateReference,
            this.getOptionsUpdateParameter(secondary),
            this.activityURL,
            this.isPublicAccessed,
          ),
        );
        secondary.value.options = FormMapper.mapOptionsUpdate(updates);
        secondary.isProcessed = false;
      }
      this.setSingleOptionDefaultValueOrResetValue(secondary);
    }
  }

  private resetOptionUpdatesSecondaryFields(dependence: DependenceDefinition) {
    for (const item of this.getAllDependentSecondaries(dependence)) {
      item.isProcessed = true;
      item.value.options = [];
      item.resetValue();
      item.resetOptions();
      item.isProcessed = false;
    }
  }

  private setSingleOptionDefaultValueOrResetValue(item: DynamicDataField): void {
    const itemHasOnlyOneOption: boolean = item.value.options?.length === 1;
    const itemHasDefaultValue = !!item.value.defaultValue;
    if (itemHasOnlyOneOption) {
      this.setFirstOption(item);
    } else if (itemHasDefaultValue) {
      const defaultValueIsStillInOptionsAfterOptionsUpdate: boolean = !!item.value.options?.find(
        (option) => option.identifier === item.value.defaultValue,
      );
      if (defaultValueIsStillInOptionsAfterOptionsUpdate) {
        item.value.value = item.value.defaultValue;
      } else {
        item.value.value = '';
      }
    } else {
      item.resetValue();
    }
  }

  private setSingleOption(item: DynamicDataField): void {
    if (item.value.options?.length === 1) {
      this.setFirstOption(item);
    }
  }

  private setFirstOption(item: DynamicDataField): void {
    try {
      item.value.value = item.value.options[0].identifier;
    } catch (error) {
      throw new APIError(`Option Parameter '${item.value.options[0]}' is invalid.`, error);
    }
  }

  private checkPrimariesFilled(dependence: DependenceDefinition): boolean {
    const primariesOfSameIndex: DynamicDataField[] = this.getAllPrimaryFields().filter(
      (field: DynamicDataField) =>
        field.dependence?.isPrimary &&
        field.dependence?.dependencyGroupIndices.primary ===
          dependence.dependencyGroupIndices.primary,
    );
    return primariesOfSameIndex.every((primary: DynamicDataField) => primary.isFilled);
  }

  private getAllPrimaryFields(): DynamicDataField[] {
    return this.getAllFieldItems().filter((field: DynamicDataField) => field.dependence?.isPrimary);
  }

  private getAllDependentSecondaries(dependence: DependenceDefinition): DynamicDataField[] {
    return this.getAllFieldItems().filter((field: DynamicDataField) => {
      if (!field.dependence?.isSecondary) {
        return false;
      }
      return this.areDependencyGroupIndicesMatching(dependence, field.dependence);
    });
  }

  public getAllFieldItems(): DynamicDataField[] {
    return this.body.flatMap((group: DynamicDataField) => group.fieldGroup);
  }

  private getOptionsUpdateParameter(secondary: DynamicDataField): RequestParameters {
    return this.getAllPrimaryFields()
      .filter((primary: DynamicDataField) =>
        this.areDependencyGroupIndicesMatching(primary.dependence, secondary.dependence),
      )
      .reduce((parameters, field) => {
        const parameterName: string = field.dependence.primaryIdentifier || 'primary_key';
        parameters[parameterName] = this.mapOptionsUpdateParameter(field);
        return parameters;
      }, {} as RequestParameters);
  }

  private mapOptionsUpdateParameter(field: DynamicDataField): AttributeItem {
    return {
      t: field.value.attributeFieldType,
      internalValue: field.value.value,
    };
  }

  private areDependencyGroupIndicesMatching(
    primaryDependence: DependenceDefinition,
    secondaryDependence: DependenceDefinition,
  ): boolean {
    const primaryIndices: string[] = primaryDependence.dependencyGroupIndices.primary;
    const secondaryIndices: string[] = secondaryDependence.dependencyGroupIndices.secondary;
    return secondaryIndices.every((secondaryIndex: string) =>
      primaryIndices.includes(secondaryIndex),
    );
  }

  private resolveInitialFormItemVisibility(groups: DynamicDataField[]): void {
    this.ensureAllFormItemsAreVisible(groups);
    this.conditionalDependencyMap = this.createConditionalDependencyMap(groups);
    this.itemLookupMap = this.createItemsLookupMapByIdentifier(groups);
    this.setInitialVisibilityBasedOnDependencies();
  }

  private ensureAllFormItemsAreVisible(groups: DynamicDataField[]): void {
    groups.forEach((group: DynamicDataField) => {
      group.visible = true;
      group.fieldGroup?.forEach((item: DynamicDataField) => (item.visible = true));
    });
  }

  private createConditionalDependencyMap(groups: DynamicDataField[]): Map<string, FieldTrigger[]> {
    const dependencyMap = new Map<string, FieldTrigger[]>();
    groups
      .flatMap((group: DynamicDataField) => group.fieldGroup)
      .forEach((field: DynamicDataField) => {
        field?.conditionalDependencies?.forEach((dependency: ConditionalDependency) => {
          const fieldTrigger = new FieldTrigger(
            field.identifier?.normalizedValue,
            dependency.fieldTriggerValue?.normalizedValue,
          );
          dependency.dependantIdentifiers.forEach((identifier: AttributeNameIdentifier) => {
            this.addConditionDependencyMapEntry(dependencyMap, identifier, fieldTrigger);
          });
        });
      });
    return dependencyMap;
  }

  private addConditionDependencyMapEntry(
    dependencyMap: Map<string, FieldTrigger[]>,
    dependantIdentifier: AttributeNameIdentifier,
    fieldTrigger: FieldTrigger,
  ): void {
    if (!dependencyMap[dependantIdentifier.normalizedValue]) {
      dependencyMap[dependantIdentifier.normalizedValue] = [];
    }
    dependencyMap[dependantIdentifier.normalizedValue].push(fieldTrigger);
  }

  private createItemsLookupMapByIdentifier(
    groups: DynamicDataField[],
  ): Map<string, DynamicDataField> {
    const itemsMap = new Map<string, DynamicDataField>();
    groups?.forEach((group: DynamicDataField) => {
      itemsMap.set(group.identifier?.normalizedValue, group);
      group.fieldGroup?.forEach((item: DynamicDataField) =>
        itemsMap.set(item.identifier?.normalizedValue, item),
      );
    });
    return itemsMap;
  }

  private setInitialVisibilityBasedOnDependencies(): void {
    Object.keys(this.conditionalDependencyMap).forEach((conditionalFieldIdentifier: string) => {
      const conditionalVisibilityItem: DynamicDataField = this.getDataFieldByIdentifier(
        conditionalFieldIdentifier,
      );
      const fieldTriggers: FieldTrigger[] =
        this.conditionalDependencyMap[conditionalFieldIdentifier];
      if (conditionalVisibilityItem) {
        this.updateFieldVisibility(fieldTriggers, conditionalVisibilityItem);
      }
    });
  }

  public getDataFieldByIdentifier(identifier: string): DynamicDataField | undefined {
    const field: DynamicDataField = this.itemLookupMap.get(identifier.toLowerCase());
    if (field === undefined) {
      console.warn(`DataField with identifier '${identifier}' not found.`);
    }
    return field;
  }

  private updateFieldVisibilityOnFieldValueChange(dependencies: ConditionalDependency[]): void {
    const dependantIdentifiers: string[] = this.collectAllDependantIdentifiers(
      dependencies,
      this.itemLookupMap,
    );
    const filteredMap: Map<string, FieldTrigger[]> =
      this.filterConditionalDependencyMap(dependantIdentifiers);
    this.updateVisibilityBasedOnDependencies(filteredMap);
  }

  private collectAllDependantIdentifiers(
    dependencies: ConditionalDependency[],
    itemsLookupMap: Map<string, DynamicDataField>,
  ): string[] {
    const collectedIdentifiers: string[] = dependencies
      .flatMap((dependency: ConditionalDependency) => dependency.dependantIdentifiers)
      .flatMap((identifier: AttributeNameIdentifier) => identifier.normalizedValue);
    collectedIdentifiers.forEach((identifier: string) => {
      const item: DynamicDataField = itemsLookupMap.get(identifier);
      item?.fieldGroup?.forEach((i: DynamicDataField) => {
        collectedIdentifiers.push(i.identifier.normalizedValue);
      });
    });
    return collectedIdentifiers;
  }

  private filterConditionalDependencyMap(identifiers: string[]): Map<string, FieldTrigger[]> {
    const filteredMap = new Map<string, FieldTrigger[]>();
    identifiers.forEach((identifier: string) => {
      if (this.conditionalDependencyMap[identifier]) {
        filteredMap.set(identifier, this.conditionalDependencyMap[identifier]);
      }
    });
    return filteredMap;
  }

  private updateVisibilityBasedOnDependencies(dependencyMap: Map<string, FieldTrigger[]>) {
    dependencyMap.forEach((_fieldTriggers, identifier) => {
      const conditionalVisibilityItem: DynamicDataField = this.getDataFieldByIdentifier(identifier);
      const fieldTriggers: FieldTrigger[] = dependencyMap.get(identifier);
      if (conditionalVisibilityItem) {
        this.updateFieldVisibility(fieldTriggers, conditionalVisibilityItem);
      }
    });
  }

  private updateFieldVisibility(
    fieldTriggers: FieldTrigger[],
    conditionalVisibilityItem: DynamicDataField,
  ): void {
    const isVisible: boolean = fieldTriggers.some((trigger: FieldTrigger) => {
      const triggerItem: DynamicDataField = this.getDataFieldByIdentifier(trigger.identifier);
      if (!triggerItem) {
        return false;
      }
      return new FieldTriggerValue(trigger.value).isEqualTo(triggerItem.value.value);
    });
    conditionalVisibilityItem.visible = isVisible;
  }

  private resolveUploadLimitersForFileFields() {
    if (this.fileTypeData) {
      this.getAllFieldItems().forEach((item) => {
        if (item.type === DataType.file) {
          this.insertFileTypeData(item);
        }
      });
    }
  }

  private insertFileTypeData(item: DynamicDataField) {
    item.fileTypeData = this.fileTypeData;
  }

  private resolvePrefillIdentifiers(): FormIdentifier[] {
    return this._body
      .flatMap((field: DynamicDataField) => field.fieldGroup.flat())
      .map(({ prefillIdentifier }) => prefillIdentifier)
      .filter((prefillIdentifier: SystemAttributeName) =>
        Object.values(FormIdentifier).some((formIdentifier: FormIdentifier) =>
          prefillIdentifier?.isEqualTo(new SystemAttributeName(formIdentifier)),
        ),
      )
      .map((prefillIdentifier: SystemAttributeName) => prefillIdentifier.originalValue);
  }

  public hasPrefillIdentifier(prefillIdentifier: FormIdentifier): boolean {
    return this.prefillIdentifiers.includes(prefillIdentifier);
  }

  public get prefillIdentifiers(): FormIdentifier[] {
    return this._prefillIdentifiers;
  }
}

export type LabelData = {
  orderNumber: string;
  contactId: string;
  patientsGivenName: string;
  patientsFamilyName: string;
  patientsBirthDate: string;
  sampleNames: string[];
};

export interface DynamicFormData extends FormData {
  activityURL: string;
  title: string;
  body: DynamicDataField[];
  invoker: Invoker[];
  isInteractive: boolean;
  header?: DynamicDataField[];
}

@UntilDestroy()
export class DynamicDataField {
  private _value?: DataObject;

  identifier: AttributeNameIdentifier;
  isProcessed = false;
  name: string;
  processData: BehaviorSubject<ProcessData> = new BehaviorSubject<ProcessData>({
    dependence: this.dependence,
    value: this.value,
    conditionalDependencies: this.conditionalDependencies,
    fileTypeData: this.fileTypeData,
    dependentFormulaFields: this.dependentFormulaFields,
  });
  type: DataType;

  dependentFormulaFields?: DynamicDataField[];
  dependence?: DependenceDefinition;
  fieldGroup?: DynamicDataField[];
  prefillIdentifier?: SystemAttributeName;
  readOnly?: boolean;
  required?: boolean;
  visible?: boolean;
  validators?: ValidatorFn[];
  conditionalDependencies?: ConditionalDependency[] | null;
  file?: File;
  fileTypeData?: FileTypeData[];
  checker?: CheckerType;
  formatOptions?: FormatOptions;

  colNum?: number;
  colSpan?: number;
  hasTime?: boolean;
  rowNum?: number;
  rowSpan?: number;
  sequence?: number;

  public get value(): DataObject {
    return this._value;
  }

  public set value(value: DataObject) {
    this._value = value;
    this.initializeProcessing();
  }

  public initializeProcessing(): void {
    this.value.getValueObserver().subscribe(() => this.processData.next(this.getProcessData()));
  }

  public triggerProcessing(): void {
    this.value.triggerValueBehavior();
  }

  public get hasValue(): boolean {
    return !!this.value?.value;
  }

  public get isModified(): boolean {
    return this.value?.value !== this.value?.originalValue;
  }

  public get isFilled(): boolean {
    return this.hasValue || this.isModified;
  }

  public get isDisplayable(): boolean {
    return this.visible && this.type !== DataType.hidden && this.type !== DataType.unknown;
  }

  public get isEditable(): boolean {
    return !this.readOnly && this.isDisplayable;
  }

  public getProcessDataObserver(): Observable<ProcessData> {
    return this.processData.asObservable().pipe(untilDestroyed(this));
  }

  public resetValue() {
    if (this.value.options) {
      if (this.value.options.find((option) => option.identifier === this.value.originalValue)) {
        this.value.value = this.value.originalValue;
      } else {
        this.value.value = '';
      }
    } else {
      this.value.value = this.value.originalValue;
    }
  }

  public resetOptions() {
    if (this.value.originalOptions) {
      this.value.options = this.value.originalOptions;
    }
  }

  getProcessData(): ProcessData {
    return {
      dependence: this.dependence,
      value: this.value,
      conditionalDependencies: this.conditionalDependencies,
      fileTypeData: this.fileTypeData,
      dependentFormulaFields: this.dependentFormulaFields,
      isFileUpload: this.type == DataType.file,
    };
  }
}

export type ProcessData = {
  dependence?: DependenceDefinition;
  value: DataObject;
  conditionalDependencies: ConditionalDependency[];
  fileTypeData: FileTypeData[];
  dependentFormulaFields?: DynamicDataField[];
  isFileUpload?: boolean;
};

export class ConditionalDependency {
  dependantIdentifiers: AttributeNameIdentifier[];
  fieldTriggerValue: FieldTriggerValue;
}

export class FieldTrigger {
  readonly identifier: string;
  readonly value: string;

  constructor(identifier: string, value: string) {
    this.identifier = identifier;
    this.value = value;
  }
}

export class FieldTriggerValue {
  readonly originalValue: string;
  readonly normalizedValue: string;

  constructor(value: string) {
    this.originalValue = value;
    this.normalizedValue = this.normalizeValue(value);
  }

  public isEqualTo(comparisonValue: string): boolean {
    return this.normalizedValue === this.normalizeValue(comparisonValue);
  }

  private normalizeValue(value: string): string {
    let normalizedValue: string = value;
    normalizedValue = normalizedValue?.toLocaleLowerCase();
    normalizedValue = normalizedValue.replace(/ß/g, 'ss');
    return normalizedValue;
  }
}

export enum DataType {
  buttonStepAndClose = 'ButtonStepAndClose',
  checkbox = 'checkbox',
  date = 'date',
  file = 'file',
  grid = 'grid',
  grouping = 'grouping',
  hidden = 'hidden',
  // eslint-disable-next-line id-blacklist
  number = 'number',
  popup = 'PopupInvoker',
  radio = 'radio',
  select = 'select',
  autocomplete = 'autocomplete',
  text = 'text',
  textArea = 'textArea',
  title = 'title',
  richtext = 'richtext',
  unknown = 'unknown',
}

@UntilDestroy()
export class DataObject {
  private _value: BehaviorSubject<string | null>;
  readonly originalValue: string;

  options?: SelectionObject[];
  readonly originalOptions?: SelectionObject[];

  attributeFieldType: AttributeFieldType;
  defaultValue?: string;
  table?: TableList;
  invoker?: Invoker;
  limiters?: Limiters;
  formula?: Formula;
  autocompleteOptions?: AutocompleteOptions;
  hasDynamicOptions?: boolean;

  style?: string | null;
  title?: string;

  constructor(
    attributeFieldType: AttributeFieldType,
    value: string | null,
    optionalAttributes: any = {},
  ) {
    this.setOptionalAttributes(optionalAttributes);
    this.attributeFieldType = attributeFieldType;
    this.originalValue = value;
    this._value = new BehaviorSubject(value);
  }

  private setOptionalAttributes(attributes: any) {
    Object.entries(attributes).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  public getValueObserver(): Observable<string> {
    return this._value.asObservable().pipe(untilDestroyed(this));
  }

  public get value(): string | null {
    return this._value.getValue();
  }

  public set value(value: string | null) {
    this._value.next(value);
  }

  public triggerValueBehavior(): void {
    this._value.next(this.value);
  }
}

export class DependenceDefinition {
  dependencyGroupIndices: DependencyGroupIndices;
  primaryIdentifier: string;
  /** Options Update Reference (stmtId also popupId)*/
  optionsUpdateReference: number;
  constructor(
    primaryIdentifier: string,
    optionsUpdateReference: number,
    primaryIndices: string[],
    secondaryIndices: string[],
  ) {
    this.primaryIdentifier = primaryIdentifier;
    this.optionsUpdateReference = optionsUpdateReference;
    this.dependencyGroupIndices = {
      primary: primaryIndices,
      secondary: secondaryIndices,
    };
  }

  public get isPrimary(): boolean {
    return this.dependencyGroupIndices.primary.length > 0;
  }

  public get isSecondary(): boolean {
    return this.dependencyGroupIndices.secondary.length > 0;
  }
}

export interface DependencyGroupIndices {
  primary: string[];
  secondary: string[];
}

export interface SelectionObject {
  identifier: string;
  value: string;
}

export enum FormulaType {
  SUM = 'SUM',
  DIFFERENCE = 'DIFFERENCE',
  PRODUCT = 'PRODUCT',
}

export type AutocompleteOptions = {
  originalDisplayValue?: string;
  minprefixlength?: number;
  maxoptions?: number;
  popupMandatory?: boolean;
  popupObjId?: number;
  activityUrl?: string;
  stmtId?: number;
  isLoadingOptions?: boolean;
  optionsUpdateParameter?: any;
};

export type Formula = {
  type: string;
  fields: string[];
  tab: any;
};

export type Limiters = {
  min?: string;
  max?: string;
  rows?: string;
  extensions?: string[];
  minrel?: string;
  maxrel?: string;
};

export enum DeviationDirection {
  decremental = 'decremental',
  incremental = 'incremental',
}

export enum FieldState {
  EMPTY,
  FILLED,
}

export type ProgressBar = {
  id: number;
  progressValue: number;
  onProgressText: string;
  onSuccessText: string;
  visibility: boolean;
};

export enum FormIdentifier {
  ADDRESS2 = 'sys_patient.address2',
  BIRTH_COUNTRY = 'sys_patient.birth_country',
  BIRTH_ORDER = 'sys_patient.birth_order',
  BIRTH_PLACE = 'sys_patient.birth_place',
  CANCELATION_DATE = 'sys_patient.cancelation_date',
  CITIZENSHIP = 'sys_patient.citizenship',
  CONTACT_FAMILY_NAME = 'sys_contact.family_name',
  COUNTRY = 'sys_patient.country',
  DEATH_DATE = 'sys_patient.death_date',
  DEATH_INDICATOR = 'sys_patient.death_indicator',
  EMAIL1 = 'sys_patient.email1',
  EMAIL2 = 'sys_patient.email2',
  EMAIL3 = 'sys_patient.email3',
  FAX = 'sys_patient.fax',
  GENERIC_LABEL_PRINT = 'labelprint.generic',
  LANGUAGE = 'sys_patient.language',
  MAIDEN_NAME = 'sys_patient.maiden_name',
  MARITAL_STATUS = 'sys_patient.marital_status',
  MULTIPLE_BIRTH_INDICATOR = 'sys_patient.multiple_birth_indicator',
  NATIONALITY = 'sys_patient.nationality',
  NEWBORN_BIRTH_TIME = 'sys_patient.newborn_birth_time',
  NEWBORN_MOTHER_FID = 'sys_patient.newborn_mother_fid',
  OCCUPATION = 'sys_patient.occupation',
  ORDER_LABEL_SAMPLE = 'sys_order.sample',
  ORDER_NUMBER = 'sys_order.order_number',
  PATIENT_ID = 'sys_patient.patient_id',
  PATIENTS_ADDRESS_DOMICILE = 'sys_patient.patients_address_domicile',
  PATIENTS_ADDRESS_STREET = 'sys_patient.patients_address_street',
  PATIENTS_ADDRESS_ZIP_CODE = 'sys_patient.patients_address_zip_code',
  PATIENTS_BIRTH_DATE = 'sys_patient.patients_birth_date',
  PATIENTS_FAMILY_NAME = 'sys_patient.patients_family_name',
  PATIENTS_GIVEN_NAME = 'sys_patient.patients_given_name',
  PATIENTS_SEX = 'sys_patient.patients_sex',
  PATIENTS_STATE = 'sys_patient.patients_state',
  PHONE1 = 'sys_patient.phone1',
  PHONE2 = 'sys_patient.phone2',
  PHONE3 = 'sys_patient.phone3',
  PID = 'sys_patient.pid',
  PREFIX = 'sys_patient.prefix',
  RELIGION = 'sys_patient.religion',
  SECOND_AND_FURTHER_GIVEN_NAME = 'sys_patient.second_and_further_given_name',
  SOCIAL_SECURITY_NUMBER = 'sys_patient.social_security_number',
  SOURCE_SYSTEM = 'sys_patient.source_system',
  STATE = 'sys_patient.state',
  SUFFIX = 'sys_patient.suffix',
  TITLE = 'sys_patient.title',
}

export const labelPrintFormIdentifier: FormIdentifier[] = [
  FormIdentifier.CONTACT_FAMILY_NAME,
  FormIdentifier.ORDER_LABEL_SAMPLE,
  FormIdentifier.ORDER_NUMBER,
  FormIdentifier.PATIENTS_BIRTH_DATE,
  FormIdentifier.PATIENTS_FAMILY_NAME,
  FormIdentifier.PATIENTS_GIVEN_NAME,
];

export const patientRelatedPrefillFormIdentifier: FormIdentifier[] = [
  FormIdentifier.ADDRESS2,
  FormIdentifier.BIRTH_COUNTRY,
  FormIdentifier.BIRTH_ORDER,
  FormIdentifier.BIRTH_PLACE,
  FormIdentifier.CANCELATION_DATE,
  FormIdentifier.CITIZENSHIP,
  FormIdentifier.COUNTRY,
  FormIdentifier.DEATH_DATE,
  FormIdentifier.DEATH_INDICATOR,
  FormIdentifier.EMAIL1,
  FormIdentifier.EMAIL2,
  FormIdentifier.EMAIL3,
  FormIdentifier.FAX,
  FormIdentifier.LANGUAGE,
  FormIdentifier.MAIDEN_NAME,
  FormIdentifier.MARITAL_STATUS,
  FormIdentifier.MULTIPLE_BIRTH_INDICATOR,
  FormIdentifier.NATIONALITY,
  FormIdentifier.NEWBORN_BIRTH_TIME,
  FormIdentifier.NEWBORN_MOTHER_FID,
  FormIdentifier.OCCUPATION,
  FormIdentifier.PATIENT_ID,
  FormIdentifier.PATIENTS_ADDRESS_DOMICILE,
  FormIdentifier.PATIENTS_ADDRESS_STREET,
  FormIdentifier.PATIENTS_ADDRESS_ZIP_CODE,
  FormIdentifier.PATIENTS_BIRTH_DATE,
  FormIdentifier.PATIENTS_FAMILY_NAME,
  FormIdentifier.PATIENTS_GIVEN_NAME,
  FormIdentifier.PATIENTS_SEX,
  FormIdentifier.PATIENTS_STATE,
  FormIdentifier.PHONE1,
  FormIdentifier.PHONE2,
  FormIdentifier.PHONE3,
  FormIdentifier.PID,
  FormIdentifier.PREFIX,
  FormIdentifier.RELIGION,
  FormIdentifier.SECOND_AND_FURTHER_GIVEN_NAME,
  FormIdentifier.SOCIAL_SECURITY_NUMBER,
  FormIdentifier.SOURCE_SYSTEM,
  FormIdentifier.STATE,
  FormIdentifier.SUFFIX,
  FormIdentifier.TITLE,
];

export type PrefillAttributeNamesMap = Map<FormIdentifier, AttributeValue>;

export type FileTypeData = {
  readonly extension: string;
  readonly name: string;
  readonly identifier: string; // TIEobjclassID
};

export class FormatOptions {
  private format: FormatOptionIdentifier;
  constructor(format: FormatOptionIdentifier) {
    this.format = format;
  }
  get formattedString(): string | undefined {
    return FormatOptionsMap.get(this.format);
  }
  get identifier(): FormatOptionIdentifier {
    return this.format;
  }
}
