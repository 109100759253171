import { Component, Input } from '@angular/core';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { BookingQueue, Step } from 'projects/core/src/lib/models/appointment-booking.model';
import { DataType, DynamicDataField, FormatOptions } from 'projects/core/src/lib/models/form.model';
import { SharedBookingData } from '../booking-modal/booking-modal.component';

@Component({
  selector: 'booking-modal-result-row',
  template: ` @if (step) {
    <booking-modal-timeline-indicator
      class="ion-hide-md-up"
      [icon]="step.icon"
      [first]="isFirst"></booking-modal-timeline-indicator>
    <div class="form-item">
      <div class="row-item-flex">
        <div class="details">
          <div class="h3 title">
            <strong>{{ step.title }}</strong>
          </div>
          <div class="p4 result" *ngFor="let result of mapStepResults(step)">
            {{
              result.title +
                ': ' +
                (result.type === DataType.date
                  ? (result.value | localeDate: result.format?.formattedString)
                  : result.value)
            }}
          </div>
        </div>
        <ion-button
          *ngIf="editable"
          color="tertiary"
          class="icon-only"
          (click)="bookingQueue.backTo(index)">
          <fa-icon [icon]="ICONS.edit"></fa-icon>
        </ion-button>
      </div>
    </div>
  }`,
  styleUrls: ['./result-row.component.scss'],
})
export class ResultRow {
  @Input() bookingQueue: BookingQueue<SharedBookingData>;
  @Input() step: Step;
  @Input() index: number;
  @Input() isFirst: boolean;
  @Input() editable = false;

  DataType: typeof DataType = DataType;

  ICONS = { edit: faPencil };

  mapStepResults(step: Step): ResultItem[] | undefined {
    if (!step) {
      return undefined;
    }
    const results = [];
    const currentFieldGroup: DynamicDataField[] =
      step?.dynamicForm?.body[step?.resultGroupIndex || step?.groupIndex].fieldGroup;

    currentFieldGroup?.reduce((result, item) => {
      const hiddenTypes = [DataType.richtext];
      const value = this.getResultValue(item);
      if (value && item.visible && !hiddenTypes.includes(item.type)) {
        result.push({
          title: item.name,
          value,
          type: item.type,
          format: item.formatOptions,
        } as ResultItem);
      }
      return result;
    }, results);
    return results;
  }

  getResultValue(item: DynamicDataField): string {
    const optionsResult = item.value.options?.find?.(
      (option) => option.identifier === item.value.value,
    );
    if (optionsResult) {
      return optionsResult.value;
    } else {
      return item.value.value;
    }
  }
}

export class ResultItem {
  title: string;
  value: string;
  type: DataType;
  format?: FormatOptions;
}
