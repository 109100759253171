@if (showMobileNavigation) {
  <div class="stretch-container">
    <div class="alignment-space-between">
      <ion-button (click)="navigateToEditMode.emit()" color="cancel-button" class="p3">
        <fa-icon slot="start" [icon]="ICONS.BACK"></fa-icon>
        {{ 'shared.forms.overview' | translate }}
      </ion-button>
      <div class="gap"></div>
      <ion-button
        [disabled]="activeGroupNumber === 0 && !preview"
        (click)="navigateBack.emit()"
        color="primary"
        class="p3"
        id="dynamic-form-back-button">
        <fa-icon slot="start" [icon]="ICONS.BACK"></fa-icon>
        {{ 'shared.forms.back' | translate }}
      </ion-button>
      @if (!preview) {
        @if (allFormGroupsShown) {
          <ion-button
            [disabled]="disableSubmitButton"
            (click)="navigateToPreviewMode.emit()"
            color="primary"
            class="p3 preview-button">
            <fa-icon slot="end" [icon]="ICONS.FORWARD"></fa-icon>
            {{ 'shared.forms.verify' | translate }}
          </ion-button>
        } @else {
          <ion-button
            (click)="navigateForward.emit()"
            color="primary"
            class="p3"
            id="dynamic-form-forward-button">
            <fa-icon slot="end" [icon]="ICONS.FORWARD"></fa-icon>
            {{ 'shared.forms.next' | translate }}
          </ion-button>
        }
      }
    </div>
  </div>
}
<div [class.alignment-space-between]="!isMobileView">
  <ng-content></ng-content>
</div>
