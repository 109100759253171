import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { OverlayEventDetail } from '@ionic/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessagesData } from 'projects/core/src/lib/data/alert-messages.data';
import { DynamicFormPrefillMapper } from 'projects/core/src/lib/mappers/dynamic-form-prefill.mapper';
import {
  EventMessage,
  EventMessageType,
  SkeletonHeaderOffset,
  SkeletonRowSet,
  TableConfiguration,
  TableDataType,
  TableHeaderItem,
  TableList,
  TableListItem,
} from 'projects/core/src/lib/models/dynamic-table.model';
import {
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
  FormIdentifier,
  patientRelatedPrefillFormIdentifier,
  PrefillAttributeNamesMap,
} from 'projects/core/src/lib/models/form.model';
import { InvokerBody } from 'projects/core/src/lib/models/invoker-body.model';
import {
  GenericTranslationKeys,
  ModalEvent,
  ModalResult,
  TranslationOptions,
} from 'projects/core/src/lib/models/modal-action.model';
import { Patient } from 'projects/core/src/lib/models/patient.model';
import { AttributeNameIdentifier, SearchParams } from 'projects/core/src/lib/models/shared.model';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { ModalActionService } from 'projects/core/src/lib/services/modal-action.service';
import { OrdersService } from 'projects/core/src/lib/services/orders.service';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { firstValueFrom, take } from 'rxjs';

@Component({
  selector: 'lib-orders-browser',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
@UntilDestroy()
export class OrdersComponent implements OnInit {
  @Input() set ordersList(orders: TableList) {
    if (orders) {
      this.orders = orders;
      this.isDataLoading = false;
    }
  }
  @Input() patient?: Patient;

  @Output() orderListUpdate = new EventEmitter<void>();

  private generalTranslations: Object;
  private orderTranslations: Object;

  readonly headerOffset: SkeletonHeaderOffset = { mobile: 350, desktop: 350 };
  readonly expandedViewThreshold: number = 569;
  readonly icons = { card: faAddressCard };

  orders: TableList;
  isDataLoading = true;
  isRowDataLoading = false;
  skeletonRowSet: SkeletonRowSet;
  searchParams = new SearchParams();

  constructor(
    private ordersService: OrdersService,
    private patientService: PatientService,
    private popupService: PopupService,
    private loadingService: LoadingService,
    private alertsService: AlertService,
    private translateService: TranslateService,
    private modalActionService: ModalActionService,
  ) {
    this.searchParams.fields = ['columns'];
    this.skeletonRowSet = new SkeletonRowSet(this.headerOffset);
  }

  async ngOnInit(): Promise<void> {
    this.generalTranslations = await firstValueFrom(this.translateService.get('general'));
    this.orderTranslations = await firstValueFrom(this.translateService.get('shared.orders'));
  }

  get data(): TableConfiguration {
    return {
      table: this.orders,
      searchParams: this.searchParams,
      skeletonRowSet: this.skeletonRowSet,
      expandedViewThreshold: this.expandedViewThreshold,
      columnWidth: 200,
    };
  }

  resolveEventMessage(eventMessage: EventMessage): void {
    switch (eventMessage.type) {
      case EventMessageType.CLICK:
        this.viewOrderDetails(eventMessage.data, eventMessage.behaviorInvokers);
        break;
      case EventMessageType.RESORTING:
        this.setResortingLoadingValue(eventMessage.data);
        break;
    }
  }

  async createOrder(): Promise<void> {
    try {
      await this.loadingService.load(this.orderTranslations['fetch-order-types']);
      const orderID: string = await this.getOrderId();

      await this.loadingService.load(this.orderTranslations['check-order-type']);
      const orderForm: DynamicForm = await this.getOrderForm(orderID);

      const prefillTypes: FormIdentifier[] = this.ordersService.resolveOrderFormType(orderForm);

      if (this.patient && !prefillTypes.includes(FormIdentifier.PID)) {
        await this.alertsService.presentAlert(AlertMessagesData.orderTypeIsMissingPatientRelation);
        return;
      }
      await this.resolvePrefillTypes(prefillTypes, orderForm);

      await this.loadingService.load(this.orderTranslations['creating-order']);
      this.openCreateOrderModal(orderForm);
    } catch {
      this.loadingService.toast(this.orderTranslations['operation-canceled']);
    }
  }

  async resolvePrefillTypes(
    prefillTypes: FormIdentifier[],
    dynamicForm: DynamicForm,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const resolvePrefill = async () => {
        if (
          prefillTypes.find((prefillType: FormIdentifier) =>
            patientRelatedPrefillFormIdentifier.includes(prefillType),
          )
        ) {
          try {
            await this.injectPatientData(dynamicForm);
          } catch (error) {
            reject(error);
          }
        }

        resolve();
      };

      resolvePrefill();
    });
  }

  async injectPatientData(dynamicForm: DynamicForm): Promise<void> {
    return new Promise((resolve, reject) => {
      const injectData = async () => {
        let patient: PrefillAttributeNamesMap;
        if (this.patient) {
          patient = await firstValueFrom(
            this.patientService.getPatientAttributesNamesMap(this.patient.patientID),
          );
        } else {
          await this.loadingService.load(this.orderTranslations['fetching-patients']);
          try {
            const patientID = await this.getPatientId();
            patient = await firstValueFrom(
              this.patientService.getPatientAttributesNamesMap(patientID),
            );
          } catch (error) {
            reject(error);
          }
        }
        if (patient) {
          DynamicFormPrefillMapper.mapData(dynamicForm, patient);
        }
        resolve();
      };
      injectData();
    });
  }

  getOrderForm(orderId: string): Promise<DynamicForm> {
    return new Promise((next, error) => {
      this.ordersService
        .createOrder(orderId)
        .pipe(take(1), untilDestroyed(this))
        .subscribe({ next, error });
    });
  }

  async openCreateOrderModal(dynamicForm: DynamicForm): Promise<void> {
    const translationOptions: TranslationOptions =
      this.ordersService.translationOptionsForOrderCreation();
    const result: OverlayEventDetail = await this.popupService.showDynamicFormModal(
      new DynamicFormConfiguration({
        type: DynamicFormType.DIRECT_SAVE,
        dynamicForm,
        translationOptions,
      }),
    );
    if (result.role === OverlayEventRole.save) {
      this.isDataLoading = true;
      this.orderListUpdate.emit();
    }
  }

  async getPatientId(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.patientService
        .getAllPatients()
        .pipe(take(1), untilDestroyed(this))
        .subscribe((patients: Patient[]) => {
          const header: TableHeaderItem[] = this.constructHeaderForPatientSelectTable();
          const rows: TableListItem[] = this.constructRowsForPatientSelectTable(patients);
          this.popupService
            .showTableSelectModal(
              new TableList(header, rows),
              this.orderTranslations['choose-a-patient'],
            )
            .then((result) => {
              if (result.role === OverlayEventRole.activityChange) {
                resolve(result.data.tableListItem);
              } else {
                reject(new Error('Patient selection was cancelled or failed.'));
              }
            });
        });
    });
  }

  private constructHeaderForPatientSelectTable(): TableHeaderItem[] {
    return [
      {
        value: this.generalTranslations['patient'],
        type: TableDataType.text,
        identifier: new AttributeNameIdentifier('1'),
      },
      {
        value: this.generalTranslations['birthday'],
        type: TableDataType.date,
        identifier: new AttributeNameIdentifier('2'),
      },
    ];
  }

  private constructRowsForPatientSelectTable(patients: Patient[]): TableListItem[] {
    return patients.map(
      (patient: Patient) =>
        ({
          ...patient,
          id: patient.patientID,
          columns: [patient.getFullName(), patient.birthDate],
        }) as TableListItem,
    );
  }

  getOrderId(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.ordersService
        .getOrderTypes()
        .pipe(take(1), untilDestroyed(this))
        .subscribe((orderTypes: TableList) => {
          this.popupService
            .showTableSelectModal(orderTypes, this.orderTranslations['choose-an-order-type'])
            .then((result) => {
              try {
                if (result.role === OverlayEventRole.activityChange) {
                  resolve(result.data.tableListItem);
                }
              } catch (error) {
                reject(new Error('Failed to retrieve order ID.'));
              }
            });
        });
    });
  }

  async viewOrderDetails(orderId: string, behaviorInvokers: InvokerBody[]): Promise<void> {
    await this.loadingService.load(this.orderTranslations['fetching-order-details']);
    const result: OverlayEventDetail = await firstValueFrom(
      this.modalActionService.showDynamicViewWithActions(
        orderId,
        behaviorInvokers,
        this.ordersService.previewFallbackMethods,
      ),
    );
    if (this.modalActionService.isActionTriggeringEvent(result)) {
      this.handleModalActionEvent(result, orderId, behaviorInvokers);
    }
  }

  private async handleModalActionEvent(
    event: OverlayEventDetail,
    orderId: string,
    behaviorInvokers?: InvokerBody[],
  ): Promise<void> {
    const modalResult: ModalResult =
      await this.modalActionService.handleInvokerMethodOfActionButton(
        event,
        GenericTranslationKeys,
      );
    if (this.modalActionService.shouldReloadDataSet(modalResult)) {
      this.orderListUpdate.emit();
    }
    if (modalResult.event !== ModalEvent.stateChange) {
      this.viewOrderDetails(orderId, behaviorInvokers);
    }
  }

  private setResortingLoadingValue(value: boolean): void {
    this.isRowDataLoading = value;
  }
}
