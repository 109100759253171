import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { DocumentsService } from 'projects/core/src/lib/services/documents.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import {
  BehaviorSubject,
  firstValueFrom,
  interval,
  Observable,
  of,
  Subscription,
  throwError,
  zip,
} from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { RequiredActionsMapper } from '../mappers/required-actions.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { DocumentFormat } from '../models/documents.model';
import { TableList } from '../models/dynamic-table.model';
import { DynamicForm, DynamicFormConfiguration, DynamicFormType } from '../models/form.model';
import { Invoker, InvokerMethods } from '../models/invoker-body.model';
import {
  ModalEvent,
  ModalResult,
  RequiredTaskTranslationKeys,
  TranslationOptions,
  ViewResult,
} from '../models/modal-action.model';
import { RequiredActionsDetails } from '../models/required-actions.model';
import {
  ObjectRefresh,
  ObjectType,
  SDAPIMenuObject,
  SDAPIResponseObject,
} from '../models/sdapi-object.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { ClientConfigService } from './client-config.service';
import { LoadingService } from './loading.service';
import { ModalActionService } from './modal-action.service';
import { SDAPIService } from './sdapi.service';
import { TableDataService } from './table-data.service';

@Injectable()
export class RequiredActionsService {
  private autoRefreshIntervalSubscription: Subscription;
  private refreshRequiredActionsSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public requiredActions$: BehaviorSubject<RequiredActionsDetails[]> = new BehaviorSubject<
    RequiredActionsDetails[]
  >([]);

  public firstRequestLoading = true;

  private committedRequiredActionsIds: { committedId: number; committedStatusName: string }[] = [];

  constructor(
    private http: HttpClient,
    private documentsService: DocumentsService,
    private sdapiService: SDAPIService,
    private modalActionService: ModalActionService,
    private popupService: PopupService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private tableDataService: TableDataService,
    private destroyRef: DestroyRef,
    private clientService: ClientConfigService,
  ) {
    this.subscribeAutoRefreshInterval();
    const clientConfig = this.clientService.get();
    if (clientConfig.activeModules?.tasks) {
      this.startAutoRefreshIntervalSubscription();
    }
  }

  public startAutoRefreshIntervalSubscription(): void {
    if (!this.autoRefreshIntervalSubscription) {
      this.autoRefreshIntervalSubscription = interval(10000)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.refreshRequiredActionsSub.next(true);
        });
    }
  }

  private subscribeAutoRefreshInterval(): void {
    this.refreshRequiredActionsSub
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap(() => this.getRequiredActions()),
      )
      .subscribe();
  }

  private async getRequiredActionsRequest(): Promise<TieTableObjectList> {
    const headers: HttpHeaders = CustomHttpHeaders.build(
      CustomHttpHeaders.XBackgroundRequest,
      CustomHttpHeaders.XNoCache,
    );
    const invoker = await firstValueFrom(
      this.sdapiService.getInvokerByMethodName(
        `USER_TASKS`,
        InvokerMethods.objectConstraintObjectList,
        headers,
      ),
    );
    const objectList: TieTableObjectList = await firstValueFrom(
      this.http.put<TieTableObjectList>(invoker.activityURL, invoker.invoker, { headers }),
    );
    return objectList;
  }

  public async getRequiredActions(): Promise<RequiredActionsDetails[]> {
    const objectList: TieTableObjectList = await this.getRequiredActionsRequest();
    const requiredActions = RequiredActionsMapper.mapResource(objectList);
    const uncommittedActions = this.retrieveUncommittedActions(requiredActions);
    this.firstRequestLoading = false;
    this.requiredActions$.next(uncommittedActions);
    return uncommittedActions;
  }

  public getRequiredActionDetails(
    requiredAction: RequiredActionsDetails,
  ): Observable<RequiredActionsDetails> {
    return this.handleRequiredActionInvokers(requiredAction).pipe(
      mergeMap(([actionInvokers, imperativeInvoker]) => {
        requiredAction.actionButtons =
          SDAPIObjectMapper.mapInvokersToDynamicButtons(actionInvokers);
        return this.resolveRequiredActionDetailsByInvokerType(imperativeInvoker, requiredAction);
      }),
      catchError((error) => throwError(() => error)),
    );
  }

  public refreshRequiredActions(): void {
    this.refreshRequiredActionsSub.next(true);
  }

  public commitTask(
    requiredAction: RequiredActionsDetails,
    invoker: Invoker,
  ): Observable<ObjectRefresh | ViewResult> {
    return this.modalActionService.performActivityOnObject(invoker).pipe(
      tap((response: ObjectRefresh | ViewResult) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (response.t === ObjectType.refreshObj) {
          this.recordCommittedActionIdAndRefreshList(response as ObjectRefresh, requiredAction);
        }
      }),
      catchError((error) => throwError(() => error)),
    );
  }

  public recordCommittedActionIdAndRefreshList(
    objectRefresh: ObjectRefresh,
    requiredAction: RequiredActionsDetails,
  ): void {
    this.committedRequiredActionsIds.push({
      committedId: objectRefresh.objId,
      committedStatusName: requiredAction.statusName.value,
    });
    this.refreshRequiredActionsSub.next(true);
  }

  public async showRequiredActionModalFromComponent(
    requiredAction: RequiredActionsDetails,
  ): Promise<void> {
    try {
      await this.showRequiredAction(requiredAction);
    } finally {
      requiredAction.processing = true;
      this.refreshRequiredActions();
    }
  }

  public async showRequiredAction(requiredAction: RequiredActionsDetails): Promise<void> {
    const event: OverlayEventDetail =
      await this.popupService.showRequiredActionSwitchModal(requiredAction);
    if (this.modalActionService.isActionTriggeringEvent(event)) {
      const invoker: Invoker = event.data.invoker;
      const isCommitAction: boolean =
        this.isInvokerMethodOfTypeCommit(invoker) && event.data.requiredAction;
      if (isCommitAction) {
        await this.handleTaskCommitAction(event);
      } else {
        await this.handleNonCommitTaskAction(event, requiredAction);
      }
    }
  }

  public async showTaskInformation(requiredAction: RequiredActionsDetails): Promise<void> {
    await this.popupService
      .showRequiredActionInfoOverviewModal(requiredAction)
      .then(async (response: OverlayEventDetail) => {
        if (response.role === OverlayEventRole.actionButtonEvent && response.data) {
          await this.showRequiredAction(response.data);
        }
      });
  }

  private retrieveUncommittedActions(
    requiredActions: RequiredActionsDetails[],
  ): RequiredActionsDetails[] {
    return requiredActions.filter(
      ({ id, statusName }) =>
        !this.committedRequiredActionsIds.some(
          ({ committedId, committedStatusName }) =>
            id === committedId && statusName.value === committedStatusName,
        ),
    );
  }

  private resolveRequiredActionDetailsByInvokerType(
    imperativeInvoker: Invoker,
    requiredAction: RequiredActionsDetails,
  ): Observable<RequiredActionsDetails> {
    switch (imperativeInvoker.invoker.methodName) {
      case InvokerMethods.objectAttributesEdit:
        return this.getRequiredActionOfTypeForm(
          imperativeInvoker,
          requiredAction,
          DocumentFormat.EDIT_FORM,
        );
      case InvokerMethods.objectAttributesView:
        return this.getRequiredActionOfTypeForm(
          imperativeInvoker,
          requiredAction,
          DocumentFormat.VIEW_FORM,
        );
      case InvokerMethods.objectCreateAutoImport:
        return this.getRequiredActionOfTypeForm(
          imperativeInvoker,
          requiredAction,
          DocumentFormat.UPLOAD_FORM,
        );
      case InvokerMethods.objectObjectList:
        return this.getRequiredActionOfTypeTable(
          imperativeInvoker,
          requiredAction,
          DocumentFormat.TABLE,
        );
      case InvokerMethods.objectDistribute:
        return this.getRequiredActionOfTypeChat(
          imperativeInvoker,
          requiredAction,
          DocumentFormat.CHAT,
        );
      default:
        return this.getRequiredActionWithViewInvoker(imperativeInvoker, requiredAction);
    }
  }

  private getRequiredActionOfTypeForm(
    invoker: Invoker,
    requiredAction: RequiredActionsDetails,
    format: DocumentFormat,
  ): Observable<RequiredActionsDetails> {
    const activityURL = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
    return of(RequiredActionsMapper.mapResourceOfTypeForm(requiredAction, activityURL, format));
  }

  private getRequiredActionOfTypeChat(
    invoker: Invoker,
    requiredAction: RequiredActionsDetails,
    format: DocumentFormat,
  ): Observable<RequiredActionsDetails> {
    const activityURL = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
    return of(RequiredActionsMapper.mapResourceOfTypeForm(requiredAction, activityURL, format));
  }

  private getRequiredActionWithViewInvoker(
    invoker: Invoker,
    requiredAction: RequiredActionsDetails,
  ): Observable<RequiredActionsDetails> {
    return this.documentsService.getDocumentsViewLinkWithInvoker(invoker).pipe(
      concatMap((viewLink: string) => {
        requiredAction.viewLink = viewLink;
        return this.getRequiredActionDataType(requiredAction);
      }),
      map((ra: RequiredActionsDetails) => RequiredActionsMapper.mapResourceOfTypeOther(ra)),
    );
  }

  private getRequiredActionOfTypeTable(
    imperativeInvoker: Invoker,
    requiredAction: RequiredActionsDetails,
    format: DocumentFormat,
  ): Observable<RequiredActionsDetails> {
    return this.tableDataService.fetchTableListWithHeader(imperativeInvoker).pipe(
      map((table: TableList) => {
        requiredAction.table = table;
        requiredAction.table.actionButtons = requiredAction.actionButtons;
        requiredAction.fileType = format;
        return requiredAction;
      }),
    );
  }

  private handleRequiredActionInvokers(
    requiredAction: RequiredActionsDetails,
  ): Observable<[Invoker[], Invoker]> {
    return this.http.get<SDAPIMenuObject>(`/objects/${requiredAction.id}`).pipe(
      mergeMap((response: SDAPIMenuObject) => {
        const actionInvokers: Invoker[] =
          this.sdapiService.constructListOfSupportedActionInvokers(response);
        const imperativeInvoker: Invoker = this.constructImperativeInvoker(response);
        const filteredActionInvokers: Invoker[] = this.sdapiService.getFilteredActionInvokerList(
          imperativeInvoker,
          actionInvokers,
        );
        return zip(of(filteredActionInvokers), of(imperativeInvoker));
      }),
    );
  }

  private constructImperativeInvoker(response: SDAPIMenuObject): Invoker {
    const invokerBody = SDAPIObjectMapper.mapImperativeInvoker(response);
    return this.sdapiService.getConstructedInvoker(invokerBody);
  }

  private getRequiredActionDataType(
    requiredAction: RequiredActionsDetails,
  ): Observable<RequiredActionsDetails> {
    return this.http
      .head<any>(requiredAction.viewLink, {
        observe: 'response',
        headers: CustomHttpHeaders.XNoAlertInterceptorHeaders,
      })
      .pipe(
        map((header) =>
          RequiredActionsMapper.mapMimeTypeOfResourceDetails(
            requiredAction,
            header.headers.get('content-type'),
          ),
        ),
        catchError((error) => {
          console.warn(`Could not determine content type of task ${requiredAction.id}`, error);
          return of(
            RequiredActionsMapper.mapMimeTypeOfResourceDetails(requiredAction, 'text/html'),
          );
        }),
      );
  }

  private async handleNonCommitTaskAction(
    event: OverlayEventDetail,
    requiredAction: RequiredActionsDetails,
  ): Promise<void> {
    const modalResult: ModalResult =
      await this.modalActionService.handleInvokerMethodOfActionButton(
        event,
        RequiredTaskTranslationKeys,
      );
    if (modalResult.event !== ModalEvent.stateChange) {
      await this.showRequiredAction(requiredAction);
    }
  }

  private isInvokerMethodOfTypeCommit(invoker: Invoker): boolean {
    return (
      invoker.invoker.methodName === InvokerMethods.objectCommit ||
      invoker.invoker.methodName === InvokerMethods.objectDistribute
    );
  }

  private async handleTaskCommitAction(modalResponse: OverlayEventDetail): Promise<void> {
    try {
      const response: ObjectRefresh | ViewResult = await firstValueFrom(
        this.commitTask(modalResponse.data.requiredAction, modalResponse.data.invoker),
      );
      if (this.modalActionService.isValidDynamicForm(response)) {
        await this.showTaskCommitFinalizationModal(
          modalResponse.data.requiredAction,
          response as DynamicForm,
        );
      } else {
        await this.loadingService.toast(
          await firstValueFrom(this.translateService.get('shared.required-action.task-completed')),
        );
      }
    } catch (error) {
      console.error(
        `Error in RequiredActionsWidgetComponent -> handleRequiredTaskCommitAction`,
        error,
      );
    }
  }

  private async showTaskCommitFinalizationModal(
    requiredAction: RequiredActionsDetails,
    form: DynamicForm,
  ): Promise<void> {
    await this.popupService
      .showDynamicFormModal(this.formConfiguration(requiredAction, form))
      .then(async (response: OverlayEventDetail) => {
        if (response.role === OverlayEventRole.save && response.data.response) {
          await this.handleFormSaveAction(response.data.response, requiredAction);
        } else {
          await this.showRequiredAction(requiredAction);
        }
      });
  }

  private formConfiguration(
    requiredAction: RequiredActionsDetails,
    form: DynamicForm,
  ): DynamicFormConfiguration {
    const translationOptions: TranslationOptions = {
      keys: RequiredTaskTranslationKeys,
      successMessageKey: 'commit-completion',
    };
    return new DynamicFormConfiguration({
      type: DynamicFormType.DIRECT_SAVE,
      activityURL: form.activityURL,
      requiredActionsDetails: requiredAction,
      dynamicForm: form,
      translationOptions,
    });
  }

  private async handleFormSaveAction(
    response: SDAPIResponseObject,
    requiredAction: RequiredActionsDetails,
  ) {
    try {
      const objectRefresh: ObjectRefresh =
        this.modalActionService.handleResponseOfFormSave(response);
      this.recordCommittedActionIdAndRefreshList(objectRefresh, requiredAction);
    } catch (error) {
      console.error(`Error in RequiredActionsWidgetComponent -> handleFormSaveAction`, error);
    }
  }
}
