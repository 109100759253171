<ion-button
  class="p3"
  [color]="config.triggerButton.color"
  expand="block"
  [disabled]="config.triggerButton.disabled"
  [attr.data-api-id]="config.triggerButton.dataApiId"
  (click)="openActionSheet()">
  <span>{{ config.triggerButton.label }}</span>
  <fa-icon icon="bars" size="sm" slot="end"></fa-icon>
</ion-button>

@for (button of config.actionButtons; track $index) {
  @if (button.hasPrintActivity && labelService.isPrintCapableDevice()) {
    <iframe
      class="hidden-label-iframe"
      [src]="button.printDetails.safeUrl"
      [title]="button.printDetails.fileName"
      #iframe>
    </iframe>
  }
}
