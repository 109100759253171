<lib-auto-layout direction="vertical" horizontal="stretch" [grow]="1" class="widget-card">
  <lib-auto-layout vertical="center" [gap]="[10]" [padding]="[15, 20, 10]">
    <fa-icon [icon]="icons.handHoldingDollar"></fa-icon>
    <h2 data-api-id="document-list" [grow]="1" class="no-margin">
      {{ 'expenses-widget.latest-expenses' | translate }}
    </h2>
  </lib-auto-layout>
  @if (isLoading) {
    <lib-documents-skeleton [lines]="skeletonItemsNumber" [hasExtendedView]="desktopView">
    </lib-documents-skeleton>
  } @else if (expenses.length !== 0) {
    <lib-auto-layout
      direction="vertical"
      horizontal="stretch"
      [grow]="1"
      [padding]="[1, 0, 0]"
      id="expenses-list">
      @for (expense of expenses; track expense) {
        <lib-expense-row
          [expense]="expense"
          [hasExtendedView]="desktopView"
          [hasNavigationIcon]="false"
          (listReload)="initializeExpensesList()">
        </lib-expense-row>
      }
    </lib-auto-layout>
  } @else {
    <lib-auto-layout direction="vertical" [padding]="[1, 0]" [grow]="1" class="no-documents">
      <lib-notification-box-component
        [grow]="1"
        [message]="'expenses-widget.no-expenses-found' | translate">
      </lib-notification-box-component>
    </lib-auto-layout>
  }
  @if (expenses.length !== 0) {
    <lib-auto-layout horizontal="center" [padding]="[15]" id="button-section">
      <ion-button
        class="documents-page-link p3"
        color="medium"
        fill="clear"
        expand="block"
        routerLink="/portal/expenses">
        {{ 'expenses-widget.show-all-expenses' | translate }}
        <fa-icon slot="end" [icon]="icons.arrow"></fa-icon>
      </ion-button>
    </lib-auto-layout>
  }
</lib-auto-layout>
