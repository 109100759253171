import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-date-field-time-popover',
  templateUrl: './date-field-time-popover.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrls: ['./date-field-time-popover.component.scss'],
})
export class DateFieldTimePopoverComponent {
  @Input() value: string;
  @Input() name: string;

  constructor(
    private popoverController: PopoverController,
    public translateService: TranslateService,
  ) {}

  async onTimeChange(changeEvent: any): Promise<void> {
    if (await this.popoverController.getTop()) {
      await this.popoverController.dismiss(
        changeEvent.detail.value ?? null,
        'timeChanged',
        'date-field-time-popover',
      );
    }
  }

  async onCancel(): Promise<void> {
    if (await this.popoverController.getTop()) {
      await this.popoverController.dismiss(null, 'timeChanged', 'date-field-time-popover');
    }
  }
}
