import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'lib-auto-layout',
  template: '<ng-content></ng-content>',
  styles: [
    `
      :host {
        display: flex;
        position: relative;
        min-width: 0px;
        min-height: 0px;
        max-width: 100%;
      }
    `,
  ],
})
export class AutoLayoutComponent implements OnInit, OnChanges {
  @Input() direction: LayoutDirection;
  @Input() horizontal: AlignMethod;
  @Input() vertical: AlignMethod;
  @Input() gap: number[] = [];
  @Input() padding: number[] = [];

  @HostBinding('style.gap') get gapString() {
    return this.gapValueString;
  }
  @HostBinding('style.padding') get paddingString() {
    return this.paddingValueString;
  }
  @HostBinding('style.flex-direction') get flexDirection() {
    return this.directionString;
  }
  @HostBinding('style.align-items') get alignString() {
    return this.alignProperty;
  }
  @HostBinding('style.justify-content') get justifyString() {
    return this.justifyProperty;
  }

  alignProperty = 'flex-start';
  justifyProperty = 'flex-start';

  ngOnInit(): void {
    this.setAligns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['direction'] || changes['horizontal'] || changes['vertical']) {
      this.setAligns();
    }
  }

  private get gapValueString(): string {
    return this.toCSSPixelString(this.gap);
  }

  private get paddingValueString(): string {
    return this.toCSSPixelString(this.padding);
  }

  private toCSSPixelString(values: number[]): string {
    return values.map((value) => `${value}px`).join(' ');
  }

  private setAligns(): void {
    const horizontalMethod = this.resolveAlignMethod(this.horizontal);
    const verticalMethod = this.resolveAlignMethod(this.vertical);
    if (this.direction === 'vertical') {
      this.alignProperty = horizontalMethod;
      this.justifyProperty = verticalMethod;
    } else {
      this.alignProperty = verticalMethod;
      this.justifyProperty = horizontalMethod;
    }
  }

  resolveAlignMethod(method: AlignMethod): string {
    switch (method) {
      case 'start':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'end':
        return 'flex-end';
      case 'space-around':
        return 'space-around';
      case 'space-between':
        return 'space-between';
      case 'space-evenly':
        return 'space-evenly';
      case 'stretch':
        return 'stretch';
      default:
        return 'flex-start';
    }
  }
  private get directionString(): string {
    if (this.direction === 'vertical') {
      return 'column';
    } else {
      return 'row';
    }
  }
}

type LayoutDirection = 'horizontal' | 'vertical';
type AlignMethod =
  | 'start'
  | 'center'
  | 'end'
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'stretch';
