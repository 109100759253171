import { Component, Input } from '@angular/core';
import { faArrowRight, faInfo } from '@fortawesome/free-solid-svg-icons';

import { UntilDestroy } from '@ngneat/until-destroy';
import { RequiredActionsDetails } from 'projects/core/src/lib/models/required-actions.model';
import { ElementBreakpoint } from 'projects/core/src/lib/services/breakpoint.service';
import { RequiredActionsService } from 'projects/core/src/lib/services/required-actions.service';
import { Dimensions } from 'projects/theme/src/lib/directives/on-dimension-change.directive';

@Component({
  selector: 'lib-task-row',
  templateUrl: './task-row.component.html',
  styleUrls: ['./task-row.component.scss'],
})
@UntilDestroy()
export class TaskRowComponent {
  @Input({ required: true }) requiredAction: RequiredActionsDetails;
  @Input() isOdd: boolean;

  readonly ICONS = {
    info: faInfo,
    arrow: faArrowRight,
  };

  isMobileView = false;

  constructor(private requiredActionsService: RequiredActionsService) {}

  async showRequiredAction(requiredAction: RequiredActionsDetails) {
    this.requiredActionsService.showRequiredActionModalFromComponent(requiredAction);
  }

  showTaskInformation(requiredAction: RequiredActionsDetails): void {
    this.requiredActionsService.showTaskInformation(requiredAction);
  }

  setViewMode(dimension: Dimensions): void {
    this.isMobileView = dimension.width <= ElementBreakpoint.MOBILE;
  }

  get timeLabelClasses(): any {
    const { isUrgent, isOverdue } = this.requiredAction;
    return {
      urgent: isUrgent,
      past: isOverdue,
      'color-grey': !isUrgent || !isOverdue,
      'color-info': isUrgent,
      'color-warn': isOverdue,
    };
  }

  get dateDecription(): string {
    const { isUrgent, isOverdue } = this.requiredAction;
    if (isUrgent) {
      return 'shared.required-action.due-today';
    } else if (isOverdue) {
      return 'shared.required-action.overdue-on';
    } else {
      return 'shared.required-action.due-on';
    }
  }
}
