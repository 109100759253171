import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { faArrowRight, faCheck, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { RequiredActionsDetails } from 'projects//core/src/lib/models/required-actions.model';
import { RequiredActionsService } from 'projects/core/src/lib/services/required-actions.service';
import { catchError, firstValueFrom, of } from 'rxjs';

@Component({
  selector: 'lib-required-actions-widget',
  templateUrl: './required-actions.component.html',
  styleUrls: ['./required-actions.component.scss'],
})
export class RequiredActionsWidgetComponent implements OnInit {
  requiredActions: RequiredActionsDetails[] = [];
  translations: Object;

  get isLoading(): boolean {
    return this.requiredActionsService.firstRequestLoading;
  }

  readonly icons = {
    arrow: faArrowRight,
    task: faClipboardCheck,
    check: faCheck,
  };

  constructor(
    private requiredActionsService: RequiredActionsService,
    private destroyRef: DestroyRef,
    private translateService: TranslateService,
  ) {}

  async ngOnInit() {
    await this.setRequiredActionTranslations();
    await this.subscribeRequiredActions();
  }

  private async setRequiredActionTranslations(): Promise<void> {
    this.translations = await firstValueFrom(this.translateService.get('shared.required-action'));
  }

  private subscribeRequiredActions(): Promise<void> {
    return new Promise((resolve) => {
      this.requiredActionsService.requiredActions$
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError(() => of([])),
        )
        .subscribe((requiredActions: RequiredActionsDetails[]) => {
          this.requiredActions = requiredActions;
          resolve();
        });
    });
  }

  async showRequiredAction(requiredAction: RequiredActionsDetails) {
    this.requiredActionsService.showRequiredActionModalFromComponent(requiredAction);
  }

  showTaskInformation(requiredAction: RequiredActionsDetails): void {
    this.requiredActionsService.showTaskInformation(requiredAction);
  }

  get layoutClass(): string {
    const count = this.requiredActions.length;
    if (count === 1) {
      return 'col-1';
    } else if ((count % 3 === 0 || count % 5 === 0) && (count - 10) % 15 !== 0) {
      return 'col-3';
    } else {
      return 'col-2';
    }
  }

  get hasRequiredActions(): boolean {
    return this.requiredActions.length > 0;
  }

  get filteredRequiredActions(): RequiredActionsDetails[] {
    return this.requiredActions.filter((requiredAction) => !requiredAction.processing);
  }
}
