import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import {
  faCalendar,
  faCalendarWeek,
  faClock,
  faLock,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDataField } from 'projects/core/src/lib/models/form.model';
import { consoleDebugInDevMode } from 'projects/core/src/lib/utils/debug.utils';

@Component({
  selector: 'pp-base-field',
  templateUrl: './base-field.component.html',
  styleUrls: ['./base-field.component.scss'],
})
export class BaseFieldComponent implements AfterContentInit {
  @Input() item: DynamicDataField;
  @Input() preview: boolean;
  @Input() customHtmlId = Math.random().toString(36).substring(7);
  @Input() input: NgModel;
  @Input() showLabel: boolean = true;
  @Input() showResetButton: boolean = true;
  @Input() resetButtonTabIndex: string = '0';

  @Output() resetValueEvent = new EventEmitter<void>();

  @HostListener('mousedown', ['$event']) debug = (event) => consoleDebugInDevMode(event, this.item);

  @ViewChild('formInput') formInput: ElementRef<HTMLInputElement>;

  protected translateService: TranslateService;

  remainingCharactersMessage: string;

  readonly ICONS = {
    RESET: faRotateLeft,
    LOCK: faLock,
    CLOCK: faClock,
    CALENDAR: faCalendar,
    CALENDAR_WEEK: faCalendarWeek,
  };

  ngAfterContentInit() {
    this.initValidators();
  }

  initValidators() {
    if (this.inputNeedsValidators) {
      this.setValidators();
      this.setPossibleValueOnInputControl();
      this.handleFieldsWithErrors();
    }
  }

  get inputNeedsValidators(): boolean {
    const hasInputControl = !!this.input?.control;
    const hasItemValidators = !!this.item?.validators?.length;

    return hasInputControl && hasItemValidators && this.isEditableField;
  }

  private setValidators(): void {
    this.input.control.setValidators(this.item.validators);
  }

  setPossibleValueOnInputControl(): void {
    if (this.item.hasValue) {
      this.input.control.setValue(this.item.value.value);
    }
  }

  handleFieldsWithErrors(): void {
    const { control } = this.input;
    control.updateValueAndValidity();

    if (control.errors) {
      this.ensureFieldMarkedAsTouched(this.input);
    }

    this.setInitialErrorsExceptInputRequired();
  }

  private setInitialErrorsExceptInputRequired() {
    if (this.input.errors) {
      if (
        Object.keys(this.input.errors).filter((key) => key !== 'shared.forms.input-required')
          .length == 0
      ) {
        this.input.control.markAsUntouched();
      }
    }
  }

  public ensureFieldMarkedAsTouched(field: NgModel): void {
    /**
     * This is important to derive when to show the errors.
     */
    if (!field.touched) {
      field.control?.markAsTouched();
    }
  }

  get isEditableField() {
    return !this.item.readOnly && !this.preview;
  }

  get ifOnLimitGetTranslationKeyWithParams(): {
    key: string;
    params: { maxLength: number; currentLength: number };
  } | null {
    if (!this.item?.value?.limiters?.max || !this.item?.value?.value) {
      return null;
    }
    const maxLength = Number.parseInt(this.item.value.limiters?.max, 10);
    const currentLength = this.item.value.value.length;
    const isOnLimit = currentLength / maxLength > 0.8;
    if (isOnLimit) {
      return {
        key: 'shared.forms.remaining-characters',
        params: { maxLength, currentLength },
      };
    } else {
      return null;
    }
  }

  get resetButtonDisabled(): boolean {
    return !(this.item.isModified && this.isEditableField);
  }

  resetValue() {
    this.input?.control.setValue(this.item.value.originalValue);
    this.item.value.value = this.item.value.originalValue;
    this.input?.control.updateValueAndValidity();
    this.resetValueEvent.emit();
  }

  get showErrors(): boolean {
    if (!this.input?.errors) {
      return false;
    }
    if (
      this.item.value.value &&
      Object.keys(this.input?.errors).includes('shared.forms.invalid-input-numbers-only')
    ) {
      return true;
    }
    return !this.item.required || this.input.touched;
  }

  get displayLabel(): boolean {
    return this.showLabel && (!!this.isEditableField || !!this.item.name);
  }

  get placeholderKey(): string {
    return this.isEditableField ? 'shared.forms.please-enter' : 'general.not-specified';
  }

  get shouldShowResetButton(): boolean {
    return this.isEditableField && this.showResetButton;
  }

  get isEdited(): boolean {
    return this.item?.isModified && !this.preview;
  }

  get tabIndex() {
    return this.isEditableField ? 0 : -1;
  }
}
