<div class="viewer">
  <ion-row class="top">
    <ion-col size="12" class="ion-no-padding">
      <lib-terms-of-service-viewer
        (loading)="setLoadingValue($event)"></lib-terms-of-service-viewer>
    </ion-col>
  </ion-row>
  <div
    *ngIf="buttonsVisibility && !isLoading"
    class="button-section ion-justify-content-center ion-align-items-center">
    <ion-button
      class="accept-button p1 ion-no-margin"
      color="primary"
      type="submit"
      expand="block"
      (click)="setTosToRead()">
      {{ 'general.accept' | translate }}
    </ion-button>
    <ion-button
      class="reject-button p1 ion-no-margin"
      color="cancel-button"
      expand="block"
      (click)="logOutPatient()">
      {{ 'general.decline' | translate }}
    </ion-button>
  </div>
</div>
