<lib-auto-layout direction="vertical" [gap]="[5]" [padding]="[5]" class="stretch-self">
  @for (appointment of appointmentGroup.appointments; track appointment.id) {
    <button
      (click)="selectAppointment(appointment)"
      class="appointment-item"
      [class.selected]="isSelectedAppointment(appointment.id) && !isMobile"
      [class.future]="isFutureAppointment(appointmentGroup.date)">
      <lib-auto-layout
        class="appointment-details"
        [grow]="1"
        [padding]="[15, 20]"
        [gap]="[20]"
        vertical="center">
        <lib-auto-layout
          class="appointment-info"
          direction="vertical"
          horizontal="stretch"
          [gap]="[4]"
          [grow]="1">
          <lib-auto-layout vertical="center">
            <lib-auto-layout [gap]="[10]" vertical="center" [grow]="1">
              <fa-icon
                [size]="iconSize"
                [icon]="appointment.type === 'REMOTE' ? icons.video : icons.date">
              </fa-icon>
              <p [grow]="1" class="appointment-time ion-no-padding ion-no-margin h3" slot="start">
                @if (appointment.isAllDay) {
                  {{ 'shared.appointment-item.all-day' | translate }}
                } @else {
                  {{ appointment.startDate | time: 'shortTime' : true : true }}
                }
              </p>
            </lib-auto-layout>

            @if (appointment.status) {
              <lib-auto-layout
                direction="horizontal"
                vertical="center"
                [gap]="[5]"
                class="appointment-status shape-chip color-grey-medium">
                <div
                  class="appointment-status-indicator"
                  [style.background]="appointment.status.hexColor"></div>
                <p class="p3 trim-text-inline">
                  {{ appointment.status.label }}
                </p>
              </lib-auto-layout>
            }
          </lib-auto-layout>

          <p class="trim-text-inline">
            {{ appointment.title }}
          </p>
          <p class="trim-text-inline">
            {{ appointment.doctor }}
          </p>
        </lib-auto-layout>

        <fa-icon size="lg" [icon]="icons.chevronRight"></fa-icon>
      </lib-auto-layout>

      <div
        [class.active]="isSelectedAppointment(appointment.id) && !isMobile"
        class="selected-indicator"></div>
    </button>
  }
</lib-auto-layout>
