<ion-item
  [class.hide-item-icon]="!hasNavigationIcon"
  button="true"
  lines="none"
  (click)="viewDocument(document)">
  <div class="list-row" [ngClass]="isExtendedView ? 'extended' : 'condensed'">
    <div class="thumbnail-item">
      <lib-document-thumbnail [document]="document"></lib-document-thumbnail>
    </div>

    @if (!isExtendedView) {
      <lib-auto-layout
        [padding]="[7, 0, 7, 10]"
        direction="vertical"
        vertical="start"
        horizontal="center"
        class="stretch-self trim-text-inline">
        <h3 class="ion-no-margin stretch-self trim-text-inline">{{ document.name }}</h3>
        <lib-auto-layout
          [gap]="[10]"
          direction="horizontal"
          horizontal="space-between"
          vertical="center"
          class="stretch-self trim-text-inline">
          <span class="detail trim-text-inline">
            {{ document.date | localeDate: 'shortDate' }}</span
          >
          <span class="detail">
            {{ document.size ? documentSize : ('shared.documents.no-specification' | translate) }}
          </span>
        </lib-auto-layout>
      </lib-auto-layout>
    }

    @if (isExtendedView) {
      <div class="details">
        <div class="name-item trim-text-inline">
          <ion-text class="name">{{ document.name }}</ion-text>
        </div>

        <div class="date-item ion-text-end">
          <ion-text class="detail">
            {{ document.date | localeDate: 'shortDate' }}
          </ion-text>
        </div>

        <div slot="end" class="size-item ion-text-end" data-api-id="document-details">
          <ion-text class="detail">
            {{ document.size ? documentSize : ('shared.documents.no-specification' | translate) }}
          </ion-text>
        </div>
      </div>
    }
  </div>
</ion-item>
