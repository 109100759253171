import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { API_CONFIG, ApiConfig } from 'projects/core/src/lib/models/api-config.model';
import { AppName } from 'projects/core/src/lib/models/app.model';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { catchError, firstValueFrom, mergeMap, Observable, of, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  ClientAuthCockpitMode,
  ClientAuthType,
  ClientConfig,
  HttpMethod,
} from '../models/client.model';
import { ClientConfigService } from './client-config.service';
import { WINDOW } from 'projects/core/src/lib/injection-tokens';
import { getReferrerPath } from 'projects/core/src/lib/functions/referrer.functions';
import { APP_BASE_HREF } from '@angular/common';

@Injectable()
export class AuthService {
  private cachedLoggedInState: Observable<boolean>;
  readonly onLogout: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpClient,
    private clientConfigService: ClientConfigService,
    @Inject('AppName') private appName: AppName,
    @Inject(WINDOW) private window: Window,
    @Inject(API_CONFIG) private readonly apiConfig: ApiConfig,
    @Inject(APP_BASE_HREF) private readonly baseHref: string,
  ) {}

  public async logout(): Promise<void> {
    const clientConfig: ClientConfig = this.clientConfigService.get();

    if (clientConfig.authConfig.type === ClientAuthType.TIE) {
      await this.performTieAuthServiceLogout(clientConfig);
    } else {
      await this.performDefaultLogout(clientConfig);
    }

    if (!clientConfig.activeModules.landingpage && !clientConfig.authConfig.logoutByRedirect) {
      this.window.location.reload();
    }
    this.onLogout.next();
    delete this.cachedLoggedInState;
  }

  private getAuthCheckPath(): string {
    return this.apiConfig.apiUrl + this.apiConfig.authCheckPath;
  }

  public isLoggedIn(): Observable<boolean> {
    if (this.cachedLoggedInState) {return this.cachedLoggedInState;}
    this.cachedLoggedInState = this.http
      .get(this.getAuthCheckPath(), {
        headers: CustomHttpHeaders.build(
          CustomHttpHeaders.XNoAlertInterceptor,
          CustomHttpHeaders.XNoUnauthorizedInterceptor,
          CustomHttpHeaders.XNoApiBaseUrlInterception,
        ),
        responseType: 'text',
      })
      .pipe(
        mergeMap(() => of(true)),
        catchError(() => of(false)),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    return this.cachedLoggedInState;
  }

  private async performTieAuthServiceLogout(clientConfig: ClientConfig): Promise<void> {
    await firstValueFrom(
      this.postRequestForLogout(
        `/auth-service/jslogout?application=${this.getCurrentAuthApplicationKey(clientConfig)}`,
      ),
      { defaultValue: {} },
    );
  }

  private async performDefaultLogout(clientConfig: ClientConfig): Promise<void> {
    if (clientConfig.authConfig.logoutByRedirect) {
      this.window.location.replace(clientConfig.authConfig.customLogoutUrl);
    } else {
      await firstValueFrom(this.requestLogout(clientConfig));
    }
  }

  public getCurrentAuthApplicationKey(clientConfig: ClientConfig): string {
    const clientKey: string = isDevMode() ? 'dev' : clientConfig.key;
    return clientKey === 'default' ? this.appName : `${this.appName}-${clientKey}`;
  }

  public authCheck(): void {
    firstValueFrom(
      this.http.get(this.getAuthCheckPath(), {
        responseType: 'text',
        headers: CustomHttpHeaders.XNoApiBaseUrlInterceptionHeaders,
      }),
    );
  }

  private requestLogout(clientConfig: ClientConfig): Observable<void> {
    switch (clientConfig.authConfig.logoutHttpMethod) {
      case HttpMethod.GET: {
        return this.getRequestForLogout(clientConfig.authConfig.customLogoutUrl);
      }
      case HttpMethod.POST: {
        return this.postRequestForLogout(clientConfig.authConfig.customLogoutUrl);
      }
      case HttpMethod.PUT: {
        return this.putRequestForLogout(clientConfig.authConfig.customLogoutUrl);
      }
      case HttpMethod.DELETE: {
        return this.deleteRequestForLogout(clientConfig.authConfig.customLogoutUrl);
      }
    }
  }

  private getRequestForLogout(customLogoutUrl: string): Observable<void> {
    return this.http.get<void>(customLogoutUrl);
  }

  private postRequestForLogout(customLogoutUrl: string): Observable<void> {
    return this.http.post<void>(customLogoutUrl, {});
  }

  private putRequestForLogout(customLogoutUrl: string): Observable<void> {
    return this.http.put<void>(customLogoutUrl, {});
  }

  private deleteRequestForLogout(customLogoutUrl: string): Observable<void> {
    return this.http.delete<void>(customLogoutUrl);
  }

  public buildAuthCockpitUrl(clientConfig: ClientConfig): string | undefined {
    const link: string = clientConfig.authConfig.authCockpitLink;
    if (link) {
      const authCockpitUrl = `${clientConfig.authConfig.authCockpitLink}?application=${this.getCurrentAuthApplicationKey(clientConfig)}`;
      if (clientConfig.authConfig.authCockpitMode === ClientAuthCockpitMode.SAME_PAGE) {
        return `${authCockpitUrl}&from=${getReferrerPath(this.baseHref)}`;
      } else {
        return authCockpitUrl;
      }
    } else {
      return undefined;
    }
  }

  public determineHeadersForPublicRequest(): Observable<HttpHeaders | undefined> {
    return this.isLoggedIn().pipe(
      map((loggedIn) => {
        if (loggedIn) {
          return undefined;
        } else {
          return CustomHttpHeaders.XPublicUserRequestHeaders;
        }
      }),
    );
  }
}
