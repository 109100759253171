import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  faCalendar,
  faCalendarCheck,
  faCalendarPlus,
  faChevronLeft,
  faChevronRight,
  faHospital,
  faInfo,
  faPencil,
  faStethoscope,
} from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { APIError } from 'projects/core/src/lib/data/errors.data';
import { DynamicFormPrefillMapper } from 'projects/core/src/lib/mappers/dynamic-form-prefill.mapper';
import { DynamicFormMapper } from 'projects/core/src/lib/mappers/dynamic-form.mapper';
import { FormMapper } from 'projects/core/src/lib/mappers/form.mapper';
import { SDAPIObjectMapper } from 'projects/core/src/lib/mappers/sdapi-object.mapper';
import {
  BookingQueue,
  Step,
  StepTypes,
} from 'projects/core/src/lib/models/appointment-booking.model';
import { TableList, TableListItem } from 'projects/core/src/lib/models/dynamic-table.model';
import { DataType, DynamicDataField, DynamicForm } from 'projects/core/src/lib/models/form.model';
import { Invoker, InvokerMethods } from 'projects/core/src/lib/models/invoker-body.model';
import { SDAPIResponseObject } from 'projects/core/src/lib/models/sdapi-object.model';
import { AppointmentBookingService } from 'projects/core/src/lib/services/appointment-booking.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { SDAPIService } from 'projects/core/src/lib/services/sdapi.service';
import { concatMap, firstValueFrom, map, Subscription, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'lib-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.scss'],
})
export class BookingModalComponent implements OnInit {
  @Input() form: DynamicForm;
  @Input() createId: string = undefined;

  @ViewChild('f') ngForm: NgForm;

  private generalTranslations: string[];
  private bookingTranslations: string[];

  readonly ICONS = {
    title: faCalendarPlus,
    edit: faPencil,
    back: faChevronLeft,
    next: faChevronRight,
  };

  sharedData: SharedBookingData = new SharedBookingData();
  bookingQueue: BookingQueue<SharedBookingData>;

  constructor(
    private bookingService: AppointmentBookingService,
    private dynamicFormMapper: DynamicFormMapper,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private patientService: PatientService,
    private popupService: PopupService,
    private sdapiService: SDAPIService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.generalTranslations = await firstValueFrom(this.translateService.get('general'));
    this.bookingTranslations = await firstValueFrom(
      this.translateService.get('shared.appointment-booking'),
    );
    this.bookingQueue = new BookingQueue<SharedBookingData>(
      [
        {
          title: this.form.body?.[0]?.name || this.bookingTranslations['department'],
          icon: faHospital,
          type: StepTypes.form,
          dynamicForm: this.form,
          trigger: () => this.typeSelectionGroupTrigger(),
          groupIndex: 0,
          keepEditable: false,
        },
        {
          title: this.form.body?.[1]?.name || this.bookingTranslations['appointment-type'],
          icon: faStethoscope,
          type: StepTypes.form,
          dynamicForm: this.form,
          trigger: () => this.typeSelectionGroupTrigger(),
          groupIndex: 1,
          keepEditable: false,
        },
        {
          title: this.bookingTranslations['date-selection'],
          icon: faCalendar,
          type: StepTypes.dynamicDate,
          groupIndex: 0,
          resultGroupIndex: 1,
          dynamicForm: this.sharedData.bookingForm,
          trigger: () => this.resolveAppointmentForm(this.sharedData),
          keepEditable: true,
        },
        {
          title: this.bookingTranslations['more-information'],
          icon: faInfo,
          type: StepTypes.form,
          dynamicForm: this.sharedData.bookingForm,
          groupIndex: 2,
          trigger: () => this.injectForm(),
          keepEditable: true,
        },
        {
          title: this.generalTranslations['verify'],
          icon: faCalendarCheck,
          type: StepTypes.information,
          info: this.bookingTranslations['verify-data-and-send-form'],
          keepEditable: false,
        },
      ],
      this.sharedData,
    );
    if (this.createId) {
      this.bookingQueue.next();
      this.bookingQueue.next();
    } else {
      this.skipStaticFormStep(this.bookingQueue);
    }
  }

  private typeSelectionGroupTrigger(): void {
    this.deleteBookingForm();
    this.skipStaticFormStep(this.bookingQueue);
  }

  private skipStaticFormStep(bookingQueue: BookingQueue<any>): void {
    if (!this.stepHasEditableFields(bookingQueue.currentStep)) {
      bookingQueue.next();
    }
  }

  private stepHasEditableFields(step: Step): boolean {
    return !!step.dynamicForm?.body[step.groupIndex].fieldGroup.find(
      (field) => field.value.options?.length !== 1,
    );
  }

  stepIsEditable(step: Step): boolean {
    return step.keepEditable || this.stepHasEditableFields(step);
  }

  deleteBookingForm(): void {
    this.sharedData.bookingForm = undefined;
    this.sharedData.availableAppointmentSlots = undefined;
  }

  injectForm(): void {
    this.bookingQueue.currentStep.dynamicForm = this.bookingQueue.sharedData.bookingForm;
  }

  resolveAppointmentForm(sharedData: SharedBookingData) {
    delete this.bookingQueue.currentStep.hasIntermediateStep;
    delete this.bookingQueue.currentStep.intermediateStepForm;

    this.bookingService
      .getAppointmentBookingForm(this.form)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: async (form) => {
          if (this.createId) {
            const popupField = form
              .getAllFieldItems()
              .find((field) => field.type === DataType.popup);
            popupField.value.value = this.createId;
          }

          sharedData.bookingForm = form;
          await this.injectPatientData(form);
          this.injectForm();

          if (this.createId) {
            delete this.createId;
            this.bookingQueue.next();
          } else {
            this.decideBookingForm(form);
          }
        },
        error: (error) => {
          throw new APIError('Failed to create Appointment.', error);
        },
      });
  }

  injectPatientData(dynamicForm: DynamicForm): Promise<void> {
    return firstValueFrom(
      this.patientService.getCurrentPatient().pipe(
        concatMap((patient) => this.patientService.getPatientAttributesNamesMap(patient.patientID)),
        map((prefillMap) => {
          DynamicFormPrefillMapper.mapData(dynamicForm, prefillMap);
        }),
      ),
    );
  }

  async decideBookingForm(form: DynamicForm) {
    delete this.bookingQueue.currentStep.hasIntermediateStep;
    let invoker = this.getPopupInvokerField(form)?.value.invoker;
    if (!invoker) {
      throw new APIError('No PopupInvoker found!');
    }

    if (invoker.invoker.methodName === InvokerMethods.objectFindInObjectList) {
      this.bookingQueue.currentStep.hasIntermediateStep = true;
      invoker = await this.waitForAppointmentSlotInvoker(invoker);
    }
    await this.getAppointmentSlotsFromInvoker(invoker);
  }

  async waitForAppointmentSlotInvoker(invoker: Invoker): Promise<Invoker> {
    this.bookingQueue.currentStep.intermediateStepForm = await firstValueFrom(
      this.bookingService.getAppointmentSlotsIntermediateForm(invoker),
    );

    return new Promise<Invoker>((resolve) => {
      const intermediateForm: DynamicForm = this.bookingQueue.currentStep.intermediateStepForm;

      const formStatusSubscription: Subscription = this.ngForm.statusChanges.subscribe((status) => {
        if (status == 'VALID') {
          const searchInvoker = this.dynamicFormMapper.getSearchInvoker(intermediateForm);
          searchInvoker.invoker = FormMapper.insertDynamicFormItemsToInvokerBody(
            intermediateForm.body,
            searchInvoker,
          );
          resolve(searchInvoker);
          this.bookingQueue.currentStep.intermediateStepForm = undefined;
          formStatusSubscription.unsubscribe();
        }
      });
    });
  }

  get appointmentChosen(): boolean {
    return !!this.bookingQueue.sharedData.bookingForm?.body
      .flatMap((group) => group.fieldGroup)
      .find((item) => item.type === DataType.popup)?.value.value;
  }

  async sendAppointment(): Promise<void> {
    await this.loadingService.load(this.bookingTranslations['appointment-booking-in-process']);
    await firstValueFrom(
      this.sdapiService.sendByStepInvoker(this.bookingQueue.sharedData.bookingForm),
    ).then(async (response: SDAPIResponseObject) => {
      const appointmentId: string = this.retrieveAppointmentIdFromResponse(response);
      await this.modalController.dismiss(appointmentId, OverlayEventRole.save);
      await this.loadingService.stop();
      await this.popupService.dismissTopOverlayPopover();
      await this.loadingService.toast(
        `${this.bookingTranslations['appointment-booking-successful']}`,
      );
    });
  }

  private retrieveAppointmentIdFromResponse(response: SDAPIResponseObject): string {
    return SDAPIObjectMapper.mapCreatedObjId(response).toString();
  }

  async getAppointmentSlotsFromInvoker(invoker: Invoker) {
    this.bookingQueue.sharedData.availableAppointmentSlots =
      await this.getSelectionByPopupInvoker(invoker);
  }

  async getSelectionByPopupInvoker(invoker: Invoker): Promise<AppointmentSlot[]> {
    const appointmentsTable: TableList = await firstValueFrom(
      this.bookingService.getAppointmentSlots(invoker),
    );
    return appointmentsTable.initialRows.map((row: TableListItem) => ({
      id: row.id,
      time: row.columns[2],
    }));
  }

  private getPopupInvokerField(dynamicForm: DynamicForm): DynamicDataField | undefined {
    return dynamicForm?.body
      .flatMap((group) => group.fieldGroup)
      .find((item) => item.type === DataType.popup);
  }

  get requiredFullfiled(): boolean {
    const queue = this.bookingQueue;
    const step = this.bookingQueue.currentStep;

    if (step.type === StepTypes.dynamicDate) {
      return !!this.getPopupInvokerField(queue.sharedData.bookingForm)?.hasValue;
    } else if (step.groupIndex !== undefined) {
      return queue.currentFormGroup?.fieldGroup.every(
        (field: DynamicDataField) => !field.required || field.hasValue,
      );
    } else {
      return true;
    }
  }
}

export class SharedBookingData {
  availableAppointmentSlots: AppointmentSlot[] = [];
  bookingForm: DynamicForm | undefined = undefined;
}

export class AppointmentSlot {
  id: string;
  time: string;
}
