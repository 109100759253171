@if (isEditMode) {
  <lib-horizontal-sorting-edit-mode
    [visibleHeaderItems]="visibleHeaderItems"
    [hiddenHeaderItems]="hiddenHeaderItems"
    [header]="header"
    [headerReordering]="headerReordering"
    [rowState]="rowState"
    (editModeToggle)="toggleEditMode($event)"></lib-horizontal-sorting-edit-mode>
} @else {
  <lib-horizontal-sorting-view-mode
    [visibleHeaderItems]="visibleHeaderItems"
    [hiddenHeaderItems]="hiddenHeaderItems"
    [header]="header"
    [headerReordering]="headerReordering"
    (editModeToggle)="toggleEditMode($event)"></lib-horizontal-sorting-view-mode>
}
