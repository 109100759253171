<ng-container *ngIf="bookingQueue.currentStep.intermediateStepForm">
  <lib-dynamic-form-compact-layout
    [hiddenFieldIdentifierList]="['opener_obj_id[BODY,4]']"
    [group]="
      bookingQueue.currentStep.intermediateStepForm.body[0]
    "></lib-dynamic-form-compact-layout>
</ng-container>

<lib-inline-loader
  *ngIf="
    !bookingQueue.sharedData.availableAppointmentSlots &&
    !bookingQueue.currentStep.intermediateStepForm
  "
  [message]="'shared.appointment-booking.loading-appointments' | translate"></lib-inline-loader>
<lib-date-selection-field
  *ngIf="
    bookingQueue.sharedData.availableAppointmentSlots &&
    !bookingQueue.currentStep.intermediateStepForm
  "
  [dates]="bookingQueue.sharedData.availableAppointmentSlots"
  (select)="onAppointmentSelect.emit($event)"></lib-date-selection-field>
