import { APIError } from '../data/errors.data';
import { LastActivitiesAttributesResource, LastActivity } from '../models/last-activities.model';
import { ReportObject } from '../models/sdapi-object.model';
import { SDAPIObjectMapper } from './sdapi-object.mapper';

export class LastActivitiesMapper {
  public static mapResourceDetails(resource: ReportObject): LastActivity[] {
    const lastActivities: LastActivity[] = [];

    try {
      for (const activity of resource.items) {
        const getAttributeValue = (attribute: string): string => {
          const attributeNames: string[] = SDAPIObjectMapper.mapAttributeNamesAsTableRowIdentifiers(
            resource.attrNames,
          );
          return SDAPIObjectMapper.getTableRowValueByItemIdentifier(
            attributeNames,
            activity.attrValues,
            attribute,
          );
        };

        const lastActivity: LastActivity = {
          date: new Date(getAttributeValue(LastActivitiesAttributesResource.date)),
          type: getAttributeValue(LastActivitiesAttributesResource.type),
          title: getAttributeValue(LastActivitiesAttributesResource.title),
        };

        lastActivities.push(lastActivity);
      }
    } catch (error) {
      throw new APIError('Mapping of last activities failed in the LastActivitiesMapper.', error);
    }

    return lastActivities;
  }
}
