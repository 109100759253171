import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImprintMapper } from 'projects/core/src/lib/mappers/imprint.mapper';
import { TieTableObjectList } from 'projects/core/src/lib/models/sdapi-table-object.model';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Imprint } from '../models/imprint.model';
import { InvokerMethods } from '../models/invoker-body.model';
import { SDAPIService } from './sdapi.service';

@Injectable()
export class ImprintService {
  constructor(
    private sdapiService: SDAPIService,
    private authService: AuthService,
    private imprintMapper: ImprintMapper,
  ) {}

  public getImprint(): Observable<Imprint> {
    return this.authService.determineHeadersForPublicRequest().pipe(
      mergeMap((customHttpHeaders: HttpHeaders | undefined) => this.sdapiService
          .getInvokerByMethodName(
            'PP_IMPRINT_PAGE',
            InvokerMethods.constraintObjectList,
            customHttpHeaders,
          )
          .pipe(
            mergeMap((invoker) =>
              this.sdapiService.invokeMethod<TieTableObjectList>(
                invoker.invoker,
                customHttpHeaders,
              ),
            ),
            map((response: TieTableObjectList) => this.imprintMapper.mapResource(response)),
          )),
    );
  }
}
