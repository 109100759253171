<h3 id="notification-field" *ngIf="changePasswordLink && hintMessageActive">
  {{ 'shared.profile.external-service-hint' | translate }}
</h3>

<ion-list class="card-list">
  <ng-container *ngFor="let field of fields">
    <lib-profile-card-field [field]="field"></lib-profile-card-field>
  </ng-container>
  <ion-item *ngIf="changePasswordLink" id="password-change" class="item-line" lines="none">
    <p>{{ 'general.password' | translate }}</p>
    <a slot="end" (click)="navigateToChangePassword()" class="p">
      {{ 'general.change' | translate }}
      <fa-icon size="xs" slot="end" [icon]="icons.redirect"></fa-icon>
    </a>
  </ion-item>
  <ion-item *ngIf="authCockpitLink" id="auth-cockpit" class="item-line" lines="none">
    <p>{{ 'shared.profile.two-factor-authentication' | translate }}</p>
    <a
      slot="end"
      class="p"
      [href]="authCockpitLink"
      [target]="openAuthCockpitBlank ? '_blank' : '_self'">
      {{ 'general.change' | translate }}
      <fa-icon size="xs" slot="end" [icon]="icons.redirect"></fa-icon>
    </a>
  </ion-item>
  <ion-item
    *ngIf="userDeactivationForm"
    class="item-line"
    lines="none"
    (click)="openUserDeactivationModal()">
    <p>{{ 'general.account' | translate }}</p>
    <ion-button size="small" color="danger" slot="end" class="ion-no-margin">
      {{ 'general.deactivate' | translate }}
    </ion-button>
  </ion-item>
  @if (autoIdentVisibility) {
    <lib-account-information-auto-ident-section></lib-account-information-auto-ident-section>
  }
</ion-list>
