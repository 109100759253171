import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  isDevMode,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import {
  HttpCacheGuard,
  KeySerializer,
  provideHttpCache,
  withHttpCacheInterceptor,
} from '@ngneat/cashew';
import { TranslateModule } from '@ngx-translate/core';
import { PortalHttpCacheGuard } from 'projects/core/src/lib/guards/http-cache.guard';
import { WINDOW } from 'projects/core/src/lib/injection-tokens';
import { AlertOnHttpErrorInterceptor } from 'projects/core/src/lib/interceptors/alert-on-http-error.interceptor';
import { ApiBaseUrlInterceptor } from 'projects/core/src/lib/interceptors/api-base-url.interceptor';
import { PublicUserInterceptor } from 'projects/core/src/lib/interceptors/public-user.interceptor';
import { ReplaceDevUrlInterceptor } from 'projects/core/src/lib/interceptors/replace-dev-url-interceptor.interceptor';
import { UnauthorizedInterceptor } from 'projects/core/src/lib/interceptors/unauthorized.interceptor';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { DomSanitizerService } from 'projects/core/src/lib/services/dom-sanitizer.service';
import { ExpensesService } from 'projects/core/src/lib/services/expenses.service';
import { HttpCacheKeySerializer } from 'projects/core/src/lib/services/http-cache-key.serializer';
import { MarkdownService } from 'projects/core/src/lib/services/markdown.service';
import { MedicalCertificatesService } from 'projects/core/src/lib/services/medical-certificates.service';
import { ErrorLoggingHandler } from './interceptors/error-logging.handler';
import { About } from './models/about.model';
import { AboutService } from './services/about.service';
import { AftercareService } from './services/aftercare.service';
import { AlertHandlerActions } from './services/alert-handler.service';
import { AppUpdateService } from './services/app-update.service';
import { AppointmentBookingService } from './services/appointment-booking.service';
import { AppointmentsService } from './services/appointments.service';
import { AuthRedirectService } from './services/auth-redirect.service';
import { AuthService } from './services/auth.service';
import { BehaviorInvokerService } from './services/behaviour-invoker.service';
import { BreakpointService } from './services/breakpoint.service';
import { ClientConfigService } from './services/client-config.service';
import { DocumentsService } from './services/documents.service';
import { DownloadService } from './services/download.service';
import { DynamicButtonsService } from './services/dynamic-buttons.service';
import { DynamicFormService } from './services/dynamic-form.service';
import { FormService } from './services/form.service';
import { IheDocumentsService } from './services/ihe-documents.service';
import { ImprintService } from './services/imprint.service';
import { ItemFilterService } from './services/item-filter.service';
import { ItemSearcherService } from './services/item-searcher.service';
import { ItemSorterService } from './services/item-sorter.service';
import { LabelService } from './services/label.service';
import { LanguageService } from './services/language.service';
import { LastActivitiesService } from './services/last-activities.service';
import { LastVisitedPatientsService } from './services/last-visited-patients.service';
import { LoadingService } from './services/loading.service';
import { ModalActionService } from './services/modal-action.service';
import { OrdersService } from './services/orders.service';
import { PageHeaderService } from './services/page-header.service';
import { PatientService } from './services/patient.service';
import { PDFService } from './services/pdf.service';
import { PlatformFixes } from './services/platform-fixes.service';
import { PopupService } from './services/popup.service';
import { PrivacyPolicyService } from './services/privacy-policy.service';
import { ProfileFormService } from './services/profile-form.service';
import { ProfileSettingsService } from './services/profile-settings.service';
import { ProfileService } from './services/profile.service';
import { RequiredActionsService } from './services/required-actions.service';
import { RouterService } from './services/router.service';
import { SDAPIService } from './services/sdapi.service';
import { StorageService } from './services/storage.service';
import { TableDataService } from './services/table-data.service';
import { TableModalService } from './services/table-modal.service';
import { TableOrganizerService } from './services/table-organizer.service';
import { ThemeService } from './services/theme.service';
import { TreatmentsService } from './services/treatment.service';
import { UploadService } from './services/upload.service';
import { UserAgreementService } from './services/user-agreement.service';
import { UserProfileService } from './services/user-profile.service';
import { VideoCallService } from './services/video-call.service';
import { VirtualScrollService } from './services/virtual-scroll.service';
import { LaboratoryResultsService } from 'projects/core/src/lib/services/laboratory-results.service';

const MODULES = [IonicModule, TranslateModule];

const SERVICES = [
  provideHttpClient(
    withInterceptors([
      withHttpCacheInterceptor(),
      ApiBaseUrlInterceptor,
      PublicUserInterceptor,
      UnauthorizedInterceptor,
      AlertOnHttpErrorInterceptor,
      ...(isDevMode() || !(window as any).isJarDeployment ? [ReplaceDevUrlInterceptor] : []),
    ]),
  ),
  provideHttpCache({ strategy: 'implicit', ttl: 3000 }),
  {
    provide: HttpCacheGuard,
    useClass: PortalHttpCacheGuard,
  },
  {
    provide: KeySerializer,
    useClass: HttpCacheKeySerializer,
  },
  AftercareService,
  LanguageService,
  {
    provide: APP_INITIALIZER,
    useFactory: (languageService: LanguageService) => (): Promise<void> =>
      languageService.configureLanguage(),
    deps: [LanguageService],
    multi: true,
  },
  AboutService,
  {
    provide: APP_INITIALIZER,
    useFactory: (aboutService: AboutService) => (): Promise<About> => aboutService.loadAbout(),
    deps: [AboutService],
    multi: true,
  },
  ThemeService,
  {
    provide: APP_INITIALIZER,
    useFactory: (themeService: ThemeService) => (): Promise<boolean> => themeService.loadTheme(),
    deps: [ThemeService],
    multi: true,
  },
  {
    provide: APP_BASE_HREF,
    useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
    deps: [PlatformLocation],
  },
  AlertHandlerActions,
  AlertService,
  AppointmentBookingService,
  AppointmentsService,
  AppUpdateService,
  AuthRedirectService,
  AuthService,
  BehaviorInvokerService,
  BreakpointService,
  ClientConfigService,
  DocumentsService,
  DomSanitizerService,
  DownloadService,
  DynamicButtonsService,
  DynamicFormService,
  ExpensesService,
  FormService,
  IheDocumentsService,
  ImprintService,
  ItemFilterService,
  ItemSearcherService,
  ItemSorterService,
  LabelService,
  LaboratoryResultsService,
  LastActivitiesService,
  LastVisitedPatientsService,
  LoadingService,
  MarkdownService,
  MedicalCertificatesService,
  ModalActionService,
  OrdersService,
  PageHeaderService,
  PatientService,
  PDFService,
  PlatformFixes,
  PopupService,
  PrivacyPolicyService,
  ProfileFormService,
  ProfileService,
  ProfileSettingsService,
  RequiredActionsService,
  RouterService,
  SDAPIService,
  StorageService,
  TableDataService,
  TableModalService,
  TableOrganizerService,
  ThemeService,
  TreatmentsService,
  UploadService,
  UserAgreementService,
  UserProfileService,
  VideoCallService,
  VirtualScrollService,
];

const OTHER_PROVIDERS = [
  { provide: WINDOW, useValue: window },
  {
    provide: ErrorHandler,
    useClass: ErrorLoggingHandler,
  },
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...SERVICES, ...OTHER_PROVIDERS],
    };
  }
}
