<ion-list class="card-list items">
  @for (menuItem of defaultMenu; track menuItem.url) {
    @if (menuItem.visibility) {
      <ion-item
        [class.menu-item-warning]="shouldShowNotification(menuItem.idSuffix)"
        class="menu-item"
        button
        lines="none"
        [routerLink]="menuItem.url"
        routerDirection="root"
        (click)="handleMenuItemClick(menuItem)">
        <fa-icon size="lg" slot="start" [icon]="menuItem.icon"></fa-icon>
        <ion-label>{{ menuItem.title }}</ion-label>
        @if (shouldShowNotification(menuItem.idSuffix)) {
          <fa-icon slot="end" [icon]="icons.circleExclamation"></fa-icon>
        }
      </ion-item>
    }
  }

  @for (menuItem of customMenu; track menuItem.url) {
    @if (menuItem.target === 'iframe') {
      <ion-item
        button
        class="menu-item"
        lines="none"
        [routerLink]="['/portal', 'external', menuItem.idSuffix]"
        routerDirection="root"
        routerLinkActive="active-link">
        <fa-icon size="lg" slot="start" [icon]="menuItem.icon"></fa-icon>
        <ion-label class="ion-no-margin trim-text-inline"> {{ menuItem.title }} </ion-label>
      </ion-item>
    } @else if (menuItem.target === 'tab') {
      <ion-item button class="menu-item" lines="none" (click)="openExternalUrl(menuItem)">
        <fa-icon size="lg" slot="start" [icon]="menuItem.icon"></fa-icon>
        <ion-label class="ion-no-margin trim-text-inline"> {{ menuItem.title }} </ion-label>
      </ion-item>
    }
  }
</ion-list>
