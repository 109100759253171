import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DocumentDetails, DocumentFormat } from 'projects/core/src/lib/models/documents.model';
import {
  DownloadDetails,
  DynamicButton,
  DynamicButtonSetConfiguration,
} from 'projects/core/src/lib/models/dynamic-button.model';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-document-viewer',
  templateUrl: './document-viewer.component.html',
  host: { class: 'like-ion-content large-modal' },
})
export class DocumentViewerComponent implements OnInit {
  @Input() document: DocumentDetails;

  configuration: DynamicButtonSetConfiguration;
  translations: Object;

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private translateService: TranslateService,
  ) {}

  get isDownloadableDocumentFormat(): boolean {
    return (
      this.document.fileType !== DocumentFormat.HTML &&
      this.document.fileType !== DocumentFormat.LINK &&
      this.document.fileType !== DocumentFormat.DICOM
    );
  }

  async ngOnInit(): Promise<void> {
    this.translations = await firstValueFrom(this.translateService.get('shared'));
    this.configuration = this.constructButtonContainerConfiguration();
    this.authService.authCheck();
  }

  async dismissModalWithActionEvent(invoker: Invoker): Promise<void> {
    await this.modalController.dismiss(
      { invoker, actionButtons: this.document.actionButtons },
      OverlayEventRole.actionButtonEvent,
    );
  }

  private constructButtonContainerConfiguration(): DynamicButtonSetConfiguration {
    const actionButtons: DynamicButton[] = this.document.actionButtons || [];
    if (this.document.fileType !== DocumentFormat.LINK) {
      actionButtons.push(this.constructDownloadButton());
    }
    return {
      actionButtons,
      triggerButton: {
        label: this.translations['forms']['options'],
        color: 'primary',
        disabled: false,
        dataApiId: 'activity-change',
      },
    };
  }

  private constructDownloadButton(): DynamicButton {
    const href: string = this.document.viewLink || this.document.downloadLink;
    const downloadDetails: DownloadDetails = new DownloadDetails(this.document.fileName, href);
    return new DynamicButton(this.translations['documents']['download'], null, downloadDetails);
  }
}
