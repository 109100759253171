import { Component, OnInit } from '@angular/core';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';

@Component({
  selector: 'lib-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss'],
})
export class SessionTimeoutComponent implements OnInit {
  landingpageActive = false;

  constructor(private clientConfigService: ClientConfigService) {}

  ngOnInit(): void {
    const clientConfig: ClientConfig = this.clientConfigService.get();
    this.landingpageActive = clientConfig.activeModules.landingpage;
  }
}
