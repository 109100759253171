import { Component, OnInit } from '@angular/core';
import { AboutService } from 'projects/core/src/lib/services/about.service';

@Component({
  selector: 'lib-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss'],
})
export class AppVersionComponent implements OnInit {
  version: string;

  constructor(private aboutService: AboutService) {}

  ngOnInit() {
    this.version = this.aboutService.get().appVersion;
  }
}
