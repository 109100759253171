import { LocaleDatePipe } from 'projects/theme/src/lib/pipes/locale-date.pipe';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import jsPDF from 'jspdf';
import { firstValueFrom } from 'rxjs';
import { LabelData10cm } from '../models/pdf.model';

declare let bwipjs: any;

@Injectable()
export class PDFService {
  constructor(
    private localeDatePipe: LocaleDatePipe,
    private translateService: TranslateService,
  ) {}

  public async get10cmLabel(labelData: LabelData10cm): Promise<Blob> {
    const pdfTranslations: string = await firstValueFrom(
      this.translateService.get('core.label-print-pdf'),
    );

    const width = 100;
    const height = 40;

    const document = new jsPDF({
      orientation: width < height ? 'portrait' : 'landscape',
      unit: 'mm',
      format: [width, height],
    });

    document.setFontSize(9);
    document.setFont('courier', 'normal');

    for (const [i, sample] of Object.entries(labelData.sampleNames)) {
      document.text(
        document
          .splitTextToSize(
            labelData.patientsGivenName || pdfTranslations['undefined-data-fallback'],
            34,
          )
          .slice(0, 4),
        21,
        5,
        {
          lineHeightFactor: 1,
        },
      );

      document.text(
        document
          .splitTextToSize(
            labelData.patientsFamilyName || pdfTranslations['undefined-data-fallback'],
            34,
          )
          .slice(0, 4),
        57,
        5,
        {
          lineHeightFactor: 1,
        },
      );

      document.text(
        labelData.patientsBirthDate
          ? this.localeDatePipe.transform(labelData.patientsBirthDate)
          : pdfTranslations['undefined-data-fallback'],
        21,
        18,
      );

      const bottomSectionOffset = 23;
      const bottomSectionMargin = 3;

      document.text(
        document
          .splitTextToSize(
            labelData.contactId || pdfTranslations['undefined-data-fallback'],
            width - bottomSectionMargin * 2,
          )
          .slice(0, 2),
        bottomSectionMargin,
        bottomSectionOffset,
        {
          lineHeightFactor: 1,
        },
      );

      if (labelData.orderNumber) {
        document.addImage(this.getDatamatrixDataURL(labelData.orderNumber), 'PNG', 3, 3, 15, 0);
        document.text(
          document
            .splitTextToSize(labelData.orderNumber, width - bottomSectionMargin * 2)
            .slice(0, 2),
          bottomSectionMargin,
          6.5 + bottomSectionOffset,
          {
            lineHeightFactor: 1,
          },
        );
      } else {
        document.setLineWidth(0.3);
        document.rect(3, 3, 15, 15);
        document.setFontSize(7);
        document.text('ID', 6, 8.5);
        const splitText = document.splitTextToSize(pdfTranslations['data-not-available'], 10);
        document.text(splitText, 6, 12.5);
        document.setFontSize(9);
        document.text(
          pdfTranslations['undefined-order-number'],
          bottomSectionMargin,
          6.5 + bottomSectionOffset,
        );
      }

      const sampleName = sample || pdfTranslations['undefined-data-fallback'];
      const sampleString =
        labelData.sampleNames.length > 1
          ? this.indexOutOf(i, labelData.sampleNames.length) + sampleName
          : sampleName;

      document.text(
        document.splitTextToSize(sampleString, width - bottomSectionMargin * 2).slice(0, 2),
        bottomSectionMargin,
        13 + bottomSectionOffset,
        {
          lineHeightFactor: 1,
        },
      );

      if (i < (labelData.sampleNames.length - 1).toString()) {
        document.addPage();
      }
    }

    return document.output('blob');
  }

  private indexOutOf(index: string | number, length: string | number): string {
    return `${parseInt(index.toString(), 10) + 1}/${length} – `;
  }

  private getDatamatrixDataURL(message: string): string {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    return bwipjs
      .toCanvas(canvas, {
        bcid: 'datamatrix',
        text: message,
        scale: 3,
        height: 10,
        width: 10,
      })
      .toDataURL();
  }
}
