import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faChevronRight, faVideo } from '@fortawesome/free-solid-svg-icons';
import {
  AppointmentDetails,
  TemplateAppointmentsGroup,
  ViewAppointmentsGroup,
} from 'projects/core/src/lib/models/appointments.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';

@Component({
  selector: 'lib-appointment-item',
  templateUrl: './appointment-item.component.html',
  styleUrl: './appointment-item.component.scss',
})
export class AppointmentItemComponent {
  @Output() onSelectAppointment: EventEmitter<AppointmentDetails> =
    new EventEmitter<AppointmentDetails>();

  @Input() widgetView = false;
  @Input() selectedDate: Date;
  @Input() selectedAppointment: AppointmentDetails;
  @Input() mappedAppointmentGroups: ViewAppointmentsGroup;
  @Input() appointmentGroup: TemplateAppointmentsGroup;

  readonly icons = {
    chevronRight: faChevronRight,
    date: faCalendar,
    video: faVideo,
  };

  constructor(private breakpointService: BreakpointService) {}

  get isMobile(): boolean {
    return this.breakpointService.isBelow('md');
  }

  public isFutureAppointment(date: Date): boolean {
    return date.getTime() >= new Date(new Date().toDateString()).getTime();
  }

  public selectAppointment(appointment: AppointmentDetails): void {
    this.onSelectAppointment.emit(appointment);
  }

  public isSelectedAppointment(id: number): boolean {
    return this.selectedAppointment?.id == id;
  }

  isCompactView(): boolean {
    const isMobileCalendarView: boolean = this.isMobile && !this.widgetView;
    const isWidgetInSmallScreen: boolean = this.widgetView && this.breakpointService.isBelow('xl');

    return isMobileCalendarView || isWidgetInSmallScreen;
  }

  get iconSize(): SizeProp {
    return this.isCompactView() ? 'sm' : 'lg';
  }
}
