<pp-base-field [item]="item" [customHtmlId]="customHtmlId" [input]="input" [preview]="preview">
  <ng-container inputPart>
    <input
      [id]="customHtmlId"
      type="number"
      [(ngModel)]="item.value.value"
      [name]="item.identifier.originalValue"
      [placeholder]="placeholderKey | translate"
      [disabled]="!isEditableField"
      #input="ngModel"
      [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
      [attr.aria-required]="item.required"
      step="1"
      inputmode="numeric" />
    <button
      (click)="decrementNumber()"
      class="hide-on-condensed"
      role="button"
      type="button"
      tabindex="0"
      [ariaLabel]="'shared.forms.decrement-number' | translate"
      *ngIf="isEditableField && !item.identifier.isFloat">
      <fa-icon [icon]="numberFieldIcons.minus" slot="icon-only"></fa-icon>
    </button>
    <button
      (click)="incrementNumber()"
      class="hide-on-condensed"
      role="button"
      type="button"
      tabindex="0"
      [ariaLabel]="'shared.forms.increment-number' | translate"
      *ngIf="isEditableField && !item.identifier.isFloat">
      <fa-icon [icon]="numberFieldIcons.plus" slot="icon-only"></fa-icon>
    </button>
  </ng-container>
</pp-base-field>
