import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ClientConfigService } from './client-config.service';
import { LanguageService } from './language.service';
import { WINDOW } from 'projects/core/src/lib/injection-tokens';

@Injectable()
export class AuthRedirectService {
  constructor(
    private clientConfigService: ClientConfigService,
    private languageService: LanguageService,
    private authService: AuthService,
    @Inject(WINDOW) private window: Window,
  ) {}

  public redirectToAuthService(url: string, postRedirectUrl: string = '/portal'): void {
    const clientSlug: string = this.authService.getCurrentAuthApplicationKey(
      this.clientConfigService.get(),
    );
    const languageCode: string = this.languageService.getCurrentLanguageCode();
    const locationUrl = `/auth-service/${url}?application=${clientSlug}&path=${postRedirectUrl}&lang=${languageCode}`;
    this.window.location.replace(locationUrl);
  }

  redirectToRegistration(postRedirectUrl?: string) {
    this.redirectToAuthService('register/form', postRedirectUrl);
  }

  redirectToLogin(postRedirectUrl?: string) {
    this.redirectToAuthService('authenticate/start', postRedirectUrl);
  }
}
