import { Component } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { BaseFieldComponent } from 'projects/shared/src/lib/components/form/base-field/base-field.component';

@Component({
  selector: 'lib-text-area-input-field',
  templateUrl: './text-area-field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TextAreaFieldComponent extends BaseFieldComponent {}
