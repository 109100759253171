<lib-modal-header
  [title]="requiredAction.title"
  [style]="isDownloadableDocumentFormat ? 'view' : 'edit'">
  @if (isDownloadableDocumentFormat) {
    <lib-document-details-popover button [document]="requiredAction">
    </lib-document-details-popover>
  }
</lib-modal-header>
<ion-content class="ion-no-padding ion-no-margin">
  <div class="required-action" *ngIf="requiredAction">
    <ion-row class="top">
      <ion-col size="12" class="ion-no-padding">
        <lib-document-details-viewer
          (commitTaskButtonsVisibility)="commitButtonsVisibility($event)"
          [viewDocument]="requiredAction">
        </lib-document-details-viewer>
      </ion-col>
    </ion-row>
    <div
      *ngIf="commitTaskButtonVisibility && translations"
      class="button-section ion-justify-content-end">
      <lib-action-buttons-container
        [config]="commitActionSheetConfig"
        (actionTrigger)="closeModalWithCommitDataMessage($event)">
      </lib-action-buttons-container>
    </div>
  </div>
</ion-content>
