import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import {
  DataType,
  DynamicDataField,
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
} from '../models/form.model';
import { Invoker } from '../models/invoker-body.model';
import { TieFormObject } from '../models/sdapi-form-object.model';

@Injectable()
export class DynamicFormService {
  constructor(
    private http: HttpClient,
    private dynamicFormMapper: DynamicFormMapper,
  ) {}

  public getDynamicForm(
    dynamicFormConfiguration: DynamicFormConfiguration,
  ): Observable<DynamicForm> {
    switch (dynamicFormConfiguration.type) {
      case DynamicFormType.TASK:
      case DynamicFormType.CHAT:
      case DynamicFormType.TREATMENT_CREATE:
        return this.getForm(dynamicFormConfiguration);
      case DynamicFormType.TASK_WITH_ACTIONS:
        return this.getForm(dynamicFormConfiguration, true);
      case DynamicFormType.STATIC_PATIENT_CREATE:
        return this.getFormByInvoker(dynamicFormConfiguration.invoker);
      default:
        return of(dynamicFormConfiguration.dynamicForm);
    }
  }

  public getForm(
    dynamicFormConfiguration: DynamicFormConfiguration,
    skipInvokers?: boolean,
  ): Observable<DynamicForm> {
    return this.http.get<TieFormObject>(`${dynamicFormConfiguration.activityURL}`).pipe(
      map((result: TieFormObject) => {
        return this.dynamicFormMapper.mapDynamicFormResource(
          result,
          dynamicFormConfiguration.activityURL,
          this.isInteractiveDynamicForm(dynamicFormConfiguration),
          skipInvokers,
        );
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      }),
    );
  }

  private isInteractiveDynamicForm(dynamicFormConfiguration: DynamicFormConfiguration): boolean {
    return (
      dynamicFormConfiguration.type !== DynamicFormType.VIEW &&
      dynamicFormConfiguration.isInteractive
    );
  }

  private getFormByInvoker(invoker: Invoker): Observable<DynamicForm> {
    return this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker).pipe(
      map((result: TieFormObject) =>
        this.dynamicFormMapper.mapDynamicFormResource(
          result,
          `/${invoker.invoker.objId}/${invoker.invoker.activityId}/0`,
        ),
      ),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      }),
    );
  }

  public getDynamicViewFormWithActionButtons(
    primaryInvoker: Invoker,
    actionInvokers: Invoker[],
  ): Observable<DynamicForm> {
    return this.fetchViewForm(primaryInvoker).pipe(
      map((form: DynamicForm) => {
        form.actionButtons = SDAPIObjectMapper.mapInvokersToDynamicButtons(actionInvokers);
        return form;
      }),
    );
  }

  public fetchViewForm(invoker: Invoker): Observable<DynamicForm> {
    return this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker).pipe(
      map((response: TieFormObject) => {
        const activityUrl: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
        return this.dynamicFormMapper.mapDynamicFormResource(response, activityUrl, false, true);
      }),
    );
  }

  public static isDisplayableFieldType(item: DynamicDataField): boolean {
    return (
      !DynamicFormService.isFieldType(item, DataType.unknown) &&
      !DynamicFormService.isFieldType(item, DataType.hidden) &&
      item.visible
    );
  }

  public static isDisplayableGroupType(group: DynamicDataField): boolean {
    return DynamicFormService.isFieldType(group, DataType.grouping) && group.visible;
  }

  public static isFieldType(item: DynamicDataField, type: string): boolean {
    return item.type === DataType[type];
  }

  public static hideFormItemsOfTypeGrid(form: DynamicForm): DynamicForm {
    form.body.forEach((group: DynamicDataField) => {
      group.fieldGroup.forEach((item: DynamicDataField) => {
        item.visible = !DynamicFormService.isFieldType(item, DataType.grid);
      });
    });

    return form;
  }
}
