export type Logo = {
  url?: string;
  padding?: string;
  paddingMobile?: string;
};

export type Color = {
  selector: ColorSelector;
  value: string;
};

export type FontFace = {
  selector: FontSelector;
  url?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  textAlign?: FontAlign;
};

export enum FontAlign {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify',
}

export type Theme = {
  logo?: Logo;
  color?: Color[];
  font?: FontFace[];
  properties?: ThemeProperty[];
  css?: string;
};

export type ThemeProperty = {
  selector: ThemePropertySelector;
  value: string;
};

export enum FontSelector {
  p = 'p',
  p1 = 'p1',
  p2 = 'p2',
  p3 = 'p3',
  p4 = 'p4',
  h = 'h',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  labelInput = 'label-input',
  a = 'a',
  pMobile = 'p-mobile',
  p1Mobile = 'p1-mobile',
  p2Mobile = 'p2-mobile',
  p3Mobile = 'p3-mobile',
  p4Mobile = 'p4-mobile',
  hMobile = 'h-mobile',
  h1Mobile = 'h1-mobile',
  h2Mobile = 'h2-mobile',
  h3Mobile = 'h3-mobile',
  h4Mobile = 'h4-mobile',
  h5Mobile = 'h5-mobile',
}

export enum ColorSelector {
  primary = 'primary',
  secondary = 'secondary',
  greyish = 'greyish',
  warn = 'warn',
  info = 'info',
}

export enum ThemePropertySelector {
  small = 'radius-small',
  medium = 'radius-medium',
  button = 'radius-button',
  card = 'radius-card',
}
