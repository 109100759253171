import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SDAPIResponseObject } from 'projects/core/src/lib/models/sdapi-object.model';
import { TreatmentDetails, TreatmentSegment } from 'projects/core/src/lib/models/treatment.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { VirtualScrollService } from 'projects/core/src/lib/services/virtual-scroll.service';

@Component({
  selector: 'lib-treatment-details',
  templateUrl: './treatment-details.component.html',
  styleUrls: ['./treatment-details.component.scss'],
})
export class TreatmentDetailsComponent {
  @Output() refreshTreatment = new EventEmitter<void>();
  @Output() documentUploaded = new EventEmitter<SDAPIResponseObject>();
  @Output() openTreatmentNotes = new EventEmitter<void>();

  @Input() treatment: TreatmentDetails;

  segment: typeof TreatmentSegment = TreatmentSegment;
  activeSegment: TreatmentSegment = TreatmentSegment.INFORMATION;

  private _isLoading: boolean;

  constructor(
    public breakpoint: BreakpointService,
    public virtualScrollService: VirtualScrollService,
  ) {}

  get shouldShowInformationSection(): boolean {
    return (
      !!this.treatment?.information?.length ||
      this.treatment?.hasNotesFeature ||
      !!this.treatment?.details?.length
    );
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Input() set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    if (!this._isLoading) {
      this.setActiveSegment(isLoading);
    }
  }

  activateDocumentSection(): void {
    this.activeSegment = TreatmentSegment.DOCUMENTS;
  }
  private setActiveSegment(isLoading: boolean): void {
    const hasInformationDetails: boolean = !!this.treatment?.details?.length;
    const hasInformationDocuments: boolean = !!this.treatment?.information?.length;

    if (hasInformationDetails || hasInformationDocuments || !isLoading) {
      this.activeSegment = TreatmentSegment.INFORMATION;
    } else {
      this.activeSegment = TreatmentSegment.DOCUMENTS;
    }
  }
}
