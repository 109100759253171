<lib-generic-table-container
  class="stretch-container fill-height"
  [data]="data"
  [isDataLoading]="isDataLoading"
  [isRowDataLoading]="isRowDataLoading"
  (eventMessage)="resolveEventMessage($event)">
  <lib-auto-layout actionButtonCollection class="toolbar-item card-layout" [gap]="[1]">
    <ion-button
      color="primary"
      (click)="createOrder()"
      data-api-id="order-type-list"
      class="ion-no-margin">
      <fa-icon slot="end" [icon]="icons.card"></fa-icon>
      <span class="ion-hide-md-down">{{ 'shared.orders.new-order' | translate }}</span>
      <span class="ion-hide-md-up">{{ 'general.new' | translate }}</span>
    </ion-button>
  </lib-auto-layout>
</lib-generic-table-container>
