<ion-item lines="none" *ngIf="translations">
  <div class="row-container stretch-container">
    <div class="title-container">
      <h5 class="group-title" lang="de">
        <span *ngIf="item">{{ item.name }}</span>
        <span>{{ hasRequiredItemInGroup(form.body[index].fieldGroup) ? ' *' : '' }}</span>
      </h5>
      <div class="label-container">
        <div class="validation-label" *ngIf="hasInvalidFields(ngForm, index)">
          {{ translations['invalid-input'] }}
        </div>
      </div>
    </div>
    <ng-container *ngFor="let bar of getProgressBarData(index); trackBy: trackBy">
      <div
        class="field-group-progress"
        *ngIf="bar.visibility"
        [class.complete]="bar.progressValue === 1">
        <ion-progress-bar
          class="progress-bar"
          [class.progress-bar-colorful]="bar.id === 1 && bar.progressValue !== 1"
          [value]="bar.progressValue">
        </ion-progress-bar>
        <span *ngIf="bar.progressValue !== 1" class="p3 trim-text-inline">{{
          bar.onProgressText
        }}</span>
        <span *ngIf="bar.progressValue === 1" class="p3 trim-text-inline">{{
          bar.onSuccessText
        }}</span>
      </div>
    </ng-container>
  </div>
  <fa-icon *ngIf="!isFieldGroupEmpty(index)" slot="end" [icon]="ICONS.FORWARD"></fa-icon>
  <div
    slot="end"
    class="spacer"
    [class.active-button-indicator]="activeGroupNumber === index"></div>
</ion-item>
