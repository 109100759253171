import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem, WriteFileResult } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { LoadingService } from './loading.service';

@Injectable()
export class DownloadService {
  renderer: Renderer2;
  translations: any;
  constructor(
    private rendererFactory: RendererFactory2,
    private loadingService: LoadingService,
    private translateService: TranslateService,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    void this.initializeTranslations();
  }

  public async initializeTranslations(): Promise<void> {
    this.translations = await firstValueFrom(this.translateService.get('shared.documents'));
  }

  public async handleDownload(fileName: string, href: string, blob?: Blob): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await this.handleDownloadOnNativePlatform(fileName, href);
    } else {
      const url = blob ? URL.createObjectURL(blob) : href;
      await this.handleDownloadOnWebPlatform(fileName, url);
    }
  }

  private async handleDownloadOnWebPlatform(fileName: string, url: string): Promise<void> {
    const link = this.renderer.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.download = fileName;
    link.click();
    link.remove();
  }

  private async handleDownloadOnNativePlatform(fileName: string, href: string): Promise<void> {
    try {
      await this.loadingService.load(this.translations['file-is-being-downloaded']);
      const fetchedBlob = await fetch(href).then((r) => r.blob());
      const base64 = await this.convertBlobToBase64(fetchedBlob);
      const fileResult: WriteFileResult = await this.writeFileToCache(fileName, base64);
      await this.loadingService.stop();
      await this.shareFile(fileResult, fileName);
      await this.deleteFileFromCache(fileName);
      await this.loadingService.toast(this.translations['file-was-downloaded-successfully']);
    } catch (error: any) {
      if (!error?.message?.includes('canceled')) {
        await this.loadingService.toast(this.translations['error-downloading-file']);
      }
      throw error;
    } finally {
      await this.loadingService.stop();
    }
  }

  private async writeFileToCache(fileName: string, base64: string): Promise<WriteFileResult> {
    return Filesystem.writeFile({
      path: `${fileName}`,
      data: base64.split(',')[1],
      directory: Directory.Cache,
    });
  }

  private async shareFile(fileResult: WriteFileResult, fileName: string): Promise<void> {
    await Share.share({
      files: [fileResult.uri],
      title: fileName,
    });
  }

  private async deleteFileFromCache(fileName: string): Promise<void> {
    try {
      await Filesystem.deleteFile({
        path: `${fileName}`,
        directory: Directory.Cache,
      });
    } catch (error) {
      console.warn('Error deleting file', error);
    }
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  }
}
