import { Inject, Injectable } from '@angular/core';
import {
  ActiveModules,
  CLIENT_CONFIG,
  ClientAuthConfig,
  ClientConfig,
  TermsOfServiceConfig,
} from 'projects/core/src/lib/models/client.model';

@Injectable()
export class ClientConfigService {
  constructor(@Inject(CLIENT_CONFIG) private clientConfig: ClientConfig) {}

  get(): ClientConfig {
    return this.clientConfig;
  }

  static chooseClientConfigByHostname(
    clientConfigs: ClientConfig[],
    hostname: string,
  ): ClientConfig {
    for (const clientConfig of clientConfigs) {
      if (clientConfig.domains.includes(hostname)) {
        return this.mergeClientConfigWithDefault(clientConfig);
      }
    }
    return new ClientConfig();
  }

  private static mergeClientConfigWithDefault(clientConfig: ClientConfig): ClientConfig {
    const tenantClientConfig: ClientConfig = Object.assign(new ClientConfig(), clientConfig);

    if (clientConfig.authConfig) {
      tenantClientConfig.authConfig = Object.assign(
        new ClientAuthConfig(),
        clientConfig.authConfig,
      );
    }

    if (clientConfig.termsOfServiceConfig) {
      tenantClientConfig.termsOfServiceConfig = Object.assign(
        new TermsOfServiceConfig(),
        clientConfig.termsOfServiceConfig,
      );
    }

    if (clientConfig.activeModules) {
      tenantClientConfig.activeModules = Object.assign(
        new ActiveModules(),
        clientConfig.activeModules,
      );
    } else {
      tenantClientConfig.adaptDeprecatedActiveModulesProperties();
    }

    return tenantClientConfig;
  }
}
