import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ViewDocumentDetails } from 'projects/core/src/lib/models/documents.model';

@UntilDestroy()
@Component({
  selector: 'lib-video-viewer',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoViewerComponent implements OnInit {
  @Output() commitButtonsVisibility = new EventEmitter<boolean>();
  @Input() video: ViewDocumentDetails;
  shouldShowVideoPlayer = false;
  fileSizeThreshold = 50000000;

  ICONS = {
    play: faPlay,
  };

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    if (!this.video.size || this.video.size < this.fileSizeThreshold) {
      this.showPlayer();
    }
  }

  showPlayer(): void {
    this.shouldShowVideoPlayer = true;
  }

  async close(): Promise<boolean> {
    return this.modalController.dismiss('data', 'close');
  }
}
