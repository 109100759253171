import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { ClientConfig } from '../models/client.model';

export const TermsOfServicePageGuard: CanActivateFn = async (): Promise<boolean | UrlTree> => {
  const clientConfigService: ClientConfigService = inject(ClientConfigService);
  const router: Router = inject(Router);
  const clientConfig: ClientConfig = clientConfigService.get();

  if (clientConfig.termsOfServiceConfig.active) {
    return Promise.resolve(true);
  } else {
    await router.navigateByUrl('/portal');
    return Promise.resolve(false);
  }
};
