import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  DynamicDataField,
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
} from 'projects/core/src/lib/models/form.model';
import {
  ProfileTranslationKeys,
  TranslationOptions,
} from 'projects/core/src/lib/models/modal-action.model';
import { ProfileFieldKey } from 'projects/core/src/lib/models/profile.model';
import { LanguageService } from 'projects/core/src/lib/services/language.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { ProfileFormService } from 'projects/core/src/lib/services/profile-form.service';
import { ProfileService } from 'projects/core/src/lib/services/profile.service';
import { firstValueFrom, take } from 'rxjs';

@Component({
  selector: 'lib-profile-card-wrapper',
  templateUrl: './profile-card-wrapper.component.html',
  styleUrls: ['./profile-card-wrapper.component.scss'],
})
@UntilDestroy()
export class ProfileCardWrapperComponent {
  @Output() profileDataUpdate = new EventEmitter<string>();

  @Input() title: string;
  @Input() isLoading = true;
  @Input() fieldKeys: ProfileFieldKey[];
  @Input() groupIndex: number;
  @Input() hasEditableFields: boolean;

  readonly icon = {
    edit: faPenToSquare,
  };

  constructor(
    private profileService: ProfileService,
    private popupService: PopupService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private profileFormService: ProfileFormService,
  ) {}

  async openEditProfileModal(): Promise<void> {
    await this.loadingService.load(
      await firstValueFrom(this.translateService.get('shared.profile.opening-form')),
    );
    this.profileService
      .getProfileEditForm()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((form: DynamicForm) => {
        const filteredForm: DynamicForm = this.getFilteredForm(form);
        const mappedForm = this.mapFieldGridValues(filteredForm);
        this.showFormModalAndUpdateProfileOnSave(mappedForm);
      });
  }

  private async showFormModalAndUpdateProfileOnSave(filteredForm: DynamicForm): Promise<void> {
    await this.popupService
      .showDynamicFormModal(this.getDynamicFormConfiguration(filteredForm))
      .then(async (response) => {
        if (response.role === OverlayEventRole.save) {
          this.configureLanguage(filteredForm);
          this.profileDataUpdate.emit();
        }
        await this.loadingService.stop();
      });
  }

  private configureLanguage(form: DynamicForm): void {
    this.languageService.syncCurrentLanguageWithProfileForm(form);
  }

  private getDynamicFormConfiguration(form: DynamicForm): DynamicFormConfiguration {
    const translationOptions: TranslationOptions = {
      keys: ProfileTranslationKeys,
      successMessageKey: 'save-completion',
      actionInProgressKey: 'saving-in-progress',
    };
    return new DynamicFormConfiguration({
      type: DynamicFormType.DIRECT_SAVE,
      dynamicForm: form,
      translationOptions,
    });
  }

  private getFilteredForm(form: DynamicForm): DynamicForm {
    form.body = this.profileFormService.filterFormFields(form, this.fieldKeys, this.groupIndex);
    form.body = this.removeEmptyFieldGroups(form);
    return form;
  }

  private removeEmptyFieldGroups(form: DynamicForm): DynamicDataField[] {
    return form.body.filter((group: DynamicDataField) => group.fieldGroup.length);
  }

  private mapFieldGridValues(form: DynamicForm): DynamicForm {
    form.body.forEach((group: DynamicDataField) => {
      group.fieldGroup.forEach((field: DynamicDataField, index) => {
        field.rowNum = index + 2;
        field.colSpan = 1;
        field.colNum = 1;
      });
    });
    return form;
  }
}
