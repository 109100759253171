import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleInfo, faFilter, faXmark } from '@fortawesome/free-solid-svg-icons';
import {
  DocumentDetails,
  DocumentFilter,
  DocumentFilterName,
  Subtotal,
} from 'projects/core/src/lib/models/documents.model';
import { FilterParams, SearchParams, SortParams } from 'projects/core/src/lib/models/shared.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { ItemFilterService } from 'projects/core/src/lib/services/item-filter.service';
import { ItemSearcherService } from 'projects/core/src/lib/services/item-searcher.service';
import { ItemSorterService } from 'projects/core/src/lib/services/item-sorter.service';

@Component({
  selector: 'lib-documents-browser',
  templateUrl: './documents-browser.component.html',
  styleUrls: ['./documents-browser.component.scss'],
})
export class DocumentsBrowserComponent implements OnInit {
  @Output() documentListReload: EventEmitter<void> = new EventEmitter<void>();

  @Input() set documents(documents: DocumentDetails[]) {
    this.documentsList = documents;
  }

  @Input() set loading(isLoading: boolean) {
    this.isLoading = isLoading;
    this.refreshViewItems();
  }

  readonly icons = {
    faCircleInfo,
    faFilter,
    faFilterDelete: faXmark,
  };

  documentsList: DocumentDetails[];
  viewList: DocumentDetails[] = [];
  isLoading = true;

  filtersPreset: DocumentFilter[] = [];
  filterParams = new FilterParams();
  searchParams = new SearchParams();
  sortParams = new SortParams();
  isFiltersContextOpen = false;
  subtotals: Subtotal[] = [];

  constructor(
    private itemFilterService: ItemFilterService,
    private itemSearcherService: ItemSearcherService,
    private breakpointService: BreakpointService,
  ) {
    this.filterParams.field = 'type';
    this.searchParams.fields = ['name', 'date', 'size', 'type'];
    this.sortParams.field = 'date';
  }

  ngOnInit(): void {
    this.filtersPreset = this.itemFilterService.generateDocumentsFilters();
  }

  refreshViewItems(): void {
    this.viewList = [];
    if (!this.isLoading && this.documentsList) {
      if (this.subtotals.length === 0) {
        this.getSubtotals();
      }
      const filteredItems = this.itemFilterService.filter(this.documentsList, this.filterParams);
      const searchedItems = this.itemSearcherService.search(filteredItems, this.searchParams);
      this.viewList = ItemSorterService.sort(searchedItems, this.sortParams);
    }
  }

  get maxRowAmountOfCard(): number {
    const offset = this.breakpointService.isAbove('sm') ? 220 : 265;
    return Math.floor((window.innerHeight - offset) / 58);
  }

  getVirtualHeightOfPopover(): string {
    const itemSize = 40;
    return this.isVirtualHeightMaxOfPopoverExceeded()
      ? 'unset'
      : `${Math.floor(this.getMaxRowAmountOfPopover() * itemSize)}px`;
  }

  isVirtualHeightMaxOfPopoverExceeded(): boolean {
    return this.filtersPreset.length < this.getMaxRowAmountOfPopover();
  }

  getMaxRowAmountOfPopover(): number {
    return Math.floor((window.innerHeight - 187) / 45);
  }

  getSubtotals(): void {
    this.filtersPreset.forEach((filter: DocumentFilter) => {
      const subtotal = new Subtotal();
      subtotal.category = filter.name;
      subtotal.quantity = this.documentsList.filter(
        (document) => document.type == filter.name,
      ).length;
      this.subtotals.push(subtotal);
    });
  }

  toggleFilterButtonState(): void {
    this.isFiltersContextOpen = !this.isFiltersContextOpen;
  }

  removeFilter(filterToBeRemoved: DocumentFilterName): void {
    this.filtersPreset.find((filter: DocumentFilter) => filter.name == filterToBeRemoved).selected =
      false;
    this.onFilterChange();
  }

  isFilterSet(): boolean {
    return this.filtersPreset.some((filter: DocumentFilter) => filter.selected);
  }

  onFilterChange(): void {
    this.filterParams.filters = [];
    this.filtersPreset.forEach((filter: DocumentFilter) => {
      if (filter.selected) {this.filterParams.filters.push(filter.name);}
    });
    this.refreshViewItems();
  }
}
