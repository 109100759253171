<ion-list>
  <ion-item (click)="dateSelected()" button="true" detail="false">
    <ion-icon slot="start" name="calendar-outline" size="small"></ion-icon>
    <ion-label> {{ 'shared.forms.choose-date' | translate }} </ion-label>
  </ion-item>
  <ion-item (click)="timeSelected()" button="true" detail="false" lines="none">
    <ion-icon slot="start" name="time-outline" size="small"></ion-icon>
    <ion-label>
      {{ 'shared.forms.choose-time' | translate }}
    </ion-label>
  </ion-item>
</ion-list>
