@if (!isLoading && hasRequiredActions) {
  <ion-card color="primary" class="no-margin" [ngClass]="layoutClass" id="required-actions-card">
    <ion-card-header>
      <h2 data-api-id="required-action-list">
        <fa-icon [icon]="icons.task" size="sm"></fa-icon>
        {{ 'required-actions.action-required' | translate }}
      </h2>
    </ion-card-header>
    <ion-card-content>
      <div class="stretch-self grid-layout">
        @for (requiredAction of requiredActions; track requiredAction.id) {
          <lib-required-action-card
            [class.element-loading-overlay]="requiredAction.processing"
            [requiredAction]="requiredAction"
            (openRequiredAction)="showRequiredAction(requiredAction)"
            (viewTaskInformation)="showTaskInformation(requiredAction)">
          </lib-required-action-card>
        }
      </div>
    </ion-card-content>
  </ion-card>
} @else if (isLoading) {
  <ion-card class="no-margin col-1" color="primary" id="loading-skeleton-card">
    <ion-card-header>
      <h2>
        <fa-icon [icon]="icons.task" size="sm"></fa-icon>
        {{ 'required-actions.action-required' | translate }}
      </h2>
    </ion-card-header>
    <ion-card-content>
      <ion-skeleton-text class="rounded-card" animated></ion-skeleton-text>
    </ion-card-content>
  </ion-card>
} @else {
  <ion-card color="primary" class="no-margin no-required-actions col-1" id="required-actions-card">
    <ion-card-header>
      <h2>
        <fa-icon [icon]="icons.check" size="sm"></fa-icon>
        {{ 'shared.required-action.everything-done' | translate }}
      </h2>
    </ion-card-header>
    <ion-card-content>
      <ion-text class="ion-text-center">
        <p>{{ 'shared.required-action.no-action-required-description' | translate }}</p>
      </ion-text>
    </ion-card-content>
  </ion-card>
}
