import { Component, Input } from '@angular/core';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RequiredActionsDetails } from 'projects/core/src/lib/models/required-actions.model';

@Component({
  selector: 'lib-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss'],
})
@UntilDestroy()
export class TasksListComponent {
  @Input() requiredActionsList: RequiredActionsDetails[];

  readonly ICONS = {
    warn: faCircleExclamation,
  };

  get pastRequiredActions(): RequiredActionsDetails[] {
    return this.requiredActionsList.filter((requiredAction) => requiredAction.isOverdue);
  }

  get futureRequiredActions(): RequiredActionsDetails[] {
    return this.requiredActionsList.filter((requiredAction) => !requiredAction.isOverdue);
  }
}
