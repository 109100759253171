import { Component, Input, OnInit } from '@angular/core';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';

@Component({
  selector: 'lib-terms-of-service-button',
  templateUrl: './terms-of-service-button.component.html',
  styleUrls: ['./terms-of-service-button.component.scss'],
})
export class TermsOfServiceButtonComponent implements OnInit {
  @Input()
  containerize: boolean;

  consolidatedAvb: boolean;

  constructor(
    private popupService: PopupService,
    private clientConfigService: ClientConfigService,
  ) {}

  ngOnInit(): void {
    const clientConfig: ClientConfig = this.clientConfigService.get();
    this.consolidatedAvb = clientConfig.consolidatedAvb;
  }

  async openTos() {
    await this.popupService.showTosModal();
  }
}
