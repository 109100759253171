import { ErrorHandler } from '@angular/core';

export class ErrorLoggingHandler extends ErrorHandler {
  override handleError(error: any) {
    const loggedErrors = [];
    loggedErrors.push(this.checkAndLogHttpEmptyError(error));
    loggedErrors.push(this.checkAndLogAPIErrors(error));

    if (this.checkIfErrorNotLoggedYet(loggedErrors)) {
      console.error('Logging unknown or generic error', error);
    }
  }

  private checkAndLogHttpEmptyError(error: any): boolean {
    if (error?.name !== 'EmptyError') {
      return false;
    }
    if (error?.stack) {
      console.error(error.stack);
      return true;
    }
    return false;
  }

  private checkAndLogAPIErrors(error: any) {
    if (error?.name !== 'API Error') {
      return false;
    }

    console.error(error);
    let errorStack = error.parentError;
    let errorCounter = 0;
    while (errorStack && errorCounter < 5) {
      console.log(errorStack);
      errorStack = errorStack.parentError;
      errorCounter++;
    }

    return true;
  }

  private checkIfErrorNotLoggedYet(loggedErrors: boolean[]) {
    return !loggedErrors.includes(true);
  }
}
