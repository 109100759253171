import { InvokerBody } from './invoker-body.model';

export type MedicalCertificatesWidgetRow = {
  name: string;
  dateFrom: string;
  dateTo: string;
  duration: string;
  status: string;
  behaviourInvokers?: InvokerBody[];
  id: string;
};

export enum MedicalCertificateTypeIdentifier {
  name = 'sys_object.descr',
  dateFrom = 'sys_attribute.d1',
  dateTo = 'sys_attribute.d2',
  duration = 'sys_attribute.t2',
  status = 'status',
}
