import { UIError } from '../data/errors.data';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { DynamicDataField, SelectionObject } from './form.model';
import { AttributeValue, AttributeValueCollection, FormItem } from './sdapi-form-object.model';

export interface MultiChosenData {
  collector: MultiChosenCollector;
  options: DynamicDataField;
}

export interface MultiChosenCollectorData {
  title: string;
  identifier: string;
  header: string[];
  collection: DynamicDataField[][];
  collectionTemplate: CollectionTemplateResource;
}

export interface CollectionTemplateResource {
  items: FormItem[];
  attributeValueCollection: AttributeValueCollection;
  indexedAttributeValues: AttributeValue[];
}

export class MultiChosenCollector implements MultiChosenCollectorData {
  title: string;
  identifier: string;
  header: string[];
  collection: DynamicDataField[][];
  readonly collectionTemplate: CollectionTemplateResource;

  constructor(data: MultiChosenCollectorData) {
    this.title = data.title;
    this.identifier = data.identifier;
    this.header = data.header;
    this.collection = data.collection;
    this.collectionTemplate = data.collectionTemplate;
  }

  addRow(row: SelectionObject): void {
    try {
      const modifiedAttributeValues: AttributeValueCollection = {
        ...this.collectionTemplate.attributeValueCollection,
      };
      const firstItemAttributeName = this.collectionTemplate.items[0].attributeName;
      const firstItem = modifiedAttributeValues[firstItemAttributeName];
      firstItem.displayValue = row.value;
      firstItem.internalValue = row.identifier;

      const newRow: DynamicDataField[] = DynamicFormMapper.mapResourceFormItemsToDynamicFormFields(
        this.collectionTemplate.items,
        modifiedAttributeValues,
      );

      this.collection.push(newRow);
    } catch (error) {
      new UIError('Failed to add row to MultiChosenCollector.', error);
    }
  }

  removeRow(index: number): void {
    try {
      this.collection.splice(index, 1);
    } catch (error) {
      new UIError('Failed to remove row from MultiChosenCollector.', error);
    }
  }

  clearRows(): void {
    try {
      this.collection = [];
    } catch (error) {
      new UIError('Failed to clear rows from MultiChosenCollector.', error);
    }
  }
}
