import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable } from 'rxjs';

@Pipe({
  name: 'blobToBase64',
})
export class BlobToBase64Pipe implements PipeTransform {
  transform(blob: Blob): Observable<string | ArrayBuffer> {
    return from(this.blobToBase64(blob));
  }

  private async blobToBase64(blob: Blob): Promise<string | ArrayBuffer> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
