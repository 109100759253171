<ion-grid class="swiper-container">
  <ion-row>
    <ion-col>
      <swiper id="onboarding-swiper" [config]="config" (activeIndexChange)="setIcon()" #swiper>
        <ng-container *ngIf="slideCollection">
          <ng-template class="slide" *ngFor="let slide of slideCollection" swiperSlide>
            <lib-swiper-slide-content [slide]="slide"></lib-swiper-slide-content>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="!slideCollection">
          <ng-template class="slide" *ngFor="let skeleton of skeletonCountAsArray" swiperSlide>
            <div class="skeleton stretch-self">
              <ion-skeleton-text class="image" animated></ion-skeleton-text>
              <div class="description">
                <ion-skeleton-text class="rounded-small like-h1" animated></ion-skeleton-text>
                <ion-skeleton-text class="rounded-small like-h2" animated></ion-skeleton-text>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </swiper>

      <div class="pagination"></div>

      <ion-button
        *ngIf="!isStart"
        [disabled]="!slideCollection"
        color="primary"
        class="nav prev"
        (click)="slide('prev')">
        <fa-icon size="lg" [icon]="icons.prev"></fa-icon>
      </ion-button>

      <ion-button
        [disabled]="!slideCollection"
        color="primary"
        class="nav next"
        (click)="slide('next')">
        <fa-icon size="lg" [icon]="icons.next"></fa-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>

<div class="fade left" (click)="slide('prev')"></div>
<div class="fade right" (click)="slide('next')"></div>
