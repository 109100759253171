import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { MenuItem } from 'projects/shared/src/lib/models/menu.model';
import { HeaderConfiguration } from 'projects/theme/src/lib/components/header/header.model';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class PageHeaderService {
  private headerUpdate = new ReplaySubject<HeaderConfiguration>(1);
  public headerObservable: Observable<HeaderConfiguration> = this.headerUpdate.asObservable();

  constructor(private router: Router) {}

  updateHeader(header: HeaderConfiguration): void {
    this.headerUpdate.next(header);
  }

  public updateCustomPageHeaderIfActive(customMenu: MenuItem[]): void {
    const activeCustomMenuItem: MenuItem = this.findActiveCustomMenuItem(customMenu);
    if (activeCustomMenuItem) {
      this.updateCustomPageHeader(activeCustomMenuItem);
    } else {
      this.updateHeader(new HeaderConfiguration());
    }
  }

  private findActiveCustomMenuItem(customMenu: MenuItem[]) {
    return customMenu.find((item: MenuItem) => {
      const itemPath = `/portal/external/${item.idSuffix}`;
      return itemPath === this.router.routerState.snapshot.url;
    });
  }

  private updateCustomPageHeader(customMenuItem: MenuItem): void {
    const configuration: HeaderConfiguration = this.constructCustomPageHeaderConfig(customMenuItem);
    this.updateHeader(configuration);
  }

  private constructCustomPageHeaderConfig(customMenuItem: MenuItem): HeaderConfiguration {
    return {
      title: { extended: customMenuItem.title },
      icon: customMenuItem.icon,
    } as HeaderConfiguration;
  }

  private getDeepestRouteSnapshot(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    while (snapshot.firstChild) {
      snapshot = snapshot.firstChild;
    }
    return snapshot;
  }

  public retrieveRouteData(route: ActivatedRouteSnapshot): HeaderConfiguration {
    return (
      this.getDeepestRouteSnapshot(route).data['headerConfig'] ||
      this.getDeepestRouteSnapshot(route).data
    );
  }
}
