import { Component } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BaseFieldComponent } from 'projects/shared/src/lib/components/form/base-field/base-field.component';

@Component({
  selector: 'lib-number-input-field',
  templateUrl: './number-field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class NumberFieldComponent extends BaseFieldComponent {
  readonly numberFieldIcons = {
    plus: faPlus,
    minus: faMinus,
  };

  incrementNumber(): void {
    this.item.value.value = `${this.numberValue + 1}`;
  }

  decrementNumber(): void {
    this.item.value.value = `${this.numberValue - 1}`;
  }

  private get numberValue(): number {
    return parseInt(this.item.value.value || '0', 10);
  }
}
