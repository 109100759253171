import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faInfoCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import { LocaleDatePipe } from 'projects/theme/src/lib/pipes/locale-date.pipe';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { LanguageService } from 'projects/core/src/lib/services/language.service';

@Component({
  selector: 'lib-date-selection-field',
  templateUrl: './date-selection-field.component.html',
  styleUrls: ['./date-selection-field.component.scss'],
})
export class DateSelectionFieldComponent implements OnInit {
  @Input() dates: SelectionDate[] = [];

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  ICONS = {
    required: faLock,
    info: faInfoCircle,
    showMoreTimeslots: faClock,
  };

  filteredDates: SelectionDate[] = [];
  date: string;
  selectedId: string;
  selectedDate: string;
  today: Date = new Date();
  showAllTimeslots = false;
  timeslotsForDate: SelectionDate[] = [];
  firstAvailableDate: string = new Date().toISOString();
  lastAvailableDate: string;

  dateHasAvailableSlot = (dateString: string) => {
    const calendarDate = new Date(dateString);
    return this.getAvailableDates().some(
      (date) => date.toISOString().substring(0, 10) === calendarDate.toISOString().substring(0, 10),
    );
  };

  constructor(
    private localeDatePipe: LocaleDatePipe,
    public languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.filterAndSortDates();
    this.calculateFirstAvailableDate();
    this.calculateLastAvailableDate();
  }

  private filterAndSortDates() {
    this.filteredDates = this.filterAndSortAvailableDates();
  }

  private calculateFirstAvailableDate(): void {
    this.firstAvailableDate =
      this.filteredDates.length > 0 ? this.filteredDates[0].time : new Date().toISOString();
  }

  private calculateLastAvailableDate(): void {
    const upcomingDatesDesc: SelectionDate[] = this.filteredDates.slice().reverse();
    this.lastAvailableDate = upcomingDatesDesc.length > 0 ? upcomingDatesDesc[0].time : undefined;
  }

  private filterAndSortAvailableDates(): SelectionDate[] {
    const onlyUpcomingDates = this.dates.filter(
      (date: SelectionDate) => new Date(date.time) > new Date(),
    );
    return onlyUpcomingDates.sort(sortByDateAsc);
  }

  getAvailableDates(): Date[] {
    return this.dates.map((date) => new Date(date.time));
  }

  getSelectedDate(): string {
    return this.localeDatePipe.transform(
      new Date(this.dates.find((date) => date.id === this.selectedId)?.time),
      'dd.MM.yyyy HH:mm',
    );
  }

  dateSelected(selectedDate: any) {
    this.timeslotsForDate = [];
    this.selectedDate = selectedDate.detail.value.substring(0, 10);
    this.showAllTimeslots = false;
    this.loadTimeslots();
  }

  private loadTimeslots() {
    this.timeslotsForDate = this.dates
      .filter((date: SelectionDate) => date.time.substring(0, 10) === this.selectedDate)
      .sort(sortByDateAsc);
  }

  selectAppointment(id: string) {
    this.selectedId = id;
    this.select.emit(id);
  }
}

const sortByDateAsc = (a: SelectionDate, b: SelectionDate) => {
  if (!a && !b) {return 0;}
  if (!a) {return -1;}
  if (!b) {return 1;}

  return new Date(a.time).getTime() - new Date(b.time).getTime();
};

export type SelectionDate = {
  id: string;
  time: string;
};
