<ion-datetime
  [value]="value"
  [name]="name"
  (ionChange)="onTimeChange($event)"
  (ionCancel)="onCancel()"
  [showDefaultButtons]="true"
  doneText="{{ 'general.confirm' | translate }}"
  cancelText="{{ 'general.cancel' | translate }}"
  presentation="time"
  slot="content"
  [locale]="translateService.currentLang"
  color="medium"
  class="datetime-time">
</ion-datetime>
