import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';

const linksToReplace = [
  'https://2-link.tie.ch',
  'https://kundenportal.tie.ch',
  'https://3de7pmuom2dnj93g0q5rocaevijg5fer.tie.ch',
];

export const ReplaceDevUrlInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => next(req).pipe(
    map((response: any) => {
      if (response?.body) {
        response.body = replaceUrls(response.body);
      }
      return response;
    }),
  );

const replaceUrls = (body: any): any => {
  let stringifiedBody = JSON.stringify(body);
  for (const link of linksToReplace) {
    stringifiedBody = stringifiedBody.replace(link, window.location.origin);
  }
  return JSON.parse(stringifiedBody);
};
