import { Component, Input } from '@angular/core';
import {
  DataType,
  DynamicDataField,
  SelectionObject,
} from 'projects/core/src/lib/models/form.model';
import { LocaleDatePipe } from 'projects/theme/src/lib/pipes/locale-date.pipe';

@Component({
  selector: 'lib-profile-card-field',
  templateUrl: './profile-card-field.component.html',
  styleUrls: ['./profile-card-field.component.scss'],
})
export class ProfileCardFieldComponent {
  @Input() field: DynamicDataField;

  constructor(private localeDatePipe: LocaleDatePipe) {}

  isDisplayableItem(field: DynamicDataField): boolean {
    const allowedDataTypes: DataType[] = [
      DataType.text,
      DataType.number,
      DataType.date,
      DataType.radio,
      DataType.select,
    ];
    return allowedDataTypes.includes(field.type);
  }

  resolveValue(field: DynamicDataField): string {
    switch (field.type) {
      case DataType.date:
        return this.localeDatePipe.transform(
          field.value.value,
          field.formatOptions?.formattedString,
        );
      case DataType.radio:
      case DataType.select:
        return this.getSelectionFieldValue(field);
      default:
        return field.value.value;
    }
  }

  private getSelectionFieldValue(field: DynamicDataField): string {
    if (field.value.value) {
      return field.value.options.find((selection: SelectionObject) => selection.identifier === field.value.value).value;
    }
    return '';
  }
}
