import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { ViewDocumentDetails } from 'projects/core/src/lib/models/documents.model';
import { DocumentsService } from 'projects/core/src/lib/services/documents.service';
import { DownloadService } from 'projects/core/src/lib/services/download.service';

@Component({
  selector: 'lib-download-viewer',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadViewerComponent {
  @Input() document: ViewDocumentDetails;
  @Output() commitButtonsVisibility = new EventEmitter<boolean>();
  cancelButtonVisibility = true;

  ICONS = {
    download: faDownload,
  };

  constructor(
    private modalController: ModalController,
    private documentService: DocumentsService,
    private downloadService: DownloadService,
  ) {}

  get showLargeFileSizeWarning(): boolean {
    return this.documentService.checkForBigFileSize(this.document);
  }

  async close(): Promise<boolean> {
    return this.modalController.dismiss('data', 'close');
  }

  async downloadFile(document: ViewDocumentDetails): Promise<void> {
    await this.downloadService.handleDownload(
      document.fileName,
      document.viewLink || document.downloadLink,
    );
  }
}
