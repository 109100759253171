import { Injectable } from '@angular/core';
import { AlertHandler } from '../models/alert.model';

@Injectable()
export class AlertHandlerActions {
  static confirmable: AlertHandler = {
    buttons: [{ text: 'OK' }],
  };

  static appUpdateAction: AlertHandler = {
    buttons: [
      {
        text: 'general.update',
        role: 'confirm',
      },
      {
        text: 'general.cancel',
        role: 'cancel',
        cssClass: 'ion-color-cancel-button',
      },
    ],
  };
}
