import { APIError } from '../data/errors.data';
import {
  DataType,
  DynamicDataField,
  DynamicForm,
  FormIdentifier,
  PrefillAttributeNamesMap,
} from '../models/form.model';
import { AttributeValue } from '../models/sdapi-form-object.model';
import { SystemAttributeName } from '../models/shared.model';

export class DynamicFormPrefillMapper {
  public static mapData(
    dynamicForm: DynamicForm,
    prefillMap: PrefillAttributeNamesMap,
  ): DynamicForm {
    try {
      dynamicForm.body.forEach((group: DynamicDataField) => {
        group.fieldGroup.forEach((field: DynamicDataField) => {
          const matchingIdentifier: FormIdentifier = Array.from(prefillMap.keys()).find(
            (identifier: FormIdentifier) =>
              field.prefillIdentifier.isEqualTo(new SystemAttributeName(identifier)),
          );

          if (matchingIdentifier) {
            const prefillData: AttributeValue = prefillMap.get(matchingIdentifier);
            if (this.checkDataValidation(field, prefillData)) {
              field.value.value = prefillData.internalValue;
            } else {
              delete field.prefillIdentifier;
            }
          }
        });
      });

      return dynamicForm;
    } catch (error) {
      throw new APIError('Dynamic Form Prefill Mapping failed.', error);
    }
  }

  private static checkDataValidation(
    formField: DynamicDataField,
    prefillData: AttributeValue,
  ): boolean {
    if ([DataType.radio, DataType.select].includes(formField.type)) {
      if (
        !formField.value.options
          .map((option) => option.identifier)
          .includes(prefillData.internalValue)
      ) {
        console.warn(
          `Field '${formField.prefillIdentifier}' can't be filled with provided data: '${prefillData.internalValue}'. Selection Option doesn't exist.`,
        );
        return false;
      }
    }
    if (formField.value.limiters) {
      if (!this.limitersValidation(formField, prefillData)) {
        return false;
      }
    }
    return !!prefillData.internalValue;
  }

  private static limitersValidation(
    formField: DynamicDataField,
    prefillData: AttributeValue,
  ): boolean {
    if (formField.value.limiters.min) {
      const length: number = parseInt(formField.value.limiters.min, 10);
      if (!isNaN(length) && prefillData.internalValue.length <= length) {
        console.warn(
          `Field '${formField.prefillIdentifier}' can't be filled with provided data: '${prefillData.internalValue}'. Value is to short.`,
        );
        return false;
      }
    }
    if (formField.value.limiters.max) {
      const length: number = parseInt(formField.value.limiters.max, 10);
      if (!isNaN(length) && prefillData.internalValue.length >= length) {
        console.warn(
          `Field '${formField.prefillIdentifier}' can't be filled with provided data: '${prefillData.internalValue}'. Value is to long.`,
        );
        return false;
      }
    }
    return true;
  }
}
