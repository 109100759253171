import {
  Directive,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[onDimensionChange]',
})
export class OnDimensionChangeDirective implements OnInit, OnDestroy {
  @Output() onDimensionChange: EventEmitter<Dimensions> = new EventEmitter<Dimensions>();

  dimensions: Dimensions = { width: 0, height: 0 };
  observer: ResizeObserver;

  constructor(
    private elementReference: ElementRef,
    private angularZone: NgZone,
  ) {}

  ngOnInit() {
    const performResizeObservation = (entries: ResizeObserverEntry[]) => {
      this.angularZone.run(() => {
        const contentRect = entries[0]?.contentRect;
        if (contentRect) {
          this.dimensions.width = contentRect.width;
          this.dimensions.height = contentRect.height;
          this.onDimensionChange.emit(this.dimensions);
        } else {
          console.warn(
            'Invalid dimensions: ',
            contentRect,
            '\nof Element: ',
            this.elementReference,
          );
        }
      });
    };
    this.observer = new ResizeObserver(performResizeObservation);
    this.observer.observe(this.elementReference.nativeElement);
  }

  ngOnDestroy() {
    this.observer?.unobserve(this.elementReference.nativeElement);
  }
}

export type Dimensions = {
  width: number;
  height: number;
};
