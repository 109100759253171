import { IconDefinition } from '@fortawesome/angular-fontawesome/types';
import { DynamicDataField, DynamicForm } from './form.model';

export class BookingQueue<T> {
  private steps: Step[];
  private currentStepIndex = 0;

  sharedData: T;

  constructor(steps: Step[], sharedData: T) {
    this.sharedData = sharedData;
    this.steps = steps;
  }

  back(): Step {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.currentStep.trigger?.();
      return this.steps[this.currentStepIndex];
    }
    return null;
  }

  backTo(index: number): Step {
    if (index >= 0 && index <= this.currentStepIndex) {
      this.currentStepIndex = index;
      this.currentStep.trigger?.();
      return this.steps[this.currentStepIndex];
    }
    return null;
  }

  next(): { previous: Step; current: Step } {
    if (this.currentStepIndex < this.steps.length - 1) {
      this.currentStepIndex++;
      this.currentStep.trigger?.();
      return {
        previous: this.steps[this.currentStepIndex - 1],
        current: this.steps[this.currentStepIndex],
      };
    } else if (this.isLast) {
      this.currentStep.trigger?.();
    }
    return null;
  }

  get isLast(): boolean {
    return this.currentStepIndex === this.steps.length - 1;
  }

  get isFirst(): boolean {
    return this.currentStepIndex === 0;
  }

  get past(): Step[] {
    return this.currentStepIndex > 0 ? this.steps.slice(0, this.currentStepIndex) : null;
  }

  get currentStep(): Step {
    return this.steps[this.currentStepIndex];
  }

  get currentFormGroup(): DynamicDataField {
    return this.currentStep?.dynamicForm?.body?.[this.currentStep?.groupIndex];
  }

  get followingSteps(): Step[] {
    return this.currentStepIndex < this.steps.length
      ? this.steps.slice(this.currentStepIndex + 1, this.steps.length)
      : null;
  }

  get nextSteps(): Step {
    return this.currentStepIndex < this.steps.length - 1
      ? this.steps[this.currentStepIndex + 1]
      : null;
  }

  get allSteps(): Step[] {
    return this.steps;
  }
}

export type Step = {
  title: string;
  icon: IconDefinition;
  type: StepTypes;
  keepEditable: boolean;
  dynamicForm?: DynamicForm;
  groupIndex?: number;
  resultGroupIndex?: number;
  info?: string;
  hasIntermediateStep?: boolean;
  intermediateStepForm?: DynamicForm;
  shouldInjectPatientData?: boolean;
  trigger?: () => any;
};

export enum StepTypes {
  form,
  dynamicDate,
  information,
}
