import { Injectable } from '@angular/core';
import { SortDirection, SortParams } from '../models/shared.model';

@Injectable()
export class ItemSorterService {
  public static sort(data: any[], sortParams: SortParams): any[] {
    if (!sortParams.field) {return data;}
    const processedData = [...data];
    return processedData.sort((a, b) => {
      if (sortParams.direction === SortDirection.ASC) {
        return ItemSorterService.getNestedValue(sortParams.field, a) <
          ItemSorterService.getNestedValue(sortParams.field, b)
          ? -1
          : 1;
      } else {
        return ItemSorterService.getNestedValue(sortParams.field, b) <
          ItemSorterService.getNestedValue(sortParams.field, a)
          ? -1
          : 1;
      }
    });
  }

  private static getNestedValue(name: string, object: any) {
    return name.split('.').reduce((parent, child) => parent?.[child], object);
  }
}
