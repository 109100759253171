import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DynamicDataField } from 'projects/core/src/lib/models/form.model';

@Component({
  selector: 'lib-dynamic-form-compact-layout',
  templateUrl: './dynamic-form-compact-layout.component.html',
  styleUrls: ['./dynamic-form-compact-layout.component.scss'],
})
@UntilDestroy()
export class DynamicFormCompactLayoutComponent {
  @Input() group: DynamicDataField;
  @Input() isProcessing = false;
  @Input() hiddenFieldIdentifierList: string[] = [];

  public isVisibleField(field: DynamicDataField): boolean {
    return (
      !this.hiddenFieldIdentifierList.includes(field.identifier.originalValue) && field.visible
    );
  }
}
