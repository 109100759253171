import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { DynamicDataField, DynamicForm } from 'projects/core/src/lib/models/form.model';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { AutoIdentService } from 'projects/core/src/lib/services/auto-ident.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { firstValueFrom, throwError } from 'rxjs';

@Component({
  selector: 'lib-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.scss'],
})
export class AccountInformationComponent {
  @Input() fields: DynamicDataField[];
  @Input() userDeactivationForm: DynamicForm;
  @Input() changePasswordLink: string;
  @Input() hintMessageActive: boolean;
  @Input() authCockpitLink: string;
  @Input() openAuthCockpitBlank = false;

  clientConfig: ClientConfig;

  readonly icons = {
    redirect: faArrowUpRightFromSquare,
  };

  constructor(
    private popupService: PopupService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    private autoIdentService: AutoIdentService,
  ) {}

  get autoIdentVisibility(): boolean {
    return (
      this.autoIdentService.autoIdentRequired ||
      this.autoIdentService.autoIdentPending ||
      this.autoIdentService.autoIdentSuccess
    );
  }

  navigateToChangePassword(): void {
    window.location.href = this.changePasswordLink;
  }

  async openUserDeactivationModal(): Promise<void> {
    await this.showLoadingAnimation();
    await this.popupService
      .showUserDeactivationModal(this.userDeactivationForm)
      .then(async (response) => {
        if (response.role === OverlayEventRole.save) {
          await this.authService.logout();
          await this.router.navigateByUrl('/');
        }
      })
      .catch((error) => {
        console.error('AccountInformationComponent->openUserDeactivationModal()', error);
        this.loadingService.stop().catch((nestedError) => throwError(() => new Error(nestedError)));
      });
  }

  private async showLoadingAnimation(): Promise<void> {
    const translatedMessage = await firstValueFrom(
      this.translateService.get('shared.profile.opening-form'),
    );
    await this.loadingService.load(translatedMessage);
  }
}
