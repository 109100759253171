import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DocumentFormat } from 'projects/core/src/lib/models/documents.model';
import { DynamicButtonSetConfiguration } from 'projects/core/src/lib/models/dynamic-button.model';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';
import { RequiredActionsDetails } from 'projects/core/src/lib/models/required-actions.model';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-required-action',
  templateUrl: './required-action.component.html',
  styleUrls: ['./required-action.component.scss'],
  host: { class: 'handle-ion-layout large-modal' },
})
export class RequiredActionComponent implements OnInit {
  @Input() requiredAction: RequiredActionsDetails;
  commitTaskButtonVisibility = false;
  documentFormat: typeof DocumentFormat = DocumentFormat;
  translations: Object;

  readonly icons = {
    close: faXmark,
  };

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
    public breakPoint: BreakpointService,
  ) {}

  ngOnInit(): void {
    this.loadTranslations();
    this.authService.authCheck();
  }

  private async loadTranslations(): Promise<void> {
    this.translations = await firstValueFrom(this.translateService.get('shared.forms'));
  }

  commitButtonsVisibility(value: boolean) {
    this.commitTaskButtonVisibility = value;
    this.changeDetector.detectChanges();
  }

  async close(): Promise<boolean> {
    return this.modalController.dismiss({});
  }

  async closeModalWithCommitDataMessage(invoker: Invoker): Promise<void> {
    await this.modalController.dismiss(
      { requiredAction: this.requiredAction, invoker },
      OverlayEventRole.actionButtonEvent,
    );
  }

  get isDownloadableDocumentFormat(): boolean {
    return (
      this.requiredAction.fileType !== this.documentFormat.HTML &&
      this.requiredAction.fileType !== this.documentFormat.DICOM
    );
  }

  get commitActionSheetConfig(): DynamicButtonSetConfiguration {
    return {
      actionButtons: this.requiredAction.actionButtons,
      triggerButton: {
        label: this.translations['send'],
        color: 'primary',
        disabled: false,
        dataApiId: 'commit-form',
      },
    };
  }
}
