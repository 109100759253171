import { FontSelector } from '../models/theme.model';

const {
  p,
  p1,
  p2,
  p3,
  p4,
  pMobile,
  p1Mobile,
  p2Mobile,
  p3Mobile,
  p4Mobile,
  a,
  labelInput,
  h,
  h1,
  h2,
  h3,
  h4,
  h5,
  hMobile,
  h1Mobile,
  h2Mobile,
  h3Mobile,
  h4Mobile,
  h5Mobile,
} = FontSelector;

export const FONTFACE_NAME: Map<FontSelector, string> = new Map([
  [h, 'TF H'],
  [h1, 'TF H1'],
  [h2, 'TF H2'],
  [h3, 'TF H3'],
  [h4, 'TF H4'],
  [h5, 'TF H5'],
  [p, 'TF P'],
  [p1, 'TF P1'],
  [p2, 'TF P2'],
  [p3, 'TF P3'],
  [p4, 'TF P4'],
  [a, 'TF A'],
  [labelInput, 'TF LABEL'],
  [hMobile, 'TF HM'],
  [h1Mobile, 'TF H1M'],
  [h2Mobile, 'TF H2M'],
  [h3Mobile, 'TF H3M'],
  [h4Mobile, 'TF H4M'],
  [h5Mobile, 'TF H5M'],
  [pMobile, 'TF PM'],
  [p1Mobile, 'TF P1M'],
  [p2Mobile, 'TF P2M'],
  [p3Mobile, 'TF P3M'],
  [p4Mobile, 'TF P4M'],
]);

export const FONT_SELECTOR_MAPPING: Map<FontSelector, FontSelector[]> = new Map([
  [p, [p, p1, p2, p4, a, labelInput]],
  [pMobile, [pMobile, p2Mobile, p4Mobile]],
  [h, [h1, h2, h3, h4, h5, p3]],
  [hMobile, [h1Mobile, h2Mobile, h3Mobile, h4Mobile, h5Mobile, p3Mobile]],
]);

export const THEME_PROPERTIES = ['radius-small', 'radius-button', 'radius-card'];
