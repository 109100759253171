import { MimeTypeToDocumentFormatPipe } from 'projects/theme/src/lib/pipes/mime-type-to-document-format.pipe';
import { APIError } from '../data/errors.data';
import { AttributeDetail } from '../models/attribute.model';
import { DocumentFormat } from '../models/documents.model';
import {
  AttributeDisplayDetails,
  OPTIONAL_REQUIRED_ACTIONS_ATTRIBUTE_IDENTIFIER_VALUES,
  RequiredActionsAttributeNames,
  RequiredActionsDetails,
  StatusType,
} from '../models/required-actions.model';
import {
  TieTableObjectList,
  TieTableProcessor,
  TieTableRow,
} from '../models/sdapi-table-object.model';
import { AttributeNameIdentifier } from '../models/shared.model';

export class RequiredActionsMapper {
  public static mapResource(resource: TieTableObjectList): RequiredActionsDetails[] {
    const requiredActionsList: RequiredActionsDetails[] = [];
    const table: TieTableProcessor = new TieTableProcessor(resource);
    const rows: TieTableRow[] = table.getRows();

    try {
      for (const row of rows) {
        const id: string = row.getOptionalAttributeValue(RequiredActionsAttributeNames.id);
        const endDate: string = row.getOptionalAttributeValue(
          RequiredActionsAttributeNames.endDate,
        );
        const startDate: AttributeDisplayDetails = new AttributeDisplayDetails(
          row,
          RequiredActionsAttributeNames.startDate,
        );
        if (
          row.statusInfo.type === StatusType.distribution &&
          RequiredActionsMapper.checkDatesValidity(startDate.value, endDate, id)
        ) {
          requiredActionsList.push(RequiredActionsMapper.mapRowToRequiredAction(id, row, table));
        }
      }
    } catch (error) {
      throw new APIError(
        'Mapping of a list of required actions failed in the RequiredActionsMapper.',
        error,
      );
    }

    return requiredActionsList;
  }

  private static mapRowToRequiredAction(
    id: string,
    row: TieTableRow,
    table: TieTableProcessor,
  ): RequiredActionsDetails {
    const requiredAction = new RequiredActionsDetails();

    requiredAction.id = Number(id);
    requiredAction.title = row.getAttributeValue(RequiredActionsAttributeNames.title);
    requiredAction.task = {
      value: row.getAttributeValue(RequiredActionsAttributeNames.task),
      label: row.getDisplayName(RequiredActionsAttributeNames.task),
    };
    requiredAction.statusName = {
      value: 'statusName',
      label: 'statusName',
    };
    requiredAction.startDate = {
      value: row.getAttributeValue(RequiredActionsAttributeNames.startDate),
      label: row.getDisplayName(RequiredActionsAttributeNames.startDate),
    };
    requiredAction.endDate = {
      value: row.getAttributeValue(RequiredActionsAttributeNames.endDate),
      label: row.getDisplayName(RequiredActionsAttributeNames.endDate),
    };

    requiredAction.details = OPTIONAL_REQUIRED_ACTIONS_ATTRIBUTE_IDENTIFIER_VALUES.flatMap(
      (attributeIdentifier) => {
        const identifier = new AttributeNameIdentifier(attributeIdentifier);
        const value: string = row.getOptionalAttributeValue(identifier.normalizedValue);
        if (value) {
          const detail = new AttributeDetail(null, table, identifier, value);
          return [detail];
        } else {
          return [];
        }
      },
    );
    return requiredAction;
  }

  public static mapResourceOfTypeOther(
    requiredAction: RequiredActionsDetails,
  ): RequiredActionsDetails {
    try {
      if (
        requiredAction.fileType !== DocumentFormat.HTML &&
        requiredAction.fileType !== DocumentFormat.DICOM
      ) {
        const extension = requiredAction.viewLink.split('.').pop();
        requiredAction.fileName = `${requiredAction.title}.${extension}`;
      }

      return requiredAction;
    } catch (error) {
      throw new APIError(
        "Mapping of a required action with a type 'other' failed in the RequiredActionsMapper.",
        error,
      );
    }
  }

  public static mapResourceOfTypeForm(
    requiredAction: RequiredActionsDetails,
    link: string,
    formType: DocumentFormat,
  ): RequiredActionsDetails {
    requiredAction.viewLink = link;
    requiredAction.fileType = formType;

    return requiredAction;
  }

  public static mapMimeTypeOfResourceDetails(
    requiredAction: RequiredActionsDetails,
    contentType: string,
  ): RequiredActionsDetails {
    requiredAction.mimeType = contentType;
    requiredAction.fileType = MimeTypeToDocumentFormatPipe.transformStatic(requiredAction.mimeType);

    return requiredAction;
  }

  private static checkDatesValidity(startDate: string, endDate: string, id: string): boolean {
    return (
      RequiredActionsMapper.isDateValid(startDate, id) &&
      RequiredActionsMapper.isDateValid(endDate, id)
    );
  }

  private static isDateValid(dateString: string, id: string): boolean {
    const date = new Date(dateString);
    const isValid = dateString !== null && !isNaN(date.getTime());
    if (!isValid && id) {
      console.warn(`Required action with id "${id}" has an invalid date value.`);
    }
    return isValid;
  }
}
