import { Injectable } from '@angular/core';
import { AlertController, AlertOptions, LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { AlertBehaviourPresets } from '../data/alert-behavior.data';
import { AlertBehaviour, AlertHandler, AlertMessage } from '../models/alert.model';
import { AlertHandlerActions } from './alert-handler.service';

@Injectable()
export class AlertService {
  private isPresenting = false;

  constructor(
    public alertController: AlertController,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private translateService: TranslateService,
  ) {}

  async presentAlert(
    alertMessage: AlertMessage,
    alertHandler?: AlertHandler,
    alertBehaviour?: AlertBehaviour,
  ): Promise<any> {
    try {
      await this.loadingController.dismiss();
    } catch {}
    if (this.isPresenting) {
      return new Promise<void>((resolve) => resolve());
    }
    this.isPresenting = true;

    await this.translateAlertProperties(alertMessage, alertHandler);
    const alert = await this.createAlert(alertMessage, alertHandler, alertBehaviour);
    await alert.present();

    if (alertBehaviour?.timeout) {
      setTimeout(() => {
        alert.dismiss();
      }, alertBehaviour?.timeout);
    }

    alert.onDidDismiss().then(() => {
      if (alertBehaviour?.dismissOtherOnDismiss) {
        this.modalController.dismiss();
      }
      this.isPresenting = false;
    });

    return alert.onDidDismiss();
  }

  private async createAlert(
    alertMessage: AlertMessage,
    alertHandler?: AlertHandler,
    alertBehaviour?: AlertBehaviour,
  ): Promise<HTMLIonAlertElement> {
    let alertOptions: AlertOptions = { ...alertMessage };

    if (alertHandler) {
      alertOptions = { ...alertOptions, ...alertHandler };
    } else {
      alertOptions = { ...alertOptions, ...AlertHandlerActions.confirmable };
    }

    if (alertBehaviour) {
      alertOptions = { ...alertOptions, ...alertBehaviour };
    } else {
      alertOptions = { ...alertOptions, ...AlertBehaviourPresets.persistent };
    }
    return this.alertController.create(alertOptions);
  }

  private async translateAlertProperties(
    alertMessage: AlertMessage,
    alertHandler: AlertHandler,
  ): Promise<void> {
    alertMessage.header = await this.translateAlertProperty(alertMessage.header);
    alertMessage.message = await this.translateAlertProperty(alertMessage.message);
    alertMessage.subHeader = await this.translateAlertProperty(alertMessage.subHeader, {
      value: alertMessage.value,
    });
    if (alertHandler)
      {for (const button of alertHandler.buttons) {
        button.text = await this.translateAlertProperty(button.text);
      }}
  }

  private async translateAlertProperty(key: string, params?: Object): Promise<string> {
    if (key) {
      return await firstValueFrom(this.translateService.get(key, params));
    }
    return key;
  }
}
