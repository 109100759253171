import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessagesData } from 'projects/core/src/lib/data/alert-messages.data';
import { APIError, ProcessError, UserError } from 'projects/core/src/lib/data/errors.data';
import { DynamicFormPrefillMapper } from 'projects/core/src/lib/mappers/dynamic-form-prefill.mapper';
import {
  EventMessage,
  EventMessageType,
  SkeletonRowSet,
  TableConfiguration,
  TableDataType,
  TableHeaderItem,
  TableList,
  TableListItem,
} from 'projects/core/src/lib/models/dynamic-table.model';
import {
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
  FormIdentifier,
  PrefillAttributeNamesMap,
} from 'projects/core/src/lib/models/form.model';
import {
  AfterCareTranslationKeys,
  TranslationOptions,
} from 'projects/core/src/lib/models/modal-action.model';
import { Patient } from 'projects/core/src/lib/models/patient.model';
import { AttributeNameIdentifier } from 'projects/core/src/lib/models/shared.model';
import { AftercareService } from 'projects/core/src/lib/services/aftercare.service';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-aftercare',
  templateUrl: './aftercare.component.html',
  styleUrls: ['./aftercare.component.scss'],
})
@UntilDestroy()
export class AftercareComponent implements OnInit {
  @Input() patient: Patient;

  @Output() eventMessage: EventEmitter<EventMessage> = new EventEmitter();

  private generalTranslations: Object;
  private aftercareTranslations: Object = {};

  clinics: TableList;
  isLoading = true;

  genericTableContainerComponentStateData = {
    searchParams: { fields: ['columns'], criterions: '' },
    isRowDataLoading: false,
  };

  constructor(
    private aftercareService: AftercareService,
    private patientService: PatientService,
    private popupService: PopupService,
    private loadingService: LoadingService,
    private alertsService: AlertService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.generalTranslations = await firstValueFrom(this.translateService.get('general'));
    this.aftercareTranslations = await firstValueFrom(
      this.translateService.get('shared.aftercare'),
    );
    await this.fetchAllClinics();
  }

  async fetchAllClinics(): Promise<void> {
    this.isLoading = true;
    this.clinics = await firstValueFrom(this.aftercareService.getClinicReport());
    this.isLoading = false;
  }

  get tableConfiguration(): TableConfiguration {
    return {
      table: this.clinics,
      searchParams: this.genericTableContainerComponentStateData.searchParams,
      skeletonRowSet: new SkeletonRowSet({ mobile: 330, desktop: 320 }),
      expandedViewThreshold: 569,
      columnWidth: 200,
    };
  }

  handleEventMessage(eventMessage: EventMessage) {
    switch (eventMessage.type) {
      case EventMessageType.CLICK:
        this.handleAftercareOrderCreationWorkflow();
        break;
      case EventMessageType.RESORTING:
        this.genericTableContainerComponentStateData.isRowDataLoading = eventMessage.data;
        break;
    }
  }

  async handleAftercareOrderCreationWorkflow(): Promise<void> {
    try {
      const aftercareOrderForm: DynamicForm = await this.getAftercareOrderForm();
      await this.checkIfFormIsValidForPatientPrefilling(aftercareOrderForm);
      await this.handlePatientDataInjectionIntoForm(aftercareOrderForm);
      await this.openAftercareOrderFormModal(aftercareOrderForm);
    } catch (error) {
      await this.loadingService.toast(`${this.aftercareTranslations['operation-canceled']}`);
      if (error instanceof UserError) {
        // eslint-disable-next-line no-console
        console.info(error.message);
      } else {
        throw new ProcessError('Aftercare Order creation failed.', error);
      }
    }
  }

  async checkIfFormIsValidForPatientPrefilling(aftercareOrderForm: DynamicForm): Promise<void> {
    const patientIdentifierIsMissing = !aftercareOrderForm.hasPrefillIdentifier(FormIdentifier.PID);
    if (patientIdentifierIsMissing) {
      await this.alertsService.presentAlert(AlertMessagesData.orderTypeIsMissingPatientRelation);
      throw new APIError('Aftercare Order Type is missing patient relation.');
    }
  }

  async handlePatientDataInjectionIntoForm(dynamicForm: DynamicForm): Promise<void> {
    const patientID: string = await this.resolvePatientId();
    const patientPrefillAttributeNames: PrefillAttributeNamesMap = await firstValueFrom(
      this.patientService.getPatientAttributesNamesMap(patientID),
    );

    DynamicFormPrefillMapper.mapData(dynamicForm, patientPrefillAttributeNames);
  }

  async resolvePatientId(): Promise<string> {
    if (this.patient) {
      return this.patient.patientID;
    } else {
      return await this.getPatientIdFromUser();
    }
  }

  async getAftercareOrderForm(): Promise<DynamicForm> {
    await this.loadingService.load(`${this.aftercareTranslations['load-aftercare-order']}`);
    try {
      return await firstValueFrom(this.aftercareService.createAftercareOrder());
    } catch (error) {
      throw new ProcessError(
        'Aftercare Order creation form fetching failed in Aftercare Component.',
        error,
      );
    }
  }

  async openAftercareOrderFormModal(dynamicForm: DynamicForm): Promise<void> {
    await this.loadingService.load(`${this.aftercareTranslations['creating-aftercare-order']}`);

    const translationOptions: TranslationOptions = {
      keys: AfterCareTranslationKeys,
      successMessageKey: 'create-completion',
      actionInProgressKey: 'saving-in-progress',
    };

    const result = await this.popupService.showDynamicFormModal(
      new DynamicFormConfiguration({
        type: DynamicFormType.DIRECT_SAVE,
        dynamicForm,
        translationOptions,
      }),
    );

    if (result.role === OverlayEventRole.save) {
      this.fetchAllClinics();
    } else if (result.role === OverlayEventRole.cancel) {
      this.fetchAllClinics();
      throw new UserError('Aftercare Order creation modal was canceled by User.');
    }
  }

  async getPatientIdFromUser(): Promise<string> {
    await this.loadingService.load(`${this.aftercareTranslations['fetching-patients']}`);

    const result = await this.popupService.showTableSelectModal(
      await this.fetchPatientTable(),
      this.aftercareTranslations['choose-a-patient'],
    );

    if (result.role === OverlayEventRole.activityChange) {
      return result.data.tableListItem;
    } else {
      throw new UserError('Patient selection modal was canceled by User.');
    }
  }

  private async fetchPatientTable(): Promise<TableList> {
    const patients: Patient[] = await firstValueFrom(this.patientService.getAllPatients());
    const header: TableHeaderItem[] = this.constructHeaderForPatientSelectTable();
    const rows: TableListItem[] = this.constructRowsForPatientSelectTable(patients);
    return new TableList(header, rows);
  }

  private constructHeaderForPatientSelectTable(): TableHeaderItem[] {
    return [
      {
        value: this.generalTranslations['patient'],
        type: TableDataType.text,
        identifier: new AttributeNameIdentifier('1'),
      },
      {
        value: this.generalTranslations['birthday'],
        type: TableDataType.date,
        identifier: new AttributeNameIdentifier('2'),
      },
    ];
  }

  private constructRowsForPatientSelectTable(patients: Patient[]): TableListItem[] {
    return patients.map(
      (patient: Patient) =>
        ({
          ...patient,
          id: patient.patientID,
          columns: [patient.getFullName(), patient.birthDate],
        }) as TableListItem,
    );
  }
}
