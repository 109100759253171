import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bundle } from 'fhir/r4';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { firstValueFrom } from 'rxjs';
import { IheDocumentsResourceMapper } from '../mappers/ihe-document.mapper';
import { IheDocumentDetails, IheDocumentsExistsResponse } from '../models/ihe-documents.model';
import { ClientConfigService } from './client-config.service';

@Injectable()
export class IheDocumentsService {
  constructor(
    private http: HttpClient,
    private clientConfigService: ClientConfigService,
    private documentsMapper: IheDocumentsResourceMapper,
  ) {}

  public async getIheDocumentsList(
    pid: string,
    sourceSystemOid: string,
  ): Promise<IheDocumentDetails[]> {
    const response = await this.getIheDocumentsListRequest(pid, sourceSystemOid);
    const documents = this.documentsMapper.mapIheDocumentResourceDetails(response);
    await this.setAlreadyExistingInSourceSystemFlag(documents);
    return documents;
  }

  public async setAlreadyExistingInSourceSystemFlag(
    documents: IheDocumentDetails[],
  ): Promise<IheDocumentDetails[]> {
    const masterIdentifiers = documents
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      .map((doc) => doc.originalResource?.masterIdentifier?.value)
      .filter((id) => id);
    const existingDocuments =
      await this.getListOfAlreadyExistingDocumentsInCurrentSourceSystemRequest(masterIdentifiers);
    documents.forEach((doc) => {
      doc.existsInCurrentSourceSystem = existingDocuments.documentIds.includes(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        doc.originalResource?.masterIdentifier?.value,
      );
    });
    return documents;
  }

  public async getIheDocumentsListRequest(pid: string, sourceSystemOid: string): Promise<Bundle> {
    const clientConfig = this.clientConfigService.get();
    const patientDocumentsBasePath = clientConfig.activeModules?.iheViewer?.iheConnectorApiBasePath;
    const path = `/documents/${pid}/${sourceSystemOid}`;
    return firstValueFrom(
      this.http.get<Bundle>(patientDocumentsBasePath + path, {
        headers: CustomHttpHeaders.XNoApiBaseUrlInterceptionHeaders,
      }),
    );
  }

  public async getListOfAlreadyExistingDocumentsInCurrentSourceSystemRequest(
    documentIds: string[],
  ): Promise<IheDocumentsExistsResponse> {
    const clientConfig = this.clientConfigService.get();
    const patientDocumentsBasePath = clientConfig.activeModules?.iheViewer?.iheConnectorApiBasePath;
    const path = `/docsExist`;
    return firstValueFrom(
      this.http.post<IheDocumentsExistsResponse>(patientDocumentsBasePath + path, documentIds, {
        headers: CustomHttpHeaders.XNoApiBaseUrlInterceptionHeaders,
      }),
    );
  }

  public async transferDocuments(
    pid: string,
    sourceSystemOid: string,
    listOfIheDocumentUniqueIds: string[],
  ) {
    const clientConfig = this.clientConfigService.get();
    const patientDocumentsBasePath = clientConfig.activeModules?.iheViewer?.iheConnectorApiBasePath;
    const path = `/transfer/${pid}/${sourceSystemOid}`;
    await firstValueFrom(
      this.http.post(patientDocumentsBasePath + path, listOfIheDocumentUniqueIds),
    );
  }
}
