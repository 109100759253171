import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PlatformFix } from '../models/platform-fixes.model';

@Injectable()
export class PlatformFixes {
  constructor(private platform: Platform) {}

  public getFixes(): Array<PlatformFix> {
    return [this.visualGlitchesWebkitFix()];
  }

  private visualGlitchesWebkitFix(): PlatformFix {
    return {
      isTarget: () => {
        if (this.platform.testUserAgent('WebKit') && !this.platform.testUserAgent('Chrome')) {
          // eslint-disable-next-line no-console
          console.info('WebKit fix applied');
          return true;
        } else {
          return false;
        }
      },
      css: `ion-item,
        ion-item *:not(ion-radio, ion-checkbox),
        ion-item-divider,
        .list-head,
        ion-list,
        ion-card { transform: translateZ(1px); }
        .dynamic-modal, .dynamic-modal * {animation: none !important;}
        .dynamic-modal .form-modal {height: var(--height) !important;}`,
    };
  }
}
