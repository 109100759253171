<div id="list-smaller" [ngClass]="breakLayout() ? 'ion-hide-md-up' : ''">
  <ion-row class="list-row" *ngFor="let item of items">
    <ion-col size-xs="2" style="display: flex; align-items: center">
      <ion-skeleton-text animated="true" style="height: 2.4rem"></ion-skeleton-text>
    </ion-col>

    <ion-col size-xs="10">
      <ion-row>
        <ion-col class="document-name">
          <ion-skeleton-text animated="true" style="height: 1rem"></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="document-size" size-xs="4" *ngIf="hasExtendedView">
          <ion-skeleton-text animated="true"></ion-skeleton-text>
        </ion-col>
        <ion-col *ngIf="hasExtendedView" size="5"></ion-col>
        <ion-col class="document-date" size-xs="3">
          <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</div>

<div id="list-larger" class="ion-hide-md-down" *ngIf="hasExtendedView">
  <ion-row class="list-row ion-align-items-center" *ngFor="let item of items">
    <ion-col size-xs="1" class="col-thumb">
      <ion-skeleton-text animated="true" style="height: 2rem"></ion-skeleton-text>
    </ion-col>

    <ion-col size-xs="7" class="name">
      <ion-skeleton-text animated="true" style="height: 1rem"></ion-skeleton-text>
    </ion-col>

    <ion-col size-xs="2" class="color-greyish-67">
      <ion-skeleton-text animated="true" style="height: 1rem"></ion-skeleton-text>
    </ion-col>

    <ion-col size-xs="2" class="color-greyish-67">
      <ion-skeleton-text animated="true" style="height: 1rem"></ion-skeleton-text>
    </ion-col>
  </ion-row>
</div>
