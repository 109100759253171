<lib-modal-header [title]="title"></lib-modal-header>

<ion-content [scrollY]="false">
  <lib-generic-table-container
    class="stretch-container fill-height"
    [data]="data"
    [isRowDataLoading]="isRowDataLoading"
    [isCard]="false"
    (eventMessage)="resolveEventMessage($event)">
  </lib-generic-table-container>
</ion-content>
