@if (isMobileView) {
  <lib-auto-layout
    class="row-container"
    [class.odd]="isOdd"
    direction="vertical"
    horizontal="stretch"
    [gap]="[0]"
    [padding]="[12, 20, 12, 10]"
    (onDimensionChange)="setViewMode($event)">
    <lib-auto-layout [grow]="1" [gap]="[10]" [padding]="[0, 0, 10, 0]">
      <lib-auto-layout direction="vertical" [grow]="1" [padding]="[0, 10]">
        <p class="trim-text-inline no-margin">
          <strong> {{ requiredAction.title }} </strong>
        </p>
        <p class="trim-text-inline no-margin">{{ requiredAction.task.value }}</p>
      </lib-auto-layout>

      <ion-button
        color="primary"
        class="icon-only no-margin"
        (click)="showRequiredAction(requiredAction)">
        <fa-icon [icon]="ICONS.arrow"></fa-icon>
      </ion-button>
    </lib-auto-layout>

    <lib-auto-layout horizontal="space-between" vertical="center" [gap]="[10]">
      <p class="time-label shape-chip no-margin" [ngClass]="timeLabelClasses">
        <strong>
          {{ dateDecription | translate }}&nbsp;{{ requiredAction.endDate.value | localeDate }}
        </strong>
      </p>
      <ion-button
        color="tertiary"
        class="icon-only no-margin"
        (click)="showTaskInformation(requiredAction)">
        <fa-icon [icon]="ICONS.info"></fa-icon>
      </ion-button>
    </lib-auto-layout>
  </lib-auto-layout>
} @else {
  <lib-auto-layout
    class="row-container"
    [class.odd]="isOdd"
    vertical="center"
    [gap]="[20]"
    [padding]="[10, 20]"
    (onDimensionChange)="setViewMode($event)">
    <p class="time-label shape-chip no-margin" [ngClass]="timeLabelClasses">
      <strong> {{ requiredAction.endDate.value | localeDate: 'shortDate' }} </strong>
    </p>
    <lib-auto-layout direction="vertical" [grow]="1">
      <p class="trim-text-inline no-margin">
        <strong> {{ requiredAction.title }} </strong>
      </p>
      <p class="trim-text-inline no-margin">{{ requiredAction.task.value }}</p>
    </lib-auto-layout>
    <lib-auto-layout vertical="space-between" [gap]="[10]">
      <ion-button
        color="tertiary"
        class="icon-only no-margin"
        (click)="showTaskInformation(requiredAction)">
        <fa-icon [icon]="ICONS.info"></fa-icon>
      </ion-button>
      <ion-button
        color="primary"
        class="icon-only no-margin"
        (click)="showRequiredAction(requiredAction)">
        <fa-icon [icon]="ICONS.arrow"></fa-icon>
      </ion-button>
    </lib-auto-layout>
  </lib-auto-layout>
}
