import { Component, Input } from '@angular/core';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { ViewDocumentDetails } from 'projects/core/src/lib/models/documents.model';

@Component({
  selector: 'lib-document-details-popover',
  templateUrl: './document-details-popover.component.html',
  styleUrls: ['./document-details-popover.component.scss'],
})
export class DocumentDetailsPopoverComponent {
  @Input() document: ViewDocumentDetails;

  isDownloadPopupOpen = false;

  readonly icons = { info: faInfo };

  toggleDownloadButtonState(): void {
    this.isDownloadPopupOpen = !this.isDownloadPopupOpen;
  }
}
