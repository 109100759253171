import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { BehaviorSubject, Observable, of, throwError, zip } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { ProfileResourceMapper } from '../mappers/profile.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { ClientConfig } from '../models/client.model';
import { DynamicForm } from '../models/form.model';
import { Invoker, InvokerMethods } from '../models/invoker-body.model';
import { Profile } from '../models/profile.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import { SDAPIMenuObject } from '../models/sdapi-object.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { ClientConfigService } from './client-config.service';
import { SDAPIService } from './sdapi.service';

@Injectable()
export class ProfileService {
  private readonly refreshCurrentProfile = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private dynamicFormMapper: DynamicFormMapper,
    private sdapiService: SDAPIService,
    private clientConfigService: ClientConfigService,
  ) {}

  public getCurrentProfile(): Observable<Profile> {
    return this.refreshCurrentProfile.pipe(switchMap(() => this.fetchProfile()));
  }

  public getProfileEditForm(): Observable<DynamicForm> {
    return this.getUserObjId().pipe(
      concatMap((id: number) =>
        this.sdapiService.getInvokerByMethodName(
          id.toString(),
          InvokerMethods.objectAttributesEdit,
        ),
      ),
      concatMap((invoker: Invoker) =>
        zip(this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker), of(invoker)),
      ),
      map(([response, invoker]) => {
        const activityURL: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
        return this.dynamicFormMapper.mapDynamicFormResource(response, activityURL);
      }),
    );
  }

  public getUserDeactivationForm(): Observable<DynamicForm> {
    return this.getUserObjId().pipe(
      concatMap((id: number) =>
        this.sdapiService.getInvokerByMethodName(id.toString(), InvokerMethods.userDeactivate),
      ),
      concatMap((invoker: Invoker) =>
        zip(this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker), of(invoker)),
      ),
      map(([response, invoker]) => {
        const activityURL: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
        return this.dynamicFormMapper.mapDynamicFormResource(response, activityURL);
      }),
      catchError(() => of(undefined)),
    );
  }

  public getUserObjId(): Observable<number> {
    const clientConfig: ClientConfig = this.clientConfigService.get();
    /** @deprecated as soon as unispital-basel is migrated to the new sdapi version - remove this distinction */
    if (clientConfig.useLegacyPPCurrentUser) {
      return this.getUserObjIdLegacy();
    } else {
      return this.getUserObjIdNew();
    }
  }

  private fetchProfile(): Observable<Profile> {
    return this.getUserObjId().pipe(
      concatMap((id: number) => {
        if (id === -1) {
          return of(new Profile());
        } else {
          return this.getProfileDetails(id);
        }
      }),
      catchError((error) => {
        console.error(`Could not fetch current profile`, error);
        return throwError(() => error);
      }),
    );
  }

  private getUserObjIdNew(): Observable<number> {
    return this.http.get<SDAPIMenuObject>(`/objects/USER`).pipe(
      map((response: SDAPIMenuObject) => response.subMenus[0].invokers[0].objId),
      catchError((error) => {
        console.error('Could not fetch USER', error);
        return this.getUserObjIdLegacy();
      }),
    );
  }

  /** @deprecated as soon as unispital-basel is migrated to the new sdapi version - remove this distinction */
  private getUserObjIdLegacy(): Observable<number> {
    return this.sdapiService
      .getInvokerByMethodName(
        'PP_CURRENT_USER',
        InvokerMethods.constraintObjectList,
        CustomHttpHeaders.XNoAlertInterceptorHeaders,
      )
      .pipe(
        concatMap((invoker: Invoker) =>
          this.http.put<TieTableObjectList>(invoker.activityURL, invoker.invoker),
        ),
        map((response: TieTableObjectList) => response.items[0].objId),
        catchError((error) => {
          console.error('Could not fetch PP_CURRENT_USER', error);
          return of(-1);
        }),
      );
  }

  private getProfileDetails(id: number): Observable<Profile> {
    return this.sdapiService
      .getInvokerByMethodName(id.toString(), InvokerMethods.objectAttributesView)
      .pipe(
        concatMap((invoker: Invoker) =>
          zip(this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker), of(invoker)),
        ),
        map(([response, invoker]) => {
          const activityURL: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
          const profileForm: DynamicForm = this.dynamicFormMapper.mapDynamicFormResource(
            response,
            activityURL,
            false,
            true,
          );
          return ProfileResourceMapper.mapResource(profileForm, id);
        }),
      );
  }
}
