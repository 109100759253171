export class Clinic {
  name: string;
  type: ClinicType;
  treatmentTypes?: TreatmentType[];
  city: string;
  distance: number;
  availability: ClinicAvailabilityDetails[] = [];
}

export class ClinicAvailabilityDetails {
  class: number;
  gender: ClinicGender;
  count: number;
}

export enum ClinicType {
  Rehaklinik = 'Rehaklinik',
  Krankenhaus = 'Krankenhaus',
  Pflege = 'Pflege',
}

export enum TreatmentType {
  Allgemeinmedizin = 'Allgemeinmedizin',
  Angiologie = 'Angiologie',
  Anaesthesiologie = 'Anästhesiologie',
  Arbeitsmedizin = 'Arbeitsmedizin',
  Augenheilkunde = 'Augenheilkunde',
  Chirurgie = 'Chirurgie',
  Dermatologie_Geschlechtskrankheiten = 'Dermatologie und Geschlechtskrankheiten',
  Endokrinologie_Diabetologie = 'Endokrinologie und Diabetologie',
  Ernaehrungsmedizin_Adipositaschirurgie = 'Ernährungsmedizin und Adipositaschirurgie',
  Frauenheilkunde_Geburtshilfe = 'Frauenheilkunde und Geburtshilfe',
  Gastroenterologie = 'Gastroenterologie',
  Gefaesschirurgie = 'Gefässchirurgie',
  Geriatrie = 'Geriatrie',
  Hals_Nasen_Ohrenheilkunde = 'Hals-Nasen-Ohrenheilkunde',
  Herzchirurgie = 'Herzchirurgie',
  Haematologie_Onkologie = 'Hämatologie und Onkologie',
  Innere_Medizin = 'Innere Medizin',
  Kardiologie = 'Kardiologie',
  Kinder_Jugendmedizin = 'Kinder- und Jugendmedizin',
  Kinder_Jugendpsychiatrie_psychotherapie = 'Kinder- und Jugendpsychiatrie und -psychotherapie',
  Kinderchirurgie = 'Kinderchirurgie',
  Laboratoriumsmedizin = 'Laboratoriumsmedizin',
  Mund_Kiefer_Gesichtschirurgie = 'Mund-, Kiefer- und Gesichtschirurgie',
  Nervenheilkunde_Neurologie_Psychiatrie = 'Nervenheilkunde/Neurologie und Psychiatrie',
  Neurochirurgie = 'Neurochirurgie',
  Neurologie = 'Neurologie',
  Nuklearmedizin = 'Nuklearmedizin',
  Orthopaedie = 'Orthopädie',
  Palliativmedizin = 'Palliativmedizin',
  Phoniatrie = 'Phoniatrie',
  Physikalische_Rehabilitative_Medizin = 'Physikalische und Rehabilitative Medizin',
  Plastische_Chirurgie = 'Plastische Chirurgie',
  Pneumologie = 'Pneumologie',
  Proktologie = 'Proktologie',
  Praevention = 'Prävention',
  Psychiatrie_Psychotherapie = 'Psychiatrie und Psychotherapie',
  Radiologie = 'Radiologie',
  Schmerztherapie = 'Schmerztherapie',
  Sonstige_Fachabteilungen = 'Sonstige Fachabteilungen',
  Spezielle_Geburtshilfe_Perinatalmedizin = 'Spezielle Geburtshilfe und Perinatalmedizin',
  Strahlentherapie = 'Strahlentherapie',
  Thoraxchirurgie = 'Thoraxchirurgie',
  Transfusionsmedizin = 'Transfusionsmedizin',
  Unfallchirurgie = 'Unfallchirurgie',
  Urologie = 'Urologie',
  Viszeralchirurgie = 'Viszeralchirurgie',
}

export enum ClinicGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  DIVERS = 'DIVERS',
}
