<ion-header *ngIf="showHeader">
  <ion-toolbar style="--padding-start: 10px; --padding-end: 10px">
    <h3 class="ion-no-margin" *ngIf="consolidatedAvb">
      {{ 'shared.terms-of-service.title-consolidated' | translate }}
    </h3>
    <h3 class="ion-no-margin" *ngIf="!consolidatedAvb">
      {{ 'shared.terms-of-service.title' | translate }}
    </h3>
    <div class="toolbar-buttons" slot="end" style="margin-left: 10px">
      <ion-button id="cancel-button" color="tertiary" (click)="close()">
        <fa-icon [icon]="icons.close"></fa-icon>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-no-margin ion-no-padding">
  <pinch-zoom
    *ngIf="url"
    [properties]="zoomProperties"
    [wheel]="false"
    [autoHeight]="true"
    [ngClass]="{ 'stretch-self': !isLoading }">
    <div id="pdf-viewer" class="stretch-self">
      <pdf-viewer
        *ngIf="url"
        [src]="url"
        [render-text]="false"
        [render-text-mode]="renderTextMode.DISABLED"
        [fit-to-page]="true"
        [original-size]="true"
        [autoresize]="true"
        zoom-scale="page-width"
        (after-load-complete)="afterLoadComplete()"
        style="height: 100%">
      </pdf-viewer>
    </div>
  </pinch-zoom>

  <div id="skeleton" *ngIf="isLoading">
    <ion-skeleton-text
      *ngFor="let i of getRowAmount()"
      class="ion-margin-vertical rounded-small"
      animated="true"></ion-skeleton-text>
  </div>
</ion-content>
