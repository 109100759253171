import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: ['./inline-loader.component.scss'],
})
export class InlineLoaderComponent {
  @Input() message = 'Lädt…';
}
