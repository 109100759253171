import { APIError, PostMappingError } from '../data/errors.data';
import { AttributeDetail } from '../models/attribute.model';
import { TableHeaderItem, TableList, TableListItem } from '../models/dynamic-table.model';
import {
  AutocompleteOptions,
  DataObject,
  DataType,
  DynamicDataField,
  DynamicForm,
  FormatOptions,
  Formula,
  FormulaType,
  SelectionObject,
} from '../models/form.model';
import { MultiChosenData } from '../models/grid.model';
import {
  FormAttributeCollection,
  Invoker,
  InvokerBody,
  InvokerTypes,
} from '../models/invoker-body.model';
import {
  AttributeFieldType,
  AttributeValue,
  AttributeValueCollection,
  AttributeValueItem,
  CheckerType,
  DataFieldValueType,
  FormItem,
  FormItemBooleanString,
  FormItemType,
  MandatoryAttributeType,
  ShowType,
  ShowTypeGroup,
  TabItem,
  TieFormObject,
} from '../models/sdapi-form-object.model';
import { AttributeNameIdentifier } from '../models/shared.model';
import { parseDateRelativeFromNow } from './validator.generator';

export class FormMapper {
  public static resolveType({
    t: type,
    attrType: dataType,
    mandatory: mandatoryType,
    checker: checkerType,
    readonly,
    hidden,
  }: FormItem): DataType {
    if (type === FormItemType.hidden || hidden === true) {
      return DataType.hidden;
    }
    if (
      type === FormItemType.richtext &&
      (mandatoryType === MandatoryAttributeType.fix || readonly === true)
    ) {
      return DataType.richtext;
    }
    if (dataType === DataFieldValueType.number && type !== FormItemType.select) {
      return DataType.number;
    }
    if (dataType === DataFieldValueType.date) {
      return DataType.date;
    }
    if (checkerType === CheckerType.objectClass) {
      return DataType.hidden;
    }

    switch (type) {
      case FormItemType.date:
        return DataType.date;
      case FormItemType.radio:
        return DataType.radio;
      case FormItemType.checkbox:
        return DataType.checkbox;
      case FormItemType.textArea:
        return DataType.textArea;
      case FormItemType.title:
        return DataType.title;
      case FormItemType.fixtext:
        return DataType.text;
      case FormItemType.textMl:
        return DataType.text;
      case FormItemType.text:
        return DataType.text;
      case FormItemType.select:
        return DataType.select;
      case FormItemType.autocomplete:
        return DataType.autocomplete;
      case FormItemType.grid:
        return DataType.grid;
      case FormItemType.grouping:
        return DataType.grouping;
      case FormItemType.file:
        return DataType.file;
      case FormItemType.popup:
        return DataType.popup;
      case FormItemType.formula:
        return DataType.number;
      default:
        return DataType.unknown;
    }
  }

  public static resolveTypedValue(item: FormItem, values?: AttributeValue): DataObject {
    const { t: type, displayName: title, showType } = item;

    const attributeFieldType: AttributeFieldType = values?.t || AttributeFieldType.text;
    const currentValue = values?.internalValue || '';

    switch (type) {
      case FormItemType.radio:
      case FormItemType.select: {
        const options = item.options.map((itemOption: AttributeValue) => ({
          identifier: itemOption.internalValue,
          value: itemOption.displayValue,
        }));
        return new DataObject(attributeFieldType, currentValue, { title, options });
      }
      case FormItemType.date: {
        return this.resolveDateType(item, attributeFieldType, currentValue);
      }
      case FormItemType.title: {
        return new DataObject(attributeFieldType, currentValue, { style: item.tagPrefs?.style });
      }
      case FormItemType.textArea: {
        const limiters = {
          min: item.tagPrefs.min,
          max: item.tagPrefs.max,
          rows: item.tagPrefs.rows || '3',
        };
        return new DataObject(attributeFieldType, currentValue, { limiters });
      }
      case FormItemType.checkbox: {
        const state = currentValue === 'true' ? 'true' : 'false';
        return new DataObject(attributeFieldType, state);
      }
      case FormItemType.textMl: {
        const fieldValue = values[`value${values.language}`];
        return new DataObject(attributeFieldType, fieldValue);
      }
      case FormItemType.fixtext: {
        const fixTextValue =
          values?.displayValue !== null ? values?.displayValue : values?.internalValue;
        return new DataObject(attributeFieldType, fixTextValue);
      }
      case FormItemType.text: {
        if (showType === ShowTypeGroup.setRoles) {
          const options = [
            {
              identifier: values?.internalValue,
              value: values?.displayValue,
            },
          ];
          return new DataObject(attributeFieldType, currentValue, { title, options });
        } else {
          const limiters = {
            min: item.tagPrefs.min,
            max: item.tagPrefs.max,
            rows: item.tagPrefs.rows,
          };
          let textValue = currentValue;
          if (item.readonly) {
            textValue =
              values?.displayValue !== null ? values?.displayValue : values?.internalValue;
          }
          return new DataObject(attributeFieldType, textValue, { limiters });
        }
      }
      case FormItemType.grid: {
        const table: TableList = this.mapGridData(item, values);
        return new DataObject(attributeFieldType, currentValue, { table });
      }
      case FormItemType.file: {
        return new DataObject(attributeFieldType, currentValue);
      }
      case FormItemType.popup: {
        const invoker: Invoker = {
          type: InvokerTypes.INVOKE_METHOD,
          invoker: item.onClick,
        };
        return new DataObject(attributeFieldType, currentValue, { invoker });
      }
      case FormItemType.formula: {
        const formula: Formula = this.extractFormula(item);
        return new DataObject(attributeFieldType, currentValue, { formula });
      }
      case FormItemType.autocomplete: {
        const autocompleteOptions: AutocompleteOptions = {
          originalDisplayValue: values?.displayValue,
          popupObjId: item.popupObjId,
          minprefixlength:
            (item.tagPrefs?.minprefixlength ?? item.minprefixlength)
              ? Number.parseInt(item.tagPrefs?.minprefixlength ?? item.minprefixlength, 10)
              : undefined,
          maxoptions:
            (item.tagPrefs?.maxoptions ?? item.maxoptions)
              ? Number.parseInt(item.tagPrefs?.maxoptions ?? item.maxoptions, 10)
              : undefined,
          popupMandatory: item.popupMandatory === FormItemBooleanString.true,
        };
        return new DataObject(attributeFieldType, currentValue, {
          autocompleteOptions,
          options: [],
        });
      }

      default: {
        const limiters = {
          max: item.tagPrefs.max,
        };
        return new DataObject(attributeFieldType, currentValue, { limiters });
      }
    }
  }

  private static extractFormula(item: FormItem): Formula {
    try {
      const split = item.tagPrefs.formula.split('(');
      const type = split[0] as FormulaType;
      const fields = split[1].replace(')', '').split(',');
      const tab = item.showType;
      return { type, fields, tab };
    } catch {
      return undefined;
    }
  }

  public static getAttributeValuesOfField(
    attributeValues: AttributeValueCollection,
    identifier: string,
  ): AttributeValue {
    const directAttribute = attributeValues[identifier];

    if (directAttribute) {
      return directAttribute;
    }

    for (const attribute of Object.values(attributeValues)) {
      const nestedAttribute = attribute.attributeValues
        ? attribute.attributeValues[identifier]
        : null;
      if (nestedAttribute) {
        return nestedAttribute;
      }
    }

    return null;
  }

  private static resolveDateType(
    item: FormItem,
    attributeFieldType: AttributeFieldType,
    value?: string,
  ) {
    const date = value || null;
    const { minrel, maxrel, min, max } = item.tagPrefs;
    if (!minrel && !maxrel && !min && !max) {
      return new DataObject(attributeFieldType, date);
    }

    const limiters: { min?; max?; minrel?; maxrel? } = {};
    if (min) {
      limiters.min = min;
    }
    if (max) {
      limiters.max = max;
    }
    if (minrel && parseDateRelativeFromNow(minrel)) {
      limiters.minrel = parseDateRelativeFromNow(minrel).tz().toISOString();
    }
    if (maxrel && parseDateRelativeFromNow(maxrel)) {
      limiters.maxrel = parseDateRelativeFromNow(maxrel).tz().endOf('day').toISOString();
    }
    return new DataObject(attributeFieldType, date, { limiters });
  }

  private static mapGridData(item: FormItem, values: AttributeValue): TableList {
    const header: TableHeaderItem[] = [];
    const rows: TableListItem[] = [];

    try {
      const resourceHeaderItems: FormItem[] = item.columnTypes;
      resourceHeaderItems.forEach((headerItem: FormItem) => {
        header.push({
          value: headerItem.displayName,
          type: undefined,
          identifier: new AttributeNameIdentifier(headerItem.attributeName),
          format: new FormatOptions(headerItem.format),
        });
      });
      const resourceList: AttributeValueItem[] = values.items;
      resourceList.forEach((attrItem: AttributeValueItem, index: number) => {
        rows.push({
          columns: [],
        });
        for (const value of Object.values(attrItem.attrValues)) {
          rows[index].columns.push(value.displayValue || value.internalValue);
          rows[index].id = index.toString();
          header.forEach((headerItem: TableHeaderItem) => {
            headerItem.type = value.t;
          });
        }
      });
    } catch (error) {
      throw new APIError('The mapping of grid data in the dynamic form mapper failed.', error);
    }

    return new TableList(header, rows);
  }

  public static resolveStepInvoker(
    types: InvokerTypes[],
    activityURL: string,
    resource: TieFormObject,
  ): Invoker[] {
    const invokerArray: Invoker[] = [];
    for (const type of types) {
      const invoker: FormItem = this.getSubmissionInvoker(resource);
      if (invoker) {
        invokerArray.push({
          type,
          activityURL,
          invoker: { ...invoker.invokerRequest, parameters: resource.attributeValues },
        });
      } else {
        throw new APIError(
          `No Invoker of type '${type}' on attribute profile '${resource.attributeProfileId}'.`,
        );
      }
    }
    return invokerArray;
  }

  private static getSubmissionInvoker(resource: TieFormObject): FormItem {
    const buttonTypes: FormItemType[] = [FormItemType.buttonStep, FormItemType.buttonStepAndClose];
    let buttons: FormItem[] = this.collectButtonsFromButtonShowType(resource, buttonTypes);

    if (resource.showTypes.TAB) {
      buttons = [...buttons, ...this.collectButtonsFromTabShowType(resource, buttonTypes)];
    }

    let invoker = this.findInvoker(buttons, FormItemType.buttonStep);
    if (!invoker) {
      invoker = this.findInvoker(buttons, FormItemType.buttonStepAndClose);
    }
    return invoker;
  }

  private static collectButtonsFromButtonShowType(
    resource: TieFormObject,
    buttonTypes: FormItemType[],
  ): FormItem[] {
    const possibleShowTypes = ['BUTTON', 'BUTTON0', 'BUTTON_SELECT'];
    const showTypeKeys = Object.keys(resource.showTypes);
    let showTypeKey: string;

    for (const showType of possibleShowTypes) {
      if (showTypeKeys.includes(showType)) {
        showTypeKey = showType;
        break;
      }
    }

    return resource.showTypes[showTypeKey].items.filter((button: FormItem) =>
      buttonTypes.includes(button.t),
    );
  }

  private static collectButtonsFromTabShowType(
    resource: TieFormObject,
    buttonTypes: FormItemType[],
  ): FormItem[] {
    if (!resource.showTypes) {
      return [];
    }

    return resource.showTypes.TAB.tabs
      .flatMap((tab: TabItem) => tab.showTypes.flatMap((showType: ShowType) => showType.items))
      .filter((item: FormItem) => item && buttonTypes.includes(item.t));
  }

  private static findInvoker(buttons: FormItem[], formItemType: FormItemType): FormItem {
    return buttons.find((button: FormItem) => button.t === formItemType);
  }

  public static mapInvokerWithFieldValues(dynamicForm: DynamicForm, invoker: Invoker): InvokerBody {
    const invokerWithBody = FormMapper.insertDynamicFormItemsToInvokerBody(
      dynamicForm.body,
      invoker,
    );
    if (!dynamicForm.multiChosen) {
      return invokerWithBody;
    } else {
      return FormMapper.insertMultiChosenFieldsToInvokerBody(
        dynamicForm.multiChosen,
        invokerWithBody,
      );
    }
  }

  public static insertDynamicFormItemsToInvokerBody(
    dynamicFormBody: DynamicDataField[],
    invoker: Invoker,
  ): InvokerBody {
    const invokerBody = invoker.invoker;
    dynamicFormBody.forEach((item: DynamicDataField) => {
      if (item.fieldGroup) {
        item.fieldGroup.forEach((groupField: DynamicDataField) =>
          this.updateAttributeValuesOfGroupedItems(groupField, invokerBody, item),
        );
      }
      this.updateAttributeValuesOfUngroupedItems(item, invokerBody);
    });
    return invokerBody;
  }

  private static insertMultiChosenFieldsToInvokerBody(
    multiChosenData: MultiChosenData,
    invokerBody: InvokerBody,
  ): InvokerBody {
    try {
      const gridIdentifier = multiChosenData.collector.identifier;
      invokerBody.parameters[gridIdentifier].items =
        this.mapCollectorCollectionToAttributeValueItems(multiChosenData);
      return invokerBody;
    } catch (error) {
      throw new PostMappingError('Failed to insert MultiChosen fields to invoker body.', error);
    }
  }

  private static mapCollectorCollectionToAttributeValueItems(
    multiChosenData: MultiChosenData,
  ): AttributeValueItem[] {
    try {
      return multiChosenData.collector.collection.map((items: DynamicDataField[]) => {
        const template = multiChosenData.collector.collectionTemplate.indexedAttributeValues;
        const attrValues = this.mapDynamicDataFieldToAttributeValuesForGridDO(items, template);
        return { attrValues };
      });
    } catch (error) {
      throw new PostMappingError(
        'Failed to map MultiChosen collection to AttributeValueItems.',
        error,
      );
    }
  }

  private static mapDynamicDataFieldToAttributeValuesForGridDO(
    items: DynamicDataField[],
    indexedAttributeValueTemplate: AttributeValue[],
  ): AttributeValue[] {
    try {
      return items.map((item: DynamicDataField, index: number) => {
        const internalValue = item.value?.value || '';
        const displayValue = item.value.options ? this.getSelectionFieldValue(item) : internalValue;
        return {
          ...indexedAttributeValueTemplate[index],
          internalValue,
          displayValue,
        };
      });
    } catch (error) {
      throw new PostMappingError(
        'Failed to map DynamicDataField to AttributeValues for GridDO parameters.',
        error,
      );
    }
  }

  private static updateAttributeValuesOfUngroupedItems(
    field: DynamicDataField,
    invokerBody: InvokerBody,
  ): void {
    const target: FormAttributeCollection = invokerBody.parameters;
    this.updateAttributeByFieldType(field, target, field.identifier?.originalValue);
  }

  private static updateAttributeValuesOfGroupedItems(
    field: DynamicDataField,
    invokerBody: InvokerBody,
    group?: DynamicDataField,
  ): void {
    const target: FormAttributeCollection =
      invokerBody.parameters[group.identifier?.originalValue]?.attributeValues;
    this.updateAttributeValuesOfUngroupedItems(field, invokerBody);
    if (
      invokerBody.parameters[group.identifier?.originalValue]?.t === AttributeFieldType.grouping &&
      target
    ) {
      this.updateAttributeByFieldType(field, target, field.identifier.originalValue);
    }
  }

  private static updateAttributeByFieldType(
    field: DynamicDataField,
    target: FormAttributeCollection,
    identifier: string,
  ): void {
    const internalValue = field.value?.value;
    const displayValue =
      field.type === DataType.select ? this.getSelectionFieldValue(field) : field.value?.value;
    if (target[identifier]?.t === AttributeFieldType.date && internalValue && displayValue) {
      this.updateDateParameter(target, field, internalValue, displayValue);
    } else if (target[identifier]?.t === AttributeFieldType.text) {
      this.updateStringParameter(target, field, internalValue, displayValue);
    }
  }

  private static getSelectionFieldValue(field: DynamicDataField): string {
    return field.value.options.find(
      (option: SelectionObject) => option.identifier === field.value?.value,
    )?.value;
  }

  private static updateDateParameter(
    target: FormAttributeCollection,
    field: DynamicDataField,
    internalValue: string,
    displayValue: string,
  ): void {
    target[field.identifier.originalValue].internalValue = this.toISOString(internalValue);
    target[field.identifier.originalValue].displayValue = this.toISOString(displayValue);
  }

  private static updateStringParameter(
    target: FormAttributeCollection,
    field: DynamicDataField,
    internalValue: string,
    displayValue: string,
  ): void {
    target[field.identifier.originalValue].internalValue = `${internalValue || ''}`;
    target[field.identifier.originalValue].displayValue = `${displayValue || ''}`;
  }

  private static toISOString(date: string): string {
    return new Date(date).toISOString();
  }

  public static transferAttributesToInvokers(sourceInvoker: Invoker, form: DynamicForm): Invoker[] {
    return form.invoker.map((invoker: Invoker) => {
      const modifiedInvoker = invoker.invoker;
      modifiedInvoker.parameters = {
        ...modifiedInvoker.parameters,
        ...sourceInvoker.invoker.parameters,
      };
      return {
        ...invoker,
        invoker: modifiedInvoker,
      };
    });
  }

  public static sortBySequence(items: FormItem[]): FormItem[] {
    items?.sort(({ seq: a }, { seq: b }) => a - b);

    return items;
  }

  public static mapFormBodyItemsToAttributeDetails(form: DynamicForm): AttributeDetail[] {
    return form.body
      .flatMap((group: DynamicDataField) => group?.fieldGroup)
      .filter((item: DynamicDataField) => item.value.value)
      .map((item: DynamicDataField) => new AttributeDetail(item));
  }

  public static mapOptionsUpdate(updates: any): SelectionObject[] {
    return updates.options.map(({ internalValue: identifier, displayValue: value }) => ({
      identifier,
      value: value || identifier,
    }));
  }
}
