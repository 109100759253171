import { Component, Input, ViewChild } from '@angular/core';
import { IonPopover } from '@ionic/angular';
import { SelectionObject } from 'projects/core/src/lib/models/form.model';
import { MultiChosenData } from 'projects/core/src/lib/models/grid.model';

@Component({
  selector: 'lib-multi-chosen',
  templateUrl: './multi-chosen.component.html',
  styleUrls: ['./multi-chosen.component.scss'],
})
export class MultiChosenComponent {
  @ViewChild('choseMenu') choseMenu: IonPopover;
  @Input() multiChosenData: MultiChosenData;

  get options(): SelectionObject[] {
    const filteredOptions = this.multiChosenData.options.value.options.filter((option) => {
      const isEmpty = option.value === '' && option.identifier === '';
      const isUsed = this.multiChosenData.collector.collection.some(
        (row) => row[0].value.value === option.identifier,
      );
      return !isEmpty && !isUsed;
    });
    return filteredOptions;
  }

  get hasOptionsLeft(): boolean {
    return this.options.length > 0;
  }

  addRow(option: SelectionObject): void {
    this.multiChosenData.collector.addRow(option);
    if (!this.hasOptionsLeft) {
      this.choseMenu.dismiss();
    }
  }
}
