<ion-card color="light" class="no-margin">
  <ion-card-header>
    <ion-card-title data-api-id="last-activities-list">
      <h2>{{ 'last-activities.title' | translate }}</h2>
    </ion-card-title>
  </ion-card-header>
  <ion-card-content class="outer-card">
    <ion-row *ngIf="!isLoading">
      <ion-col id="last-activities">
        <ion-row class="activity-by-date-card" *ngFor="let activity of slicedGroupedActivities">
          <ion-col class="date-container ion-no-padding" size="12" sizeXl="3.5">
            <div class="line ion-hide-xl-down"></div>
            <div class="date-text">
              <p>{{ activity.date | localeDate: 'longDate' }}</p>
            </div>
          </ion-col>
          <ion-col class="inner-card ion-no-padding" size="12" sizeXl="8.25">
            <ion-list>
              <ion-item lines="none" *ngFor="let event of activity.activities">
                <span class="date p" slot="start">
                  <span class="time"> {{ event.date | time: 'shortTime' : true }}</span>
                </span>
                <fa-icon
                  class="icon ion-text-center"
                  [icon]="lastActivityTypeToIcon(event.type)"></fa-icon>
                <ion-label class="ion-text-wrap ion-no-margin">
                  <p>{{ event.title }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>

    <ion-row id="skeleton" *ngIf="isLoading">
      <ion-col>
        <ion-row *ngFor="let i of [1, 2, 3]" class="activity-by-date-card ion-align-items-center">
          <ion-col class="date-container ion-no-padding" sizeXl="2.75" size="6">
            <ion-skeleton-text
              style="height: 25px; border-radius: var(--radius-small); margin: 0"
              animated></ion-skeleton-text>
          </ion-col>
          <ion-col size="0.75" class="ion-hide-xl-down"></ion-col>
          <ion-col class="inner-card ion-no-padding" sizeXl="8.25" size="12">
            <ion-skeleton-text
              style="height: 75px; border-radius: var(--radius-card); margin: 0"
              animated>
            </ion-skeleton-text>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-card-content>
</ion-card>
