export class CustomHttpQueryParams {
  static readonly publicUser: CustomHttpQueryParam = {
    key: 'public-user',
    value: 'PUBLICUSER',
  };
}

export interface CustomHttpQueryParam {
  key: string;
  value: string;
}
