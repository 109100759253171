import { Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { faMagnifyingGlass, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { PopoverController } from '@ionic/angular';
import {
  DataType,
  DynamicDataField,
  SelectionObject,
} from 'projects/core/src/lib/models/form.model';
import { BehaviorSubject } from 'rxjs';
import { filterOptions } from '../../../functions/selection-options-filter.functions';

@Component({
  selector: 'app-my-custom-select-popover',
  styleUrls: ['./selection-field-popover.component.scss'],
  templateUrl: './selection-field-popover.component.html',
})
export class SelectionFieldPopoverComponent {
  @Input({ required: true }) selectedOption: SelectionObject;
  @Input() triggerOnSearchSubject: BehaviorSubject<string>;
  @Input() item: DynamicDataField;

  @ViewChild('searchField') searchField: ElementRef<HTMLInputElement>;
  @ViewChildren('searchListItem', { read: ElementRef }) searchListItems: QueryList<ElementRef>;

  search: string = '';

  constructor(private popoverController: PopoverController) {}

  ionViewDidEnter() {
    this.focusSearchField();
  }

  focusSearchField() {
    this.searchField?.nativeElement.focus();
  }

  updateSearchEvent() {
    if (this.triggerOnSearchSubject) {
      this.triggerOnSearchSubject.next(this.search);
    }
  }

  readonly ICONS = {
    RESET: faRotateLeft,
    SEARCH: faMagnifyingGlass,
  };

  selectOption(option: SelectionObject) {
    this.popoverController.dismiss(option);
  }

  isSelected(option: SelectionObject) {
    return this.selectedOption.identifier === option.identifier;
  }

  resetSearchField() {
    this.search = '';
    this.updateSearchEvent();
    this.focusSearchField();
  }

  get showSearchField(): boolean {
    const hasOverMoreOptionsThanLimit = (this.item.value.options?.length || 0) > 4;
    const isAutocompleteField = this.item.type === DataType.autocomplete;
    return hasOverMoreOptionsThanLimit || isAutocompleteField;
  }

  get filteredOptions(): SelectionObject[] {
    const currentValue = this.search ?? '';
    const currentOptions = this.item.value.options ?? [];
    if (this.showSearchField) {
      return filterOptions(currentOptions, currentValue);
    } else {
      return currentOptions;
    }
  }

  get autocompleteOptionsAreLoading(): boolean {
    return this.item.value.autocompleteOptions?.isLoadingOptions;
  }

  focusFirstListElement() {
    const firstItem = this.searchListItems.first;
    if (firstItem) {
      firstItem.nativeElement.focus();
    }
  }

  focusLastListElement() {
    const lastItem = this.searchListItems.last;
    if (lastItem) {
      lastItem.nativeElement.focus();
    }
  }
}
