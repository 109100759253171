import { Injectable } from '@angular/core';
import { APIError } from 'projects/core/src/lib/data/errors.data';
import {
  TableHeaderItem,
  TableList,
  TableListItem,
  TableType,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { TieTableObjectList } from 'projects/core/src/lib/models/sdapi-table-object.model';
import {
  MedicalCertificatesWidgetRow,
  MedicalCertificateTypeIdentifier,
} from '../models/medical-certificates.model';
import { TableMapper } from './table.mapper';

@Injectable({ providedIn: 'root' })
export class MedicalCertificatesMapper {
  private static readonly storageKeyColumnPreferences: string = `medicalCertificatesColumnPreference`;

  public static mapMedicalCertificatesTable(resource: TieTableObjectList): TableList {
    let tableHeader: TableHeaderItem[] = [];
    let tableRows: TableListItem[] = [];

    //TODO: Improve mapping error handling
    try {
      tableHeader = TableMapper.mapTableHeaderResource(resource);
      tableRows = TableMapper.mapTableRowsObjectListResource(resource);
    } catch (error) {
      throw new APIError(
        'Mapping of medicalCertificates failed in the MedicalCertificatesMapper.',
        error,
      );
    }

    return new TableList(tableHeader, tableRows, {
      title: resource.objName,
      storageKey: this.storageKeyColumnPreferences,
      tableType: TableType.medicalCertificates,
    });
  }

  public static mapMedicalCertificatesTableListToMedicalCertificatesWidgetRows(
    tableList: TableList,
  ): MedicalCertificatesWidgetRow[] {
    const indexOfNameAmountDate = {
      name: tableList.initialHeader.findIndex(
        (header) => header.identifier.normalizedValue === MedicalCertificateTypeIdentifier.name,
      ),
      dateFrom: tableList.initialHeader.findIndex(
        (header) => header.identifier.normalizedValue === MedicalCertificateTypeIdentifier.dateFrom,
      ),
      dateTo: tableList.initialHeader.findIndex(
        (header) => header.identifier.normalizedValue === MedicalCertificateTypeIdentifier.dateTo,
      ),
      duration: tableList.initialHeader.findIndex(
        (header) => header.identifier.normalizedValue === MedicalCertificateTypeIdentifier.duration,
      ),
      status: tableList.initialHeader.findIndex(
        (header) => header.identifier.normalizedValue === MedicalCertificateTypeIdentifier.status,
      ),
    };

    return tableList.initialRows.map((row) => {
      const name = row.columns[indexOfNameAmountDate.name];
      const dateFrom = row.columns[indexOfNameAmountDate.dateFrom];
      const dateTo = row.columns[indexOfNameAmountDate.dateTo];
      const duration = row.columns[indexOfNameAmountDate.duration];
      const status = row.columns[indexOfNameAmountDate.status];
      const id = row.id;
      return {
        name: name ? name : '',
        dateFrom: dateFrom ? dateFrom : '',
        dateTo: dateTo ? dateTo : '',
        duration: duration ? duration : '',
        status: status ? status : '',
        behaviourInvokers: row.behaviorInvokers,
        id,
      };
    });
  }
}
