import { DataType, DynamicDataField, FormatOptions } from './form.model';
import { DataFieldValueType } from './sdapi-form-object.model';
import { TieTableProcessor } from './sdapi-table-object.model';
import { AttributeNameIdentifier } from './shared.model';

export class AttributeDetail {
  public key: string;
  public value: AttributeDetailValue;
  public label: string;
  constructor(
    formField?: DynamicDataField,
    table?: TieTableProcessor,
    identifier?: AttributeNameIdentifier,
    value?: string,
  ) {
    if (formField) {
      this.setAttributeDetailsBasedOnFormField(formField);
    } else if (table) {
      this.setAttributeDetailsBasedOnTableList(identifier, value, table);
    } else {
      console.error(
        'AttributeDetail requires either a form field or a combination of a table list, identifier, and value to be constructed..',
      );
    }
  }

  private setAttributeDetailsBasedOnTableList(
    identifier: AttributeNameIdentifier,
    value: string,
    table: TieTableProcessor,
  ): void {
    this.key = identifier.normalizedValue;
    this.label = table.names.get(identifier.normalizedValue);
    const formatOptions = new FormatOptions(table.formats.get(identifier.normalizedValue));
    this.value = {
      value,
      type: table.types.get(identifier.normalizedValue),
      format: formatOptions.formattedString,
    };
  }

  private setAttributeDetailsBasedOnFormField(field: DynamicDataField): void {
    this.key = field.identifier.normalizedValue;
    this.label = field.name;
    this.value = {
      value: field.value.value,
      type: field.type,
      format: field.formatOptions.formattedString,
    };
  }
}

export class AttributeDetailValue {
  value: string;
  type: DataFieldValueType | DataType;
  format?: string;
}
