import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faCloudArrowDown,
  faDiamond,
  faDrawPolygon,
  faFileCode,
  faFont,
  faPaintbrush,
  faPalette,
} from '@fortawesome/free-solid-svg-icons';
import JSZip from 'jszip';
import { FONTFACE_NAME, THEME_PROPERTIES } from 'projects/core/src/lib/data/theme.data';
import {
  ColorSelector,
  FontAlign,
  FontSelector,
  Logo,
  Theme,
  ThemePropertySelector,
} from 'projects/core/src/lib/models/theme.model';
import { DomSanitizerService } from 'projects/core/src/lib/services/dom-sanitizer.service';
import { DownloadService } from 'projects/core/src/lib/services/download.service';
import { ThemeService } from 'projects/core/src/lib/services/theme.service';

@Component({
  selector: 'lib-themer',
  templateUrl: './themer.component.html',
  styleUrls: ['./themer.component.scss'],
})
export class ThemerComponent {
  @ViewChild('themeString') themeStringElement: ElementRef;

  readonly ICONS = {
    DOWNLOAD: faCloudArrowDown,
    DESIGN: faPaintbrush,
    FONT: faFont,
    PROPERTIES: faDrawPolygon,
    LOGO: faDiamond,
    COLORS: faPalette,
    JSON: faFileCode,
    LEFT: faAlignLeft,
    CENTER: faAlignCenter,
    RIGHT: faAlignRight,
    JUSTIFY: faAlignJustify,
  };

  themeProperties = THEME_PROPERTIES;

  fontDescription = {
    p: 'Bodycopy',
    p1: 'Input text for forms, checkbox labels',
    p2: 'Secondary document information, segment buttons',
    p3: 'buttons & segment buttons',
    p4: 'Menu buttons',
    a: 'Links',
    'label-input': 'User Input Labels',
    h: 'all Headlines',
    h1: 'Primary Headlines',
    h2: 'Secondary Headlines',
    h3: 'Section Headlines',
    h4: 'Landing page header',
    'h1-mobile': 'Primary Headlines',
    'h2-mobile': 'Secondary Headlines',
    'h3-mobile': 'Section Headlines',
    'h4-mobile': 'Landing page header',
    'h-mobile': 'all Headline',
    'p-mobile': 'Bodycopy',
    'p2-mobile': 'Secondary document information, segment buttons',
  };

  theme: Theme = {
    color: [],
    font: [],
    properties: [],
  };

  domain = '';

  constructor(
    private themeService: ThemeService,
    private domSanitizerService: DomSanitizerService,
    private downloadService: DownloadService,
  ) {}

  loadTheme() {
    this.themeService.loadLocalTheme(JSON.stringify(this.theme));
  }

  getThemeBlob(): string {
    return this.domSanitizerService.sanitizeUrl(
      'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.theme)),
    ) as string;
  }

  setTheme(event) {
    const fileReader: FileReader = new FileReader();
    this.domain = event.target.files[0].name.split('.theme.json')[0];
    fileReader.readAsText(event.target.files[0]);
    fileReader.onload = () => {
      this.theme = {};
      this.theme = JSON.parse(fileReader.result as string) as Theme;
      this.loadTheme();
    };
  }

  async changeLogo(event) {
    const url = await this.createObjectURL(event.target.files[0]);
    if (!this.theme.logo) {
      this.theme.logo = { url };
    } else {
      this.theme.logo.url = url;
    }
    this.loadTheme();
  }

  setLogoPadding(event, selector: 'padding' | 'paddingMobile') {
    const padding = (event.target as HTMLInputElement).value;
    if (!this.theme.logo) {
      const logo: Logo = { url: '' };
      logo[selector] = padding + 'px';
      this.theme.logo = logo;
    } else {
      this.theme.logo[selector] = padding + 'px';
    }
    this.loadTheme();
  }

  getPropertySelectors(): ThemePropertySelector[] {
    return Object.values(ThemePropertySelector) as ThemePropertySelector[];
  }

  setProperty(event, selector) {
    const radius = (event.target as HTMLInputElement).value;
    if (
      !this.theme.properties.find((propertie) => {
        if (propertie.selector === selector) {
          propertie.value = radius + 'px';
          return true;
        } else {
          return false;
        }
      })
    ) {
      this.theme.properties = [...this.theme.properties, { selector, value: radius + 'px' }];
    }
    this.loadTheme();
  }

  getColorSelectors(): ColorSelector[] {
    return Object.values(ColorSelector) as ColorSelector[];
  }

  setColor(event, selector: ColorSelector) {
    const value = (event.target as HTMLInputElement).value;
    if (
      !this.theme.color.find((color) => {
        if (color.selector === selector) {
          color.value = value;
          return true;
        } else {
          return false;
        }
      })
    ) {
      this.theme.color = [...this.theme.color, { selector, value }];
    }
    this.loadTheme();
  }

  getFontSelectors(): FontSelector[] {
    return Object.values(FontSelector) as FontSelector[];
  }

  setFontSize(event, selector: FontSelector) {
    const fontSize = (event.target as HTMLInputElement).value + 'px';
    if (
      !this.theme.font.find((font) => {
        if (font.selector === selector) {
          font.fontSize = fontSize;
          return true;
        } else {
          return false;
        }
      })
    ) {
      this.theme.font = [...this.theme.font, { selector, fontSize }];
    }
    this.loadTheme();
  }

  setFontLineHeight(event, selector: FontSelector) {
    const lineHeight = (event.target as HTMLInputElement).value + 'px';
    if (
      !this.theme.font.find((font) => {
        if (font.selector === selector) {
          font.lineHeight = lineHeight;
          return true;
        }
        return false;
      })
    ) {
      this.theme.font = [...this.theme.font, { selector, lineHeight }];
    }
    this.loadTheme();
  }

  setFontAlign(event, selector: FontSelector) {
    const textAlign = (event.target as HTMLInputElement).value as FontAlign;
    if (
      !this.theme.font.find((font) => {
        if (font.selector === selector) {
          font.textAlign = textAlign;
          return true;
        }
        return false;
      })
    ) {
      this.theme.font = [...this.theme.font, { selector, textAlign }];
    }
    this.loadTheme();
  }

  async insertFont(event, selector: FontSelector) {
    const file = event.target.files[0];
    if (!this.hasValidFontFileType(file)) {
      throw Error('Invalid Font File');
    }
    const url = this.replaceBase64FontMIMEType(await this.createObjectURL(file), file);
    if (
      !this.theme.font.find((font) => {
        if (font.selector === selector) {
          font.url = url;
          return true;
        }
        return false;
      })
    ) {
      this.theme.font = [...this.theme.font, { selector, url }];
    }
    this.loadTheme();
  }

  hasValidFontFileType(file: File, allowedFileTypes: string[] = ['.otf', '.ttf']): boolean {
    return allowedFileTypes.includes(file.name?.slice(-4));
  }

  replaceBase64FontMIMEType(base64URL: string, file: File): string {
    return base64URL.replace(/.*?;/, `data:font/${file.name.slice(-3)};`);
  }

  setFontWeight(event, selector: FontSelector) {
    const fontWeight = (event.target as HTMLInputElement).value;
    if (
      !this.theme.font.find((font) => {
        if (font.selector === selector) {
          font.fontWeight = fontWeight;
          return true;
        } else {
          return false;
        }
      })
    ) {
      this.theme.font = [...this.theme.font, { selector, fontWeight }];
    }
    this.loadTheme();
  }

  createObjectURL(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        const fileReader: FileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result as string);
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  getThemeDomain(): string {
    return this.domain.match(/(\w|-)*/g).join('') || 'default';
  }

  async downloadThemeZIP(): Promise<void> {
    await this.themeService.loadLocalTheme(JSON.stringify(this.theme));

    const prefix = this.getThemeDomain();
    const zip = new JSZip();
    const theme = zip.folder(prefix);

    // CSS
    let css = '';
    css += ':root {\n';
    if (this.theme.logo) {
      const [mime, data] = this.theme.logo.url.split(';base64,');
      const fileExtension = mime.split('/')[1].split('+')[0];
      theme.file(`logo.${fileExtension}`, data, { base64: true });
      css += `  --logo: url(shared/assets/themes/${prefix}/logo.${fileExtension});\n`;
    }
    css += this.themeService.getCSSPropertyList();
    css += '} /* end of :root */ \n';

    theme.file('style.css', css);

    // CUSTOM CSS
    if (this.theme.css) {
      theme.file('custom.css', this.theme.css);
    }

    // FONTS
    if (this.theme.font.length) {
      const fonts = theme.folder('fonts');
      let fontCSS = '';
      for (const font of this.theme.font) {
        if (font.url) {
          const [mime, data] = font.url.split(';base64,');
          const fileExtension = mime.split('/')[1] || 'otf';
          fonts.file(`${font.selector}.${fileExtension}`, data, { base64: true });
          fontCSS += `@font-face {\n  font-family: "${this.transformFontSelector(
            font.selector,
          )}";\n  src: url(shared/assets/themes/${prefix}/fonts/${
            font.selector
          }.${fileExtension});\n}\n`;
        }
      }
      theme.file('fonts.css', fontCSS);
    }

    // JSON
    theme.file(`${prefix}.exchange.theme.json`, JSON.stringify(this.theme));
    theme.file(
      `${prefix}.theme.json`,
      JSON.stringify(this.getURLTransformedTheme(this.theme, prefix)),
    );

    zip
      .generateAsync({ type: 'blob' })
      .then((blob: Blob) => this.downloadService.handleDownload(`${prefix}.theme`, null, blob));
  }

  transformFontSelector(selector: FontSelector): string {
    return FONTFACE_NAME.get(selector);
  }

  getURLTransformedTheme(theme: Theme, prefix: string): Theme {
    const modifiedTheme: Theme = structuredClone(theme);

    if (modifiedTheme.font) {
      for (const font of modifiedTheme.font) {
        if (font.url?.includes(';base64,')) {
          const [mime] = font.url.split(';base64,');
          const fileExtension = mime.split('/')[1] || 'otf';
          font.url = `shared/assets/themes/${prefix}/fonts/${font.selector}.${fileExtension}`;
        }
      }
    }
    if (modifiedTheme.logo?.url && modifiedTheme.logo?.url.includes(';base64,')) {
      const [mime] = this.theme.logo.url.split(';base64,');
      const fileExtension = mime.split('/')[1].split('+')[0];
      modifiedTheme.logo.url = `shared/assets/themes/${prefix}/logo.${fileExtension}`;
    }

    return modifiedTheme;
  }
}
