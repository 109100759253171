import { MenuItem } from 'projects/shared/src/lib/models/menu.model';
import { ExternalPageData } from 'projects/shared/src/lib/models/external-page.model';

export class ExternalPageMapper {
  static map(menuItem: MenuItem): ExternalPageData {
    return {
      src: menuItem.url,
      title: menuItem.title,
    };
  }
}
