import { Component, Input } from '@angular/core';
import { faRotateLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DataField } from 'projects/core/src/lib/models/data-exchange.model';

/**
 * @deprecated
 */
@Component({
  selector: 'lib-data-exchange-form',
  templateUrl: './data-exchange-form.component.html',
  styleUrls: ['./data-exchange-form.component.scss'],
})
@UntilDestroy()
export class DataExchangeFormComponent {
  @Input() title: string;
  @Input() list: DataField[];
  telNumberPattern = /(^\+|^0)[1-9][\d\s]{6,14}$/;

  readonly ICONS = {
    CLOSE: faXmark,
    RESET: faRotateLeft,
  };

  constructor(private modalController: ModalController) {}

  send() {
    return this.modalController.dismiss(
      this.list.filter(({ value, originValue }) => value !== originValue),
      'confirm',
    );
  }

  cancel() {
    return this.modalController.dismiss('data', 'cancel');
  }

  reset(item: DataField): void {
    item.value = item.originValue;
  }

  equalsOrigin(item: DataField): boolean {
    return item.value === item.originValue;
  }

  getCurrentDate() {
    return new Date().toISOString();
  }
}
