@if (displayLabel) {
  <label [for]="customHtmlId">
    {{ item.name + (item.required ? ' * ' : '') }}
  </label>
}
@if (item.isProcessed) {
  <div class="form-input-loader">
    <img src="shared/assets/images/loading-spinner.svg" alt="Loading Animation" />
    <span class="p strong">{{ 'shared.forms.refresh-options' | translate }}</span>
  </div>
} @else {
  <div
    class="form-input"
    #formInput
    [ngClass]="{
      disabled: !isEditableField,
      edited: isEdited,
    }">
    <ng-content select="[inputPart]"></ng-content>
    <button
      class="reset-button-test-class reset-button-new"
      (click)="resetValue()"
      role="button"
      type="button"
      [tabindex]="resetButtonTabIndex"
      [ariaLabel]="'shared.forms.reset-input-field' | translate"
      *ngIf="shouldShowResetButton"
      [disabled]="!item.isModified">
      <fa-icon [icon]="ICONS.RESET" slot="icon-only"></fa-icon>
    </button>
  </div>
}

<div [id]="'errors-and-hints-' + customHtmlId">
  @if (showErrors) {
    <div class="error-text">
      <span *ngFor="let error of input?.errors | keyvalue">
        {{ error.key | translate: error.value.params }}
      </span>
    </div>
  }
  <div class="warn-text">
    <ng-content class="warn-text" select="[hintPart]"></ng-content>
  </div>
</div>
