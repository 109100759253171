<pp-base-field [item]="item" [customHtmlId]="customHtmlId" [input]="input" [preview]="preview">
  <ng-container inputPart>
    <p class="input-preview" *ngIf="!isEditableField">
      {{ item.value.value || (placeholderKey | translate) }}
    </p>
    <textarea
      [ngClass]="{ 'hidden-form-element': !isEditableField }"
      [id]="customHtmlId"
      type="text"
      [(ngModel)]="item.value.value"
      [name]="item.identifier.originalValue"
      [placeholder]="placeholderKey | translate"
      [disabled]="!isEditableField"
      #input="ngModel"
      [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
      [attr.aria-required]="item.required"
      maxlength="{{ item.value.limiters?.max || '' }}"
      [attr.rows]="item.value?.limiters?.rows || 2">
    </textarea>
  </ng-container>
  <ng-container hintPart *ngIf="ifOnLimitGetTranslationKeyWithParams">
    {{
      ifOnLimitGetTranslationKeyWithParams.key
        | translate: ifOnLimitGetTranslationKeyWithParams.params
    }}
  </ng-container>
</pp-base-field>
