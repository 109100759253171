<pp-base-field [item]="item" [customHtmlId]="customHtmlId" [input]="input" [preview]="preview">
  <ng-container inputPart>
    <div
      [ngClass]="{ valid: item?.value?.value }"
      class="file-input-container"
      [attr.placeholder]="
        item.value?.value || ('shared.upload-form.please-select-file' | translate)
      ">
      <input
        #input="ngModel"
        [(ngModel)]="fileName"
        [id]="customHtmlId"
        type="file"
        (change)="fileChanged($event)"
        [name]="item.identifier.originalValue"
        placeholder="{{
          item.value?.value || ('shared.upload-form.please-select-file' | translate)
        }}"
        [disabled]="!isEditableField"
        [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
        [attr.aria-required]="item.required"
        [accept]="fileRestrictions" />
    </div>
  </ng-container>
</pp-base-field>
