import { InjectionToken } from '@angular/core';

export const API_CONFIG: InjectionToken<ApiConfig> = new InjectionToken<ApiConfig>(
  'API Configuration',
);

export class ApiConfig {
  apiUrl: string;
  apiSuffix: string;
  authCheckPath = '/authclient/testauth';
}
