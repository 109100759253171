import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { API_CONFIG, ApiConfig } from 'projects/core/src/lib/models/api-config.model';
import { CLIENT_CONFIG, ClientConfig } from 'projects/core/src/lib/models/client.model';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

dayjs.extend(utc);
dayjs.extend(timezone);

Promise.all([
  fetch('shared/assets/config/static/api.json'),
  fetch('shared/assets/themes/client.config.json'),
])
  .then((responses) => Promise.all(
      responses.map((response) => response.json()),
    ))
  .then(([apiConfigResource, clientConfigResource]) => {
    const apiConfig: ApiConfig = Object.assign(
      new ApiConfig(),
      apiConfigResource as unknown as ApiConfig,
    );
    const clientConfigs: ClientConfig[] = clientConfigResource as unknown as ClientConfig[];
    const clientConfigByHostname: ClientConfig = ClientConfigService.chooseClientConfigByHostname(
      clientConfigs,
      document.location.hostname,
    );

    if (environment.production) {
      enableProdMode();
    }

    dayjs.tz.setDefault(clientConfigByHostname.appTimeZone);

    platformBrowserDynamic([
      { provide: API_CONFIG, useValue: apiConfig },
      { provide: CLIENT_CONFIG, useValue: clientConfigByHostname },
    ])
      .bootstrapModule(AppModule)
      .catch((error) => console.error(error));
  })
  .catch((error) => {
    console.error('Something went wrong reading the api.json or client.config.json', error);
    alert(
      'Something went wrong reading the api.json or client.config.json. Please check the console for further information',
    );
  });
