<ion-item (click)="treatmentClicked.emit(treatment.id)" lines="none">
  <ion-label class="ion-text-nowrap">
    <lib-auto-layout class="row-label" [gap]="[10]">
      <lib-auto-layout [grow]="1">
        <span class="title trim-text-inline">
          {{ treatment.title }}
        </span>
      </lib-auto-layout>
      <span class="date p3">
        {{ !treatment.date ? '' : (treatment.date | localeDate: 'shortDate') }}
      </span>
    </lib-auto-layout>
  </ion-label>
</ion-item>
