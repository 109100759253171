import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
} from '@angular/core';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { AppUpdateService } from 'projects/core/src/lib/services/app-update.service';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { LanguageService } from 'projects/core/src/lib/services/language.service';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import { RouterService } from 'projects/core/src/lib/services/router.service';

@Component({
  selector: 'pp-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  @HostBinding('class.loaded')
  initialLoading = false;

  @HostListener('window:popstate', ['$event'])
  async onPopState() {
    await this.popupService.dismissTopOverlayAlert();
    await this.popupService.dismissTopOverlayModal();
    await this.popupService.dismissTopOverlayPopover();
  }

  type = 'overview';
  themeMode = false;

  constructor(
    private update: AppUpdateService,
    private popupService: PopupService,
    private clientConfig: ClientConfigService,
    private routerService: RouterService,
    private languageService: LanguageService,
    private el: ElementRef,
  ) {
    this.languageService.handleLanguageChange(el);
  }

  ngOnInit(): void {
    this.languageService.setHtmlLangAttribute(this.el);
    this.initializeThemeModeFromConfig();
    this.handleInitialLoadingOnNavigationEnd();
  }

  ngAfterViewInit(): void {
    this.update.callUpdate();
  }

  private initializeThemeModeFromConfig(): void {
    const clientConfig: ClientConfig = this.clientConfig.get();
    this.themeMode = clientConfig.activeModules.themeEditor;
  }

  private handleInitialLoadingOnNavigationEnd(): void {
    this.routerService.waitForRouterNavigationToEnd().then(() => {
      this.initialLoading = true;
    });
  }
}
