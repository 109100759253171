<div class="appointment-list">
  <ng-container *ngIf="mappedAppointmentGroups">
    <ng-container *ngFor="let appointmentGroup of mappedAppointmentGroups.past">
      <ng-container
        *ngTemplateOutlet="
          dateDivider;
          context: { $implicit: appointmentGroup.date }
        "></ng-container>
      <ng-container
        *ngTemplateOutlet="
          appointmentItem;
          context: { $implicit: appointmentGroup }
        "></ng-container>
    </ng-container>
    <div class="headline-divider">
      <h3>
        {{ 'shared.appointment-calendar.past-appointments' | translate }}
      </h3>
      <fa-icon [icon]="ICON.pastAppointment"></fa-icon>
    </div>
    <div #scrollPoint></div>
    <div class="future-appointments">
      <ng-container *ngFor="let appointmentGroup of mappedAppointmentGroups.future">
        <ng-container
          *ngTemplateOutlet="
            dateDivider;
            context: { $implicit: appointmentGroup.date }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            appointmentItem;
            context: { $implicit: appointmentGroup }
          "></ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #dateDivider let-data>
  <lib-appointment-date-divider
    [date]="data"
    [selectedDate]="selectedDate"
    [selectedAppointment]="selectedAppointment"
    [mappedAppointmentGroups]="mappedAppointmentGroups">
  </lib-appointment-date-divider>
</ng-template>

<ng-template #appointmentItem let-data>
  <lib-appointment-item
    [appointmentGroup]="data"
    [selectedDate]="selectedDate"
    [selectedAppointment]="selectedAppointment"
    [mappedAppointmentGroups]="mappedAppointmentGroups"
    (onSelectAppointment)="onSelectAppointment.emit($event)">
  </lib-appointment-item>
</ng-template>
