import { Pipe, PipeTransform } from '@angular/core';
import { DocumentFormat } from 'projects/core/src/lib/models/documents.model';

@Pipe({
  name: 'mimeTypeToDocumentType',
})
export class MimeTypeToDocumentFormatPipe implements PipeTransform {
  transform(mimeType: string): DocumentFormat {
    return MimeTypeToDocumentFormatPipe.transformStatic(mimeType);
  }

  static transformStatic(mimeType: string): DocumentFormat {
    switch (mimeType) {
      case 'application/pdf':
        return DocumentFormat.PDF;
      case 'image/dicom':
      case 'application/dicom':
        return DocumentFormat.DICOM;
      case 'text/html':
        return DocumentFormat.HTML;
      case 'video/mp4':
      case 'video/mpeg':
        return DocumentFormat.VIDEO;
      case 'image/jpeg':
      case 'image/pjpeg':
      case 'image/png':
      case 'image/x-png':
      case 'image/bmp':
      case 'image/tiff':
        return DocumentFormat.IMAGE;
      case 'audio/mp3':
      case 'audio/mpeg3':
      case 'audio/mpeg':
      case 'audio/mpg':
      case 'audio/wav':
      case 'audio/x-wav':
      case 'audio/aiff':
      case 'audio/x-aiff':
      case 'audio/midi':
      case 'audio/x-acc':
        return DocumentFormat.AUDIO;
      case 'application/x-compressed':
      case 'application/x-zip':
      case 'application/zip':
      case 'application/x-zip-compressed':
      case 'application/s-compressed':
      case 'multipart/x-zip':
        return DocumentFormat.ARCHIVE;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformatsofficedocument.spreadsheetml.template':
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
      case 'application/vnd.ms-excel.template.macroEnabled.12':
      case 'application/vnd.ms-excel.addin.macroEnabled.12':
      case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
        return DocumentFormat.EXCEL;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
      case 'application/vnd.msfontobject':
        return DocumentFormat.WORD;
      case 'application/vnd.openxmlformats-officedocument.presentationml.pr':
      case 'application/vnd.ms-powerpoint':
        return DocumentFormat.POWERPOINT;
      default:
        return DocumentFormat.UNKNOWN;
    }
  }
}
