import { Component } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import { PopoverController } from '@ionic/angular';
import { LanguageService } from 'projects/core/src/lib/services/language.service';

@Component({
  selector: 'lib-date-field-select-time-or-date-popover',
  templateUrl: './date-field-select-time-or-date-popover.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrl: './date-field-select-time-or-date-popover.component.scss',
})
export class DateFieldSelectTimeOrDatePopoverComponent {
  constructor(
    private popoverController: PopoverController,
    public languageService: LanguageService,
  ) {}
  readonly icons = {
    calendar: faCalendar,
    clock: faClock,
  };
  dateSelected() {
    this.popoverController.dismiss('date');
  }
  timeSelected() {
    this.popoverController.dismiss('time');
  }
}
