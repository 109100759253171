import { ElementRef, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { RouterService } from 'projects/core/src/lib/services/router.service';
import { firstValueFrom, take } from 'rxjs';
import { ClientConfig, I18nOverwrite } from '../models/client.model';
import { DynamicDataField, DynamicForm } from '../models/form.model';
import { InvokerTypes } from '../models/invoker-body.model';
import {
  DefaultLanguageCode,
  ProfileLanguageCode,
  profileLanguageCodeToId,
  profileLanguageIdToCode,
} from '../models/language.model';
import { ProfileFieldKey } from '../models/profile.model';
import { SDAPIResponseObject } from '../models/sdapi-object.model';
import { AttributeNameIdentifier } from '../models/shared.model';
import { AuthService } from './auth.service';
import { FormService } from './form.service';
import { ProfileService } from './profile.service';
import { SDAPIService } from './sdapi.service';
import { STORAGE_KEY_SELECTED_LANGUAGE_CODE, StorageService } from './storage.service';

@Injectable()
@UntilDestroy()
export class LanguageService {
  readonly languageStorageKey: string = STORAGE_KEY_SELECTED_LANGUAGE_CODE;

  constructor(
    private profileService: ProfileService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private authService: AuthService,
    private formService: FormService,
    private routerService: RouterService,
    private sdapiService: SDAPIService,
    private clientConfigService: ClientConfigService,
  ) {}

  async configureLanguage(): Promise<void> {
    const isLoggedIn: boolean = await firstValueFrom(this.authService.isLoggedIn());
    await this.applyTextOverwrites();
    await this.resolveLanguagePreference(isLoggedIn);
  }

  private async applyTextOverwrites(): Promise<void> {
    const clientConfig: ClientConfig = this.clientConfigService.get();
    const languages = Object.values(ProfileLanguageCode);

    const i18nInstances = await Promise.all(
      languages.map((language) => firstValueFrom(this.translateService.getTranslation(language))),
    );

    const i18nInstancesKeyValue = {};
    languages.forEach((language, index) => {
      i18nInstancesKeyValue[language] = i18nInstances[index];
    });

    for (const overwrite of clientConfig.customizableStrings) {
      this.setI18nOverwriteForLanguages(overwrite, i18nInstancesKeyValue, languages);
    }

    for (const language of languages) {
      this.translateService.setTranslation(language, i18nInstancesKeyValue[language]);
    }
  }

  setI18nOverwriteForLanguages(
    overwrite: I18nOverwrite,
    i18nInstancesKeyValue: Object,
    languages: string[],
  ): void {
    for (const language of languages) {
      const key = overwrite.key;
      const keyIsValid = key && key !== '';
      const overwriteIsValid = !!overwrite[language] || overwrite[language] === '';
      if (keyIsValid && overwriteIsValid) {
        this.setI18nOverwrite(i18nInstancesKeyValue[language], key, overwrite[language]);
      }
    }
  }

  private setI18nOverwrite(
    translationsObject: Object,
    nestedKey: string,
    stringReplacement: string,
  ) {
    const keys = nestedKey.split('.');
    let currentObject = translationsObject;

    for (let i = 0; i < keys.length - 1; i++) {
      if (!currentObject[keys[i]]) {
        return;
      }
      currentObject = currentObject[keys[i]];
    }

    currentObject[keys[keys.length - 1]] = stringReplacement;
  }

  private async resolveLanguagePreference(isLoggedIn: boolean): Promise<void> {
    const storedLanguage: string = await this.storageService.get(this.languageStorageKey);

    if (isLoggedIn && !this.routerService.checkIfCurrentRouteEquals('onboarding')) {
      const profileLanguage = await this.retrieveProfileLanguageCode();
      await this.setupLanguage(Object.values(ProfileLanguageCode), profileLanguage);
      if (storedLanguage !== profileLanguage) {
        await this.invalidateSdapiCache();
      }
    } else {
      const selectedLanguage = storedLanguage || this.translateService.defaultLang;
      await this.setupLanguage(Object.values(DefaultLanguageCode), selectedLanguage);
    }
  }

  private async retrieveProfileLanguageCode(): Promise<string> {
    try {
      const profileFormBody: DynamicDataField[] = (await this.getProfileForm()).body;
      const languageField: DynamicDataField = this.extractLanguageSelectionField(profileFormBody);
      return profileLanguageIdToCode[languageField?.value.value] || ProfileLanguageCode.de;
    } catch (e) {
      return ProfileLanguageCode.de;
    }
  }

  private async getProfileForm(): Promise<DynamicForm> {
    return await firstValueFrom(
      this.profileService.getProfileEditForm().pipe(take(1), untilDestroyed(this)),
    );
  }

  public extractLanguageSelectionField(body: DynamicDataField[]): DynamicDataField {
    return body
      .flatMap((group: DynamicDataField) => group.fieldGroup)
      .find((field: DynamicDataField) => {
        const languageFieldIdentifier = new AttributeNameIdentifier(ProfileFieldKey.language);
        return field.identifier.isEqualTo(languageFieldIdentifier);
      });
  }

  private async setupLanguage(availableCodes: string[], selectedCode: string): Promise<void> {
    this.translateService.addLangs(Object.values(availableCodes));
    await this.changeLanguageAndStore(selectedCode);
  }

  public async handleChangedLanguagePreference(
    code: string,
    reloadAfterChange: boolean,
  ): Promise<void> {
    if ((await this.storageService.get(STORAGE_KEY_SELECTED_LANGUAGE_CODE)) === code) {
      return;
    }

    await this.changeLanguageAndStore(code);

    if (reloadAfterChange) {
      window.location.reload();
    }
  }

  private async invalidateSdapiCache() {
    const profileForm = await firstValueFrom(this.profileService.getProfileEditForm());
    const saveInvoker = profileForm.invoker.find((invoker) => invoker.type === InvokerTypes.SAVE);
    if (saveInvoker && saveInvoker.invoker) {
      delete saveInvoker.invoker.parameters;
      await firstValueFrom(this.sdapiService.getResponseFromStepInvoker(saveInvoker));
    }
    window.location.reload();
  }

  private async changeLanguageAndStore(languageCode: string): Promise<void> {
    this.translateService.use(languageCode);
    await this.storageService.set(STORAGE_KEY_SELECTED_LANGUAGE_CODE, languageCode);
  }

  public async syncProfileFormWithSelectedLanguage(selectedCode: string): Promise<void> {
    let profile: DynamicForm = await this.getProfileForm();
    profile = this.updateProfileLanguageValue(profile, selectedCode);
    await this.saveProfileData(profile);
  }

  private async saveProfileData(form: DynamicForm): Promise<void> {
    await firstValueFrom(
      this.formService.saveForm<SDAPIResponseObject>(form).pipe(untilDestroyed(this), take(1)),
    );
  }

  public updateProfileLanguageValue(profile: DynamicForm, selectedCode?: string): DynamicForm {
    const languageField: DynamicDataField = this.extractLanguageSelectionField(profile.body);
    if (languageField) {
      const langCode = selectedCode || this.translateService.currentLang;
      languageField.value.value = profileLanguageCodeToId[langCode];
    }
    return profile;
  }

  public async syncCurrentLanguageWithProfileForm(form: DynamicForm): Promise<void> {
    const languageField: DynamicDataField = this.extractLanguageSelectionField(form.body);
    if (languageField) {
      await this.handleChangedLanguagePreference(
        profileLanguageIdToCode[languageField.value.value],
        true,
      );
    }
  }

  public getCurrentLanguageCode(): string {
    return this.translateService.currentLang || this.translateService.defaultLang;
  }

  private createLangAttributeForHtmlElement(): Attr {
    const langCode = this.getCurrentLanguageCode();
    const langAttribute = document.createAttribute('lang');
    langAttribute.value = langCode;
    return langAttribute;
  }

  public handleLanguageChange(el: ElementRef): void {
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.setHtmlLangAttribute(el);
    });
  }

  public setHtmlLangAttribute(el: ElementRef): void {
    const langElement = this.createLangAttributeForHtmlElement();
    el.nativeElement.ownerDocument.documentElement.attributes.setNamedItem(langElement);
  }
}
