import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
} from 'projects/core/src/lib//models/form.model';
import {
  ExpenseTranslationKeys,
  InvokerMethodCollection,
  ModalResult,
  TranslationOptions,
} from 'projects/core/src/lib//models/modal-action.model';
import { ExpensesMapper } from 'projects/core/src/lib/mappers/expenses.mapper';
import { TableList } from 'projects/core/src/lib/models/dynamic-table.model';
import { Invoker, InvokerMethods } from 'projects/core/src/lib/models/invoker-body.model';
import { TieTableObjectList } from 'projects/core/src/lib/models/sdapi-table-object.model';
import { SDAPIService } from 'projects/core/src/lib/services/sdapi.service';
import { TableDataService } from 'projects/core/src/lib/services/table-data.service';
import { Observable, firstValueFrom, mergeMap } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { ModalActionService } from './modal-action.service';
import { UploadService } from './upload.service';

@Injectable()
export class ExpensesService {
  constructor(
    private http: HttpClient,
    private sdapiService: SDAPIService,
    private tableDataService: TableDataService,
    private uploadService: UploadService,
    private modalActionService: ModalActionService,
  ) {}

  getAllExpenses(): Observable<TableList> {
    return this.getExpensesObjectId().pipe(
      switchMap((expensesObjectId: number) =>
        this.sdapiService.getInvokerByMethodName(
          `${expensesObjectId}`,
          InvokerMethods.objectObjectList,
        ),
      ),
      switchMap((invoker: Invoker) =>
        this.http.put<TieTableObjectList>(invoker.activityURL, invoker.invoker),
      ),
      switchMap((response: TieTableObjectList) =>
        this.mapAndUpdateTableAccordingToUserSettings(response),
      ),
    );
  }

  private mapAndUpdateTableAccordingToUserSettings(
    response: TieTableObjectList,
  ): Observable<TableList> {
    const tableList: TableList = ExpensesMapper.mapExpensesTable(response);
    return this.tableDataService.fetchHeaderPreferencesAndUpdateTable(tableList);
  }

  private getExpensesObjectId(): Observable<number> {
    return this.sdapiService
      .getInvokerByMethodName(
        'MP_EXPENSES_FOLDER_OF_LOGGEDIN_USER',
        InvokerMethods.constraintObjectList,
      )
      .pipe(
        mergeMap((invoker: Invoker) =>
          this.http
            .put<TieTableObjectList>(invoker.activityURL, invoker.invoker)
            .pipe(map((response: TieTableObjectList) => response.items[0].objId)),
        ),
      );
  }

  public getExpenseCreationInvoker(): Observable<Invoker> {
    return this.getExpensesObjectId().pipe(
      concatMap((objectId: number) =>
        this.uploadService.getUploadFormInvoker(
          objectId,
          InvokerMethods.objectCreateAutoImport,
          InvokerMethods.objectCreate,
        ),
      ),
    );
  }

  public async getExpenseCreationForm(): Promise<DynamicForm> {
    const invoker: Invoker = await firstValueFrom(this.getExpenseCreationInvoker());
    return await firstValueFrom(this.uploadService.getDocumentUploadForm(invoker));
  }

  public resolveFormTypeForExpenseCreation(invoker: Invoker): DynamicFormType {
    return this.isExpenseCreationWithMultiUpload(invoker)
      ? DynamicFormType.DIRECT_SAVE
      : DynamicFormType.DOCUMENT_UPLOAD;
  }

  public async showExpenseCreationForm(invoker: Invoker): Promise<ModalResult> {
    const form: DynamicForm = await firstValueFrom(
      this.uploadService.getDocumentUploadForm(invoker),
    );
    const type: DynamicFormType = this.resolveFormTypeForExpenseCreation(invoker);
    const translationOptions: TranslationOptions = this.translationOptionsForExpenseCreation();
    return await this.modalActionService.openDynamicFormModal(
      new DynamicFormConfiguration({
        dynamicForm: form,
        type,
        translationOptions,
      }),
    );
  }

  private translationOptionsForExpenseCreation(): TranslationOptions {
    return {
      keys: ExpenseTranslationKeys,
      successMessageKey: 'create-completion',
      actionInProgressKey: 'saving-in-progress',
    };
  }

  public isExpenseCreationWithMultiUpload(invoker: Invoker): boolean {
    return invoker.invoker.methodName === InvokerMethods.objectCreate;
  }

  public get previewFallbackMethods(): InvokerMethodCollection {
    return {
      preferred: InvokerMethods.objectObjectList,
      default: InvokerMethods.objectAttributesView,
    };
  }
}
