<lib-auto-layout [padding]="[12, 20, 12, 15]" [gap]="[10]" horizontal="stretch" vertical="center">
  @if (table.selectableRows) {
    @if (!row.selectedFix) {
      <div
        class="icon-container align-center"
        (click)="emitSelectEventMessageAndToggleRowSelection()">
        <fa-icon [icon]="row.selected ? icons.squareCheck : icons.square"></fa-icon>
      </div>
    } @else {
      <div class="icon-container align-center">
        <fa-icon [icon]="icons.check"></fa-icon>
      </div>
    }
  }
  <lib-auto-layout [grow]="1" vertical="space-between" direction="vertical">
    <lib-auto-layout direction="vertical" [grow]="1" [gap]="[5]">
      @for (item of visibleHeaderItems; track item.identifier; let i = $index) {
        <lib-auto-layout
          horizontal="space-between"
          [padding]="[0, 5]"
          [grow]="1"
          [gap]="[7]"
          class="primary-items">
          <lib-auto-layout class="row-item" direction="vertical">
            @if (visibleMobileRowValues[i] | isIconCellType: i : table) {
              <fa-icon
                slot="end"
                [icon]="visibleMobileRowValues[i] | documentFormatToIconPipe"></fa-icon>
            } @else {
              <span>
                {{ visibleMobileRowValues[i] | tableValueByType: i : table }}
              </span>
            }
          </lib-auto-layout>

          <lib-auto-layout class="header-item" direction="vertical" horizontal="end">
            <span class="p">{{ item.value }}</span>
          </lib-auto-layout>
        </lib-auto-layout>
      }
    </lib-auto-layout>
  </lib-auto-layout>
  <button class="primary" (click)="emitClickEventMessage()">
    <fa-icon [icon]="table.rowIconMobile"></fa-icon>
  </button>
</lib-auto-layout>
