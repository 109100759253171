<pp-base-field [item]="item" [customHtmlId]="customHtmlId" [input]="input" [preview]="preview">
  <ng-container inputPart>
    <p class="input-preview" *ngIf="!isEditableField">
      {{ item.value.value || placeholderKey | translate }}
    </p>
    <input
      [ngClass]="{ 'hidden-form-element': !isEditableField }"
      [tabIndex]="tabIndex"
      [id]="customHtmlId"
      type="text"
      [(ngModel)]="item.value.value"
      [name]="item.identifier.originalValue"
      [placeholder]="placeholderKey | translate"
      #input="ngModel"
      maxlength="{{ item.value.limiters?.max || '' }}"
      [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
      [attr.aria-required]="item.required" />
  </ng-container>
  <ng-container hintPart *ngIf="ifOnLimitGetTranslationKeyWithParams">
    {{
      ifOnLimitGetTranslationKeyWithParams.key
        | translate: ifOnLimitGetTranslationKeyWithParams.params
    }}
  </ng-container>
</pp-base-field>
