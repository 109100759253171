import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'lib-iframe-viewer',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeViewerComponent implements OnInit {
  @Output() commitButtonsVisibility = new EventEmitter<boolean>();
  @Input() url: SafeUrl;

  ngOnInit() {
    this.commitButtonsVisibility.emit(true);
  }
}
