<ion-content scrollX="false" scrollY="false">
  <div
    id="notification-container"
    class="stretch-self ion-justify-content-center ion-align-items-center">
    <ion-card class="card-transparent">
      <div id="info-section">
        <a id="document-viewer-download-link" class="download-link">
          <fa-icon
            size="5x"
            class="greyish download-request"
            [icon]="document.fileType | documentFormatToIconPipe">
          </fa-icon>
        </a>
        <div>
          <h2>
            <strong>{{ 'shared.documents.file-type' | translate }}: {{ document.fileType }}</strong>
          </h2>
          @if (document.size) {
            <p>{{ 'shared.documents.file-size' | translate }}: {{ document.size | filesize }}</p>
          }
        </div>
      </div>

      <ion-text class="p"> {{ 'shared.documents.document-not-viewable' | translate }}. </ion-text>
      @if (showLargeFileSizeWarning) {
        <lib-notification-box-component
          [padding]="[0]"
          [message]="'core.alert.message.large-file-warning' | translate">
        </lib-notification-box-component>
      }

      <div class="button-section ion-justify-content-center ion-align-items-center">
        <ion-button
          class="p1 ion-no-margin"
          color="primary"
          expand="block"
          (click)="downloadFile(document)">
          <fa-icon size="sm" slot="start" [icon]="ICONS.download"></fa-icon>
          {{ 'general.download' | translate }}
        </ion-button>
        @if (cancelButtonVisibility) {
          <ion-button
            class="p1 ion-no-margin"
            color="cancel-button"
            expand="block"
            (click)="close()">
            {{ 'general.cancel' | translate }}
          </ion-button>
        }
      </div>
    </ion-card>
  </div>
</ion-content>
