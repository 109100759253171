import { IconDefinition } from '@fortawesome/angular-fontawesome/types';

export class AppointmentAttributeNames {
  public static readonly title = 'SYS_APPOINTMENT.DESCRIPTION';
  public static readonly startDate = 'SYS_APPOINTMENT.START_DATE';
  public static readonly endDate = 'SYS_APPOINTMENT.END_DATE';
  public static readonly doctor = 'SYS_ATTRIBUTE.T1';
  public static readonly telephoneNumber = 'SYS_ATTRIBUTE.T2';
  public static readonly location = 'SYS_APPOINTMENT.LOCATION';
}
export class AppointmentDetails {
  startDate: Date;
  endDate?: Date;
  title: string;
  doctor?: string;
  patient?: string;
  clinicPhoneNumber: string | number;
  location: string;
  id: number;
  type: AppointmentType;
  status?: AppointmentStatus;
  isAllDay?: boolean;
}

export enum AppointmentType {
  ONSITE = 'ONSITE',
  REMOTE = 'REMOTE',
}

export class CalendarEvent {
  title: string;
  date: Date;
  appointment: AppointmentDetails;
  icon: IconDefinition;
}

export class TemplateAppointmentsGroup {
  date: Date;
  appointments: AppointmentDetails[];
}

export class ViewAppointmentsGroup {
  past: TemplateAppointmentsGroup[];
  future: TemplateAppointmentsGroup[];
}

export class ListConfiguration {
  expandable?: boolean;
  noAppointmentsMessage?: string;
  skeletonItemCount?: number[];
  hideContactInfo?: boolean;
  appointments?: TemplateAppointmentsGroup[];
  isDoctor: boolean;
  sortDirection: SortDirection = SortDirection.ASC;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export class DateApiRequest {
  from: Date | null;
  to: Date | null;
}

export enum AppointmentViewType {
  OVERVIEW = 'OVERVIEW',
  MONTH = 'MONTH',
  DAY = 'DAY',
}

export class AppointmentStatus {
  label: string;
  hexColor: string;
}
