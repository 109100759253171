import { IconDefinition } from '@fortawesome/angular-fontawesome/types';

export const tieFileKey: IconDefinition = {
  prefix: 'tie',
  iconName: 'file-key',
  icon: [
    512,
    512,
    [],
    '',
    'M64 0H224V128C224 146 238 160 256 160H384V448C384 484 355 512 320 512H64C28 512 0 484 0 448V64C0 29 28 0 64 0ZM201.258 369.719C207.965 372.086 215.066 372.875 222.562 372.875C260.832 372.875 292 342.102 292 303.438C292 265.168 260.832 234 222.562 234C183.898 234 153.125 265.168 153.125 303.438C153.125 310.934 153.914 318.035 156.281 324.742L92.762 388.262C90.789 390.234 90 392.602 90 394.969V426.531C90 432.055 93.945 436 99.469 436H131.031C136.16 436 140.5 432.055 140.5 426.531V410.75H156.281C161.41 410.75 165.75 406.805 165.75 401.281V385.5H181.531C183.898 385.5 186.266 384.711 188.238 382.738L201.258 369.719ZM224.535 279.766C227.297 275.031 232.426 271.875 238.344 271.875C243.867 271.875 248.996 275.031 251.758 279.766C254.52 284.895 254.52 290.812 251.758 295.547C248.996 300.676 243.867 303.438 238.344 303.438C232.426 303.438 227.297 300.676 224.535 295.547C221.773 290.812 221.773 284.895 224.535 279.766ZM256 128V0L384 128H256Z',
  ],
};


