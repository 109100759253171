<ion-header *ngIf="header" class="ion-no-border">
  <ion-toolbar>
    <div class="toolbar-container">
      <div id="navigation-section">
        <ion-button
          *ngIf="header.backNavigation"
          (click)="navigate()"
          color="tertiary"
          class="back-button">
          <fa-icon slot="start" [icon]="icons.back" size="sm"></fa-icon>
          <span id="navigation-back" class="p3" [class.p2]="breakpoint.isBelow('md')">
            {{ buttonText | translate }}
          </span>
        </ion-button>

        <ion-item *ngIf="header.title" class="page-title h1 layout" lines="none">
          <fa-icon *ngIf="header.icon" [size]="titleIconSize" [icon]="header.icon"></fa-icon>
          <h1 id="title" [class.h3]="breakpoint.isBelow('md')">
            <span>{{ pageTitle | translate }}</span>
          </h1>
        </ion-item>
      </div>

      <lib-logo slot="end"></lib-logo>
    </div>
  </ion-toolbar>
</ion-header>
