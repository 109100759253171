export enum DefaultLanguageCode {
  de = 'de',
  en = 'en',
}

export enum DefaultLanguageName {
  german = 'shared.landingpage.footer.language-switch.german',
  english = 'shared.landingpage.footer.language-switch.english',
}

export enum ProfileLanguageCode {
  de = 'de',
  fr = 'fr',
  it = 'it',
  en = 'en',
}

export enum ProfileLanguageId {
  german = '0',
  french = '1',
  italian = '2',
  english = '3',
}

export const defaultLanguageCodeToName: { [key in DefaultLanguageCode]: DefaultLanguageName } = {
  [DefaultLanguageCode.de]: DefaultLanguageName.german,
  [DefaultLanguageCode.en]: DefaultLanguageName.english,
};

export const profileLanguageIdToCode: { [key in ProfileLanguageId]: ProfileLanguageCode } = {
  [ProfileLanguageId.german]: ProfileLanguageCode.de,
  [ProfileLanguageId.english]: ProfileLanguageCode.en,
  [ProfileLanguageId.french]: ProfileLanguageCode.fr,
  [ProfileLanguageId.italian]: ProfileLanguageCode.it,
};

export const profileLanguageCodeToId: { [key in ProfileLanguageCode]: ProfileLanguageId } = {
  [ProfileLanguageCode.de]: ProfileLanguageId.german,
  [ProfileLanguageCode.en]: ProfileLanguageId.english,
  [ProfileLanguageCode.fr]: ProfileLanguageId.french,
  [ProfileLanguageCode.it]: ProfileLanguageId.italian,
};
