<lib-auto-layout
  id="share-with-doctor-section"
  data-api-id="share-treatment-with-doctor"
  [padding]="[10, 20]"
  direction="horizontal"
  horizontal="space-between"
  vertical="center"
  [gap]="[10]">
  <h3 [grow]="1" class="ion-no-margin">
    {{ 'shared.treatments.please-share-with-doctor' | translate }}.
  </h3>
  <ion-button color="light" class="p3 ion-no-margin" (click)="shareWithDoctor()">
    <span>{{ 'general.share' | translate }}</span>
    <fa-icon size="sm" slot="end" [icon]="icons.share"></fa-icon>
  </ion-button>
</lib-auto-layout>
