<ion-app>
  <ion-split-pane
    [ngSwitch]="type"
    *ngIf="themeMode"
    when="md"
    contentId="main"
    class="themer-temporary-fix">
    <lib-themer contentId="main"></lib-themer>
    <ion-content id="main" class="ion-no-padding">
      <ion-header>
        <ion-row>
          <ion-col size="12">
            <ion-segment value="overview" [(ngModel)]="type">
              <ion-segment-button value="overview">
                <ion-label class="p3">{{ 'menu.overview' | translate }}</ion-label>
              </ion-segment-button>
              <ion-segment-button routerLink="/" value="landingpage">
                <ion-label class="p3">{{ 'menu.landingpage' | translate }}</ion-label>
              </ion-segment-button>
              <ion-segment-button routerLink="/onboarding" value="onboarding">
                <ion-label class="p3">{{ 'menu.onboarding' | translate }}</ion-label>
              </ion-segment-button>
              <ion-segment-button routerLink="/portal" value="pp">
                <ion-label class="p3">{{ 'menu.patient-portal' | translate }}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-col>
        </ion-row>
      </ion-header>

      <ion-content id="router-outlet-container">
        <ng-container *ngSwitchCase="'overview'">
          <lib-theme-preview></lib-theme-preview>
          <div id="overview">
            <ion-router-outlet [animated]="false"></ion-router-outlet>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'pp'">
          <ion-router-outlet [animated]="false"></ion-router-outlet>
        </ng-container>

        <ng-container *ngSwitchCase="'onboarding'">
          <ion-router-outlet [animated]="false"></ion-router-outlet>
        </ng-container>

        <ng-container *ngSwitchCase="'landingpage'">
          <ion-router-outlet [animated]="false"></ion-router-outlet>
        </ng-container>
      </ion-content>
    </ion-content>
  </ion-split-pane>

  <ion-router-outlet *ngIf="!themeMode" [animated]="false"></ion-router-outlet>
</ion-app>
