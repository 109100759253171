<div class="pinch-zoom-content" [class.pz-dragging]="isDragging">
  <ng-content></ng-content>
</div>

<!-- Control: one button -->
<div
  class="pz-zoom-button pz-zoom-control-position-bottom"
  [class.pz-zoom-button-out]="isZoomedIn"
  *ngIf="isControl()"
  (click)="toggleZoom()"></div>
