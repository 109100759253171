import { HttpCacheGuard } from '@ngneat/cashew';
import { HttpRequest } from '@angular/common/http';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { Injectable } from '@angular/core';

@Injectable()
export class PortalHttpCacheGuard extends HttpCacheGuard {
  override canActivate(request: HttpRequest<any>): boolean {
    return !request.headers.has(CustomHttpHeaders.XNoCache);
  }
}
