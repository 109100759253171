<div class="linear-container">
  <ng-container *ngFor="let item of group.fieldGroup">
    <lib-field-container
      *ngIf="isVisibleField(item)"
      [group]="group"
      [item]="item"
      [preview]="item?.value?.options?.length === 1"
      [isProcessing]="isProcessing"></lib-field-container>
  </ng-container>
</div>
