import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { OverlayEventDetail } from '@ionic/core';
import { DynamicFormMapper } from 'projects/core/src/lib/mappers/dynamic-form.mapper';
import { RowHeightSet } from 'projects/core/src/lib/models/dynamic-table.model';
import {
  DataType,
  DynamicDataField,
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
} from 'projects/core/src/lib/models/form.model';
import {
  PatientDataTranslationKeys,
  TranslationOptions,
} from 'projects/core/src/lib/models/modal-action.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { DynamicFormService } from 'projects/core/src/lib/services/dynamic-form.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { VirtualScrollService } from 'projects/core/src/lib/services/virtual-scroll.service';

@Component({
  selector: 'lib-patient-list-data',
  templateUrl: './patient-list-data.component.html',
  styleUrls: ['./patient-list-data.component.scss'],
})
export class PatientListDataComponent {
  @Input() list: DynamicDataField[];
  @Input() set editForm(form: DynamicForm) {
    if (form) {
      this.dynamicEditForm = this.mapEditFormForModalView(form);
    }
  }

  @Output() patientDataUpdate = new EventEmitter<string>();

  dynamicEditForm: DynamicForm;
  dataType: typeof DataType = DataType;

  readonly rowHeight: RowHeightSet = new RowHeightSet(47, 52);
  readonly icon = { edit: faPenToSquare };

  constructor(
    private breakpointService: BreakpointService,
    public virtualScrollService: VirtualScrollService,
    private popupService: PopupService,
  ) {}

  get rowHeightValue(): number {
    return this.breakpointService.isBelow('md') ? this.rowHeight.mobile : this.rowHeight.desktop;
  }

  get editFormItems(): DynamicDataField[] {
    return this.dynamicEditForm.body.flatMap((group: DynamicDataField) => group.fieldGroup);
  }

  private mapEditFormForModalView(form: DynamicForm): DynamicForm {
    let dynamicForm: DynamicForm = DynamicFormService.hideFormItemsOfTypeGrid(form);
    return DynamicFormMapper.assignSequentialGridValuesToFormItems(dynamicForm);
  }

  showPatientDataEditModal(): void {
    this.popupService
      .showDynamicFormModal(this.getDynamicFormConfiguration(this.dynamicEditForm))
      .then((response: OverlayEventDetail) => {
        if (response.role === OverlayEventRole.save) {
          this.patientDataUpdate.emit();
        }
      });
  }

  private getDynamicFormConfiguration(form: DynamicForm): DynamicFormConfiguration {
    const translationOptions: TranslationOptions = {
      keys: PatientDataTranslationKeys,
      successMessageKey: 'save-completion',
      actionInProgressKey: 'saving-in-progress',
    };
    return new DynamicFormConfiguration({
      type: DynamicFormType.DIRECT_SAVE,
      dynamicForm: form,
      translationOptions,
    });
  }

  isDisplayableFieldType(item: DynamicDataField): boolean {
    return DynamicFormService.isDisplayableFieldType(item);
  }
}
