import dayjs from 'dayjs';
import { APIError } from '../data/errors.data';
import { AppointmentAttributeNames, AppointmentDetails } from '../models/appointments.model';
import {
  TieTableObjectList,
  TieTableProcessor,
  TieTableRow,
} from '../models/sdapi-table-object.model';
import { StatusInfoLabel } from '../models/shared.model';

export class AppointmentsResourceMapper {
  static mapResource(resource: TieTableObjectList): AppointmentDetails[] {
    const appointmentList: AppointmentDetails[] = [];
    const table: TieTableProcessor = new TieTableProcessor(resource);
    const rows: TieTableRow[] = table.getRows();
    try {
      for (const row of rows) {
        const startDate: Date = AppointmentsResourceMapper.stringToDate(
          row.getOptionalAttributeValue(AppointmentAttributeNames.startDate),
        );

        if (startDate) {
          const appointment = new AppointmentDetails();
          const label = new StatusInfoLabel(row.statusInfo.label);
          appointment.id = row.objId;
          appointment.title = row.getOptionalAttributeValue(AppointmentAttributeNames.title);
          appointment.startDate = startDate;
          appointment.endDate = AppointmentsResourceMapper.stringToDate(
            row.getOptionalAttributeValue(AppointmentAttributeNames.endDate),
          );
          appointment.isAllDay = AppointmentsResourceMapper.isAllDayAppointment(appointment);
          appointment.doctor = row.getOptionalAttributeValue(AppointmentAttributeNames.doctor);
          appointment.clinicPhoneNumber = row.getOptionalAttributeValue(
            AppointmentAttributeNames.telephoneNumber,
          );
          appointment.location = row.getOptionalAttributeValue(AppointmentAttributeNames.location);
          appointment.status = {
            label: label.normalizedValue,
            hexColor: row.statusInfo.color,
          };
          appointmentList.push(appointment);
        } else {
          console.warn(`Appointment with ID ${row.objId} has an invalid START_DATE value.`);
        }
      }
    } catch (error) {
      throw new APIError(
        'Mapping of appointments failed in the AppointmentsResourceMapper.',
        error,
      );
    }

    return appointmentList;
  }

  private static stringToDate(dateString: string): Date | undefined {
    if (dateString) {
      const date = new Date(dateString);
      return !isNaN(date?.getTime?.()) ? date : undefined;
    } else {
      return undefined;
    }
  }

  private static isAllDayAppointment(appointment: AppointmentDetails): boolean | undefined {
    const hasEndDate = !!appointment.endDate;
    if (hasEndDate) {
      const dayInMilliseconds = 24 * 60 * 60 * 1000;
      const timeZonedStartTime = dayjs(appointment.startDate).tz();
      const startsAtMidnight = timeZonedStartTime.hour() === 0 && timeZonedStartTime.minute() === 0;
      const durationIsOneDay =
        appointment.endDate.getTime() - appointment.startDate.getTime() === dayInMilliseconds;
      return startsAtMidnight && durationIsOneDay;
    } else {
      return undefined;
    }
  }
}
