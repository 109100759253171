import {
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { CustomHttpQueryParams } from 'projects/core/src/lib/services/custom-http-query-params';
import { mergeMap } from 'rxjs/operators';

export const PublicUserInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const authService: AuthService = inject(AuthService);
  if (req.headers.get(CustomHttpHeaders.XPublicUserRequest) === 'true') {
    const cleanedHttpHeaders: HttpHeaders = req.headers.delete(
      CustomHttpHeaders.XPublicUserRequest,
    );
    const appendedHttpQueryParams: HttpParams = req.params.append(
      CustomHttpQueryParams.publicUser.key,
      CustomHttpQueryParams.publicUser.value,
    );
    const adaptedHttpRequest: HttpRequest<any> = req.clone({
      headers: cleanedHttpHeaders,
      params: appendedHttpQueryParams,
    });

    return authService.isLoggedIn().pipe(mergeMap(() => next(adaptedHttpRequest)));
  }

  return next(req);
};
