import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SDAPIService } from 'projects/core/src/lib/services/sdapi.service';
import { concatMap, map, Observable } from 'rxjs';
import { APIError } from '../data/errors.data';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { TableMapper } from '../mappers/table.mapper';
import { TableList } from '../models/dynamic-table.model';
import { DynamicForm } from '../models/form.model';
import { Invoker, InvokerMethods, InvokerTypes } from '../models/invoker-body.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import {
  TieConstraintPopupListObject,
  TieTableObjectList,
} from '../models/sdapi-table-object.model';
import { CustomHttpHeaders } from './custom-http-headers';

@Injectable()
export class AppointmentBookingService {
  constructor(
    private sdapiService: SDAPIService,
    private dynamicFormMapper: DynamicFormMapper,
  ) {}

  getBookingForm(isPublicRequest: boolean = false): Observable<DynamicForm> {
    return this.sdapiService.getFormByMethodFromObjectMenu(
      InvokerMethods.objectFindInObjectList,
      `/objects/SDAPI_APPOINTMENT_CREATE_FINDER`,
      isPublicRequest,
    );
  }

  getAppointmentTypeIdFromBookingForm(dynamicForm: DynamicForm): Observable<number> {
    return this.sdapiService.getIdOfFirstObjlistItemByStepInvoker(dynamicForm);
  }

  getAppointmentBookingForm(dynamicForm: DynamicForm): Observable<DynamicForm> {
    return this.getAppointmentTypeIdFromBookingForm(dynamicForm).pipe(
      concatMap((id) =>
        this.sdapiService.getFormByMethodFromObjectMenu(
          InvokerMethods.appointmentCreate,
          `/objects/${id}`,
          dynamicForm.isPublicAccessed,
        ),
      ),
    );
  }

  getAppointmentSlotsIntermediateForm(
    invoker: Invoker,
    isPublicRequest: boolean = false,
  ): Observable<DynamicForm> {
    let headers: HttpHeaders;
    if (isPublicRequest) {
      headers = CustomHttpHeaders.XPublicUserRequestHeaders;
    }
    try {
      return this.sdapiService.invokeMethod<TieFormObject>(invoker.invoker, headers).pipe(
        map((tieFormObject) => {
          const invokerUrl = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
          return this.dynamicFormMapper.mapDynamicFormResource(
            tieFormObject,
            invokerUrl,
            true,
            false,
            true,
          );
        }),
      );
    } catch (error) {
      throw new APIError(`Can't resolve intermediate appointment step.`, error);
    }
  }

  getAppointmentSlots(invoker: Invoker, isPublicRequest: boolean = false): Observable<TableList> {
    let headers: HttpHeaders;
    if (isPublicRequest) {
      headers = CustomHttpHeaders.XPublicUserRequestHeaders;
    }
    try {
      if (invoker.type === InvokerTypes.SEARCH) {
        return this.sdapiService
          .getResponseFromStepInvoker<TieTableObjectList>(invoker, headers)
          .pipe(map((list: TieTableObjectList) => TableMapper.mapTable(list)));
      } else {
        return this.sdapiService
          .invokeMethod<TieConstraintPopupListObject>(invoker.invoker, headers)
          .pipe(
            map((list: TieConstraintPopupListObject) => {
              const resourceTable: TieTableObjectList =
                TableMapper.mapConstraintPopupObjectList(list);
              return TableMapper.mapTable(resourceTable);
            }),
          );
      }
    } catch (error) {
      throw new APIError(`Can't get appointment slots.`, error);
    }
  }
}
