import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, retry, switchMap, take } from 'rxjs/operators';
import { AlertMessagesData } from '../data/alert-messages.data';
import { DocumentsResourceMapper } from '../mappers/document.mapper';
import { FormMapper } from '../mappers/form.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { TableMapper } from '../mappers/table.mapper';
import { TreatmentResourceMapper } from '../mappers/treatment.mapper';
import { AttributeDetail } from '../models/attribute.model';
import { DocumentDetails } from '../models/documents.model';
import { TableList } from '../models/dynamic-table.model';
import { DynamicForm } from '../models/form.model';
import { Invoker, InvokerMethods } from '../models/invoker-body.model';
import { InvokerMethodCollection } from '../models/modal-action.model';
import { Patient } from '../models/patient.model';
import { ObjectType, SDAPIResponseObject } from '../models/sdapi-object.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { TreatmentDetails } from '../models/treatment.model';
import { AlertService } from './alert.service';
import { CustomHttpHeaders } from './custom-http-headers';
import { PatientService } from './patient.service';
import { SDAPIService } from './sdapi.service';
import { TableDataService } from './table-data.service';
import { UploadService } from './upload.service';

@Injectable()
export class TreatmentsService {
  constructor(
    private http: HttpClient,
    private patientService: PatientService,
    private alertService: AlertService,
    private sdapiService: SDAPIService,
    private uploadService: UploadService,
    private tableDataService: TableDataService,
  ) {}

  public getTreatmentList(): Observable<TreatmentDetails[]> {
    return this.patientService.getCurrentPatient().pipe(
      concatMap((patient: Patient) => this.findTreatments(patient.patientID)),
      map((resource: TieTableObjectList) => TreatmentResourceMapper.mapResourceDetails(resource)),
    );
  }

  public getTreatmentsTable(): Observable<TableList> {
    return this.patientService.getCurrentPatient().pipe(
      concatMap((patient: Patient) => this.findTreatments(patient.patientID)),
      switchMap((resource: TieTableObjectList) => {
        const tableList: TableList = TreatmentResourceMapper.mapTreatmentsTable(resource);
        return this.tableDataService.fetchHeaderPreferencesAndUpdateTable(tableList);
      }),
    );
  }

  public async getTreatmentCount(): Promise<number> {
    return (await firstValueFrom(this.getTreatmentList())).length;
  }

  public findTreatments(patientID: string): Observable<TieTableObjectList> {
    const dataMap: Map<string, string> = new Map([['TEMP.PATIENT_ID[BODY,1]', patientID]]);

    return this.sdapiService.findDataObjectWithMetaFinder<TieTableObjectList>(
      'PP_FIND_ALL_TREATMENTS_FROM_PAT_W_ID',
      InvokerMethods.objectFindInObjectList,
      dataMap,
    );
  }

  public async refreshTreatmentDocumentsListAfterCreation(
    treatmentDetails: TreatmentDetails,
    documentCreateResponse: SDAPIResponseObject,
  ): Promise<void> {
    const newDocumentId = documentCreateResponse.bulk.find(
      (entry) => entry.t === ObjectType.objectCreate,
    )?.['newObjId'];
    if (!newDocumentId) {
      throw new Error('New treatment ID not found');
    }

    const documents = await firstValueFrom(
      this.getTreatmentsDocumentList(treatmentDetails, CustomHttpHeaders.XNoCacheHeaders).pipe(
        take(1),
        concatMap((docs: DocumentDetails[]) => {
          const newDocumentExists = docs.find(
            (document: DocumentDetails) => document.id === newDocumentId,
          );
          if (!newDocumentExists) {
            throw new Error('Document not found');
          }
          return of(docs);
        }),
        retry({ count: 5, delay: 1500 }),
      ),
    );
    treatmentDetails.documents = documents;
  }

  public getTreatmentsDocumentList(
    treatmentDetails: TreatmentDetails,
    headers?: HttpHeaders,
  ): Observable<DocumentDetails[]> {
    return this.sdapiService
      .getInvokerByMethodName(
        treatmentDetails.id.toString(),
        InvokerMethods.objectConstraintObjectList,
      )
      .pipe(
        concatMap((invoker: Invoker) =>
          this.http.put<TieTableObjectList>(invoker.activityURL, invoker.invoker, {
            headers,
          }),
        ),
        map((response: TieTableObjectList) =>
          DocumentsResourceMapper.mapDocumentResourceDetails(response),
        ),
        catchError((error) => {
          this.alertService.presentAlert(AlertMessagesData.authAlertTreatments);
          return throwError(() => error);
        }),
      );
  }

  public getTreatmentsNotesList(treatmentDetails: TreatmentDetails): Observable<TableList> {
    const dataMap: Map<string, string> = new Map([
      ['TEMP.VISIT_ID[BODY,1]', treatmentDetails.id.toString()],
    ]);

    return this.sdapiService
      .findDataObjectWithMetaFinder<TieTableObjectList>(
        'PP_VISIT_NOTE',
        InvokerMethods.objectFindInObjectList,
        dataMap,
      )
      .pipe(
        map((response: TieTableObjectList) => TableMapper.mapTable(response, false)),
        catchError((error) => {
          this.alertService.presentAlert(AlertMessagesData.authAlertTreatments);
          return throwError(() => error);
        }),
      );
  }

  public getNoteCreateInvoker(treatment: TreatmentDetails): Promise<Invoker> {
    return firstValueFrom(
      this.sdapiService.getInvokerByMethodName(
        treatment.id.toString(),
        InvokerMethods.objectCreate,
      ),
    );
  }

  public getTreatmentsInformationList(treatment: TreatmentDetails): Observable<DocumentDetails[]> {
    return this.findTreatmentInformation(treatment.id).pipe(
      map(TreatmentResourceMapper.mapTreatmentInformationResource),
    );
  }

  public findTreatmentInformation(treatmentID: number): Observable<TieTableObjectList> {
    const dataMap: Map<string, string> = new Map([
      ['TEMP.VISIT_ID[BODY,1]', treatmentID.toString()],
    ]);

    return this.sdapiService.findDataObjectWithMetaFinder<TieTableObjectList>(
      'PP_VISIT_INFOMAT',
      InvokerMethods.objectFindInObjectList,
      dataMap,
      CustomHttpHeaders.XNoAlertInterceptorHeaders,
    );
  }

  public checkForUploadDocumentActivity(treatment: TreatmentDetails): Observable<boolean> {
    return this.uploadService.getUploadFormInvoker(treatment.id).pipe(
      map((invoker: Invoker) => !!invoker.invoker),
      catchError(() => of(undefined)),
    );
  }

  public async checkPrerequisitesForNotesFeature(treatmentId: number): Promise<boolean> {
    const ppVisitEndpointExists = await firstValueFrom(
      this.sdapiService.checkForInvokerByMethodName(
        'PP_VISIT_NOTE',
        InvokerMethods.objectFindInObjectList,
        CustomHttpHeaders.XNoAlertInterceptorHeaders,
      ),
    );

    const checkForNotesActivity = await firstValueFrom(
      this.sdapiService.checkForInvokerByMethodName(
        treatmentId.toString(),
        InvokerMethods.objectCreate,
      ),
    );

    return ppVisitEndpointExists && checkForNotesActivity;
  }

  public checkForShareWithDoctorActivity(treatmentId: number): Observable<boolean> {
    return this.sdapiService
      .getInvokerByMethodName(treatmentId.toString(), InvokerMethods.objectSetShow)
      .pipe(
        map((invoker: Invoker) => !!invoker),
        catchError(() => of(undefined)),
      );
  }

  public checkForCreateTreatmentActivity(): Observable<boolean> {
    return this.patientService.getCurrentPatient().pipe(
      concatMap((currentPatient: Patient) =>
        this.sdapiService.getInvokerByMethodName(
          currentPatient.patientID,
          InvokerMethods.visitCreate,
        ),
      ),
      map((invoker: Invoker) => !!invoker),
      catchError(() => of(undefined)),
    );
  }

  public retrieveTreatmentDetails(treatmentId: number): Observable<AttributeDetail[]> {
    return this.sdapiService
      .getFormByMethodFromObjectMenu(
        InvokerMethods.objectAttributesView,
        `/objects/${treatmentId}`,
        false,
        true,
      )
      .pipe(
        map((form: DynamicForm) => FormMapper.mapFormBodyItemsToAttributeDetails(form)),
        catchError(() => of(null)),
      );
  }

  public getShareWithDoctorLink(treatmentId: number): Observable<string> {
    return this.sdapiService
      .getInvokerByMethodName(treatmentId.toString(), InvokerMethods.objectSetShow)
      .pipe(map((invoker: Invoker) => SDAPIObjectMapper.mapActivityPath(invoker.invoker)));
  }

  public getCreateTreatmentLink(): Observable<string> {
    return this.patientService.getCurrentPatient().pipe(
      concatMap((currentPatient: Patient) =>
        this.sdapiService.getInvokerByMethodName(
          currentPatient.patientID,
          InvokerMethods.visitCreate,
        ),
      ),
      map((invoker: Invoker) => SDAPIObjectMapper.mapActivityPath(invoker.invoker)),
      catchError((error) => throwError(() => error)),
    );
  }

  public get previewFallbackMethod(): InvokerMethodCollection {
    return { preferred: InvokerMethods.objectAttributesView };
  }

  public extractNewTreatmentIdFromBulkResponse(result: OverlayEventDetail): number | null {
    const bulkRequest = result?.data?.response?.bulk;
    if (!bulkRequest) {
      return null;
    }
    const newTreatmentId = bulkRequest.find(
      (entry) => entry.t === ObjectType.objectCreate,
    )?.newObjId;
    if (!newTreatmentId) {
      console.warn('New treatment ID not found');
      return null;
    }
    return newTreatmentId;
  }
}
