import { APP_BASE_HREF } from '@angular/common';
import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { ClientAuthType, ClientConfig } from 'projects/core/src/lib/models/client.model';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { EMPTY, Observable, of, throwError, zip } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { LanguageService } from '../services/language.service';
import { WINDOW } from 'projects/core/src/lib/injection-tokens';
import { getReferrerPath } from 'projects/core/src/lib/functions/referrer.functions';

export const UnauthorizedInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const clientConfigService: ClientConfigService = inject(ClientConfigService);
  const authService: AuthService = inject(AuthService);
  const languageService: LanguageService = inject(LanguageService);
  const window: Window = inject(WINDOW);
  const baseHref: string = inject(APP_BASE_HREF);

  const checkForRedirectOn401 = (error: HttpErrorResponse): Observable<any> => zip(of(clientConfigService.get()), of(error)).pipe(
      mergeMap((result) => {
        if (result[0].authConfig.redirectOn401) {
          doTheRedirect(result[0]);
          return of(EMPTY);
        }
        return throwError(() => error);
      }),
    );

  const getAuthServicePath = (clientConfig: ClientConfig): string => clientConfig.authConfig.authServicePath ?? '/auth-service/authenticate/start';

  const doTheRedirect = (clientConfig: ClientConfig): void => {
    let locationUrl: string;
    if (clientConfig.authConfig.type == ClientAuthType.TIE) {
      const applicationKey: string = authService.getCurrentAuthApplicationKey(clientConfig);
      const redirectPath: string = getReferrerPath(baseHref);
      const languageCode: string = getLanguageCode();
      const authServicePath: string = getAuthServicePath(clientConfig);
      locationUrl = `${authServicePath}?application=${applicationKey}&path=${redirectPath}&lang=${languageCode}`;
    } else {
      locationUrl = baseHref;
    }

    if (clientConfig.authConfig.customHost) {
      locationUrl = `${window.location.protocol}//${clientConfig.authConfig.customHost}${locationUrl}`;
    }

    window.location.replace(locationUrl);
  };

  const getLanguageCode = (): string => {
    const languageCode = languageService.getCurrentLanguageCode();
    return languageCode.toUpperCase();
  };

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (req.headers.get(CustomHttpHeaders.XNoUnauthorizedInterceptor) === 'true') {
        return throwError(() => error);
      }

      if (error.status === 401) {
        return checkForRedirectOn401(error);
      } else {
        return throwError(() => error);
      }
    }),
  );
};
