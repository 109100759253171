import { SDAPIResponseObject } from 'projects/core/src/lib/models/sdapi-object.model';

export class LaboratoryResults {
  chartVisible: boolean;
  groups: LaboratoryResultGroup[] = [];
}

export class LaboratoryResultGroup {
  name: string;
  tableData: LaboratoryResultTable;
}

export class LaboratoryResultTable {
  fixedColumns: LaboratoryResultTableColumn[] = [];
  columns: LaboratoryResultTableColumn[] = [];
  rows: LaboratoryResultTableRow[] = [];
}

export class LaboratoryResultTableColumn {
  name: string;
  type: ObservationResultColumnType;
  tooltip: string;
  fixed: boolean;
  originalSortIndex: number;

  constructor(
    name: string,
    type: ObservationResultColumnType,
    tooltip: string,
    fixed: boolean,
    originalSortIndex: number,
  ) {
    this.name = name;
    this.type = type;
    this.tooltip = tooltip;
    this.fixed = fixed;
    this.originalSortIndex = originalSortIndex;
  }
}

export class LaboratoryResultTableRow {
  fixedCells: LaboratoryResultTableCell[] = [];
  cells: LaboratoryResultTableCell[] = [];
}

export class LaboratoryResultTableCell {
  value: string;
  originalSortIndex: number;

  constructor(value: string, originalSortIndex: number) {
    this.value = value;
    this.originalSortIndex = originalSortIndex;
  }
}

export class PatientObservationResultViewResource extends SDAPIResponseObject {
  observationResultView: ObservationResultViewResource;
}

export interface ObservationResultViewResource {
  chartVisible: boolean;
  sortAscending: boolean;
  groups: ObservationResultGroupsResource[];
}

export interface ObservationResultGroupsResource {
  groupName: string;
  descriptionColumns: ObservationResultDescriptionColumnsResource[];
  valueColumns: ObservationResultValueColumnsResource[];
}

export interface ObservationResultDescriptionColumnsResource {
  description: string;
  tooltip: string;
  type: ObservationResultColumnType;
  values: string[] | null;
}

export interface ObservationResultValueColumnsResource {
  description: string;
  tooltip: string;
  observationDateTime: number;
  notes: any;
  values: ObservationResultValueColumnsValueResource[];
}

export interface ObservationResultValueColumnsValueResource {
  value: string;
  notes: any;
}

export enum ObservationResultColumnType {
  TEXT = 'TEXT',
  RANGE = 'RANGE',
  UNIT = 'UNIT',
}
