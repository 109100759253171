<ion-item
  id="patient-context-switch"
  detail="false"
  class="menu-item p1"
  [ngClass]="{ 'expanded-menu': isMenuExpanded, loading: !currentPatient && !currentDoctor }"
  lines="none"
  (click)="openPatientSelectionModal()">
  <ng-container *ngIf="currentPatient">
    <ion-avatar [ngClass]="{ isLoading: !currentPatient?.getInitials() }">
      <ion-label class="h3 initials">{{ currentPatient?.getInitials() }}</ion-label>
    </ion-avatar>
    <ng-container *ngIf="isExpandedContextSwitch">
      <div data-api-id="all-patients" class="patient-info">
        <p class="p3 ion-no-margin trim-text-inline">
          <strong>{{ 'theme.context-switch.patient-context' | translate }}</strong>
        </p>
        <p class="p3 ion-no-margin trim-text-inline">{{ currentPatient.getFullName() }}</p>
      </div>
      <ion-button
        *ngIf="patients?.length > 1"
        id="patient-selection-modal"
        slot="end"
        color="primary"
        size="small">
        <fa-icon [icon]="icons.changePatient" size="xs"></fa-icon>
      </ion-button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="currentDoctor">
    <ion-avatar [ngClass]="{ isLoading: !currentDoctor.getInitials() }">
      <ion-label class="h3 initials">{{ currentDoctor.getInitials() }}</ion-label>
    </ion-avatar>
    <ng-container *ngIf="isExpandedContextSwitch">
      <div data-api-id="all-patients" class="patient-info">
        <p class="p3 ion-no-margin trim-text-inline">
          <strong *ngIf="appName !== 'k-p' && appName !== 'm-p'">{{
            'theme.context-switch.doctor-context' | translate
          }}</strong>
          <strong *ngIf="appName === 'k-p'">{{
            'theme.context-switch.user-context' | translate
          }}</strong>
        </p>
        <p class="p3 ion-no-margin trim-text-inline">{{ currentDoctor.fullName }}</p>
      </div>
    </ng-container>
  </ng-container>

  @if (!currentPatient && !currentDoctor) {
    <div class="skeleton">
      <ion-skeleton-text animated="true" class="like-p skeleton-avatar"></ion-skeleton-text>
      @if (isExpandedContextSwitch) {
        <div class="skeleton-details stretch-container" [grow]="1">
          <ion-skeleton-text animated="true" class="rounded-small like-p"> </ion-skeleton-text>
          <ion-skeleton-text animated="true" class="rounded-small like-p"> </ion-skeleton-text>
        </div>
      }
    </div>
  }
</ion-item>

<ion-modal #patientSelectionModal>
  <ng-template>
    <ion-header>
      <ion-toolbar id="form-title-toolbar">
        <h5 class="form-title">
          {{ 'theme.context-switch.change-patient-context' | translate }}
        </h5>
        <div class="toolbar-buttons" slot="end" style="margin-left: 10px">
          <ion-button id="cancel-button" color="tertiary" (click)="closePatientSelectionModal()">
            {{ 'general.cancel' | translate }}
            <fa-icon [icon]="icons.close" slot="end"></fa-icon>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list lines="full">
        <ion-item
          [button]="true"
          [detail]="true"
          *ngFor="let patient of patients"
          (click)="switchPatientContext(patient)">
          <div style="padding-left: 4px">
            <p class="h5" style="margin-bottom: 5px">{{ patient.getFullName() }}</p>
            <p class="p3" style="margin-top: 0">
              geb. am {{ patient.birthDate | localeDate: 'shortDate' }}
            </p>
          </div>
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-modal>
