<lib-auto-layout direction="vertical" [gap]="[10]" [padding]="[0, 20]" horizontal="stretch">
  <lib-auto-layout [padding]="[0, 20]">
    <h3 class="ion-no-margin">{{ 'shared.data-organizer.method' | translate }}</h3>
  </lib-auto-layout>

  <lib-auto-layout direction="vertical" class="shape-list" horizontal="stretch" [grow]="1">
    <lib-organizer-list-item (itemClicked)="setDirectionParameterForSorting(directionType.ASC)">
      <ion-label>{{ 'shared.data-organizer.ascending' | translate }}</ion-label>
      <span [ngClass]="getClassNameOfDirectionButton(directionType.ASC)" class="radio-button">
      </span>
    </lib-organizer-list-item>

    <lib-organizer-list-item (itemClicked)="setDirectionParameterForSorting(directionType.DESC)">
      <ion-label> {{ 'shared.data-organizer.descending' | translate }} </ion-label>
      <span
        class="radio-button"
        [ngClass]="getClassNameOfDirectionButton(directionType.DESC)"></span>
    </lib-organizer-list-item>
  </lib-auto-layout>

  <lib-auto-layout [padding]="[10, 20, 0, 20]">
    <h3 class="ion-no-margin">{{ 'shared.data-organizer.reference' | translate }}</h3>
  </lib-auto-layout>

  <lib-auto-layout direction="vertical" class="shape-list" horizontal="stretch" [grow]="1">
    @for (item of table.sortedHeader; track item.identifier; let i = $index) {
      <lib-organizer-list-item (itemClicked)="setFieldIndexParameterForSorting(item, i)">
        <ion-label>{{ item.value }}</ion-label>
        <span [ngClass]="getClassNameOfHeaderValueButton(item)" class="radio-button"></span>
      </lib-organizer-list-item>
    }
  </lib-auto-layout>
</lib-auto-layout>
