<ion-thumbnail class="{{ resolveColor(document.type) }} ion-no-margin" slot="start">
  <img
    *ngIf="document.path && !hasError"
    [src]="document.path"
    [alt]="document.name"
    (error)="hasError = true" />
  <div class="fallback-square" *ngIf="!document.path || hasError">
    <div class="fallback">
      {{ document.name }} <br />
      <ion-skeleton-text></ion-skeleton-text>
      <ion-skeleton-text></ion-skeleton-text>
      <ion-skeleton-text></ion-skeleton-text>
      <ion-skeleton-text></ion-skeleton-text>
      <ion-skeleton-text></ion-skeleton-text>
    </div>
  </div>
</ion-thumbnail>
