import { SafeHtml } from '@angular/platform-browser';

export class PrivacyPolicyAttributeNames {
  public static readonly content = 'SYS_ATTRIBUTE.L1';
  public static readonly type = 'SYS_ATTRIBUTE.T1';
}

export class PrivacyPolicy {
  content: string | SafeHtml;
  formatType: PrivacyPolicyFormatType;
}

export enum PrivacyPolicyFormatType {
  TEXT = 'txt',
  HTML = 'html',
  MD = 'md',
}
