<input
  class="invisible-input hidden-form-element"
  [(ngModel)]="item.value.value"
  [name]="item.identifier.originalValue"
  #input="ngModel"
  hidden />

<pp-base-field
  [item]="item"
  [customHtmlId]="customHtmlId"
  [input]="input"
  [showLabel]="false"
  [preview]="preview">
  <ng-container inputPart>
    <label
      class="checkbox"
      [for]="customHtmlId"
      tabindex="0"
      (keyup.enter)="toggleCheckbox(input)"
      (keyup.space)="toggleCheckbox(input)"
      [class.disabled-padding]="!isEditableField">
      <input
        type="checkbox"
        [id]="customHtmlId"
        [name]="item.identifier.originalValue"
        [disabled]="!isEditableField"
        [value]="isChecked"
        (change)="toggleCheckbox(input)"
        [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
        [attr.aria-required]="item.required"
        tabindex="-1" />

      @if (!isEditableField) {
        <fa-icon
          [icon]="checkboxIcon"
          class="grey-checkbox"
          [class.filled-checkbox]="isChecked"></fa-icon>
      } @else {
        <fa-icon
          [icon]="checkboxIcon"
          [class.grey-checkbox]="!isChecked"
          [class.filled-checkbox]="isChecked"></fa-icon>
      }

      <span>
        {{ item.name + (item.required ? ' * ' : '') }}
      </span>
    </label>
  </ng-container>
</pp-base-field>
