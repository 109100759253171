<div id="header-container">
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-item color="tertiary" slot="start" class="color-white" lines="none">
        <fa-icon slot="start" class="h2" [icon]="ICONS.DOCTOR"></fa-icon>
        <ion-text id="portal-name" class="h2 color-white ion-hide-md-down">{{
          portalNameKey | translate
        }}</ion-text>
        <ion-text
          slot="end"
          class="h3 color-white"
          id="profile-name"
          *ngIf="profile"
          data-api-id="user-information"
          >{{ profile?.fullName }}</ion-text
        >
        <ion-text slot="end" class="h3 color-white" id="profile-name" *ngIf="!profile">
          <ion-skeleton-text
            animated="true"
            style="width: 140px; background-color: white"
            class="rounded-small">
          </ion-skeleton-text>
        </ion-text>
      </ion-item>
      <ion-button
        color="light"
        size="small"
        class="button-thin logout-button"
        slot="end"
        (click)="logout()"
        routerLink="/"
        routerDirection="root">
        <ion-label class="logout-text uppercase">{{ 'general.logout' | translate }}</ion-label>
        <fa-icon slot="end" [icon]="ICONS.LOGOUT"></fa-icon>
      </ion-button>
    </ion-toolbar>
  </ion-header>
</div>
