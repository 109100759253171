import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { DynamicButtonSetConfiguration } from '../models/dynamic-button.model';
import { ModalTableDismissData, TableList } from '../models/dynamic-table.model';
import { InvokerBody, InvokerMethods } from '../models/invoker-body.model';
import { InvokerMethodCollection } from '../models/modal-action.model';
import { BehaviorInvokerService } from './behaviour-invoker.service';

@Injectable()
export class TableModalService {
  constructor(private behaviorInvokerService: BehaviorInvokerService) {}

  public async constructModalDismissData(
    id: number,
    behaviorInvokers: InvokerBody[],
  ): Promise<ModalTableDismissData> {
    const [primaryInvoker, actionInvokers] = await firstValueFrom(
      this.behaviorInvokerService.retrievePrimaryAndActionInvokers(
        `${id}`,
        behaviorInvokers,
        this.fallbackMethodsForTableItemPreview,
      ),
    );
    return {
      invoker: primaryInvoker,
      actionButtons: SDAPIObjectMapper.mapInvokersToDynamicButtons(actionInvokers),
    };
  }

  private get fallbackMethodsForTableItemPreview(): InvokerMethodCollection {
    return {
      preferred: InvokerMethods.objectView,
      default: InvokerMethods.objectAttributesView,
    };
  }

  public constructActionButtonConfiguration(
    table: TableList,
    translations: Object,
  ): DynamicButtonSetConfiguration {
    return {
      actionButtons: table.actionButtons || [],
      triggerButton: {
        label: translations['forms.options'],
        color: 'primary',
        disabled: false,
        dataApiId: 'activity-change',
      },
    };
  }
}
