@if (rowState.isDesktopView) {
  <lib-auto-layout
    vertical="center"
    class="row-container"
    (click)="emitClickEventMessage()"
    [class.selectable-rows]="table?.selectableRows">
    @if (table.selectableRows) {
      @if (!row.selectedFix) {
        <div
          class="icon-container align-center select-box"
          (click)="emitSelectEventMessageAndToggleRowSelection($event)">
          <fa-icon [icon]="row.selected ? icons.squareCheck : icons.square"></fa-icon>
        </div>
      } @else {
        <div class="icon-container align-center">
          <fa-icon [icon]="icons.check"></fa-icon>
        </div>
      }
    }
    <div class="row">
      @for (value of visibleDesktopRowValues; track i; let i = $index) {
        @if (value | isIconCellType: i : table) {
          <fa-icon slot="end" [icon]="value | documentFormatToIconPipe"></fa-icon>
        } @else {
          <div
            class="stretch-self trim-text-inline"
            [class.stretch-cell]="hasHiddenHeaderItems && $last">
            <span class="p trim-text-inline">{{ value | tableValueByType: i : table }}</span>
          </div>
        }
      }
    </div>
    <div class="icon-container">
      <fa-icon [icon]="table.rowIcon"></fa-icon>
    </div>
  </lib-auto-layout>
} @else {
  @if (shouldShowCondensedView) {
    <lib-generic-condensed-row-mobile
      [table]="table"
      [row]="row"
      [state]="rowState"
      (eventMessage)="eventMessage.emit($event)">
    </lib-generic-condensed-row-mobile>
  } @else {
    <lib-generic-expandable-row-mobile
      [table]="table"
      [row]="row"
      [state]="rowState"
      (eventMessage)="eventMessage.emit($event)">
    </lib-generic-expandable-row-mobile>
  }
}
