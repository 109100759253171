import { Component, Input } from '@angular/core';
import { BookingQueue } from 'projects/core/src/lib/models/appointment-booking.model';
import { SharedBookingData } from '../booking-modal/booking-modal.component';
@Component({
  selector: 'booking-modal-upcomming-list',
  template: `
    <div class="upcomming-list-container">
      <div
        class="upcomming-list-item p ion-hide-md-up"
        *ngFor="let item of bookingQueue.followingSteps; let last = last">
        <booking-modal-timeline-indicator
          class="ion-hide-md-up"
          [upcomming]="true"
          [last]="last"></booking-modal-timeline-indicator>
        <div class="upcomming-list-text">{{ item.title }}</div>
        <div class="upcomming-list-dot"></div>
      </div>
      <div
        class="upcomming-list-item p ion-hide-md-down"
        [class.active]="bookingQueue.currentStep === item"
        *ngFor="let item of bookingQueue.allSteps">
        <div class="upcomming-list-text" *ngIf="bookingQueue.currentStep === item">
          <strong>{{ item.title }}</strong>
        </div>
        <div class="upcomming-list-text" *ngIf="bookingQueue.currentStep !== item">
          {{ item.title }}
        </div>
        <div class="upcomming-list-dot"></div>
      </div>
    </div>
  `,
  styleUrls: ['./upcomming-list.component.scss'],
})
export class BookingUpcommingList {
  @Input() bookingQueue: BookingQueue<SharedBookingData>;
}
