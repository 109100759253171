import { Injectable } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Bundle } from 'fhir/r4';
import { MimeTypeToDocumentFormatPipe } from 'projects/theme/src/lib/pipes/mime-type-to-document-format.pipe';
import { firstValueFrom } from 'rxjs';
import { APIError } from '../data/errors.data';
import { DocumentFormat } from '../models/documents.model';
import {
  TableDataType,
  TableDefinition,
  TableHeaderItem,
  TableList,
  TableListItem,
  TableType,
} from '../models/dynamic-table.model';
import { IheDocumentDetails } from '../models/ihe-documents.model';
import { AttributeNameIdentifier } from '../models/shared.model';

@Injectable({ providedIn: 'root' })
export class IheDocumentsResourceMapper {
  constructor(private translateService: TranslateService) {}

  public static fields = [
    { field: 'mimeType', label: '', type: TableDataType.icon },
    { field: 'date', translationKey: 'date', label: 'Erstellt am', type: TableDataType.date },
    {
      field: 'description',
      translationKey: 'description',
      label: 'Beschreibung',
      type: TableDataType.text,
    },
    { field: 'type', translationKey: 'type', label: 'Typ', type: TableDataType.text },
    { field: 'class', translationKey: 'class', label: 'Klasse', type: TableDataType.text },
    {
      field: 'subjectArea',
      translationKey: 'subject-area',
      label: 'Fachrichtung',
      type: TableDataType.text,
    },
    {
      field: 'sourceSystem',
      translationKey: 'author-institution',
      label: 'Authorinstitution',
      type: TableDataType.text,
    },
  ];

  public static getTmpHeaders() {
    return IheDocumentsResourceMapper.fields.map(
      (field) =>
        ({
          value: field.translationKey ? field.translationKey : field.label,
          type: field.type,
          identifier: new AttributeNameIdentifier(field.field),
        }) as TableHeaderItem,
    );
  }

  public async getTableHeader(): Promise<TableHeaderItem[]> {
    const translations = await firstValueFrom(
      this.translateService.get('shared.ihe-viewer.table-header'),
    );
    return IheDocumentsResourceMapper.fields.map(
      (field) =>
        ({
          value: field.translationKey ? translations[field.translationKey] : field.label,
          type: field.type,
          identifier: new AttributeNameIdentifier(field.field),
        }) as TableHeaderItem,
    );
  }

  public async mapIheDocumentTable(mappedData: IheDocumentDetails[]): Promise<TableList> {
    let tableHeader: TableHeaderItem[] = [];
    let tableRows: TableListItem[] = [];

    try {
      tableHeader = await this.getTableHeader();
      tableRows = mappedData.map(
        (document, index) =>
          ({
            columns: [
              this.getIconFromMimeType(document.mimeType),
              document.date,
              document.description,
              document.type,
              document.class,
              document.subjectArea,
              document.sourceSystem,
            ],
            id: document.id,
            name: '' + index,
            selected: false,
            selectedFix: document.existsInCurrentSourceSystem,
          }) as TableListItem,
      );
    } catch (error) {
      throw new APIError('Mapping of orders failed in the IheDocumentsMapper.', error);
    }

    const tableDefinition: TableDefinition = {
      selectableRows: true,
      title: 'IHE Viewer',
      storageKey: 'iheDocumentColumnPreference',
      tableType: TableType.generic,
      customRowIcon: faDownload,
      customRowIconMobile: faDownload,
    };

    return new TableList(tableHeader, tableRows, tableDefinition);
  }

  private getIconFromMimeType(mimeType: string): string {
    return DocumentFormat[MimeTypeToDocumentFormatPipe.transformStatic(mimeType)];
  }

  public mapIheDocumentResourceDetails(resource: Bundle): IheDocumentDetails[] {
    const documentDetails: IheDocumentDetails[] = [];
    try {
      for (const row of resource?.entry || []) {
        const rowResource: any = row.resource;

        const containedArray = rowResource.contained;
        const organizationReference = containedArray[0]?.organization?.reference || '';
        const organizationId = organizationReference?.split('#')[1];
        const organizationById = containedArray.find((item) => item.id === organizationId);
        const organizationName = organizationById?.name;

        const contentObject = rowResource.content[0];
        const attachment = contentObject.attachment;
        const mimeType = attachment.contentType;
        const documentType = MimeTypeToDocumentFormatPipe.transformStatic(mimeType);
        const fileIcon = DocumentFormat[documentType];

        const createdAt = rowResource.date;

        const type = rowResource.type?.text;
        const category = rowResource.category?.[0]?.text;
        const subjectArea = rowResource.context?.practiceSetting?.text;

        const documentsDetails: IheDocumentDetails = {
          id: rowResource.id,
          mimeType,
          fileIcon,
          date: createdAt,
          description: `${rowResource.description}`,
          type,
          class: category,
          subjectArea,
          sourceSystem: organizationName,
          originalResource: rowResource,
        };
        documentDetails.push(documentsDetails);
      }
    } catch (error) {
      console.error(error);
      throw new APIError(
        'Mapping of document details failed in the DocumentsResourceMapper',
        error,
      );
    }
    return documentDetails;
  }
}
