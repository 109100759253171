<ion-button class="ion-text-wrap ion-no-margin" color="primary" (click)="printLabel()">
  <span class="p3 ion-text-center">{{ button.label }}</span>
  <fa-icon icon="print" slot="end"></fa-icon>
</ion-button>

<iframe
  class="hidden-label-iframe"
  [src]="button.printDetails.safeUrl"
  [title]="button.printDetails.fileName"
  #iframe>
</iframe>
