import { Pipe, PipeTransform } from '@angular/core';
import {
  TableDataType,
  TableHeaderItem,
  TableList,
} from 'projects/core/src/lib/models/dynamic-table.model';

@Pipe({
  name: 'isIconCellType',
})
export class IsIconCellType implements PipeTransform {
  transform(rowValue: string, index: number, table: TableList): boolean {
    if (!rowValue) {return false;}
    const headerItem: TableHeaderItem = table.sortedHeader
      ? table.sortedHeader[index]
      : table.initialHeader[index];
    return headerItem?.type === TableDataType.icon;
  }
}
