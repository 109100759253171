import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { SafeAreaInsets } from 'capacitor-plugin-safe-area/dist/esm/definitions';

@Injectable({
  providedIn: 'root',
})
export class NativeAppService {
  statusBarHeight = 20;

  constructor() {}

  async init() {
    await Promise.allSettled([
      this.initInsetListenerForIos(),
      this.initBackButtonListenerForAndroid(),
    ]);
  }

  private async initBackButtonListenerForAndroid() {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
      await App.addListener('backButton', ({ canGoBack }) => {
        if (canGoBack) {
          window.history.back();
        }
      });
    }
  }

  private async initInsetListenerForIos() {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
      this.statusBarHeight = (await SafeArea.getStatusBarHeight()).statusBarHeight;

      const insetData = await SafeArea.getSafeAreaInsets();
      this.setIosInsets(insetData);

      await SafeArea.addListener('safeAreaChanged', (data) => {
        this.setIosInsets(data);
      });
    }
  }

  private setIosInsets(data: SafeAreaInsets) {
    setTimeout(() => {
      const { insets } = data;
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`);
        if (key === 'bottom') {
          this.setBottomInset(value);
          this.applyIPhoneSeFix(value);
        }
      }
    }, 500);
  }

  private setBottomInset(value) {
    const hasSafeBottomArea = value !== 0;
    if (hasSafeBottomArea) {
      const bias = this.statusBarHeight - 11;
      document.documentElement.style.setProperty(`--safe-area-inset-bottom`, `${value + bias}px`);
    }
  }

  private applyIPhoneSeFix(value) {
    const hasSafeBottomArea = value !== 0;
    const isBelowMobileBreakpoint = window.innerWidth <= 768;
    const isLandscapeMode = window.innerWidth < window.innerHeight;
    if (!hasSafeBottomArea && isLandscapeMode && isBelowMobileBreakpoint) {
      document.documentElement.style.setProperty(`--safe-area-inset-bottom`, `${value + 25}px`);
    }
  }
}
