<lib-modal-header [title]="document?.name" [style]="'view'">
  @if (isDownloadableDocumentFormat) {
    <lib-document-details-popover button [document]="document"></lib-document-details-popover>
  }
</lib-modal-header>

<ion-content class="ion-no-padding ion-no-margin">
  <lib-auto-layout direction="vertical" vertical="stretch" horizontal="stretch" class="fill-height">
    <div [grow]="1">
      <lib-document-details-viewer [viewDocument]="document"></lib-document-details-viewer>
    </div>

    @if (configuration?.actionButtons?.length) {
      <lib-action-buttons-toolbar
        [configuration]="configuration"
        (actionTrigger)="dismissModalWithActionEvent($event)">
      </lib-action-buttons-toolbar>
    }
  </lib-auto-layout>
</ion-content>
