import { Component, Input } from '@angular/core';
import { DynamicDataField } from 'projects/core/src/lib/models/form.model';

@Component({
  selector: 'lib-title-field',
  templateUrl: './title-field.component.html',
  styleUrls: ['./title-field.component.scss'],
})
export class TitleFieldComponent {
  @Input() item: DynamicDataField;
}
