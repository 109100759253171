import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lib-organizer-list-item',
  templateUrl: './organizer-list-item.component.html',
  styleUrls: ['../data-organizer-toolbox.scss'],
})
export class OrganizerListItemComponent {
  @Output() itemClicked: EventEmitter<void> = new EventEmitter();
}
