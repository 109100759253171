import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/angular-fontawesome/types';

@Component({
  selector: 'booking-modal-timeline-indicator',
  template: `
    <div class="icon-circle" *ngIf="!isSpacer" [ngClass]="{ current, upcomming }">
      <fa-icon [icon]="icon" size="sm" *ngIf="!upcomming"></fa-icon>
    </div>
    <div class="line" [ngClass]="{ first, last }"></div>
  `,
  styleUrls: ['./timeline-indicator.component.scss'],
})
export class TimelineIndicator {
  @Input() icon: IconDefinition;
  @Input() first = false;
  @Input() last = false;
  @Input() current = false;
  @Input() upcomming = false;
  @Input() isSpacer = false;
}
