import { Injectable } from '@angular/core';
import { firstValueFrom, from, map, mergeMap, Observable, throwError } from 'rxjs';
import {
  Invoker,
  InvokerBody,
  InvokerMethods,
} from 'projects/core/src/lib/models/invoker-body.model';
import { SDAPIService } from 'projects/core/src/lib/services/sdapi.service';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { TieFormObject } from 'projects/core/src/lib/models/sdapi-form-object.model';
import {
  LaboratoryResults,
  PatientObservationResultViewResource,
} from 'projects/core/src/lib/models/laboratory-results.model';
import { LaboratoryResultsMapper } from 'projects/core/src/lib/mappers/laboratory-results.mapper';

@Injectable()
export class LaboratoryResultsService {
  constructor(
    private sdapiService: SDAPIService,
    private patientService: PatientService,
  ) {}

  async hasAccessToPatientsLaboratoryResults(): Promise<boolean> {
    const dossier: TieFormObject = await firstValueFrom(this.getPatientDossier());
    return !!dossier.showTypes.BUTTON_PROJECT.items.find(
      (projectItem) =>
        projectItem.invokerRequest.methodName === InvokerMethods.patientObservationResultsView,
    );
  }

  getLaboratoryResults(): Observable<LaboratoryResults> {
    return from(this.getLaboratoryInvoker()).pipe(
      mergeMap((laboratoryInvoker: InvokerBody) =>
        this.sdapiService.invokeMethod<PatientObservationResultViewResource>(laboratoryInvoker),
      ),
      map((results: any) => LaboratoryResultsMapper.mapResponse(results)),
    );
  }

  private async getLaboratoryInvoker(): Promise<InvokerBody | undefined> {
    const dossier: TieFormObject = await firstValueFrom(this.getPatientDossier());
    return dossier.showTypes.BUTTON_PROJECT.items.find(
      (projectItem) =>
        projectItem.invokerRequest.methodName === InvokerMethods.patientObservationResultsView,
    )?.invokerRequest;
  }

  private getPatientDossier(): Observable<TieFormObject> {
    return from(this.patientService.getCurrentPatientId()).pipe(
      mergeMap((patientId: string) =>
        this.sdapiService.getInvokerListByMethodName(patientId, InvokerMethods.projectEdit),
      ),
      mergeMap((invokerList: Invoker[]) => {
        if (invokerList?.length > 0) {
          return this.sdapiService.invokeMethod<TieFormObject>(invokerList[0].invoker);
        } else {
          return throwError(() => new Error(`No projectEdit method found on current patient`));
        }
      }),
    );
  }
}
