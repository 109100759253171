import { Component, Input, OnInit } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  EventMessage,
  EventMessageType,
  SkeletonHeaderOffset,
  SkeletonRowSet,
  TableConfiguration,
  TableList,
  TableListItem,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-table-select',
  templateUrl: './table-select.component.html',
  host: { class: 'medium-modal' },
})
export class TableSelectComponent implements OnInit {
  @Input() tableList: TableList;
  @Input() title: string;

  readonly headerOffset: SkeletonHeaderOffset = { mobile: 210, desktop: 520 };
  readonly expandedViewThreshold: number = 600;
  readonly ICONS = { CLOSE: faXmark };

  skeletonRowSet: SkeletonRowSet;
  searchParams: SearchParams = new SearchParams();
  isRowDataLoading: boolean;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
  ) {
    this.setDefaultTitleIfNoSpecified();
    this.skeletonRowSet = new SkeletonRowSet(this.headerOffset);
  }

  ngOnInit(): void {
    this.initializeSearchParams();
  }

  private initializeSearchParams() {
    if (this.tableList.initialRows.length > 0) {
      this.searchParams.fields = Object.keys(this.tableList.initialRows[0]);
    } else {
      this.searchParams.fields = [''];
    }
  }

  get data(): TableConfiguration {
    return {
      table: this.tableList,
      searchParams: this.searchParams,
      skeletonRowSet: this.skeletonRowSet,
      expandedViewThreshold: this.expandedViewThreshold,
      columnWidth: 200,
    };
  }

  private setDefaultTitleIfNoSpecified(): void {
    if (!this.title) {
      firstValueFrom(this.translateService.get('general.selection')).then((value: string) => {
        this.title = value;
      });
    }
  }

  cancel(): void {
    this.modalController.dismiss({}, 'cancel');
  }

  resolveEventMessage(eventMessage: EventMessage): void {
    switch (eventMessage.type) {
      case EventMessageType.CLICK:
        this.vieListItem(eventMessage.data);
        break;
      case EventMessageType.RESORTING:
        this.setRowDataLoading(eventMessage.data);
        break;
    }
  }

  private setRowDataLoading(value: boolean): void {
    this.isRowDataLoading = value;
  }

  vieListItem(tableListItem: TableListItem): void {
    this.modalController.dismiss({ tableListItem }, OverlayEventRole.activityChange);
  }
}
