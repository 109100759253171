import {
  faFile,
  faFileContract,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileMedical,
  faFileWaveform,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { DynamicButton } from './dynamic-button.model';
import { InvokerBody } from './invoker-body.model';

export class DocumentDetails implements ViewDocumentDetails {
  path?: string;
  name: string;
  size?: number;
  date: Date;
  type?: DocumentFilterName;
  id: number;
  downloadLink?: string;
  viewLink?: string;
  externalOpenLink?: string;
  downloadPending: boolean;
  blob?: Blob;
  fileName?: string;
  httpContentType?: string;
  fileType?: DocumentFormat;
  mimeType: string;
  actionButtons?: DynamicButton[];
  behaviorInvokers?: InvokerBody[];
}

export class DocumentFilter {
  name: DocumentFilterName;
  icon: IconDefinition;
  selected: boolean;
  colorClass: string;
}

export enum DocumentFilterName {
  FINDINGS_REPORTS = 'findings-reports',
  DOCTORS_LETTERS = 'doctor-letters',
  LAB_RESULTS = 'lab-results',
  INVOICE = 'invoice',
  DICOM = 'dicom',
  OTHERS = 'others',
}

export const DocumentFilterIcon: Map<DocumentFilterName, IconDefinition> = new Map([
  [DocumentFilterName.FINDINGS_REPORTS, faFileInvoice],
  [DocumentFilterName.DOCTORS_LETTERS, faFileContract],
  [DocumentFilterName.LAB_RESULTS, faFileMedical],
  [DocumentFilterName.INVOICE, faFileInvoiceDollar],
  [DocumentFilterName.DICOM, faFileWaveform],
  [DocumentFilterName.OTHERS, faFile],
]);

export enum DocumentFilterColor {
  FINDINGS_REPORTS = 'color-primary-shift-1',
  DOCTORS_LETTERS = 'color-primary-shift-2',
  LAB_RESULTS = 'color-primary-shift-3',
  INVOICE = 'color-primary-shift-4',
  DICOM = 'color-primary-shift-5',
  OTHERS = 'color-primary-shift-6',
}

export enum DocumentFormat {
  ARCHIVE = 'Archivierte Dateien',
  AUDIO = 'Audio',
  CHAT = 'Chat',
  DICOM = 'DICOM',
  EDIT_FORM = 'Edit Form',
  EXCEL = 'Microsoft Excel',
  HTML = 'HTML',
  IMAGE = 'Bild',
  LINK = 'Link',
  PDF = 'PDF',
  POWERPOINT = 'Microsoft PowerPoint',
  TABLE = 'Table',
  UNKNOWN = 'Unbekannt',
  UPLOAD_FORM = 'Upload Form',
  VIDEO = 'Video',
  VIEW_FORM = 'View Form',
  WORD = 'Microsoft Word',
}

export class Subtotal {
  category: DocumentFilterName;
  quantity = 0;
}

export interface ViewDocumentDetails {
  id: number;
  blob?: Blob;
  downloadPending: boolean;
  downloadLink?: string;
  size?: number;
  fileName?: string;
  fileType?: DocumentFormat;
  viewLink?: string;
  externalOpenLink?: string;
  actionButtons?: DynamicButton[];
}

export class DocumentAttributeNames {
  public static readonly creationDate = 'SYS_OBJECT.CREATION_DATE';
  public static readonly title = 'SYS_OBJECT.OBJ_NAME';
  public static readonly fileSize = 'SYS_OBJECT.OBJ_SIZE';
  public static readonly mimeType = 'mime_type';
  public static readonly documentType = 'SYS_OBJCLASS.OBJCLASS_NAME';
}
