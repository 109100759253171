import { Injectable } from '@angular/core';
import { GetResult, Preferences } from '@capacitor/preferences';

export const STORAGE_KEY_AUTH_CREDENTIALS = 'pp_auth_credentials';
export const STORAGE_KEY_SELECTED_LANGUAGE_CODE = 'pp_selected_language_code';

@Injectable()
export class StorageService {
  public async get(objectKey: string): Promise<string> {
    const getResult: GetResult = await Preferences.get({ key: objectKey });
    return getResult.value;
  }

  public async getObject<T>(objectKey: string): Promise<T | null> {
    const storedValue: string = await this.get(objectKey);
    return storedValue ? (JSON.parse(storedValue) as unknown as T) : null;
  }

  public async set(objectKey: string, objectValue: string): Promise<void> {
    return Preferences.set({
      key: objectKey,
      value: objectValue,
    });
  }

  public async setObject<T>(objectKey: string, objectValue: T): Promise<void> {
    return this.set(objectKey, JSON.stringify(objectValue));
  }

  public async remove(objectKey: string): Promise<void> {
    return Preferences.remove({
      key: objectKey,
    });
  }
}
