import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe, JsonPipe, registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import fr from '@angular/common/locales/fr';
import it from '@angular/common/locales/it';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { IonicModule } from '@ionic/angular';
import { MaskitoDirective } from '@maskito/angular';
import { CoreModule } from 'projects/core/src/lib/core.module';
import { ContextSwitchComponent } from 'projects/theme/src/lib/components/context-switch/context-switch.component';
import { DemoOnlyDirective } from 'projects/theme/src/lib/directives/demo-only.directive';
import { HtmlInitDirective } from 'projects/theme/src/lib/directives/html-init.directive';
import { PPOnlyDirective } from 'projects/theme/src/lib/directives/pp-only.directive';
import { ZpAndKpOnlyDirective } from 'projects/theme/src/lib/directives/zp-and-kp-only.directive';
import { ClinicTypeToIconPipe } from 'projects/theme/src/lib/pipes/clinic-type-to-icon.pipe';
import { DownloadablePipe } from 'projects/theme/src/lib/pipes/downloadable.pipe';
import { FileSizePipe } from 'projects/theme/src/lib/pipes/file-size.pipe';
import { MimeTypeToDocumentFormatPipe } from 'projects/theme/src/lib/pipes/mime-type-to-document-format.pipe';
import { SafeHtmlPipe } from 'projects/theme/src/lib/pipes/safe-html.pipe';
import { SafeResourceUrlPipe } from 'projects/theme/src/lib/pipes/safe-url.pipe';
import { SwiperModule } from 'swiper/angular';
import { HeaderComponent } from './components/header/header.component';
import { LandingpageHeaderComponent } from './components/landingpage-header/landingpage-header.component';
import { LogoComponent } from './components/logo/logo.component';
import { ReferrerHeaderComponent } from './components/referrer-header/referrer-header.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { AutoGrowDirective } from './directives/auto-grow.directive';
import { OnDimensionChangeDirective } from './directives/on-dimension-change.directive';
import { TIEIcons } from './icons/tie-icons';
import { AgeCountPipe } from './pipes/age-count.pipe';
import { BlobToBase64Pipe } from './pipes/blobToBase64.pipe';
import { DetailsToListTransformPipe } from './pipes/details-to-list.pipe';
import { DocumentFormatToIconPipe } from './pipes/document-format-to-icon.pipe';
import { GenderTypePipe } from './pipes/gender-type.pipe';
import { IsIconCellType } from './pipes/is-icon-cell-type.pipe';
import { LocaleDatePipe } from './pipes/locale-date.pipe';
import { TableValueByTypePipe } from './pipes/table-value-by-type.pipe';
import { TimePipe } from './pipes/time.pipe';
import { NativeAppService } from './services/native-app.service';

const TOKENS = [];

const MODULES = [
  IonicModule.forRoot({
    mode: 'ios',
    rippleEffect: false,
    scrollAssist: false,
  }),
  CommonModule,
  CoreModule,
  FontAwesomeModule,
  FormsModule,
  RouterModule,
  ScrollingModule,
  SwiperModule,
];

const COMPONENTS = [
  ContextSwitchComponent,
  HeaderComponent,
  LandingpageHeaderComponent,
  LogoComponent,
  ReferrerHeaderComponent,
  TabBarComponent,
];

const EXTERNAL_PIPES = [DatePipe, JsonPipe];

const PIPES = [
  AgeCountPipe,
  BlobToBase64Pipe,
  ClinicTypeToIconPipe,
  DetailsToListTransformPipe,
  DocumentFormatToIconPipe,
  DownloadablePipe,
  FileSizePipe,
  GenderTypePipe,
  LocaleDatePipe,
  MimeTypeToDocumentFormatPipe,
  SafeHtmlPipe,
  SafeResourceUrlPipe,
  TableValueByTypePipe,
  IsIconCellType,
  TimePipe,
];

const DIRECTIVES: any[] = [
  AutoGrowDirective,
  DemoOnlyDirective,
  HtmlInitDirective,
  OnDimensionChangeDirective,
  PPOnlyDirective,
  ZpAndKpOnlyDirective,
];

const EXTERNAL_DIRECTIVES: any[] = [MaskitoDirective];

const INITIALIZERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: (library: FaIconLibrary) => (): void => {
      library.addIconPacks(fas, far);
    },
    deps: [FaIconLibrary],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: (nativeAppService: NativeAppService) => async (): Promise<void> => {
      await nativeAppService.init();
    },
    deps: [NativeAppService],
    multi: true,
  },
];

@NgModule({
  imports: [...MODULES, ...EXTERNAL_DIRECTIVES],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...MODULES,
    ...PIPES,
    ...EXTERNAL_PIPES,
    ...EXTERNAL_DIRECTIVES,
  ],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    registerLocaleData(de);
    registerLocaleData(en);
    registerLocaleData(fr);
    registerLocaleData(it);
    return {
      ngModule: ThemeModule,
      providers: [...PIPES, ...EXTERNAL_PIPES, ...TOKENS, ...INITIALIZERS],
    };
  }
  constructor(library: FaIconLibrary) {
    library.addIcons(...TIEIcons);
  }
}
