<ion-item
  *ngIf="field && isDisplayableItem(field)"
  [ngClass]="{ static: field.readOnly || !field.value.value }"
  class="item-line"
  lines="none">
  <p>{{ field.name }}</p>
  <p slot="end" class="no-hyphens">
    {{ resolveValue(field) || ('general.not-specified' | translate) }}
  </p>
</ion-item>
