import { AfterViewInit, Component, Input } from '@angular/core';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';

@Component({
  selector: 'lib-documents-skeleton',
  templateUrl: './documents-skeleton.component.html',
  styleUrls: ['./documents-skeleton.component.scss'],
})
export class DocumentsSkeletonComponent implements AfterViewInit {
  @Input() hasExtendedView = true;
  @Input() lines: any = 1;
  items = new Set();

  constructor(private breakPointService: BreakpointService) {}

  ngAfterViewInit() {
    this.generateArray(this.lines);
  }

  private generateArray(total: number): void {
    for (let i = 0; i < total; i++) {
      this.items.add(i);
    }
  }

  breakLayout() {
    return this.hasExtendedView && this.breakPointService.isAbove('sm');
  }
}
