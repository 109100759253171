<ion-grid [class.content]="containerize" class="content" [fixed]="true">
  <ion-row>
    <ion-col *ngIf="!isLoading">
      <ion-text *ngIf="imprint?.content" [innerHTML]="imprint.content | safeHtml"></ion-text>
      <ion-text>
        <small
          class="copyright-additional-disclaimer"
          [innerHTML]="
            'imprint.font-awesome-credit' | translate: { link: 'https://fontawesome.com' }
          ">
          {{ 'imprint.font-awesome-credit' | translate }}
        </small>
      </ion-text>
    </ion-col>

    <ion-col *ngIf="isLoading" id="skeleton">
      <ng-container *ngFor="let item of generateSkeletonItems()">
        <p>
          <ion-skeleton-text class="rounded-small like-p" animated></ion-skeleton-text>
        </p>
      </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>
