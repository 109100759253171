import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicDataField, DynamicForm } from 'projects/core/src/lib/models/form.model';
import { SkeletonService } from 'projects/core/src/lib/services/skeleton.service';

@Component({
  selector: 'lib-patient-data-browser',
  templateUrl: './patient-data-browser.component.html',
  styleUrls: ['./patient-data-browser.component.scss'],
})
export class PatientDataBrowserComponent {
  @Input() viewForm: DynamicForm;
  @Input() editForm: DynamicForm;
  @Input() isLoading: boolean;

  @Output() patientDataUpdate = new EventEmitter<string>();

  isMenuPopupOpen: boolean = false;
  tabIndex: string = '0';

  readonly skeletonRowAmountAsArray: Array<number> = SkeletonService.getRowAmountAsArray(320, 56);

  toggleButtonState(): void {
    this.isMenuPopupOpen = !this.isMenuPopupOpen;
  }

  setMenuValue(index: number): void {
    this.tabIndex = index.toString();
  }

  get hasMultipleTabs(): boolean {
    return this.viewForm.body.length > 1;
  }

  hasTableView(index: string): boolean {
    return this.viewForm.body[index].fieldGroup.some((item: DynamicDataField) => item.value.table);
  }
}
