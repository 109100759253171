import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ClientConfigService } from '../services/client-config.service';

export const PublicAppointmentModuleGuard: CanActivateFn = async (): Promise<boolean> => {
  const clientConfigService = inject(ClientConfigService);
  const isPublicAppointmentBookingActive =
    clientConfigService.get().activeModules.publicAppointmentBooking;
  return isPublicAppointmentBookingActive;
};
