import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronRight, faPen } from '@fortawesome/free-solid-svg-icons';
import {
  TreatmentDetails,
  TreatmentDetailsAttributeIcons,
} from 'projects/core/src/lib/models/treatment.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { VirtualScrollService } from 'projects/core/src/lib/services/virtual-scroll.service';

@Component({
  selector: 'lib-information-section',
  templateUrl: './information-section.component.html',
  styleUrl: './information-section.component.scss',
})
export class InformationSectionComponent {
  @Input() isLoading: boolean;
  @Input() treatment: TreatmentDetails;

  @Output() documentSectionActivation = new EventEmitter<void>();
  @Output() openTreatmentNotes = new EventEmitter<void>();
  @Output() refreshTreatment = new EventEmitter<void>();

  readonly iconSet = TreatmentDetailsAttributeIcons;
  readonly icons = {
    chevron: faChevronRight,
    notes: faPen,
  };

  constructor(
    public virtualScrollService: VirtualScrollService,
    public breakpointService: BreakpointService,
  ) {}

  get maxRowAmountOfInfoDocuments(): number {
    const offset: number = this.breakpointService.isAbove('sm') ? 376 : 435;
    return Math.floor((window.innerHeight - offset) / 58);
  }

  get maxRowAmountOfInfoItems(): number[] {
    return Array(3).fill(0);
  }

  activateDocumentSection(): void {
    this.documentSectionActivation.emit();
  }

  get hasInformation(): boolean {
    return this.hasInformationDocuments || this.showNotesButton || this.hasTreatmentDetails;
  }

  get hasInformationDocuments(): boolean {
    return !!this.treatment.information?.length;
  }

  private get hasTreatmentDetails(): boolean {
    return !!this.treatment.details?.length;
  }

  get showToDocumentsButton(): boolean {
    const isMobileView = this.breakpointService.isBelow('md');
    return !this.hasInformationDocuments && isMobileView && this.hasTreatmentDetails;
  }

  get showNotesButton(): boolean {
    return this.treatment.hasNotesFeature;
  }
}
