import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { DocumentFilter, Subtotal } from 'projects/core/src/lib/models/documents.model';

@Component({
  selector: 'lib-filters-context',
  templateUrl: './filters-context.component.html',
  styleUrls: ['./filters-context.component.scss'],
})
export class FiltersContextComponent {
  @Input() filtersPreset: DocumentFilter[];
  @Input() subtotals: Subtotal[];
  @Output() selectedFiltersChanged = new EventEmitter<void>();

  icons = {
    checkbox: faSquare,
    checkedbox: faSquareCheck,
  };

  toggleFilter(filter: DocumentFilter) {
    const filterFound = this.filtersPreset.find((f) => f.name == filter.name);
    filterFound.selected = !filterFound.selected;
    this.selectedFiltersChanged.emit();
  }

  getSubtotal(filter: DocumentFilter): number {
    return this.subtotals.find((sub) => sub.category == filter.name).quantity;
  }
}
