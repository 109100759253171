import { TableList } from 'projects/core/src/lib/models/dynamic-table.model';
import { DynamicForm } from 'projects/core/src/lib/models/form.model';

export class ChatSection {
  timestamp: Date;
  messagesByUser: MessageGroup[];
}

export class MessageGroup {
  user: string;
  colorClass?: string;
  messages: Message[];
}

export class Message {
  message: string;
  timestamp: Date;
}

export class MessagesRow {
  date: Date;
  message: string;
  user: string;
}

export class ChatFormMapper {
  static mapChatFormToTableList(form: DynamicForm): TableList {
    return form.body[0].fieldGroup[1].value.table;
  }

  static mapChatFormToMessages(form: DynamicForm): MessagesRow[] {
    const grid = this.mapChatFormToTableList(form);
    return this.mapGridToMessages(grid);
  }

  static mapGridToMessages(grid: TableList): MessagesRow[] {
    return grid.sortedRows
      .map(({ columns: [date, user, message] }) => ({
        date: new Date(date),
        user,
        message,
      }))
      .sort(({ date: a }, { date: b }) => a.getTime() - b.getTime());
  }
}

export class ChatResolverData {
  messages: MessagesRow[];
  userPool: string[];
  userName: string;
  chatSections: ChatSection[] = [];

  constructor(grid: TableList, userName: string) {
    const mappedMessagesRows: MessagesRow[] = ChatFormMapper.mapGridToMessages(grid);

    this.messages = mappedMessagesRows;
    this.userPool = this.extractUserPoolFromMessages(mappedMessagesRows);
    this.userName = userName;
  }

  isSender(messageRow: MessagesRow): boolean {
    return this.userName === messageRow.user;
  }

  private extractUserPoolFromMessages(messages: MessagesRow[]): string[] {
    return [...new Set(messages.map(({ user }) => user))];
  }
}

export class ChatResolver {
  static readonly ONE_HOUR = 1000 * 60 * 60;
  resolver: ChatResolverData;
  mapGridToChatSections(grid: TableList, userName: string): ChatSection[] {
    this.resolver = new ChatResolverData(grid, userName);

    this.resolver.messages.forEach((row) => {
      const lastSection = this.resolver.chatSections?.slice(-1)?.[0];
      const sameDate = lastSection?.timestamp.toDateString() === row.date.toDateString();
      if (sameDate) {
        this.handleMessageOfSameDate(lastSection, row);
      } else {
        this.addNewChatSection(row);
      }
    });

    return this.resolver.chatSections;
  }

  addNewChatSection(messageRow: MessagesRow) {
    const { date, user, message } = messageRow;
    this.resolver.chatSections.push({
      timestamp: date,
      messagesByUser: [
        {
          user,
          colorClass: this.resolveColorClass(user, this.resolver.userPool),
          messages: [{ message, timestamp: date }],
        },
      ],
    });
  }

  handleMessageOfSameDate(lastSection: ChatSection, messageRow: MessagesRow) {
    const { date, user, message } = messageRow;
    const lastMessageGroup = lastSection.messagesByUser[lastSection.messagesByUser.length - 1];
    const latestMessageOfUser = lastMessageGroup.messages.slice(-1)[0];

    const sameUser = lastMessageGroup.user === user;
    const messageWithinTimeDelta =
      date.getTime() - latestMessageOfUser.timestamp.getTime() < ChatResolver.ONE_HOUR;

    if (sameUser && messageWithinTimeDelta) {
      lastMessageGroup.messages.push({ message, timestamp: date });
    } else {
      lastSection.messagesByUser.push({
        user,
        colorClass: this.resolveColorClass(user, this.resolver.userPool),
        messages: [{ message, timestamp: date }],
      });
    }
  }

  resolveColorClass(user: string, userPool: string[]): string {
    return `color-chat-${(userPool.indexOf(user) % 10) + 1}`;
  }
}
