import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { APIError } from '../data/errors.data';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { FormMapper } from '../mappers/form.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { DynamicForm } from '../models/form.model';
import { Invoker, InvokerTypes } from '../models/invoker-body.model';
import { SDAPIResponseObject } from '../models/sdapi-object.model';

@Injectable()
export class FormService {
  constructor(
    private http: HttpClient,
    private dynamicFormMapper: DynamicFormMapper,
  ) {}

  public saveForm<T>(
    form: DynamicForm,
    excludeInvisibleItems?: boolean,
    httpHeaders: HttpHeaders = new HttpHeaders(),
  ): Observable<T> {
    if (excludeInvisibleItems) {
      this.dynamicFormMapper.filterOutInvisibleFormItems(form);
    }
    const invoker = this.findFormSaveInvoker(form);
    if (invoker) {
      return this.http.put<T>(
        `${invoker.activityURL}/step`,
        FormMapper.mapInvokerWithFieldValues(form, invoker),
        { headers: httpHeaders },
      );
    } else {
      throw new APIError(`No Invoker of type 'SAVE', 'STEP0' or 'SEARCH'.`);
    }
  }

  public saveFormWithResponse(
    form: DynamicForm,
    excludeInvisibleItems?: boolean,
    httpHeaders: HttpHeaders = new HttpHeaders(),
  ): Observable<DynamicForm> {
    try {
      return this.saveForm<SDAPIResponseObject>(form, excludeInvisibleItems, httpHeaders).pipe(
        map((response) => SDAPIObjectMapper.getFormObjectFromBulkServerResponse(response)),
        map((formObject) =>
          this.dynamicFormMapper.mapDynamicFormResource(formObject, form.activityURL),
        ),
      );
    } catch (error) {
      throw new APIError('Cannot save form or resolve BulkServerResponse', error);
    }
  }

  private findFormSaveInvoker(form: DynamicForm): Invoker {
    return form.invoker.find((invoker: Invoker) =>
      [InvokerTypes.SAVE, InvokerTypes.STEP0, InvokerTypes.SEARCH].includes(invoker.type),
    );
  }
}
