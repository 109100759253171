import { Component } from '@angular/core';
import { DataField } from 'projects/core/src/lib/models/data-exchange.model';
import { DocumentDetails, DocumentFormat } from 'projects/core/src/lib/models/documents.model';

@Component({
  selector: 'lib-theme-preview',
  templateUrl: './theme-preview.component.html',
  styleUrls: ['./theme-preview.component.scss'],
})
export class ThemePreviewComponent {
  lorem: string[];

  document: DocumentDetails = {
    name: 'Dokumententitel',
    date: new Date(),
    id: 293875293,
    downloadLink: '',
    httpContentType: '',
    mimeType: '',
    fileType: DocumentFormat.UNKNOWN,
    fileName: 'dokumententitel.abc',
    size: 8435793,
    downloadPending: false,
  };

  formFields: DataField[] = [
    {
      name: 'Eigenschaft',
      value: 'Wert',
      originValue: 'Wert',
      identifier: 'Wert',
      editable: true,
      type: 'text',
    },
    {
      name: 'Datum',
      value: new Date().toISOString(),
      originValue: new Date().toISOString(),
      identifier: 'Wert',
      editable: true,
      type: 'date',
    },
    {
      name: 'Geschlecht',
      value: 'other',
      originValue: 'other',
      identifier: 'other',
      editable: true,
      type: 'gender',
    },
  ];

  constructor() {
    this.lorem = [
      'Sollicitudin aenean adipiscing, hendrerit sodales vestibulum eu suscipit. Pellentesque maecenas magna hendrerit, dictumst curae nostra, turpis nunc phasellus orci lorem libero class quis. Taciti dui eu, per aliquam, lorem mauris phasellus quam sociosqu. Gravida luctus lacus, quam porttitor, commodo per nunc dictum nulla. Inceptos nunc, sem dui diam dolor. Mauris quisque eros taciti, gravida urna vulputate, sit diam tortor aliquam consequat ultrices integer. Sollicitudin hac duis, lobortis cubilia neque euismod quis.',
      'Vulputate velit ligula morbi, vivamus ullamcorper per augue adipiscing. Nibh amet maecenas tristique, mollis pharetra ornare, praesent fames gravida dapibus purus porta nam. Vitae ligula himenaeos magna, sed gravida torquent, ac nisl nulla elementum duis curabitur. Odio tellus, feugiat venenatis in eleifend. Litora sodales consectetur lectus, posuere blandit diam, sagittis dapibus rutrum proin quam integer nec tempor. Interdum iaculis etiam platea, luctus diam justo vulputate condimentum. Litora quisque leo luctus, platea donec egestas, primis integer pretium ad adipiscing laoreet. Ante hac, ligula turpis nisi vivamus. Interdum malesuada, augue sed dolor eu. Integer massa hac eros, justo eu malesuada bibendum neque.',
      'Habitasse suspendisse molestie, aenean etiam lectus pulvinar porttitor. Amet porta enim quam, scelerisque in lacus, a netus venenatis nisl nulla posuere cras. Accumsan quisque neque augue, amet ac dui, quis mauris netus consequat dolor hac. In habitant eget dictumst, ante pellentesque suscipit vestibulum sed. Nam semper neque a, quis consequat interdum adipiscing turpis.',
      'Fermentum aenean ut, taciti ultricies, eleifend ac rhoncus in aenean sit augue. Augue laoreet condimentum neque, malesuada sociosqu nulla, elementum fermentum interdum mollis habitant primis lorem. Fermentum neque tortor, cubilia fusce, dictum platea cras dapibus bibendum. Faucibus mauris at potenti, tincidunt ante arcu, morbi venenatis sociosqu tortor cursus libero maecenas etiam. Nam ut eget, magna urna aliquam nostra arcu. Faucibus varius, nostra tempor cras tincidunt. Nibh ante facilisis, ac dui felis orci arcu. Dictumst tortor malesuada, aliquet elementum, ante nulla mollis mattis nisi vitae.',
      'Leo auctor, at pretium suspendisse imperdiet. A vehicula maecenas, mollis quam, id gravida nec venenatis hendrerit tempor. Vehicula orci diam id, sit ligula purus vel eget. Platea dapibus tincidunt, varius eget vestibulum venenatis. Molestie netus nisi, praesent integer, blandit ullamcorper bibendum aliquam ac. Potenti viverra dui auctor, id cursus venenatis, ligula scelerisque mollis felis non nisl. Maecenas quam, nulla ultricies nostra. Massa sagittis inceptos, etiam ornare placerat pulvinar. Sapien enim, velit lobortis rhoncus.',
      'Mollis venenatis aliquet eros, nec habitasse duis, ut metus euismod habitant commodo nisi etiam dui. Quis varius, aliquam blandit dictumst. Quisque ac quam, netus eros interdum etiam. Cursus turpis duis, elementum pellentesque nam nisi. Proin vitae elementum vehicula, sodales amet sed, netus platea mauris aptent metus etiam aenean tincidunt. Varius platea netus ullamcorper, vel duis tortor, arcu in vulputate tempor bibendum ornare senectus blandit. Curabitur suscipit, tempor etiam et.',
      'Interdum curae fringilla, phasellus volutpat ultricies vitae. Pulvinar pharetra, molestie sem ut. Mollis elementum interdum luctus, vestibulum aliquam fermentum, torquent eleifend sem tempor quisque class praesent. Duis cras quisque leo, velit sociosqu ante, aliquam placerat ultrices vehicula etiam quam elit. Pellentesque etiam at sodales, imperdiet accumsan a, condimentum in taciti erat nisl ultricies ac.',
      'Imperdiet viverra arcu condimentum, auctor fermentum aptent, egestas sollicitudin senectus sed enim mattis sapien. Hac nam, cursus ad aenean torquent. Lacinia tortor pretium, nunc luctus, curae rhoncus velit suspendisse vivamus. Feugiat integer, faucibus dapibus pulvinar nostra. Congue magna purus, mi diam ultrices accumsan. Euismod turpis, pretium magna metus.',
      'Non morbi eget convallis, mauris arcu potenti, massa netus sed pharetra pulvinar magna at odio. Nullam eleifend eu arcu, bibendum amet donec, porta taciti tempor pulvinar vel platea consectetur. Cras massa platea, vulputate etiam, vestibulum phasellus tempus nullam consectetur eget gravida. Iaculis congue, tempor ad est. Cursus vehicula lobortis, ac ad, eu ligula etiam tempus etiam aenean pulvinar.',
      'Per fusce varius dictum, molestie velit euismod, aptent sagittis sodales eros ut semper donec. Lorem nisi nibh, ipsum litora ullamcorper per consequat. Feugiat fringilla rutrum, lacus dictumst, sodales aliquam ipsum pulvinar ligula blandit. Risus posuere non, nisi porttitor placerat lectus. Pretium etiam platea, himenaeos congue senectus gravida. Commodo eleifend donec, ligula suspendisse a sagittis. Curabitur aliquam at, etiam leo cubilia class ipsum. Quisque class ultricies, id varius ornare nisl litora.',
    ].sort(() => Math.random() - 0.5);
  }
}
