import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { TreatmentsService } from 'projects/core/src/lib/services/treatment.service';
import { take } from 'rxjs';

@Component({
  selector: 'lib-share-treatment-with-doctor',
  templateUrl: './share-treatment-with-doctor.component.html',
  styleUrls: ['./share-treatment-with-doctor.component.scss'],
})
@UntilDestroy()
export class ShareTreatmentWithDoctorComponent {
  @Output() treatmentShared: EventEmitter<void> = new EventEmitter();
  @Input() id: number;

  public readonly icons = {
    share: faArrowRight,
  };

  constructor(
    private treatmentService: TreatmentsService,
    private popupService: PopupService,
  ) {}

  shareWithDoctor(): void {
    this.treatmentService
      .getShareWithDoctorLink(this.id)
      .pipe(untilDestroyed(this), take(1))
      .subscribe((link) => {
        this.popupService.showShareTreatmentWithDoctorModal(link).then(async (result) => {
          if (result.role === OverlayEventRole.save) {
            this.treatmentShared.emit();
          }
        });
      });
  }
}
