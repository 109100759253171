import {
  IconDefinition,
  faFolder,
  faFolderTree,
  faGears,
  faUserPen,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { AttributeDetail } from './attribute.model';
import { DocumentFormat, ViewDocumentDetails } from './documents.model';
import { DynamicButton } from './dynamic-button.model';
import { TableList } from './dynamic-table.model';
import { TieTableRow } from './sdapi-table-object.model';

export class RequiredActionsDetails implements ViewDocumentDetails {
  processing?: boolean;
  size?: number;
  fileName: string;
  id: number;
  title: string;
  downloadPending: boolean;
  blob?: Blob;
  fileType: DocumentFormat;
  mimeType?: string;
  viewLink?: string;
  downloadLink: string;
  endDate: RequiredActionsDetail;
  task: RequiredActionsDetail;
  statusName: RequiredActionsDetail;
  startDate: RequiredActionsDetail;
  actionButtons: DynamicButton[];
  details?: AttributeDetail[];
  table?: TableList;

  get remainingDaysForProcessing(): number {
    return Math.floor(this.millisecondsToEndDate / (1000 * 60 * 60 * 24));
  }

  get isUrgent(): boolean {
    const timeRange = Date.parse(this.endDate.value) - Date.now();
    return timeRange > 0 && timeRange < 1000 * 60 * 60 * 72;
  }

  get isOverdue(): boolean {
    const endDate = new Date(this.endDate.value);
    return endDate < new Date();
  }

  private get millisecondsToEndDate(): number {
    const endDate: Date = new Date(this.endDate.value);
    return endDate.getTime() - Date.now();
  }
}

export class RequiredActionsDetail {
  label: string;
  value: string;
}

export class AttributeDisplayDetails {
  public readonly displayValue: string;
  public readonly value: string;

  constructor(row: TieTableRow, attributeName: string) {
    this.displayValue = row.getDisplayName(attributeName);
    this.value = row.getOptionalAttributeValue(attributeName);
  }
}

export enum RequiredActionsAttributeNames {
  id = 'SYS_OBJECT.OBJ_ID',
  title = 'SYS_OBJECT.OBJ_NAME',
  task = 'TBL.TEXT',
  startDate = 'TBL.DTSTART',
  endDate = 'TBL.DTEND',
}

export enum OptionalRequiredActionsAttributeNames {
  handler = 'TBL.MEMBER_TO',
  process = 'TBL.PROCESS',
  project = 'TBL.PROJECT',
  scheduleGroup = 'TBL.DUE_GROUP',
  sender = 'TBL.DISTRIBUTOR',
}

const identifier: typeof OptionalRequiredActionsAttributeNames =
  OptionalRequiredActionsAttributeNames;

export const OPTIONAL_REQUIRED_ACTIONS_ATTRIBUTE_IDENTIFIER_VALUES: OptionalRequiredActionsAttributeNames[] =
  [
    identifier.handler,
    identifier.process,
    identifier.project,
    identifier.scheduleGroup,
    identifier.sender,
  ];

export const OPTIONAL_REQUIRED_ACTIONS_ATTRIBUTE_ICONS = new Map<
  OptionalRequiredActionsAttributeNames,
  IconDefinition
>([
  [identifier.handler, faUserPen],
  [identifier.process, faGears],
  [identifier.project, faFolder],
  [identifier.scheduleGroup, faFolderTree],
  [identifier.sender, faUserTie],
]);

export enum StatusType {
  distribution = 'DISTRIBUTION',
  state = 'STATE',
}
