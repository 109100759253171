import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import {
  DataType,
  DynamicDataField,
  DynamicForm,
  FieldState,
  ProgressBar,
} from 'projects/core/src/lib/models/form.model';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-menu-button-content',
  templateUrl: './menu-button-content.component.html',
  styleUrls: ['./menu-button-content.component.scss'],
})
export class MenuButtonContentComponent {
  @Input() form: DynamicForm;
  @Input() item: DynamicDataField;
  @Input() activeGroupNumber: number;
  @Input() index: number;
  @Input() ngForm: NgForm;
  @Input() title: string;

  dataType: typeof DataType = DataType;
  inputState: typeof FieldState = FieldState;

  translations: object;

  readonly ICONS = {
    FORWARD: faChevronRight,
  };

  constructor(private translateService: TranslateService) {
    firstValueFrom(this.translateService.get('shared.forms.menu-button')).then(
      (translations) => (this.translations = translations),
    );
  }

  private getNumberOfRequiredFields(i: number, inputState?: FieldState): number {
    return this.getArrayOfDisplayableFields(i).filter((field: DynamicDataField) => {
      const requiredField = field.required;
      return this.matchFieldCriteria(field.value.value, requiredField, inputState);
    }).length;
  }

  private getNumberOfInputFields(i: number, inputState?: FieldState): number {
    return this.getArrayOfDisplayableFields(i).filter((field: DynamicDataField) => {
      const fieldTypeCondition =
        field.type === this.dataType.text ||
        field.type === this.dataType.textArea ||
        field.type === this.dataType.number ||
        field.type === this.dataType.date;
      return this.matchFieldCriteria(field.value.value, fieldTypeCondition, inputState);
    }).length;
  }

  private getNumberOfSelections(i: number, inputState?: FieldState): number {
    return this.getArrayOfDisplayableFields(i).filter((field: DynamicDataField) => {
      const fieldTypeCondition =
        field.type === this.dataType.radio ||
        field.type === this.dataType.select ||
        field.type === this.dataType.checkbox;
      return this.matchFieldCriteria(field.value.value, fieldTypeCondition, inputState);
    }).length;
  }

  private getProgressValue(getFieldCount: (i: number, state?: FieldState) => number): number {
    const totalFields = getFieldCount(this.index);
    const filledFields = getFieldCount(this.index, this.inputState.FILLED);

    return totalFields === 0 ? 1 : filledFields / totalFields;
  }

  private matchFieldCriteria(
    fieldValue: string,
    condition: boolean,
    inputState?: FieldState,
  ): boolean {
    switch (inputState) {
      case this.inputState.EMPTY:
        return (!fieldValue || fieldValue === 'false') && condition;
      case this.inputState.FILLED:
        return fieldValue && fieldValue !== 'false' && condition;
      default:
        return condition;
    }
  }

  private getArrayOfDisplayableFields(i: number): DynamicDataField[] {
    return this.form.body[i].fieldGroup.filter(
      (field: DynamicDataField) =>
        field.type !== this.dataType.title &&
        field.type !== this.dataType.unknown &&
        field.type !== this.dataType.hidden &&
        field.visible,
    );
  }

  isFieldGroupEmpty(i: number): boolean {
    return this.getArrayOfDisplayableFields(i).length === 0;
  }

  hasRequiredItemInGroup(group: DynamicDataField[]): boolean {
    return group.some((item: DynamicDataField) => item.required);
  }

  private hasFieldItemInGroup(group: DynamicDataField[]): boolean {
    return group.some(
      (item: DynamicDataField) =>
        item.type === this.dataType.text ||
        item.type === this.dataType.textArea ||
        item.type === this.dataType.number ||
        item.type === this.dataType.date,
    );
  }

  private hasSelectionItemInGroup(group: DynamicDataField[]): boolean {
    return group.some(
      (item: DynamicDataField) =>
        item.type === this.dataType.radio ||
        item.type === this.dataType.select ||
        item.type === this.dataType.checkbox,
    );
  }

  hasInvalidFields(form: NgForm, index: number): boolean {
    const groupedFields = this.form.body[index].fieldGroup;
    for (const value in form.form.controls) {
      if (
        groupedFields.some(
          (field: DynamicDataField) =>
            value.includes(field.identifier.originalValue) &&
            form.form.controls[value].invalid &&
            form.form.controls[value].touched,
        )
      ) {
        return true;
      }
    }
    return false;
  }

  trackBy(item: any): number {
    return item.id;
  }

  getProgressBarData(index): ProgressBar[] {
    const group = this.form.body[index].fieldGroup;
    return [
      {
        id: 1,
        progressValue: this.getProgressValue((i: number, inputState: FieldState) =>
          this.getNumberOfRequiredFields(i, inputState),
        ),
        onProgressText:
          this.translations['still'] +
          ' ' +
          this.getNumberOfRequiredFields(index, this.inputState.EMPTY) +
          ' ' +
          this.translations['required-fields'],
        onSuccessText: this.translations['all-required-fields-filled'],
        visibility: this.hasRequiredItemInGroup(group),
      },
      {
        id: 2,
        progressValue: this.getProgressValue((i: number, inputState: FieldState) =>
          this.getNumberOfInputFields(i, inputState),
        ),
        onProgressText:
          this.getNumberOfInputFields(index, this.inputState.FILLED) +
          ' ' +
          this.translations['fields-filled'],
        onSuccessText: this.translations['all-fields-filled'],
        visibility: this.hasFieldItemInGroup(group),
      },
      {
        id: 3,
        progressValue: this.getProgressValue((i: number, inputState: FieldState) =>
          this.getNumberOfSelections(i, inputState),
        ),
        onProgressText:
          this.getNumberOfSelections(index, this.inputState.FILLED) +
          ' ' +
          this.translations['options-selected'],
        onSuccessText: this.translations['all-options-selected'],
        visibility: this.hasSelectionItemInGroup(group),
      },
    ];
  }
}
