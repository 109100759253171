import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BookingQueue } from 'projects/core/src/lib/models/appointment-booking.model';
import { SharedBookingData } from '../booking-modal/booking-modal.component';
@Component({
  selector: 'lib-dynamic-date-selection',
  templateUrl: './dynamic-date.selection.component.html',
})
export class DynamicDateSelection {
  @Input() bookingQueue: BookingQueue<SharedBookingData>;
  @Output() onAppointmentSelect: EventEmitter<any> = new EventEmitter<any>();
}
