import { Component, OnInit } from '@angular/core';
import {
  faClock,
  faFile,
  faObjectGroup,
  IconDefinition,
} from '@fortawesome/free-regular-svg-icons';
import { faDoorOpen, faHospitalUser } from '@fortawesome/free-solid-svg-icons';
import {
  LastActivity,
  LastActivityType,
  TemplateLastActivityGroup,
} from 'projects/core/src/lib/models/last-activities.model';
import { LastActivitiesService } from 'projects/core/src/lib/services/last-activities.service';

@Component({
  selector: 'lib-last-activities',
  templateUrl: './last-activities.component.html',
  styleUrls: ['./last-activities.component.scss'],
})
export class LastActivitiesComponent implements OnInit {
  groupedLastActivities: TemplateLastActivityGroup[] = [];
  slicedGroupedActivities: TemplateLastActivityGroup[] = [];
  sliceValue = 3;
  isLoading = true;

  public icons = {
    document: faFile,
    patient: faHospitalUser,
    visit: faDoorOpen,
    object: faObjectGroup,
    default: faClock,
  };

  constructor(private lastActivitiesService: LastActivitiesService) {}

  ngOnInit() {
    this.lastActivitiesService
      .getLastActivities()
      .subscribe((lastActivities: LastActivity[]) => {
        this.groupedLastActivities = this.getGroupedLastActivities(lastActivities);
        this.slicedGroupedActivities = this.groupedLastActivities.slice(0, this.sliceValue);
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  getGroupedLastActivities(activities: LastActivity[]): TemplateLastActivityGroup[] {
    const activitiesGrouped: TemplateLastActivityGroup[] = [];

    const activitiesMapped = {};
    activities.forEach((activity) => {
      const date = new Date(activity.date).toDateString();
      if (activitiesMapped[date] == undefined) {
        activitiesMapped[date] = new TemplateLastActivityGroup();
        activitiesMapped[date].date = new Date(activity.date);
        activitiesMapped[date].activities = [];
      }

      const activityObject: LastActivity = new LastActivity();
      activityObject.date = activity.date;
      activityObject.title = activity.title;
      activityObject.type = activity.type;

      activitiesMapped[date].activities.push(activityObject);
    });

    for (const i in activitiesMapped) {
      activitiesGrouped.push(activitiesMapped[i]);
    }
    return activitiesGrouped.sort((objA, objB) => objB.date.getTime() - objA.date.getTime());
  }

  lastActivityTypeToIcon(type: string): IconDefinition {
    switch (type) {
      case LastActivityType.DOCUMENT:
        return this.icons.document;
      case LastActivityType.PATIENT:
        return this.icons.patient;
      case LastActivityType.VISIT:
        return this.icons.visit;
      case LastActivityType.OBJECT:
        return this.icons.object;
      default:
        return this.icons.default;
    }
  }

  loadData(event): void {
    if (this.groupedLastActivities.length > this.sliceValue) {
      this.sliceValue += 5;
      this.slicedGroupedActivities = this.groupedLastActivities.slice(0, this.sliceValue);
    }
    event.target.complete();
  }
}
