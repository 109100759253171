<div
  class="skeleton-container"
  [class.header-skeleton]="isHeaderItem"
  [class.desktop]="rowState.isDesktopView">
  <div class="skeleton-row">
    @for (column of skeletonColumnItemsList; track i; let i = $index) {
      <p>
        <ion-skeleton-text class="rounded-small like-h2" animated></ion-skeleton-text>
      </p>
    }
  </div>
  @if (isHeaderItem) {
    <div class="icon-gap"></div>
  } @else {
    <fa-icon [icon]="rowIcon" slot="end"></fa-icon>
  }
</div>
