<ion-grid [class.content]="containerize" class="content" [fixed]="true">
  <ion-row *ngIf="!consolidatedAvb">
    <ion-col>
      <h1>{{ 'shared.terms-of-service.title' | translate }}</h1>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="columnized">
      <ion-button (click)="openTos()" color="primary">{{
        'shared.terms-of-service.read-here' | translate
      }}</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
