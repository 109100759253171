import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DataType, DynamicDataField } from 'projects/core/src/lib/models/form.model';

@Component({
  selector: 'lib-field-container',
  templateUrl: './field-container.component.html',
  styleUrls: ['./field-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@UntilDestroy()
export class FieldContainerComponent {
  @Input() item: DynamicDataField;
  @Input() group: DynamicDataField;
  @Input() preview: boolean;
  @Input() isProcessing: boolean;

  dataType: typeof DataType = DataType;
}
