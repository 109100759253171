export class SharedMapper {
  public static parseEnum<T>(
    enumType: { [s: string | number]: T },
    value: string | number,
  ): T | undefined {
    switch (typeof value) {
      case 'string':
        return SharedMapper.enumFromString(enumType, value);
      case 'number':
        return SharedMapper.enumFromNumber(enumType, value);
      default:
        return undefined;
    }
  }

  private static enumFromString<T>(enumType: { [s: string]: T }, value: string): T | undefined {
    return (Object.values(enumType) as unknown as string[]).includes(value)
      ? (value as unknown as T)
      : undefined;
  }

  private static enumFromNumber<T>(enumType: { [s: number]: T }, value: number): T | undefined {
    return (Object.values(enumType) as unknown as number[]).includes(value)
      ? (value as unknown as T)
      : undefined;
  }
}
