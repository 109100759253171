<lib-modal-header
  [titleIcon]="icons.fingerprint"
  [title]="'shared.autoident.verification' | translate"
  [style]="modalHeaderStyle"
  [showCancelButton]="false"></lib-modal-header>

<div class="content-container">
  @if (isLoading) {
    <div class="loader">
      <img src="shared/assets/images/loading-spinner.svg" alt="Loading Animation" />
    </div>
  }
  <iframe
    (load)="hideLoader()"
    *ngIf="sanitizedURL"
    [title]="title"
    [src]="sanitizedURL"
    allow="camera *; microphone *"
    allowfullscreen
    width="100%"
    height="100%"
    #iframe></iframe>
</div>
