import { isDevMode } from '@angular/core';

export function debugKeyIsPressed(event: MouseEvent) {
  return isDevMode() && event.altKey;
}

export function consoleDebugInDevMode(event: MouseEvent, object: any) {
  event.stopPropagation();
  if (debugKeyIsPressed(event)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.DebugFormatter) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line no-console
      console.debug(window.DebugFormatter(object));
    } else {
      // eslint-disable-next-line no-console
      console.debug(object);
    }
  }
}
