import { Injectable } from '@angular/core';
import {
  DocumentFilter,
  DocumentFilterColor,
  DocumentFilterIcon,
  DocumentFilterName,
} from '../models/documents.model';
import { FilterParams } from '../models/shared.model';

@Injectable()
export class ItemFilterService {
  public filter(data: any[], filterParams: FilterParams): any[] {
    if (filterParams?.filters?.length === 0) {return data;}

    const processedData = [...data];
    return processedData.filter((item: any[]) => {
      if (
        new RegExp(filterParams.filters.join('|')).test(
          this.getNestedValue(filterParams.field, item),
        )
      ) {
        return item;
      } else {
        return null;
      }
    });
  }

  public generateDocumentsFilters(): DocumentFilter[] {
    const docFilters: DocumentFilter[] = [];
    Object.keys(DocumentFilterName).forEach((filterNameKey) =>
      docFilters.push(this.generateDocumentFilter(filterNameKey)),
    );
    return docFilters;
  }

  private generateDocumentFilter(key: string): DocumentFilter {
    return {
      name: DocumentFilterName[key] || DocumentFilterName.OTHERS,
      icon:
        DocumentFilterIcon.get(DocumentFilterName[key]) ||
        DocumentFilterIcon.get(DocumentFilterName.OTHERS),
      selected: false,
      colorClass: DocumentFilterColor[key] || DocumentFilterColor.OTHERS,
    };
  }

  private getNestedValue(name: string, object: any) {
    return name.split('.').reduce((parent, child) => parent?.[child], object);
  }
}
