<ng-container *ngIf="viewDocument" [ngSwitch]="viewDocument.fileType">
  <lib-pdf-viewer
    *ngSwitchCase="documentFormatEnum.PDF"
    (loading)="loadingSkeleton($event)"
    (commitButtonsVisibility)="commitButtonsVisibility($event)"
    [url]="viewDocument.viewLink">
  </lib-pdf-viewer>

  <lib-iframe-viewer
    *ngSwitchCase="documentFormatEnum.DICOM"
    [url]="viewDocument.viewLink | safeResourceUrl"
    (commitButtonsVisibility)="commitButtonsVisibility($event)">
  </lib-iframe-viewer>

  <lib-iframe-viewer
    *ngSwitchCase="documentFormatEnum.HTML"
    [url]="viewDocument.viewLink | safeResourceUrl"
    (commitButtonsVisibility)="commitButtonsVisibility($event)">
  </lib-iframe-viewer>

  <lib-video-viewer
    *ngSwitchCase="documentFormatEnum.VIDEO"
    (commitButtonsVisibility)="commitButtonsVisibility($event)"
    [video]="viewDocument">
  </lib-video-viewer>

  <lib-image-viewer
    *ngSwitchCase="documentFormatEnum.IMAGE"
    (commitButtonsVisibility)="commitButtonsVisibility($event)"
    [image]="viewDocument"></lib-image-viewer>

  <lib-link-viewer
    *ngSwitchCase="documentFormatEnum.LINK"
    (commitButtonsVisibility)="commitButtonsVisibility($event)"
    [document]="viewDocument"></lib-link-viewer>

  <lib-download-viewer
    *ngSwitchDefault
    (commitButtonsVisibility)="commitButtonsVisibility($event)"
    [document]="viewDocument"></lib-download-viewer>
</ng-container>
