import { Component, DestroyRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessagesData } from 'projects/core/src/lib/data/alert-messages.data';
import { APIError } from 'projects/core/src/lib/data/errors.data';
import {
  DynamicButton,
  DynamicButtonSetConfiguration,
} from 'projects/core/src/lib/models/dynamic-button.model';
import {
  DeviationDirection,
  DynamicDataField,
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormLayoutType,
  DynamicFormType,
} from 'projects/core/src/lib/models/form.model';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';
import { OperationKey, TranslationOptions } from 'projects/core/src/lib/models/modal-action.model';
import { SDAPIResponseObject } from 'projects/core/src/lib/models/sdapi-object.model';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { DynamicFormService } from 'projects/core/src/lib/services/dynamic-form.service';
import { FormService } from 'projects/core/src/lib/services/form.service';
import { LabelService } from 'projects/core/src/lib/services/label.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { ModalActionService } from 'projects/core/src/lib/services/modal-action.service';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';
import { SkeletonService } from 'projects/core/src/lib/services/skeleton.service';
import { UploadService } from 'projects/core/src/lib/services/upload.service';
import { consoleDebugInDevMode } from 'projects/core/src/lib/utils/debug.utils';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lib-dynamic-form-flexible-layout',
  templateUrl: './dynamic-form-flexible-layout.component.html',
  styleUrls: ['./dynamic-form-flexible-layout.component.scss'],
  host: { class: 'handle-ion-layout form-modal' },
})
export class DynamicFormFlexibleLayoutComponent implements OnInit {
  @Input() configuration: DynamicFormConfiguration;

  @ViewChild('f') formElement: NgForm;

  dynamicFormType: typeof DynamicFormType = DynamicFormType;

  isLoading = true;
  form: DynamicForm;
  activeGroupNumber: number = undefined;
  allFormGroupsShown = false;
  preview = false;
  viewMode: string;
  formTranslations: Object;
  actionButtonConfiguration: DynamicButtonSetConfiguration;

  constructor(
    private modalController: ModalController,
    private dynamicFormService: DynamicFormService,
    private formService: FormService,
    private uploadService: UploadService,
    private alertService: AlertService,
    public breakPoint: BreakpointService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private destroyRef: DestroyRef,
    private modalActionService: ModalActionService,
    private labelService: LabelService,
  ) {}

  get isFormGroupActive(): boolean {
    return this.activeGroupNumber >= 0;
  }

  get hasPrintableLabel(): boolean {
    return !!this.form?.labelData;
  }

  get showNavigationForMultipleGroupsOnMobile(): boolean {
    const viewRequiresNavigation = this.isFormGroupActive || this.preview;
    const hasMultipleGroups = this.form.body.length > 1;

    return this.isMobileView && viewRequiresNavigation && hasMultipleGroups;
  }

  get hasMultipleGroupsOnDesktopOrInMobileGroupOverview(): boolean {
    return (!this.isFormGroupActive || this.isDesktopView) && this.form.body.length > 1;
  }

  get isMobileView(): boolean {
    return this.viewMode === DynamicFormLayoutType.MOBILE;
  }

  get isDesktopView(): boolean {
    return this.viewMode === DynamicFormLayoutType.DESKTOP;
  }

  get buttonExpandValue(): string | undefined {
    return this.isMobileView ? 'block' : undefined;
  }

  get activityChangeActionSheetConfig(): DynamicButtonSetConfiguration {
    return {
      actionButtons: this.form.actionButtons,
      triggerButton: {
        label: this.formTranslations['options'],
        color: 'primary',
        disabled: false,
        dataApiId: 'change-activity',
      },
    };
  }

  get isNavigationLayoutRequired(): boolean {
    const isPreview = this.preview;
    const isDesktop = this.viewMode === DynamicFormLayoutType.DESKTOP;
    const isMobile = this.viewMode === DynamicFormLayoutType.MOBILE;
    const isViewOnly = this.configuration.type === DynamicFormType.VIEW;
    return (isPreview && !isMobile) || isDesktop || (!isViewOnly && !isPreview && isMobile);
  }

  get isMobilePreviewLayoutRequired(): boolean {
    const isPreview = this.preview;
    const isMobile = this.viewMode === DynamicFormLayoutType.MOBILE;
    const isViewOnly = this.configuration.type === DynamicFormType.VIEW;
    return (isPreview || isViewOnly) && isMobile;
  }

  @HostListener('mousedown', ['$event']) logForm = (e) => consoleDebugInDevMode(e, this.form);

  async ngOnInit(): Promise<void> {
    this.getTranslatedDynamicForm();
  }

  private async getActivityChangeActionSheetConfig(): Promise<DynamicButtonSetConfiguration> {
    const actionButtons: DynamicButton[] = await this.constructActionButtons();
    return {
      actionButtons,
      triggerButton: {
        label: this.formTranslations['options'],
        color: 'primary',
        disabled: false,
        dataApiId: 'change-activity',
      },
    };
  }

  private async constructActionButtons(): Promise<DynamicButton[]> {
    if (!this.hasPrintableLabel) {
      return this.form.actionButtons;
    }
    const labelButtons: DynamicButton[] = await this.labelService.constructDownloadAndPrintButtons(
      this.form.labelData,
    );
    return [...this.form.actionButtons, ...labelButtons];
  }

  generateSkeletonMenuItems(): Array<number> {
    const rowHeight = 80;
    const offsetHeader = this.breakPoint.isAbove('sm') ? 345 : 125;
    return Array(SkeletonService.getMaxRowAmount(offsetHeader, rowHeight)).fill(0);
  }

  generateSkeletonFields(): Array<number> {
    return Array(SkeletonService.getMaxRowAmount(345, 94)).fill(0);
  }

  onWindowResize(): void {
    if (this.breakPoint.isAbove('sm')) {
      this.viewMode = DynamicFormLayoutType.DESKTOP;
      this.activeGroupNumber = this.activeGroupNumber ?? 0;
    } else {
      this.viewMode = DynamicFormLayoutType.MOBILE;
    }
  }

  cancel(): Promise<boolean> {
    return this.modalController.dismiss(null, OverlayEventRole.cancel);
  }

  async handleFormSave(): Promise<void> {
    await this.showActionInProgressMessage();
    await this.saveForm();
  }

  async handleFormUploadSave(): Promise<void> {
    await this.showActionInProgressMessage();
    await this.saveUploadForm();
  }

  async handleFormUploadSubmission(invoker?: Invoker): Promise<void> {
    await this.showActionInProgressMessage();
    await this.saveAndSendUploadForm(invoker);
  }

  async handleFormSubmission(invoker?: Invoker): Promise<void> {
    await this.showActionInProgressMessage();
    if (this.form.invoker?.length > 0) {
      await this.saveAndSendForm(invoker);
    } else {
      await this.sendForm(invoker);
    }
  }

  async dismissModalWithActionEvent(invoker?: Invoker): Promise<void> {
    await this.modalController.dismiss({ invoker }, OverlayEventRole.actionButtonEvent);
  }

  isPatternInvalid(form: NgForm): boolean {
    return !(
      !form.errors ||
      Object.keys(form.errors).filter((key) => key.includes('invalid-input')).length === 0
    );
  }

  shouldDisableSubmitButton(form: NgForm): boolean {
    return form.invalid;
  }

  shouldDisableVerifyButton(form: NgForm): boolean {
    return form.invalid;
  }

  resetActiveGroup(): void {
    this.activeGroupNumber = this.form.body.length === 1 ? 0 : undefined;
    this.allFormGroupsShown = false;
    this.preview = false;
  }

  togglePreviewMode(): void {
    this.preview = !this.preview;
  }

  activateFormGroup(index: number): void {
    this.activeGroupNumber = index;
    this.updateAllFormGroupsShownValue();
  }

  openNextGroup(): void {
    this.activeGroupNumber++;
    this.handleInvalidFormGroups(DeviationDirection.incremental);
    this.updateAllFormGroupsShownValue();
  }

  openPreviousGroup(): void {
    if (this.preview) {
      this.activeGroupNumber = this.getLastValidGroupNumber();
      this.preview = false;
    } else {
      this.activeGroupNumber--;
      this.handleInvalidFormGroups(DeviationDirection.decremental);
    }
    this.updateAllFormGroupsShownValue();
  }

  getLastValidGroupNumber(): number {
    const distance = this.form.body
      .map((field) => field.visible)
      .reverse()
      .indexOf(true);
    return distance < 0 ? 0 : this.form.body.length - distance - 1;
  }

  isFieldGroupEmpty(i: number): boolean {
    return this.getArrayOfDisplayableFields(i).length === 0;
  }

  openFormPreview(): void {
    this.activeGroupNumber = undefined;
    this.preview = true;
  }

  getFormStyles(group: DynamicDataField[], index: number): StylingInformation {
    if (this.breakPoint.isAbove('lg')) {
      return {
        ...this.getDesktopGridStyles(group),
        ...this.getFormGroupVisibilityStyles(index),
      };
    } else {
      return {
        ...this.getMobileGridStyles(),
        ...this.getFormGroupVisibilityStyles(index),
      };
    }
  }

  getFormVisibilityStyles(): StylingInformation {
    const invisible: boolean = this.activeGroupNumber === undefined;
    return this.isSingleFormInDesktopView()
      ? { display: 'flex' }
      : this.getInvisibleItemStyles(invisible, '');
  }

  getInvisibleItemStyles(invisible: boolean, display: string): StylingInformation {
    return { display: invisible ? 'none' : display };
  }

  getFormFieldStyles(group: DynamicDataField[], item: DynamicDataField): StylingInformation {
    const filteredGroup = group.filter((field: DynamicDataField) =>
      this.isDisplayableFieldType(field),
    );
    if (this.breakPoint.isAbove('lg')) {
      const { colNum, colSpan, rowNum, rowSpan } = item;
      const minColumnStartNumber = Math.min(
        ...filteredGroup.map((field: DynamicDataField) => field.colNum),
      );
      const gridColumn = `${colNum - minColumnStartNumber + 1} / span ${colSpan}`;
      const firstRowNum = filteredGroup[0].rowNum;
      const gridRow = `${rowNum - (firstRowNum - 1)} / span ${rowSpan}`;

      return {
        gridColumn,
        gridRow,
      };
    } else {
      return { order: `${item.sequence}` };
    }
  }

  isDisplayableFieldType(item: DynamicDataField): boolean {
    return DynamicFormService.isDisplayableFieldType(item);
  }

  isDisplayableGroupType(group: DynamicDataField): boolean {
    return DynamicFormService.isDisplayableGroupType(group);
  }

  isSingleFormInDesktopView(): boolean {
    return this.form.body.length === 1 && this.isDesktopView;
  }

  isFormReadyForMobilePreview(): boolean {
    return !this.preview && (!this.isFormGroupActive || this.form.body.length === 1);
  }

  getSaveButtonText(form: NgForm): string {
    if (this.isPatternInvalid(form)) {
      return this.formTranslations['invalid-input-prompt'];
    } else {
      return this.formTranslations['save'] + ' & ' + this.formTranslations['close'];
    }
  }

  getRequiredTaskActionSheetConfig(form: NgForm): DynamicButtonSetConfiguration {
    return {
      actionButtons: this.configuration.requiredActionsDetails.actionButtons,
      triggerButton: {
        label: this.formTranslations['options'],
        color: 'primary',
        disabled: this.shouldDisableSubmitButton(form),
        dataApiId: 'change-activity',
      },
    };
  }

  private getTranslatedDynamicForm() {
    this.translateService
      .get('shared.forms')
      .pipe(takeUntilDestroyed(this.destroyRef), take(1))
      .subscribe((translations) => {
        this.formTranslations = translations;
        this.getDynamicForm();
      });
  }

  private getDynamicForm(): void {
    this.dynamicFormService
      .getDynamicForm(this.configuration)
      .pipe(takeUntilDestroyed(this.destroyRef), take(1))
      .subscribe({
        next: async (dynamicForm: DynamicForm) => {
          this.form = dynamicForm;
          await this.configureDynamicFormDetails();
        },
        error: async (error) => {
          if (error instanceof APIError) {
            await this.alertService
              .presentAlert(AlertMessagesData.dynamicFormInvokerError)
              .then(async () => {
                await this.modalController.dismiss(error, 'error');
              });
          }
        },
      })
      .add(() => {
        this.setViewMode();
        this.isLoading = !this.form;
      });
  }

  private async configureDynamicFormDetails(): Promise<void> {
    await this.assignNamesToUnnamedGroups();
    this.actionButtonConfiguration = await this.getActivityChangeActionSheetConfig();
    this.handleFieldVisibilityChange();
    this.handleFormProcessed();
  }

  private setViewMode(): void {
    this.viewMode = this.breakPoint.isAbove('sm')
      ? DynamicFormLayoutType.DESKTOP
      : DynamicFormLayoutType.MOBILE;
    if (this.isDesktopView || this.form.body.length === 1) {
      this.activeGroupNumber = 0;
    }
  }

  private async assignNamesToUnnamedGroups(): Promise<void> {
    const groupName = this.formTranslations['general-field-group'];
    let counter = 1;
    for (const group of this.form.body) {
      if (!group.name) {
        group.name = `${groupName} ${counter}`;
        counter++;
      }
    }
  }

  private handleFieldVisibilityChange(): void {
    this.form.fieldVisibilityChanged.subscribe(() => {
      this.updateAllFormGroupsShownValue();
      this.formElement.control.updateValueAndValidity();
    });
  }

  private handleFormProcessed(): void {
    this.form.formProcessed.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.validateAllFormFields(this.formElement.control);
    });
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.controls[field];
      if (control instanceof FormControl) {
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private async showActionInProgressMessage(): Promise<void> {
    const options: TranslationOptions = this.configuration.translationOptions;
    const messageKey: OperationKey = options?.actionInProgressKey;
    const fallbackKey: string = 'saving-in-progress';
    if (messageKey) {
      await this.tryShowInProgressMessage(options.keys, messageKey, fallbackKey);
    } else {
      await this.loadingService.load(this.formTranslations[fallbackKey]);
    }
  }

  private async tryShowInProgressMessage(
    keySet: Map<OperationKey, string>,
    messageKey: OperationKey,
    fallbackKey: string,
  ): Promise<void> {
    try {
      await this.modalActionService.showLoadingMessage(keySet, messageKey);
    } catch (error) {
      console.error(error);
      await this.loadingService.load(this.formTranslations[fallbackKey]);
    }
  }
  private saveAndSendForm(invoker: Invoker): Promise<void> {
    return new Promise((resolve, reject) => {
      this.formService
        .saveForm<SDAPIResponseObject>(this.form, true)
        .pipe(takeUntilDestroyed(this.destroyRef), take(1))
        .subscribe({
          next: async (response: SDAPIResponseObject) => {
            await this.modalController
              .dismiss(
                {
                  response,
                  form: this.form,
                  requiredAction: this.configuration.requiredActionsDetails,
                  invoker,
                },
                OverlayEventRole.actionButtonEvent,
              )
              .then(async () => {
                await this.stopLoadingAndShowToast(invoker);
                resolve();
              });
          },
          error: () => {
            reject(this.loadingService.stop());
          },
        });
    });
  }

  private sendForm(invoker: Invoker): Promise<void> {
    return this.modalController
      .dismiss(
        { requiredAction: this.configuration.requiredActionsDetails, invoker },
        OverlayEventRole.actionButtonEvent,
      )
      .then(async () => {
        await this.stopLoadingAndShowToast(invoker);
      });
  }

  private saveForm(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.formService
        .saveForm<SDAPIResponseObject>(this.form, true)
        .pipe(takeUntilDestroyed(this.destroyRef), take(1))
        .subscribe({
          next: async (response: SDAPIResponseObject) => {
            await this.modalController
              .dismiss({ response }, OverlayEventRole.save)
              .then(async () => {
                await this.stopLoadingAndShowToast();
                resolve();
              });
          },
          error: () => reject(this.loadingService.stop()),
        });
    });
  }

  private async saveUploadForm(): Promise<void> {
    try {
      const response = await this.uploadService.saveDynamicFormWithFile<SDAPIResponseObject>(
        this.form,
      );
      await this.modalController.dismiss({ response }, OverlayEventRole.save);
      await this.stopLoadingAndShowToast();
    } catch (error) {
      await this.handleUploadFormSaveError();
      throw error;
    }
  }

  private async saveAndSendUploadForm(invoker: Invoker): Promise<void> {
    try {
      await this.uploadService.saveDynamicFormWithFile<SDAPIResponseObject>(this.form);
      await this.modalController.dismiss(
        {
          requiredAction: this.configuration.requiredActionsDetails,
          invoker,
        },
        OverlayEventRole.actionButtonEvent,
      );
      await this.stopLoadingAndShowToast(invoker);
    } catch (error) {
      await this.handleUploadFormSaveError();
      throw error;
    }
  }

  private async handleUploadFormSaveError(): Promise<void> {
    await this.loadingService.stop();
    await this.alertService.presentAlert(AlertMessagesData.genericErrorAlert);
  }

  private async showSuccessToast(): Promise<void> {
    const options: TranslationOptions = this.configuration.translationOptions;
    const messageKey: OperationKey = options?.successMessageKey;
    const fallbackKey: string = 'saving-successful';
    if (messageKey) {
      await this.tryShowSuccessToast(options.keys, messageKey, fallbackKey);
    } else {
      await this.loadingService.toast(this.formTranslations[fallbackKey]);
    }
  }

  private async tryShowSuccessToast(
    keySet: Map<OperationKey, string>,
    messageKey: OperationKey,
    fallbackKey: string,
  ): Promise<void> {
    try {
      await this.modalActionService.showToastMessage(keySet, messageKey);
    } catch (error) {
      console.error(error);
      await this.loadingService.toast(this.formTranslations[fallbackKey]);
    }
  }

  private async stopLoadingAndShowToast(invoker?: Invoker): Promise<void> {
    const shouldShowSuccessToast: boolean = !invoker;
    await this.loadingService.stop();
    if (shouldShowSuccessToast) {
      await this.showSuccessToast();
    }
  }

  private updateAllFormGroupsShownValue(): void {
    const nextGroupNumber: number = this.activeGroupNumber + 1;
    const isNextGroupLast: boolean = nextGroupNumber >= this.form.body.length - 1;
    this.allFormGroupsShown = !this.isGroupValid(nextGroupNumber) && isNextGroupLast;
  }

  private isGroupValid(groupNumber: number): boolean {
    return (
      this.form.body[groupNumber] &&
      !this.isFieldGroupEmpty(groupNumber) &&
      this.isFieldGroupVisible(groupNumber)
    );
  }

  private handleInvalidFormGroups(direction: DeviationDirection): void {
    while (!this.isGroupValid(this.activeGroupNumber)) {
      if (direction === DeviationDirection.incremental) {
        this.activeGroupNumber++;
      } else {
        this.activeGroupNumber--;
      }
    }
  }

  private isFieldGroupVisible(i: number): boolean {
    return this.form.body[i].visible;
  }

  private getArrayOfDisplayableFields(i: number): DynamicDataField[] {
    return this.form.body[i].fieldGroup.filter((field: DynamicDataField) =>
      this.isDisplayableFieldType(field),
    );
  }

  private getDesktopGridStyles(group: DynamicDataField[]): StylingInformation {
    const filteredGroup = group.filter((field: DynamicDataField) =>
      this.isDisplayableFieldType(field),
    );
    const maxColumnNumber = Math.max(
      ...filteredGroup.map((field: DynamicDataField) => field.colNum + field.colSpan),
    );
    const minColumnStartNumber = Math.min(
      ...filteredGroup.map((field: DynamicDataField) => field.colNum),
    );
    const totalColumnNumber = maxColumnNumber - minColumnStartNumber;

    const minGroupRowNumber = filteredGroup[0]?.rowNum;
    const maxGroupRowNumber = filteredGroup[filteredGroup.length - 1]?.rowNum;
    const maxRowNum = Math.max(...filteredGroup.map((field: DynamicDataField) => field?.rowNum));
    const maxRowSpanOfMaxRowNumber = Math.max(
      ...filteredGroup
        .filter((field: DynamicDataField) => field?.rowNum === maxRowNum)
        .map((field: DynamicDataField) => field.rowSpan),
    );
    const totalRowNumber = maxGroupRowNumber + maxRowSpanOfMaxRowNumber - minGroupRowNumber;

    return {
      columnGap: '20px',
      rowGap: '15px',
      gridTemplateColumns: `repeat(${totalColumnNumber}, minmax(0, 1fr))`,
      gridTemplateRows: `repeat(${totalRowNumber}, minmax(0, auto))`,
      paddingTop: this.form.body.length === 1 ? '15px' : '5px',
    };
  }

  private getMobileGridStyles(): StylingInformation {
    return { rowGap: '15px', paddingTop: this.form.body.length === 1 ? '15px' : '5px' };
  }

  private getFormGroupVisibilityStyles(index: number): StylingInformation {
    const invisible: boolean = this.activeGroupNumber !== index;
    return this.getInvisibleItemStyles(invisible, 'grid');
  }
}

export type StylingInformation = {
  [key: string]: any;
};
