<ion-item
  [class.hide-item-icon]="!hasNavigationIcon"
  button="true"
  lines="none"
  (click)="viewMedicalCertifciate(medicalCertificate)">
  <div class="list-row" [ngClass]="isExtendedView ? 'extended' : 'condensed'">
    <div class="thumbnail-item">
      <fa-icon [icon]="icons.notesMedical"></fa-icon>
    </div>

    @if (!isExtendedView) {
      <lib-auto-layout
        [padding]="[7, 0, 7, 10]"
        direction="vertical"
        vertical="start"
        horizontal="center"
        class="stretch-self trim-text-inline">
        <lib-auto-layout
          [gap]="[10]"
          direction="horizontal"
          horizontal="space-between"
          vertical="center"
          class="stretch-self trim-text-inline">
          <h3 class="ion-no-margin stretch-self trim-text-inline">{{ medicalCertificate.name }}</h3>
          <span class="detail">
            {{ medicalCertificate.duration ? medicalCertificate.duration : '' }}
          </span>
        </lib-auto-layout>
        <lib-auto-layout
          [gap]="[10]"
          direction="horizontal"
          horizontal="space-between"
          vertical="center"
          class="stretch-self trim-text-inline">
          <span class="detail trim-text-inline">
            {{ medicalCertificate.dateFrom | localeDate: 'shortDate' }}
            {{
              medicalCertificate.dateTo
                ? ' - ' + (medicalCertificate.dateTo | localeDate: 'shortDate')
                : ''
            }}
          </span>
          <span class="detail">
            {{
              medicalCertificate.status
                ? medicalCertificate.status
                : ('shared.documents.no-specification' | translate)
            }}
          </span>
        </lib-auto-layout>
      </lib-auto-layout>
    }

    @if (isExtendedView) {
      <div class="details">
        <div class="name-item trim-text-inline">
          <ion-text class="name">{{ medicalCertificate.name }}</ion-text>
        </div>

        <div class="date-item ion-text-end">
          <ion-text class="detail">
            {{ medicalCertificate.dateFrom | localeDate: 'shortDate' }}
            {{
              medicalCertificate.dateTo
                ? ' - ' + (medicalCertificate.dateTo | localeDate: 'shortDate')
                : ''
            }}
            {{ medicalCertificate.duration ? ' (' + medicalCertificate.duration + ')' : '' }}
          </ion-text>
        </div>

        <div slot="end" class="size-item ion-text-end" data-api-id="document-details">
          <ion-text class="detail">
            {{
              medicalCertificate.status
                ? medicalCertificate.status
                : ('shared.documents.no-specification' | translate)
            }}
          </ion-text>
        </div>
      </div>
    }
  </div>
</ion-item>
