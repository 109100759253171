import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import {
  AppointmentDetails,
  ViewAppointmentsGroup,
} from 'projects/core/src/lib/models/appointments.model';

@Component({
  selector: 'lib-appointments-scroll-list',
  templateUrl: './appointments-scroll-list.component.html',
  styleUrls: ['./appointments-scroll-list.component.scss'],
})
export class AppointmentsScrollListComponent implements AfterViewInit {
  @Input() selectedDate: Date;
  @Input() selectedAppointment: AppointmentDetails;
  @Input() mappedAppointmentGroups: ViewAppointmentsGroup;
  @Input() isLoading: boolean;
  @Output() onSelectAppointment: EventEmitter<AppointmentDetails> =
    new EventEmitter<AppointmentDetails>();

  @ViewChild('scrollPoint') scrollPoint: ElementRef;

  readonly ICON = {
    pastAppointment: faArrowUp,
  };

  ngAfterViewInit(): void {
    this.scrollPoint.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }
}
