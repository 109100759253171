<lib-auto-layout horizontal="stretch" direction="vertical" class="fill-height">
  <ion-item *ngIf="dynamicEditForm && editFormItems?.length" class="list-header" lines="none">
    <div class="header-container">
      <ion-button (click)="showPatientDataEditModal()" color="light" slot="end">
        <span>{{ 'general.edit' | translate }}</span>
        <fa-icon slot="end" [icon]="icon.edit"></fa-icon>
      </ion-button>
    </div>
  </ion-item>

  <ion-list class="card-list" [grow]="1">
    <cdk-virtual-scroll-viewport
      class="scrollbar stretch-self"
      [itemSize]="rowHeightValue"
      [minBufferPx]="virtualScrollService.getBufferPx('min')"
      [maxBufferPx]="virtualScrollService.getBufferPx('max')">
      @for (item of list; track item.identifier.normalizedValue) {
        @if (isDisplayableFieldType(item)) {
          <ion-item class="item-line" lines="none">
            <ng-container>
              <p class="field-label">{{ item.name }}</p>
              <p slot="end" class="text no-hyphens">
                <span *ngIf="item.type === dataType.date">
                  {{
                    (item.value.originalValue | localeDate: item.formatOptions?.formattedString) ||
                      ('general.not-specified' | translate)
                  }}
                </span>
                <span *ngIf="item.type !== dataType.date">
                  {{ item.value.originalValue || ('general.not-specified' | translate) }}
                </span>
              </p>
            </ng-container>
          </ion-item>
        }
      }
    </cdk-virtual-scroll-viewport>
  </ion-list>
</lib-auto-layout>
