import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  faCalendar,
  faChevronRight,
  faCircleInfo,
  faClinicMedical,
  faClock,
  faCloudArrowDown,
  faPen,
  faPhone,
  faUserDoctor,
  faUserInjured,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import {
  AppointmentDetails,
  AppointmentType,
  AppointmentViewType,
} from 'projects/core/src/lib/models/appointments.model';
import {
  DynamicButton,
  DynamicButtonSetConfiguration,
} from 'projects/core/src/lib/models/dynamic-button.model';
import {
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
} from 'projects/core/src/lib/models/form.model';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';
import { AppointmentsService } from 'projects/core/src/lib/services/appointments.service';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { VideoCallService } from 'projects/core/src/lib/services/video-call.service';
import { catchError, firstValueFrom, of, take, throwError } from 'rxjs';

@Component({
  selector: 'lib-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss'],
})
export class AppointmentDetailsComponent implements OnInit {
  @Input() set selectedAppointment(appointment: AppointmentDetails) {
    this.appointment = appointment;
    this.videoCallUrl = this.videoCallService.getVideoCallUrl(this.isDoctor);
    this.resetButtonsOnAppointmentChange();
    this.retrieveAdditionalAppointmentDetails();
    this.generateIcsFileString();
  }
  @Input() isDoctor = false;

  @Output() redirectToMonthOverview = new EventEmitter<AppointmentViewType>();
  @Output() appointmentListUpdate = new EventEmitter<void>();

  appointmentType: typeof AppointmentType = AppointmentType;

  appointment: AppointmentDetails;
  videoCallUrl: string;
  updateButtons: DynamicButton[];
  treatmentId: string;
  isLoading: boolean;
  translations: Object;
  icsFileString: string;

  readonly icons = {
    chevronRight: faChevronRight,
    clinic: faClinicMedical,
    doctor: faUserDoctor,
    info: faCircleInfo,
    patient: faUserInjured,
    telephone: faPhone,
    video: faVideo,
    pen: faPen,
    calendar: faCalendar,
    clock: faClock,
    cloudArrowDown: faCloudArrowDown,
  };

  constructor(
    private videoCallService: VideoCallService,
    private appointmentService: AppointmentsService,
    private destroyRef: DestroyRef,
    private popupService: PopupService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private router: Router,
    public breakPoint: BreakpointService,
  ) {}

  ngOnInit(): void {
    this.loadTranslations();
  }

  public downloadIcsFile(): void {
    this.appointmentService.downloadIcsFile(this.icsFileString, this.appointment.title);
  }

  private async loadTranslations(): Promise<void> {
    this.translations = await firstValueFrom(this.translateService.get('shared'));
  }

  private resetButtonsOnAppointmentChange(): void {
    this.updateButtons = undefined;
  }

  retrieveAdditionalAppointmentDetails() {
    this.isLoading = true;

    const additionalAppointmentDetails = [
      this.retrieveAppointmentUpdateButtons(),
      this.retrieveTreatmentLink(),
    ];

    Promise.all(additionalAppointmentDetails).then(() => {
      this.isLoading = false;
    });
  }

  private retrieveAppointmentUpdateButtons(): Promise<void> {
    return new Promise((resolve) => {
      if (this.appointment.type !== this.appointmentType.REMOTE) {
        this.appointmentService
          .getAppointmentUpdateButtons(this.appointment.id)
          .pipe(takeUntilDestroyed(this.destroyRef), take(1))
          .subscribe((buttons: DynamicButton[]) => {
            this.updateButtons = buttons;
          })
          .add(resolve);
      } else {
        // TODO: Following line prevents fake video call to load infinite.
        resolve();
      }
    });
  }

  private retrieveTreatmentLink(): Promise<void> {
    return new Promise((resolve) => {
      this.appointmentService
        .getLinkedTreatmentId(this.appointment.id)
        .pipe(takeUntilDestroyed(this.destroyRef), take(1))
        .subscribe((treatmentId: string) => {
          this.treatmentId = treatmentId;
        })
        .add(resolve);
    });
  }

  public hasNoDetails(appointment: AppointmentDetails): boolean {
    const { clinicPhoneNumber, doctor, location, patient, type } = appointment;
    return (
      !clinicPhoneNumber && !doctor && !location && !patient && type === AppointmentType.ONSITE
    );
  }

  async openFormForAppointmentUpdateModal(invoker: Invoker) {
    await this.showLoadingAnimation();
    this.appointmentService
      .getAppointmentUpdateForm(invoker)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        take(1),
        catchError((error) => {
          console.error('AppointmentDetailsComponent->openFormForAppointmentUpdateModal()', error);
          this.loadingService
            .stop()
            .catch((nestedError) => throwError(() => new Error(nestedError)));
          return of(undefined);
        }),
      )
      .subscribe((form: DynamicForm) => {
        this.showAppointmentUpdateModal(form);
      });
  }

  private showAppointmentUpdateModal(form: DynamicForm): void {
    this.popupService.showAppointmentUpdateModal(this.getFormConfiguration(form)).then((result) => {
      if (result.role === OverlayEventRole.save) {
        this.appointmentListUpdate.next();
      }
    });
  }

  private getFormConfiguration(form: DynamicForm): DynamicFormConfiguration {
    return new DynamicFormConfiguration({
      type: DynamicFormType.DIRECT_SAVE,
      dynamicForm: form,
    });
  }

  private async showLoadingAnimation(): Promise<void> {
    const translatedMessage: string = this.translations['forms']['opening-form'];
    await this.loadingService.load(translatedMessage);
  }

  navigateToTreatmentDetails(): void {
    this.router.navigate(['/portal/treatments', `${this.treatmentId}`]);
  }

  get actionSheetConfig(): DynamicButtonSetConfiguration {
    return {
      actionButtons: this.updateButtons,
      triggerButton: {
        label: this.translations['appointment-details']['appointment-options'],
        color: 'tertiary',
        disabled: false,
        dataApiId: 'appointment-update',
      },
    };
  }

  generateIcsFileString(): void {
    try {
      this.icsFileString = this.appointmentService.generateIcsFile(
        this.appointment,
        this.videoCallUrl,
      );
    } catch (error) {
      console.error(error);
    }
  }
}
