import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { DomSanitizerService } from 'projects/core/src/lib/services/dom-sanitizer.service';

@Pipe({
  name: 'safeResourceUrl',
})
export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private domSanitizerService: DomSanitizerService) {}

  transform(url: string): SafeUrl {
    return this.domSanitizerService.sanitizeResourceUrl(url);
  }
}
