<ng-container *ngIf="isActiveRoute('/portal/menu')">
  <lib-referrer-header *zpAndKpOnly></lib-referrer-header>
</ng-container>

<lib-header [headerConfiguration]="header"></lib-header>

<ion-tabs *ngIf="menu">
  <ion-tab-bar slot="bottom">
    <ng-container *ngFor="let menuItem of menu">
      <ion-tab-button
        [tab]="menuItem.idSuffix"
        [routerLink]="menuItem.url"
        routerDirection="root"
        routerLinkActive="active-link"
        *ngIf="menuItem.visibility"
        layout="icon-top">
        <ion-item detail="false" class="menu-item" lines="none">
          <div class="menu-item-container">
            <fa-icon size="lg" slot="start" [icon]="menuItem.icon"></fa-icon>
            <p>{{ menuItem.title }}</p>
          </div>
        </ion-item>
      </ion-tab-button>
    </ng-container>
  </ion-tab-bar>
</ion-tabs>
