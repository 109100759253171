<lib-auto-layout direction="vertical" [gap]="[10]" [padding]="[0, 20]" horizontal="stretch">
  <lib-auto-layout [padding]="[0, 20]">
    <h3 class="ion-no-margin">{{ 'shared.data-organizer.shown' | translate }}</h3>
  </lib-auto-layout>

  <lib-auto-layout direction="vertical" class="shape-list reorder" [grow]="1" horizontal="stretch">
    <ion-reorder-group
      [disabled]="false"
      (ionItemReorder)="
        handleColumnReorder($any($event), visibleHeaderItems, visibilityType.visible)
      ">
      @for (visibleItem of visibleHeaderItems; track visibleItem.identifier) {
        <ion-item lines="none" class="list-item">
          <ion-label> {{ visibleItem.value }} </ion-label>
          <ion-reorder slot="end"></ion-reorder>
        </ion-item>
      }
    </ion-reorder-group>
  </lib-auto-layout>

  @if (hasHiddenHeaderItems) {
    <lib-auto-layout [padding]="[10, 0]" [grow]="1" horizontal="stretch">
      <ion-button
        color="primary"
        expand="block"
        class="p3 ion-no-margin"
        [grow]="1"
        (click)="activateEditMode()">
        <span>{{ 'shared.data-organizer.adjust' | translate }}</span>
        <fa-icon [icon]="icons.pen" slot="end"></fa-icon>
      </ion-button>
    </lib-auto-layout>
  }

  @if (hiddenHeaderItems.length) {
    <lib-auto-layout [padding]="[0, 20]">
      <h3 class="ion-no-margin">{{ 'shared.data-organizer.hidden' | translate }}</h3>
    </lib-auto-layout>

    <lib-auto-layout
      direction="vertical"
      class="shape-list reorder"
      [grow]="1"
      horizontal="stretch">
      <ion-reorder-group
        [disabled]="false"
        (ionItemReorder)="
          handleColumnReorder($any($event), hiddenHeaderItems, visibilityType.hidden)
        ">
        @for (hiddenItem of hiddenHeaderItems; track hiddenItem.identifier) {
          <ion-item lines="none" class="list-item">
            <ion-label> {{ hiddenItem.value }} </ion-label>
            <ion-reorder slot="end"></ion-reorder>
          </ion-item>
        }
      </ion-reorder-group>
    </lib-auto-layout>
  }
</lib-auto-layout>
