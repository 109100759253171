import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-testimonial-card',
  templateUrl: './testimonial-card.component.html',
  styleUrls: ['./testimonial-card.component.scss'],
})
export class TestimonialCardComponent {
  @Input() name: string;
  @Input() role: string;
  @Input() quote: string;
  @Input() image: string;
}
