import { PrivacyPolicy } from 'projects/core/src/lib/models/privacy-policy.model';
import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { PrivacyPolicyService } from 'projects/core/src/lib/services/privacy-policy.service';

@Component({
  selector: 'lib-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
@UntilDestroy()
export class PrivacyPolicyComponent implements OnInit {
  @Input()
  containerize: boolean;
  isLoading: boolean;

  privacyPolicy: PrivacyPolicy;

  constructor(private privacyPolicyService: PrivacyPolicyService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.privacyPolicyService
      .getPrivacyPolicy()
      .pipe(untilDestroyed(this), take(1))
      .subscribe((privacyPolicy: PrivacyPolicy) => (this.privacyPolicy = privacyPolicy))
      .add(() => {
        this.isLoading = false;
      });
  }

  getMaxRowAmount(): number {
    const offsetHeader = 191;
    const rowHeight = 42;
    return Math.ceil((window.innerHeight - offsetHeader) / rowHeight);
  }

  generateSkeletonItems(): Array<number> {
    return Array(this.getMaxRowAmount()).fill(0);
  }
}
