import { Component, Input } from '@angular/core';
import { Introduction } from 'projects/shared/src/lib/components/onboarding-swiper/swiper.model';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { DataType, DynamicDataField } from 'projects/core/src/lib/models/form.model';
import { ProfileFieldKey } from 'projects/core/src/lib/models/profile.model';
import { AttributeNameIdentifier } from 'projects/core/src/lib/models/shared.model';

@Component({
  selector: 'lib-swiper-slide-content',
  templateUrl: './swiper-slide-content.component.html',
  styleUrls: ['./swiper-slide-content.component.scss'],
})
export class SwiperSlideContentComponent {
  @Input() slide: Introduction;

  dataType: typeof DataType = DataType;

  readonly icons = {
    language: faGlobe,
  };

  isLanguageSelectionField(field: DynamicDataField): boolean {
    const languageFieldIdentifier = new AttributeNameIdentifier(ProfileFieldKey.language);
    return field.identifier.isEqualTo(languageFieldIdentifier);
  }
}
