<ion-header>
  <ion-grid class="content" [fixed]="true">
    <ion-row class="ion-align-items-center">
      <ion-col size="6" class="logo" [class.no-logo-padding-left]="noLogoPaddingLeft">
        <lib-logo></lib-logo>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        @if (showBackButton && !useWindowHistoryBack) {
          <ion-back-button
            color="primary"
            defaultHref="/"
            [text]="'general.back' | translate"></ion-back-button>
        } @else if (!showBackButton && loginPath && !useWindowHistoryBack && showLoginButton) {
          <ion-button
            class="p3"
            size="medium"
            color="primary"
            [routerLink]="loginPath"
            routerDirection="root">
            {{ 'general.login' | translate }}
          </ion-button>
        } @else if (showBackButton && useWindowHistoryBack && historyHasEntries) {
          <ion-button class="p3" size="medium" color="primary" (click)="navigateBack()">
            <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
            {{ 'general.back' | translate }}
          </ion-button>
        }
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-header>
