import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { InactivityService } from 'projects/shared/src/lib/services/inactivity.service';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { firstValueFrom } from 'rxjs';

export const SessionTimeoutActiveGuard: CanActivateFn = async (): Promise<boolean> => {
  const authService: AuthService = inject(AuthService);
  const inactivityService: InactivityService = inject(InactivityService);
  const router: Router = inject(Router);

  if (await inactivityService.isActive()) {
    if (await firstValueFrom(authService.isLoggedIn())) {
      await authService.logout();
    }
    return true;
  } else {
    await router.navigate(['/'], { replaceUrl: true });
    return false;
  }
};
