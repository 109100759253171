import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  EventMessage,
  EventMessageType,
  TableHeaderItem,
  TableList,
  TableListItem,
  TableRowState,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { TableDataService } from 'projects/core/src/lib/services/table-data.service';

@Component({
  selector: 'lib-generic-condensed-row-mobile',
  templateUrl: './generic-condensed-row-mobile.component.html',
  styleUrls: ['./generic-condensed-row-mobile.component.scss', '../shared-generic-table-row.scss'],
})
export class GenericCondensedRowMobileComponent {
  @Output() eventMessage: EventEmitter<EventMessage> = new EventEmitter();

  @Input() table: TableList;
  @Input() row: TableListItem;
  @Input() state: TableRowState;

  readonly icons = {
    square: faSquare,
    squareCheck: faSquareCheck,
    check: faCheck,
  };

  get visibleHeaderItems(): TableHeaderItem[] {
    return TableDataService.getVisibleTableItemList<TableHeaderItem>(
      this.table.sortedHeader,
      this.state.columnNumber.current,
    );
  }

  get visibleMobileRowValues(): string[] {
    return TableDataService.getVisibleTableItemList<string>(
      this.row.columns,
      this.state.columnNumber.current,
    );
  }

  emitClickEventMessage(): void {
    this.eventMessage.emit({
      type: EventMessageType.CLICK,
      data: this.row.id,
      behaviorInvokers: this.row.behaviorInvokers,
    });
  }

  emitSelectEventMessageAndToggleRowSelection(): void {
    this.row.selected = !this.row.selected;
    this.eventMessage.emit({
      type: EventMessageType.SELECT,
      data: this.row.id,
      behaviorInvokers: this.row.behaviorInvokers,
    });
  }
}
