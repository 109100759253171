<lib-auto-layout
  class="list-item"
  [grow]="1"
  [padding]="[10, 20]"
  horizontal="space-between"
  vertical="center"
  [gap]="[10]"
  (click)="itemClicked.emit()">
  <ng-content></ng-content>
</lib-auto-layout>
