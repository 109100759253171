<lib-auto-layout direction="vertical" horizontal="stretch" [grow]="1" class="widget-card">
  <lib-auto-layout vertical="center" [gap]="[10]" [padding]="[15, 20, 10]">
    <fa-icon [icon]="icons.notesMedical"></fa-icon>
    <h2 data-api-id="medical-certificates-list" [grow]="1" class="no-margin">
      {{ 'medical-certificates-widget.latest-medical-certificates' | translate }}
    </h2>
  </lib-auto-layout>
  @if (isLoading) {
    <lib-documents-skeleton [lines]="skeletonItemsNumber" [hasExtendedView]="desktopView">
    </lib-documents-skeleton>
  } @else if (medicalCertificates.length !== 0) {
    <lib-auto-layout
      direction="vertical"
      horizontal="stretch"
      [grow]="1"
      [padding]="[1, 0, 0]"
      id="medical-certificates-list">
      @for (medicalCertificate of medicalCertificates; track medicalCertificate) {
        <lib-medical-certificate-row
          [medicalCertificate]="medicalCertificate"
          [hasExtendedView]="desktopView"
          [hasNavigationIcon]="false"
          (listReload)="initializeMedicalCertificatesList()">
        </lib-medical-certificate-row>
      }
    </lib-auto-layout>
  } @else {
    <lib-auto-layout direction="vertical" [padding]="[1, 0]" [grow]="1" class="no-documents">
      <lib-notification-box-component
        [grow]="1"
        [message]="'medical-certificates-widget.no-medical-certificates-found' | translate">
      </lib-notification-box-component>
    </lib-auto-layout>
  }
  @if (medicalCertificates.length !== 0) {
    <lib-auto-layout horizontal="center" [padding]="[15]" id="button-section">
      <ion-button
        class="documents-page-link p3"
        color="medium"
        fill="clear"
        expand="block"
        routerLink="/portal/medical-certificates">
        {{ 'medical-certificates-widget.show-all-medical-certificates' | translate }}
        <fa-icon slot="end" [icon]="icons.arrow"></fa-icon>
      </ion-button>
    </lib-auto-layout>
  }
</lib-auto-layout>
