import { Component, Input } from '@angular/core';
import { TableHeaderItem, TableList } from 'projects/core/src/lib/models/dynamic-table.model';
import { SortDirection } from 'projects/core/src/lib/models/shared.model';

@Component({
  selector: 'lib-vertical-sorting-section',
  templateUrl: './vertical-sorting-section.component.html',
  styleUrls: ['./vertical-sorting-section.component.scss', '../data-organizer-toolbox.scss'],
})
export class VerticalSortingSectionComponent {
  @Input() table: TableList;

  readonly directionType: typeof SortDirection = SortDirection;

  setDirectionParameterForSorting(currentDirection: SortDirection): void {
    if (this.table.sortParams.direction !== currentDirection) {
      this.table.sortParams.direction = currentDirection;
      this.table.sortColumnsVertically();
    }
  }

  setFieldIndexParameterForSorting(item: TableHeaderItem, index: number): void {
    if (this.table.sortParams.identifier !== item.identifier.normalizedValue) {
      this.table.updateSortParams(index);
      this.table.sortColumnsVertically();
    }
  }

  getClassNameOfDirectionButton(direction: SortDirection): string {
    return direction === this.table.sortParams.direction ? 'active' : null;
  }

  getClassNameOfHeaderValueButton(item: TableHeaderItem): string {
    return this.table.sortParams.identifier === item.identifier.normalizedValue ? 'active' : null;
  }
}
