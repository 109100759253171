@if (!isLoading) {
  <ng-container>
    <ion-header>
      <ion-toolbar id="form-title-toolbar">
        <h5 class="ion-no-margin form-title">{{ form.title }}</h5>
        <div class="toolbar-buttons" slot="end">
          <ion-button color="tertiary" (click)="cancel()">
            {{ formTranslations['cancel'] }}
            <fa-icon icon="xmark" slot="end"></fa-icon>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content [scrollY]="false" (window:resize)="onWindowResize()">
      <form #f="ngForm">
        @if (isNavigationLayoutRequired) {
          <div id="form-container" class="flex-form-container top" [class.preview]="preview">
            @if (hasMultipleGroupsOnDesktopOrInMobileGroupOverview) {
              <div id="side-menu" class="flex-side-menu">
                @for (item of form.body; track $index) {
                  @if (isDisplayableGroupType(item) && !isFieldGroupEmpty($index)) {
                    <div class="menu-button-wrapper">
                      <ion-button expand="full" (click)="activateFormGroup($index)">
                        <lib-menu-button-content
                          [form]="form"
                          [ngForm]="f"
                          [item]="item"
                          [activeGroupNumber]="activeGroupNumber"
                          [index]="$index"
                          [title]="item.name"></lib-menu-button-content>
                      </ion-button>
                    </div>
                  }
                }
              </div>
            }

            <div id="form-content" [ngStyle]="getFormVisibilityStyles()" class="flex-form-content">
              @for (group of form.body; track $index) {
                <div [ngStyle]="isSingleFormInDesktopView() ? { 'flex-basis': '80%' } : {}">
                  @if (isDisplayableGroupType(group) || form.multiChosen) {
                    <div
                      id="form-content-container"
                      [ngStyle]="getFormStyles(group.fieldGroup, $index)">
                      @for (item of group.fieldGroup; track item.identifier) {
                        @if (isDisplayableFieldType(item)) {
                          <div
                            class="field-container"
                            [ngStyle]="getFormFieldStyles(group.fieldGroup, item)">
                            <lib-field-container
                              [group]="group"
                              [item]="item"
                              [preview]="preview"></lib-field-container>
                          </div>
                        }
                      }
                      @if ($first && form.multiChosen) {
                        <lib-multi-chosen
                          [multiChosenData]="form.multiChosen"
                          style="order: 1000"></lib-multi-chosen>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }

        @if (preview) {
          <div id="form-header">
            <ion-item-divider color="primary">
              <h5>{{ formTranslations['preview'] }}</h5>
            </ion-item-divider>
          </div>
        }

        @if (isMobilePreviewLayoutRequired) {
          <div class="preview-form">
            <lib-preview-form [form]="form"></lib-preview-form>
          </div>
        }

        @if (configuration.type === dynamicFormType.VIEW) {
          @if (form.actionButtons?.length && actionButtonConfiguration) {
            <div class="button-section">
              <lib-action-buttons-container
                [config]="actionButtonConfiguration"
                (actionTrigger)="dismissModalWithActionEvent($event)">
              </lib-action-buttons-container>
            </div>
          }
        } @else {
          <div class="button-section">
            @if (f.invalid) {
              <div class="warn label-input button-notification">
                * {{ 'shared.forms.required-fields-prompt' | translate }}
              </div>
            }
            <lib-form-navigation
              [form]="form"
              [activeGroupNumber]="activeGroupNumber"
              [preview]="preview"
              [isMobileView]="isMobileView"
              [isFormGroupActive]="isFormGroupActive"
              [allFormGroupsShown]="allFormGroupsShown"
              [disableSubmitButton]="shouldDisableVerifyButton(f)"
              [showMobileNavigation]="showNavigationForMultipleGroupsOnMobile"
              (navigateToPreviewMode)="togglePreviewMode()"
              (navigateToEditMode)="resetActiveGroup()"
              (navigateBack)="openPreviousGroup()"
              (navigateForward)="openNextGroup()">
              @switch (configuration.type) {
                @case (dynamicFormType.TASK) {
                  @if (!preview) {
                    <ion-button
                      class="p3 save-button fit-content"
                      color="tertiary"
                      [expand]="buttonExpandValue"
                      data-api-id="save-form"
                      (click)="handleFormSave()"
                      [disabled]="isPatternInvalid(f)">
                      {{
                        ('shared.forms.save' | translate) +
                          ' & ' +
                          ('shared.forms.close' | translate)
                      }}
                    </ion-button>
                  }
                  <div class="gap ion-hide-md-down"></div>
                  @if (preview) {
                    <ion-button
                      color="cancel-button"
                      class="p3 cancel-button ion-hide-md-down"
                      [expand]="buttonExpandValue"
                      (click)="togglePreviewMode()">
                      <fa-icon slot="start" icon="chevron-left"></fa-icon>
                      {{ 'shared.forms.change' | translate }}
                    </ion-button>
                    <div class="alignment-block">
                      <lib-action-buttons-container
                        class="stretch-self"
                        [config]="getRequiredTaskActionSheetConfig(f)"
                        (actionTrigger)="handleFormSubmission($event)">
                      </lib-action-buttons-container>
                    </div>
                  } @else {
                    @if (hasMultipleGroupsOnDesktopOrInMobileGroupOverview) {
                      <ion-button
                        [disabled]="shouldDisableVerifyButton(f)"
                        (click)="togglePreviewMode()"
                        [expand]="buttonExpandValue"
                        color="primary"
                        class="p3 preview-button">
                        <fa-icon slot="end" icon="chevron-right"></fa-icon>
                        {{ 'shared.forms.verify' | translate }}
                      </ion-button>
                    }
                    @if (form.body.length === 1) {
                      <lib-action-buttons-container
                        [config]="getRequiredTaskActionSheetConfig(f)"
                        (actionTrigger)="handleFormSubmission($event)">
                      </lib-action-buttons-container>
                    }
                  }
                }
                @case (dynamicFormType.TASK_WITH_ACTIONS) {
                  <div class="gap ion-hide-md-down"></div>
                  <div class="alignment-block">
                    <lib-action-buttons-container
                      class="stretch-self"
                      [config]="getRequiredTaskActionSheetConfig(f)"
                      (actionTrigger)="handleFormSubmission($event)">
                    </lib-action-buttons-container>
                  </div>
                }
                @case (dynamicFormType.TASK_UPLOAD) {
                  <div class="gap ion-hide-md-down"></div>
                  <div class="alignment-block">
                    <lib-action-buttons-container
                      [config]="getRequiredTaskActionSheetConfig(f)"
                      (actionTrigger)="handleFormUploadSubmission($event)">
                    </lib-action-buttons-container>
                  </div>
                }
                @case (dynamicFormType.USER_DEACTIVATION) {
                  <div class="gap ion-hide-md-down"></div>
                  <ion-button
                    [disabled]="shouldDisableSubmitButton(f)"
                    color="danger"
                    class="p3 save-button"
                    [expand]="buttonExpandValue"
                    data-api-id="save-form"
                    (click)="handleFormSave()">
                    {{ 'shared.forms.deactivate' | translate }}
                  </ion-button>
                }
                @case (dynamicFormType.DOCUMENT_UPLOAD) {
                  <div class="gap ion-hide-md-down"></div>
                  <ion-button
                    color="primary"
                    class="p3 save-button"
                    [expand]="buttonExpandValue"
                    (click)="handleFormUploadSave()"
                    [disabled]="shouldDisableSubmitButton(f)">
                    {{ 'shared.forms.save' | translate }}
                  </ion-button>
                }
                @case (dynamicFormType.ACTIVITY_CHANGE) {
                  @if (actionButtonConfiguration) {
                    <div class="gap ion-hide-md-down"></div>
                    <lib-action-buttons-container
                      [config]="actionButtonConfiguration"
                      (actionTrigger)="dismissModalWithActionEvent($event)">
                    </lib-action-buttons-container>
                  }
                }
                @default {
                  <div class="gap ion-hide-md-down"></div>
                  <ion-button
                    [disabled]="shouldDisableSubmitButton(f)"
                    color="primary"
                    class="p3 save-button"
                    [expand]="buttonExpandValue"
                    data-api-id="save-form"
                    (click)="handleFormSave()">
                    {{ 'shared.forms.save' | translate }}
                  </ion-button>
                }
              }
            </lib-form-navigation>
          </div>
        }
      </form>
    </ion-content>
  </ng-container>
} @else {
  <ng-container>
    <ion-content [scrollY]="false">
      <div class="loading-skeleton">
        <ion-item-divider color="light">
          <ion-skeleton-text animated="true" class="h2 rounded-small"></ion-skeleton-text>
        </ion-item-divider>
        <div class="flex-form-container stretch-self">
          <div class="flex-side-menu">
            <ion-item *ngFor="let i of generateSkeletonMenuItems()" lines="none">
              <ion-skeleton-text animated="true" class="rounded-small"></ion-skeleton-text>
            </ion-item>
          </div>
          <div class="flex-form-content flex-skeleton-content ion-hide-md-down">
            <div class="field-skeleton stretch-container">
              <ng-container *ngFor="let i of generateSkeletonFields()">
                <ion-skeleton-text
                  animated="true"
                  class="h3 rounded-small label-skeleton"></ion-skeleton-text>
                <ion-skeleton-text animated="true" class="h1 rounded-small"></ion-skeleton-text>
              </ng-container>
            </div>
            <div class="ion-hide-xl-down">
              <div class="field-skeleton" *ngFor="let i of generateSkeletonFields()">
                <ion-skeleton-text
                  animated="true"
                  class="h3 rounded-small label-skeleton"></ion-skeleton-text>
                <ion-skeleton-text animated="true" class="h1 rounded-small"></ion-skeleton-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ng-container>
}
