<div [class.future]="isFutureAppointment(date)" [class.today]="isToday(date)" class="date-divider">
  <div [class.active]="isToday(date)" class="today-indicator"></div>
  <h3 class="ion-no-margin p4">
    <strong>
      {{ isToday(date) ? ('shared.appointment-overview.today' | translate) + ', ' : '' }}
      {{ date | localeDate: 'fullDate' }}
    </strong>
  </h3>
  <fa-icon size="sm" [icon]="icons.day" *ngIf="isToday(date)"></fa-icon>
</div>
