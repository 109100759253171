import { Component, Input } from '@angular/core';
import {
  EventMessage,
  EventMessageType,
  SkeletonHeaderOffset,
  SkeletonRowSet,
  TableConfiguration,
  TableList,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { PatientDataField } from 'projects/core/src/lib/models/patient.model';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
@Component({
  selector: 'lib-patient-data-table',
  templateUrl: './patient-data-table.component.html',
})
export class PatientDataTableComponent {
  @Input() table: TableList;
  @Input() viewOnly = false;

  readonly headerOffset: SkeletonHeaderOffset = { mobile: 400, desktop: 410 };
  readonly expandedViewThreshold: number = 370;

  isRowDataLoading = false;
  skeletonRowSet: SkeletonRowSet;
  searchParams: SearchParams = new SearchParams();

  constructor(private popupService: PopupService) {
    this.searchParams.fields = ['columns'];
    this.skeletonRowSet = new SkeletonRowSet(this.headerOffset);
  }

  get data(): TableConfiguration {
    return {
      table: this.table,
      searchParams: this.searchParams,
      skeletonRowSet: this.skeletonRowSet,
      expandedViewThreshold: this.expandedViewThreshold,
      columnWidth: 200,
    };
  }

  resolveEventMessage({ type, data }: EventMessage): void {
    switch (type) {
      case EventMessageType.CLICK:
        this.viewTableRowDetails(data);
        break;
      case EventMessageType.RESORTING:
        this.setLoadingValue(data);
        break;
    }
  }

  setLoadingValue(value: boolean): void {
    this.isRowDataLoading = value;
  }

  private viewTableRowDetails(i: number): Promise<void> {
    return this.popupService.showPatientData(this.getMappedRowDetails(i));
  }

  private getMappedRowDetails(i: number): PatientDataField[] {
    const list: PatientDataField[] = [];
    this.table.initialRows[i].columns.forEach((columnValue: string, index: number) => {
      const fieldItem: PatientDataField = {
        name: this.table.initialHeader[index].value,
        value: columnValue,
        type: this.table.initialHeader[index].type,
      };
      list.push(fieldItem);
    });
    return list;
  }
}
