import { Component, Input, OnInit } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import {
  EventMessage,
  EventMessageType,
  SkeletonHeaderOffset,
  SkeletonRowSet,
  TableConfiguration,
  TableList,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { Invoker, InvokerBody } from 'projects/core/src/lib/models/invoker-body.model';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';

@Component({
  selector: 'lib-dynamic-table-select',
  templateUrl: './dynamic-table-select.component.html',
  host: { class: 'medium-modal' },
})
export class DynamicTableSelectComponent implements OnInit {
  @Input({ required: true }) dynamicTableConfiguration: DynamicTableConfiguration;

  readonly headerOffset: SkeletonHeaderOffset = { mobile: 210, desktop: 520 };
  readonly expandedViewThreshold: number = 600;
  readonly ICONS = { CLOSE: faXmark };

  skeletonRowSet: SkeletonRowSet;
  searchParams: SearchParams = new SearchParams();
  isRowDataLoading: boolean;

  constructor(private modalController: ModalController) {
    this.skeletonRowSet = new SkeletonRowSet(this.headerOffset);
  }

  ngOnInit(): void {
    this.initializeSearchParams();
  }

  private initializeSearchParams() {
    if (this.dynamicTableConfiguration.tableList.initialRows.length > 0) {
      this.searchParams.fields = Object.keys(
        this.dynamicTableConfiguration.tableList.initialRows[0],
      );
    } else {
      this.searchParams.fields = [''];
    }
  }

  get data(): TableConfiguration {
    return {
      table: this.dynamicTableConfiguration.tableList,
      searchParams: this.searchParams,
      skeletonRowSet: this.skeletonRowSet,
      expandedViewThreshold: this.expandedViewThreshold,
      columnWidth: 200,
    };
  }

  cancel(): void {
    this.modalController.dismiss(null, 'cancel');
  }

  resolveEventMessage(eventMessage: EventMessage): void {
    switch (eventMessage.type) {
      case EventMessageType.CLICK:
        this.viewListItem(eventMessage.data, eventMessage.behaviorInvokers);
        break;
      case EventMessageType.RESORTING:
        this.setRowDataLoading(eventMessage.data);
        break;
    }
  }

  private setRowDataLoading(value: boolean): void {
    this.isRowDataLoading = value;
  }

  viewListItem(tableListItemId: string, behaviorInvokers: InvokerBody[]): void {
    this.modalController.dismiss(
      { tableListItemId, behaviorInvokers },
      OverlayEventRole.activityChange,
    );
  }

  dismissModalWithActionEvent(invoker: Invoker): void {
    this.modalController.dismiss({ invoker }, OverlayEventRole.actionButtonEvent);
  }

  get title(): string {
    return this.dynamicTableConfiguration.tableList.title;
  }

  get toolBarButtons(): Invoker[] {
    return this.dynamicTableConfiguration.actionButtons;
  }
}

export interface DynamicTableConfiguration {
  tableList: TableList;
  actionButtons?: Invoker[];
}
