import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, Optional, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  TableDataType,
  TableHeaderItem,
  TableList,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { AttributeFieldType } from 'projects/core/src/lib/models/sdapi-form-object.model';
import { LocaleDatePipe } from './locale-date.pipe';

@Pipe({
  name: 'tableValueByType',
  pure: false,
})
export class TableValueByTypePipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    private localeDatePipe: LocaleDatePipe,
    private translateService: TranslateService,
    @Optional() private cdRef: ChangeDetectorRef,
  ) {
    this.asyncPipe = new AsyncPipe(this.cdRef);
  }

  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }

  transform(rowValue: string, index: number, table: TableList): string {
    if (!rowValue) {return this.asyncPipe.transform(this.translateService.get('general.unknown'));}
    const headerItem: TableHeaderItem = table.sortedHeader
      ? table.sortedHeader[index]
      : table.initialHeader[index];
    const hasDateType: boolean = this.hasDateType(headerItem);
    return hasDateType ? this.transformDateValue(rowValue, headerItem) : rowValue;
  }

  hasDateType(headerItem: TableHeaderItem): boolean {
    return headerItem?.type === TableDataType.date || headerItem?.type === AttributeFieldType.date;
  }

  transformDateValue(rowValue: string, headerItem: TableHeaderItem): string {
    return this.localeDatePipe.transform(rowValue, headerItem.format?.formattedString);
  }
}
