import { FormIdentifier } from './form.model';
import { FormAttributeCollection, InvokerBody } from './invoker-body.model';
import { ObjectType } from './sdapi-object.model';

export interface TieFormObject {
  t?: ObjectType;
  target?: string;
  txnId?: string;
  windowName?: string;
  attributeProfileId?: number;
  autoResize?: boolean;
  hasKeepAliveThread?: boolean;
  dialogPrefs?: DialogPreferences;
  attributeValues?: AttributeValueCollection;
  showTypes?: ShowTypes;
  extValTmp?: string[];
  objclassID?: string[];
  objclassName?: string[];
  [key: string]: any;
}

export type AttributeValueCollection = Record<string, AttributeValue>;

export interface DialogPreferences {
  toolbar: DialogBooleanString;
  menubar: DialogBooleanString;
  scrollbars: DialogBooleanString;
  location: DialogBooleanString;
  status: DialogBooleanString;
  resizable: DialogBooleanString;
  width: string;
  height: string;
  [key: string]: any;
}

export type ShowTypes = {
  [key in ShowTypeGroup]: ShowType;
};

export interface ShowType {
  t: FormItemType;
  name: string;
  maxHSize: number;
  maxVSize: number;
  items: FormItem[];
  tabs?: TabItem[];
}

export interface FormItem {
  t: FormItemType;

  alignment: Alignment;
  attributeName: string;
  attributeProfileId: number;
  attrType: DataFieldValueType;
  colSpan: number;
  displayName: string;
  displayNameWidth: number;
  hPos: number;
  info: string;
  invokerRequest: InvokerBody;
  popupMandatory: FormItemBooleanString;
  minprefixlength?: string;
  maxoptions?: string;
  popupObjId: number;
  rowSpan: number;
  seq: number;
  showType: ShowTypeGroup;
  systemAttributeName: FormIdentifier;
  tagPrefs: TagPreferences;
  vPos: number;

  autoPopulatable?: boolean;
  checker?: CheckerType;
  columnTypes?: FormItem[];
  conditionalAttributes?: Record<string, string[]>;
  defaultItem?: DefaultItem;
  displayRowNumbers?: boolean;
  format?: FormatOptionIdentifier;
  hidden?: boolean;
  mandatory?: MandatoryAttributeType | boolean;
  onClick?: InvokerBody;
  options?: AttributeValue[];
  readonly?: boolean;
  reference?: string;
  showTypeGrouping?: ShowType;
  tag?: string;
}

export interface DefaultItem {
  attrValues: AttributeValue[];
}

export enum CheckerType {
  contains = 'checkcontains',
  currency = 'iscurrency',
  date = 'checkdate',
  directoryName = 'checkdirname',
  length = 'checklength',
  // eslint-disable-next-line id-blacklist
  number = 'isnumber',
  objectClass = 'checkobjclass',
  objectName = 'checkobjname',
  passwords = 'checkpasswords',
  user = 'checkuser',
  checkmail = 'checkmail',
}

export class TagPreferences {
  min?: string;
  max?: string;
  rows?: string;
  style?: string;

  primary?: string;
  secondary?: string;
  name?: string;
  autoselect?: 'YES' | 'NO' | string;

  minrel?: string;
  maxrel?: string;

  show_type?: string;

  formula?: string;

  minprefixlength?: string;
  maxoptions?: string;

  [key: string]: any;
}

export interface AttributeValue {
  t: AttributeFieldType;

  internalValue?: string | null;
  displayValue?: string | null;
  language?: string;
  items?: AttributeValueItem[];
  attributeValues?: FormAttributeCollection;

  [key: string]: any;
}

export interface AttributeValueItem {
  attrValues: AttributeValue[];
  [key: string]: any;
}

export interface TabItem {
  t: string;
  title: string;
  showTypes: ShowType[];
}

export interface AttributeItem {
  t: AttributeFieldType;
  internalValue?: string;
  displayValue?: string;
}

export interface RequestParameters {
  [key: string]: AttributeItem;
}

export enum ShowTypeGroup {
  body = 'BODY',
  button = 'BUTTON',
  button0 = 'BUTTON0',
  buttonProject = 'BUTTON_PROJECT',
  chosen = 'CHOSEN',
  header = 'HEADER',
  mBody = 'MBODY',
  select = 'CHOOSE',
  setRoles = 'SETROLES',
  tab = 'TAB',
}

export enum DialogBooleanString {
  true = 'yes',
  false = 'no',
}

export enum FormItemBooleanString {
  true = 'YES',
  false = 'NO',
}

/** @deprecated  */
export enum MandatoryAttributeType {
  yes = 'YES',
  no = 'NO',
  fix = 'FIX',
}

export enum Alignment {
  left = 'LEFT',
}

export enum AttributeFieldType {
  date = 'DateDO',
  grouping = 'GroupingDO',
  text = 'StringDO',
  grid = 'GridDO',
}

export enum DataFieldValueType {
  date = 'DATE',
  // eslint-disable-next-line id-blacklist
  number = 'NUMBER',
  text = 'TEXT',
}

export enum FormItemType {
  buttonStep = 'ButtonStep',
  buttonStepAndClose = 'ButtonStepAndClose',
  buttonWorkflow = 'ButtonWorkflow',
  checkbox = 'Checkbox',
  date = 'Date',
  file = 'File',
  formula = 'Formula',
  grid = 'Grid',
  grouping = 'Grouping',
  // eslint-disable-next-line id-blacklist
  number = 'Number',
  popup = 'PopupInvoker',
  radio = 'Radio',
  richtext = 'Richtext',
  select = 'Select',
  showType = 'showType',
  text = 'Text',
  textArea = 'Textarea',
  textMl = 'TextMl',
  title = 'Title',
  autocomplete = 'Autocomplete',
  /** @deprecated  */
  fixtext = 'Fixtext',
  /** @deprecated  */
  hidden = 'Hidden',
}

export enum FormatOptionIdentifier {
  d = 'd',
  D = 'D',
  f = 'f',
  F = 'F',
  g = 'g',
  G = 'G',
  m = 'm',
  M = 'M',
  s = 's',
  t = 't',
  T = 'T',
  y = 'y',
  Y = 'Y',
  color = 'color',
  colorWithoutText = 'color-without-text',
  internal = 'internal',
}

export const FormatOptionsMap: Map<FormatOptionIdentifier, string> = new Map([
  [FormatOptionIdentifier.d, 'dd.MM.yyyy'],
  [FormatOptionIdentifier.D, 'dddd'],
  [FormatOptionIdentifier.f, 'dddd'],
  [FormatOptionIdentifier.F, 'dddd'],
  [FormatOptionIdentifier.g, 'dd.MM.yyyy HH:mm'],
  [FormatOptionIdentifier.G, 'dd.MM.yyyy HH:mm:ss'],
  [FormatOptionIdentifier.m, 'd. MMMM'],
  [FormatOptionIdentifier.M, 'd. MMMM'],
  [FormatOptionIdentifier.s, 'yyyy-MM-ddTHH:mm:ss'],
  [FormatOptionIdentifier.t, 'HH:mm'],
  [FormatOptionIdentifier.T, 'HH:mm:ss'],
  [FormatOptionIdentifier.y, 'MMMM yyyy'],
  [FormatOptionIdentifier.Y, 'MMMM yyyy'],
]);
