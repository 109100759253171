import { Component, Input } from '@angular/core';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { AutoIdentService } from 'projects/core/src/lib/services/auto-ident.service';
import { ProfileService } from 'projects/core/src/lib/services/profile.service';
import { MenuItem } from '../../models/menu.model';

@Component({
  selector: 'lib-tab-bar-portal-menu',
  templateUrl: './tab-bar-portal-menu.component.html',
  styleUrls: ['./tab-bar-portal-menu.component.scss'],
})
export class TabBarPortalMenuComponent {
  @Input() defaultMenu: MenuItem[];
  @Input() customMenu: MenuItem[];

  icons = {
    circleExclamation: faCircleExclamation,
  };

  shouldShowNotification(idSuffix: string): boolean {
    return idSuffix === 'profile' && this.autoIdentService.autoIdentRequired;
  }

  constructor(
    private authService: AuthService,
    public profileService: ProfileService,
    private autoIdentService: AutoIdentService,
  ) {}

  openExternalUrl(customMenuItem: MenuItem): void {
    window.open(customMenuItem.url, '_blank');
  }

  handleMenuItemClick(menuItem: MenuItem): void {
    if (menuItem.idSuffix === 'logout') {
      this.authService.logout();
    }
  }
}
