<input
  class="invisible-input hidden-form-element"
  [(ngModel)]="item.value.value"
  [name]="item.identifier.originalValue"
  #input="ngModel"
  hidden />

<pp-base-field
  [item]="item"
  [customHtmlId]="customHtmlId"
  [input]="input"
  (resetValueEvent)="setCurrentMaskedInputsFromAcutalValue()"
  [preview]="preview">
  <ng-container inputPart>
    <p class="input-preview" *ngIf="!isEditableField && !currentMaskedDateValue">
      {{ 'general.not-specified' | translate }}
    </p>
    <input
      *ngIf="isEditableField || currentMaskedDateValue"
      [disabled]="!isEditableField"
      [id]="'date-' + customHtmlId"
      class="date"
      type="text"
      [placeholder]="'shared.forms.date-format-placeholder' | translate"
      [maskito]="dateMask"
      (keyup)="onDateChange($event, input)"
      (blur)="ensureFieldMarkedAsTouched(input)"
      [attr.aria-label]="'shared.forms.date' | translate"
      [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
      [attr.aria-required]="item.required"
      [(ngModel)]="currentMaskedDateValue"
      [ngModelOptions]="{ standalone: true }"
      #dateInput="ngModel" />
    <button
      [class.hide-on-condensed]="item.hasTime"
      class="date-btn datepicker-button"
      *ngIf="isEditableField"
      role="button"
      type="button"
      tabindex="0"
      [attr.aria-label]="'shared.forms.choose-date' | translate"
      (click)="openDatePopover(input)">
      <fa-icon [icon]="ICONS.CALENDAR" slot="icon-only"></fa-icon>
    </button>
    <input
      [id]="'time-' + customHtmlId"
      class="time"
      type="text"
      [placeholder]="'shared.forms.time-format-placeholder' | translate"
      [maskito]="timeMask"
      (keyup)="onTimeChange($event, input)"
      (blur)="ensureFieldMarkedAsTouched(input)"
      [attr.aria-label]="'shared.forms.time' | translate"
      [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
      [attr.aria-required]="item.required"
      *ngIf="item.hasTime && (isEditableField || currentMaskedTimeValue)"
      [disabled]="!isEditableField"
      #timeInput
      [(ngModel)]="currentMaskedTimeValue"
      [ngModelOptions]="{ standalone: true }" />
    <button
      class="date-btn hide-on-condensed"
      *ngIf="item.hasTime && isEditableField"
      role="button"
      type="button"
      tabindex="0"
      [attr.aria-label]="'shared.forms.choose-time' | translate"
      (click)="openTimePopover(input)">
      <fa-icon [icon]="ICONS.CLOCK" slot="icon-only"></fa-icon>
    </button>
    <button
      [id]="'date-time-' + customHtmlId"
      class="date-btn show-on-condensed"
      *ngIf="item.hasTime && isEditableField"
      role="button"
      type="button"
      tabindex="0"
      [attr.aria-label]="'shared.forms.choose-time' | translate"
      (click)="openSelectionPopover(input)">
      <fa-icon [icon]="ICONS.CALENDAR_WEEK" slot="icon-only"></fa-icon>
    </button>
  </ng-container>
</pp-base-field>
