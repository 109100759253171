import { ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Pipe({
  name: 'genderType',
  pure: false,
})
export class GenderTypePipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    private translateService: TranslateService,
    private injector: Injector,
  ) {
    this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
  }

  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }

  transform(gender: string): string {
    switch (gender) {
      case 'female':
        return this.asyncPipe.transform(this.translateService.get('general.female')).toLowerCase();
      case 'male':
        return this.asyncPipe.transform(this.translateService.get('general.male')).toLowerCase();
      case 'other':
        return this.asyncPipe.transform(this.translateService.get('general.other')).toLowerCase();
      default:
        return this.asyncPipe
          .transform(this.translateService.get('general.unknown-gender'))
          .toLowerCase();
    }
  }
}
