import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'localeDate',
  pure: false,
})
export class LocaleDatePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {}

  transform(value: any, pattern: string = 'mediumDate') {
    if (!value) {return null;}

    const tzValue = dayjs(value).tz().format('YYYY-MM-DDTHH:mm:ss');

    const timezonedDateFormatted = this.datePipe.transform(
      tzValue,
      pattern,
      undefined,
      this.translateService.currentLang,
    );

    return timezonedDateFormatted;
  }
}
