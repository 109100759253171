import { Component, Input } from '@angular/core';
import {
  faCalendarDays,
  faClone,
  faFolderOpen,
  faList,
  faStethoscope,
} from '@fortawesome/free-solid-svg-icons';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { MenuItem } from 'projects/shared/src/lib/models/menu.model';
import { HeaderConfiguration } from '../header/header.model';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent {
  @Input() header: HeaderConfiguration;
  @Input() menu: MenuItem[];
  clientConfig: ClientConfig;

  readonly icons = {
    dashboard: faClone,
    treatments: faStethoscope,
    documents: faFolderOpen,
    appointments: faCalendarDays,
    portal: faList,
  };

  constructor(private router: Router) {}

  isActiveRoute(route: string): boolean {
    return this.router.url.endsWith(route);
  }
}
