import { Injectable } from '@angular/core';
import { ProfileSettings } from '../models/profile.model';
import { ProfileSettingsService } from './profile-settings.service';
import { from, catchError, throwError, Observable, tap, switchMap } from 'rxjs';

@Injectable()
export class LastVisitedPatientsService {
  constructor(private profileSettingsService: ProfileSettingsService) {}

  public updateLastVisitedPatientIdList(patientID: string): Observable<void> {
    return from(this.profileSettingsService.getUserProfileSettingsValue()).pipe(
      tap((userSettings: ProfileSettings) =>
        this.addLastVisitedPatientIdToList(userSettings, patientID),
      ),
      switchMap((userSettings) => this.profileSettingsService.updateProfileSettings(userSettings)),
      catchError((error) => throwError(() => error)),
    );
  }

  private addLastVisitedPatientIdToList(
    userSettings: ProfileSettings,
    patientID: string,
  ): ProfileSettings {
    let patientIdList: string[] = userSettings.lastVisitedPatientsIdList || [];

    patientIdList = this.deleteExistingPatientIdFromList(patientID, patientIdList);

    patientIdList.unshift(patientID);
    userSettings.lastVisitedPatientsIdList = patientIdList.slice(0, 3);

    return userSettings;
  }

  private deleteExistingPatientIdFromList(patientID: string, patientIdList: string[]): string[] {
    const index = patientIdList.indexOf(patientID);
    if (index !== -1) {
      patientIdList.splice(index, 1);
    }
    return patientIdList;
  }
}
