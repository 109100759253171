import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition } from '@fortawesome/angular-fontawesome/types';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  AttributeDetail,
  AttributeDetailValue,
} from 'projects/core/src/lib/models/attribute.model';
import { GenericListItem } from 'projects/core/src/lib/models/list.model';
import { DataFieldValueType } from 'projects/core/src/lib/models/sdapi-form-object.model';
import { AttributeNameIdentifier } from 'projects/core/src/lib/models/shared.model';
import { LocaleDatePipe } from './locale-date.pipe';

@Pipe({
  name: 'detailsToList',
  pure: false,
})
export class DetailsToListTransformPipe implements PipeTransform {
  constructor(private localeDatePipe: LocaleDatePipe) {}

  transform(
    attributeDetails: AttributeDetail[],
    iconSet?: Map<string, IconDefinition>,
  ): GenericListItem[] {
    return attributeDetails.map((attributeDetail: AttributeDetail) => ({
      label: attributeDetail.label,
      value: this.getValueByType(attributeDetail.value),
      icon: this.findCorrespondingIcon(iconSet, attributeDetail.key) || faInfoCircle,
    }));
  }

  private findCorrespondingIcon(iconSet: Map<string, IconDefinition>, key: string): IconDefinition {
    if (!iconSet) {
      return null;
    }
    for (const [identifier, value] of iconSet.entries()) {
      const normalizedIdentifier = new AttributeNameIdentifier(identifier).normalizedValue;
      if (normalizedIdentifier === key) {
        return value;
      }
    }
    return null;
  }

  private getValueByType(value: AttributeDetailValue): string {
    if (value.type.toUpperCase() === DataFieldValueType.date) {
      return this.localeDatePipe.transform(value.value, value.format);
    } else {
      return value.value;
    }
  }
}
