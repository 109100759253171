import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ViewDocumentDetails } from 'projects/core/src/lib/models/documents.model';

@Component({
  selector: 'lib-image-viewer',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
@UntilDestroy()
export class ImageViewerComponent {
  @Input() image: ViewDocumentDetails;
  @Output() commitButtonsVisibility = new EventEmitter<boolean>();
  zoomProperties = {
    backgroundColor: 'rgba(0,0,0,0)',
  };
}
