<ion-grid [class.content]="containerize" class="content" [fixed]="true">
  <ion-row>
    <ion-col *ngIf="!isLoading" class="columnized">
      <ion-text *ngIf="privacyPolicy?.content" [innerHTML]="privacyPolicy.content"></ion-text>
    </ion-col>

    <ion-col *ngIf="isLoading" id="skeleton">
      <ng-container *ngFor="let item of generateSkeletonItems()">
        <p>
          <ion-skeleton-text class="rounded-small like-p" animated></ion-skeleton-text>
        </p>
      </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>
