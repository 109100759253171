import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ProfileSettings } from '../models/profile.model';
import { RetryOptions } from '../models/shared.model';
import { ProfileSettingsService } from './profile-settings.service';

@Injectable()
export class UserAgreementService {
  constructor(private profileSettingsService: ProfileSettingsService) {}

  public async fetchUpdatedTosReadStatusWithRetry(attempt: number = 0): Promise<boolean> {
    const retryOptions: RetryOptions = new RetryOptions(10, 1500);
    try {
      const userSettings: ProfileSettings =
        await this.profileSettingsService.getUserProfileSettingsValue();
      return this.ensureTosReadStatusUpdated(userSettings);
    } catch (error) {
      return this.handleTosStatusUpdateFailure(attempt, retryOptions);
    }
  }

  public async updateTosReadStatus(value: boolean): Promise<void> {
    const userSettings: ProfileSettings =
      await this.profileSettingsService.getUserProfileSettingsValue();
    userSettings.userProfileData.tosRead = value;
    await firstValueFrom(this.profileSettingsService.updateProfileSettings(userSettings));
  }

  private ensureTosReadStatusUpdated(userSettings: ProfileSettings): boolean {
    if (userSettings.userProfileData?.tosRead === true) {
      return true;
    } else {
      throw new Error(
        'The Terms of Service status was not updated within the expected time frame.',
      );
    }
  }

  private async handleTosStatusUpdateFailure(
    attempt: number,
    retryOptions: RetryOptions,
  ): Promise<boolean> {
    if (attempt >= retryOptions.retryCount) {
      throw new Error(
        `Maximum retry attempts reached. Unable to confirm the Terms of Service status update after ${retryOptions.retryCount} attempts.`,
      );
    }
    await new Promise((resolve) => setTimeout(resolve, retryOptions.delayInMilliseconds));
    return this.fetchUpdatedTosReadStatusWithRetry(attempt + 1);
  }
}
