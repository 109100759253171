import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { TableDataType } from './dynamic-table.model';
import { InvokerBody } from './invoker-body.model';
import {
  DataFieldValueType,
  FormatOptionIdentifier,
  ShowTypes,
  TieFormObject,
} from './sdapi-form-object.model';
import { ObjectType, SDAPIObject } from './sdapi-object.model';

export class TieTableObjectList extends SDAPIObject<ObjectType.objectList> {
  target: string;
  objId: number;
  parentId: number;
  objName: string;
  attributeProfileId: number;
  autoRefreshIntervalSeconds: any;
  attrNames: string[];
  attrTypes: TableDataType[];
  attrFormats: string[];
  items: TieTableItemObject[];
  columnFormats: ColumnFormats[];
  conditionalFormatting: string[];
  showTypes: ShowTypes;
  header?: TieFormObject;
  [key: string]: any;
}

export class TieTableReport extends SDAPIObject<ObjectType.report> {
  target: string;
  attributeProfileId: number;
  attrNames: string[];
  attrTypes: TableDataType[];
  attrFormats: string[];
  items: TieTableItemObject[];
  header: string;
  [key: string]: any;
}

export class TieTableItemObject {
  attrValues: string[];
  onPreview: BehaviorInvokerObject;

  objId?: number;
  objName?: string;
  statusInfo?: StatusInfoResource;

  [key: string]: any;
}

export class BehaviorInvokerObject {
  t: 'BehaviorInvoker';
  behavior: number;
  methods: InvokerBody[];
  objId: number;
}

export type ColumnFormats = {
  cellColor: string;
  cellBackgroundColor: string;
  cellFontWeight: string;
};

export class TieConstraintPopupListObject {
  objlist: TieTableObjectList;
  [key: string]: any;
}

export class TieTableProcessor {
  private readonly showTypes: ShowTypes;
  private readonly attrNames: string[];
  private readonly items: TieTableItemObject[];

  readonly types: Map<string, DataFieldValueType>;
  readonly names: Map<string, string>;
  readonly formats: Map<string, FormatOptionIdentifier>;

  constructor(resource: TieTableObjectList) {
    this.showTypes = structuredClone(resource.showTypes);
    this.items = [...resource.items];
    this.attrNames = [...resource.attrNames];

    this.types = SDAPIObjectMapper.mapShowTypesAsTableRowTypes(resource.showTypes.BODY);
    this.names = SDAPIObjectMapper.mapShowTypesAsTableRowNames(resource.showTypes.BODY);
    this.formats = SDAPIObjectMapper.mapShowTypesAsTableRowFormats(resource.showTypes.BODY);
  }

  public getRows(): TieTableRow[] {
    const identifiers: string[] = SDAPIObjectMapper.mapShowTypesAsTableRowIdentifiers(
      this.showTypes.BODY,
    );
    return this.items.map(
      (item: TieTableItemObject) => new TieTableRow(item, identifiers, this.attrNames),
    );
  }
}

export class TieTableRow {
  private readonly identifiers: string[];
  private readonly attrValues: string[];
  private readonly displayNames: string[];
  public readonly objId: number;
  public readonly statusInfo: StatusInfoResource;
  public readonly behaviorInvokers: InvokerBody[];

  constructor(item: TieTableItemObject, identifiers: string[], displayNames: string[]) {
    this.identifiers = identifiers;
    this.objId = item.objId;
    this.attrValues = item.attrValues;
    this.displayNames = displayNames;
    this.statusInfo = item.statusInfo;
    this.behaviorInvokers = item.onPreview.methods;
  }

  public getAttributeValue(attributeName: string): string {
    return SDAPIObjectMapper.getTableRowValueByItemIdentifier(
      this.identifiers,
      this.attrValues,
      attributeName,
    );
  }

  public getOptionalAttributeValue(attributeName: string): string | undefined {
    return SDAPIObjectMapper.getOptionalTableRowValueByItemIdentifier(
      this.identifiers,
      this.attrValues,
      attributeName,
    );
  }

  public getDisplayName(attributeName: string): string {
    return SDAPIObjectMapper.getTableRowValueByItemIdentifier(
      this.identifiers,
      this.displayNames,
      attributeName,
    );
  }
}

export class StatusInfoResource {
  type: StatusType;
  label: string;
  color: string;

  [key: string]: any;
}

export enum StatusType {
  distribution = 'DISTRIBUTION',
  state = 'STATE',
}
