import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  AppointmentDetails,
  ViewAppointmentsGroup,
} from 'projects/core/src/lib/models/appointments.model';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { AppointmentBookingService } from 'projects/core/src/lib/services/appointment-booking.service';
import { AppointmentsService } from 'projects/core/src/lib/services/appointments.service';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import { firstValueFrom, mergeMap } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lib-next-appointments',
  templateUrl: './next-appointments.component.html',
  styleUrls: ['./next-appointments.component.scss'],
})
@UntilDestroy()
export class NextAppointmentsComponent implements OnInit {
  showRequestNewAppointment: boolean;
  noAppointmentsMessage: string;
  mappedAppointmentGroups: ViewAppointmentsGroup;

  readonly icons = {
    appointment: faCalendarPlus,
  };

  constructor(
    private router: Router,
    private popupService: PopupService,
    private clientService: ClientConfigService,
    private appointmentService: AppointmentsService,
    private loadingService: LoadingService,
    private bookingService: AppointmentBookingService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.loadTranslatedNoDataMessage();
    this.setAppointmentsVisibility();
    this.fetchAppointments();
  }

  private loadTranslatedNoDataMessage(): void {
    firstValueFrom(this.translateService.get('appointments.no-upcoming-appointments')).then(
      (translation) => (this.noAppointmentsMessage = translation),
    );
  }

  private setAppointmentsVisibility(): void {
    const clientConfig: ClientConfig = this.clientService.get();
    if (clientConfig) {
      this.showRequestNewAppointment = clientConfig.activeModules.requestAppointment;
    }
  }

  navigateToAllAppointments(): void {
    this.router.navigate(['/portal/appointments']);
  }

  navigateToAppointment(appointment: AppointmentDetails): void {
    this.router.navigate(['/portal/appointments', `${appointment.id}`]);
  }

  async newAppointment(): Promise<void> {
    await this.loadingService.load(
      await firstValueFrom(this.translateService.get('appointments.loading-appointment-booking')),
    );
    this.bookingService
      .getBookingForm()
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (form) => {
          this.popupService.showAppointmentBookingModal(form);
        },
      });
  }

  private fetchAppointments(): void {
    this.appointmentService
      .getUpcomingAppointments()
      .pipe(
        untilDestroyed(this),
        take(1),
        mergeMap(
          async (upcomingAppointments: AppointmentDetails[]) =>
            await this.appointmentService.addFakeAppointmentInDemo(upcomingAppointments, false),
        ),
      )
      .subscribe((appointments: AppointmentDetails[]) => {
        this.mapAppointmentsForView(appointments);
      });
  }

  private mapAppointmentsForView(appointments: AppointmentDetails[]): void {
    appointments = appointments
      .sort((objA, objB) => objA.startDate.getTime() - objB.startDate.getTime())
      .slice(0, 3);
    this.mappedAppointmentGroups =
      this.appointmentService.groupAppointmentsByDateForView(appointments);
  }

  get showNoAppointmentsMessage(): boolean {
    const hasNoFutureAppointments = this.mappedAppointmentGroups?.future?.length === 0;
    return hasNoFutureAppointments;
  }
}
