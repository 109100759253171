@if (data) {
  <div
    class="hero"
    [style]="
      !!data.hero?.img
        ? '--hero-image: url(' +
          data.hero.img +
          '); --hero-color: white; --hero-color-shadow: rgba(0, 0, 0, 0.7);'
        : ''
    ">
    <ion-grid class="content" [fixed]="true">
      <ion-row>
        <ion-col sizeXl="7" sizeMd="7" size="12" class="teaser">
          <ion-row *ngIf="data.hero?.headline !== ''">
            <h4 class="align no-hyphens" *ngIf="appName === 'p-p'">
              {{
                data.hero?.headline
                  ? data.hero.headline
                  : ('shared.landingpage.hero.headline-pp' | translate)
              }}
            </h4>
            <h4 class="align no-hyphens" *ngIf="appName === 'z-p'">
              {{
                data.hero?.headline
                  ? data.hero.headline
                  : ('shared.landingpage.hero.headline-zp' | translate)
              }}
            </h4>
            <h4 class="align no-hyphens" *ngIf="appName === 'k-p'">
              {{
                data.hero?.headline
                  ? data.hero.headline
                  : ('shared.landingpage.hero.headline-kp' | translate)
              }}
            </h4>
            <h4 class="align no-hyphens" *ngIf="appName === 'm-p'">
              {{
                data.hero?.headline
                  ? data.hero.headline
                  : ('shared.landingpage.hero.headline-mp' | translate)
              }}
            </h4>
          </ion-row>
          <ion-row *ngIf="data.hero?.subHeadline !== ''">
            <ion-text>
              <p class="align">
                {{
                  data.hero?.subHeadline
                    ? data.hero.subHeadline
                    : ('shared.landingpage.hero.sub-headline' | translate)
                }}
              </p>
            </ion-text>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-button
                *ngIf="loginPath"
                class="medium p3 block-button-mobile"
                color="primary"
                [routerLink]="loginPath"
                routerDirection="root">
                {{ data.hero?.loginText ? data.hero.loginText : ('general.login' | translate) }}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col size="1" class="spacer ion-hide-md-down"></ion-col>
        <ion-col size="3" class="feature ion-hide-md-down" *ngIf="data.hero?.teaser !== ''">
          <ion-img
            [src]="
              data.hero?.teaser ? data.hero.teaser : ('shared.landingpage.hero.teaser' | translate)
            "></ion-img>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <ion-grid class="content" id="teaser" [fixed]="true">
    <ion-row class="ion-align-items-center">
      <ion-col sizeXl="5.5" sizeMd="5.5" size="12">
        <ion-img
          class="image"
          [src]="
            data.teaser?.img ? data.teaser?.img : ('shared.landingpage.teaser.img' | translate)
          "></ion-img>
      </ion-col>
      <ion-col sizeXl="1" sizeMd="0.5" class="spacer ion-hide-md-down"></ion-col>
      <ion-col sizeXl="4.5" sizeMd="6" size="12">
        <h1 class="align">
          {{
            data.teaser?.headline
              ? data.teaser.headline
              : ('shared.landingpage.teaser.headline' | translate)
          }}
        </h1>
        <p id="teaser-description" class="align">
          {{
            data.teaser?.description
              ? data.teaser.description
              : ('shared.landingpage.teaser.description' | translate)
          }}
        </p>
        <ion-list class="bullet-points p" *ngIf="data.teaser?.points">
          <ion-item lines="none" *ngFor="let point of data.teaser.points">
            <fa-icon size="lg" slot="start" class="primary" [icon]="icons.bulletPoint"></fa-icon>
            {{ point }}
          </ion-item>
        </ion-list>
        <ion-list class="bullet-points p" *ngIf="!data.teaser?.points">
          <ion-item lines="none">
            <fa-icon size="lg" slot="start" class="primary" [icon]="icons.bulletPoint"></fa-icon>
            {{ 'shared.landingpage.teaser.point-1' | translate }}
          </ion-item>
          <ion-item lines="none">
            <fa-icon size="lg" slot="start" class="primary" [icon]="icons.bulletPoint"></fa-icon>
            {{ 'shared.landingpage.teaser.point-2' | translate }}
          </ion-item>
          <ion-item lines="none">
            <fa-icon size="lg" slot="start" class="primary" [icon]="icons.bulletPoint"></fa-icon>
            {{ 'shared.landingpage.teaser.point-3' | translate }}
          </ion-item>
        </ion-list>
        @if (shouldShowBookingButton) {
          <ion-button
            class="p3 small block-button-mobile"
            color="primary"
            (click)="openBookingModal()">
            {{ 'shared.appointment-booking.book' | translate }}
          </ion-button>
        } @else if (loginPath) {
          <ion-button
            class="p3 small block-button-mobile"
            color="primary"
            [routerLink]="loginPath"
            routerDirection="root">
            {{ data.teaser?.loginText ? data.teaser?.loginText : ('general.login' | translate) }}
          </ion-button>
        }
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="footer">
    <ion-grid id="testimonials" class="content" [fixed]="true" *ngIf="data.testimonials">
      <ion-row *ngIf="data.testimonials && !!data.testimonials.length">
        <ion-col id="testimonials-title">
          <h1 class="align">{{ 'shared.landingpage.testimonials-title' | translate }}</h1>
        </ion-col>
      </ion-row>

      <ion-row class="ion-justify-content-center">
        <swiper
          class="swiper-container"
          style="--slides: {{ data.testimonials.length }}"
          [config]="config"
          #swiper>
          <ng-template
            *ngFor="let testimonial of data.testimonials"
            class="testimonial-section"
            swiperSlide>
            <lib-testimonial-card
              [name]="testimonial.name"
              [role]="testimonial.role"
              [image]="testimonial.image"
              [quote]="testimonial.quote"></lib-testimonial-card>
          </ng-template>
        </swiper>

        <div class="paginator"></div>
      </ion-row>

      <ion-row class="ion-justify-content-center">
        <ion-card class="feature-bottom">
          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col class="text" sizeXl="7" sizeMd="7" sizeSm="12">
                  <h2 class="align">
                    {{
                      data.footer?.headline
                        ? data.footer.headline
                        : ('shared.landingpage.footer.headline' | translate)
                    }}
                  </h2>
                  <p class="align">
                    {{
                      data.footer?.description
                        ? data.footer.description
                        : ('shared.landingpage.footer.description' | translate)
                    }}
                  </p>
                </ion-col>
                <ion-col sizeXl="1" sizeLg="0" sizeMd="0" size="12" class="spacer"></ion-col>
                <ion-col
                  class="ion-align-self-center"
                  style="text-align: center"
                  size="12"
                  sizeXl="4"
                  sizeMd="4"
                  sizeSm="12">
                  <ion-button
                    *ngIf="loginPath"
                    class="p3 large block-button-mobile"
                    color="primary"
                    [routerLink]="loginPath"
                    routerDirection="root">
                    {{
                      data.footer?.loginText ? data.footer.loginText : ('general.login' | translate)
                    }}
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-row>
    </ion-grid>
  </div>

  <div class="footer-action-pane">
    <ion-grid class="content" [fixed]="true">
      <ion-button
        id="language-switch-button"
        color="light"
        size="small"
        (click)="presentLanguageSwitch()">
        <fa-icon [icon]="icons.language" slot="start"></fa-icon>
        <span>{{
          data.footer?.languageButtonText
            ? data.footer.languageButtonText
            : ('shared.landingpage.footer.language-switch.switch-button' | translate)
        }}</span>
      </ion-button>

      <div class="footer-links">
        <a id="imprint" [routerLink]="['/imprint']" routerDirection="forward">
          {{ 'general.imprint' | translate }}
        </a>
        <a id="privacy-policy" [routerLink]="['/privacy-policy']" routerDirection="forward">
          {{ 'general.privacy-policy' | translate }}
        </a>
        <a id="back-to-clinic-page" [routerLink]="[backToClinicPageLink]">
          {{ 'shared.landingpage.back-to-clinic-page' | translate }}
        </a>
      </div>
    </ion-grid>
  </div>

  <div id="language-switch-trigger"></div>
}
