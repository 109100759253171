<ion-list #searchList>
  @if (showSearchField) {
    <ion-item class="select-search-ion-item" lines="none">
      <div class="form-input select-search">
        <input
          class=""
          [(ngModel)]="search"
          (keyup)="updateSearchEvent()"
          (keyup.arrowdown)="focusFirstListElement()"
          (keyup.arrowup)="focusLastListElement()"
          [ngModelOptions]="{ standalone: true }"
          #searchField
          [placeholder]="'shared.forms.search' | translate" />
        <button
          class="reset-button-test-class reset-button-new"
          role="button"
          type="button"
          [disabled]="search === ''"
          (click)="resetSearchField()"
          [ariaLabel]="'shared.forms.reset-input-field' | translate">
          @if (search === '') {
            <fa-icon [icon]="ICONS.SEARCH" slot="icon-only"></fa-icon>
          } @else {
            <fa-icon [icon]="ICONS.RESET" slot="icon-only"></fa-icon>
          }
        </button>
      </div>
    </ion-item>
  }
  @if (filteredOptions.length === 0 && autocompleteOptionsAreLoading) {
    <ion-item class="selection-field-popover-item" lines="none">
      <div class="form-input-loader">
        <img src="shared/assets/images/loading-spinner.svg" alt="Loading Animation" />
        <span class="p strong">{{ 'shared.forms.refresh-options' | translate }}</span>
      </div>
    </ion-item>
  } @else if (filteredOptions.length === 0) {
    <ion-item class="selection-field-popover-item" lines="none">
      <span class="no-results">{{ 'shared.forms.no-results-found' | translate }}</span>
    </ion-item>
  }
  @for (
    option of filteredOptions;
    track option.identifier;
    let isLastElement = $last;
    let isFirstElement = $first
  ) {
    <ion-item
      class="selection-field-popover-item"
      (click)="selectOption(option)"
      (keyup.enter)="selectOption(option)"
      (keyup.arrowup)="isFirstElement ? focusSearchField() : undefined"
      button="true"
      tabindex="0"
      detail="false"
      [lines]="isLastElement ? 'none' : 'full'"
      #searchListItem>
      {{ option.value }}
      <ion-icon
        *ngIf="option.identifier === selectedOption?.identifier"
        slot="end"
        name="checkmark"
        color="primary"></ion-icon>
    </ion-item>
  }
</ion-list>
