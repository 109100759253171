import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationOptions, ViewResult } from 'projects/core/src/lib/models/modal-action.model';
import { Observable, firstValueFrom, from, map, switchMap } from 'rxjs';
import { APIError } from '../data/errors.data';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { TableMapper } from '../mappers/table.mapper';
import { DocumentDetails } from '../models/documents.model';
import { DynamicButton } from '../models/dynamic-button.model';
import { TableList } from '../models/dynamic-table.model';
import { DynamicForm, DynamicFormConfiguration, DynamicFormType } from '../models/form.model';
import { Invoker, InvokerBody, InvokerMethods } from '../models/invoker-body.model';
import {
  GenericTranslationKeys,
  InvokerMethodCollection,
  ModalEvent,
  ModalResult,
  OperationKey,
} from '../models/modal-action.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import { ObjectRefresh, ObjectType, SDAPIResponseObject } from '../models/sdapi-object.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { BehaviorInvokerService } from './behaviour-invoker.service';
import { DocumentsService } from './documents.service';
import { DynamicFormService } from './dynamic-form.service';
import { LoadingService } from './loading.service';
import { OverlayEventRole, PopupService } from './popup.service';
import { TableDataService } from './table-data.service';

@Injectable()
export class ModalActionService {
  constructor(
    private dynamicFormMapper: DynamicFormMapper,
    private http: HttpClient,
    private documentsService: DocumentsService,
    private popupService: PopupService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private dynamicFormService: DynamicFormService,
    private tableDataService: TableDataService,
    private behaviorInvokerService: BehaviorInvokerService,
  ) {}

  public showDynamicViewWithActions(
    id: string,
    behaviorInvokers: InvokerBody[],
    fallbackMethods?: InvokerMethodCollection,
  ): Observable<OverlayEventDetail> {
    return this.behaviorInvokerService
      .retrievePrimaryAndActionInvokers(id, behaviorInvokers, fallbackMethods)
      .pipe(
        switchMap(([primaryInvoker, actionInvokers]) =>
          this.getPrimaryViewBasedOnInvokerMethod(primaryInvoker, actionInvokers).pipe(
            switchMap((response: ViewResult) =>
              from(this.showDynamicView(primaryInvoker, response)),
            ),
          ),
        ),
      );
  }

  private getPrimaryViewBasedOnInvokerMethod(
    primaryInvoker: Invoker,
    actionInvokers: Invoker[],
  ): Observable<ViewResult> | null {
    switch (primaryInvoker.invoker.methodName) {
      case InvokerMethods.objectAttributesView:
        return this.dynamicFormService.getDynamicViewFormWithActionButtons(
          primaryInvoker,
          actionInvokers,
        );
      case InvokerMethods.objectObjectList:
      case InvokerMethods.objectConstraintObjectList:
        return this.tableDataService.getTableListWithHeaderAndActionButtons(
          primaryInvoker,
          actionInvokers,
        );
      case InvokerMethods.objectView:
        return this.documentsService.getDocumentDetails(primaryInvoker, actionInvokers);
      default:
        console.error(
          `Error in ModalActionService. Unsupported invoker method name "${primaryInvoker.invoker.methodName}" for mapping SDAPI response`,
        );
        return null;
    }
  }

  private showDynamicView(
    primaryInvoker: Invoker,
    mappedResponse: ViewResult,
  ): Promise<OverlayEventDetail> | null {
    switch (primaryInvoker.invoker.methodName) {
      case InvokerMethods.objectAttributesView:
        return this.popupService.showDynamicFormModal(
          this.getFormConfiguration(mappedResponse as DynamicForm, DynamicFormType.VIEW),
        );
      case InvokerMethods.objectObjectList:
      case InvokerMethods.objectConstraintObjectList:
        return this.popupService.showTableDataModal(mappedResponse as TableList);
      case InvokerMethods.objectView:
        return this.popupService.showDocumentModal(mappedResponse as DocumentDetails);
      default:
        console.error(
          `Error in ModalActionService. Unsupported invoker method name "${primaryInvoker.invoker.methodName}" for showing the primary view`,
        );
        return null;
    }
  }

  public performActivityOnObject(invoker: Invoker): Observable<ObjectRefresh | ViewResult> {
    return this.http
      .put<TieFormObject>(invoker.activityURL, invoker.invoker)
      .pipe(
        map((response: SDAPIResponseObject | TieFormObject | TieTableObjectList) =>
          this.handleResponseOfActivity(response, invoker),
        ),
      );
  }

  public handleResponseOfActivity(
    response: SDAPIResponseObject | TieFormObject | TieTableObjectList,
    invoker: Invoker,
  ): ObjectRefresh | ViewResult {
    switch (response.t) {
      case ObjectType.bulkServerResponse:
        return this.handleBulkServerResponse(response as SDAPIResponseObject);
      case ObjectType.form:
      case ObjectType.objectCreateAutoImport:
        return this.dynamicFormMapper.mapResponseToDynamicFormWithActivityUrl(response, invoker);
      case ObjectType.objectList:
        return TableMapper.mapTable(response as TieTableObjectList);
      default:
        throw new Error(
          `Error in ModalActionService. Execution of the activity "${invoker.invoker.methodName}" failed. Unsupported response type: "${response.t}".`,
        );
    }
  }

  public handleResponseOfFormSave(response: SDAPIResponseObject): ObjectRefresh {
    switch (response.t) {
      case ObjectType.bulkServerResponse:
        return this.handleBulkServerResponse(response);
      default:
        throw new Error(
          `Error in ModalActionService. Form save failed. Unsupported response type: "${response.t}".`,
        );
    }
  }

  private handleBulkServerResponse(response: SDAPIResponseObject): ObjectRefresh {
    if (response.bulk) {
      return SDAPIObjectMapper.findObjectInBulkServerResponse<ObjectRefresh>(
        response,
        ObjectType.refreshObj,
      );
    } else {
      throw new APIError(`Error in ModalActionService. Bulk in BulkServerResponse not found.`);
    }
  }

  public isValidDynamicForm(response: ObjectRefresh | ViewResult): boolean {
    return response instanceof DynamicForm && !!response.body;
  }

  public async handleInvokerMethodOfActionButton(
    event: OverlayEventDetail,
    translationKeys?: Map<OperationKey, string>,
  ): Promise<ModalResult> {
    const actionButtons: DynamicButton[] | null = this.resolveActionButtonVisibility(event);
    const keys: Map<OperationKey, string> = translationKeys || GenericTranslationKeys;
    try {
      return await this.handleInvokerMethod(event.data.invoker, actionButtons, keys);
    } catch (error) {
      console.error(error);
      return { event: ModalEvent.processFailed };
    } finally {
      this.loadingService.stop();
    }
  }

  public handleInvokerMethod(
    invoker: Invoker,
    actionButtons: DynamicButton[],
    translationKeys: Map<OperationKey, string>,
  ): Promise<ModalResult> {
    switch (invoker.invoker.methodName) {
      case InvokerMethods.objectView:
        return this.handleDocument(invoker, actionButtons, translationKeys);
      case InvokerMethods.objectAttributesEdit:
      case InvokerMethods.objectCreate:
        return this.showEditForm(invoker, translationKeys);
      case InvokerMethods.objectAttributesView:
        return this.showViewForm(invoker, actionButtons, translationKeys);
      case InvokerMethods.objectDelete:
      case InvokerMethods.projectDelete:
        return this.handleDeleteAction(invoker, translationKeys);
      case InvokerMethods.objectUpdate:
        return this.handleUpdateAction(invoker, translationKeys);
      case InvokerMethods.medicalDocumentCreate:
      case InvokerMethods.objectCreateAutoImport:
        return this.showUploadForm(invoker, translationKeys);
      case InvokerMethods.objectObjectList:
      case InvokerMethods.objectConstraintObjectList:
        return this.handleTable(invoker, actionButtons, translationKeys);
      case InvokerMethods.objectCommit:
      case InvokerMethods.objectDistribute:
        return this.handleCommitAction(invoker, translationKeys);
      default:
        throw new Error(
          `Error in ModalActionService. Unhandled invoker method name "${invoker.invoker.methodName}"`,
        );
    }
  }

  private async handleDocument(
    invoker: Invoker,
    actionButtons: DynamicButton[],
    translationKeys: Map<OperationKey, string>,
  ): Promise<ModalResult> {
    try {
      await this.showLoadingMessage(translationKeys, 'document-fetching');
      const document: DocumentDetails = await firstValueFrom(
        this.documentsService.getDocumentDetails(invoker),
      );
      if (actionButtons?.length) {
        document.actionButtons = actionButtons;
      }
      const event: OverlayEventDetail = await this.popupService.showDocumentModal(document);
      if (this.isActionTriggeringEvent(event)) {
        await this.handleInvokerMethodOfActionButton(event, translationKeys);
      }
      return { event: ModalEvent.processCompleted };
    } catch (error) {
      throw new Error(`Error in ModalActionService. Failed to show the document. ${error}`);
    }
  }

  private async showEditForm(
    invoker: Invoker,
    translationKeys: Map<OperationKey, string>,
  ): Promise<ModalResult> {
    try {
      await this.showLoadingMessage(translationKeys, 'edit-form-fetching');
      const response: ObjectRefresh | ViewResult = await firstValueFrom(
        this.performActivityOnObject(invoker),
      );
      if (this.isValidDynamicForm(response)) {
        return this.openDynamicFormModal(
          new DynamicFormConfiguration({
            dynamicForm: response as DynamicForm,
            type: DynamicFormType.DIRECT_SAVE,
            translationOptions: this.getTranslationOptions(translationKeys, 'save-completion'),
          }),
        );
      }
      return { event: ModalEvent.processCompleted };
    } catch (error) {
      throw new Error(`Error in ModalActionService. Failed to show the edit form. ${error}`);
    }
  }

  private async showViewForm(
    invoker: Invoker,
    actionButtons: DynamicButton[],
    translationKeys: Map<OperationKey, string>,
  ): Promise<ModalResult> {
    try {
      await this.showLoadingMessage(translationKeys, 'view-form-fetching');
      const form: DynamicForm = await firstValueFrom(
        this.dynamicFormService.fetchViewForm(invoker),
      );
      if (actionButtons?.length) {
        form.actionButtons = actionButtons;
      }
      return this.openDynamicFormModal(
        new DynamicFormConfiguration({
          dynamicForm: form,
          type: DynamicFormType.VIEW,
        }),
        ModalEvent.processCompleted,
      );
    } catch (error) {
      throw new Error(`Error in ModalActionService. Failed to show the view form. ${error}`);
    }
  }

  private async handleDeleteAction(
    invoker: Invoker,
    translationKeys: Map<OperationKey, string>,
  ): Promise<ModalResult> {
    try {
      await this.showLoadingMessage(translationKeys, 'delete-form-fetching');
      const response: ObjectRefresh | ViewResult = await firstValueFrom(
        this.performActivityOnObject(invoker),
      );
      const translationOptions: TranslationOptions = {
        keys: translationKeys,
        successMessageKey: 'delete-completion',
        actionInProgressKey: 'deleting-in-progress',
      };
      return this.handleResponseOfStateChangingActivity(response, translationOptions);
    } catch (error) {
      throw new Error(`Error in ModalActionService. Failed to trigger deletion form. ${error}`);
    }
  }

  private async handleUpdateAction(
    invoker: Invoker,
    translationKeys: Map<OperationKey, string>,
  ): Promise<ModalResult> {
    try {
      await this.showLoadingMessage(translationKeys, 'update-form-fetching');
      const response: ObjectRefresh | ViewResult = await firstValueFrom(
        this.performActivityOnObject(invoker),
      );
      const translationOptions: TranslationOptions = {
        keys: translationKeys,
        successMessageKey: 'update-completion',
      };
      return this.handleResponseOfStateChangingActivity(response, translationOptions);
    } catch (error) {
      throw new Error(`Error in ModalActionService. Failed to trigger update form. ${error}`);
    }
  }

  private async handleResponseOfStateChangingActivity(
    response: ObjectRefresh | ViewResult,
    translationOptions: TranslationOptions,
  ): Promise<ModalResult> {
    if (this.isValidDynamicForm(response)) {
      return await this.openDynamicFormModal(
        new DynamicFormConfiguration({
          dynamicForm: response as DynamicForm,
          type: DynamicFormType.DIRECT_SAVE,
          translationOptions,
        }),
        ModalEvent.stateChange,
      );
    } else {
      await this.showToastMessage(translationOptions.keys, translationOptions.successMessageKey);
      return { event: ModalEvent.stateChange };
    }
  }

  private async showUploadForm(
    invoker: Invoker,
    translationKeys: Map<OperationKey, string>,
  ): Promise<ModalResult> {
    try {
      await this.showLoadingMessage(translationKeys, 'upload-form-fetching');
      const response: ObjectRefresh | ViewResult = await firstValueFrom(
        this.performActivityOnObject(invoker),
      );
      return await this.openDynamicFormModal(
        new DynamicFormConfiguration({
          dynamicForm: response as DynamicForm,
          type: DynamicFormType.DOCUMENT_UPLOAD,
        }),
      );
    } catch (error) {
      throw new Error(
        `Error in ModalActionService. Failed to open the form for uploading documents. ${error}`,
      );
    }
  }

  private async handleTable(
    invoker: Invoker,
    actionButtons: DynamicButton[],
    translationKeys: Map<OperationKey, string>,
  ): Promise<ModalResult> {
    try {
      await this.showLoadingMessage(translationKeys, 'table-information-fetching');
      const response: TableList = (await firstValueFrom(
        this.performActivityOnObject(invoker),
      )) as TableList;
      if (actionButtons?.length) {
        response.actionButtons = actionButtons;
      }
      const event: OverlayEventDetail = await this.popupService.showTableDataModal(response);
      if (this.isActionTriggeringEvent(event)) {
        await this.handleInvokerMethodOfActionButton(event, translationKeys);
      }
      return { event: ModalEvent.processCompleted };
    } catch (error) {
      throw new Error(
        `Error in ModalActionService. Failed to open the table information. ${error}`,
      );
    }
  }

  private async handleCommitAction(
    invoker: Invoker,
    translationKeys: Map<OperationKey, string>,
  ): Promise<ModalResult> {
    const response: ObjectRefresh | ViewResult = await firstValueFrom(
      this.performActivityOnObject(invoker),
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (response.t === ObjectType.refreshObj) {
      await this.showToastMessage(translationKeys, 'commit-completion');
      return { event: ModalEvent.stateChange };
    } else if (this.isValidDynamicForm(response)) {
      return await this.openDynamicFormModal(
        new DynamicFormConfiguration({
          dynamicForm: response as DynamicForm,
          type: DynamicFormType.DIRECT_SAVE,
          translationOptions: this.getTranslationOptions(translationKeys, 'commit-completion'),
        }),
        ModalEvent.stateChange,
      );
    } else {
      throw new Error(
        `Error in ModalActionService. Failed to commit. Unsupported response type: "${response}".`,
      );
    }
  }

  public async openDynamicFormModal(
    configuration: DynamicFormConfiguration,
    eventOnSave?: ModalEvent,
  ): Promise<ModalResult> {
    return new Promise((resolve) => {
      this.popupService
        .showDynamicFormModal(configuration)
        .then(async (overlayEvent: OverlayEventDetail) => {
          if (overlayEvent.role === OverlayEventRole.save) {
            const event: ModalEvent = eventOnSave || ModalEvent.dataReload;
            resolve({ event, data: overlayEvent.data.response });
          } else if (this.isNoActionEventRole(overlayEvent)) {
            resolve({ event: ModalEvent.processCanceled });
          }
        });
    });
  }

  private isNoActionEventRole(role: OverlayEventDetail): boolean {
    return role.role === OverlayEventRole.cancel || role.role === undefined;
  }

  public getFormConfiguration(
    dynamicForm: DynamicForm,
    type: DynamicFormType,
  ): DynamicFormConfiguration {
    return new DynamicFormConfiguration({
      type,
      dynamicForm,
    });
  }

  private async retrieveTranslationByKey(
    translationKeys: Map<OperationKey, string>,
    operationKey: OperationKey,
  ): Promise<string> {
    try {
      const translationKey: string = translationKeys.get(operationKey);
      return await firstValueFrom(this.translateService.get(translationKey));
    } catch (error) {
      throw new Error(
        `Error in ModalActionService. Failed to retrieve translation by key. ${error}`,
      );
    }
  }

  public async showLoadingMessage(
    translationKeys: Map<OperationKey, string>,
    operationKey: OperationKey,
  ): Promise<void> {
    await this.loadingService.load(
      await this.retrieveTranslationByKey(translationKeys, operationKey),
    );
  }

  public async showToastMessage(
    translationKeys: Map<OperationKey, string>,
    operationKey: OperationKey,
  ): Promise<void> {
    this.loadingService.toast(await this.retrieveTranslationByKey(translationKeys, operationKey));
  }

  public shouldReloadDataSet(result: ModalResult): boolean {
    return result.event === ModalEvent.dataReload || result.event === ModalEvent.stateChange;
  }

  public isActionTriggeringEvent(result: OverlayEventDetail): boolean {
    return (
      result.role === OverlayEventRole.actionButtonEvent ||
      result.role === OverlayEventRole.activityChange
    );
  }

  private resolveActionButtonVisibility(event: OverlayEventDetail): DynamicButton[] | null {
    return event.role === OverlayEventRole.actionButtonEvent ? null : event.data.actionButtons;
  }

  private getTranslationOptions(
    translationKeys: Map<OperationKey, string>,
    messageKey: OperationKey,
  ): TranslationOptions {
    return {
      keys: translationKeys,
      successMessageKey: messageKey,
    };
  }

  public async resolveDocumentModalView(
    documentDetails: DocumentDetails,
    invoker: Invoker,
  ): Promise<OverlayEventDetail> {
    if (this.documentsService.isViewOrOpenUrlInvokerMethod(invoker.invoker)) {
      return await this.popupService.showDocumentModal(documentDetails);
    } else {
      return await firstValueFrom(
        this.showDynamicViewWithActions(`${documentDetails.id}`, documentDetails.behaviorInvokers),
      );
    }
  }
}
