import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { firstValueFrom } from 'rxjs';
import { About } from '../models/about.model';

@Injectable()
export class AboutService {
  private about: About;

  constructor(
    private http: HttpClient,
    @Inject(APP_BASE_HREF) private baseHref: string,
  ) {}

  async loadAbout(): Promise<About> {
    return firstValueFrom(
      this.http.get<About>(`${this.baseHref}shared/assets/config/dynamic/about.json`, {
        headers: CustomHttpHeaders.XNoApiBaseUrlInterceptionHeaders,
      }),
    ).then((data: About) => (this.about = data));
  }

  get(): About {
    return this.about;
  }

  isDemo(): boolean {
    return (
      window.location.hostname.includes('localhost') ||
      window.location.hostname.includes('swcode.io') ||
      window.location.hostname.includes('portal.tie.ch')
    );
  }
}
