import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { firstValueFrom, take } from 'rxjs';

@Injectable()
@UntilDestroy()
export class InactivityService {
  constructor(
    private idle: Idle,
    private clientConfigService: ClientConfigService,
    private authService: AuthService,
    private router: Router,
  ) {}

  async initInactivityObserver(): Promise<void> {
    const isLoggedIn: boolean = await firstValueFrom(this.authService.isLoggedIn());

    if (isLoggedIn && this.isActive()) {
      this.configureInactivityTimeout(this.clientConfigService.get());
      this.subscribeToInactivityTimeout();
    }
  }

  private configureInactivityTimeout(clientConfig: ClientConfig): void {
    this.idle.setIdle(clientConfig.authConfig.inactivityTimeout);
    this.idle.setTimeout(clientConfig.authConfig.inactivityTimeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  private subscribeToInactivityTimeout(): void {
    this.idle.onTimeout
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => this.runTimeoutAction());
    this.idle.watch();
    this.authService.onLogout
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => this.stopInactivityTimeoutObservation());
  }

  private async runTimeoutAction(): Promise<void> {
    await this.router.navigateByUrl('/session-timeout', { replaceUrl: true });
  }

  private stopInactivityTimeoutObservation(): void {
    this.idle.stop();
  }

  isActive(): boolean {
    const clientConfig: ClientConfig = this.clientConfigService.get();
    return clientConfig.authConfig.inactivityTimeout > 0;
  }
}
