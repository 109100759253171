import { Injectable, Injector, runInInjectionContext } from '@angular/core';
import { DynamicDataField, DynamicForm } from '../models/form.model';
import { Invoker, InvokerTypes } from '../models/invoker-body.model';
import {
  FormItem,
  FormatOptionIdentifier,
  MandatoryAttributeType,
  TieFormObject,
} from '../models/sdapi-form-object.model';
import { AttributeNameIdentifier } from '../models/shared.model';
import { FormMapper } from './form.mapper';

/**
 * @deprecated use {@link DynamicFormMapper} instead
 */
@Injectable({
  providedIn: 'root',
})
export class DataFormMapper {
  constructor(private injector: Injector) {}

  public mapDataFormResource(
    resource: TieFormObject,
    activityURL: string,
    isInteractive: boolean = false,
    skipInvokers?: boolean,
  ): DynamicForm {
    const bodyItems: FormItem[] = FormMapper.sortBySequence(resource.showTypes.BODY?.items);

    let invoker: Invoker[];
    if (!skipInvokers) {
      invoker = FormMapper.resolveStepInvoker(
        [InvokerTypes.SAVE, InvokerTypes.SEND, InvokerTypes.SEARCH],
        activityURL,
        resource,
      );
    }

    const body: DynamicDataField[] = this.mapResourceFormItemsToDataFormFields(bodyItems, resource);

    let dynamicForm: DynamicForm;
    runInInjectionContext(this.injector, () => {
      dynamicForm = new DynamicForm({
        activityURL,
        title: resource.windowName,
        body,
        invoker,
        isInteractive,
      });
    });

    return dynamicForm;
  }

  private mapResourceFormItemsToDataFormFields(
    items: FormItem[],
    resource: TieFormObject,
  ): DynamicDataField[] {
    return items.map((item: FormItem) => {
      const {
        displayName: name,
        mandatory,
        hidden,
        readonly,
        attributeName: identifier,
        format,
      } = item;

      const value = FormMapper.resolveTypedValue(
        item,
        FormMapper.getAttributeValuesOfField(resource.attributeValues, identifier),
      );

      const dataField: DynamicDataField = Object.assign(new DynamicDataField(), {
        name,
        value,
        readOnly: mandatory === MandatoryAttributeType.fix || readonly === true,
        required: mandatory === MandatoryAttributeType.yes || mandatory === true,
        hidden: hidden === true,
        identifier: new AttributeNameIdentifier(identifier),
        type: FormMapper.resolveType(item),
        format: format as FormatOptionIdentifier,
      });

      if (item.showTypeGrouping) {
        dataField.fieldGroup = this.mapResourceFormItemsToDataFormFields(
          item.showTypeGrouping.items,
          resource,
        );
      }

      return dataField;
    });
  }
}
