import { APIError } from 'projects/core/src/lib/data/errors.data';
import { TableHeaderItem, TableListItem } from 'projects/core/src/lib/models/dynamic-table.model';
import { FormatOptions } from '../models/form.model';
import { FormItem, FormatOptionIdentifier } from '../models/sdapi-form-object.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { AttributeNameIdentifier } from '../models/shared.model';
import { TableList, TableType } from './../models/dynamic-table.model';
import { TableMapper } from './table.mapper';

export class OrdersResourceMapper {
  private static readonly storageKeyColumnPreferences: string = `orderColumnPreference`;

  public static mapOrderTable(resource: TieTableObjectList): TableList {
    let tableHeader: TableHeaderItem[] = [];
    let tableRows: TableListItem[] = [];

    //TODO: Improve mapping error handling
    try {
      tableHeader = TableMapper.mapTableHeaderResource(resource);
      tableRows = TableMapper.mapTableRowsObjectListResource(resource);
    } catch (error) {
      throw new APIError('Mapping of orders failed in the OrdersResourceMapper.', error);
    }

    return new TableList(tableHeader, tableRows, {
      title: resource.objName,
      storageKey: this.storageKeyColumnPreferences,
      tableType: TableType.order,
    });
  }

  public static mapOrderTypeTable(resource: TieTableObjectList): TableList {
    //TODO: Improve mapping error handling
    try {
      const columnFilter = [0, 4];
      let tableHeader: TableHeaderItem[] = [];
      const ordersAttributeNames: string[] = [...resource.attrNames];
      const showTypeItems: FormItem[] = [...resource.showTypes.BODY.items];
      ordersAttributeNames.forEach((header: string, i: number) => {
        tableHeader.push({
          value: header,
          type: resource.attrTypes[i],
          format: new FormatOptions(resource.attrFormats[i] as FormatOptionIdentifier),
          identifier: new AttributeNameIdentifier(showTypeItems[i].attributeName),
        });
      });
      tableHeader = tableHeader.filter((_, i) => columnFilter.includes(i));
      const tableRows: TableListItem[] = resource.items.map((orderType) => ({
        id: orderType.objId.toString(),
        columns: orderType.attrValues.filter((_, i) => columnFilter.includes(i)),
      }));
      return new TableList(tableHeader, tableRows);
    } catch (error) {
      throw new APIError('Mapping of order types failed in the OrdersResourceMapper.', error);
    }
  }
}
