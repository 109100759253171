import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { SelectionObject } from 'projects/core/src/lib/models/form.model';
import { BaseFieldComponent } from 'projects/shared/src/lib/components/form/base-field/base-field.component';
import { SelectionFieldPopoverComponent } from '../selection-field-popover/selection-field-popover.component';

@Component({
  selector: 'lib-selection-input-field',
  templateUrl: './selection-field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SelectionFieldComponent extends BaseFieldComponent {
  @Output() change: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('selectionInput') selectionInput: ElementRef<HTMLInputElement>;

  containerWidth: number;

  constructor(private popoverController: PopoverController) {
    super();
  }

  get showAsRadio(): boolean {
    const isRadio = this.options.length === 2;
    const optionsRadioLengthLimit = 5;
    const optionsInLengthLimit = this.options.every(
      ({ value }) => value.length <= optionsRadioLengthLimit,
    );

    return isRadio && optionsInLengthLimit && this.isEditableField;
  }

  get showAsSelect(): boolean {
    const hasOptions = this.options.length > 0;
    const isNotRadio = !this.showAsRadio;

    return hasOptions && isNotRadio;
  }

  get options(): SelectionObject[] {
    return this.item.value.options;
  }

  get currentSelectedOptionText(): string {
    return this.currentSelection?.value;
  }

  get currentSelection(): SelectionObject {
    return this.item.value.options.find(({ identifier }) => identifier === this.item.value.value);
  }

  override get placeholderKey(): string {
    return this.isEditableField ? 'shared.forms.please-select' : 'general.not-specified';
  }

  onRadioChange(identifier: string, input: NgModel) {
    this.item.value.value = identifier;
    this.ensureFieldMarkedAsTouched(input);
  }

  async openSelect() {
    if (this.isEditableField) {
      const popover = await this.popoverController.create({
        component: SelectionFieldPopoverComponent,
        translucent: true,
        trigger: 'select-' + this.customHtmlId,
        reference: 'trigger',
        alignment: 'center',
        side: 'bottom',
        animated: true,
        arrow: true,
        id: 'options-popover',
        componentProps: {
          item: this.item,
          selectedOption: this.currentSelection,
        },
      });
      await popover.present();
      await popover.onDidDismiss<SelectionObject>().then((data) => {
        this.setSelection(data.data);
        this.setFocusBackToInput();
      });
    }
  }

  setFocusBackToInput() {
    this.selectionInput.nativeElement.focus();
  }

  setSelection(selectedOption: SelectionObject) {
    if (selectedOption) {
      this.item.value.value = selectedOption.identifier;
    }
  }
}
