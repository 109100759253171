import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { ICalCalendar, ICalCalendarMethod, ICalEventData } from 'ical-generator';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { SDAPIService } from 'projects/core/src/lib/services/sdapi.service';
import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, retry, take } from 'rxjs/operators';
import { AppointmentsResourceMapper } from '../mappers/appointment.mapper';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import {
  AppointmentDetails,
  AppointmentType,
  DateApiRequest,
  TemplateAppointmentsGroup,
  ViewAppointmentsGroup,
} from '../models/appointments.model';
import { DynamicButton } from '../models/dynamic-button.model';
import { DynamicForm } from '../models/form.model';
import { Invoker, InvokerMethods } from '../models/invoker-body.model';
import { Patient } from '../models/patient.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { AboutService } from './about.service';
import { DownloadService } from './download.service';
import { PatientService } from './patient.service';

@Injectable()
export class AppointmentsService {
  constructor(
    private http: HttpClient,
    private patientService: PatientService,
    private sdapiService: SDAPIService,
    private clientConfig: ClientConfigService,
    private aboutService: AboutService,
    private dynamicFormMapper: DynamicFormMapper,
    private translatePipe: TranslatePipe,
    private downloadService: DownloadService,
  ) {}

  getUpcomingAppointments(cache: boolean = true): Observable<AppointmentDetails[]> {
    return this.getAppointments({ from: new Date(), to: null }, cache);
  }

  async getUpcomingAppointmentCount(): Promise<number> {
    return (await firstValueFrom(this.getUpcomingAppointments(false))).length;
  }

  getPastAppointments(cache: boolean = true): Observable<AppointmentDetails[]> {
    return this.getAppointments({ from: null, to: new Date() }, cache);
  }

  async getPastAppointmentCount(): Promise<number> {
    return (await firstValueFrom(this.getPastAppointments(false))).length;
  }

  getAllAppointments(cache: boolean = true): Observable<AppointmentDetails[]> {
    return this.getAppointments({ from: null, to: null }, cache);
  }

  async hasAccessToPatientsAppointments(): Promise<boolean> {
    return firstValueFrom(
      this.sdapiService.checkForInvokerByMethodName(
        'PP_FIND_ALL_APPOINTMENTS_FROM_PAT_W_ID',
        InvokerMethods.objectFindInObjectList,
        CustomHttpHeaders.XNoAlertInterceptorHeaders,
      ),
    );
  }

  public getAppointments(
    dateRequest: DateApiRequest,
    cache: boolean = true,
  ): Observable<AppointmentDetails[]> {
    return this.patientService.getCurrentPatient().pipe(
      concatMap((patient: Patient) =>
        this.findAppointmentsOfPatient(dateRequest, patient.patientID, cache),
      ),
      map(AppointmentsResourceMapper.mapResource),
    );
  }

  public async retrieveUpdatedAppointmentList(
    appointmentId: string,
  ): Promise<AppointmentDetails[]> {
    const appointments: AppointmentDetails[] =
      await this.getAppointmentsWhenCreatedAppointmentPresent(appointmentId);
    return await this.addFakeAppointmentInDemo(appointments, true);
  }

  private findAppointmentsOfPatient(
    dateRequest: DateApiRequest,
    patientID: string,
    cache: boolean = true,
  ): Observable<TieTableObjectList> {
    const dataMap: Map<string, string> = this.getAppointmentsDataMap(dateRequest, patientID);
    const customHeaders: string[] = cache ? [] : [CustomHttpHeaders.XNoCache];

    return this.sdapiService.findDataObjectWithMetaFinder<TieTableObjectList>(
      'PP_FIND_ALL_APPOINTMENTS_FROM_PAT_W_ID',
      InvokerMethods.objectFindInObjectList,
      dataMap,
      CustomHttpHeaders.build(...customHeaders),
    );
  }

  private getAppointmentsDataMap(
    dateRequest: DateApiRequest,
    patientID: string,
  ): Map<string, string> {
    return new Map([
      ['TEMP.TO[BODY,3]', dateRequest.to?.toISOString()],
      ['TEMP.FROM[BODY,2]', dateRequest.from?.toISOString()],
      ['TEMP.PATIENT_ID[BODY,1]', patientID],
    ]);
  }

  public getAppointmentUpdateButtons(appointmentId: number): Observable<DynamicButton[]> {
    return this.sdapiService
      .getInvokerListByMethodName(`${appointmentId}`, InvokerMethods.objectUpdate)
      .pipe(
        map((invokers: Invoker[]) => SDAPIObjectMapper.mapInvokersToDynamicButtons(invokers)),
        catchError(() => of(undefined)),
      );
  }

  public getLinkedTreatmentId(appointmentId: number): Observable<string | undefined> {
    // TODO: Following line prevents fake video call to throw error.
    if (appointmentId === 1) {
      return of(undefined);
    }

    return this.sdapiService
      .getFormByMethodFromObjectMenu(
        InvokerMethods.objectAttributesView,
        `/objects/${appointmentId}`,
        false,
        true,
      )
      .pipe(
        map((form: DynamicForm) => {
          const treatmentIdField = form.getDataFieldByIdentifier('TEMP.FALLID');
          const treatmentId: string | undefined = treatmentIdField?.value?.value;
          return treatmentId;
        }),
      );
  }

  public getAppointmentUpdateForm(invoker: Invoker): Observable<DynamicForm> {
    return this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker).pipe(
      map((response: TieFormObject) => {
        const activityURL: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
        return this.dynamicFormMapper.mapDynamicFormResource(response, activityURL);
      }),
    );
  }

  async getFakeRemoteAppointment(isDoctor: boolean): Promise<AppointmentDetails> {
    const clientConfig: ClientConfig = this.clientConfig.get();
    return {
      startDate: this.roundTimeQuarterHour(new Date()),
      title: clientConfig.fakeAppointmentData.title,
      doctor: isDoctor ? undefined : clientConfig.fakeAppointmentData.doctor,
      patient: isDoctor ? clientConfig.fakeAppointmentData.patient : undefined,
      clinicPhoneNumber: clientConfig.fakeAppointmentData.phoneNumber,
      location: clientConfig.fakeAppointmentData.clinic,
      id: 1,
      type: AppointmentType.REMOTE,
      status: {
        label: 'Akzeptiert',
        hexColor: '#00bb00',
      },
    };
  }

  roundTimeQuarterHour(time: Date): Date {
    const timeToReturn = new Date(time);
    timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
    timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);

    if (timeToReturn <= new Date()) {
      timeToReturn.setMinutes(timeToReturn.getMinutes() + 15);
    }

    return timeToReturn;
  }

  public groupAppointmentsByDateForView(appointments: AppointmentDetails[]): ViewAppointmentsGroup {
    const groupedAppointments: ViewAppointmentsGroup = { past: [], future: [] };

    this.groupAppointments(appointments).forEach((appointmentGroup: TemplateAppointmentsGroup) => {
      if (this.isFutureAppointment(appointmentGroup.date)) {
        groupedAppointments.future.push(appointmentGroup);
      } else {
        groupedAppointments.past.push(appointmentGroup);
      }
    });

    return groupedAppointments;
  }

  public groupAppointments(appointments: AppointmentDetails[]): TemplateAppointmentsGroup[] {
    const appointmentsGrouped: TemplateAppointmentsGroup[] = [];

    const appointmentsMapped: { [key: string]: TemplateAppointmentsGroup } = {};
    appointments.forEach((appointment: AppointmentDetails) => {
      const date = new Date(appointment.startDate).toDateString();
      if (appointmentsMapped[date] == undefined) {
        appointmentsMapped[date] = new TemplateAppointmentsGroup();
        appointmentsMapped[date].date = new Date(appointment.startDate);
        appointmentsMapped[date].appointments = [];
      }
      appointmentsMapped[date].appointments.push(appointment);
    });

    for (const mappedGroupKey in appointmentsMapped) {
      appointmentsMapped[mappedGroupKey].appointments.sort(
        (objA, objB) => objA.startDate.getTime() - objB.startDate.getTime(),
      );
      appointmentsGrouped.push(appointmentsMapped[mappedGroupKey]);
    }

    return appointmentsGrouped.sort((objA, objB) => objA.date.getTime() - objB.date.getTime());
  }

  public isFutureAppointment(date: Date): boolean {
    return date.getTime() >= new Date(new Date().toDateString()).getTime();
  }

  public async addFakeAppointmentInDemo(
    appointments: AppointmentDetails[],
    isDoctor: boolean,
  ): Promise<AppointmentDetails[]> {
    if (this.aboutService.isDemo()) {
      const fakeAppointment: AppointmentDetails = await this.getFakeRemoteAppointment(isDoctor);
      return [...appointments, fakeAppointment];
    }
    return appointments;
  }

  getAppointmentsWhenCreatedAppointmentPresent(
    appointmentId: string,
  ): Promise<AppointmentDetails[]> {
    return new Promise((resolve, reject) =>
      this.getAllAppointments(false)
        .pipe(
          take(1),
          concatMap((appointments: AppointmentDetails[]) =>
            this.checkForPresenceOfCreatedAppointment(appointments, appointmentId),
          ),
          retry({ count: 5, delay: 1500 }),
        )
        .subscribe({
          next: (appointments: AppointmentDetails[]) => resolve(appointments),
          error: (error: Error) => {
            console.error(`Appointment with ObjectID [${appointmentId}] not found.`);
            reject(error);
          },
        }),
    );
  }

  private checkForPresenceOfCreatedAppointment(
    appointments: AppointmentDetails[],
    appointmentId: string,
  ): Observable<AppointmentDetails[]> {
    const createdAppointment: AppointmentDetails = this.retrieveAppointmentById(
      appointments,
      appointmentId,
    );
    if (createdAppointment) {
      return of(appointments);
    } else {
      return throwError(() => new Error());
    }
  }

  public retrieveAppointmentById(
    appointments: AppointmentDetails[],
    appointmentId: string,
  ): AppointmentDetails {
    return appointments.find(
      (appointment: AppointmentDetails) => appointment.id.toString() === appointmentId,
    );
  }

  private getIcsDescription(appointment: AppointmentDetails, videoCallUrl?: string): string {
    const descriptionParts = [];
    const EOL = '\n';
    const videoLinkIsNotEmpty = !!videoCallUrl;
    const appointmentIsRemote = appointment.type === AppointmentType.REMOTE;
    if (videoLinkIsNotEmpty && appointmentIsRemote) {
      const videoCallDescription = this.translatePipe.transform(
        'shared.appointment-list.start-video-call',
      );
      descriptionParts.push(`${videoCallDescription}: ${EOL}${videoCallUrl}`);
    }

    const doctorExists = !!appointment.doctor;
    const clinicPhoneNumberIsNotEmpty = !!appointment.clinicPhoneNumber;

    if (doctorExists || clinicPhoneNumberIsNotEmpty) {
      descriptionParts.push(EOL);
    }

    if (doctorExists) {
      const doctorString = `${appointment.doctor}`;
      descriptionParts.push(doctorString);
    }

    if (clinicPhoneNumberIsNotEmpty) {
      const cleanedTelephonenumber = `${appointment.clinicPhoneNumber}`.replace(/[^0-9+]+/g, '');
      const clinicPhoneNumberLink = `${cleanedTelephonenumber}`;
      descriptionParts.push(clinicPhoneNumberLink);
    }
    return descriptionParts.join(EOL);
  }

  public generateIcsFile(appointment: AppointmentDetails, videoCallUrl?: string): string {
    const dateStringIsNotEmpty = appointment.startDate;
    const startDateIsValid = dayjs(appointment.startDate).isValid();
    if (dateStringIsNotEmpty && startDateIsValid) {
      const iCalCalendar = new ICalCalendar();
      let end;
      const endIsValid = appointment.endDate && dayjs(appointment.endDate).isValid();
      if (endIsValid) {
        end = new Date(appointment.endDate);
      } else {
        end = new Date(appointment.startDate);
        end.setHours(end.getHours() + 1);
      }
      iCalCalendar.method(ICalCalendarMethod.REQUEST);
      iCalCalendar.createEvent({
        id: `${appointment.id}@${window.location.hostname}`,
        summary: `${appointment.title}`,
        description: this.getIcsDescription(appointment, videoCallUrl),
        location: appointment.location,
        start: new Date(appointment.startDate),
        end,
      } as ICalEventData);
      return iCalCalendar.toString();
    }
    return undefined;
  }

  public async downloadIcsFile(icsString: string, appointmentTitle: string): Promise<void> {
    const href = `data:text/calendar;charset=utf-8,${encodeURIComponent(icsString)}`;
    await this.downloadService.handleDownload(`${appointmentTitle}.ics`, href);
  }
}
