export const getReferrerPath = (baseHref: string): string => {
  const pathname = window.location.pathname;
  const currentPathNameEqualsBasePath = pathname === baseHref || pathname + '/' === baseHref;
  const currentPathNameEqualsSessionTimeout = window.location.pathname === '/session-timeout';
  //@deprecated as soon as jar deployment is available on all clients - set the redirect url on the auth-config service and omit
  const isJarDeployment = (window as any).isJarDeployment;

  if (currentPathNameEqualsBasePath || currentPathNameEqualsSessionTimeout) {
    if (isJarDeployment) {
      return 'portal';
    }
    return `${baseHref}portal`;
  } else {
    if (isJarDeployment) {
      return getPathnameWithoutLeadingBaseHref(baseHref) + window.location.search;
    }
    return window.location.pathname + window.location.search;
  }
};

const getPathnameWithoutLeadingBaseHref = (baseHref: string): string => {
  const pathname = window.location.pathname;
  const pathnameStartsWithBaseHref = pathname.startsWith(baseHref);
  if (pathnameStartsWithBaseHref) {
    return pathname.replace(baseHref, '');
  } else {
    return pathname;
  }
};
