<ion-list class="filter-list">
  <ion-item
    class="p"
    [disabled]="!getSubtotal(filter)"
    lines="none"
    *ngFor="let filter of filtersPreset">
    <ion-checkbox
      (ionChange)="toggleFilter(filter)"
      color="primary"
      labelPlacement="end"
      horizontal="start"
      [checked]="filter.selected"
      justify="start">
      <div class="checkbox-label">
        <fa-icon [icon]="filter.icon" [class]="filter.colorClass"></fa-icon>
        <span class="filter-name">{{
          'shared.documents.filter-names.' + filter.name | translate
        }}</span>
      </div>
    </ion-checkbox>
    <span class="filter-subtotal"> {{ getSubtotal(filter) }}</span>
  </ion-item>
</ion-list>
