import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, concatMap, map } from 'rxjs';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { Invoker, InvokerBody } from '../models/invoker-body.model';
import { InvokerMethodCollection } from '../models/modal-action.model';
import { SDAPIMenuObject } from '../models/sdapi-object.model';
import { SDAPIService } from './sdapi.service';

@Injectable()
export class BehaviorInvokerService {
  constructor(
    private http: HttpClient,
    private sdapiService: SDAPIService,
  ) {}

  public fetchInvokerUsingBehaviorInvokerDetails(
    objectName: number,
    behaviorInvokers: InvokerBody[],
  ): Observable<Invoker> {
    return this.http
      .get<SDAPIMenuObject>(`/objects/${objectName}`)
      .pipe(
        map(
          (response: SDAPIMenuObject): Invoker =>
            this.retrieveInvokerUsingBehaviorInvokerDetails(response, behaviorInvokers),
        ),
      );
  }

  public retrieveInvokerUsingBehaviorInvokerDetails(
    response: SDAPIMenuObject,
    behaviorInvokers: InvokerBody[],
  ): Invoker {
    const invokerBody: InvokerBody = SDAPIObjectMapper.getInvokerBodyByBehaviorInvokerNameAndId(
      behaviorInvokers,
      response,
    );
    return this.sdapiService.getConstructedInvoker(invokerBody);
  }

  public retrievePrimaryAndActionInvokers(
    id: string,
    behaviorInvokers: InvokerBody[],
    fallbackMethods?: InvokerMethodCollection,
  ): Observable<[Invoker, Invoker[]]> {
    if (behaviorInvokers?.length) {
      return this.tryFetchInvokersUsingBehaviorDetails(id, behaviorInvokers, fallbackMethods);
    } else {
      return this.fetchInvokersUsingFallbackMethods(id, fallbackMethods);
    }
  }

  private tryFetchInvokersUsingBehaviorDetails(
    id: string,
    behaviorInvokers: InvokerBody[],
    fallbackMethods?: InvokerMethodCollection,
  ): Observable<[Invoker, Invoker[]]> {
    return this.fetchInvokersUsingBehaviorDetails(id, behaviorInvokers).pipe(
      catchError((error) => {
        console.warn(error);
        return this.fetchInvokersUsingFallbackMethods(id, fallbackMethods);
      }),
    );
  }

  private fetchInvokersUsingBehaviorDetails(
    id: string,
    behaviorInvokers: InvokerBody[],
  ): Observable<[Invoker, Invoker[]]> {
    return this.fetchInvokerUsingBehaviorInvokerDetails(Number(id), behaviorInvokers).pipe(
      concatMap((primaryInvoker: Invoker) =>
        this.sdapiService.fetchPrimaryAndActionInvokers(id, primaryInvoker),
      ),
    );
  }

  private fetchInvokersUsingFallbackMethods(
    id: string,
    fallbackMethods: InvokerMethodCollection,
  ): Observable<[Invoker, Invoker[]]> {
    return this.sdapiService
      .getPreferredInvokerOrDefault(Number(id), fallbackMethods.preferred, fallbackMethods.default)
      .pipe(
        concatMap((primaryInvoker: Invoker) =>
          this.sdapiService.fetchPrimaryAndActionInvokers(id, primaryInvoker),
        ),
      );
  }
}
