import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';
import { DataType, DynamicDataField, Limiters } from '../models/form.model';
import { CheckerType, TagPreferences } from '../models/sdapi-form-object.model';

export const VALID_MAIL_PATTERN =
  /^[a-zA-Z0-9][\w.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z.]*[a-zA-Z]$/;

export const getRelativeFromNowDate = (
  amount: number,
  dayjsUnit: dayjs.ManipulateType,
  dayJsManipulateTypeString: string,
): dayjs.Dayjs => {
  try {
    const relativeFromNowDate = dayjs().add(amount, dayjsUnit as dayjs.ManipulateType);
    const relativeFromNowDateIsInvalid = !relativeFromNowDate.isValid();
    if (relativeFromNowDateIsInvalid) {
      console.error('relative from now tagpref is malformed', dayJsManipulateTypeString);
      return null;
    }
    return relativeFromNowDate;
  } catch (error) {
    console.error('relative from now tagpref is malformed', dayJsManipulateTypeString);
    return null;
  }
};

export const parseDateRelativeFromNow = (dayJsManipulateTypeString: string): dayjs.Dayjs | null => {
  if (!dayJsManipulateTypeString) {
    return null;
  }
  const numberPrefix = dayJsManipulateTypeString.includes('-') ? '-' : '';
  const amount = parseInt(numberPrefix + dayJsManipulateTypeString.match(/\d+/)?.[0], 10);
  const amountIsInvalid = isNaN(amount);
  const dayjsUnitTypes = ['day', 'week', 'month', 'year'];
  const dayjsUnit = dayjsUnitTypes.find((type) => dayJsManipulateTypeString.includes(type));
  if (!amountIsInvalid && dayjsUnit) {
    return getRelativeFromNowDate(
      amount,
      dayjsUnit as dayjs.ManipulateType,
      dayJsManipulateTypeString,
    );
  } else {
    console.error('relative from now tagpref is malformed', dayJsManipulateTypeString);
    return null;
  }
};

const parseDate = (dateString: string): dayjs.Dayjs | null => {
  if (!dateString) {
    return null;
  }
  const parsedDate = dayjs(dateString);
  const isDateValid = parsedDate.isValid();
  if (isDateValid) {
    return parsedDate;
  } else {
    return null;
  }
};

const getMinDate = (tagPreferences: TagPreferences): dayjs.Dayjs | null => {
  const minRelativeDate = parseDate(tagPreferences.minrel);
  const minDate = parseDate(tagPreferences.min);
  const getMinimumDateFunction = (minimumValue: dayjs.Dayjs, currentValue: dayjs.Dayjs) => {
    if (currentValue && (!minimumValue || currentValue.isAfter(minimumValue))) {
      return currentValue;
    }
    return minimumValue;
  };
  return [minRelativeDate, minDate].reduce(getMinimumDateFunction, null);
};

const getMaxDate = (tagPreferences: TagPreferences): dayjs.Dayjs | null => {
  const maxRelativeDate = parseDate(tagPreferences.maxrel);
  const maxDate = parseDate(tagPreferences.max);
  const getMaximumDateFunction = (maximumValue: dayjs.Dayjs, currentValue: dayjs.Dayjs) => {
    if (currentValue && (!maximumValue || currentValue.isBefore(maximumValue))) {
      return currentValue;
    }
    return maximumValue;
  };
  return [maxRelativeDate, maxDate].reduce(getMaximumDateFunction, null);
};

const getValidationErrorForCaseThatMinValueAndMaxValueAreViolated = (
  minDate: dayjs.Dayjs,
  maxDate: dayjs.Dayjs,
): ValidationErrors => ({
  'shared.forms.date-too-early-late': {
    key: 'shared.forms.date-too-early-late',
    params: {
      minDate: minDate.format('DD.MM.YYYY'),
      maxDate: maxDate.format('DD.MM.YYYY'),
    },
  },
});

const getValidationErrorForCaseThatMinValueIsViolated = (
  minDate: dayjs.Dayjs,
): ValidationErrors => ({
  'shared.forms.date-too-early': {
    key: 'shared.forms.date-too-early',
    params: {
      date: minDate.format('DD.MM.YYYY'),
    },
  },
});

const getValidationErrorForCaseThatMaxValueIsViolated = (
  maxDate: dayjs.Dayjs,
): ValidationErrors => ({
  'shared.forms.date-too-late': {
    key: 'shared.forms.date-too-late',
    params: {
      date: maxDate.format('DD.MM.YYYY'),
    },
  },
});

const getMinMaxDateValidatorFn = (tagPreferences: Limiters): ValidatorFn => {
  const minDate = getMinDate(tagPreferences);
  const maxDate = getMaxDate(tagPreferences);

  return (control: AbstractControl): ValidationErrors | null => {
    const currentDateValue = dayjs(control.value);
    const currentDateValueIsBelowMinDate = minDate && currentDateValue.isBefore(minDate);
    const currentDateValueIsOverMaxDate = maxDate && currentDateValue.isAfter(maxDate);
    const hasMinAndMaxDate = minDate && maxDate;
    if (hasMinAndMaxDate && (currentDateValueIsBelowMinDate || currentDateValueIsOverMaxDate)) {
      return getValidationErrorForCaseThatMinValueAndMaxValueAreViolated(minDate, maxDate);
    } else if (currentDateValueIsBelowMinDate) {
      return getValidationErrorForCaseThatMinValueIsViolated(minDate);
    } else if (currentDateValueIsOverMaxDate) {
      return getValidationErrorForCaseThatMaxValueIsViolated(maxDate);
    }
    return null;
  };
};

const getValidDateValidatorFn =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const date = dayjs(control.value);
    const dateStringIsNotEmpty = !!control.value;
    const dateIsInvalid = dateStringIsNotEmpty && !date.isValid();
    if (dateStringIsNotEmpty && dateIsInvalid) {
      return {
        'shared.forms.invalid-input': {
          key: 'shared.forms.invalid-input',
        },
      };
    }
    return null;
  };

const getRequiredValidatorFn =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isFalsyValue = (!value && value !== 0) || value === '' || value === 'false';
    if (isFalsyValue) {
      return {
        'shared.forms.input-required': {
          key: 'shared.forms.input-required',
        },
      };
    }
    return null;
  };

const getMaxTextLengthValidatorFn =
  (tagPreferences: Limiters): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const textValue = control.value;
    const maxLength = parseFloat(tagPreferences.max);
    const textValueIsLongerThanMaxLength = textValue && `${textValue}`.length > maxLength;
    if (textValueIsLongerThanMaxLength) {
      return {
        'shared.forms.the-max-input-length-is': {
          key: 'shared.forms.the-max-input-length-is',
          params: { maxLength: tagPreferences.max },
        },
      };
    }
    return null;
  };

const getAllowedFileExtensionsValidatorFn =
  (allowedFileExtensions: string[]): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    if (!allowedFileExtensions?.filter((x) => x).length) {
      return null;
    }
    const fileExtension = control.value.split('.').pop();
    const valueIsNotEmpty = !!control.value;
    const fileExtensionIsInvalid =
      valueIsNotEmpty &&
      !allowedFileExtensions
        .map((a) => a?.toLocaleLowerCase())
        .includes(fileExtension?.toLowerCase());
    if (fileExtensionIsInvalid) {
      return {
        'shared.forms.allowed-file-extensions': {
          key: 'shared.forms.allowed-file-extensions',
          params: { allowedFileExtensions: allowedFileExtensions.join(', ') },
        },
      };
    }
    return null;
  };

const getOnlyIntegersAndFloatsValidatorFn =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const hasValue = control.value !== null && control.value !== undefined;
    /* regex is used here because parseFloat is allowing exponentials (e) */
    const valueIsNumber = hasValue && !/^-?\d*\.?\d*$/.test(control.value);
    if (hasValue && valueIsNumber) {
      return {
        'shared.forms.invalid-input-numbers-only': {
          key: 'shared.forms.invalid-input-numbers-only',
        },
      };
    }
    return null;
  };

const getMinNumberValidationError = (
  control: AbstractControl,
  tagPreferences: Limiters,
): ValidationErrors => {
  const hasValue = control.value !== null && control.value !== undefined;
  if (!hasValue) {
    return null;
  }
  const numberValue = control.value;
  const valueIsNumber = !isNaN(parseFloat(numberValue));
  const minFromTagPreferences = parseFloat(`${tagPreferences.min}`);
  const minFromTagPreferencesIsNumber = !isNaN(minFromTagPreferences);
  if (!valueIsNumber || !minFromTagPreferencesIsNumber) {
    return null;
  }
  const numberIsBelowMinFromTagPreferences = numberValue < minFromTagPreferences;
  if (numberIsBelowMinFromTagPreferences) {
    return {
      'shared.forms.number-min-range': {
        key: 'shared.forms.number-min-range',
        params: { min: tagPreferences.min },
      },
    };
  } else {
    return null;
  }
};

const getMaxNumberValidationError = (
  control: AbstractControl,
  tagPreferences: Limiters,
): ValidationErrors => {
  const hasValue = control.value !== null && control.value !== undefined;
  if (!hasValue) {
    return null;
  }
  const numberValue = control.value;
  const valueIsNumber = !isNaN(parseFloat(numberValue));
  const maxFromTagPreferences = parseFloat(`${tagPreferences.max}`);
  const maxFromTagPreferencesIsNumber = !isNaN(maxFromTagPreferences);
  if (!valueIsNumber || !maxFromTagPreferencesIsNumber) {
    return null;
  }
  const numberIsAboveMaxFromTagPreferences = numberValue > maxFromTagPreferences;
  if (numberIsAboveMaxFromTagPreferences) {
    return {
      'shared.forms.number-max-range': {
        key: 'shared.forms.number-max-range',
        params: { max: tagPreferences.max },
      },
    };
  } else {
    return null;
  }
};

const getNumberMinMaxValidatorFn =
  (tagPreferences: Limiters): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const minNumberValidator = getMinNumberValidationError(control, tagPreferences);
    if (minNumberValidator) {
      return minNumberValidator;
    }
    const maxNumberValidator = getMaxNumberValidationError(control, tagPreferences);
    if (maxNumberValidator) {
      return maxNumberValidator;
    }
    return null;
  };

const checkEmailPatternFn =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    const emailIsValid = VALID_MAIL_PATTERN.test(email);
    if (email && !emailIsValid) {
      return {
        'shared.forms.invalid-email-input': {
          key: 'shared.forms.invalid-email-input',
        },
      };
    }
    return null;
  };

const existDateLimiters = (dynamicFormItem: DynamicDataField): boolean =>
  !!(
    dynamicFormItem.value.limiters?.minrel ||
    dynamicFormItem.value.limiters?.maxrel ||
    dynamicFormItem.value.limiters?.min ||
    dynamicFormItem.value.limiters?.max
  );

const getDateValidators = (dynamicFormItem: DynamicDataField): ValidatorFn[] => {
  const validators: ValidatorFn[] = [];
  if (existDateLimiters(dynamicFormItem)) {
    validators.push(getMinMaxDateValidatorFn(dynamicFormItem.value.limiters));
  }
  if (dynamicFormItem.required) {
    validators.push(getRequiredValidatorFn());
  }
  validators.push(getValidDateValidatorFn());
  return validators;
};

const getFileValidators = (dynamicFormItem: DynamicDataField): ValidatorFn[] => {
  const validators: ValidatorFn[] = [];
  const hasFileTypeData = dynamicFormItem.fileTypeData && dynamicFormItem.fileTypeData.length > 0;
  if (hasFileTypeData) {
    const allowedFileExtensions = dynamicFormItem.fileTypeData
      ?.map((fileType) => (fileType.extension || '').replace(/\./g, ''))
      .join(',')
      .split(',');
    if (allowedFileExtensions?.length) {
      validators.push(getAllowedFileExtensionsValidatorFn(allowedFileExtensions));
    }
  }
  return validators;
};

const getNumberValidators = (dynamicFormItem: DynamicDataField): ValidatorFn[] => {
  const validators: ValidatorFn[] = [];
  validators.push(getOnlyIntegersAndFloatsValidatorFn());
  if (dynamicFormItem.value.limiters?.min || dynamicFormItem.value.limiters?.max) {
    if (dynamicFormItem.checker == CheckerType.length) {
      validators.push(getMaxTextLengthValidatorFn(dynamicFormItem.value.limiters));
    } else {
      validators.push(getNumberMinMaxValidatorFn(dynamicFormItem.value.limiters));
    }
  }
  return validators;
};

const autocompleteMustBeFilledWithPredefinedOptionsFn = (
  dynamicFormItem: DynamicDataField,
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const hasValue = !!value;
    const hasAutocompleteOptions = dynamicFormItem.value.options;
    const valueIsNotInOptions =
      hasAutocompleteOptions &&
      hasAutocompleteOptions.findIndex((x) => x.identifier === value) == -1;
    if (hasValue && valueIsNotInOptions) {
      return {
        'shared.forms.field-must-be-filled-with-one-of-the-predefined-options': {
          key: 'shared.forms.field-must-be-filled-with-one-of-the-predefined-options',
        },
      };
    }
    return null;
  };
};

const getAutocompleteValidators = (dynamicFormItem: DynamicDataField): ValidatorFn[] => {
  const validators = [];
  if (dynamicFormItem.value.autocompleteOptions?.popupMandatory) {
    validators.push(autocompleteMustBeFilledWithPredefinedOptionsFn(dynamicFormItem));
  }
  return validators;
};

const getTextValidators = (dynamicFormItem: DynamicDataField): ValidatorFn[] => {
  const validators: ValidatorFn[] = [];
  if (dynamicFormItem.value.limiters?.max) {
    validators.push(getMaxTextLengthValidatorFn(dynamicFormItem.value.limiters));
  }
  if (dynamicFormItem.checker == CheckerType.checkmail) {
    validators.push(checkEmailPatternFn());
  }
  return validators;
};

export const generateValidators = (dynamicFormItem: DynamicDataField): ValidatorFn[] => {
  const validators: ValidatorFn[] = [];
  if (dynamicFormItem.required) {
    validators.push(getRequiredValidatorFn());
  }
  switch (dynamicFormItem.type) {
    case DataType.date: {
      validators.push(...getDateValidators(dynamicFormItem));
      break;
    }
    case DataType.text: {
      validators.push(...getTextValidators(dynamicFormItem));
      break;
    }
    case DataType.textArea: {
      validators.push(...getTextValidators(dynamicFormItem));
      break;
    }
    case DataType.file: {
      validators.push(...getFileValidators(dynamicFormItem));
      break;
    }
    case DataType.number: {
      validators.push(...getNumberValidators(dynamicFormItem));
      break;
    }
    case DataType.autocomplete: {
      validators.push(...getAutocompleteValidators(dynamicFormItem));
    }
  }

  return validators;
};
