<lib-modal-header
  [titleIcon]="icons.fingerprint"
  [title]="'shared.autoident.verification' | translate"
  [cancelText]="'shared.autoident.verification' | translate"
  [style]="modalHeaderStyle"></lib-modal-header>

<div class="content-container">
  <div
    class="content"
    [innerHTML]="'shared.autoident.verification-info-text' | translate | safeHtml"></div>
  <div class="button-container">
    <ion-button color="primary" expand="block" (click)="proceedWithIdentification()">
      {{ 'shared.autoident.start-verification' | translate }}
      <fa-icon [icon]="icons.arrowRight" slot="end"></fa-icon>
    </ion-button>
  </div>
</div>
