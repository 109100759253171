import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentFormat, ViewDocumentDetails } from 'projects/core/src/lib/models/documents.model';

@Component({
  selector: 'lib-document-details-viewer',
  templateUrl: './document-details-viewer.component.html',
  styleUrls: ['./document-details-viewer.component.css'],
})
export class DocumentDetailsViewerComponent {
  @Input() viewDocument: ViewDocumentDetails;
  @Output() loading = new EventEmitter<boolean>();
  @Output() commitTaskButtonsVisibility = new EventEmitter<boolean>();
  documentFormatEnum: typeof DocumentFormat = DocumentFormat;
  isLoading: boolean;

  loadingSkeleton(value: boolean) {
    this.loading.emit(value);
  }

  commitButtonsVisibility(value: boolean) {
    this.commitTaskButtonsVisibility.emit(value);
  }
}
