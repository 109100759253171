import { Component } from '@angular/core';
import { faCircleInfo, faHourglass, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';
import { AutoIdentService } from 'projects/core/src/lib/services/auto-ident.service';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import { tieUserCircleQuestionmark } from 'projects/theme/src/lib/icons/tie/user-circle-questionmark';

@Component({
  selector: 'lib-account-information-auto-ident-section',
  templateUrl: './auto-ident-section.component.html',
  styleUrls: ['./auto-ident-section.component.scss'],
})
export class AutoIdentSectionComponent {
  clientConfig: ClientConfig;

  readonly icons = {
    circleInfo: faCircleInfo,
    userCircleQuestionmark: tieUserCircleQuestionmark,
    hourGlass: faHourglass,
    userCheck: faUserCheck,
  };

  constructor(
    private popupService: PopupService,
    public autoIdentService: AutoIdentService,
  ) {}

  get autoIdentInvoker(): Invoker {
    return this.autoIdentService.autoIdentInvoker;
  }

  async openAutoIdentInformationModal() {
    await this.popupService.showAutoIdentInformationModal(this.autoIdentInvoker);
  }
}
