import { Injectable } from '@angular/core';
import { DynamicDataField, PrefillAttributeNamesMap } from '../models/form.model';
import {
  AttributeValueCollection,
  FormItem,
  ShowType,
  TabItem,
  TieFormObject,
} from '../models/sdapi-form-object.model';
import { SystemAttributeName } from '../models/shared.model';
import { DynamicFormMapper } from './dynamic-form.mapper';
import { FormMapper } from './form.mapper';

@Injectable({
  providedIn: 'root',
})
export class TabFormMapper {
  public static mapTabItems(
    resource: TieFormObject,
    attributeValues: AttributeValueCollection,
  ): DynamicDataField[] {
    const tabItems: TabItem[] = resource.showTypes.TAB.tabs;

    tabItems.forEach((tab: TabItem) => {
      tab.showTypes[0].items = FormMapper.sortBySequence(tab.showTypes[0].items);
    });

    return tabItems.flatMap((tab: TabItem) => {
      const mappedItems: DynamicDataField[] =
        DynamicFormMapper.mapResourceFormItemsToDynamicFormFields(
          tab.showTypes[0].items,
          attributeValues,
        );
      return DynamicFormMapper.createFieldGroup(mappedItems, tab.title);
    });
  }

  public mapAttributeNamesValueMapOfDynamicFormTabResource(
    resource: TieFormObject,
  ): PrefillAttributeNamesMap {
    return new Map(
      resource.showTypes.TAB?.tabs
        .map((tab: TabItem) => tab.showTypes.map((showType: ShowType) => showType.items).flat())
        .flat()
        .reduce((result, item: FormItem) => {
          if (item.systemAttributeName) {
            const itemSystemAttribute = new SystemAttributeName(item.systemAttributeName);
            result.push([
              itemSystemAttribute.normalizedValue,
              resource.attributeValues[item.attributeName],
            ]);
          }
          return result;
        }, []),
    );
  }
}
