import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface MenuItem {
  url: string;
  title: string;
  idSuffix: string;
  icon: IconProp;
  visibility: boolean;
  target: 'regular' | 'iframe' | 'tab';
  type?: MenuItemType;
  directive?: string;
}

export enum MenuItemType {
  main,
  secondary,
  external,
}
