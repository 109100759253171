import { Component } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { IconDefinition, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { BaseFieldComponent } from 'projects/shared/src/lib/components/form/base-field/base-field.component';

@Component({
  selector: 'lib-checkbox-input-field',
  templateUrl: './checkbox-field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrls: ['./checkbox-field.component.scss'],
})
export class CheckboxFieldComponent extends BaseFieldComponent {
  readonly checkboxIcons = {
    square: faSquare,
    squareCheck: faSquareCheck,
  };

  get checkboxIcon(): IconDefinition {
    return this.isValueTrue ? this.checkboxIcons.squareCheck : this.checkboxIcons.square;
  }

  toggleCheckbox(input): void {
    if (this.isEditableField) {
      this.toggleInputValue();
      this.ensureFieldMarkedAsTouched(input);
    }
  }

  toggleInputValue(): void {
    this.item.value.value = this.isValueTrue ? 'false' : 'true';
  }

  get isValueTrue(): boolean {
    return this.item.value.value === 'true';
  }

  get isChecked(): boolean {
    return this.isValueTrue;
  }
}
