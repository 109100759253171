import { Component, Input } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BookingQueue, StepTypes } from 'projects/core/src/lib/models/appointment-booking.model';
import { DataType } from 'projects/core/src/lib/models/form.model';
import { SharedBookingData } from '../booking-modal/booking-modal.component';

@UntilDestroy()
@Component({
  selector: 'booking-modal-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInput {
  @Input() bookingQueue: BookingQueue<SharedBookingData>;
  DataType: typeof DataType = DataType;
  StepTypes: typeof StepTypes = StepTypes;

  readonly ICONS = {
    info: faInfoCircle,
  };

  async chooseAppointment(id: string) {
    this.injectAppointmentIdToPopupField(id);
    this.bookingQueue.next();
  }

  private injectAppointmentIdToPopupField(id: string) {
    this.bookingQueue.sharedData.bookingForm
      .getAllFieldItems()
      .find((item) => item.type === DataType.popup).value.value = id;
  }
}
