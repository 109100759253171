import { ImprintService } from 'projects/core/src/lib/services/imprint.service';
import { Component, Input, OnInit } from '@angular/core';
import { Imprint } from 'projects/core/src/lib/models/imprint.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lib-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
@UntilDestroy()
export class ImprintComponent implements OnInit {
  @Input() containerize: boolean;
  imprint: Imprint;
  isLoading: boolean;

  constructor(private imprintService: ImprintService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.imprintService
      .getImprint()
      .pipe(untilDestroyed(this), take(1))
      .subscribe((imprint: Imprint) => (this.imprint = imprint))
      .add(() => {
        this.isLoading = false;
      });
  }

  getMaxRowAmount(): number {
    const offsetHeader = 191;
    const rowHeight = 39;
    return Math.ceil((window.innerHeight - offsetHeader) / rowHeight);
  }

  generateSkeletonItems(): Array<number> {
    return Array(this.getMaxRowAmount()).fill(0);
  }
}
