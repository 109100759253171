import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { firstValueFrom, Observable, of, throwError, zip } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { DocumentsResourceMapper } from '../mappers/document.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { DocumentDetails, ViewDocumentDetails } from '../models/documents.model';
import { Invoker, InvokerBody, InvokerMethods } from '../models/invoker-body.model';
import { InvokerMethodCollection } from '../models/modal-action.model';
import {
  ObjectType,
  SDAPIObject,
  SDAPIResponseObject,
  URLView,
} from '../models/sdapi-object.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { BehaviorInvokerService } from './behaviour-invoker.service';
import { PatientService } from './patient.service';
import { SDAPIService } from './sdapi.service';

@UntilDestroy()
@Injectable()
export class DocumentsService {
  constructor(
    private http: HttpClient,
    private patientService: PatientService,
    private sdapiService: SDAPIService,
    private behaviorInvokerService: BehaviorInvokerService,
  ) {}

  public getDocumentsList(filterTranslations?: Object): Observable<DocumentDetails[]> {
    return this.patientService.getCurrentPatient().pipe(
      mergeMap((currentPatient) =>
        this.findDocumentDetails<TieTableObjectList>(currentPatient.patientID).pipe(
          map((documentsList: TieTableObjectList) => {
            const documents = DocumentsResourceMapper.mapDocumentResourceDetails(
              documentsList,
              filterTranslations,
            );
            return documents;
          }),
          catchError((error) => {
            console.error(error);
            return throwError(() => error);
          }),
        ),
      ),
    );
  }

  async getDocumentCount(): Promise<number> {
    return (await firstValueFrom(this.getDocumentsList())).length;
  }

  public findDocumentDetails<TieTableObject>(patientID: string): Observable<TieTableObject> {
    const dataMap: Map<string, string> = new Map([['TEMP.PATIENT_ID[BODY,1]', patientID]]);

    return this.sdapiService.findDataObjectWithMetaFinder<TieTableObject>(
      'PP_FIND_ALL_DOCUMENTS_FROM_PAT_W_ID',
      InvokerMethods.objectFindInObjectList,
      dataMap,
    );
  }

  public retrievePrimaryInvokerAndUpdateDocumentDetails(
    documentDetails: DocumentDetails,
  ): Observable<Invoker> {
    return this.fetchDocumentInvokers(documentDetails).pipe(
      concatMap(([primaryInvoker, secondaryInvokers]) =>
        zip(
          this.http.put<URLView>(primaryInvoker.activityURL, primaryInvoker.invoker),
          of(primaryInvoker),
          of(secondaryInvokers),
        ),
      ),
      map(([response, primaryInvoker, secondaryInvokers]) => {
        this.updateDocumentDetails(documentDetails, response, primaryInvoker, secondaryInvokers);
        return primaryInvoker;
      }),
    );
  }

  private updateDocumentDetails(
    documentDetails: DocumentDetails,
    response: URLView,
    primaryInvoker: Invoker,
    secondaryInvokers: Invoker[],
  ): void {
    if (this.isViewOrOpenUrlInvokerMethod(primaryInvoker.invoker)) {
      const url: string = this.getUrlFromResponseObject(response);
      this.resolveDocumentLinkDetails(primaryInvoker, documentDetails, url);
    }
    documentDetails.actionButtons =
      SDAPIObjectMapper.mapInvokersToDynamicButtons(secondaryInvokers);
  }

  private resolveDocumentLinkDetails(
    invoker: Invoker,
    documentDetails: ViewDocumentDetails,
    url: string,
  ): void {
    if (invoker.invoker.methodName === InvokerMethods.objectView) {
      documentDetails.viewLink = url;
    } else if (invoker.invoker.methodName === InvokerMethods.objectOpenUrl) {
      documentDetails.externalOpenLink = url;
    }
  }

  private fetchDocumentInvokers(
    documentDetails: DocumentDetails,
  ): Observable<[Invoker, Invoker[]]> {
    return this.behaviorInvokerService.retrievePrimaryAndActionInvokers(
      `${documentDetails.id}`,
      documentDetails.behaviorInvokers,
      this.previewFallbackMethods,
    );
  }

  private get previewFallbackMethods(): InvokerMethodCollection {
    return {
      preferred: InvokerMethods.objectView,
      default: InvokerMethods.objectOpenUrl,
    };
  }

  public getDocumentsViewLinkWithInvoker(invoker: Invoker): Observable<string> {
    return this.http
      .put<SDAPIResponseObject>(invoker.activityURL, invoker.invoker)
      .pipe(map((response: SDAPIResponseObject) => this.getUrlFromResponseObject(response)));
  }

  private getUrlFromResponseObject(response: SDAPIResponseObject | URLView): string {
    if (response.t === ObjectType.bulkServerResponse) {
      const urlView = response.bulk.find(
        (bulk: SDAPIObject<ObjectType>) => bulk.t === ObjectType.urlView,
      ) as URLView;
      return urlView.url;
    }
    return response.url;
  }

  assemblePdfViewerObject(url: string): Observable<string> {
    return of(url);
  }

  insertBlob(blob: Blob, target: ViewDocumentDetails): ViewDocumentDetails {
    target.blob = blob;
    target.downloadPending = false;
    return target;
  }

  checkForBigFileSize(documentDetails: ViewDocumentDetails): boolean {
    const fileSizeThreshold = 50000000;
    return documentDetails.size > fileSizeThreshold;
  }

  public getDocumentsDataType(document: DocumentDetails): Observable<DocumentDetails> {
    if (document.externalOpenLink) {
      DocumentsResourceMapper.mapFileType(document);
      return of(document);
    }
    return this.http
      .head(document.viewLink, {
        observe: 'response',
        headers: CustomHttpHeaders.XNoAlertInterceptorHeaders,
      })
      .pipe(
        map((header: HttpResponse<Object>) =>
          DocumentsResourceMapper.addDocumentDetailsFromResponseHeader(document, header),
        ),
        catchError((error) => {
          console.warn(`Could not determine content type of document ${document.id}`, error);
          return of(DocumentsResourceMapper.addFallbackDocumentDetails(document));
        }),
      );
  }

  public getDocumentDetails(
    invoker: Invoker,
    actionInvokers?: Invoker[],
  ): Observable<DocumentDetails> {
    return this.getDocumentsViewLinkWithInvoker(invoker).pipe(
      map((documentViewLink: string) =>
        this.constructDocumentDetails(invoker, documentViewLink, actionInvokers),
      ),
      mergeMap((documentDetails: DocumentDetails) => this.getDocumentsDataType(documentDetails)),
    );
  }

  private constructDocumentDetails(
    invoker: Invoker,
    documentViewLink: string,
    actionInvokers?: Invoker[],
  ): DocumentDetails {
    const documentDetails: DocumentDetails = new DocumentDetails();
    documentDetails.id = invoker.invoker.objId;
    documentDetails.name = invoker.invoker.presentation.label;
    documentDetails.viewLink = documentViewLink;
    documentDetails.downloadLink = documentViewLink;
    if (actionInvokers) {
      documentDetails.actionButtons = SDAPIObjectMapper.mapInvokersToDynamicButtons(actionInvokers);
    }
    return documentDetails;
  }

  public isViewOrOpenUrlInvokerMethod(invokerBody: InvokerBody): boolean {
    return (
      invokerBody.methodName === InvokerMethods.objectView ||
      invokerBody.methodName === InvokerMethods.objectOpenUrl
    );
  }
}
