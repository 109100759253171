import { AlertMessage } from '../models/alert.model';

export class AlertMessagesData {
  static readonly header = 'core.alert.header';
  static readonly message = 'core.alert.message';

  static readonly orderTypeIsMissingPatientRelation: AlertMessage = {
    header: `${this.header}.error`,
    message: `${this.message}.order-type-missing-patient-relation`,
  };

  static readonly cancelTask: AlertMessage = {
    header: `${this.header}.info`,
    message: `${this.message}.canceled-task`,
  };

  static readonly waitForTask: AlertMessage = {
    header: `${this.header}.please-wait`,
    message: `${this.message}.updating-data-please-wait`,
  };

  static readonly dynamicFormInvokerError: AlertMessage = {
    header: `${this.header}.note`,
    message: `${this.message}.dynamic-form-unsupported`,
  };

  static readonly appointmentRequestSuccess: AlertMessage = {
    header: `${this.header}.request-successful`,
    message: `${this.message}.receive-answer-soon`,
  };

  static readonly genericErrorAlert: AlertMessage = {
    header: `${this.header}.software-error`,
    message: `${this.message}.program-error-try-again`,
  };

  static readonly treatmentsAlert: AlertMessage = {
    header: `${this.header}.an-error-occurred`,
    message: `${this.message}.treatments-error`,
  };

  static readonly formUpdateSuccess: AlertMessage = {
    header: `${this.header}.data-changed`,
    message: `${this.message}.data-update-success`,
  };

  static readonly authAlert: AlertMessage = {
    header: `${this.header}.note`,
    message: `${this.message}.data-access-restricted`,
  };

  static readonly authAlertDocuments: AlertMessage = {
    header: `${this.header}.note`,
    message: `${this.message}.documents-access-restricted`,
  };

  static readonly authAlertTreatments: AlertMessage = {
    header: `${this.header}.note`,
    message: `${this.message}.treatment-access-restricted`,
  };

  static readonly authAlertAppointments: AlertMessage = {
    header: `${this.header}.note`,
    message: `${this.message}.appointments-access-restricted`,
  };

  static readonly authAlertProfile: AlertMessage = {
    header: `${this.header}.note`,
    message: `${this.message}.profile-data-access-restricted`,
  };

  static readonly authAlertPatient: AlertMessage = {
    header: `${this.header}.note`,
    message: `${this.message}.patient-data-access-restricted`,
  };

  static readonly appUpdateAlert: AlertMessage = {
    header: `${this.header}.update-available`,
    message: `${this.message}.app-update-available`,
  };

  static readonly appUpdateError: AlertMessage = {
    header: `${this.header}.an-error-occurred`,
    message: `${this.message}.app-update-error`,
  };

  static readonly profileDataAlert: AlertMessage = {
    header: `${this.header}.software-error`,
    message: `${this.message}.profile-data-error`,
  };

  static readonly invalidClientConfigAlert: AlertMessage = {
    header: 'Config File Error',
    message:
      'The client configuration could not be loaded or interpreted correctly. Please check the file.',
  };

  static readonly httpRequestError: AlertMessage = {
    header: `${this.header}.server-error`,
    message: `${this.message}.server-error`,
  };

  static readonly optionsLoadingError: AlertMessage = {
    header: `${this.header}.error`,
    message: `${this.message}.error-on-options-loading-happened`,
  };

  static readonly tosConfirmationError: AlertMessage = {
    header: `${this.header}.an-error-occurred`,
    message: `${this.message}.tos-error`,
  };
}
