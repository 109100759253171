import { IconDefinition } from '@fortawesome/angular-fontawesome/types';

export const tieFileNumber: IconDefinition = {
  prefix: 'tie',
  iconName: 'file-number',
  icon: [
    512,
    512,
    [],
    '',
    'M64 0C28 0 0 29 0 64V448C0 484 28 512 64 512H320C355 512 384 484 384 448V160H256C238 160 224 146 224 128V0H64ZM256 128H384L256 0V128ZM171.008 224.21C179.359 225.602 185.021 233.516 183.63 241.864L178.926 270.071H224.566L230.085 236.826C231.476 228.479 239.395 222.819 247.745 224.21C256.096 225.602 261.759 233.516 260.367 241.864L255.711 270.071H283.643C292.137 270.071 299 276.932 299 285.422C299 293.914 292.137 300.773 283.643 300.773H250.576L240.355 362.178H268.285C276.78 362.178 283.643 369.038 283.643 377.529C283.643 386.021 276.78 392.88 268.285 392.88H235.22L229.701 426.125C228.309 434.472 220.39 440.134 212.04 438.742C203.69 437.351 198.026 429.435 199.419 421.089L204.121 392.929H158.434L152.915 426.172C151.524 434.521 143.605 440.181 135.255 438.79C126.904 437.398 121.241 429.483 122.633 421.136L127.289 392.88H99.3566C90.8634 392.88 84 386.021 84 377.529C84 369.038 90.8634 362.178 99.3566 362.178H132.424L142.645 300.773H114.714C106.22 300.773 99.3566 293.914 99.3566 285.422C99.3566 276.932 106.22 270.071 114.714 270.071H147.78L153.299 236.826C154.691 228.479 162.61 222.819 170.96 224.21H171.008ZM173.791 300.773L163.569 362.178H209.209L219.431 300.773H173.791Z',
  ],
};
