<lib-auto-layout direction="vertical" [gap]="[10]" [padding]="[0, 20]" horizontal="stretch">
  <lib-auto-layout [padding]="[0, 20]">
    <h3 class="ion-no-margin">{{ 'shared.data-organizer.shown' | translate }}</h3>
  </lib-auto-layout>

  <lib-auto-layout direction="vertical" class="shape-list" horizontal="stretch">
    @if (visibleHeaderItems.length) {
      @for (item of visibleHeaderItems; track item.identifier; let i = $index) {
        <lib-organizer-list-item (itemClicked)="moveToHiddenHeaderItems(i)">
          <ion-label>{{ item.value }}</ion-label>
          <fa-icon [icon]="icons.arrowDown" class="red"></fa-icon>
        </lib-organizer-list-item>
      }
    } @else {
      <lib-auto-layout horizontal="center" class="notification-box">
        <lib-notification-box-component
          [message]="'shared.data-organizer.no-columns-chosen' | translate"
          [icon]="icons.noItems">
        </lib-notification-box-component>
      </lib-auto-layout>
    }
  </lib-auto-layout>

  <lib-auto-layout [padding]="[10, 0]" [grow]="1" horizontal="stretch">
    <ion-button
      color="tertiary"
      expand="block"
      class="p3 ion-no-margin"
      [grow]="1"
      [disabled]="!minimumAmountOfVisibleItemsReached"
      (click)="activateViewModeWithChangesApplied()">
      <span>{{ 'shared.data-organizer.done' | translate }}</span>
      <fa-icon [icon]="icons.back" slot="start"></fa-icon>
    </ion-button>
  </lib-auto-layout>

  @if (!minimumAmountOfVisibleItemsReached) {
    <lib-auto-layout horizontal="center" class="notification-box">
      <lib-notification-box-component
        [message]="
          'shared.data-organizer.minimum-amount-reached' | translate: minAmountTranslateProperties
        ">
      </lib-notification-box-component>
    </lib-auto-layout>
  }

  @if (maximumAmountOfVisibleItemsReached) {
    <lib-auto-layout horizontal="center" class="notification-box">
      <lib-notification-box-component
        [message]="
          'shared.data-organizer.maximum-amount-reached' | translate: maxAmountTranslateProperties
        ">
      </lib-notification-box-component>
    </lib-auto-layout>
  }

  <lib-auto-layout [padding]="[0, 20]">
    <h3 class="ion-no-margin">{{ 'shared.data-organizer.hidden' | translate }}</h3>
  </lib-auto-layout>

  <lib-auto-layout direction="vertical" class="shape-list" horizontal="stretch">
    @if (hiddenHeaderItems.length) {
      @for (item of hiddenHeaderItems; track item.identifier; let i = $index) {
        <lib-organizer-list-item
          (itemClicked)="moveToVisibleHeaderItems(i)"
          [ngClass]="hiddenItemClass">
          <ion-label>{{ item.value }}</ion-label>
          <fa-icon [icon]="icons.arrowUp" class="primary"></fa-icon>
        </lib-organizer-list-item>
      }
    } @else {
      <lib-auto-layout horizontal="center" class="notification-box">
        <lib-notification-box-component
          [message]="'shared.data-organizer.no-columns-available' | translate"
          [icon]="icons.noItems">
        </lib-notification-box-component>
      </lib-auto-layout>
    }
  </lib-auto-layout>
</lib-auto-layout>
