import { Pipe, PipeTransform } from '@angular/core';
import {
  faHandHoldingHand,
  faHospital,
  faHouseChimneyMedical,
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { ClinicType } from 'projects/core/src/lib/models/aftercare.model';

@Pipe({
  name: 'clinicTypeToIcon',
})
export class ClinicTypeToIconPipe implements PipeTransform {
  icons = {
    clinic: faHospital,
    reha: faHouseChimneyMedical,
    pflege: faHandHoldingHand,
  };

  transform(clinicType: ClinicType): IconDefinition {
    switch (clinicType) {
      case ClinicType.Pflege:
        return this.icons.pflege;
      case ClinicType.Rehaklinik:
        return this.icons.reha;
      default:
        return this.icons.clinic;
    }
  }
}
