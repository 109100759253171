import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filesize } from 'filesize';

@Pipe({
  name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
  symbolTranslations = {
    fr: { B: 'o', kB: 'ko', MB: 'Mo', GB: 'Go', TB: 'To', PB: 'Po', EB: 'Eo', ZB: 'Zo', YB: 'Yo' },
    ru: { B: 'Б', kB: 'кБ', MB: 'МБ', GB: 'ГБ', TB: 'ТБ', PB: 'ПБ', EB: 'ЭБ', ZB: 'ЗБ', YB: 'ЙБ' },
  };
  constructor(private translateService: TranslateService) {}
  private transformOne(value: number, options?: any): string {
    const currentLang = this.translateService.currentLang;
    const symbols = Object.keys(this.symbolTranslations).includes(currentLang)
      ? this.symbolTranslations[currentLang]
      : undefined;
    return `${filesize(value, { ...options, locale: currentLang, symbols })}`;
  }

  transform(value: number | number[], options?: any) {
    if (Array.isArray(value)) {
      return value.map((val) => this.transformOne(val, options));
    }

    return this.transformOne(value, options);
  }
}
