import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AlertMessagesData } from 'projects/core/src/lib/data/alert-messages.data';
import { APIError } from 'projects/core/src/lib/data/errors.data';
import { TableList } from 'projects/core/src/lib/models/dynamic-table.model';
import { DynamicForm, DynamicFormConfiguration } from 'projects/core/src/lib/models/form.model';
import { Profile } from 'projects/core/src/lib/models/profile.model';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { DynamicFormService } from 'projects/core/src/lib/services/dynamic-form.service';
import { FormService } from 'projects/core/src/lib/services/form.service';
import { ProfileService } from 'projects/core/src/lib/services/profile.service';
import { firstValueFrom } from 'rxjs';
import { ChatFormMapper, ChatResolver, ChatSection, MessageGroup } from './chat';

@Component({
  selector: 'lib-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  host: { class: 'handle-ion-layout medium-modal' },
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() configuration: DynamicFormConfiguration;
  @ViewChild('f') ngForm: NgForm;
  @ViewChild('scrollPoint') scrollPoint: ElementRef;

  isSendingMessage: boolean = false;
  originDynamicForm: DynamicForm;
  chatSections: ChatSection[];

  private dynamicForm: DynamicForm;
  private profile: Profile;
  private updateChatInterval: any;

  constructor(
    private dynamicFormService: DynamicFormService,
    private alertService: AlertService,
    private modalController: ModalController,
    private formService: FormService,
    private changeDetector: ChangeDetectorRef,
    private profileService: ProfileService,
  ) {}

  get showInitialLoading(): boolean {
    return !this.chatSections && !this.chatSections?.length;
  }

  private get latestLoadedMessageTimestamp(): Date {
    return this.chatSections
      ?.slice(-1)?.[0]
      ?.messagesByUser?.slice(-1)?.[0]
      ?.messages?.slice(-1)?.[0]?.timestamp;
  }

  async ngOnInit(): Promise<void> {
    await this.setCurrentProfile();
    await this.initChat();
    this.observeNewMessages();
  }

  ngOnDestroy(): void {
    this.stopMessageObservation();
  }

  resolveColorClass(messageGroup: MessageGroup): string {
    return this.isOutgoingMessage(messageGroup.user) ? 'color-grey-medium' : 'color-chat';
  }

  async sendMessage(): Promise<void> {
    this.isSendingMessage = true;

    const chatForm: DynamicForm = await firstValueFrom(
      this.formService.saveFormWithResponse(this.originDynamicForm),
    );
    this.originDynamicForm = chatForm;
    this.dynamicForm = chatForm;

    this.setChatSections();
    this.initializeChatView();

    this.isSendingMessage = false;
  }

  isOutgoingMessage(user: string): boolean {
    return user === this.profile.userName;
  }

  private async initChat(): Promise<void> {
    this.originDynamicForm = await this.getChatForm();
    this.dynamicForm = this.originDynamicForm;
    this.setChatSections();
    this.initializeChatView();
  }

  private async updateChat(): Promise<void> {
    const latestChatForm = await this.getChatForm();
    const latestMessage = ChatFormMapper.mapChatFormToMessages(latestChatForm).pop();
    const latestMessageTimestamp = latestMessage?.date;

    if (latestMessageTimestamp && latestMessageTimestamp > this.latestLoadedMessageTimestamp) {
      this.dynamicForm = latestChatForm;
      this.setChatSections();
      this.initializeChatView();
    }
  }

  private setChatSections(): void {
    const messageGrid: TableList = this.dynamicForm.body[0].fieldGroup[1].value.table;
    this.chatSections = new ChatResolver().mapGridToChatSections(
      messageGrid,
      this.profile.userName,
    );
  }

  private async getChatForm(): Promise<DynamicForm> {
    try {
      return await firstValueFrom(this.dynamicFormService.getDynamicForm(this.configuration));
    } catch (error) {
      if (error instanceof APIError) {
        await this.alertService
          .presentAlert(AlertMessagesData.dynamicFormInvokerError)
          .then(async () => {
            await this.modalController.dismiss(error, 'error');
          });
      } else {
        throw error;
      }
      console.error(error);
      return null;
    }
  }

  private initializeChatView(): void {
    this.changeDetector.detectChanges();
    (this.scrollPoint.nativeElement as Element).scrollIntoView({
      behavior: 'instant',
      block: 'end',
      inline: 'end',
    });
  }

  private async setCurrentProfile(): Promise<void> {
    this.profile = await firstValueFrom(this.profileService.getCurrentProfile());
  }

  private observeNewMessages(): void {
    this.updateChatInterval = setInterval(async () => {
      await this.updateChat();
    }, 5000);
  }

  private stopMessageObservation(): void {
    if (this.updateChatInterval) {
      clearInterval(this.updateChatInterval);
    }
  }
}
