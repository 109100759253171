<input
  class="invisible-input hidden-form-element"
  label-placement="stacked"
  [id]="customHtmlId"
  [(ngModel)]="item.value.value"
  [name]="item.identifier.originalValue"
  #input="ngModel"
  hidden />

<pp-base-field
  [item]="item"
  [customHtmlId]="customHtmlId"
  [input]="input"
  [showResetButton]="!!item?.value?.options?.length"
  [preview]="preview">
  <ng-container inputPart *ngIf="showAsRadio">
    <div *ngFor="let option of item.value.options; let i = index" class="toggle-option">
      <input
        tabindex="0"
        [id]="customHtmlId + '-' + i"
        type="radio"
        (change)="onRadioChange(option.identifier, input)"
        (blur)="ensureFieldMarkedAsTouched(input)"
        [name]="item.name"
        [disabled]="!isEditableField"
        [value]="option.identifier"
        [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
        [attr.aria-required]="item.required"
        [checked]="option.identifier === item.value.value" />
      <label
        [for]="customHtmlId + '-' + i"
        class="input radio-selection"
        [class.radio-disabled]="!isEditableField"
        [class.edited]="item.isModified && item.value.value === option.identifier">
        <span class="toggle-point"> </span>
        <span class="hidden-form-element">{{ item.name }} {{ item.required ? ' * ' : '' }}:</span>
        <span class="toggle-value">{{
          option.value || ('shared.forms.no-selection-options-available' | translate)
        }}</span>
      </label>
    </div>
  </ng-container>

  <ng-container inputPart *ngIf="showAsSelect">
    <div
      [id]="'select-' + customHtmlId"
      class="select"
      (click)="openSelect()"
      (keyup.enter)="openSelect()"
      [class.no-value-selected]="!item.value.value"
      [class.edited]="item.isModified && item.value.value"
      [class.disabled]="!isEditableField"
      [tabindex]="tabIndex"
      (blur)="ensureFieldMarkedAsTouched(input)"
      [attr.aria-describedby]="'errors-and-hints-' + customHtmlId"
      [attr.aria-required]="item.required"
      [class.no-value-selected]="!item.value.value"
      #selectionInput>
      <span class="select-value">{{
        currentSelectedOptionText || placeholderKey | translate
      }}</span>
    </div>
  </ng-container>

  <ng-container inputPart *ngIf="!item.value.options?.length">
    <label class="no-options-available">
      <p class="input radio-selection radio-disabled" [id]="customHtmlId + '-radio-disabled'">
        {{ 'shared.forms.no-selection-options-available' | translate }}
      </p>
    </label>
  </ng-container>
</pp-base-field>
