<ion-grid class="ion-no-padding" [fixed]="true">
  <ion-row>
    <ion-col class="ion-no-padding" sizeMd="6" size="12">
      <ion-card color="light" class="appointments">
        <ion-card-content>
          <h2>Ohne Ausrichtung</h2>
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col class="ion-no-padding" sizeMd="6" size="12">
      <ion-card color="light" class="appointments">
        <ion-card-content>
          <h2 class="align">Ausgerichtet</h2>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="ion-no-padding" sizeMd="6" size="12">
      <ion-card color="light" class="appointments">
        <div class="content">
          <h1>Headline h1</h1>
          <h2>Headline h2</h2>
          <h3>Headline h3</h3>
          <h4>Headline h4</h4>
          <h5>Headline h5</h5>
        </div>
      </ion-card>
    </ion-col>
    <ion-col class="ion-no-padding" sizeMd="6" size="12">
      <ion-card color="light" class="appointments">
        <div class="content">
          <h1 class="align">Headline h1.align</h1>
          <h2 class="align">Headline h2.align</h2>
          <h3 class="align">Headline h3.align</h3>
          <h4 class="align">Headline h4.align</h4>
          <h5 class="align">Headline h5.align</h5>
        </div>
      </ion-card>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="ion-no-padding" sizeMd="6" size="12">
      <ion-card color="light" class="appointments">
        <div class="content">
          <h2>Absatzformate</h2>
          <p>
            Die Abschnittformate unterscheiden sich in ihrer Anwendung im wechsel zwischen der
            desktop und mobilen Ansicht.
          </p>
          <h2>p</h2>
          <p>{{ lorem[0] }}</p>
          <h2>p1</h2>
          <p class="p1">{{ lorem[1] }}</p>
          <h2>p2</h2>
          <p class="p2">{{ lorem[2] }}</p>
          <h2>p3</h2>
          <p class="p3">{{ lorem[3] }}</p>
          <h2>p4</h2>
          <p class="p4">{{ lorem[4] }}</p>
        </div>
      </ion-card>
    </ion-col>

    <ion-col class="ion-no-padding" sizeMd="6" size="12">
      <ion-card color="light" class="appointments">
        <div class="content">
          <h2>Absatzformate mit Ausrichtung</h2>
          <p>
            Nicht alle Verwendungsorte der Absatzformate haben die möglichkeit ausgerichtet zu
            werden.
          </p>
          <h2>p.align</h2>
          <p class="align">{{ lorem[0] }}</p>
          <h2>p1.align</h2>
          <p class="p1 align">{{ lorem[1] }}</p>
          <h2>p2.align</h2>
          <p class="p2 align">{{ lorem[2] }}</p>
          <h2>p3.align</h2>
          <p class="p3 align">{{ lorem[3] }}</p>
          <h2>p4.align</h2>
          <p class="p4 align">{{ lorem[4] }}</p>
        </div>
      </ion-card>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="ion-no-padding" sizeMd="6" size="12">
      <ion-card color="light" class="appointments">
        <ion-card-header><h2>Buttons</h2></ion-card-header>
        <ion-card-content class="distribute">
          <ion-button color="primary" class="p3">Primary</ion-button>
          <ion-button color="secondary" class="p3">Secondary</ion-button>
          <ion-button color="tertiary" class="p3">Tertiary</ion-button>
          <ion-button color="light" class="p3">Light</ion-button>
          <ion-button color="medium" class="p3">Medium</ion-button>
        </ion-card-content>
      </ion-card>
      <ion-card color="light" class="appointments">
        <ion-card-header><h2>Radius</h2></ion-card-header>
        <ion-card-content class="distribute">
          <div class="as-box rounded-small p3">small</div>
          <div class="as-box rounded-medium p3">Medium</div>
          <div class="as-box rounded-button p3">button</div>
          <div class="as-box rounded-card p3">Card</div>
        </ion-card-content>
      </ion-card>
      <ion-card color="light" class="appointments">
        <ion-card-header><h2>Farben</h2></ion-card-header>
        <ion-card-content class="distribute">
          <div class="as-box rounded-card p3 color-primary">primary</div>
          <div class="as-box rounded-card p3 color-secondary">secondary</div>
          <div class="as-box rounded-card p3 color-greyish">greyish</div>
          <div class="as-box rounded-card p3 color-warn">Warn</div>
          <div class="as-box rounded-card p3 color-info">Info</div>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col sizeMd="8" size="12">
      <ion-card class="card-transparent">
        <lib-card-list>
          <lib-document-row [document]="document"></lib-document-row>
          <lib-document-row [document]="document"></lib-document-row>
          <lib-document-row [document]="document"></lib-document-row>
          <lib-document-row [document]="document"></lib-document-row>
        </lib-card-list>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
