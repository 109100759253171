import { IconDefinition } from '@fortawesome/angular-fontawesome/types';
import { faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ItemSorterService } from '../services/item-sorter.service';
import { SkeletonService } from '../services/skeleton.service';
import { TableDataService } from '../services/table-data.service';
import { TableOrganizerService } from '../services/table-organizer.service';
import { DynamicButton } from './dynamic-button.model';
import { DynamicDataField, FormatOptions } from './form.model';
import { Invoker, InvokerBody } from './invoker-body.model';
import { RequiredActionsDetails } from './required-actions.model';
import { AttributeFieldType } from './sdapi-form-object.model';
import { AttributeNameIdentifier, SearchParams, SortDirection, SortParams } from './shared.model';

export class TableList {
  readonly initialHeader: TableHeaderItem[];
  readonly initialRows: TableListItem[];
  readonly selectableRows: boolean = false;
  title: string;
  storageKey: string;
  type: TableType;
  sortedHeader: TableHeaderItem[];
  sortedRows: TableListItem[];
  sortParams: SortParams;
  rowIcon?: IconDefinition;
  rowIconMobile?: IconDefinition;
  actionButtons?: DynamicButton[];
  header?: DynamicDataField[];

  constructor(header: TableHeaderItem[], rows: TableListItem[], tableDefinition?: TableDefinition) {
    this.initialHeader = structuredClone(header);
    this.sortedHeader = header;
    this.initialRows = structuredClone(rows);
    this.sortedRows = rows;
    this.sortParams = new SortParams();
    this.sortParams.direction = SortDirection.ASC;
    this.rowIcon = tableDefinition?.customRowIcon ?? faChevronRight;
    this.rowIconMobile = tableDefinition?.customRowIconMobile ?? faArrowRight;
    this.sortColumnsVertically();
    if (tableDefinition) {
      this.title = tableDefinition.title;
      this.storageKey = tableDefinition.storageKey;
      this.type = tableDefinition.tableType;
      this.selectableRows = tableDefinition.selectableRows;
    }
  }

  public sortColumnsHorizontally(sortedHeader: TableHeaderItem[]): void {
    this.sortedHeader = sortedHeader;
    this.sortedRows = TableOrganizerService.sortHorizontally(
      this.initialHeader,
      sortedHeader,
      this.initialRows,
      this.sortedRows,
    );
  }

  public sortColumnsVertically(): void {
    this.setSortParamsDetails();
    this.sortedRows = ItemSorterService.sort(this.sortedRows, this.sortParams);
  }

  public updateRowsAfterSearch(searchedItems: TableListItem[]): void {
    this.sortedRows = ItemSorterService.sort(searchedItems, this.sortParams);
  }

  private setSortParamsDetails(): void {
    const headerItemIndex: number = this.sortedHeader.findIndex(
      (headerLabel: TableHeaderItem) =>
        headerLabel.identifier.normalizedValue === this.sortParams.identifier,
    );
    if (this.sortedHeader.length) {
      this.resolveSortParamsDetails(headerItemIndex);
    }
  }

  private resolveSortParamsDetails(headerItemIndex: number): void {
    if (headerItemIndex === -1) {
      const columnIndexDefault = 0;
      this.sortParams.field = `columns.${columnIndexDefault}`;
      this.sortParams.identifier = this.sortedHeader[columnIndexDefault].identifier.normalizedValue;
    } else {
      this.sortParams.field = `columns.${headerItemIndex}`;
      this.sortParams.identifier = this.sortedHeader[headerItemIndex].identifier.normalizedValue;
    }
  }

  public setSortParams(headerLabel: TableHeaderItem): void {
    this.sortParams.identifier = headerLabel.identifier.normalizedValue;
    this.sortParams.direction =
      this.sortParams.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
  }

  public updateSortParams(columnIndex: number): void {
    this.sortParams.field = `columns.${columnIndex}`;
    this.sortParams.identifier = this.sortedHeader[columnIndex].identifier.normalizedValue;
  }

  public updateHeaderAccordingToSavedIdentifiers(identifiers: string[]): void {
    this.sortedHeader = TableDataService.retrieveHeaderItemsByIdentifiers(
      this.initialHeader,
      identifiers,
    );
  }

  public shiftViewedItemsToRightByOne(): void {
    const lastItem: TableHeaderItem = this.sortedHeader.pop();
    this.sortedHeader.unshift(lastItem);
    this.setSortParamsAndSortHorizontally();
  }

  public shiftViewedItemsToLeftByOne(): void {
    const firstItem: TableHeaderItem = this.sortedHeader.shift();
    this.sortedHeader.push(firstItem);
    this.setSortParamsAndSortHorizontally();
  }

  private setSortParamsAndSortHorizontally(): void {
    this.setSortParamsDetails();
    this.sortedRows = TableOrganizerService.sortHorizontally(
      this.initialHeader,
      this.sortedHeader,
      this.initialRows,
      this.sortedRows,
    );
  }
}

export class TableHeaderItem {
  value: string;
  type: TableDataType | AttributeFieldType;
  format?: FormatOptions;
  identifier?: AttributeNameIdentifier;
}

export enum TableDataType {
  text = 'TEXT',
  // eslint-disable-next-line id-blacklist
  number = 'NUMBER',
  date = 'DATE',
  icon = 'ICON',
}

export class TableCellData {
  value: string;
  type: TableDataType;
  index: number;
}

export class TableListItem {
  columns: string[];
  id?: string;
  name?: string;
  selected?: boolean = false;
  selectedFix?: boolean = false;
  behaviorInvokers?: InvokerBody[];
}

export class RowHeightSet {
  public readonly mobile: number;
  public readonly desktop: number;

  constructor(mobile: number, desktop: number) {
    this.mobile = mobile;
    this.desktop = desktop;
  }
}

export interface TableConfiguration {
  table: TableList;
  searchParams: SearchParams;
  expandedViewThreshold: number;
  columnWidth: number;

  skeletonRowSet?: SkeletonRowSet;
}

export class TableRowState {
  public height: number;
  public columnNumber: ColumnNumberType;
  public view: TableRowView;

  constructor(rowHeight?: number, columnNumber?: number, rowView?: TableRowView) {
    this.height = rowHeight;
    this.columnNumber = new ColumnNumberType(columnNumber, columnNumber);
    this.view = rowView;
  }

  public setColumnNumberToDisplay(data: TableConfiguration, containerWidth: number): void {
    const maximumColumnNumber = TableOrganizerService.getColumnNumberByTableWidth(
      data,
      containerWidth,
    );
    this.resolveMaximumColumnNumber(data.table?.initialHeader, maximumColumnNumber);
    this.resolveCurrentColumnNumber(data.table?.initialHeader);
  }

  private resolveMaximumColumnNumber(
    initialHeader: TableHeaderItem[],
    maximumColumnNumber: number,
  ): void {
    if (this.isDesktopView) {
      this.columnNumber.maximum = maximumColumnNumber;
    } else {
      this.columnNumber.maximum = this.getMaximumColumnNumberForMobileView(initialHeader);
    }
  }

  private getMaximumColumnNumberForMobileView(initialHeader: TableHeaderItem[]): number {
    return initialHeader?.length === ColumnCountInMobile.condensed
      ? ColumnCountInMobile.condensed
      : ColumnCountInMobile.expandable;
  }

  private resolveCurrentColumnNumber(initialHeader: TableHeaderItem[]): void {
    const maxColumnNumber: number = this.columnNumber.maximum;
    const isWithinMaxColumnLimit: boolean = initialHeader?.length <= maxColumnNumber;
    this.columnNumber.current = isWithinMaxColumnLimit ? initialHeader?.length : maxColumnNumber;
  }

  public setView(data: TableConfiguration, containerWidth: number): void {
    this.view = TableOrganizerService.resolveRowViewByTableWidth(data, containerWidth);
  }

  public get isDesktopView(): boolean {
    return this.view === TableRowView.desktop;
  }
}

export class ColumnNumberType {
  public readonly minimum: number;
  public maximum: number;
  public current: number;

  constructor(maximumNumber: number, currentNumber: number) {
    this.minimum = 2;
    this.maximum = maximumNumber;
    this.current = currentNumber;
  }
}

export enum ColumnCountInMobile {
  condensed = 3,
  expandable = 2,
}

export enum TableRowView {
  mobile,
  desktop,
}

export class EventMessage {
  type: EventMessageType;
  data: any;
  originalEvent?: Event;
  behaviorInvokers?: InvokerBody[];
}

export enum EventMessageType {
  CLICK,
  RESORTING,
  SELECT,
}

export enum TableType {
  patientData = 'patientDataTable',
  order = 'orderTable',
  expenses = 'expensesTable',
  treatments = 'treatmentsTable',
  medicalCertificates = 'medicalCertificatesTable',
  generic = 'genericTable',
  grid = 'gridTable',
}

export type TableDefinition = {
  title?: string;
  storageKey?: string;
  tableType?: TableType;
  selectableRows?: boolean;
  customRowIcon?: IconDefinition;
  customRowIconMobile?: IconDefinition;
};

export class SkeletonHeaderOffset {
  public mobile: number;
  public desktop: number;
}

export class SkeletonRowSet {
  public readonly mobile: number[];
  public readonly desktop: number[];
  public readonly rowIcon: IconDefinition = faChevronRight;

  constructor(
    headerOffset: SkeletonHeaderOffset,
    heightSet?: RowHeightSet,
    customRowIcon?: IconDefinition,
  ) {
    const defaultHeightSet: RowHeightSet = { mobile: 110, desktop: 58 };
    const rowHeights: RowHeightSet = heightSet ?? defaultHeightSet;
    this.mobile = SkeletonService.getRowAmountAsArray(headerOffset.mobile, rowHeights.mobile);
    this.desktop = SkeletonService.getRowAmountAsArray(headerOffset.desktop, rowHeights.desktop);
    this.rowIcon = customRowIcon ?? this.rowIcon;
  }
}

export enum TableFilterSection {
  verticalSorting,
  horizontalSorting,
}

export enum HeaderItemVisibility {
  visible,
  hidden,
}

export type SearchLayoutType = 'card' | 'list';

export enum TableSection {
  list,
  information,
}

export class ModalTableDismissData {
  invoker: Invoker;
  actionButtons: DynamicButton[];
  requiredAction?: RequiredActionsDetails;
}
