<lib-modal-header
  [title]="configuration.requiredActionsDetails.title"
  titleIcon="comments"></lib-modal-header>
<ion-content [scrollY]="false">
  @if (showInitialLoading) {
    <div class="stretch-self align-center safe-area">
      <ion-spinner></ion-spinner>
    </div>
  } @else {
    <lib-auto-layout direction="vertical" horizontal="stretch" class="stretch-self">
      <div class="conversation-scroll-container" [grow]="1">
        <div class="conversation-container p no-margin">
          @if (chatSections.length === 0) {
            <lib-auto-layout direction="vertical" horizontal="center" vertical="center">
              <lib-notification-box-component
                [message]="'shared.chat.no-messages' | translate"></lib-notification-box-component>
            </lib-auto-layout>
          } @else {
            @for (section of chatSections; track $index; let sectionIndex = $index) {
              <lib-auto-layout horizontal="center" class="date-divider color-grey-light">
                <strong>
                  {{ section.timestamp | localeDate: 'fullDate' }}
                </strong>
              </lib-auto-layout>
              @for (
                messageGroup of section.messagesByUser;
                track $index;
                let messageGroupIndex = $index
              ) {
                <lib-auto-layout
                  direction="vertical"
                  class="message-container"
                  [class.outgoing]="isOutgoingMessage(messageGroup.user)"
                  [ngClass]="messageGroup.colorClass">
                  @if (!isOutgoingMessage(messageGroup.user)) {
                    <lib-auto-layout class="user-info" [ngClass]="resolveColorClass(messageGroup)">
                      <fa-icon icon="user"></fa-icon>
                      <strong>{{ messageGroup.user }}</strong>
                    </lib-auto-layout>
                  }
                  <lib-auto-layout
                    direction="vertical"
                    horizontal="stretch"
                    class="chat-bubble"
                    [ngClass]="resolveColorClass(messageGroup)">
                    @for (message of messageGroup.messages; track message.timestamp) {
                      <div class="message">
                        <div class="time">{{ message.timestamp | time }}</div>
                        <span class="message-content">{{ message.message }}</span>
                        <span class="time-spacer"></span>
                      </div>
                    }
                  </lib-auto-layout>
                </lib-auto-layout>
              }
            }
          }
          <div #scrollPoint></div>
        </div>
      </div>

      @if (isSendingMessage) {
        <lib-auto-layout
          direction="horizontal"
          horizontal="center"
          vertical="center"
          class="sending-loader">
          <lib-inline-loader
            [message]="'shared.chat.message-sending' | translate"></lib-inline-loader>
        </lib-auto-layout>
      }

      <form #f="ngForm" [class.disabled]="isSendingMessage">
        <lib-auto-layout direction="vertical" horizontal="stretch" class="form-container">
          <textarea
            id="input-bubble"
            class="color-grey-medium"
            #input="ngModel"
            [(ngModel)]="originDynamicForm.body[0].fieldGroup[0].value.value"
            [name]="originDynamicForm.body[0].fieldGroup[0].identifier.originalValue"
            [required]="true"
            [placeholder]="'shared.chat.message-placeholder' | translate"
            [attr.aria-label]="'shared.chat.message-placeholder' | translate"></textarea>
          <lib-auto-layout horizontal="end">
            <ion-button
              color="primary"
              [disabled]="!originDynamicForm.body[0].fieldGroup[0].hasValue"
              (click)="sendMessage()">
              <fa-icon slot="start" icon="chevron-up"></fa-icon>
              <span>{{ 'shared.chat.send-message' | translate }}</span>
            </ion-button>
          </lib-auto-layout>
        </lib-auto-layout>
      </form>
    </lib-auto-layout>
  }
</ion-content>
