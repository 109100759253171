import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import imageCompression from 'browser-image-compression';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { FormMapper } from '../mappers/form.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { DynamicDataField, DynamicForm } from '../models/form.model';
import { Invoker, InvokerMethods } from '../models/invoker-body.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import { SDAPIService } from './sdapi.service';

@Injectable()
export class UploadService {
  private readonly maxUploadImageSizeInMb: number = 2;
  private readonly maxUploadImageResolution: number = 2000;

  constructor(
    private http: HttpClient,
    private sdapiService: SDAPIService,
    private dynamicFormMapper: DynamicFormMapper,
  ) {}

  public getDocumentUploadForm(invoker: Invoker): Observable<DynamicForm> {
    const activityURL: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
    return this.sdapiService
      .invokeMethod<TieFormObject>(invoker.invoker)
      .pipe(
        map((form: TieFormObject) =>
          this.dynamicFormMapper.mapDynamicFormResource(form, activityURL),
        ),
      );
  }

  public getUploadFormInvoker(
    id: number,
    preferredInvokerMethod?: InvokerMethods,
    defaultInvokerMethod?: InvokerMethods,
  ): Observable<Invoker> {
    const preferredMethod = preferredInvokerMethod || InvokerMethods.medicalDocumentCreate;
    const defaultMethod = defaultInvokerMethod || InvokerMethods.objectCreateAutoImport;
    return this.sdapiService.getPreferredInvokerOrDefault(id, preferredMethod, defaultMethod);
  }

  public async saveDynamicFormWithFile<T>(dynamicForm: DynamicForm): Promise<T> {
    const file = this.getFileFromDynamicForm(dynamicForm) || new File([], '');
    const compressedFile = await this.compressFile(file);
    const requestBody = this.combineStepInvokerAndFileToFormData(dynamicForm, compressedFile);
    const response = await firstValueFrom(
      this.http.put<T>(dynamicForm.activityURL + '/step', requestBody),
    );
    return response;
  }

  private getFileFromDynamicForm(dynamicForm: DynamicForm): File {
    const file = dynamicForm.body
      .flatMap<DynamicDataField>((group) => group.fieldGroup)
      .find((dataField: DynamicDataField) => dataField.file)?.file;
    return file;
  }

  public combineStepInvokerAndFileToFormData(dynamicForm: DynamicForm, file: File): FormData {
    const bodyWithVisbleItems = this.dynamicFormMapper.extractBodyWithVisibleFormItems(dynamicForm);
    const uploadInvoker: Invoker = this.sdapiService.getStepInvoker(dynamicForm);

    FormMapper.insertDynamicFormItemsToInvokerBody(bodyWithVisbleItems, uploadInvoker);

    const formData: FormData = new FormData();
    formData.append('stepInvoker', JSON.stringify(uploadInvoker.invoker));
    formData.append('file', file, file.name);

    return formData;
  }

  private async compressFile(file: File): Promise<File> {
    const ng2ImageMaxSupportedImageExtensions = ['jpg', 'jpeg', 'png'];
    if (
      ng2ImageMaxSupportedImageExtensions.some((extension) => file.name.endsWith(extension)) &&
      file.size > this.maxUploadImageSizeInMb * 1048576
    ) {
      try {
        return await this.getCompressedImage(file);
      } catch (error) {
        console.warn(
          'No Image or could not be compressed:',
          file.name,
          `Reason: ${error['reason'] || 'no Reason provided'}`,
        );
      }
    }
    return file;
  }

  private getCompressedImage(file: File): Promise<File> {
    return imageCompression(file, {
      maxWidthOrHeight: this.maxUploadImageResolution,
      maxSizeMB: this.maxUploadImageSizeInMb,
    });
  }
}
