<ion-header>
  <ion-toolbar class="toolbar">
    <h3>{{ 'shared.patients.patient-data' | translate }}</h3>
    <div class="toolbar-buttons" slot="end">
      <ion-button id="cancel-button" color="tertiary" (click)="close()">
        {{ 'general.cancel' | translate }}
        <fa-icon [icon]="icons.close" slot="end"></fa-icon>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="card-list">
    <ion-item *ngFor="let item of tableList" class="item-line" lines="none">
      <p class="field-label">{{ item.name }}</p>
      <p slot="end" class="text no-hyphens">
        <span *ngIf="item.type === dataType.date">{{
          (item.value | localeDate) || ('general.not-specified' | translate)
        }}</span>
        <span *ngIf="item.type !== dataType.date">{{
          item.value || ('general.not-specified' | translate)
        }}</span>
      </p>
    </ion-item>
  </ion-list>
</ion-content>
