import { CustomMenuItem } from 'projects/core/src/lib/models/client.model';
import { MenuItem, MenuItemType } from 'projects/shared/src/lib/models/menu.model';
import { IconName } from '@fortawesome/free-regular-svg-icons';

export class CustomMenuMapper {
  static map(customMenuItem: CustomMenuItem): MenuItem {
    return {
      url: customMenuItem.url,
      title: customMenuItem.title,
      icon: [customMenuItem.iconPack, customMenuItem.iconName as IconName],
      idSuffix: CustomMenuMapper.slugify(customMenuItem.title),
      visibility: true,
      target: customMenuItem.target || 'iframe',
      type: MenuItemType.external,
    };
  }

  static slugify(unslugged: string): string {
    return unslugged
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^(-+|-+)$/g, '');
  }
}
