import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  faChevronLeft,
  faChevronRight,
  faRotateLeft,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { DynamicForm } from 'projects/core/src/lib/models/form.model';

@Component({
  selector: 'lib-form-navigation',
  templateUrl: './form-navigation.component.html',
  styleUrls: ['../dynamic-form-flexible-layout.component.scss'],
})
export class FormNavigationComponent {
  @Input({ required: true }) form!: DynamicForm;

  @Input({ required: true }) activeGroupNumber!: number;

  @Input({ required: true }) preview!: boolean;
  @Input({ required: true }) isMobileView!: boolean;
  @Input({ required: true }) isFormGroupActive!: boolean;
  @Input({ required: true }) allFormGroupsShown!: boolean;
  @Input({ required: true }) disableSubmitButton!: boolean;
  @Input({ required: true }) showMobileNavigation!: boolean;

  @Output() navigateBack = new EventEmitter<void>();
  @Output() navigateForward = new EventEmitter<void>();
  @Output() navigateToEditMode = new EventEmitter<void>();
  @Output() navigateToPreviewMode = new EventEmitter<void>();

  readonly ICONS = {
    CLOSE: faXmark,
    RESET: faRotateLeft,
    BACK: faChevronLeft,
    FORWARD: faChevronRight,
  };
}
