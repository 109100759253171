<lib-auto-layout direction="vertical" class="fill-height" [gap]="[20]" horizontal="stretch">
  <lib-auto-layout [gap]="[20]">
    <lib-search-input
      [disabled]="isLoading || !documentsList?.length"
      [grow]="1"
      class="stretch-self"
      [searchParams]="searchParams"
      (searchParamsChanged)="refreshViewItems()">
    </lib-search-input>

    <lib-auto-layout class="toolbar-item card-layout" [gap]="[1]">
      <ion-button
        expand="full"
        color="secondary"
        class="card-tool ion-no-margin"
        id="filters-popover-trigger-btn"
        [ngClass]="{
          active: isFiltersContextOpen || isFilterSet(),
          'pointer-event-none': isLoading,
        }"
        (click)="toggleFilterButtonState()">
        <span>{{ 'shared.documents.file-type' | translate }}</span>
        <fa-icon class="filter-icon" slot="end" [icon]="icons.faFilter"></fa-icon>
      </ion-button>
    </lib-auto-layout>

    <ion-popover
      [isOpen]="isFiltersContextOpen"
      (ionPopoverWillDismiss)="toggleFilterButtonState()"
      trigger="filters-popover-trigger-btn"
      alignment="end"
      animated="true"
      arrow="false"
      side="bottom"
      cssClass="filters-popover"
      [ngStyle]="{ '--max-height': getVirtualHeightOfPopover() }">
      <ng-template>
        <lib-filters-context
          [filtersPreset]="filtersPreset"
          [subtotals]="subtotals"
          (selectedFiltersChanged)="onFilterChange()">
        </lib-filters-context>
      </ng-template>
    </ion-popover>
  </lib-auto-layout>

  <ion-card
    *ngIf="isFilterSet() && !documentsList.length"
    class="filter-view card-transparent ion-no-margin">
    <div class="filters-wrap">
      <ng-container *ngFor="let filter of filtersPreset">
        <ion-button
          color="tertiary"
          class="filter-badge"
          *ngIf="filter.selected"
          (click)="removeFilter(filter.name)">
          {{ 'shared.documents.filter-names.' + filter.name | translate }}
          <fa-icon slot="end" [icon]="icons.faFilterDelete"></fa-icon>
        </ion-button>
      </ng-container>
    </div>
  </ion-card>

  <ion-card color="light" class="card-container ion-no-margin stretch-self stretch-container">
    <lib-documents-skeleton
      class="initial-skeleton"
      [lines]="maxRowAmountOfCard"
      *ngIf="isLoading"></lib-documents-skeleton>

    <lib-documents-list
      *ngIf="viewList.length > 0"
      [documentsList]="viewList"
      [sortParams]="sortParams"
      (sortParamsUpdated)="refreshViewItems()"
      (documentListReload)="documentListReload.emit($event)"
      data-api-id="document-list"
      class="stretch-flex stretch-container">
    </lib-documents-list>

    <div class="no-result h3" *ngIf="viewList.length === 0 && !isLoading">
      <div class="no-result-title" data-api-id="document-list">
        <fa-icon [icon]="icons.faCircleInfo" size="2x"></fa-icon>
        <span *ngIf="isFilterSet() || searchParams?.criterions?.length > 0; else noDocuments">
          {{ 'shared.documents.no-documents-found-for-your-search' | translate }}.
        </span>
        <ng-template #noDocuments
          >{{ 'shared.documents.no-documents-found' | translate }}.</ng-template
        >
      </div>
    </div>
  </ion-card>
</lib-auto-layout>
