import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faCircleArrowDown, faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';
import { TableHeaderItem, TableRowState } from 'projects/core/src/lib/models/dynamic-table.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-horizontal-sorting-edit-mode',
  templateUrl: './horizontal-sorting-edit-mode.component.html',
  styleUrls: ['./horizontal-sorting-edit-mode.component.scss', '../../data-organizer-toolbox.scss'],
})
export class HorizontalSortingEditModeComponent implements OnDestroy {
  @Output() editModeToggle: EventEmitter<boolean> = new EventEmitter();

  @Input() header: TableHeaderItem[];
  @Input() headerReordering: BehaviorSubject<TableHeaderItem[]>;
  @Input() visibleHeaderItems: TableHeaderItem[];
  @Input() hiddenHeaderItems: TableHeaderItem[];
  @Input() rowState: TableRowState;

  private sortingPerformed = false;

  readonly icons = {
    back: faArrowLeft,
    arrowDown: faCircleArrowDown,
    arrowUp: faCircleArrowUp,
    noItems: faCircleQuestion,
  };

  ngOnDestroy(): void {
    this.updateHeaderAndRowState();
  }

  activateViewModeWithChangesApplied(): void {
    this.updateHeaderAndRowState();
    this.editModeToggle.emit(false);
  }

  private updateHeaderAndRowState(): void {
    if (this.sortingPerformed && this.minimumAmountOfVisibleItemsReached) {
      this.rowState.columnNumber.current = this.visibleHeaderItems.length;
      this.updateHeaderValues();
    }
  }

  moveToHiddenHeaderItems(index: number): void {
    this.hiddenHeaderItems.unshift(
      this.extractedItemForRepositioning(this.visibleHeaderItems, index),
    );
    this.sortingPerformed = true;
  }

  moveToVisibleHeaderItems(index: number): void {
    if (!this.maximumAmountOfVisibleItemsReached) {
      this.visibleHeaderItems.push(
        this.extractedItemForRepositioning(this.hiddenHeaderItems, index),
      );
      this.sortingPerformed = true;
    }
  }

  private extractedItemForRepositioning(
    headerItems: TableHeaderItem[],
    index: number,
  ): TableHeaderItem {
    return headerItems.splice(index, 1)[0];
  }

  private updateHeaderValues(): void {
    this.header = [...this.visibleHeaderItems, ...this.hiddenHeaderItems];
    this.headerReordering.next(this.header);
  }

  get hiddenItemClass(): string {
    return this.maximumAmountOfVisibleItemsReached ? 'disabled' : null;
  }

  get maximumAmountOfVisibleItemsReached(): boolean {
    return this.visibleHeaderItems.length === this.rowState.columnNumber.maximum;
  }

  get minimumAmountOfVisibleItemsReached(): boolean {
    return this.visibleHeaderItems.length >= this.rowState.columnNumber.minimum;
  }

  get minAmountTranslateProperties(): Object {
    return { minColumnNumber: this.rowState.columnNumber.minimum };
  }

  get maxAmountTranslateProperties(): Object {
    return { maxColumnNumber: this.rowState.columnNumber.maximum };
  }
}
