import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'downloadable',
})
export class DownloadablePipe implements PipeTransform {
  transform(mimeType: string): boolean {
    return mimeType === 'application/pdf' || mimeType === 'video/mp4' || mimeType === 'video/mpeg';
  }
}
