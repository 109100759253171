import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { MimeTypeToDocumentFormatPipe } from 'projects/theme/src/lib/pipes/mime-type-to-document-format.pipe';
import { APIError } from '../data/errors.data';
import {
  DocumentAttributeNames,
  DocumentDetails,
  DocumentFilterName,
  DocumentFormat,
} from '../models/documents.model';
import {
  TieTableObjectList,
  TieTableProcessor,
  TieTableRow,
} from '../models/sdapi-table-object.model';

export class DocumentsResourceMapper {
  public static mapDocumentResourceDetails(
    resource: TieTableObjectList,
    filterTranslations?: Object,
  ): DocumentDetails[] {
    const documentDetails: DocumentDetails[] = [];
    const table: TieTableProcessor = new TieTableProcessor(resource);
    const rows: TieTableRow[] = table.getRows();
    try {
      for (const row of rows) {
        const documentsDetails: DocumentDetails = {
          downloadPending: false,
          name: row.getOptionalAttributeValue(DocumentAttributeNames.title),
          size:
            parseInt(row.getOptionalAttributeValue(DocumentAttributeNames.fileSize), 10) ||
            undefined,
          date: new Date(row.getOptionalAttributeValue(DocumentAttributeNames.creationDate)),
          type: this.getTranslatedDocumentType(filterTranslations, row),
          id: row.objId,
          mimeType: row.getOptionalAttributeValue(DocumentAttributeNames.mimeType),
          behaviorInvokers: row.behaviorInvokers,
        };
        documentDetails.push(documentsDetails);
      }
    } catch (error) {
      throw new APIError(
        'Mapping of document details failed in the DocumentsResourceMapper',
        error,
      );
    }
    return documentDetails;
  }

  private static getTranslatedDocumentType(
    translations: Object,
    tableRow: TieTableRow,
  ): DocumentFilterName {
    const mimeType: string = tableRow.getOptionalAttributeValue(DocumentAttributeNames.mimeType);
    if (mimeType === 'application/dicom') {
      return DocumentFilterName.DICOM;
    }
    const documentType: string = tableRow.getOptionalAttributeValue(
      DocumentAttributeNames.documentType,
    );
    if (this.isFilterKeyValid(translations, documentType)) {
      return this.getFilterNameByDocumentType(translations, documentType) as DocumentFilterName;
    }
    return DocumentFilterName.OTHERS;
  }

  private static isFilterKeyValid(translations: Object, documentType: string): boolean {
    let isFilterKeyValid = false;
    if (translations) {
      isFilterKeyValid = Object.values(DocumentFilterName).some(
        (key: DocumentFilterName) => translations[key] === documentType,
      );
    }
    return isFilterKeyValid;
  }

  private static getFilterNameByDocumentType(
    filterTranslations: Object,
    documentType: string,
  ): string {
    return Object.keys(filterTranslations).find((key) => filterTranslations[key] === documentType);
  }

  public static addDocumentDetailsFromResponseHeader(
    document: DocumentDetails,
    header: HttpResponse<Object>,
  ): DocumentDetails {
    document.httpContentType = header.headers.get('content-type');
    document.size = DocumentsResourceMapper.parseFileSizeFromHeader(header.headers);
    document.fileName = DocumentsResourceMapper.parseFileNameFromHeader(header.headers);
    this.mapFileType(document);
    if (!document.fileName) {
      document.fileName = DocumentsResourceMapper.generateFileNameFromDocumentName(document);
    }

    return document;
  }

  public static addFallbackDocumentDetails(document: DocumentDetails): DocumentDetails {
    document.httpContentType = 'text/html';
    document.size = 0;
    document.fileName = DocumentsResourceMapper.generateFileNameFromDocumentName(document);
    this.mapFileType(document);
    return document;
  }

  private static generateFileNameFromDocumentName(document: DocumentDetails) {
    return `${document.name.replace(/\s/, '_').replace(/[^\w.]/g, '')}.${
      document.viewLink.split('.').slice(-1)[0]
    }`;
  }

  public static mapFileType(document: DocumentDetails): void {
    if (document.externalOpenLink) {
      document.fileType = DocumentFormat.LINK;
    } else {
      document.fileType = MimeTypeToDocumentFormatPipe.transformStatic(document.httpContentType);
    }
  }

  private static parseFileSizeFromHeader(headers: HttpHeaders): number {
    const contentLength: string = headers.get('content-length');
    if (contentLength) {
      return parseInt(contentLength, 10);
    } else {
      return 0;
    }
  }

  private static parseFileNameFromHeader(headers: HttpHeaders): string | undefined {
    const contentDisposition: string = headers.get('content-disposition');

    try {
      return RegExp(/filename="(.+)"/).exec(contentDisposition)[1];
    } catch (error: any) {
      return undefined;
    }
  }
}
