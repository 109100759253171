<lib-auto-layout direction="vertical" horizontal="stretch">
  <lib-auto-layout direction="horizontal" [gap]="[10]">
    <ng-content select="[top]"></ng-content>
  </lib-auto-layout>
  <lib-auto-layout direction="horizontal" horizontal="space-between">
    <ng-content></ng-content>
  </lib-auto-layout>
  <lib-auto-layout direction="horizontal" [gap]="[10]">
    <ng-content select="[bottom]"></ng-content>
  </lib-auto-layout>
</lib-auto-layout>
