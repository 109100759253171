import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-button-container',
  templateUrl: './button-container.component.html',
  host: { class: 'stretch-self' },
})
export class ButtonContainerComponent {
  @Input() direction: LayoutDirection = 'vertical';
  @Input() gap: number[] = [0];
  @Input() padding: number[] = [0];

  get classes(): string {
    return `auto-layout ${this.direction}`;
  }

  get styles(): string {
    return `--gap: ${this.gapValueString}; --padding: ${this.paddingValueString};`;
  }

  private get gapValueString(): string {
    return this.toCSSPixelString(this.gap);
  }

  private get paddingValueString(): string {
    return this.toCSSPixelString(this.padding);
  }

  private toCSSPixelString(values: number[]): string {
    return values.map((value) => `${value}px`).join(' ');
  }
}

type LayoutDirection = 'horizontal' | 'vertical' | 'wrap';
