import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { DocumentDetails } from 'projects/core/src/lib/models/documents.model';

@Component({
  selector: 'lib-information-card',
  templateUrl: './information-card.component.html',
  styleUrl: './information-card.component.scss',
})
export class InformationCardComponent {
  @Output() documentListReload: EventEmitter<void> = new EventEmitter<void>();

  @Input() documentList: DocumentDetails[];

  readonly icons = {
    info: faCircleInfo,
  };
}
