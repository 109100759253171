import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { ProfileSettingsService } from '../services/profile-settings.service';

export const PortalGuard: CanActivateFn = async (): Promise<boolean | UrlTree> => {
  const clientConfigService: ClientConfigService = inject(ClientConfigService);
  const profileSettingsService: ProfileSettingsService = inject(ProfileSettingsService);
  const router: Router = inject(Router);
  const clientConfig: ClientConfig = clientConfigService.get();
  const userProfileSettingsValue = await profileSettingsService.getUserProfileSettingsValue();
  const tosRead = userProfileSettingsValue.userProfileData.tosRead;

  if (clientConfig.termsOfServiceConfig.active && !tosRead) {
    await router.navigateByUrl('/terms-of-service');
    return false;
  } else {
    return true;
  }
};
