<ion-button
  color="light"
  id="document-details-popover-trigger-btn"
  class="icon-only ion-no-margin"
  (click)="toggleDownloadButtonState()">
  <fa-icon class="menu-icon" [icon]="icons.info"></fa-icon>
</ion-button>

<ion-popover
  [isOpen]="isDownloadPopupOpen"
  (ionPopoverWillDismiss)="toggleDownloadButtonState()"
  trigger="document-details-popover-trigger-btn"
  alignment="end"
  animated="true"
  arrow="false"
  side="bottom"
  cssClass="document-details-popover"
  #documentDetailsPopover>
  <ng-template>
    <lib-document-details-popover-content [document]="document" (dismiss)="documentDetailsPopover.dismiss()">
    </lib-document-details-popover-content>
  </ng-template>
</ion-popover>
