<ion-content scrollX="false" scrollY="false">
  <div
    id="notification-container"
    class="stretch-self ion-justify-content-center ion-align-items-center">
    <ion-card class="card-transparent">
      <div id="info-section" (click)="openExternalLink()">
        <a id="document-viewer-download-link" class="download-link">
          <fa-icon size="5x" class="greyish download-request" [icon]="ICONS.link"> </fa-icon>
        </a>
        <div class="overflow-hidden">
          <h2>
            <strong>{{ 'shared.documents.file-type' | translate }}: {{ document.fileType }}</strong>
          </h2>
          <p class="one-line">{{ document.externalOpenLink }}</p>
        </div>
      </div>

      <p id="external-link-url" class="p">
        {{ 'shared.documents.document-external-link' | translate }}.
      </p>

      <div class="button-section ion-justify-content-center ion-align-items-center">
        @if (!linkClicked) {
          <ion-button
            class="p1 ion-no-margin"
            color="primary"
            expand="block"
            (click)="openExternalLink()">
            <fa-icon size="sm" slot="start" [icon]="ICONS.link"></fa-icon>
            {{ 'general.open' | translate }}
          </ion-button>
          <ion-button
            *ngIf="cancelButtonVisibility"
            class="p1 ion-no-margin"
            color="cancel-button"
            expand="block"
            (click)="close()">
            {{ 'general.cancel' | translate }}
          </ion-button>
        } @else {
          <ion-button class="p1 ion-no-margin" color="primary" expand="block" (click)="close()">
            <fa-icon size="sm" slot="start" [icon]="ICONS.arrowLeft"></fa-icon>
            {{ 'shared.document-viewer.link-continue-in-portal' | translate }}
          </ion-button>
        }
      </div>
    </ion-card>
  </div>
</ion-content>
