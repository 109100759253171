import { Injectable } from '@angular/core';
import { SearchParams } from '../models/shared.model';
import { FileSizePipe } from 'projects/theme/src/lib/pipes/file-size.pipe';
import { LocaleDatePipe } from 'projects/theme/src/lib/pipes/locale-date.pipe';

@Injectable()
export class ItemSearcherService {
  constructor(
    private localeDatePipe: LocaleDatePipe,
    private fileSizePipe: FileSizePipe,
  ) {}

  public search(data: any[], searchParams: SearchParams): any[] {
    if (data.length === 0) {return data;}
    if (!searchParams.criterions || searchParams.criterions?.length === 0) {return data;}

    return this.insertHomogenousSearchIndex(data, searchParams.fields).filter(({ homogenIndex }) =>
      searchParams.criterions
        .split(' ')
        .map((criterion) => homogenIndex.includes(this.transformToHomogenousString(criterion)))
        .every((including) => including),
    );
  }

  private insertHomogenousSearchIndex(data: any[], fields: string[]): any[] {
    return data.map((item) => {
      item.homogenIndex = this.generateHomogenousSearchIndex(item, fields);
      return item;
    });
  }

  private generateHomogenousSearchIndex(object: any, fields: string[]): string {
    let chainedString = '';
    for (const field in object) {
      if (fields.includes(field)) {
        const data = object[field];

        if (data === null || data === undefined) {continue;}

        if (data instanceof Date && !isNaN(data.getTime())) {
          chainedString += this.transformDate(data);
        } else if (field.toLowerCase() === 'size' && (typeof data).toLowerCase() === 'number') {
          chainedString += this.fileSizePipe.transform(data, { roundingMethod: 'ceil', round: 0 });
        } else if ((typeof data).toLocaleLowerCase() === 'object') {
          chainedString += this.extractTextFromObject(data);
        } else {
          chainedString += data;
        }
      }
    }
    return this.transformToHomogenousString(chainedString);
  }

  private transformDate(date: Date): string {
    const formattedDate = this.localeDatePipe.transform(
      date,
      'EEEE MM dd MMMM YYYY dd MM MMMM YYYY MM dd YYYY dd MM YYYY MM dd YY dd MM YY',
    );
    return this.transformToHomogenousString(formattedDate);
  }

  private transformToHomogenousString(unhomogenousString: string): string {
    return (
      unhomogenousString
        ?.match(/[A-z,0-9äöüß]/g)
        ?.join('')
        .toLowerCase() || ''
    );
  }

  private extractTextFromObject(object: any): string {
    let text = '';
    for (const key in object) {
      if ((typeof object[key]).toLocaleLowerCase() === 'string') {
        text += object[key];
      } else if ((typeof object[key]).toLocaleLowerCase() === 'object') {
        text += this.extractTextFromObject(object[key]);
      }
    }
    return text;
  }
}
