import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicForm } from 'projects/core/src/lib/models/form.model';
import { SDAPIResponseObject } from 'projects/core/src/lib/models/sdapi-object.model';
import { FormService } from 'projects/core/src/lib/services/form.service';
import { LanguageService } from 'projects/core/src/lib/services/language.service';
import { take } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { Introduction } from '../swiper.model';

@UntilDestroy()
@Component({
  selector: 'lib-swiper-container',
  templateUrl: './swiper-container.component.html',
  styleUrls: ['./swiper-container.component.scss'],
})
export class SwiperContainerComponent {
  @ViewChild('swiper') swiper: SwiperComponent;

  @Input() config: SwiperOptions;
  @Input() set slides(slides: Introduction[]) {
    if (slides) {
      this.slideCollection = slides;
      this.updateProfileLanguageValue();
    }
  }

  slideCollection: Introduction[];
  isStart = true;

  readonly icons = {
    prev: faArrowLeft,
    next: faArrowRight,
  };

  constructor(
    private router: Router,
    private formService: FormService,
    private languageService: LanguageService,
  ) {}

  get activeSlide(): Introduction {
    return this.slideCollection[this.swiper.swiperRef.activeIndex];
  }

  private updateProfileLanguageValue(): void {
    if (this.activeSlide.form) {
      this.activeSlide.form = this.languageService.updateProfileLanguageValue(
        this.activeSlide.form,
      );
    }
  }

  setIcon(): void {
    this.isStart = this.swiper.swiperRef.activeIndex === 0;
    this.icons.next =
      this.swiper.swiperRef.activeIndex !== this.swiper.swiperRef.virtual.slides.length - 1
        ? faArrowRight
        : faCheck;
  }

  slide(direction: string): void {
    this.setIcon();
    this.handleSlideWithFormFields();

    if (!this.swiper.swiperRef.isEnd || direction === 'prev') {
      if (direction === 'next') {this.swiper.swiperRef.slideNext();}
      else if (direction === 'prev') {this.swiper.swiperRef.slidePrev();}
    } else {
      this.router.navigate(['/portal']);
    }
  }

  private handleSlideWithFormFields(): void {
    if (this.activeSlide.form) {
      this.saveForm(this.activeSlide.form);
    }
  }

  private saveForm(form: DynamicForm): void {
    this.formService
      .saveForm<SDAPIResponseObject>(form)
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.languageService.syncCurrentLanguageWithProfileForm(form);
      });
  }

  get skeletonCountAsArray(): Array<number> {
    return Array(3).fill(0);
  }
}
