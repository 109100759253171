import { Component, OnInit } from '@angular/core';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  DefaultLanguageCode,
  defaultLanguageCodeToName,
} from 'projects/core/src/lib/models/language.model';
import {
  STORAGE_KEY_SELECTED_LANGUAGE_CODE,
  StorageService,
} from 'projects/core/src/lib/services/storage.service';

@Component({
  selector: 'lib-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements OnInit {
  availableLanguageCodes: DefaultLanguageCode[] = [];
  selectedLanguageCode: DefaultLanguageCode | string;

  readonly icons = {
    language: faGlobe,
  };

  constructor(
    private translateService: TranslateService,
    private popoverController: PopoverController,
    private storage: StorageService,
  ) {
    this.translateService.addLangs(Object.values(DefaultLanguageCode));
    this.availableLanguageCodes = this.translateService.getLangs() as DefaultLanguageCode[];
  }

  ngOnInit() {
    this.setInitialLanguageValueFromStorage();
  }

  private async setInitialLanguageValueFromStorage(): Promise<void> {
    const storedCode = (await this.storage.get(
      STORAGE_KEY_SELECTED_LANGUAGE_CODE,
    )) as DefaultLanguageCode;
    this.selectedLanguageCode = this.isDisplayableLanguageCode(storedCode)
      ? storedCode
      : this.translateService.defaultLang;
  }

  saveSelectedLanguage(): void {
    this.popoverController.dismiss(this.selectedLanguageCode);
  }

  getLanguageName(code: DefaultLanguageCode): string {
    return defaultLanguageCodeToName[code].toLocaleLowerCase();
  }

  isDisplayableLanguageCode(code: DefaultLanguageCode): boolean {
    return Object.values(DefaultLanguageCode).includes(code);
  }
}
