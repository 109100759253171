<lib-modal-header [title]="requiredAction.title" [titleIcon]="icons.clipboard" [style]="'view'">
</lib-modal-header>

<ion-content>
  <lib-auto-layout
    [ngClass]="{
      urgent: requiredAction.isUrgent,
      past: requiredAction.isOverdue
    }"
    [gap]="[10]"
    [padding]="[10, 20]"
    vertical="center">
    <fa-icon [icon]="icons.clock"></fa-icon>
    <div>
      @if (remainingDaysNotification) {
        <span class="p">
          <strong>{{ remainingDaysNotification + ' ' }}</strong>
        </span>
      }
      <span class="p" [class.strong]="!remainingDaysNotification">{{ dueDateNotification }}</span>
    </div>
  </lib-auto-layout>

  <lib-auto-layout direction="vertical" [gap]="[10]" [padding]="[20]">
    <lib-auto-layout [gap]="[10]" vertical="center">
      <fa-icon [icon]="icons.info"></fa-icon>
      <p class="title">
        <strong>{{ requiredAction.task.label }}</strong>
      </p>
    </lib-auto-layout>

    <p>{{ requiredAction.task.value }}</p>
  </lib-auto-layout>

  <lib-auto-layout direction="vertical" [gap]="[10]" [padding]="[20, 20, 10, 20]">
    <lib-auto-layout [gap]="[10]" vertical="center">
      <fa-icon [icon]="icons.calendarPlus"></fa-icon>
      <p class="title">
        <strong>{{ requiredAction.startDate.label }}</strong>
      </p>
    </lib-auto-layout>

    <p>{{ requiredAction.startDate.value | localeDate: 'longDate' }}</p>
  </lib-auto-layout>

  @for (detail of requiredAction.details | detailsToList: iconSet; track $index) {
    <lib-auto-layout direction="vertical" [gap]="[10]" [padding]="[20, 20, 10, 20]">
      <lib-auto-layout [gap]="[10]" vertical="center">
        <fa-icon [icon]="detail.icon"></fa-icon>
        <p class="title">
          <strong>{{ detail.label }}</strong>
        </p>
      </lib-auto-layout>

      <p>{{ detail.value }}</p>
    </lib-auto-layout>
  }
</ion-content>

<ion-footer>
  <lib-auto-layout horizontal="end" [padding]="[10]" class="button-section">
    <ion-button
      expand="block"
      color="primary"
      class="p3"
      [class.stretch-self]="breakpoint.isBelow('md')"
      (click)="dismissModalWithEventMessage()">
      <span>{{ 'shared.required-action.proceed-to-edit' | translate }}</span>
      <fa-icon slot="end" size="sm" [icon]="icons.arrow"></fa-icon>
    </ion-button>
  </lib-auto-layout>
</ion-footer>
