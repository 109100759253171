<ion-datetime
  [value]="value"
  [name]="name"
  presentation="date"
  slot="content"
  color="medium"
  firstDayOfWeek="1"
  (ionChange)="onDateChange($event)"
  [locale]="languageService.getCurrentLanguageCode()"
  [doneText]="'general.confirm' | translate"
  [cancelText]="'general.cancel' | translate"
  [isDateEnabled]="checkDates"
  [min]="minDate"
  [max]="maxDate"
  [showDefaultButtons]="false"
  #datePicker>
</ion-datetime>
<div class="dt-footer-buttons">
  <ion-buttons>
    <ion-button (click)="cancelBtn()" color="medium">{{ 'general.cancel' | translate }}</ion-button>
    <div class="datetime-action-buttons-container">
      <ion-button (click)="confirmBtn()" color="medium">{{
        'general.confirm' | translate
      }}</ion-button>
    </div></ion-buttons
  >
</div>
