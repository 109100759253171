export const groupArrayByKey = <T>(sortedArray: Array<T>, compareKey: keyof T): T[][] => {
  if (sortedArray.length === 0) {
    return [];
  }
  if (sortedArray.length === 1) {
    return [sortedArray];
  }

  const result: T[][] = [[]];

  let previousCompareKey = sortedArray[0][compareKey];
  for (const item of sortedArray) {
    if (previousCompareKey !== item[compareKey]) {
      result.push([item]);
      previousCompareKey = item[compareKey];
    } else {
      result[result.length - 1].push(item);
    }
  }

  return result;
};

export const arrayMove = (array: any[], oldIndex: number, newIndex: number) => {
  const element = array[oldIndex];
  array.splice(oldIndex, 1);
  array.splice(newIndex, 0, element);
};
