import { Component, Input } from '@angular/core';
import {
  TableFilterSection,
  TableHeaderItem,
  TableList,
  TableRowState,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-data-organizer-toolbox',
  templateUrl: './data-organizer-toolbox.component.html',
  styleUrls: ['./data-organizer-toolbox.scss'],
})
export class DataOrganizerToolboxComponent {
  @Input() headerReordering: BehaviorSubject<TableHeaderItem[]>;
  @Input() table: TableList;
  @Input() rowState: TableRowState;

  activeFilterSection: TableFilterSection = TableFilterSection.horizontalSorting;

  readonly filterSectionType: typeof TableFilterSection = TableFilterSection;
}
