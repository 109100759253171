<ion-content scrollX="false" scrollY="false">
  @if (shouldShowVideoPlayer) {
    <div class="video-container stretch-self">
      <video controls>
        <source [src]="video.viewLink" type="video/mp4" />
        {{ 'shared.documents.video-tag-not-supported' | translate }}
      </video>
    </div>
  } @else {
    <div
      class="notification-container stretch-self ion-justify-content-center ion-align-items-center">
      <ion-card class="card-transparent">
        <div class="info-section">
          <div class="sub-info-section">
            <fa-icon
              size="5x"
              class="greyish"
              [icon]="video.fileType | documentFormatToIconPipe"></fa-icon>
            <div>
              <h2>
                <strong>{{ 'shared.documents.video-warning-title' | translate }}.</strong>
              </h2>
              <p *ngIf="video.size">
                {{ 'shared.documents.file-size' | translate }}: {{ video.size | filesize }}
              </p>
            </div>
          </div>
          <p class="before-download-info p">
            {{ 'shared.documents.video-warning-message' | translate }}
          </p>
          <p class="before-download-info p">
            {{ 'shared.documents.video-warning-message-2' | translate }}
          </p>
        </div>
        <div class="button-section ion-justify-content-center ion-align-items-center">
          <ion-button
            class="p1 ion-no-margin"
            color="primary"
            expand="block"
            (click)="showPlayer()">
            <fa-icon
              class="ion-text-center ion-align-self-center"
              size="sm"
              slot="start"
              [icon]="ICONS.play"></fa-icon>
            {{ 'general.play' | translate }}
          </ion-button>
          <ion-button
            class="p1 ion-no-margin"
            color="cancel-button"
            expand="block"
            (click)="close()">
            {{ 'general.cancel' | translate }}
          </ion-button>
        </div>
      </ion-card>
    </div>
  }
</ion-content>
