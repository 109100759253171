import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DynamicButton } from 'projects/core/src/lib/models/dynamic-button.model';
import { LabelService } from 'projects/core/src/lib/services/label.service';

@Component({
  selector: 'lib-label-print-button',
  templateUrl: './label-print-button.component.html',
})
export class LabelPrintButtonComponent {
  @ViewChild('iframe') iframe: ElementRef;

  @Input({ required: true }) button: DynamicButton;

  constructor(private labelService: LabelService) {}

  printLabel(): void {
    this.labelService.printLabel(this.iframe);
  }
}
