import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Injectable()
export class DomSanitizerService {
  constructor(private domSanitizer: DomSanitizer) {}

  public sanitizeHtml(unsafeHtml: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(
      DOMPurify.sanitize(unsafeHtml, { FORCE_BODY: true }),
    );
  }

  public sanitizeResourceUrl(unsafeResourceUrl: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(DOMPurify.sanitize(unsafeResourceUrl));
  }

  public sanitizeUrl(unsafeUrl: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(DOMPurify.sanitize(unsafeUrl));
  }

  public sanitizeSafeResourceUrlForDownload(url: SafeResourceUrl): string {
    return this.domSanitizer.sanitize(SecurityContext.URL, url);
  }
}
