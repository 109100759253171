import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { DomSanitizerService } from 'projects/core/src/lib/services/dom-sanitizer.service';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private domSanitizerService: DomSanitizerService) {}

  transform(unsafeHtml: string | SafeHtml): SafeHtml {
    return this.domSanitizerService.sanitizeHtml(unsafeHtml as string);
  }
}
