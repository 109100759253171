import { DocumentDetails } from './documents.model';
import { TableList } from './dynamic-table.model';
import { DynamicForm } from './form.model';
import { InvokerMethods } from './invoker-body.model';
import { SDAPIResponseObject } from './sdapi-object.model';

export const MedicalCertificateTranslationKeys = new Map<OperationKey, string>([
  ['document-fetching', 'shared.medical-certificates.fetching-medical-certificate-document'],
  ['edit-form-fetching', 'shared.medical-certificates.fetching-medical-certificate-edit-form'],
  ['view-form-fetching', 'shared.medical-certificates.fetching-medical-certificate-view-form'],
  ['delete-form-fetching', 'shared.medical-certificates.fetching-medical-certificate-delete-form'],
  ['update-form-fetching', 'shared.medical-certificates.fetching-medical-certificate-update-form'],
  ['upload-form-fetching', 'shared.medical-certificates.fetching-upload-attachment-form'],
  [
    'table-information-fetching',
    'shared.medical-certificates.fetching-medical-certificate-information',
  ],
  ['delete-completion', 'shared.medical-certificates.medical-certificate-deleted'],
  ['commit-completion', 'shared.medical-certificates.medical-certificate-committed'],
  ['update-completion', 'shared.medical-certificates.medical-certificate-update-completed'],
  ['create-completion', 'shared.medical-certificates.medical-certificate-created'],
  ['saving-in-progress', 'shared.medical-certificates.saving-in-progress'],
  ['deleting-in-progress', 'shared.modal-actions.deleting-in-progress'],
]);

export const ExpenseTranslationKeys = new Map<OperationKey, string>([
  ['document-fetching', 'shared.expenses.fetching-expense-document'],
  ['edit-form-fetching', 'shared.expenses.fetching-expense-edit-form'],
  ['view-form-fetching', 'shared.expenses.fetching-expense-view-form'],
  ['delete-form-fetching', 'shared.expenses.fetching-expense-delete-form'],
  ['update-form-fetching', 'shared.expenses.fetching-expense-update-form'],
  ['upload-form-fetching', 'shared.expenses.fetching-upload-attachment-form'],
  ['table-information-fetching', 'shared.expenses.fetching-expense-information'],
  ['delete-completion', 'shared.expenses.expense-deleted'],
  ['commit-completion', 'shared.expenses.expense-committed'],
  ['update-completion', 'shared.expenses.expense-update-completed'],
  ['create-completion', 'shared.expenses.expense-created'],
  ['saving-in-progress', 'shared.expenses.saving-in-progress'],
  ['deleting-in-progress', 'shared.modal-actions.deleting-in-progress'],
]);

export const RequiredTaskTranslationKeys = new Map<OperationKey, string>([
  ['document-fetching', 'shared.required-action.fetching-task-document'],
  ['edit-form-fetching', 'shared.required-action.fetching-task-edit-form'],
  ['view-form-fetching', 'shared.required-action.fetching-task-view-form'],
  ['delete-form-fetching', 'shared.required-action.fetching-task-delete-form'],
  ['update-form-fetching', 'shared.required-action.fetching-task-update-form'],
  ['upload-form-fetching', 'shared.required-action.fetching-upload-attachment-form'],
  ['table-information-fetching', 'shared.required-action.fetching-task-information'],
  ['delete-completion', 'shared.required-action.task-deleted'],
  ['saving-in-progress', 'shared.required-action.saving-in-progress'],
  ['commit-completion', 'shared.required-action.task-committed'],
  ['update-completion', 'shared.required-action.task-update-completed'],
]);

export const NotesTranslationKeys = new Map<OperationKey, string>([
  ['document-fetching', 'shared.notes.fetching-note-document'],
  ['edit-form-fetching', 'shared.notes.fetching-note-edit-form'],
  ['view-form-fetching', 'shared.notes.fetching-note-view-form'],
  ['delete-form-fetching', 'shared.notes.fetching-note-delete-form'],
  ['update-form-fetching', 'shared.notes.fetching-note-update-form'],
  ['upload-form-fetching', 'shared.notes.fetching-upload-attachment-form'],
  ['table-information-fetching', 'shared.notes.fetching-note-information'],
  ['delete-completion', 'shared.notes.note-deleted'],
  ['commit-completion', 'shared.notes.note-committed'],
  ['update-completion', 'shared.notes.note-update-completed'],
]);

export const DocumentTranslationKeys = new Map<OperationKey, string>([
  ['document-fetching', 'shared.documents.fetching-document'],
  ['edit-form-fetching', 'shared.documents.fetching-document-edit-form'],
  ['view-form-fetching', 'shared.documents.fetching-document-view-form'],
  ['delete-form-fetching', 'shared.documents.fetching-document-delete-form'],
  ['update-form-fetching', 'shared.documents.fetching-document-update-form'],
  ['upload-form-fetching', 'shared.documents.fetching-document-upload-form'],
  ['table-information-fetching', 'shared.documents.fetching-document-information'],
  ['delete-completion', 'shared.documents.document-deleted'],
  ['commit-completion', 'shared.documents.document-committed'],
  ['update-completion', 'shared.documents.document-update-completed'],
  ['upload-completion', 'shared.documents.document-upload-completed'],
  ['saving-in-progress', 'shared.documents.saving-in-progress'],
  ['upload-in-progress', 'shared.documents.document-upload-in-progress'],
]);

export const GenericTranslationKeys = new Map<OperationKey, string>([
  ['document-fetching', 'shared.modal-actions.fetching-document'],
  ['edit-form-fetching', 'shared.modal-actions.fetching-edit-form'],
  ['view-form-fetching', 'shared.modal-actions.fetching-view-form'],
  ['delete-form-fetching', 'shared.modal-actions.fetching-delete-form'],
  ['update-form-fetching', 'shared.modal-actions.fetching-update-form.'],
  ['upload-form-fetching', 'shared.modal-actions.fetching-upload-attachment-form'],
  ['table-information-fetching', 'shared.modal-actions.fetching-information'],
  ['delete-completion', 'shared.modal-actions.delete-completion'],
  ['commit-completion', 'shared.modal-actions.commit-completion'],
  ['update-completion', 'shared.modal-actions.update-completed'],
  ['saving-in-progress', 'shared.modal-actions.saving-in-progress'],
  ['deleting-in-progress', 'shared.modal-actions.deleting-in-progress'],
]);

export const UserDeactivationTranslationKeys = new Map<OperationKey, string>([
  ['create-completion', 'shared.user-deactivation.user-successfully-deactivated'],
  ['saving-in-progress', 'shared.user-deactivation.user-is-deactivating'],
]);

export const TreatmentCreationTranslationKeys = new Map<OperationKey, string>([
  ['create-completion', 'shared.treatments.treatment-created'],
  ['saving-in-progress', 'shared.treatments.treatment-is-being-created'],
]);

export const PatientCreationTranslationKeys = new Map<OperationKey, string>([
  ['save-completion', 'shared.patients.patient-created'],
  ['saving-in-progress', 'shared.patients.patient-is-being-created'],
]);

export const TreatmentSharingTranslationKeys = new Map<OperationKey, string>([
  ['save-completion', 'shared.treatments.treatment-shared'],
  ['saving-in-progress', 'shared.treatments.treatment-is-being-shared'],
]);

export const OrdersSharingTranslationKeys = new Map<OperationKey, string>([
  ['save-completion', 'shared.orders.order-created'],
  ['saving-in-progress', 'shared.orders.order-is-being-created'],
]);

export const PatientDataTranslationKeys = new Map<OperationKey, string>([
  ['save-completion', 'shared.patients.patient-data-saved'],
  ['saving-in-progress', 'shared.patients.patient-data-is-saving'],
]);

export const SetShowTranslationKeys = new Map<OperationKey, string>([
  ['save-completion', 'shared.patients.access-to-patient-granted'],
  ['saving-in-progress', 'shared.patients.access-to-patient-is-being-granted'],
]);

export const ProfileTranslationKeys = new Map<OperationKey, string>([
  ['save-completion', 'shared.profile.profile-saved'],
  ['saving-in-progress', 'shared.profile.profile-is-being-saved'],
]);

export const AfterCareTranslationKeys = new Map<OperationKey, string>([
  ['create-completion', 'shared.aftercare.aftercare-created'],
  ['saving-in-progress', 'shared.aftercare.aftercare-is-being-created'],
]);

export type OperationKey =
  | 'document-fetching'
  | 'edit-form-fetching'
  | 'view-form-fetching'
  | 'delete-form-fetching'
  | 'update-form-fetching'
  | 'upload-form-fetching'
  | 'table-information-fetching'
  | 'saving-in-progress'
  | 'deleting-in-progress'
  | 'upload-in-progress'
  | 'save-completion'
  | 'delete-completion'
  | 'commit-completion'
  | 'update-completion'
  | 'upload-completion'
  | 'create-completion';

export interface ModalResult {
  event: ModalEvent;
  data?: SDAPIResponseObject;
}

export enum ModalEvent {
  dataReload,
  stateChange,
  processCompleted,
  processFailed,
  processCanceled,
}

export interface TranslationOptions {
  keys: Map<OperationKey, string>;
  successMessageKey: OperationKey;
  actionInProgressKey?: OperationKey;
}

export type ViewResult = DynamicForm | TableList | DocumentDetails;

export interface InvokerMethodCollection {
  preferred: InvokerMethods;
  default?: InvokerMethods;
}
