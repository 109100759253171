import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RenderTextMode } from 'ng2-pdf-viewer';
import { DocumentsService } from 'projects/core/src/lib/services/documents.service';
import { loadPdfViewer } from 'projects/core/src/lib/utils/external-libs-loader.utils';
import { take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'lib-pdf-viewer',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Output() commitButtonsVisibility = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();
  @Input() url: string;
  isLoading = true;
  pdfSrc: string;
  renderTextMode: typeof RenderTextMode = RenderTextMode;
  zoomProperties = {
    backgroundColor: 'rgba(0,0,0,0)',
  };

  constructor(private documentsService: DocumentsService) {
    void loadPdfViewer();
  }

  ngOnInit(): void {
    this.documentsService
      .assemblePdfViewerObject(this.url)
      .pipe(take(1), untilDestroyed(this))
      .subscribe((pdfObject: string) => (this.pdfSrc = pdfObject));
  }

  getRowAmount(): any[] {
    const offsetHeader = 110;
    const rowHeight = 29;
    const rowAmount = Math.ceil((window.innerHeight - offsetHeader) / rowHeight);
    return new Array(rowAmount);
  }

  afterLoadComplete() {
    this.isLoading = false;
    this.loading.emit(this.isLoading);
    this.commitButtonsVisibility.emit(true);
  }
}
