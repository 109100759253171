import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[grow]',
})
export class AutoGrowDirective implements OnInit, OnChanges {
  @Input() grow: number;

  constructor(private elementReference: ElementRef) {}

  ngOnInit(): void {
    this.setGrow();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['grow']) {
      this.setGrow();
    }
  }

  setGrow(): void {
    const style = this.elementReference.nativeElement.style;

    style.flex = `${this.grow}`;
    style.minWidth = '0px';
    style.width = '100%';
  }
}
