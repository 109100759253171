import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { MarkdownService } from 'projects/core/src/lib/services/markdown.service';
import { APIError } from '../data/errors.data';
import {
  PrivacyPolicy,
  PrivacyPolicyAttributeNames,
  PrivacyPolicyFormatType,
} from '../models/privacy-policy.model';
import {
  TieTableObjectList,
  TieTableProcessor,
  TieTableRow,
} from '../models/sdapi-table-object.model';
import { SharedMapper } from './shared.mapper';

@Injectable({ providedIn: 'root' })
export class PrivacyPolicyMapper {
  constructor(private markdownService: MarkdownService) {}

  public mapResource(resource: TieTableObjectList): PrivacyPolicy {
    const privacyPolicy = new PrivacyPolicy();
    const table: TieTableProcessor = new TieTableProcessor(resource);
    const firstRow: TieTableRow = table.getRows()[0];

    try {
      if (firstRow) {
        const type: string = firstRow.getOptionalAttributeValue(PrivacyPolicyAttributeNames.type);
        const content: string = firstRow.getOptionalAttributeValue(
          PrivacyPolicyAttributeNames.content,
        );

        privacyPolicy.formatType = SharedMapper.parseEnum(PrivacyPolicyFormatType, type);
        privacyPolicy.content = this.mapContent(content, privacyPolicy.formatType);
      }
    } catch (error) {
      throw new APIError(
        'Mapping of privacy policy details failed in the PrivacyPolicyMapper.',
        error,
      );
    }

    return privacyPolicy;
  }

  public mapContent(content: string, type: PrivacyPolicyFormatType): string | SafeHtml {
    if (!content || !type) {return null;}

    switch (type) {
      case PrivacyPolicyFormatType.TEXT:
        return content.replace(/(\r\n|\n|\r|;\s)/gm, '<br>');
      case PrivacyPolicyFormatType.HTML:
        return content;
      case PrivacyPolicyFormatType.MD:
        return this.markdownService.parse(content);
      default:
        return '';
    }
  }
}
