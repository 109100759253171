<lib-auto-layout
  direction="horizontal"
  horizontal="stretch"
  [padding]="[10, 20]"
  class="modal-button-container">
  <lib-action-buttons-container
    [config]="configuration"
    (actionTrigger)="actionTrigger.emit($event)"
    [grow]="1">
  </lib-action-buttons-container>
</lib-auto-layout>
