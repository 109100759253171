@if (item.isProcessed) {
  <div class="form-input-loader">
    <img src="shared/assets/images/loading-spinner.svg" alt="Loading Animation" />
    <span class="p strong">{{ 'shared.forms.refresh-options' | translate }}</span>
  </div>
} @else {
  <div class="title-section field-section" lines="none">
    <h3>{{ item.name }}</h3>
  </div>
}
