import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';
import { APP_BASE_HREF } from '@angular/common';

@Injectable()
export class RouterService {
  constructor(
    private router: Router,
    @Inject(APP_BASE_HREF) private baseHref: string,
  ) {}

  public waitForRouterNavigationToEnd(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1),
        )
        .subscribe(() => {
          resolve();
        });
    });
  }

  checkIfCurrentRouteEquals(path: string): boolean {
    const pathWithoutLeadingSlash: string = path.startsWith('/') ? path.substring(1) : path;
    return `${this.baseHref}${pathWithoutLeadingSlash}` === document.location.pathname.substring(1);
  }
}
