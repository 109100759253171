import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faBars, faPen } from '@fortawesome/free-solid-svg-icons';
import { ItemReorderEventDetail } from '@ionic/angular';
import {
  HeaderItemVisibility,
  TableHeaderItem,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-horizontal-sorting-view-mode',
  templateUrl: './horizontal-sorting-view-mode.component.html',
  styleUrls: ['../../data-organizer-toolbox.scss'],
})
export class HorizontalSortingViewModeComponent {
  @Output() editModeToggle: EventEmitter<boolean> = new EventEmitter();

  @Input() header: TableHeaderItem[];
  @Input() headerReordering: BehaviorSubject<TableHeaderItem[]>;
  @Input() visibleHeaderItems: TableHeaderItem[];
  @Input() hiddenHeaderItems: TableHeaderItem[];

  readonly visibilityType: typeof HeaderItemVisibility = HeaderItemVisibility;
  readonly icons = { menu: faBars, pen: faPen };

  handleColumnReorder(
    event: CustomEvent<ItemReorderEventDetail>,
    header: TableHeaderItem[],
    visibilityType: HeaderItemVisibility,
  ): void {
    this.handleReorderOfVisibleOrHiddenItems(event, header, visibilityType);
    this.header = [...this.visibleHeaderItems, ...this.hiddenHeaderItems];
    this.headerReordering.next(this.header);
  }

  private handleReorderOfVisibleOrHiddenItems(
    event: CustomEvent<ItemReorderEventDetail>,
    header: TableHeaderItem[],
    visibilityType: HeaderItemVisibility,
  ): void {
    if (visibilityType === this.visibilityType.visible) {
      this.visibleHeaderItems = event.detail.complete(header);
    }
    if (visibilityType === this.visibilityType.hidden) {
      this.hiddenHeaderItems = event.detail.complete(header);
    }
  }

  activateEditMode(): void {
    this.editModeToggle.emit(true);
  }

  get hasHiddenHeaderItems(): boolean {
    return !!this.hiddenHeaderItems.length;
  }
}
