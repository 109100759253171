<lib-modal-header
  [titleIcon]="icons.filter"
  [title]="'general.filter' | translate"
  [style]="'view'"
  [hasVerticalPadding]="false"></lib-modal-header>

<lib-data-organizer-toolbox
  class="stretch-self"
  [table]="table"
  [rowState]="rowState"
  [headerReordering]="headerReordering"></lib-data-organizer-toolbox>
