import { Directive, Inject, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppName } from 'projects/core/src/lib/models/app.model';

@Directive({
  selector: '[zpAndKpOnly]',
})
export class ZpAndKpOnlyDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject('AppName') private appName: AppName,
  ) {}

  ngOnInit() {
    this.appName === AppName.ZP || this.appName === AppName.KP
      ? this.viewContainer.createEmbeddedView(this.templateRef)
      : this.viewContainer.clear();
  }
}
