import { Injectable, Injector, runInInjectionContext } from '@angular/core';
import { APIError } from '../data/errors.data';
import {
  DataObject,
  DataType,
  DependenceDefinition,
  DynamicDataField,
  DynamicForm,
  FieldTriggerValue,
  FileTypeData,
  FormatOptions,
  FormIdentifier,
  LabelData,
} from '../models/form.model';
import {
  CollectionTemplateResource,
  MultiChosenCollector,
  MultiChosenData,
} from '../models/grid.model';
import { Invoker, InvokerTypes } from '../models/invoker-body.model';
import {
  AttributeFieldType,
  AttributeValue,
  AttributeValueCollection,
  FormatOptionIdentifier,
  FormItem,
  FormItemType,
  MandatoryAttributeType,
  TieFormObject,
} from '../models/sdapi-form-object.model';
import { AttributeNameIdentifier, SystemAttributeName } from '../models/shared.model';
import { FormMapper } from './form.mapper';
import { SDAPIObjectMapper } from './sdapi-object.mapper';
import { TabFormMapper } from './tab-form.mapper';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormMapper {
  constructor(private injector: Injector) {}

  public mapDynamicFormResource(
    resource: TieFormObject,
    activityURL: string,
    isInteractive: boolean = true,
    skipInvokers?: boolean,
    isPublicRequest?: boolean,
  ): DynamicForm {
    const attributeValues: AttributeValueCollection = resource.attributeValues;
    const bodyItems: FormItem[] =
      resource.showTypes.BODY?.items ?? this.handleBodyItemsAbsence(resource, isInteractive);
    const sortedBodyItems: FormItem[] = FormMapper.sortBySequence(bodyItems);

    if (resource.showTypes.MBODY) {
      const items: FormItem[] = this.getProcessesMbodyItemsWithModifiedLayout(
        resource,
        sortedBodyItems,
      );
      sortedBodyItems.push(...FormMapper.sortBySequence(items));
    }

    let body: DynamicDataField[] = this.groupMappedBodyItemsByType(
      sortedBodyItems,
      attributeValues,
    );

    if (resource.showTypes.TAB) {
      this.warnOnTabAndBodyConflict(resource);
      body = TabFormMapper.mapTabItems(resource, attributeValues);
    }

    let multiChosen: MultiChosenData;
    if (resource.showTypes.CHOOSE && resource.showTypes.CHOSEN) {
      multiChosen = this.getProcessedMultiChosenData(resource);
    }

    let fileTypeData: FileTypeData[];
    if (resource.objclassID?.length) {
      fileTypeData = this.getProcessedFileTypeData(resource);
    }

    let header: DynamicDataField[];
    if (resource.showTypes.HEADER) {
      header = this.getProcessedFormHeader(resource);
    }

    let invokerArray: Invoker[];
    if (!skipInvokers) {
      invokerArray = FormMapper.resolveStepInvoker(
        [InvokerTypes.SAVE, InvokerTypes.SEND, InvokerTypes.SEARCH],
        activityURL,
        resource,
      );
    }

    this.completeAutocompleteFieldsRecursive(body, activityURL);
    let form: DynamicForm;
    runInInjectionContext(this.injector, () => {
      form = new DynamicForm({
        activityURL,
        title: resource.windowName,
        body,
        header,
        invoker: invokerArray,
        fileTypeData,
        isPublicAccessed: isPublicRequest,
        multiChosen,
        isInteractive,
      });
    });

    form.labelData = this.generateLabelPrintData(resource, bodyItems);
    return form;
  }

  private handleBodyItemsAbsence(resource: TieFormObject, isInteractive: boolean): FormItem[] {
    if (isInteractive) {
      console.warn(
        `DynamicFormMapper: BODY section is undefined in resource "${resource.windowName}" (id: ${resource.attributeProfileId}). Returning empty array.`,
      );
    }
    return [];
  }

  private warnOnTabAndBodyConflict(resource: TieFormObject): void {
    if (resource.showTypes.BODY) {
      console.warn(
        `DynamicFormMapper: Conflict detected in resource "${resource.windowName}" (id: ${resource.attributeProfileId}). Both TAB and BODY sections are present. Only items of the TAB section will be processed.`,
      );
    }
  }

  private completeAutocompleteFieldsRecursive(
    mappedItems: DynamicDataField[],
    activityUrl: string,
  ): void {
    mappedItems.forEach((item: DynamicDataField) => {
      if (item.type === DataType.autocomplete) {
        item.value.autocompleteOptions.activityUrl = activityUrl;
      }
      if (item.fieldGroup) {
        this.completeAutocompleteFieldsRecursive(item.fieldGroup, activityUrl);
      }
    });
  }

  private getProcessesMbodyItemsWithModifiedLayout(
    resource: TieFormObject,
    bodyItems: FormItem[],
  ): FormItem[] {
    const maxVPos: number = this.getMaximumVPos(bodyItems);
    const maxSeq: number = this.getMaximumSeq(bodyItems);
    return resource.showTypes.MBODY.items.map((item: FormItem) => {
      item.vPos += maxVPos;
      item.seq += maxSeq;
      return item;
    });
  }

  private getProcessedMultiChosenData(resource: TieFormObject): MultiChosenData {
    return {
      collector: DynamicFormMapper.mapMultiChosenCollector(resource),
      options: DynamicFormMapper.mapMultiChosenOptions(resource),
    };
  }

  private getProcessedFileTypeData(resource: TieFormObject): FileTypeData[] {
    return resource.objclassID.map((identifier: string, index: number): FileTypeData => {
      const name: string = resource.objclassName?.[index];
      const extension: string = resource.extValTmp?.[index].toLowerCase();
      return { identifier, name, extension };
    });
  }

  private getProcessedFormHeader(resource: TieFormObject): DynamicDataField[] {
    const sortedHeaderItems: FormItem[] = FormMapper.sortBySequence([
      ...resource.showTypes.HEADER.items,
    ]);
    if (sortedHeaderItems.length) {
      return DynamicFormMapper.mapResourceFormItemsToDynamicFormFields(
        sortedHeaderItems,
        resource.attributeValues,
      );
    }
    return [];
  }

  public getMaximumVPos(items: FormItem[]): number {
    return Math.max(...items.map((item) => item.vPos));
  }

  public getMaximumSeq(items: FormItem[]): number {
    return Math.max(...items.map((item) => item.seq));
  }

  private groupMappedBodyItemsByType(
    bodyItems: FormItem[],
    attributeValues: AttributeValueCollection,
  ): DynamicDataField[] {
    const mappedItems: DynamicDataField[] =
      DynamicFormMapper.mapResourceFormItemsToDynamicFormFields(bodyItems, attributeValues);

    this.addFormulaDependencies(mappedItems);

    return this.groupFieldsByType(mappedItems);
  }

  private getFormulaFieldsRecursive(
    mappedItems: DynamicDataField[],
    formulaFields: DynamicDataField[],
  ): void {
    mappedItems.forEach((item: DynamicDataField) => {
      if (item.value.formula) {
        formulaFields.push(item);
      }
      if (item.fieldGroup) {
        this.getFormulaFieldsRecursive(item.fieldGroup, formulaFields);
      }
    });
  }

  private addFormulaDependenciesRecursive(
    mappedItems: DynamicDataField[],
    formulaFields: DynamicDataField[],
  ): void {
    mappedItems.forEach((dataField: DynamicDataField) => {
      for (const formulaField of formulaFields) {
        const fields = formulaField?.value?.formula?.fields || [];
        const formulaTab = (formulaField?.value?.formula?.tab || '').toLocaleLowerCase();
        const dataFieldIdentifier = dataField.identifier.originalValue.toLocaleLowerCase();
        const isReadonlyOrIsSelectAndHasOnlyOneOption: boolean =
          dataField.readOnly || dataField.value.options?.length < 2;
        const belongsToFormula: boolean =
          !isReadonlyOrIsSelectAndHasOnlyOneOption &&
          !!fields.find((field) => dataFieldIdentifier.includes(field)) &&
          (!formulaTab || dataFieldIdentifier.includes(formulaTab));
        if (belongsToFormula) {
          if (dataField.dependentFormulaFields === undefined) {
            dataField.dependentFormulaFields = [];
          }
          dataField.dependentFormulaFields.push(formulaField);
        }
      }
      if (dataField.fieldGroup) {
        this.addFormulaDependenciesRecursive(dataField.fieldGroup, formulaFields);
      }
    });
  }

  private addFormulaDependencies(mappedItems: DynamicDataField[]): void {
    const formulaFields: DynamicDataField[] = [];
    this.getFormulaFieldsRecursive(mappedItems, formulaFields);
    this.addFormulaDependenciesRecursive(mappedItems, formulaFields);
  }

  private groupFieldsByType(formBody: DynamicDataField[]): DynamicDataField[] {
    const groupedFields: DynamicDataField[] = [];
    let temporaryGroup: DynamicDataField[] = [];

    formBody.forEach((item: DynamicDataField) => {
      const isGroupedItem = item.type === DataType.grouping;
      if (isGroupedItem) {
        this.addGeneratedNonEmptyGroup(temporaryGroup, groupedFields);
        temporaryGroup = [];
        groupedFields.push(item);
      } else {
        temporaryGroup.push(item);
      }
    });

    this.addGeneratedNonEmptyGroup(temporaryGroup, groupedFields);

    return groupedFields;
  }

  private addGeneratedNonEmptyGroup(
    temporaryGroup: DynamicDataField[],
    groupedFields: DynamicDataField[],
  ): void {
    if (temporaryGroup.length > 0) {
      const generatedGroup: DynamicDataField = DynamicFormMapper.createFieldGroup(temporaryGroup);
      groupedFields.push(generatedGroup);
    }
  }

  public static createFieldGroup(items: DynamicDataField[], title?: string): DynamicDataField {
    const dataField = new DynamicDataField();
    Object.assign(dataField, {
      name: title,
      type: DataType.grouping,
      fieldGroup: items,
      identifier: null,
      value: new DataObject(AttributeFieldType.grouping, null),
      visible: true,
    });
    return dataField;
  }

  private generateLabelPrintData(resource: TieFormObject, bodyItems: FormItem[]): LabelData {
    const hiddenFields: FormItem[] = [
      ...this.collectedHiddenFields(bodyItems),
      ...this.collectHiddenFieldsFromGroups(bodyItems),
    ];
    const labelPrintItem: FormItem = hiddenFields.find((item: FormItem) => {
      const itemSystemAttributeName = new SystemAttributeName(item.systemAttributeName);
      const labelPrintIdentifier = new SystemAttributeName(FormIdentifier.GENERIC_LABEL_PRINT);
      return itemSystemAttributeName.isEqualTo(labelPrintIdentifier);
    });
    return labelPrintItem ? this.extractLabelData(resource) : null;
  }

  private collectHiddenFieldsFromGroups(bodyItems: FormItem[]): FormItem[] {
    const hiddenFields: FormItem[] = [];

    for (const group of bodyItems) {
      if (group.showTypeGrouping) {
        hiddenFields.push(...this.collectedHiddenFields(group.showTypeGrouping.items));
      }
    }

    return hiddenFields;
  }

  private collectedHiddenFields(items: FormItem[]): FormItem[] {
    return items.filter((item) => item.t === FormItemType.hidden || item.hidden === true);
  }

  private extractLabelData(resource: TieFormObject): LabelData {
    const orderNumber = this.getSystemAttributeValues(FormIdentifier.ORDER_NUMBER, resource)[0];
    const contactId = this.getSystemAttributeValues(
      FormIdentifier.CONTACT_FAMILY_NAME,
      resource,
    )[0];
    const patientsGivenName = this.getSystemAttributeValues(
      FormIdentifier.PATIENTS_GIVEN_NAME,
      resource,
    )[0];
    const patientsFamilyName = this.getSystemAttributeValues(
      FormIdentifier.PATIENTS_FAMILY_NAME,
      resource,
    )[0];
    const patientsBirthDate = this.getSystemAttributeValues(
      FormIdentifier.PATIENTS_BIRTH_DATE,
      resource,
    )[0];
    const sampleNames = this.getSystemAttributeValues(FormIdentifier.ORDER_LABEL_SAMPLE, resource);

    return {
      orderNumber,
      contactId: contactId || undefined,
      patientsGivenName: patientsGivenName || undefined,
      patientsFamilyName: patientsFamilyName || undefined,
      patientsBirthDate: patientsBirthDate || undefined,
      sampleNames: sampleNames.length ? sampleNames : [undefined],
    };
  }

  private getSystemAttributeValues(
    prefillIdentifier: FormIdentifier,
    resource: TieFormObject,
  ): string[] {
    const bodyItems: FormItem[] = resource.showTypes.BODY?.items;

    const directBodyFields: FormItem[] = this.filterFieldsByPrefillIdentifier(
      prefillIdentifier,
      bodyItems,
    );
    const groupedBodyFields: FormItem[] = bodyItems
      .filter((group: FormItem) => group.showTypeGrouping)
      .flatMap((group: FormItem) =>
        this.filterFieldsByPrefillIdentifier(prefillIdentifier, group.showTypeGrouping.items),
      );

    const collectedFields: FormItem[] = [...directBodyFields, ...groupedBodyFields];

    return this.collectSystemAttributeValues(resource, collectedFields);
  }

  private filterFieldsByPrefillIdentifier(
    prefillIdentifier: FormIdentifier,
    items: FormItem[],
  ): FormItem[] {
    return items.filter((item: FormItem) => {
      const itemSystemAttributeIdentifier = new SystemAttributeName(item?.systemAttributeName);
      const filterIdentifier = new SystemAttributeName(prefillIdentifier);
      return itemSystemAttributeIdentifier.isEqualTo(filterIdentifier);
    });
  }

  private collectSystemAttributeValues(
    resource: TieFormObject,
    collectedFields: FormItem[],
  ): string[] {
    return collectedFields.flatMap((item: FormItem) => {
      const directAttribute: AttributeValue = resource.attributeValues[item.attributeName];

      if (directAttribute) {
        return directAttribute.displayValue || directAttribute.internalValue || [];
      } else {
        return this.getNestedSystemAttributeValues(resource, item.attributeName);
      }
    });
  }

  private getNestedSystemAttributeValues(resource: TieFormObject, attributeName: string): string[] {
    return Object.values(resource.attributeValues).flatMap((attribute: AttributeValue) => {
      const nestedAttribute: AttributeValue = attribute.attributeValues?.[attributeName];
      if (nestedAttribute) {
        return nestedAttribute.displayValue || nestedAttribute.internalValue || [];
      }
      return [];
    });
  }

  public getSearchInvoker(dynamicForm: DynamicForm): Invoker {
    const sendInvoker = dynamicForm.invoker.find(
      (invoker: Invoker) => invoker.type === InvokerTypes.SEARCH,
    );

    if (!sendInvoker) {
      throw new APIError('No Search Invoker found in boooking intermediate search step.');
    }

    return sendInvoker;
  }

  public filterOutInvisibleFormItems(form: DynamicForm): void {
    form.body = this.extractBodyWithVisibleFormItems(form);
  }

  public extractBodyWithVisibleFormItems(form: DynamicForm): DynamicDataField[] {
    const bodyWithVisibleFormItems = form.body.filter(
      (group: DynamicDataField) => group.visible !== false,
    );
    bodyWithVisibleFormItems.forEach((group: DynamicDataField) => {
      group.fieldGroup = group.fieldGroup.filter(
        (field: DynamicDataField) => field.visible !== false,
      );
    });
    return bodyWithVisibleFormItems;
  }

  public mapResponseToDynamicFormWithActivityUrl(
    response: TieFormObject,
    invoker: Invoker,
  ): DynamicForm {
    const activityUrl: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
    return this.mapDynamicFormResource(response, activityUrl);
  }

  public static assignSequentialGridValuesToFormItems(form: DynamicForm): DynamicForm {
    let rowCount = 1;
    form.body.forEach((group: DynamicDataField) => {
      group.fieldGroup.forEach((field: DynamicDataField) => {
        if (field.visible) {
          field.rowNum = rowCount++;
          field.rowSpan = 1;
          field.colSpan = 1;
          field.colNum = 1;
        }
      });
    });

    return form;
  }

  public static mapResourceFormItemsToDynamicFormFields(
    items: FormItem[],
    attributeValues: AttributeValueCollection,
  ): DynamicDataField[] {
    return items.map((item: FormItem) => {
      const {
        displayName: name,
        mandatory,
        hidden,
        readonly,
        attributeName: identifier,
        systemAttributeName: prefillIdentifier,
        colSpan,
        hPos: colNum,
        rowSpan,
        vPos: rowNum,
        checker,
        format,
        seq: sequence,
      } = item;

      const value = FormMapper.resolveTypedValue(
        item,
        FormMapper.getAttributeValuesOfField(attributeValues, identifier),
      );

      const prefillIdentifierMod: FormIdentifier =
        prefillIdentifier?.toLowerCase() as FormIdentifier;

      const dependence: DependenceDefinition = this.resolveDependencies(item);
      const dataField: DynamicDataField = Object.assign(new DynamicDataField(), {
        name,
        value,
        readOnly: mandatory === MandatoryAttributeType.fix || readonly === true,
        required: mandatory === MandatoryAttributeType.yes || mandatory === true,
        visible: hidden !== true,
        identifier: new AttributeNameIdentifier(identifier),
        prefillIdentifier: new SystemAttributeName(prefillIdentifierMod),
        type: FormMapper.resolveType(item),
        colSpan,
        colNum,
        rowSpan,
        rowNum,
        sequence,
        dependence,
        checker,
        formatOptions: new FormatOptions(format),
      } as DynamicDataField);

      dataField.hasTime = this.isDateFormatWithTime(item);

      if (item.showTypeGrouping) {
        dataField.fieldGroup = this.mapResourceFormItemsToDynamicFormFields(
          item.showTypeGrouping.items,
          attributeValues,
        );
      }

      if (item.conditionalAttributes) {
        this.mapConditionalAttributes(dataField, item);
      }

      return dataField;
    });
  }

  private static isDateFormatWithTime(item: FormItem) {
    const formatsWithDateAndTime = [
      FormatOptionIdentifier.g,
      FormatOptionIdentifier.G,
      FormatOptionIdentifier.s,
    ];
    return item.format && formatsWithDateAndTime.includes(item.format);
  }

  private static resolveDependencies(item: FormItem): DependenceDefinition | undefined {
    const { primary, secondary, name } = item.tagPrefs;

    if (!primary && !secondary) return undefined;

    const primaryGroupIndices: string[] = primary ? primary.split(',') : [];
    const secondaryGroupIndices: string[] = secondary ? secondary.split(',') : [];

    return new DependenceDefinition(
      name,
      item.popupObjId,
      primaryGroupIndices,
      secondaryGroupIndices,
    );
  }

  private static mapConditionalAttributes(dataField: DynamicDataField, item: FormItem): void {
    dataField.conditionalDependencies = Object.entries(item.conditionalAttributes).map(
      ([primaryFieldValue, dependantIdentifiers]) => {
        const identifiers: AttributeNameIdentifier[] = dependantIdentifiers.map(
          (identifier: string) => new AttributeNameIdentifier(identifier),
        );
        const fieldTriggerValue = new FieldTriggerValue(primaryFieldValue);
        return {
          fieldTriggerValue,
          dependantIdentifiers: identifiers,
        };
      },
    );
  }

  public static mapMultiChosenCollector(resource: TieFormObject): MultiChosenCollector {
    const gridItem: FormItem = this.mapGridItem(resource);
    const gridHeader: string[] = this.mapGridResourceToTableHeader(gridItem);
    const gridRows: DynamicDataField[][] = this.mapGridResourceToTableRows(resource);
    const defaultValues = this.mapAttributeValueArrayToCollection(
      gridItem,
      gridItem.defaultItem.attrValues,
    );
    const collectionTemplate: CollectionTemplateResource = {
      items: gridItem.columnTypes,
      attributeValueCollection: defaultValues,
      indexedAttributeValues: gridItem.defaultItem.attrValues,
    };

    return new MultiChosenCollector({
      title: gridItem.displayName,
      identifier: gridItem.attributeName,
      header: gridHeader,
      collection: gridRows,
      collectionTemplate,
    });
  }

  private static mapGridItem(resource: TieFormObject): FormItem {
    try {
      return resource.showTypes.CHOSEN.items[0];
    } catch (error) {
      throw new APIError('Mapping of grid item failed in the GridResourceMapper.', error);
    }
  }

  private static mapGridRows(resource: TieFormObject): AttributeValue[][] {
    try {
      const gridItem: FormItem = this.mapGridItem(resource);
      const attributeValueRow: AttributeValue = resource.attributeValues[gridItem.attributeName];
      return attributeValueRow.items.map((item) => item.attrValues);
    } catch (error) {
      throw new APIError('Mapping of grid rows failed in the GridResourceMapper.', error);
    }
  }

  private static mapGridResourceToTableHeader(resource: FormItem): string[] {
    try {
      return resource.columnTypes.map((item) => item.displayName);
    } catch (error) {
      throw new APIError('Mapping of grid header failed in the GridResourceMapper.', error);
    }
  }

  private static mapGridResourceToTableRows(resource: TieFormObject): DynamicDataField[][] {
    try {
      const gridItem: FormItem = this.mapGridItem(resource);
      const gridRows: AttributeValue[][] = this.mapGridRows(resource);

      return gridRows.map((row) => {
        const attributeValues = DynamicFormMapper.mapAttributeValueArrayToCollection(gridItem, row);
        return DynamicFormMapper.mapResourceFormItemsToDynamicFormFields(
          gridItem.columnTypes,
          attributeValues,
        );
      });
    } catch (error) {
      throw new APIError('Mapping of grid rows failed in the GridResourceMapper.', error);
    }
  }

  public static mapAttributeValueArrayToCollection(formItem: FormItem, row: AttributeValue[]) {
    try {
      const identifier: string[] = formItem.columnTypes.map((item) => item.attributeName);
      const mappedAttributeValueCollection: AttributeValueCollection = {};

      row.reduce((attributeValueCollection, value, i) => {
        attributeValueCollection[identifier[i]] = value;
        return attributeValueCollection;
      }, mappedAttributeValueCollection);

      return mappedAttributeValueCollection;
    } catch (error) {
      throw new APIError('Mapping of grid row values failed in the GridResourceMapper.', error);
    }
  }

  static mapMultiChosenOptions(resource: TieFormObject): DynamicDataField {
    try {
      return DynamicFormMapper.mapResourceFormItemsToDynamicFormFields(
        [resource.showTypes.CHOOSE.items[0]],
        resource.attributeValues,
      )[0];
    } catch (error) {
      throw new APIError(
        'Mapping of multi chosen options failed in the GridResourceMapper.',
        error,
      );
    }
  }
}
