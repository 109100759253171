<ion-header [ngClass]="style">
  <ion-toolbar
    id="modal-title-toolbar"
    [class.no-vertical-padding]="!hasVerticalPadding"
    [class.view-style-toolbar]="style === modalHeaderStyle.view">
    <h5 class="ion-no-margin modal-title">
      @if (titleIcon) {
        <fa-icon [icon]="titleIcon"></fa-icon>
      }
      <span id="title">{{ title }}</span>
    </h5>
    <lib-auto-layout class="header-toolbar-buttons" vertical="center" slot="end" [gap]="[5]">
      <ng-content select="[button]"></ng-content>
      @if (showCancelButton) {
        <ion-button
          class="close-button"
          [ngClass]="{
            'icon-only': !showCancelText,
            'danger-close-button': !!cancelButtonPopupConfiguration,
          }"
          [color]="buttonColor"
          (click)="handleCancelButton()"
          id="close-button">
          @if (showCancelText) {
            <span>{{ 'general.cancel' | translate }}</span>
            <fa-icon [icon]="closeIcon" slot="end"></fa-icon>
          } @else {
            <fa-icon [icon]="closeIcon"></fa-icon>
          }
        </ion-button>
        @if (cancelButtonPopupConfiguration) {
          <ion-popover trigger="close-button" [isOpen]="cancelPopupOpen">
            <ng-template>
              <div class="ion-padding header-toolbar-buttons autoident-cancel-popover">
                @if (cancelButtonPopupConfiguration.cancelDescriptionTextKey) {
                  <ion-text>{{
                    cancelButtonPopupConfiguration.cancelDescriptionTextKey | translate
                  }}</ion-text>
                }
                @if (
                  cancelButtonPopupConfiguration.continueButtonTextKey ||
                  cancelButtonPopupConfiguration.cancelButtonTextKey
                ) {
                  @if (cancelButtonPopupConfiguration.continueButtonTextKey) {
                    <ion-button
                      color="tertiary"
                      expand="block"
                      class="close-button"
                      (click)="handleContinueButtonPopup()">
                      {{ cancelButtonPopupConfiguration.continueButtonTextKey | translate }}
                      <fa-icon [icon]="arrowRightIcon" slot="end"></fa-icon>
                    </ion-button>
                  }
                  @if (cancelButtonPopupConfiguration.cancelButtonTextKey) {
                    <ion-button
                      expand="block"
                      class="danger-close-button close-button"
                      [color]="undefined"
                      (click)="handleCancelButtonPopup()">
                      {{ cancelButtonPopupConfiguration.cancelButtonTextKey | translate }}
                      <fa-icon [icon]="closeIcon" slot="end"></fa-icon>
                    </ion-button>
                  }
                }
              </div>
            </ng-template>
          </ion-popover>
        }
      }
    </lib-auto-layout>
  </ion-toolbar>
</ion-header>
