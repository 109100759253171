import { SelectionObject } from 'projects/core/src/lib/models/form.model';
import { AttributeNameIdentifier } from 'projects/core/src/lib/models/shared.model';

export class AppointmentBookingData {
  typeFormData: FieldTransferData[];
  createId: string;

  static fromPlainObject(object: AppointmentBookingData): AppointmentBookingData {
    const classInstance: AppointmentBookingData = new AppointmentBookingData();
    Object.assign(classInstance, object);
    if (object.typeFormData) {
      classInstance.typeFormData = object.typeFormData.map((typeFormDataEntry: FieldTransferData) =>
        FieldTransferData.fromPlainObject(typeFormDataEntry),
      );
    }
    return classInstance;
  }
}

export class FieldTransferData {
  identifier: AttributeNameIdentifier;
  value: string;
  options?: SelectionObject[];

  static fromPlainObject(object: FieldTransferData): FieldTransferData {
    const classInstance: FieldTransferData = new FieldTransferData();
    Object.assign(classInstance, object);
    if (object.identifier) {
      classInstance.identifier = new AttributeNameIdentifier(object.identifier.originalValue);
    }
    return classInstance;
  }
}
